import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { GlobalState } from '@core/interfaces/state';
import { hasValue, hasValueOperator, isEmpty, isNotEmpty } from '@core/utils/empty.util';
import { Store } from '@ngrx/store';
import { selectDmsSession } from '@store/auth';
import { catchError, distinctUntilChanged, first, iif, mergeMap, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaperlessUiSettings, SETTINGS, SETTINGS_KEYS } from '../data/paperless-uisettings';
import { PaperlessUser } from '../data/paperless-user';
import { PermissionsService } from './permissions.service';
import { CookieService } from 'ngx-cookie-service';

export interface LanguageOption {
  code: string;
  name: string;
  englishName?: string;

  /**
   * A date format string for use by the date selectors. MUST contain 'yyyy', 'mm' and 'dd'.
   */
  dateInputFormat?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  protected baseUrl: string = 'https://' + environment.hostStorageDomain + '/api/ui_settings/';

  private settings: Object = {};
  currentUser: PaperlessUser;

  public settingsSaved: EventEmitter<any> = new EventEmitter();

  public dashboardIsEmpty: boolean = false;

  public globalDropzoneEnabled: boolean = true;
  public globalDropzoneActive: boolean = false;
  public organizingSidebarSavedViews: boolean = false;

  constructor(
    protected http: HttpClient, 
    private cookie: CookieService,
    private permissionsService: PermissionsService, 
    private store: Store<GlobalState>
  ) {}

  // this is called by the app initializer in app.module
  public init() {
   return this.store.select(selectDmsSession).pipe(
      distinctUntilChanged((a, b) => a.sessionId === b.sessionId && a.csrfToken === b.csrfToken),
      hasValueOperator(),
      mergeMap((session) =>
        iif(
          () => this.isDmsReady(session),
          this.http.get<PaperlessUiSettings>(this.baseUrl).pipe(
            first(),
            tap((uisettings) => {
              this.settings = Object.assign(this.settings, uisettings.settings);
              this.currentUser = uisettings.user;
              this.permissionsService.initialize(uisettings.permissions, this.currentUser);
            }),
            catchError(() => of(null))
          ),
          of(null)
        )
      )
    )
  }

  isDmsReady(session: any) {
    const csrfToken = this.cookie.get('csrftoken');
    const sessionId = this.cookie.get('sessionid');
    return isNotEmpty(session?.csrfToken)
        && isEmpty(this.settings)
        && hasValue(csrfToken)
        && hasValue(sessionId);
  }

  get displayName(): string {
    return (this.currentUser.first_name ?? this.currentUser.username ?? '').trim();
  }

  private getSettingRawValue(key: string): any {
    let value = undefined;
    // parse key:key:key into nested object
    const keys = key.replace('general-settings:', '').split(':');
    let settingObj = this.settings;
    keys.forEach((keyPart, index) => {
      keyPart = keyPart.replace(/-/g, '_');
      if (!settingObj.hasOwnProperty(keyPart)) return;
      if (index == keys.length - 1) value = settingObj[keyPart];
      else settingObj = settingObj[keyPart];
    });
    return value;
  }

  get(key: string): any {
    let setting = SETTINGS.find((s) => s.key == key);

    if (!setting) {
      return undefined;
    }

    let value = this.getSettingRawValue(key);

    // special case to fallback
    if (key === SETTINGS_KEYS.DEFAULT_PERMS_OWNER && value === undefined) {
      return this.currentUser.id;
    }

    if (value !== undefined) {
      if (value === null) {
        return null;
      }
      switch (setting.type) {
        case 'boolean':
          return JSON.parse(value);
        case 'number':
          return +value;
        case 'string':
          return value;
        default:
          return value;
      }
    } else {
      return setting.default;
    }
  }

  set(key: string, value: any) {
    // parse key:key:key into nested object
    let settingObj = this.settings;
    const keys = key.replace('general-settings:', '').split(':');
    keys.forEach((keyPart, index) => {
      keyPart = keyPart.replace(/-/g, '_');
      if (!settingObj.hasOwnProperty(keyPart)) settingObj[keyPart] = {};
      if (index == keys.length - 1) settingObj[keyPart] = value;
      else settingObj = settingObj[keyPart];
    });
  }
}
