
/**
 * FeatureIDs contains all unique codes for the features of our application
 */
export type FeatureType = FeatureIDs;

export enum FeatureIDs {
  dashboard = 'DASHBOARD',
  recommendationsList = 'RECOMMENDATIONS_LIST',
  progressReportTask = 'PROGRESS_REPORT_TASK',
  progressReportList = 'PROGRESS_REPORT_LIST',
  progressReportView = 'PROGRESS_REPORT_VIEW',
  userManagement = 'USER_MANAGEMENT',
  institutionManagement = 'INSTITUTION_MANAGEMENT',
  bankOfQuestions = 'BANK_OF_QUESTIONS',
  bookmarkList = 'BOOKMARK_LIST',
  bookmarkView = 'BOOKMARK_VIEW',
  chapterPassport = 'CHAPTER_PASSPORT',
  bookmarkTaskView = 'BOOKMARK_TASK_VIEW',
  acquisList = 'ACQUIS_LIST',
  acquisView = 'ACQUIS_VIEW',
  acquisTaskView = 'ACQUIS_TASK_VIEW',
  workPlanList = 'WORK_PLAN_LIST',
  workPlanView = 'WORK_PLAN_VIEW',
  monitoring = 'MONITORING',
  calendar = 'CALENDAR',
  calendarTask = 'CALENDAR_TASK',
  expertsDb = 'EXPERTS_DB',
  donatorsDb = 'DONATORS_DB',
  taiexDb = 'TAIEX_DB',
  twinningDb = 'TWINNING_DB',
  projectsDb = 'PROJECTS_DB',
  projectView = 'PROJECT_VIEW',
  priorityList = 'PRIORITY_LIST',
  trainingNeeds = 'TRAINING_NEEDS',
  taiexNeeds = 'TAIEX_NEEDS',
  twinningNeeds = 'TWINNING_NEEDS',
  gnpieTrainingNeed = 'GNPIE_TRAINING_NEED',
  donatorView = 'DONATOR_VIEW',
  notifications = 'NOTIFICATIONS',
  documents = 'DOCUMENTS',
  documentDetail = 'DOCUMENT_DETAIL',
  integration = 'INTEGRATION',
  integrationQuestions = 'INTEGRATION_QUESTIONS',
  integrationCase = 'INTEGRATION_CASE',
  integrationTask = 'INTEGRATION_TASK',
  supportFields = 'SUPPORT_FIELDS',
  lga = 'LGA',
  iaga = 'IAGA',
  roadmapList = 'ROADMAP_LIST',
  roadmapTask = 'ROADMAP_TASK',
  roadmapSubtask = 'ROADMAP_SUBTASK',
  roadmapPlan = 'ROADMAP_PLAN',
  albanianLaws = 'ALBANIAN_LAWS',
  report = 'REPORT',
  topicTree = 'TOPIC_TREE',
  nenkomitetet = 'SUBCOMMITTEE',
  userManual = 'USER_MANUAL',
  errorLogs = 'ERROR_LOGS',
  approx = 'APPROX',
}
