import { AuthProvider } from "@core/interfaces/auth";

const host = 'mimie.xyz';
export const environment = {
  production: false,
  usesHTTPS: false,
  staging: false,
  logLevel: 2,
  hostApiDomain: ['back', host].join('.'),
  hostSignUpload: ['sign', host].join('.') + '/upload',
  hostSignAuthorize: ['sign', host].join('.') + '/authorize',
  hostAuthDomain: ['auth', host].join('.'),
  hostActionDomain: ['action', host].join('.'),
  hostStorageDomain: [host, '/dms'].join(''),
  webSocketProtocol: 'wss:',
  defaultTimeout: 3000,
  authProviders: [AuthProvider.OIDC, AuthProvider.ENTRA_ID, AuthProvider.NAIS_B2C],
  secret: 'A3PnGzWjxGuAYtFu'
};
