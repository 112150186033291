import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CsrfTokenInterceptor implements HttpInterceptor {

  constructor(private cookie: CookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes(environment.hostStorageDomain)) {
      let csrfToken = this.cookie.get('csrftoken');
      let sessionid = this.cookie.get('sessionid');
      if (csrfToken && sessionid) {
        request = request.clone({
          setHeaders: {
            'X-CSRFToken': csrfToken,
          },
        })
      } else {
        // Return an Observable that emits an error
        return throwError(() => new Error('CSRF token not found'))
      }
    }
    return next.handle(request);
  }
}
