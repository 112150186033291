import { Chapters, Clusters, Institutions, IntegrationPhasesEnumEnum, SubChapters, Topics } from '@core/api/generated/types';
import { PaperlessGroup } from '@core/documents/data/paperless-group';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CoreActions = createActionGroup({
  source: 'Core',
  events: {
    setClusters: props<{ clusters: Array<Clusters>}>(),
    setChapters: props<{ chapters: Array<Chapters>}>(),
    changeChapterPhase: props<{ chapterId: number, phase: IntegrationPhasesEnumEnum}>(),
    setSubChapters: props<{ subchapters: Array<SubChapters>}>(),
    setTopics: props<{ topics: Array<Topics>}>(),
    setInstitutions: props<{ institutions: Array<Institutions>}>(),
    setDmsGroups: props<{ dmsGroups: Array<PaperlessGroup>}>(),
    insertInstitution: props<{ institution: Institutions}>(),
    updateInstitution: props<{ institution: Institutions}>(),
    resetCore: emptyProps(),
  },
});
