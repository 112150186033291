import { ErrorHandler, Injector, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

//Routes
import { routes } from './app.route';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { AppComponent } from './app.component';

// service
import { InitService } from './core/services/init.service';

// store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PersistStateModule } from '@ngrx-addons/persist-state';
import { layoutReducer } from '@store/layout';
import { coreReducer } from '@store/core';
import { authReducer } from '@store/auth';

// i18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// perfect-scrollbar
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';

import { ServiceLocator } from './core/common/service-locator';
import { CoreModule } from './core/core.module';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DYNAMIC_MATCHER_PROVIDERS } from '@ng-dynamic-forms/core';
import { GraphQLModule } from '@core/api/graphql.module';
import { BeforeAppInit } from '@ngrx-addons/common';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '@store/auth/auth.effects';
import { CUSTOM_MATCHERS } from '@shared/dynamic-form/core/custom-matchers';
import { CUSTOM_VALIDATORS } from '@shared/dynamic-form/core/custom-validators';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { RouteSerializer } from '@store/router/router.serializer';

import { FileSaverModule } from 'ngx-filesaver';
import { CookieService } from 'ngx-cookie-service';
import { secureStorageStrategy } from '@core/utils/localforage.wrapper';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    CoreModule,
    FlatpickrModule.forRoot(),
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    // Apollo GraphQL configuration
    GraphQLModule,
    StoreModule.forRoot({
      layout: layoutReducer,
      core: coreReducer,
      auth: authReducer,
      router: routerReducer,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: RouteSerializer,
    }),
    EffectsModule.forRoot(AuthEffects),
    PersistStateModule.forRoot({
      states: [
        {
          key: 'auth',
          // encrypted indexdb
          storage: secureStorageStrategy,
          storageKey: `auth-cache@store`,
          migrations: [],
          skip: 1
        },
        {
          key: 'core',
          // encrypted indexdb
          storage: secureStorageStrategy,
          storageKey: `core-cache@store`,
          migrations: [],
          skip: 1
        },
        // next states to persist, same reducer key can be
        // specified multiple times to save parts of the state
        // to different storages
      ],
      // optional root options (for all, also feature states)
      storageKeyPrefix: 'sie',
      // optional rehydration strategy
      strategy: BeforeAppInit, // or AfterAppInit
    }),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectOutsideZone: true, // If set to true, the connection is established outside the Angular zone for better performance
    }),
    NgScrollbarModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean'],
        ],
      },
      sanitize: true,
    }),
    FileSaverModule,
  ],
  declarations: [AppComponent],
  providers: [
    ...InitService.providers(),
    ...DYNAMIC_MATCHER_PROVIDERS,
    ...CUSTOM_MATCHERS,
    ...CUSTOM_VALIDATORS,
    CookieService,
    Title,
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        appearance: 'standart',
        visibility: 'hover',
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: LOCALE_ID,
      useValue: 'sq',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    ServiceLocator.injector = injector;
  }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
