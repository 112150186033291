/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ListProjectsResponse } from '../../models/list-projects-response';

export interface QueryProjects$Params {

/**
 * Filter projects by name (full or partial match)
 */
  projectName?: string;

/**
 * Filter projects by creation date start (epoch time)
 */
  createdDateStart?: number;

/**
 * Filter projects by creation date end (epoch time)
 */
  createdDateEnd?: number;

/**
 * Filter projects by progress status
 */
  progress?: 'Not Started' | 'In Progress' | 'Completed';

/**
 * Filter projects by EU Law Name (full or partial match)
 */
  euLaw?: string;

/**
 * Filter projects by EU Law CELEX (Exact match)
 */
  celex?: string;

/**
 * Filter projects by overdue status (Exceeded deadline date and not completed)
 */
  isOverdue?: boolean;

/**
 * Return projects starting from the provided page number. Default page size is 10
 */
  pageNo?: number;
}

export function queryProjects(http: HttpClient, rootUrl: string, params?: QueryProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<ListProjectsResponse>> {
  const rb = new RequestBuilder(rootUrl, queryProjects.PATH, 'get');
  if (params) {
    rb.query('projectName', params.projectName, {});
    rb.query('createdDateStart', params.createdDateStart, {});
    rb.query('createdDateEnd', params.createdDateEnd, {});
    rb.query('progress', params.progress, {});
    rb.query('euLaw', params.euLaw, {});
    rb.query('celex', params.celex, {});
    rb.query('isOverdue', params.isOverdue, {});
    rb.query('pageNo', params.pageNo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ListProjectsResponse>;
    })
  );
}

queryProjects.PATH = '/projects';
