/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createProject } from '../fn/projects/create-project';
import { CreateProject$Params } from '../fn/projects/create-project';
import { getEuLawToC } from '../fn/projects/get-eu-law-to-c';
import { GetEuLawToC$Params } from '../fn/projects/get-eu-law-to-c';
import { getProject } from '../fn/projects/get-project';
import { GetProject$Params } from '../fn/projects/get-project';
import { ListProjectsResponse } from '../models/list-projects-response';
import { ProjectResponse } from '../models/project-response';
import { queryProjects } from '../fn/projects/query-projects';
import { QueryProjects$Params } from '../fn/projects/query-projects';
import { TocResponse } from '../models/toc-response';
import { updateProject } from '../fn/projects/update-project';
import { UpdateProject$Params } from '../fn/projects/update-project';


/**
 * Actions related to Projects (Transposition) management
 */
@Injectable({ providedIn: 'root' })
export class ProjectsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `queryProjects()` */
  static readonly QueryProjectsPath = '/projects';

  /**
   * List and filter Projects (Transposition).
   *
   * Query existing Projects (Transposition) and filter them based on various criteria
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryProjects$Response(params?: QueryProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<ListProjectsResponse>> {
    return queryProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * List and filter Projects (Transposition).
   *
   * Query existing Projects (Transposition) and filter them based on various criteria
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryProjects(params?: QueryProjects$Params, context?: HttpContext): Observable<ListProjectsResponse> {
    return this.queryProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListProjectsResponse>): ListProjectsResponse => r.body)
    );
  }

  /** Path part for operation `createProject()` */
  static readonly CreateProjectPath = '/projects';

  /**
   * Create a new Project (Transposition).
   *
   * Create a new Project (Transposition) with the provided details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProject$Response(params: CreateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<{

/**
 * Unique identifier of the project
 */
'id'?: string;
}>> {
    return createProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new Project (Transposition).
   *
   * Create a new Project (Transposition) with the provided details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProject(params: CreateProject$Params, context?: HttpContext): Observable<{

/**
 * Unique identifier of the project
 */
'id'?: string;
}> {
    return this.createProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Unique identifier of the project
 */
'id'?: string;
}>): {

/**
 * Unique identifier of the project
 */
'id'?: string;
} => r.body)
    );
  }

  /** Path part for operation `getProject()` */
  static readonly GetProjectPath = '/projects/{projectId}';

  /**
   * Get a specific Project (Transposition).
   *
   * Retrieve a specific Project (Transposition) by its unique identifier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject$Response(params: GetProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectResponse>> {
    return getProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a specific Project (Transposition).
   *
   * Retrieve a specific Project (Transposition) by its unique identifier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject(params: GetProject$Params, context?: HttpContext): Observable<ProjectResponse> {
    return this.getProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectResponse>): ProjectResponse => r.body)
    );
  }

  /** Path part for operation `updateProject()` */
  static readonly UpdateProjectPath = '/projects/{projectId}';

  /**
   * Update a specific Project (Transposition).
   *
   * Update a specific Project (Transposition) by its unique identifier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProject$Response(params: UpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectResponse>> {
    return updateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a specific Project (Transposition).
   *
   * Update a specific Project (Transposition) by its unique identifier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProject(params: UpdateProject$Params, context?: HttpContext): Observable<ProjectResponse> {
    return this.updateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectResponse>): ProjectResponse => r.body)
    );
  }

  /** Path part for operation `getEuLawToC()` */
  static readonly GetEuLawToCPath = '/projects/{projectId}/toc';

  /**
   * Get ToC of the EU Law in a Project (Transposition).
   *
   * Retrieve the Table of content (hierarchical structure) of a EU Law associated with a specific Project (Transposition)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEuLawToC()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEuLawToC$Response(params: GetEuLawToC$Params, context?: HttpContext): Observable<StrictHttpResponse<TocResponse>> {
    return getEuLawToC(this.http, this.rootUrl, params, context);
  }

  /**
   * Get ToC of the EU Law in a Project (Transposition).
   *
   * Retrieve the Table of content (hierarchical structure) of a EU Law associated with a specific Project (Transposition)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEuLawToC$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEuLawToC(params: GetEuLawToC$Params, context?: HttpContext): Observable<TocResponse> {
    return this.getEuLawToC$Response(params, context).pipe(
      map((r: StrictHttpResponse<TocResponse>): TocResponse => r.body)
    );
  }

}
