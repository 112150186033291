// THIS FILE IS GENERATED, DO NOT EDIT!
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  bytea: { input: any; output: any; }
  citext: { input: any; output: any; }
  date: { input: any; output: any; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  time: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  timetz: { input: any; output: any; }
  uuid: { input: any; output: any; }
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface BooleanComparisonExp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

export interface CreateAcquisWorkPlanOutput {
  __typename?: 'CreateAcquisWorkPlanOutput';
  id: Scalars['uuid']['output'];
}

export interface CreateAcquisWorkPlanPkieWorkPlansInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisAuthprovidersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthprovidersBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthprovidersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthprovidersBoolExp>>;
  id?: InputMaybe<InsertAcquisStringComparisonExp>;
  userProviders?: InputMaybe<InsertAcquisAuthuserprovidersBoolExp>;
  userProviders_aggregate?: InputMaybe<InsertAcquisAuthuserprovidersAggregateBoolExp>;
}

export enum InsertAcquisAuthprovidersConstraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

export interface InsertAcquisAuthprovidersInsertInput {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<InsertAcquisAuthuserprovidersArrRelInsertInput>;
}

export interface InsertAcquisAuthprovidersObjRelInsertInput {
  data: InsertAcquisAuthprovidersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisAuthprovidersOnConflict>;
}

export interface InsertAcquisAuthprovidersOnConflict {
  constraint: InsertAcquisAuthprovidersConstraint;
  update_columns: Array<InsertAcquisAuthprovidersUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthprovidersBoolExp>;
}

export enum InsertAcquisAuthprovidersUpdateColumn {
  /** column name */
  Id = 'id'
}

export interface InsertAcquisAuthrefreshtokensAggregateBoolExp {
  count?: InputMaybe<InsertAcquisAuthrefreshtokensAggregateBoolExpCount>;
}

export interface InsertAcquisAuthrefreshtokensAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisAuthrefreshtokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisAuthrefreshtokensBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisAuthrefreshtokensArrRelInsertInput {
  data: Array<InsertAcquisAuthrefreshtokensInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisAuthrefreshtokensOnConflict>;
}

export interface InsertAcquisAuthrefreshtokensBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthrefreshtokensBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthrefreshtokensBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthrefreshtokensBoolExp>>;
  createdAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  expiresAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  metadata?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  refreshTokenHash?: InputMaybe<InsertAcquisStringComparisonExp>;
  refresh_token_type?: InputMaybe<InsertAcquisAuthrefreshtokentypesBoolExp>;
  type?: InputMaybe<InsertAcquisAuthrefreshtokentypesEnumComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  userId?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisAuthrefreshtokensConstraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

export interface InsertAcquisAuthrefreshtokensInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  refresh_token_type?: InputMaybe<InsertAcquisAuthrefreshtokentypesObjRelInsertInput>;
  type?: InputMaybe<InsertAcquisAuthrefreshtokentypesEnum>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisAuthrefreshtokensOnConflict {
  constraint: InsertAcquisAuthrefreshtokensConstraint;
  update_columns: Array<InsertAcquisAuthrefreshtokensUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthrefreshtokensBoolExp>;
}

export enum InsertAcquisAuthrefreshtokensSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export enum InsertAcquisAuthrefreshtokensUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export interface InsertAcquisAuthrefreshtokentypesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthrefreshtokentypesBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthrefreshtokentypesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthrefreshtokentypesBoolExp>>;
  comment?: InputMaybe<InsertAcquisStringComparisonExp>;
  refreshTokens?: InputMaybe<InsertAcquisAuthrefreshtokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<InsertAcquisAuthrefreshtokensAggregateBoolExp>;
  value?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisAuthrefreshtokentypesConstraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey'
}

export enum InsertAcquisAuthrefreshtokentypesEnum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular'
}

export interface InsertAcquisAuthrefreshtokentypesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisAuthrefreshtokentypesEnum>;
  _in?: InputMaybe<Array<InsertAcquisAuthrefreshtokentypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisAuthrefreshtokentypesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisAuthrefreshtokentypesEnum>>;
}

export interface InsertAcquisAuthrefreshtokentypesInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<InsertAcquisAuthrefreshtokensArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisAuthrefreshtokentypesObjRelInsertInput {
  data: InsertAcquisAuthrefreshtokentypesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisAuthrefreshtokentypesOnConflict>;
}

export interface InsertAcquisAuthrefreshtokentypesOnConflict {
  constraint: InsertAcquisAuthrefreshtokentypesConstraint;
  update_columns: Array<InsertAcquisAuthrefreshtokentypesUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthrefreshtokentypesBoolExp>;
}

export enum InsertAcquisAuthrefreshtokentypesUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface InsertAcquisAuthrolesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthrolesBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthrolesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthrolesBoolExp>>;
  role?: InputMaybe<InsertAcquisStringComparisonExp>;
  userRoles?: InputMaybe<InsertAcquisAuthuserrolesBoolExp>;
  userRoles_aggregate?: InputMaybe<InsertAcquisAuthuserrolesAggregateBoolExp>;
  usersByDefaultRole?: InputMaybe<InsertAcquisUsersBoolExp>;
  usersByDefaultRole_aggregate?: InputMaybe<InsertAcquisUsersAggregateBoolExp>;
}

export enum InsertAcquisAuthrolesConstraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

export enum InsertAcquisAuthrolesEnum {
  ChapterCommunication = 'CHAPTER_COMMUNICATION',
  ChapterCoordinator = 'CHAPTER_COORDINATOR',
  ChapterDefined = 'CHAPTER_DEFINED',
  ChapterKnowledge = 'CHAPTER_KNOWLEDGE',
  ChapterMonitoring = 'CHAPTER_MONITORING',
  ChiefGnpieSecretariat = 'CHIEF_GNPIE_SECRETARIAT',
  ClusterCoordinator = 'CLUSTER_COORDINATOR',
  GnpieMember = 'GNPIE_MEMBER',
  GnpieSecretariat = 'GNPIE_SECRETARIAT',
  HorizontalCoordinator = 'HORIZONTAL_COORDINATOR',
  MainNegotiator = 'MAIN_NEGOTIATOR',
  Member = 'MEMBER',
  Negotiator = 'NEGOTIATOR',
  PmoSecretariat = 'PMO_SECRETARIAT',
  Subnegotiator = 'SUBNEGOTIATOR',
  UserManager = 'USER_MANAGER',
  Anonymous = 'anonymous',
  Me = 'me',
  User = 'user'
}

export interface InsertAcquisAuthrolesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisAuthrolesEnum>;
  _in?: InputMaybe<Array<InsertAcquisAuthrolesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisAuthrolesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisAuthrolesEnum>>;
}

export interface InsertAcquisAuthrolesInsertInput {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<InsertAcquisAuthuserrolesArrRelInsertInput>;
  usersByDefaultRole?: InputMaybe<InsertAcquisUsersArrRelInsertInput>;
}

export interface InsertAcquisAuthrolesObjRelInsertInput {
  data: InsertAcquisAuthrolesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisAuthrolesOnConflict>;
}

export interface InsertAcquisAuthrolesOnConflict {
  constraint: InsertAcquisAuthrolesConstraint;
  update_columns: Array<InsertAcquisAuthrolesUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthrolesBoolExp>;
}

export enum InsertAcquisAuthrolesUpdateColumn {
  /** column name */
  Role = 'role'
}

export interface InsertAcquisAuthuserprovidersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisAuthuserprovidersAggregateBoolExpCount>;
}

export interface InsertAcquisAuthuserprovidersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisAuthuserprovidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisAuthuserprovidersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisAuthuserprovidersArrRelInsertInput {
  data: Array<InsertAcquisAuthuserprovidersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisAuthuserprovidersOnConflict>;
}

export interface InsertAcquisAuthuserprovidersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthuserprovidersBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthuserprovidersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthuserprovidersBoolExp>>;
  accessToken?: InputMaybe<InsertAcquisStringComparisonExp>;
  createdAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  provider?: InputMaybe<InsertAcquisAuthprovidersBoolExp>;
  providerId?: InputMaybe<InsertAcquisStringComparisonExp>;
  providerUserId?: InputMaybe<InsertAcquisStringComparisonExp>;
  refreshToken?: InputMaybe<InsertAcquisStringComparisonExp>;
  updatedAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  userId?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisAuthuserprovidersConstraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key'
}

export interface InsertAcquisAuthuserprovidersInsertInput {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<InsertAcquisAuthprovidersObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisAuthuserprovidersOnConflict {
  constraint: InsertAcquisAuthuserprovidersConstraint;
  update_columns: Array<InsertAcquisAuthuserprovidersUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthuserprovidersBoolExp>;
}

export enum InsertAcquisAuthuserprovidersSelectColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export enum InsertAcquisAuthuserprovidersUpdateColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export interface InsertAcquisAuthuserrolesAggregateBoolExp {
  count?: InputMaybe<InsertAcquisAuthuserrolesAggregateBoolExpCount>;
}

export interface InsertAcquisAuthuserrolesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisAuthuserrolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisAuthuserrolesBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisAuthuserrolesArrRelInsertInput {
  data: Array<InsertAcquisAuthuserrolesInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisAuthuserrolesOnConflict>;
}

export interface InsertAcquisAuthuserrolesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthuserrolesBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthuserrolesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthuserrolesBoolExp>>;
  createdAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  modules?: InputMaybe<InsertAcquisStringArrayComparisonExp>;
  modules_string?: InputMaybe<InsertAcquisStringComparisonExp>;
  role?: InputMaybe<InsertAcquisAuthrolesEnumComparisonExp>;
  roleByRole?: InputMaybe<InsertAcquisAuthrolesBoolExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  userId?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisAuthuserrolesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key'
}

export interface InsertAcquisAuthuserrolesInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<InsertAcquisAuthrolesEnum>;
  roleByRole?: InputMaybe<InsertAcquisAuthrolesObjRelInsertInput>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisAuthuserrolesOnConflict {
  constraint: InsertAcquisAuthuserrolesConstraint;
  update_columns: Array<InsertAcquisAuthuserrolesUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthuserrolesBoolExp>;
}

export enum InsertAcquisAuthuserrolesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Modules = 'modules',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export enum InsertAcquisAuthuserrolesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Modules = 'modules',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export interface InsertAcquisAuthusersecuritykeysAggregateBoolExp {
  count?: InputMaybe<InsertAcquisAuthusersecuritykeysAggregateBoolExpCount>;
}

export interface InsertAcquisAuthusersecuritykeysAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisAuthusersecuritykeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisAuthusersecuritykeysBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisAuthusersecuritykeysArrRelInsertInput {
  data: Array<InsertAcquisAuthusersecuritykeysInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisAuthusersecuritykeysOnConflict>;
}

export interface InsertAcquisAuthusersecuritykeysBoolExp {
  _and?: InputMaybe<Array<InsertAcquisAuthusersecuritykeysBoolExp>>;
  _not?: InputMaybe<InsertAcquisAuthusersecuritykeysBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisAuthusersecuritykeysBoolExp>>;
  counter?: InputMaybe<InsertAcquisBigintComparisonExp>;
  credentialId?: InputMaybe<InsertAcquisStringComparisonExp>;
  credentialPublicKey?: InputMaybe<InsertAcquisByteaComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  nickname?: InputMaybe<InsertAcquisStringComparisonExp>;
  transports?: InputMaybe<InsertAcquisStringComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  userId?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisAuthusersecuritykeysConstraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey'
}

export interface InsertAcquisAuthusersecuritykeysInsertInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisAuthusersecuritykeysOnConflict {
  constraint: InsertAcquisAuthusersecuritykeysConstraint;
  update_columns: Array<InsertAcquisAuthusersecuritykeysUpdateColumn>;
  where?: InputMaybe<InsertAcquisAuthusersecuritykeysBoolExp>;
}

export enum InsertAcquisAuthusersecuritykeysSelectColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export enum InsertAcquisAuthusersecuritykeysUpdateColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export interface InsertAcquisBigintComparisonExp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

export interface InsertAcquisBookmarkTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertAcquisBookmarkTaskActivityAggregateBoolExpCount>;
}

export interface InsertAcquisBookmarkTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisBookmarkTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTaskActivityBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisBookmarkTaskActivityArrRelInsertInput {
  data: Array<InsertAcquisBookmarkTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisBookmarkTaskActivityOnConflict>;
}

export interface InsertAcquisBookmarkTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertAcquisBookmarkTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertAcquisBookmarkTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisBookmarkTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertAcquisStringComparisonExp>;
  bookmark_task?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  status?: InputMaybe<InsertAcquisBookmarkTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisBookmarkTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskActivityPkey = 'bookmark_task_activity_pkey'
}

export interface InsertAcquisBookmarkTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  bookmark_task?: InputMaybe<InsertAcquisBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertAcquisBookmarkTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisBookmarkTaskActivityOnConflict {
  constraint: InsertAcquisBookmarkTaskActivityConstraint;
  update_columns: Array<InsertAcquisBookmarkTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertAcquisBookmarkTaskActivityBoolExp>;
}

export enum InsertAcquisBookmarkTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisBookmarkTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisBookmarkTaskAnswersAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisBookmarkTaskAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisBookmarkTaskAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisBookmarkTaskAnswersAggregateBoolExpCount>;
}

export interface InsertAcquisBookmarkTaskAnswersAggregateBoolExpBoolAnd {
  arguments: InsertAcquisBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTaskAnswersBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisBookmarkTaskAnswersAggregateBoolExpBoolOr {
  arguments: InsertAcquisBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTaskAnswersBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisBookmarkTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisBookmarkTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTaskAnswersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisBookmarkTaskAnswersArrRelInsertInput {
  data: Array<InsertAcquisBookmarkTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisBookmarkTaskAnswersOnConflict>;
}

export interface InsertAcquisBookmarkTaskAnswersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisBookmarkTaskAnswersBoolExp>>;
  _not?: InputMaybe<InsertAcquisBookmarkTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisBookmarkTaskAnswersBoolExp>>;
  answer?: InputMaybe<InsertAcquisStringComparisonExp>;
  asnwered_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  bookmark_task?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_completed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisBookmarkTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskAnswersPkey = 'bookmark_task_answers_pkey'
}

export interface InsertAcquisBookmarkTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  asnwered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_task?: InputMaybe<InsertAcquisBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisBookmarkTaskAnswersOnConflict {
  constraint: InsertAcquisBookmarkTaskAnswersConstraint;
  update_columns: Array<InsertAcquisBookmarkTaskAnswersUpdateColumn>;
  where?: InputMaybe<InsertAcquisBookmarkTaskAnswersBoolExp>;
}

export enum InsertAcquisBookmarkTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertAcquisBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertAcquisBookmarkTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisBookmarkTaskCommentAggregateBoolExp {
  count?: InputMaybe<InsertAcquisBookmarkTaskCommentAggregateBoolExpCount>;
}

export interface InsertAcquisBookmarkTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisBookmarkTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTaskCommentBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisBookmarkTaskCommentArrRelInsertInput {
  data: Array<InsertAcquisBookmarkTaskCommentInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisBookmarkTaskCommentOnConflict>;
}

export interface InsertAcquisBookmarkTaskCommentBoolExp {
  _and?: InputMaybe<Array<InsertAcquisBookmarkTaskCommentBoolExp>>;
  _not?: InputMaybe<InsertAcquisBookmarkTaskCommentBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisBookmarkTaskCommentBoolExp>>;
  bookmark_task?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  comment?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisBookmarkTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskCommentPkey = 'bookmark_task_comment_pkey'
}

export interface InsertAcquisBookmarkTaskCommentInsertInput {
  bookmark_task?: InputMaybe<InsertAcquisBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisBookmarkTaskCommentOnConflict {
  constraint: InsertAcquisBookmarkTaskCommentConstraint;
  update_columns: Array<InsertAcquisBookmarkTaskCommentUpdateColumn>;
  where?: InputMaybe<InsertAcquisBookmarkTaskCommentBoolExp>;
}

export enum InsertAcquisBookmarkTaskCommentSelectColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisBookmarkTaskCommentUpdateColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisBookmarkTaskMeasuresBoolExp {
  _and?: InputMaybe<Array<InsertAcquisBookmarkTaskMeasuresBoolExp>>;
  _not?: InputMaybe<InsertAcquisBookmarkTaskMeasuresBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisBookmarkTaskMeasuresBoolExp>>;
  measure?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisBookmarkTaskMeasuresConstraint {
  /** unique or primary key constraint on columns "measure" */
  BookmarkTaskMeasuresPkey = 'bookmark_task_measures_pkey'
}

export enum InsertAcquisBookmarkTaskMeasuresEnum {
  ImplementationEnforcment = 'IMPLEMENTATION_ENFORCMENT',
  InstitutionalAdministrative = 'INSTITUTIONAL_ADMINISTRATIVE',
  LegalPolicy = 'LEGAL_POLICY'
}

export interface InsertAcquisBookmarkTaskMeasuresEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisBookmarkTaskMeasuresEnum>;
  _in?: InputMaybe<Array<InsertAcquisBookmarkTaskMeasuresEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisBookmarkTaskMeasuresEnum>;
  _nin?: InputMaybe<Array<InsertAcquisBookmarkTaskMeasuresEnum>>;
}

export interface InsertAcquisBookmarkTaskMeasuresInsertInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisBookmarkTaskMeasuresObjRelInsertInput {
  data: InsertAcquisBookmarkTaskMeasuresInsertInput;
  on_conflict?: InputMaybe<InsertAcquisBookmarkTaskMeasuresOnConflict>;
}

export interface InsertAcquisBookmarkTaskMeasuresOnConflict {
  constraint: InsertAcquisBookmarkTaskMeasuresConstraint;
  update_columns: Array<InsertAcquisBookmarkTaskMeasuresUpdateColumn>;
  where?: InputMaybe<InsertAcquisBookmarkTaskMeasuresBoolExp>;
}

export enum InsertAcquisBookmarkTaskMeasuresUpdateColumn {
  /** column name */
  Measure = 'measure'
}

export enum InsertAcquisBookmarkTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertAcquisBookmarkTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisBookmarkTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertAcquisBookmarkTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisBookmarkTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisBookmarkTaskStatusesEnum>>;
}

export interface InsertAcquisBookmarkTasksAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisBookmarkTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisBookmarkTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisBookmarkTasksAggregateBoolExpCount>;
}

export interface InsertAcquisBookmarkTasksAggregateBoolExpBoolAnd {
  arguments: InsertAcquisBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisBookmarkTasksAggregateBoolExpBoolOr {
  arguments: InsertAcquisBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisBookmarkTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisBookmarkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisBookmarkTasksArrRelInsertInput {
  data: Array<InsertAcquisBookmarkTasksInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisBookmarkTasksOnConflict>;
}

export interface InsertAcquisBookmarkTasksBoolExp {
  _and?: InputMaybe<Array<InsertAcquisBookmarkTasksBoolExp>>;
  _not?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisBookmarkTasksBoolExp>>;
  assignee_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  bookmark?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  bookmark_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  bookmark_task_activities?: InputMaybe<InsertAcquisBookmarkTaskActivityBoolExp>;
  bookmark_task_activities_aggregate?: InputMaybe<InsertAcquisBookmarkTaskActivityAggregateBoolExp>;
  bookmark_task_answers?: InputMaybe<InsertAcquisBookmarkTaskAnswersBoolExp>;
  bookmark_task_answers_aggregate?: InputMaybe<InsertAcquisBookmarkTaskAnswersAggregateBoolExp>;
  bookmark_task_comments?: InputMaybe<InsertAcquisBookmarkTaskCommentBoolExp>;
  bookmark_task_comments_aggregate?: InputMaybe<InsertAcquisBookmarkTaskCommentAggregateBoolExp>;
  bookmark_task_measure?: InputMaybe<InsertAcquisBookmarkTaskMeasuresBoolExp>;
  budget_allocated_at_quarter?: InputMaybe<InsertAcquisStringComparisonExp>;
  budget_allocated_at_year?: InputMaybe<InsertAcquisIntComparisonExp>;
  budget_needed?: InputMaybe<InsertAcquisNumericComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  completition_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  gnpie_member?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_completed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  measure?: InputMaybe<InsertAcquisBookmarkTaskMeasuresEnumComparisonExp>;
  negotiator?: InputMaybe<InsertAcquisUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  specific_measure?: InputMaybe<InsertAcquisStringComparisonExp>;
  status?: InputMaybe<InsertAcquisBookmarkTaskStatusesEnumComparisonExp>;
  text?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  updated_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  yearly_quarter?: InputMaybe<InsertAcquisYearlyQuartersBoolExp>;
}

export enum InsertAcquisBookmarkTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTasksPkey = 'bookmark_tasks_pkey'
}

export interface InsertAcquisBookmarkTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark?: InputMaybe<InsertAcquisBookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task_activities?: InputMaybe<InsertAcquisBookmarkTaskActivityArrRelInsertInput>;
  bookmark_task_answers?: InputMaybe<InsertAcquisBookmarkTaskAnswersArrRelInsertInput>;
  bookmark_task_comments?: InputMaybe<InsertAcquisBookmarkTaskCommentArrRelInsertInput>;
  bookmark_task_measure?: InputMaybe<InsertAcquisBookmarkTaskMeasuresObjRelInsertInput>;
  budget_allocated_at_quarter?: InputMaybe<Scalars['String']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  gnpie_member?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<InsertAcquisBookmarkTaskMeasuresEnum>;
  negotiator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  specific_measure?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InsertAcquisBookmarkTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  yearly_quarter?: InputMaybe<InsertAcquisYearlyQuartersObjRelInsertInput>;
}

export interface InsertAcquisBookmarkTasksObjRelInsertInput {
  data: InsertAcquisBookmarkTasksInsertInput;
  on_conflict?: InputMaybe<InsertAcquisBookmarkTasksOnConflict>;
}

export interface InsertAcquisBookmarkTasksOnConflict {
  constraint: InsertAcquisBookmarkTasksConstraint;
  update_columns: Array<InsertAcquisBookmarkTasksUpdateColumn>;
  where?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
}

export enum InsertAcquisBookmarkTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export enum InsertAcquisBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertAcquisBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertAcquisBookmarkTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface InsertAcquisBookmarksAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisBookmarksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisBookmarksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisBookmarksAggregateBoolExpCount>;
}

export interface InsertAcquisBookmarksAggregateBoolExpBoolAnd {
  arguments: InsertAcquisBookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisBookmarksAggregateBoolExpBoolOr {
  arguments: InsertAcquisBookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisBookmarksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisBookmarksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisBookmarksArrRelInsertInput {
  data: Array<InsertAcquisBookmarksInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisBookmarksOnConflict>;
}

export interface InsertAcquisBookmarksBoolExp {
  _and?: InputMaybe<Array<InsertAcquisBookmarksBoolExp>>;
  _not?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisBookmarksBoolExp>>;
  assigned_to_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  assignee?: InputMaybe<InsertAcquisUsersBoolExp>;
  bookmark_tasks?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertAcquisBookmarkTasksAggregateBoolExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  code_id?: InputMaybe<InsertAcquisStringComparisonExp>;
  code_text?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  id?: InputMaybe<InsertAcquisIntComparisonExp>;
  index_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  is_completed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  measure_bookmarks?: InputMaybe<InsertAcquisPkieMeasureBookmarkBoolExp>;
  measure_bookmarks_aggregate?: InputMaybe<InsertAcquisPkieMeasureBookmarkAggregateBoolExp>;
  recommendation?: InputMaybe<InsertAcquisRecommendationsBoolExp>;
  recommendation_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  reference?: InputMaybe<InsertAcquisStringComparisonExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  topic?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topic_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisBookmarksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarksPkey = 'bookmarks_pkey'
}

export interface InsertAcquisBookmarksInsertInput {
  assigned_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  assignee?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  bookmark_tasks?: InputMaybe<InsertAcquisBookmarkTasksArrRelInsertInput>;
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  code_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  index_id?: InputMaybe<Scalars['Int']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure_bookmarks?: InputMaybe<InsertAcquisPkieMeasureBookmarkArrRelInsertInput>;
  recommendation?: InputMaybe<InsertAcquisRecommendationsObjRelInsertInput>;
  recommendation_id?: InputMaybe<Scalars['uuid']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<InsertAcquisTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
}

export interface InsertAcquisBookmarksObjRelInsertInput {
  data: InsertAcquisBookmarksInsertInput;
  on_conflict?: InputMaybe<InsertAcquisBookmarksOnConflict>;
}

export interface InsertAcquisBookmarksOnConflict {
  constraint: InsertAcquisBookmarksConstraint;
  update_columns: Array<InsertAcquisBookmarksUpdateColumn>;
  where?: InputMaybe<InsertAcquisBookmarksBoolExp>;
}

export enum InsertAcquisBookmarksSelectColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisBookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisBookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisBookmarksUpdateColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisBooleanComparisonExp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

export interface InsertAcquisByteaComparisonExp {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
}

export interface InsertAcquisCapacBeneficiariesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacBeneficiariesBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacBeneficiariesBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_Id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  email?: InputMaybe<InsertAcquisStringComparisonExp>;
  first_name?: InputMaybe<InsertAcquisStringComparisonExp>;
  function?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  institution_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  number?: InputMaybe<InsertAcquisStringComparisonExp>;
  project_beneficiaries?: InputMaybe<InsertAcquisCapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<InsertAcquisCapacProjectBeneficiariesAggregateBoolExp>;
  surname?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  BeneficiariesPkey = 'beneficiaries_pkey'
}

export interface InsertAcquisCapacBeneficiariesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_Id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<InsertAcquisCapacProjectBeneficiariesArrRelInsertInput>;
  surname?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacBeneficiariesObjRelInsertInput {
  data: InsertAcquisCapacBeneficiariesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacBeneficiariesOnConflict>;
}

export interface InsertAcquisCapacBeneficiariesOnConflict {
  constraint: InsertAcquisCapacBeneficiariesConstraint;
  update_columns: Array<InsertAcquisCapacBeneficiariesUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacBeneficiariesBoolExp>;
}

export enum InsertAcquisCapacBeneficiariesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_Id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Function = 'function',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  Number = 'number',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacChapterDonatorAggregateBoolExp {
  count?: InputMaybe<InsertAcquisCapacChapterDonatorAggregateBoolExpCount>;
}

export interface InsertAcquisCapacChapterDonatorAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacChapterDonatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacChapterDonatorBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacChapterDonatorArrRelInsertInput {
  data: Array<InsertAcquisCapacChapterDonatorInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacChapterDonatorOnConflict>;
}

export interface InsertAcquisCapacChapterDonatorBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacChapterDonatorBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacChapterDonatorBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacChapterDonatorBoolExp>>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  donator?: InputMaybe<InsertAcquisCapacDonatorsBoolExp>;
  donator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisCapacChapterDonatorConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterDonatorPkey = 'chapter_donator_pkey'
}

export interface InsertAcquisCapacChapterDonatorInsertInput {
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  donator?: InputMaybe<InsertAcquisCapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisCapacChapterDonatorOnConflict {
  constraint: InsertAcquisCapacChapterDonatorConstraint;
  update_columns: Array<InsertAcquisCapacChapterDonatorUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacChapterDonatorBoolExp>;
}

export enum InsertAcquisCapacChapterDonatorSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export enum InsertAcquisCapacChapterDonatorUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export interface InsertAcquisCapacDonatorsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacDonatorsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacDonatorsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacDonatorsBoolExp>>;
  address?: InputMaybe<InsertAcquisStringComparisonExp>;
  chapter_donators?: InputMaybe<InsertAcquisCapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<InsertAcquisCapacChapterDonatorAggregateBoolExp>;
  contact_email?: InputMaybe<InsertAcquisStringComparisonExp>;
  contact_function?: InputMaybe<InsertAcquisStringComparisonExp>;
  contact_person?: InputMaybe<InsertAcquisStringComparisonExp>;
  contact_phone?: InputMaybe<InsertAcquisStringComparisonExp>;
  country?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  integration_steps_supported?: InputMaybe<InsertAcquisNumericArrayComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  organisation?: InputMaybe<InsertAcquisStringComparisonExp>;
  planned_new_support?: InputMaybe<InsertAcquisStringComparisonExp>;
  projects?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<InsertAcquisCapacProjectsAggregateBoolExp>;
  suggestions?: InputMaybe<InsertAcquisStringComparisonExp>;
  supports_accelerated_integration?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacDonatorsConstraint {
  /** unique or primary key constraint on columns "id" */
  DonatorsPkey = 'donators_pkey'
}

export interface InsertAcquisCapacDonatorsInsertInput {
  address?: InputMaybe<Scalars['String']['input']>;
  chapter_donators?: InputMaybe<InsertAcquisCapacChapterDonatorArrRelInsertInput>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_function?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps_supported?: InputMaybe<Array<Scalars['numeric']['input']>>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<InsertAcquisCapacProjectsArrRelInsertInput>;
  suggestions?: InputMaybe<Scalars['String']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacDonatorsObjRelInsertInput {
  data: InsertAcquisCapacDonatorsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacDonatorsOnConflict>;
}

export interface InsertAcquisCapacDonatorsOnConflict {
  constraint: InsertAcquisCapacDonatorsConstraint;
  update_columns: Array<InsertAcquisCapacDonatorsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacDonatorsBoolExp>;
}

export enum InsertAcquisCapacDonatorsUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFunction = 'contact_function',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactPhone = 'contact_phone',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationStepsSupported = 'integration_steps_supported',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  Suggestions = 'suggestions',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacExpertsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacExpertsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacExpertsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacExpertsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  email?: InputMaybe<InsertAcquisStringComparisonExp>;
  expertise?: InputMaybe<InsertAcquisStringComparisonExp>;
  full_name?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  organization?: InputMaybe<InsertAcquisStringComparisonExp>;
  phone?: InputMaybe<InsertAcquisStringComparisonExp>;
  position?: InputMaybe<InsertAcquisStringComparisonExp>;
  trainings_experts?: InputMaybe<InsertAcquisCapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<InsertAcquisCapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacExpertsConstraint {
  /** unique or primary key constraint on columns "full_name" */
  ExpertsFullNameKey = 'experts_full_name_key',
  /** unique or primary key constraint on columns "id" */
  ExpertsPkey = 'experts_pkey'
}

export interface InsertAcquisCapacExpertsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  trainings_experts?: InputMaybe<InsertAcquisCapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacExpertsObjRelInsertInput {
  data: InsertAcquisCapacExpertsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacExpertsOnConflict>;
}

export interface InsertAcquisCapacExpertsOnConflict {
  constraint: InsertAcquisCapacExpertsConstraint;
  update_columns: Array<InsertAcquisCapacExpertsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacExpertsBoolExp>;
}

export enum InsertAcquisCapacExpertsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expertise = 'expertise',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacPriorityListsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacPriorityListsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacPriorityListsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacPriorityListsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  end_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_active?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  start_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacPriorityListsConstraint {
  /** unique or primary key constraint on columns "id" */
  PriorityListsPkey = 'priority_lists_pkey'
}

export interface InsertAcquisCapacPriorityListsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacPriorityListsObjRelInsertInput {
  data: InsertAcquisCapacPriorityListsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacPriorityListsOnConflict>;
}

export interface InsertAcquisCapacPriorityListsOnConflict {
  constraint: InsertAcquisCapacPriorityListsConstraint;
  update_columns: Array<InsertAcquisCapacPriorityListsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacPriorityListsBoolExp>;
}

export enum InsertAcquisCapacPriorityListsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacProjectBeneficiariesAggregateBoolExp {
  count?: InputMaybe<InsertAcquisCapacProjectBeneficiariesAggregateBoolExpCount>;
}

export interface InsertAcquisCapacProjectBeneficiariesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacProjectBeneficiariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacProjectBeneficiariesBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacProjectBeneficiariesArrRelInsertInput {
  data: Array<InsertAcquisCapacProjectBeneficiariesInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacProjectBeneficiariesOnConflict>;
}

export interface InsertAcquisCapacProjectBeneficiariesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacProjectBeneficiariesBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacProjectBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacProjectBeneficiariesBoolExp>>;
  beneficiary?: InputMaybe<InsertAcquisCapacBeneficiariesBoolExp>;
  beneficiary_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  project?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  project_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisCapacProjectBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectBeneficiariesPkey = 'project_beneficiaries_pkey'
}

export interface InsertAcquisCapacProjectBeneficiariesInsertInput {
  beneficiary?: InputMaybe<InsertAcquisCapacBeneficiariesObjRelInsertInput>;
  beneficiary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  project?: InputMaybe<InsertAcquisCapacProjectsObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisCapacProjectBeneficiariesOnConflict {
  constraint: InsertAcquisCapacProjectBeneficiariesConstraint;
  update_columns: Array<InsertAcquisCapacProjectBeneficiariesUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacProjectBeneficiariesBoolExp>;
}

export enum InsertAcquisCapacProjectBeneficiariesSelectColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export enum InsertAcquisCapacProjectBeneficiariesUpdateColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export interface InsertAcquisCapacProjectsAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisCapacProjectsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisCapacProjectsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisCapacProjectsAggregateBoolExpCount>;
}

export interface InsertAcquisCapacProjectsAggregateBoolExpBoolAnd {
  arguments: InsertAcquisCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisCapacProjectsAggregateBoolExpBoolOr {
  arguments: InsertAcquisCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisCapacProjectsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacProjectsArrRelInsertInput {
  data: Array<InsertAcquisCapacProjectsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacProjectsOnConflict>;
}

export interface InsertAcquisCapacProjectsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacProjectsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacProjectsBoolExp>>;
  activities?: InputMaybe<InsertAcquisStringComparisonExp>;
  beneficiary?: InputMaybe<InsertAcquisStringComparisonExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  disbursement?: InputMaybe<InsertAcquisNumericComparisonExp>;
  donator?: InputMaybe<InsertAcquisCapacDonatorsBoolExp>;
  donator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  end_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  expected_results?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  last_disbusement_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  other_suggestions?: InputMaybe<InsertAcquisStringComparisonExp>;
  outputs_delivered?: InputMaybe<InsertAcquisStringComparisonExp>;
  planned_new_support?: InputMaybe<InsertAcquisStringComparisonExp>;
  project_beneficiaries?: InputMaybe<InsertAcquisCapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<InsertAcquisCapacProjectBeneficiariesAggregateBoolExp>;
  start_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  supports_accelerated_integration?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  value?: InputMaybe<InsertAcquisNumericComparisonExp>;
}

export enum InsertAcquisCapacProjectsConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

export interface InsertAcquisCapacProjectsInsertInput {
  activities?: InputMaybe<Scalars['String']['input']>;
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  donator?: InputMaybe<InsertAcquisCapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  expected_results?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  last_disbusement_date?: InputMaybe<Scalars['date']['input']>;
  other_suggestions?: InputMaybe<Scalars['String']['input']>;
  outputs_delivered?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<InsertAcquisCapacProjectBeneficiariesArrRelInsertInput>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

export interface InsertAcquisCapacProjectsObjRelInsertInput {
  data: InsertAcquisCapacProjectsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacProjectsOnConflict>;
}

export interface InsertAcquisCapacProjectsOnConflict {
  constraint: InsertAcquisCapacProjectsConstraint;
  update_columns: Array<InsertAcquisCapacProjectsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
}

export enum InsertAcquisCapacProjectsSelectColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export enum InsertAcquisCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

export enum InsertAcquisCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

export enum InsertAcquisCapacProjectsUpdateColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export interface InsertAcquisCapacTaiexBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTaiexBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTaiexBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTaiexBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacTaiexConstraint {
  /** unique or primary key constraint on columns "id" */
  TaiexPkey = 'taiex_pkey'
}

export interface InsertAcquisCapacTaiexInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacTaiexObjRelInsertInput {
  data: InsertAcquisCapacTaiexInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacTaiexOnConflict>;
}

export interface InsertAcquisCapacTaiexOnConflict {
  constraint: InsertAcquisCapacTaiexConstraint;
  update_columns: Array<InsertAcquisCapacTaiexUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTaiexBoolExp>;
}

export enum InsertAcquisCapacTaiexUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacTrainingNeedsAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExpCount>;
}

export interface InsertAcquisCapacTrainingNeedsAggregateBoolExpBoolAnd {
  arguments: InsertAcquisCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisCapacTrainingNeedsAggregateBoolExpBoolOr {
  arguments: InsertAcquisCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisCapacTrainingNeedsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacTrainingNeedsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacTrainingNeedsArrRelInsertInput {
  data: Array<InsertAcquisCapacTrainingNeedsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacTrainingNeedsOnConflict>;
}

export interface InsertAcquisCapacTrainingNeedsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTrainingNeedsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTrainingNeedsBoolExp>>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  integration_steps?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  is_approved?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  need?: InputMaybe<InsertAcquisStringComparisonExp>;
  priority_list?: InputMaybe<InsertAcquisCapacPriorityListsBoolExp>;
  priority_list_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  taiex?: InputMaybe<InsertAcquisCapacTaiexBoolExp>;
  taiex_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  training?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  twinning?: InputMaybe<InsertAcquisCapacTwinningBoolExp>;
  twinning_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacTrainingNeedsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingNeedsPkey = 'training_needs_pkey'
}

export interface InsertAcquisCapacTrainingNeedsInsertInput {
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps?: InputMaybe<Scalars['jsonb']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  need?: InputMaybe<Scalars['String']['input']>;
  priority_list?: InputMaybe<InsertAcquisCapacPriorityListsObjRelInsertInput>;
  priority_list_id?: InputMaybe<Scalars['uuid']['input']>;
  taiex?: InputMaybe<InsertAcquisCapacTaiexObjRelInsertInput>;
  taiex_id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertAcquisCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  twinning?: InputMaybe<InsertAcquisCapacTwinningObjRelInsertInput>;
  twinning_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacTrainingNeedsOnConflict {
  constraint: InsertAcquisCapacTrainingNeedsConstraint;
  update_columns: Array<InsertAcquisCapacTrainingNeedsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
}

export enum InsertAcquisCapacTrainingNeedsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationSteps = 'integration_steps',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

export enum InsertAcquisCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

export enum InsertAcquisCapacTrainingNeedsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationSteps = 'integration_steps',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacTrainingParticipantsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisCapacTrainingParticipantsAggregateBoolExpCount>;
}

export interface InsertAcquisCapacTrainingParticipantsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacTrainingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingParticipantsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacTrainingParticipantsArrRelInsertInput {
  data: Array<InsertAcquisCapacTrainingParticipantsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacTrainingParticipantsOnConflict>;
}

export interface InsertAcquisCapacTrainingParticipantsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTrainingParticipantsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTrainingParticipantsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTrainingParticipantsBoolExp>>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  training?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisCapacTrainingParticipantsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey = 'training_sessions_pkey'
}

export interface InsertAcquisCapacTrainingParticipantsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertAcquisCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisCapacTrainingParticipantsOnConflict {
  constraint: InsertAcquisCapacTrainingParticipantsConstraint;
  update_columns: Array<InsertAcquisCapacTrainingParticipantsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTrainingParticipantsBoolExp>;
}

export enum InsertAcquisCapacTrainingParticipantsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertAcquisCapacTrainingParticipantsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertAcquisCapacTrainingSessionsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisCapacTrainingSessionsAggregateBoolExpCount>;
}

export interface InsertAcquisCapacTrainingSessionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacTrainingSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingSessionsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacTrainingSessionsArrRelInsertInput {
  data: Array<InsertAcquisCapacTrainingSessionsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacTrainingSessionsOnConflict>;
}

export interface InsertAcquisCapacTrainingSessionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTrainingSessionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTrainingSessionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTrainingSessionsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  duration_mins?: InputMaybe<InsertAcquisNumericComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  location?: InputMaybe<InsertAcquisStringComparisonExp>;
  participant_ids?: InputMaybe<InsertAcquisUuidArrayComparisonExp>;
  starting_datetime?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  training?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacTrainingSessionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey1 = 'training_sessions_pkey1'
}

export interface InsertAcquisCapacTrainingSessionsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  participant_ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  starting_datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  training?: InputMaybe<InsertAcquisCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacTrainingSessionsOnConflict {
  constraint: InsertAcquisCapacTrainingSessionsConstraint;
  update_columns: Array<InsertAcquisCapacTrainingSessionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTrainingSessionsBoolExp>;
}

export enum InsertAcquisCapacTrainingSessionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisCapacTrainingSessionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacTrainingsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisCapacTrainingsAggregateBoolExpCount>;
}

export interface InsertAcquisCapacTrainingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacTrainingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacTrainingsArrRelInsertInput {
  data: Array<InsertAcquisCapacTrainingsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacTrainingsOnConflict>;
}

export interface InsertAcquisCapacTrainingsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTrainingsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTrainingsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  description?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  location?: InputMaybe<InsertAcquisStringComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  trainers?: InputMaybe<InsertAcquisStringComparisonExp>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExp>;
  training_participants?: InputMaybe<InsertAcquisCapacTrainingParticipantsBoolExp>;
  training_participants_aggregate?: InputMaybe<InsertAcquisCapacTrainingParticipantsAggregateBoolExp>;
  training_sessions?: InputMaybe<InsertAcquisCapacTrainingSessionsBoolExp>;
  training_sessions_aggregate?: InputMaybe<InsertAcquisCapacTrainingSessionsAggregateBoolExp>;
  trainings_experts?: InputMaybe<InsertAcquisCapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<InsertAcquisCapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacTrainingsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsPkey = 'trainings_pkey'
}

export interface InsertAcquisCapacTrainingsExpertsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisCapacTrainingsExpertsAggregateBoolExpCount>;
}

export interface InsertAcquisCapacTrainingsExpertsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisCapacTrainingsExpertsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisCapacTrainingsExpertsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisCapacTrainingsExpertsArrRelInsertInput {
  data: Array<InsertAcquisCapacTrainingsExpertsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisCapacTrainingsExpertsOnConflict>;
}

export interface InsertAcquisCapacTrainingsExpertsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTrainingsExpertsBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTrainingsExpertsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTrainingsExpertsBoolExp>>;
  expert?: InputMaybe<InsertAcquisCapacExpertsBoolExp>;
  expert_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  training?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisCapacTrainingsExpertsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsExpertsPkey = 'trainings_experts_pkey'
}

export interface InsertAcquisCapacTrainingsExpertsInsertInput {
  expert?: InputMaybe<InsertAcquisCapacExpertsObjRelInsertInput>;
  expert_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertAcquisCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisCapacTrainingsExpertsOnConflict {
  constraint: InsertAcquisCapacTrainingsExpertsConstraint;
  update_columns: Array<InsertAcquisCapacTrainingsExpertsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTrainingsExpertsBoolExp>;
}

export enum InsertAcquisCapacTrainingsExpertsSelectColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export enum InsertAcquisCapacTrainingsExpertsUpdateColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export interface InsertAcquisCapacTrainingsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainers?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsArrRelInsertInput>;
  training_participants?: InputMaybe<InsertAcquisCapacTrainingParticipantsArrRelInsertInput>;
  training_sessions?: InputMaybe<InsertAcquisCapacTrainingSessionsArrRelInsertInput>;
  trainings_experts?: InputMaybe<InsertAcquisCapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacTrainingsObjRelInsertInput {
  data: InsertAcquisCapacTrainingsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacTrainingsOnConflict>;
}

export interface InsertAcquisCapacTrainingsOnConflict {
  constraint: InsertAcquisCapacTrainingsConstraint;
  update_columns: Array<InsertAcquisCapacTrainingsUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
}

export enum InsertAcquisCapacTrainingsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisCapacTrainingsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisCapacTwinningBoolExp {
  _and?: InputMaybe<Array<InsertAcquisCapacTwinningBoolExp>>;
  _not?: InputMaybe<InsertAcquisCapacTwinningBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisCapacTwinningBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisCapacTwinningConstraint {
  /** unique or primary key constraint on columns "id" */
  TwinningPkey = 'twinning_pkey'
}

export interface InsertAcquisCapacTwinningInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisCapacTwinningObjRelInsertInput {
  data: InsertAcquisCapacTwinningInsertInput;
  on_conflict?: InputMaybe<InsertAcquisCapacTwinningOnConflict>;
}

export interface InsertAcquisCapacTwinningOnConflict {
  constraint: InsertAcquisCapacTwinningConstraint;
  update_columns: Array<InsertAcquisCapacTwinningUpdateColumn>;
  where?: InputMaybe<InsertAcquisCapacTwinningBoolExp>;
}

export enum InsertAcquisCapacTwinningUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisChapterLeadInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisChapterLeadInstitutionsAggregateBoolExpCount>;
}

export interface InsertAcquisChapterLeadInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisChapterLeadInstitutionsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisChapterLeadInstitutionsArrRelInsertInput {
  data: Array<InsertAcquisChapterLeadInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisChapterLeadInstitutionsOnConflict>;
}

export interface InsertAcquisChapterLeadInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisChapterLeadInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisChapterLeadInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisChapterLeadInstitutionsBoolExp>>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  institution?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisChapterLeadInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterLeadInstitutionsPkey = 'chapter_lead_institutions_pkey'
}

export interface InsertAcquisChapterLeadInstitutionsInsertInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertAcquisInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisChapterLeadInstitutionsOnConflict {
  constraint: InsertAcquisChapterLeadInstitutionsConstraint;
  update_columns: Array<InsertAcquisChapterLeadInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisChapterLeadInstitutionsBoolExp>;
}

export enum InsertAcquisChapterLeadInstitutionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export enum InsertAcquisChapterLeadInstitutionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface InsertAcquisChaptersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisChaptersAggregateBoolExpCount>;
}

export interface InsertAcquisChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisChaptersArrRelInsertInput {
  data: Array<InsertAcquisChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisChaptersOnConflict>;
}

export interface InsertAcquisChaptersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisChaptersBoolExp>>;
  _not?: InputMaybe<InsertAcquisChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisChaptersBoolExp>>;
  acquis_chapters?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
  acquis_chapters_aggregate?: InputMaybe<InsertAcquisPkieAcquisChapterAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertAcquisBookmarksAggregateBoolExp>;
  chapter_donators?: InputMaybe<InsertAcquisCapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<InsertAcquisCapacChapterDonatorAggregateBoolExp>;
  chapter_lead_institutions?: InputMaybe<InsertAcquisChapterLeadInstitutionsBoolExp>;
  chapter_lead_institutions_aggregate?: InputMaybe<InsertAcquisChapterLeadInstitutionsAggregateBoolExp>;
  chapter_number?: InputMaybe<InsertAcquisStringComparisonExp>;
  chapter_title?: InputMaybe<InsertAcquisStringComparisonExp>;
  chapter_title_en?: InputMaybe<InsertAcquisStringComparisonExp>;
  cluster?: InputMaybe<InsertAcquisClustersBoolExp>;
  cluster_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  documents?: InputMaybe<InsertAcquisDocumentsBoolExp>;
  documents_aggregate?: InputMaybe<InsertAcquisDocumentsAggregateBoolExp>;
  gnpies?: InputMaybe<InsertAcquisGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertAcquisGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertAcquisIntComparisonExp>;
  integration_phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumBoolExp>;
  new_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnumComparisonExp>;
  projects?: InputMaybe<InsertAcquisCapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<InsertAcquisCapacProjectsAggregateBoolExp>;
  questions?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<InsertAcquisQuestionsAggregateBoolExp>;
  report_chapters?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertAcquisReportChaptersAggregateBoolExp>;
  sub_chapters?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapters_aggregate?: InputMaybe<InsertAcquisSubChaptersAggregateBoolExp>;
  topics?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topics_aggregate?: InputMaybe<InsertAcquisTopicsAggregateBoolExp>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExp>;
  work_plan_tasks?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertAcquisPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertAcquisChaptersConstraint {
  /** unique or primary key constraint on columns "chapter_number" */
  ChaptersChapterNumberKey = 'chapters_chapter_number_key',
  /** unique or primary key constraint on columns "chapter_title_en" */
  ChaptersChapterTitleEnKey = 'chapters_chapter_title_en_key',
  /** unique or primary key constraint on columns "chapter_title" */
  ChaptersChapterTitleKey = 'chapters_chapter_title_key',
  /** unique or primary key constraint on columns "new_id" */
  ChaptersNewIdKey = 'chapters_new_id_key',
  /** unique or primary key constraint on columns "id" */
  ChaptersPkey = 'chapters_pkey'
}

export interface InsertAcquisChaptersInsertInput {
  acquis_chapters?: InputMaybe<InsertAcquisPkieAcquisChapterArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertAcquisBookmarksArrRelInsertInput>;
  chapter_donators?: InputMaybe<InsertAcquisCapacChapterDonatorArrRelInsertInput>;
  chapter_lead_institutions?: InputMaybe<InsertAcquisChapterLeadInstitutionsArrRelInsertInput>;
  chapter_number?: InputMaybe<Scalars['String']['input']>;
  chapter_title?: InputMaybe<Scalars['String']['input']>;
  chapter_title_en?: InputMaybe<Scalars['String']['input']>;
  cluster?: InputMaybe<InsertAcquisClustersObjRelInsertInput>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  documents?: InputMaybe<InsertAcquisDocumentsArrRelInsertInput>;
  gnpies?: InputMaybe<InsertAcquisGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  integration_phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumObjRelInsertInput>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnum>;
  projects?: InputMaybe<InsertAcquisCapacProjectsArrRelInsertInput>;
  questions?: InputMaybe<InsertAcquisQuestionsArrRelInsertInput>;
  report_chapters?: InputMaybe<InsertAcquisReportChaptersArrRelInsertInput>;
  sub_chapters?: InputMaybe<InsertAcquisSubChaptersArrRelInsertInput>;
  topics?: InputMaybe<InsertAcquisTopicsArrRelInsertInput>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsArrRelInsertInput>;
  work_plan_tasks?: InputMaybe<InsertAcquisPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertAcquisChaptersObjRelInsertInput {
  data: InsertAcquisChaptersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisChaptersOnConflict>;
}

export interface InsertAcquisChaptersOnConflict {
  constraint: InsertAcquisChaptersConstraint;
  update_columns: Array<InsertAcquisChaptersUpdateColumn>;
  where?: InputMaybe<InsertAcquisChaptersBoolExp>;
}

export enum InsertAcquisChaptersSelectColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ChapterTitleEn = 'chapter_title_en',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Phase = 'phase'
}

export enum InsertAcquisChaptersUpdateColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ChapterTitleEn = 'chapter_title_en',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Phase = 'phase'
}

export interface InsertAcquisCitextComparisonExp {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  _regex?: InputMaybe<Scalars['citext']['input']>;
  _similar?: InputMaybe<Scalars['citext']['input']>;
}

export interface InsertAcquisClustersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisClustersBoolExp>>;
  _not?: InputMaybe<InsertAcquisClustersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisClustersBoolExp>>;
  chapters?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapters_aggregate?: InputMaybe<InsertAcquisChaptersAggregateBoolExp>;
  color?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisIntComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  title_en?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisClustersConstraint {
  /** unique or primary key constraint on columns "id" */
  ClustersPkey = 'clusters_pkey',
  /** unique or primary key constraint on columns "title_en" */
  ClustersTitleEnKey = 'clusters_title_en_key',
  /** unique or primary key constraint on columns "title" */
  ClustersTitleKey = 'clusters_title_key'
}

export interface InsertAcquisClustersInsertInput {
  chapters?: InputMaybe<InsertAcquisChaptersArrRelInsertInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisClustersObjRelInsertInput {
  data: InsertAcquisClustersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisClustersOnConflict>;
}

export interface InsertAcquisClustersOnConflict {
  constraint: InsertAcquisClustersConstraint;
  update_columns: Array<InsertAcquisClustersUpdateColumn>;
  where?: InputMaybe<InsertAcquisClustersBoolExp>;
}

export enum InsertAcquisClustersUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export interface InsertAcquisDateComparisonExp {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
}

export interface InsertAcquisDocumentsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisDocumentsAggregateBoolExpCount>;
}

export interface InsertAcquisDocumentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisDocumentsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisDocumentsArrRelInsertInput {
  data: Array<InsertAcquisDocumentsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisDocumentsOnConflict>;
}

export interface InsertAcquisDocumentsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisDocumentsBoolExp>>;
  _not?: InputMaybe<InsertAcquisDocumentsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisDocumentsBoolExp>>;
  c_total_document_definition_tasks?: InputMaybe<InsertAcquisBigintComparisonExp>;
  c_total_document_definition_tasks_done?: InputMaybe<InsertAcquisBigintComparisonExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  document_type?: InputMaybe<InsertAcquisTaskTypesEnumComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  phase?: InputMaybe<InsertAcquisStringComparisonExp>;
  status?: InputMaybe<InsertAcquisTaskStatusesEnumComparisonExp>;
  tasks?: InputMaybe<InsertAcquisTaskBoolExp>;
  tasks_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisDocumentsConstraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey'
}

export interface InsertAcquisDocumentsInsertInput {
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_type?: InputMaybe<InsertAcquisTaskTypesEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InsertAcquisTaskStatusesEnum>;
  tasks?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisDocumentsObjRelInsertInput {
  data: InsertAcquisDocumentsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisDocumentsOnConflict>;
}

export interface InsertAcquisDocumentsOnConflict {
  constraint: InsertAcquisDocumentsConstraint;
  update_columns: Array<InsertAcquisDocumentsUpdateColumn>;
  where?: InputMaybe<InsertAcquisDocumentsBoolExp>;
}

export enum InsertAcquisDocumentsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisDocumentsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisEventNotesAggregateBoolExp {
  count?: InputMaybe<InsertAcquisEventNotesAggregateBoolExpCount>;
}

export interface InsertAcquisEventNotesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisEventNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisEventNotesBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisEventNotesArrRelInsertInput {
  data: Array<InsertAcquisEventNotesInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisEventNotesOnConflict>;
}

export interface InsertAcquisEventNotesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisEventNotesBoolExp>>;
  _not?: InputMaybe<InsertAcquisEventNotesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisEventNotesBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  event?: InputMaybe<InsertAcquisEventsBoolExp>;
  event_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  note?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisEventNotesConstraint {
  /** unique or primary key constraint on columns "id" */
  EventNotesPkey = 'event_notes_pkey'
}

export interface InsertAcquisEventNotesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  event?: InputMaybe<InsertAcquisEventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
}

export interface InsertAcquisEventNotesOnConflict {
  constraint: InsertAcquisEventNotesConstraint;
  update_columns: Array<InsertAcquisEventNotesUpdateColumn>;
  where?: InputMaybe<InsertAcquisEventNotesBoolExp>;
}

export enum InsertAcquisEventNotesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisEventNotesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisEventTypologyEnum {
  GrupetNderinstitucionalePuneIntegrim = 'GRUPET_NDERINSTITUCIONALE_PUNE_INTEGRIM',
  GrupiNegociator = 'GRUPI_NEGOCIATOR',
  PlatformaPartneritetIntegrimiEvropian = 'PLATFORMA_PARTNERITET_INTEGRIMI_EVROPIAN',
  RrjetiIntegrimitEvropian = 'RRJETI_INTEGRIMIT_EVROPIAN',
  Tjeter = 'TJETER'
}

export interface InsertAcquisEventTypologyEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisEventTypologyEnum>;
  _in?: InputMaybe<Array<InsertAcquisEventTypologyEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisEventTypologyEnum>;
  _nin?: InputMaybe<Array<InsertAcquisEventTypologyEnum>>;
}

export interface InsertAcquisEventUsersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisEventUsersAggregateBoolExpCount>;
}

export interface InsertAcquisEventUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisEventUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisEventUsersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisEventUsersArrRelInsertInput {
  data: Array<InsertAcquisEventUsersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisEventUsersOnConflict>;
}

export interface InsertAcquisEventUsersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisEventUsersBoolExp>>;
  _not?: InputMaybe<InsertAcquisEventUsersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisEventUsersBoolExp>>;
  event?: InputMaybe<InsertAcquisEventsBoolExp>;
  event_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisEventUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  EventUsersPkey = 'event_users_pkey'
}

export interface InsertAcquisEventUsersInsertInput {
  event?: InputMaybe<InsertAcquisEventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisEventUsersOnConflict {
  constraint: InsertAcquisEventUsersConstraint;
  update_columns: Array<InsertAcquisEventUsersUpdateColumn>;
  where?: InputMaybe<InsertAcquisEventUsersBoolExp>;
}

export enum InsertAcquisEventUsersSelectColumn {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertAcquisEventUsersUpdateColumn {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertAcquisEventsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisEventsBoolExp>>;
  _not?: InputMaybe<InsertAcquisEventsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisEventsBoolExp>>;
  content?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  end_date?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  event_date?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  event_date_deleted?: InputMaybe<InsertAcquisTimetzComparisonExp>;
  event_notes?: InputMaybe<InsertAcquisEventNotesBoolExp>;
  event_notes_aggregate?: InputMaybe<InsertAcquisEventNotesAggregateBoolExp>;
  event_users?: InputMaybe<InsertAcquisEventUsersBoolExp>;
  event_users_aggregate?: InputMaybe<InsertAcquisEventUsersAggregateBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  tasks?: InputMaybe<InsertAcquisTaskBoolExp>;
  tasks_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  typology?: InputMaybe<InsertAcquisEventTypologyEnumComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisEventsConstraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

export interface InsertAcquisEventsInsertInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date_deleted?: InputMaybe<Scalars['timetz']['input']>;
  event_notes?: InputMaybe<InsertAcquisEventNotesArrRelInsertInput>;
  event_users?: InputMaybe<InsertAcquisEventUsersArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tasks?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  typology?: InputMaybe<InsertAcquisEventTypologyEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
}

export interface InsertAcquisEventsObjRelInsertInput {
  data: InsertAcquisEventsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisEventsOnConflict>;
}

export interface InsertAcquisEventsOnConflict {
  constraint: InsertAcquisEventsConstraint;
  update_columns: Array<InsertAcquisEventsUpdateColumn>;
  where?: InputMaybe<InsertAcquisEventsBoolExp>;
}

export enum InsertAcquisEventsUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventDateDeleted = 'event_date_deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Typology = 'typology',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisGnpieAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisGnpieAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisGnpieAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisGnpieAggregateBoolExpCount>;
}

export interface InsertAcquisGnpieAggregateBoolExpBoolAnd {
  arguments: InsertAcquisGnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisGnpieBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisGnpieAggregateBoolExpBoolOr {
  arguments: InsertAcquisGnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisGnpieBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisGnpieAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisGnpieSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisGnpieBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisGnpieArrRelInsertInput {
  data: Array<InsertAcquisGnpieInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisGnpieOnConflict>;
}

export interface InsertAcquisGnpieBoolExp {
  _and?: InputMaybe<Array<InsertAcquisGnpieBoolExp>>;
  _not?: InputMaybe<InsertAcquisGnpieBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisGnpieBoolExp>>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  cluster?: InputMaybe<InsertAcquisClustersBoolExp>;
  cluster_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_by_role?: InputMaybe<InsertAcquisAuthrolesEnumComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_main_negotiator?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  role?: InputMaybe<InsertAcquisAuthrolesEnumComparisonExp>;
  roleByRole?: InputMaybe<InsertAcquisAuthrolesBoolExp>;
  role_modules?: InputMaybe<InsertAcquisStringComparisonExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  topic?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topic_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisGnpieConstraint {
  /** unique or primary key constraint on columns "id" */
  GnpiePkey = 'gnpie_pkey'
}

export interface InsertAcquisGnpieInsertInput {
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  cluster?: InputMaybe<InsertAcquisClustersObjRelInsertInput>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_role?: InputMaybe<InsertAcquisAuthrolesEnum>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_main_negotiator?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<InsertAcquisAuthrolesEnum>;
  roleByRole?: InputMaybe<InsertAcquisAuthrolesObjRelInsertInput>;
  role_modules?: InputMaybe<Scalars['String']['input']>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic?: InputMaybe<InsertAcquisTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisGnpieOnConflict {
  constraint: InsertAcquisGnpieConstraint;
  update_columns: Array<InsertAcquisGnpieUpdateColumn>;
  where?: InputMaybe<InsertAcquisGnpieBoolExp>;
}

export enum InsertAcquisGnpieSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedByRole = 'created_by_role',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  RoleModules = 'role_modules',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertAcquisGnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

export enum InsertAcquisGnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

export enum InsertAcquisGnpieUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedByRole = 'created_by_role',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  RoleModules = 'role_modules',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertAcquisInstitutionsAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisInstitutionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisInstitutionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisInstitutionsAggregateBoolExpCount>;
}

export interface InsertAcquisInstitutionsAggregateBoolExpBoolAnd {
  arguments: InsertAcquisInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisInstitutionsAggregateBoolExpBoolOr {
  arguments: InsertAcquisInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisInstitutionsArrRelInsertInput {
  data: Array<InsertAcquisInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisInstitutionsOnConflict>;
}

export interface InsertAcquisInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisInstitutionsBoolExp>>;
  acquis_institutions?: InputMaybe<InsertAcquisPkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<InsertAcquisPkieAcquisInstitutionsAggregateBoolExp>;
  child_institutions?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  child_institutions_aggregate?: InputMaybe<InsertAcquisInstitutionsAggregateBoolExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
  name?: InputMaybe<InsertAcquisStringComparisonExp>;
  parent_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  parent_institution?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  qbz_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  short_name?: InputMaybe<InsertAcquisStringComparisonExp>;
  type?: InputMaybe<InsertAcquisTypeInstitutionsEnumComparisonExp>;
  type_institution?: InputMaybe<InsertAcquisTypeInstitutionsBoolExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  users?: InputMaybe<InsertAcquisUsersBoolExp>;
  users_aggregate?: InputMaybe<InsertAcquisUsersAggregateBoolExp>;
}

export enum InsertAcquisInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  InstitutionsPkey = 'institutions_pkey'
}

export interface InsertAcquisInstitutionsInsertInput {
  acquis_institutions?: InputMaybe<InsertAcquisPkieAcquisInstitutionsArrRelInsertInput>;
  child_institutions?: InputMaybe<InsertAcquisInstitutionsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_institution?: InputMaybe<InsertAcquisInstitutionsObjRelInsertInput>;
  qbz_id?: InputMaybe<Scalars['Int']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InsertAcquisTypeInstitutionsEnum>;
  type_institution?: InputMaybe<InsertAcquisTypeInstitutionsObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<InsertAcquisUsersArrRelInsertInput>;
}

export interface InsertAcquisInstitutionsObjRelInsertInput {
  data: InsertAcquisInstitutionsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisInstitutionsOnConflict>;
}

export interface InsertAcquisInstitutionsOnConflict {
  constraint: InsertAcquisInstitutionsConstraint;
  update_columns: Array<InsertAcquisInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
}

export enum InsertAcquisInstitutionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisInstitutionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisIntComparisonExp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface InsertAcquisIntegrationPhasesEnumBoolExp {
  _and?: InputMaybe<Array<InsertAcquisIntegrationPhasesEnumBoolExp>>;
  _not?: InputMaybe<InsertAcquisIntegrationPhasesEnumBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisIntegrationPhasesEnumBoolExp>>;
  phase?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisIntegrationPhasesEnumConstraint {
  /** unique or primary key constraint on columns "phase" */
  IntegrationPhasesPkey = 'integration_phases_pkey'
}

export enum InsertAcquisIntegrationPhasesEnumEnum {
  ClosingBenchmarks = 'CLOSING_BENCHMARKS',
  IntermediarySteps = 'INTERMEDIARY_STEPS',
  Membership = 'MEMBERSHIP',
  OpeningBenchmarcs = 'OPENING_BENCHMARCS',
  OpeningNegotiation = 'OPENING_NEGOTIATION',
  PositionNegotiation = 'POSITION_NEGOTIATION',
  Screening = 'SCREENING'
}

export interface InsertAcquisIntegrationPhasesEnumEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnum>;
  _in?: InputMaybe<Array<InsertAcquisIntegrationPhasesEnumEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnum>;
  _nin?: InputMaybe<Array<InsertAcquisIntegrationPhasesEnumEnum>>;
}

export interface InsertAcquisIntegrationPhasesEnumInsertInput {
  phase?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisIntegrationPhasesEnumObjRelInsertInput {
  data: InsertAcquisIntegrationPhasesEnumInsertInput;
  on_conflict?: InputMaybe<InsertAcquisIntegrationPhasesEnumOnConflict>;
}

export interface InsertAcquisIntegrationPhasesEnumOnConflict {
  constraint: InsertAcquisIntegrationPhasesEnumConstraint;
  update_columns: Array<InsertAcquisIntegrationPhasesEnumUpdateColumn>;
  where?: InputMaybe<InsertAcquisIntegrationPhasesEnumBoolExp>;
}

export enum InsertAcquisIntegrationPhasesEnumUpdateColumn {
  /** column name */
  Phase = 'phase'
}

export interface InsertAcquisJsonbCastExp {
  String?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export interface InsertAcquisJsonbComparisonExp {
  _cast?: InputMaybe<InsertAcquisJsonbCastExp>;
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  _has_key?: InputMaybe<Scalars['String']['input']>;
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

export enum InsertAcquisMeetingAttendanceEnum {
  Accepted = 'ACCEPTED',
  NoAnswer = 'NO_ANSWER',
  Refused = 'REFUSED'
}

export interface InsertAcquisMeetingAttendanceEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisMeetingAttendanceEnum>;
  _in?: InputMaybe<Array<InsertAcquisMeetingAttendanceEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisMeetingAttendanceEnum>;
  _nin?: InputMaybe<Array<InsertAcquisMeetingAttendanceEnum>>;
}

export interface InsertAcquisMeetingNotesAggregateBoolExp {
  count?: InputMaybe<InsertAcquisMeetingNotesAggregateBoolExpCount>;
}

export interface InsertAcquisMeetingNotesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisMeetingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisMeetingNotesBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisMeetingNotesArrRelInsertInput {
  data: Array<InsertAcquisMeetingNotesInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisMeetingNotesOnConflict>;
}

export interface InsertAcquisMeetingNotesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisMeetingNotesBoolExp>>;
  _not?: InputMaybe<InsertAcquisMeetingNotesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisMeetingNotesBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  meeting?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  note?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisMeetingNotesConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingNotesPkey = 'meeting_notes_pkey'
}

export interface InsertAcquisMeetingNotesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertAcquisMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
}

export interface InsertAcquisMeetingNotesOnConflict {
  constraint: InsertAcquisMeetingNotesConstraint;
  update_columns: Array<InsertAcquisMeetingNotesUpdateColumn>;
  where?: InputMaybe<InsertAcquisMeetingNotesBoolExp>;
}

export enum InsertAcquisMeetingNotesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisMeetingNotesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisMeetingUsersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisMeetingUsersAggregateBoolExpCount>;
}

export interface InsertAcquisMeetingUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisMeetingUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisMeetingUsersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisMeetingUsersArrRelInsertInput {
  data: Array<InsertAcquisMeetingUsersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisMeetingUsersOnConflict>;
}

export interface InsertAcquisMeetingUsersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisMeetingUsersBoolExp>>;
  _not?: InputMaybe<InsertAcquisMeetingUsersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisMeetingUsersBoolExp>>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  meeting?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  meeting_attendence?: InputMaybe<InsertAcquisMeetingAttendanceEnumComparisonExp>;
  meeting_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisMeetingUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingUsersPkey = 'meeting_users_pkey'
}

export interface InsertAcquisMeetingUsersInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertAcquisMeetingsObjRelInsertInput>;
  meeting_attendence?: InputMaybe<InsertAcquisMeetingAttendanceEnum>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisMeetingUsersOnConflict {
  constraint: InsertAcquisMeetingUsersConstraint;
  update_columns: Array<InsertAcquisMeetingUsersUpdateColumn>;
  where?: InputMaybe<InsertAcquisMeetingUsersBoolExp>;
}

export enum InsertAcquisMeetingUsersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingAttendence = 'meeting_attendence',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertAcquisMeetingUsersUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingAttendence = 'meeting_attendence',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertAcquisMeetingsAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisMeetingsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisMeetingsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisMeetingsAggregateBoolExpCount>;
}

export interface InsertAcquisMeetingsAggregateBoolExpBoolAnd {
  arguments: InsertAcquisMeetingsSelectColumnMeetingsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisMeetingsAggregateBoolExpBoolOr {
  arguments: InsertAcquisMeetingsSelectColumnMeetingsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisMeetingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisMeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisMeetingsArrRelInsertInput {
  data: Array<InsertAcquisMeetingsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisMeetingsOnConflict>;
}

export interface InsertAcquisMeetingsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisMeetingsBoolExp>>;
  _not?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisMeetingsBoolExp>>;
  content?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  end_date?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  meeting_date?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  meeting_notes?: InputMaybe<InsertAcquisMeetingNotesBoolExp>;
  meeting_notes_aggregate?: InputMaybe<InsertAcquisMeetingNotesAggregateBoolExp>;
  meeting_users?: InputMaybe<InsertAcquisMeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<InsertAcquisMeetingUsersAggregateBoolExp>;
  minutes_of_meeting?: InputMaybe<InsertAcquisStringComparisonExp>;
  notified?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  tasks?: InputMaybe<InsertAcquisTaskBoolExp>;
  tasks_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisMeetingsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingsPkey = 'meetings_pkey'
}

export interface InsertAcquisMeetingsInsertInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_date?: InputMaybe<Scalars['timestamptz']['input']>;
  meeting_notes?: InputMaybe<InsertAcquisMeetingNotesArrRelInsertInput>;
  meeting_users?: InputMaybe<InsertAcquisMeetingUsersArrRelInsertInput>;
  minutes_of_meeting?: InputMaybe<Scalars['String']['input']>;
  notified?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
}

export interface InsertAcquisMeetingsObjRelInsertInput {
  data: InsertAcquisMeetingsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisMeetingsOnConflict>;
}

export interface InsertAcquisMeetingsOnConflict {
  constraint: InsertAcquisMeetingsConstraint;
  update_columns: Array<InsertAcquisMeetingsUpdateColumn>;
  where?: InputMaybe<InsertAcquisMeetingsBoolExp>;
}

export enum InsertAcquisMeetingsSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Notified = 'notified',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisMeetingsSelectColumnMeetingsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Notified = 'notified'
}

export enum InsertAcquisMeetingsSelectColumnMeetingsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Notified = 'notified'
}

export enum InsertAcquisMeetingsUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Notified = 'notified',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisNumericArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _contains?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _eq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _lte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _neq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
}

export interface InsertAcquisNumericComparisonExp {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
}

export interface InsertAcquisOutput {
  __typename?: 'InsertAcquisOutput';
  author?: Maybe<Scalars['String']['output']>;
  celex: Scalars['String']['output'];
  chapter_id?: Maybe<Scalars['Int']['output']>;
  current_consolidated_version?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['date']['output']>;
  date_of_effect?: Maybe<Scalars['String']['output']>;
  date_of_end_of_validity?: Maybe<Scalars['String']['output']>;
  date_of_publication?: Maybe<Scalars['String']['output']>;
  date_of_transposition?: Maybe<Scalars['String']['output']>;
  directory_code?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  eurovoc_descriptor?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Scalars['String']['output']>;
  in_force_indicator?: Maybe<Scalars['Boolean']['output']>;
  is_aligned?: Maybe<Scalars['Boolean']['output']>;
  latest_conslolidated_version?: Maybe<Scalars['String']['output']>;
  latest_modification_notice?: Maybe<Scalars['String']['output']>;
  publication_reference?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  subject_matter?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  to_be_transposed?: Maybe<Scalars['Boolean']['output']>;
  to_be_transposed_general_date?: Maybe<Scalars['date']['output']>;
  to_be_transposed_work_plan_task_date?: Maybe<Scalars['date']['output']>;
}

export interface InsertAcquisPkieAcquisAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisPkieAcquisAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisPkieAcquisAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisPkieAcquisAggregateBoolExpCount>;
}

export interface InsertAcquisPkieAcquisAggregateBoolExpBoolAnd {
  arguments: InsertAcquisPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieAcquisAggregateBoolExpBoolOr {
  arguments: InsertAcquisPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieAcquisAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieAcquisSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieAcquisArrRelInsertInput {
  data: Array<InsertAcquisPkieAcquisInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisOnConflict>;
}

export interface InsertAcquisPkieAcquisBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieAcquisBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieAcquisBoolExp>>;
  acquis_chapters?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
  acquis_chapters_aggregate?: InputMaybe<InsertAcquisPkieAcquisChapterAggregateBoolExp>;
  acquis_institutions?: InputMaybe<InsertAcquisPkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<InsertAcquisPkieAcquisInstitutionsAggregateBoolExp>;
  acquis_national_acts?: InputMaybe<InsertAcquisPkieAcquisNationalActsBoolExp>;
  acquis_national_acts_aggregate?: InputMaybe<InsertAcquisPkieAcquisNationalActsAggregateBoolExp>;
  author?: InputMaybe<InsertAcquisStringComparisonExp>;
  celex?: InputMaybe<InsertAcquisStringComparisonExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  current_consolidated_version?: InputMaybe<InsertAcquisStringComparisonExp>;
  date_created?: InputMaybe<InsertAcquisDateComparisonExp>;
  date_of_effect?: InputMaybe<InsertAcquisStringComparisonExp>;
  date_of_end_of_validity?: InputMaybe<InsertAcquisStringComparisonExp>;
  date_of_publication?: InputMaybe<InsertAcquisStringComparisonExp>;
  date_of_transposition?: InputMaybe<InsertAcquisStringComparisonExp>;
  directory_code?: InputMaybe<InsertAcquisStringComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  eli?: InputMaybe<InsertAcquisStringComparisonExp>;
  eurovoc_descriptor?: InputMaybe<InsertAcquisStringComparisonExp>;
  form?: InputMaybe<InsertAcquisStringComparisonExp>;
  in_force_indicator?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  is_aligned?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  latest_conslolidated_version?: InputMaybe<InsertAcquisStringComparisonExp>;
  latest_modification_notice?: InputMaybe<InsertAcquisStringComparisonExp>;
  level_of_alignment?: InputMaybe<InsertAcquisPkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<InsertAcquisPkieLevelOfAlignmentBoolExp>;
  proces_pune_iagas?: InputMaybe<InsertAcquisPkieProcesPuneIagaBoolExp>;
  proces_pune_iagas_aggregate?: InputMaybe<InsertAcquisPkieProcesPuneIagaAggregateBoolExp>;
  proces_pune_lgas?: InputMaybe<InsertAcquisPkieProcesPuneLgaBoolExp>;
  proces_pune_lgas_aggregate?: InputMaybe<InsertAcquisPkieProcesPuneLgaAggregateBoolExp>;
  publication_reference?: InputMaybe<InsertAcquisStringComparisonExp>;
  reference?: InputMaybe<InsertAcquisStringComparisonExp>;
  subject_matter?: InputMaybe<InsertAcquisStringComparisonExp>;
  subtitle?: InputMaybe<InsertAcquisStringComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  to_be_transposed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  to_be_transposed_general_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  to_be_transposed_work_plan_task_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  type?: InputMaybe<InsertAcquisPkieAcquisTypeEnumComparisonExp>;
}

export interface InsertAcquisPkieAcquisChapterAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieAcquisChapterAggregateBoolExpCount>;
}

export interface InsertAcquisPkieAcquisChapterAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieAcquisChapterSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieAcquisChapterArrRelInsertInput {
  data: Array<InsertAcquisPkieAcquisChapterInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisChapterOnConflict>;
}

export interface InsertAcquisPkieAcquisChapterBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieAcquisChapterBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieAcquisChapterBoolExp>>;
  acqui?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  acquis_work_plan_tasks?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  acquis_work_plan_tasks_aggregate?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExp>;
  celex?: InputMaybe<InsertAcquisStringComparisonExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  topic?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topic_id?: InputMaybe<InsertAcquisIntComparisonExp>;
}

export enum InsertAcquisPkieAcquisChapterConstraint {
  /** unique or primary key constraint on columns "celex", "chapter_id" */
  AcquisChapterChapterIdCelexKey = 'acquis_chapter_chapter_id_celex_key',
  /** unique or primary key constraint on columns "id" */
  AcquisChapterPkey = 'acquis_chapter_pkey'
}

export interface InsertAcquisPkieAcquisChapterInsertInput {
  acqui?: InputMaybe<InsertAcquisPkieAcquisObjRelInsertInput>;
  acquis_work_plan_tasks?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskArrRelInsertInput>;
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic?: InputMaybe<InsertAcquisTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

export interface InsertAcquisPkieAcquisChapterObjRelInsertInput {
  data: InsertAcquisPkieAcquisChapterInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisChapterOnConflict>;
}

export interface InsertAcquisPkieAcquisChapterOnConflict {
  constraint: InsertAcquisPkieAcquisChapterConstraint;
  update_columns: Array<InsertAcquisPkieAcquisChapterUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
}

export enum InsertAcquisPkieAcquisChapterSelectColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id'
}

export enum InsertAcquisPkieAcquisChapterUpdateColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id'
}

export enum InsertAcquisPkieAcquisConstraint {
  /** unique or primary key constraint on columns "celex" */
  AcquisPkey = 'acquis_pkey'
}

export interface InsertAcquisPkieAcquisInsertInput {
  acquis_chapters?: InputMaybe<InsertAcquisPkieAcquisChapterArrRelInsertInput>;
  acquis_institutions?: InputMaybe<InsertAcquisPkieAcquisInstitutionsArrRelInsertInput>;
  acquis_national_acts?: InputMaybe<InsertAcquisPkieAcquisNationalActsArrRelInsertInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  current_consolidated_version?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['date']['input']>;
  date_of_effect?: InputMaybe<Scalars['String']['input']>;
  date_of_end_of_validity?: InputMaybe<Scalars['String']['input']>;
  date_of_publication?: InputMaybe<Scalars['String']['input']>;
  date_of_transposition?: InputMaybe<Scalars['String']['input']>;
  directory_code?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  eurovoc_descriptor?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  in_force_indicator?: InputMaybe<Scalars['Boolean']['input']>;
  is_aligned?: InputMaybe<Scalars['Boolean']['input']>;
  latest_conslolidated_version?: InputMaybe<Scalars['String']['input']>;
  latest_modification_notice?: InputMaybe<Scalars['String']['input']>;
  level_of_alignment?: InputMaybe<InsertAcquisPkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<InsertAcquisPkieLevelOfAlignmentObjRelInsertInput>;
  proces_pune_iagas?: InputMaybe<InsertAcquisPkieProcesPuneIagaArrRelInsertInput>;
  proces_pune_lgas?: InputMaybe<InsertAcquisPkieProcesPuneLgaArrRelInsertInput>;
  publication_reference?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  subject_matter?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to_be_transposed?: InputMaybe<Scalars['Boolean']['input']>;
  to_be_transposed_general_date?: InputMaybe<Scalars['date']['input']>;
  to_be_transposed_work_plan_task_date?: InputMaybe<Scalars['date']['input']>;
  type?: InputMaybe<InsertAcquisPkieAcquisTypeEnum>;
}

export interface InsertAcquisPkieAcquisInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieAcquisInstitutionsAggregateBoolExpCount>;
}

export interface InsertAcquisPkieAcquisInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieAcquisInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisInstitutionsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieAcquisInstitutionsArrRelInsertInput {
  data: Array<InsertAcquisPkieAcquisInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisInstitutionsOnConflict>;
}

export interface InsertAcquisPkieAcquisInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieAcquisInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieAcquisInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieAcquisInstitutionsBoolExp>>;
  acqui?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  acqui_celex?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  institution?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisPkieAcquisInstitutionsConstraint {
  /** unique or primary key constraint on columns "acqui_celex", "institution_id" */
  AcquisInstitutionsInstitutionIdAcquiCelexKey = 'acquis_institutions_institution_id_acqui_celex_key',
  /** unique or primary key constraint on columns "id" */
  AcquisInstitutionsPkey = 'acquis_institutions_pkey'
}

export interface InsertAcquisPkieAcquisInstitutionsInsertInput {
  acqui?: InputMaybe<InsertAcquisPkieAcquisObjRelInsertInput>;
  acqui_celex?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertAcquisInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisPkieAcquisInstitutionsOnConflict {
  constraint: InsertAcquisPkieAcquisInstitutionsConstraint;
  update_columns: Array<InsertAcquisPkieAcquisInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieAcquisInstitutionsBoolExp>;
}

export enum InsertAcquisPkieAcquisInstitutionsSelectColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export enum InsertAcquisPkieAcquisInstitutionsUpdateColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface InsertAcquisPkieAcquisNationalActsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieAcquisNationalActsAggregateBoolExpCount>;
}

export interface InsertAcquisPkieAcquisNationalActsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieAcquisNationalActsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisNationalActsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieAcquisNationalActsArrRelInsertInput {
  data: Array<InsertAcquisPkieAcquisNationalActsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisNationalActsOnConflict>;
}

export interface InsertAcquisPkieAcquisNationalActsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieAcquisNationalActsBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieAcquisNationalActsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieAcquisNationalActsBoolExp>>;
  acqui?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  acquis_celex?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  national_act?: InputMaybe<InsertAcquisPkieNationalActsBoolExp>;
  national_act_eli?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieAcquisNationalActsConstraint {
  /** unique or primary key constraint on columns "national_act_eli", "acquis_celex" */
  AcquisNationalActsNationalActEliAcquisCelexKey = 'acquis_national_acts_national_act_eli_acquis_celex_key',
  /** unique or primary key constraint on columns "id" */
  AcquisNationalActsPkey = 'acquis_national_acts_pkey'
}

export interface InsertAcquisPkieAcquisNationalActsInsertInput {
  acqui?: InputMaybe<InsertAcquisPkieAcquisObjRelInsertInput>;
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  national_act?: InputMaybe<InsertAcquisPkieNationalActsObjRelInsertInput>;
  national_act_eli?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieAcquisNationalActsOnConflict {
  constraint: InsertAcquisPkieAcquisNationalActsConstraint;
  update_columns: Array<InsertAcquisPkieAcquisNationalActsUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieAcquisNationalActsBoolExp>;
}

export enum InsertAcquisPkieAcquisNationalActsSelectColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export enum InsertAcquisPkieAcquisNationalActsUpdateColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export interface InsertAcquisPkieAcquisObjRelInsertInput {
  data: InsertAcquisPkieAcquisInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisOnConflict>;
}

export interface InsertAcquisPkieAcquisOnConflict {
  constraint: InsertAcquisPkieAcquisConstraint;
  update_columns: Array<InsertAcquisPkieAcquisUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
}

export enum InsertAcquisPkieAcquisSelectColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  Type = 'type'
}

export enum InsertAcquisPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

export enum InsertAcquisPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

export enum InsertAcquisPkieAcquisTypeEnum {
  Hard = 'HARD',
  Soft = 'SOFT'
}

export interface InsertAcquisPkieAcquisTypeEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieAcquisTypeEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieAcquisTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieAcquisTypeEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieAcquisTypeEnum>>;
}

export enum InsertAcquisPkieAcquisUpdateColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  Type = 'type'
}

export interface InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExpCount>;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExpBoolAnd {
  arguments: InsertAcquisPkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExpBoolOr {
  arguments: InsertAcquisPkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieAcquisWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskArrRelInsertInput {
  data: Array<InsertAcquisPkieAcquisWorkPlanTaskInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskOnConflict>;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>>;
  acquis_chapter?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
  acquis_chapter_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
  planed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisPkieAcquisWorkPlanTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  AcquisWorkPlanTaskPkey = 'acquis_work_plan_task_pkey',
  /** unique or primary key constraint on columns "work_plan_task_id", "acquis_chapter_id" */
  AcquisWorkPlanTaskWorkPlanTaskIdAcquisChapterIdKey = 'acquis_work_plan_task_work_plan_task_id_acquis_chapter_id_key'
}

export interface InsertAcquisPkieAcquisWorkPlanTaskInsertInput {
  acquis_chapter?: InputMaybe<InsertAcquisPkieAcquisChapterObjRelInsertInput>;
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
  planed?: InputMaybe<Scalars['Boolean']['input']>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskObjRelInsertInput {
  data: InsertAcquisPkieAcquisWorkPlanTaskInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskOnConflict>;
}

export interface InsertAcquisPkieAcquisWorkPlanTaskOnConflict {
  constraint: InsertAcquisPkieAcquisWorkPlanTaskConstraint;
  update_columns: Array<InsertAcquisPkieAcquisWorkPlanTaskUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
}

export enum InsertAcquisPkieAcquisWorkPlanTaskSelectColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Planed = 'planed',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertAcquisPkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Planed = 'planed'
}

export enum InsertAcquisPkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Planed = 'planed'
}

export enum InsertAcquisPkieAcquisWorkPlanTaskUpdateColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Planed = 'planed',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertAcquisPkieActTypeBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieActTypeBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieActTypeBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieActTypeBoolExp>>;
  code?: InputMaybe<InsertAcquisStringComparisonExp>;
  name?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieActTypeConstraint {
  /** unique or primary key constraint on columns "code" */
  ActTypePkey = 'act_type_pkey'
}

export enum InsertAcquisPkieActTypeEnum {
  /** Akt normativ */
  AktNormativ = 'akt_normativ',
  /** Autorizim */
  Autorizim = 'autorizim',
  /** Bibliografi */
  Bibliografi = 'bibliografi',
  /** Deklaratë */
  Deklarate = 'deklarate',
  /** Dekret */
  Dekret = 'dekret',
  /** Kërkesë */
  Kerkese = 'kerkese',
  /** Konventë */
  Konvente = 'konvente',
  /** Licensë */
  Licenca = 'licenca',
  /** Ligj */
  Ligj = 'ligj',
  /** Marrëveshje */
  Marreveshje = 'marreveshje',
  /** Memorandum */
  Memorandum = 'memorandum',
  /** Njoftim */
  Njoftim = 'njoftim',
  /** Rezolutë */
  Rezolute = 'rezolute',
  /** Rregullore */
  Rregullore = 'rregullore',
  /** Udhezim */
  Udhezim = 'udhezim',
  /** Urdhër */
  Urdher = 'urdher',
  /** Vendim */
  Vendim = 'vendim',
  /** Vendim i shkurtuar */
  VendimIShkurtuar = 'vendim_i_shkurtuar'
}

export interface InsertAcquisPkieActTypeEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieActTypeEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieActTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieActTypeEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieActTypeEnum>>;
}

export interface InsertAcquisPkieActTypeInsertInput {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieActTypeObjRelInsertInput {
  data: InsertAcquisPkieActTypeInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieActTypeOnConflict>;
}

export interface InsertAcquisPkieActTypeOnConflict {
  constraint: InsertAcquisPkieActTypeConstraint;
  update_columns: Array<InsertAcquisPkieActTypeUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieActTypeBoolExp>;
}

export enum InsertAcquisPkieActTypeUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

export interface InsertAcquisPkieLevelOfAlignmentBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieLevelOfAlignmentBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieLevelOfAlignmentBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieLevelOfAlignmentBoolExp>>;
  acquis?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertAcquisPkieAcquisAggregateBoolExp>;
  level?: InputMaybe<InsertAcquisStringComparisonExp>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
}

export enum InsertAcquisPkieLevelOfAlignmentConstraint {
  /** unique or primary key constraint on columns "level" */
  LevelOfAlignmentPkey = 'level_of_alignment_pkey'
}

export enum InsertAcquisPkieLevelOfAlignmentEnum {
  ELarte = 'E_LARTE',
  EMire = 'E_MIRE',
  EModeruar = 'E_MODERUAR',
  EPlote = 'E_PLOTE',
  Fillestare = 'FILLESTARE',
  Na = 'NA'
}

export interface InsertAcquisPkieLevelOfAlignmentEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieLevelOfAlignmentEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieLevelOfAlignmentEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieLevelOfAlignmentEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieLevelOfAlignmentEnum>>;
}

export interface InsertAcquisPkieLevelOfAlignmentInsertInput {
  acquis?: InputMaybe<InsertAcquisPkieAcquisArrRelInsertInput>;
  level?: InputMaybe<Scalars['String']['input']>;
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
}

export interface InsertAcquisPkieLevelOfAlignmentObjRelInsertInput {
  data: InsertAcquisPkieLevelOfAlignmentInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieLevelOfAlignmentOnConflict>;
}

export interface InsertAcquisPkieLevelOfAlignmentOnConflict {
  constraint: InsertAcquisPkieLevelOfAlignmentConstraint;
  update_columns: Array<InsertAcquisPkieLevelOfAlignmentUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieLevelOfAlignmentBoolExp>;
}

export enum InsertAcquisPkieLevelOfAlignmentUpdateColumn {
  /** column name */
  Level = 'level'
}

export interface InsertAcquisPkieMeasureActivityAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieMeasureActivityAggregateBoolExpCount>;
}

export interface InsertAcquisPkieMeasureActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieMeasureActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureActivityBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieMeasureActivityArrRelInsertInput {
  data: Array<InsertAcquisPkieMeasureActivityInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureActivityOnConflict>;
}

export interface InsertAcquisPkieMeasureActivityBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureActivityBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureActivityBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureActivityBoolExp>>;
  activity?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  measure?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieMeasureActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureActivityPkey = 'measure_activity_pkey'
}

export interface InsertAcquisPkieMeasureActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertAcquisPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieMeasureActivityOnConflict {
  constraint: InsertAcquisPkieMeasureActivityConstraint;
  update_columns: Array<InsertAcquisPkieMeasureActivityUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureActivityBoolExp>;
}

export enum InsertAcquisPkieMeasureActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status'
}

export enum InsertAcquisPkieMeasureActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status'
}

export interface InsertAcquisPkieMeasureAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExpCount>;
}

export interface InsertAcquisPkieMeasureAggregateBoolExpBoolAnd {
  arguments: InsertAcquisPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieMeasureAggregateBoolExpBoolOr {
  arguments: InsertAcquisPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieMeasureAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieMeasureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieMeasureAnswersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieMeasureAnswersAggregateBoolExpCount>;
}

export interface InsertAcquisPkieMeasureAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieMeasureAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureAnswersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieMeasureAnswersArrRelInsertInput {
  data: Array<InsertAcquisPkieMeasureAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureAnswersOnConflict>;
}

export interface InsertAcquisPkieMeasureAnswersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureAnswersBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureAnswersBoolExp>>;
  answer?: InputMaybe<InsertAcquisStringComparisonExp>;
  answered_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  measure?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisPkieMeasureAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureAnswersPkey = 'measure_answers_pkey'
}

export interface InsertAcquisPkieMeasureAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertAcquisPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisPkieMeasureAnswersOnConflict {
  constraint: InsertAcquisPkieMeasureAnswersConstraint;
  update_columns: Array<InsertAcquisPkieMeasureAnswersUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureAnswersBoolExp>;
}

export enum InsertAcquisPkieMeasureAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisPkieMeasureAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisPkieMeasureArrRelInsertInput {
  data: Array<InsertAcquisPkieMeasureInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureOnConflict>;
}

export interface InsertAcquisPkieMeasureBookmarkAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieMeasureBookmarkAggregateBoolExpCount>;
}

export interface InsertAcquisPkieMeasureBookmarkAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieMeasureBookmarkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBookmarkBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieMeasureBookmarkArrRelInsertInput {
  data: Array<InsertAcquisPkieMeasureBookmarkInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureBookmarkOnConflict>;
}

export interface InsertAcquisPkieMeasureBookmarkBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureBookmarkBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureBookmarkBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureBookmarkBoolExp>>;
  bookmark?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  bookmark_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  measure?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisPkieMeasureBookmarkConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureBookmarkPkey = 'measure_bookmark_pkey'
}

export interface InsertAcquisPkieMeasureBookmarkInsertInput {
  bookmark?: InputMaybe<InsertAcquisBookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertAcquisPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisPkieMeasureBookmarkOnConflict {
  constraint: InsertAcquisPkieMeasureBookmarkConstraint;
  update_columns: Array<InsertAcquisPkieMeasureBookmarkUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureBookmarkBoolExp>;
}

export enum InsertAcquisPkieMeasureBookmarkSelectColumn {
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export enum InsertAcquisPkieMeasureBookmarkUpdateColumn {
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export interface InsertAcquisPkieMeasureBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureBoolExp>>;
  acquis_chapter?: InputMaybe<InsertAcquisPkieAcquisChapterBoolExp>;
  acquis_chapter_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  acquis_work_plan_task?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  acquis_work_plan_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  crated_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  eli?: InputMaybe<InsertAcquisStringComparisonExp>;
  gnpie_member?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  impact?: InputMaybe<InsertAcquisStringComparisonExp>;
  is_completed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  level_of_alignment?: InputMaybe<InsertAcquisPkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<InsertAcquisPkieLevelOfAlignmentBoolExp>;
  measure_activities?: InputMaybe<InsertAcquisPkieMeasureActivityBoolExp>;
  measure_activities_aggregate?: InputMaybe<InsertAcquisPkieMeasureActivityAggregateBoolExp>;
  measure_answers?: InputMaybe<InsertAcquisPkieMeasureAnswersBoolExp>;
  measure_answers_aggregate?: InputMaybe<InsertAcquisPkieMeasureAnswersAggregateBoolExp>;
  measure_bookmarks?: InputMaybe<InsertAcquisPkieMeasureBookmarkBoolExp>;
  measure_bookmarks_aggregate?: InputMaybe<InsertAcquisPkieMeasureBookmarkAggregateBoolExp>;
  measure_budgets?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<InsertAcquisPkieMeasureBudgetsAggregateBoolExp>;
  measure_comments?: InputMaybe<InsertAcquisPkieMeasureCommentBoolExp>;
  measure_comments_aggregate?: InputMaybe<InsertAcquisPkieMeasureCommentAggregateBoolExp>;
  measure_status?: InputMaybe<InsertAcquisPkieMeasureStatusBoolExp>;
  national_act?: InputMaybe<InsertAcquisPkieNationalActsBoolExp>;
  negotiator?: InputMaybe<InsertAcquisUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  proposing_institution?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  proposing_institution_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  quadrennial_scheduled_approval?: InputMaybe<InsertAcquisStringComparisonExp>;
  scheduled_approval_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  status?: InputMaybe<InsertAcquisPkieMeasureStatusEnumComparisonExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  text?: InputMaybe<InsertAcquisStringComparisonExp>;
  topic?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topic_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  type?: InputMaybe<InsertAcquisPkieTypeMeasureEnumComparisonExp>;
  type_alignment_act?: InputMaybe<InsertAcquisPkieTypeAlignmentActBoolExp>;
  type_of_alignment?: InputMaybe<InsertAcquisPkieTypeAlignmentActEnumComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  weight_transposition?: InputMaybe<InsertAcquisIntComparisonExp>;
  year_of_completition?: InputMaybe<InsertAcquisIntComparisonExp>;
  yearly_quarter?: InputMaybe<InsertAcquisYearlyQuartersBoolExp>;
}

export interface InsertAcquisPkieMeasureBudgetsAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisPkieMeasureBudgetsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisPkieMeasureBudgetsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisPkieMeasureBudgetsAggregateBoolExpCount>;
}

export interface InsertAcquisPkieMeasureBudgetsAggregateBoolExpBoolAnd {
  arguments: InsertAcquisPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieMeasureBudgetsAggregateBoolExpBoolOr {
  arguments: InsertAcquisPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieMeasureBudgetsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieMeasureBudgetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieMeasureBudgetsArrRelInsertInput {
  data: Array<InsertAcquisPkieMeasureBudgetsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureBudgetsOnConflict>;
}

export interface InsertAcquisPkieMeasureBudgetsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureBudgetsBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureBudgetsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  measure?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  source_of_funding?: InputMaybe<InsertAcquisPkieSourceOfFundingEnumComparisonExp>;
  type_budget?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetBoolExp>;
  type_of_budget?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetEnumComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  value?: InputMaybe<InsertAcquisNumericComparisonExp>;
  year?: InputMaybe<InsertAcquisIntComparisonExp>;
}

export enum InsertAcquisPkieMeasureBudgetsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureBudgetsPkey = 'measure_budgets_pkey'
}

export interface InsertAcquisPkieMeasureBudgetsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<InsertAcquisPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  source_of_funding?: InputMaybe<InsertAcquisPkieSourceOfFundingEnum>;
  type_budget?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetObjRelInsertInput>;
  type_of_budget?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface InsertAcquisPkieMeasureBudgetsOnConflict {
  constraint: InsertAcquisPkieMeasureBudgetsConstraint;
  update_columns: Array<InsertAcquisPkieMeasureBudgetsUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
}

export enum InsertAcquisPkieMeasureBudgetsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export enum InsertAcquisPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisPkieMeasureBudgetsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export interface InsertAcquisPkieMeasureCommentAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieMeasureCommentAggregateBoolExpCount>;
}

export interface InsertAcquisPkieMeasureCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieMeasureCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieMeasureCommentBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieMeasureCommentArrRelInsertInput {
  data: Array<InsertAcquisPkieMeasureCommentInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureCommentOnConflict>;
}

export interface InsertAcquisPkieMeasureCommentBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureCommentBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureCommentBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  comment?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  measure?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisPkieMeasureCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureCommentPkey = 'measure_comment_pkey'
}

export interface InsertAcquisPkieMeasureCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertAcquisPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisPkieMeasureCommentOnConflict {
  constraint: InsertAcquisPkieMeasureCommentConstraint;
  update_columns: Array<InsertAcquisPkieMeasureCommentUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureCommentBoolExp>;
}

export enum InsertAcquisPkieMeasureCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisPkieMeasureCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisPkieMeasureConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasurePkey = 'measure_pkey'
}

export interface InsertAcquisPkieMeasureInsertInput {
  acquis_chapter?: InputMaybe<InsertAcquisPkieAcquisChapterObjRelInsertInput>;
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  acquis_work_plan_task?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskObjRelInsertInput>;
  acquis_work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  crated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  gnpie_member?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Scalars['String']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  level_of_alignment?: InputMaybe<InsertAcquisPkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<InsertAcquisPkieLevelOfAlignmentObjRelInsertInput>;
  measure_activities?: InputMaybe<InsertAcquisPkieMeasureActivityArrRelInsertInput>;
  measure_answers?: InputMaybe<InsertAcquisPkieMeasureAnswersArrRelInsertInput>;
  measure_bookmarks?: InputMaybe<InsertAcquisPkieMeasureBookmarkArrRelInsertInput>;
  measure_budgets?: InputMaybe<InsertAcquisPkieMeasureBudgetsArrRelInsertInput>;
  measure_comments?: InputMaybe<InsertAcquisPkieMeasureCommentArrRelInsertInput>;
  measure_status?: InputMaybe<InsertAcquisPkieMeasureStatusObjRelInsertInput>;
  national_act?: InputMaybe<InsertAcquisPkieNationalActsObjRelInsertInput>;
  negotiator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  proposing_institution?: InputMaybe<InsertAcquisInstitutionsObjRelInsertInput>;
  proposing_institution_id?: InputMaybe<Scalars['uuid']['input']>;
  quadrennial_scheduled_approval?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InsertAcquisPkieMeasureStatusEnum>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<InsertAcquisTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<InsertAcquisPkieTypeMeasureEnum>;
  type_alignment_act?: InputMaybe<InsertAcquisPkieTypeAlignmentActObjRelInsertInput>;
  type_of_alignment?: InputMaybe<InsertAcquisPkieTypeAlignmentActEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
  yearly_quarter?: InputMaybe<InsertAcquisYearlyQuartersObjRelInsertInput>;
}

export interface InsertAcquisPkieMeasureObjRelInsertInput {
  data: InsertAcquisPkieMeasureInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureOnConflict>;
}

export interface InsertAcquisPkieMeasureOnConflict {
  constraint: InsertAcquisPkieMeasureConstraint;
  update_columns: Array<InsertAcquisPkieMeasureUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
}

export enum InsertAcquisPkieMeasureSelectColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  AcquisWorkPlanTaskId = 'acquis_work_plan_task_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Eli = 'eli',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export enum InsertAcquisPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertAcquisPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export interface InsertAcquisPkieMeasureStatusBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieMeasureStatusBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieMeasureStatusBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieMeasureStatusBoolExp>>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieMeasureStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  MeasureStatusPkey = 'measure_status_pkey'
}

export enum InsertAcquisPkieMeasureStatusEnum {
  HartimKonceptiInstitucioni = 'HARTIM_KONCEPTI_INSTITUCIONI',
  HyrjaNeFuqi = 'HYRJA_NE_FUQI',
  MiratimiNeKm = 'MIRATIMI_NE_KM',
  MiratimiNgaMinistri = 'MIRATIMI_NGA_MINISTRI',
  MiratimNgaKuvendi = 'MIRATIM_NGA_KUVENDI',
  PergatitjaEAktitLigjorInstitucioni = 'PERGATITJA_E_AKTIT_LIGJOR_INSTITUCIONI',
  PerKonsultimMeInstitucionet = 'PER_KONSULTIM_ME_INSTITUCIONET',
  PerKonsultimPublik = 'PER_KONSULTIM_PUBLIK',
  ShqyrtimNgaKuvendi = 'SHQYRTIM_NGA_KUVENDI'
}

export interface InsertAcquisPkieMeasureStatusEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieMeasureStatusEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieMeasureStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieMeasureStatusEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieMeasureStatusEnum>>;
}

export interface InsertAcquisPkieMeasureStatusInsertInput {
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieMeasureStatusObjRelInsertInput {
  data: InsertAcquisPkieMeasureStatusInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieMeasureStatusOnConflict>;
}

export interface InsertAcquisPkieMeasureStatusOnConflict {
  constraint: InsertAcquisPkieMeasureStatusConstraint;
  update_columns: Array<InsertAcquisPkieMeasureStatusUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieMeasureStatusBoolExp>;
}

export enum InsertAcquisPkieMeasureStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export enum InsertAcquisPkieMeasureUpdateColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  AcquisWorkPlanTaskId = 'acquis_work_plan_task_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Eli = 'eli',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export interface InsertAcquisPkieNationalActsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieNationalActsBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieNationalActsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieNationalActsBoolExp>>;
  acquis_national_acts?: InputMaybe<InsertAcquisPkieAcquisNationalActsBoolExp>;
  acquis_national_acts_aggregate?: InputMaybe<InsertAcquisPkieAcquisNationalActsAggregateBoolExp>;
  act_number?: InputMaybe<InsertAcquisStringComparisonExp>;
  act_type?: InputMaybe<InsertAcquisPkieActTypeBoolExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  eli?: InputMaybe<InsertAcquisStringComparisonExp>;
  failed?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  publish_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  qbz_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  type?: InputMaybe<InsertAcquisPkieActTypeEnumComparisonExp>;
}

export enum InsertAcquisPkieNationalActsConstraint {
  /** unique or primary key constraint on columns "id" */
  NationalActsIdKey = 'national_acts_id_key',
  /** unique or primary key constraint on columns "eli" */
  NationalActsPkey = 'national_acts_pkey'
}

export interface InsertAcquisPkieNationalActsInsertInput {
  acquis_national_acts?: InputMaybe<InsertAcquisPkieAcquisNationalActsArrRelInsertInput>;
  act_number?: InputMaybe<Scalars['String']['input']>;
  act_type?: InputMaybe<InsertAcquisPkieActTypeObjRelInsertInput>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  failed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  publish_date?: InputMaybe<Scalars['date']['input']>;
  qbz_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InsertAcquisPkieActTypeEnum>;
}

export interface InsertAcquisPkieNationalActsObjRelInsertInput {
  data: InsertAcquisPkieNationalActsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieNationalActsOnConflict>;
}

export interface InsertAcquisPkieNationalActsOnConflict {
  constraint: InsertAcquisPkieNationalActsConstraint;
  update_columns: Array<InsertAcquisPkieNationalActsUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieNationalActsBoolExp>;
}

export enum InsertAcquisPkieNationalActsUpdateColumn {
  /** column name */
  ActNumber = 'act_number',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  Failed = 'failed',
  /** column name */
  Id = 'id',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

export interface InsertAcquisPkieProcesPuneIagaAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieProcesPuneIagaAggregateBoolExpCount>;
}

export interface InsertAcquisPkieProcesPuneIagaAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieProcesPuneIagaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieProcesPuneIagaBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieProcesPuneIagaArrRelInsertInput {
  data: Array<InsertAcquisPkieProcesPuneIagaInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieProcesPuneIagaOnConflict>;
}

export interface InsertAcquisPkieProcesPuneIagaBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieProcesPuneIagaBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieProcesPuneIagaBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieProcesPuneIagaBoolExp>>;
  acqui?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  boshlleqet_e_struktures?: InputMaybe<InsertAcquisStringComparisonExp>;
  celex?: InputMaybe<InsertAcquisStringComparisonExp>;
  elemente_te_struktures?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  ngritja_institucionale?: InputMaybe<InsertAcquisStringComparisonExp>;
  pershtatje_struktura_administrative?: InputMaybe<InsertAcquisStringComparisonExp>;
  struktura_administrative?: InputMaybe<InsertAcquisStringComparisonExp>;
  synimi_aspekte_financiare?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieProcesPuneIagaConstraint {
  /** unique or primary key constraint on columns "id" */
  ProcesPuneIagaPkey = 'proces_pune_iaga_pkey'
}

export interface InsertAcquisPkieProcesPuneIagaInsertInput {
  acqui?: InputMaybe<InsertAcquisPkieAcquisObjRelInsertInput>;
  boshlleqet_e_struktures?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  elemente_te_struktures?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ngritja_institucionale?: InputMaybe<Scalars['String']['input']>;
  pershtatje_struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  synimi_aspekte_financiare?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieProcesPuneIagaOnConflict {
  constraint: InsertAcquisPkieProcesPuneIagaConstraint;
  update_columns: Array<InsertAcquisPkieProcesPuneIagaUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieProcesPuneIagaBoolExp>;
}

export enum InsertAcquisPkieProcesPuneIagaSelectColumn {
  /** column name */
  BoshlleqetEStruktures = 'boshlleqet_e_struktures',
  /** column name */
  Celex = 'celex',
  /** column name */
  ElementeTeStruktures = 'elemente_te_struktures',
  /** column name */
  Id = 'id',
  /** column name */
  NgritjaInstitucionale = 'ngritja_institucionale',
  /** column name */
  PershtatjeStrukturaAdministrative = 'pershtatje_struktura_administrative',
  /** column name */
  StrukturaAdministrative = 'struktura_administrative',
  /** column name */
  SynimiAspekteFinanciare = 'synimi_aspekte_financiare'
}

export enum InsertAcquisPkieProcesPuneIagaUpdateColumn {
  /** column name */
  BoshlleqetEStruktures = 'boshlleqet_e_struktures',
  /** column name */
  Celex = 'celex',
  /** column name */
  ElementeTeStruktures = 'elemente_te_struktures',
  /** column name */
  Id = 'id',
  /** column name */
  NgritjaInstitucionale = 'ngritja_institucionale',
  /** column name */
  PershtatjeStrukturaAdministrative = 'pershtatje_struktura_administrative',
  /** column name */
  StrukturaAdministrative = 'struktura_administrative',
  /** column name */
  SynimiAspekteFinanciare = 'synimi_aspekte_financiare'
}

export interface InsertAcquisPkieProcesPuneLgaAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieProcesPuneLgaAggregateBoolExpCount>;
}

export interface InsertAcquisPkieProcesPuneLgaAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieProcesPuneLgaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieProcesPuneLgaBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieProcesPuneLgaArrRelInsertInput {
  data: Array<InsertAcquisPkieProcesPuneLgaInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieProcesPuneLgaOnConflict>;
}

export interface InsertAcquisPkieProcesPuneLgaBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieProcesPuneLgaBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieProcesPuneLgaBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieProcesPuneLgaBoolExp>>;
  acqui?: InputMaybe<InsertAcquisPkieAcquisBoolExp>;
  celex?: InputMaybe<InsertAcquisStringComparisonExp>;
  harmonizimi_hapesirat?: InputMaybe<InsertAcquisStringComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  transpozim_plane?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieProcesPuneLgaConstraint {
  /** unique or primary key constraint on columns "id" */
  ProcesePuneLgaPkey = 'procese_pune_lga_pkey'
}

export interface InsertAcquisPkieProcesPuneLgaInsertInput {
  acqui?: InputMaybe<InsertAcquisPkieAcquisObjRelInsertInput>;
  celex?: InputMaybe<Scalars['String']['input']>;
  harmonizimi_hapesirat?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  transpozim_plane?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieProcesPuneLgaOnConflict {
  constraint: InsertAcquisPkieProcesPuneLgaConstraint;
  update_columns: Array<InsertAcquisPkieProcesPuneLgaUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieProcesPuneLgaBoolExp>;
}

export enum InsertAcquisPkieProcesPuneLgaSelectColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  HarmonizimiHapesirat = 'harmonizimi_hapesirat',
  /** column name */
  Id = 'id',
  /** column name */
  TranspozimPlane = 'transpozim_plane'
}

export enum InsertAcquisPkieProcesPuneLgaUpdateColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  HarmonizimiHapesirat = 'harmonizimi_hapesirat',
  /** column name */
  Id = 'id',
  /** column name */
  TranspozimPlane = 'transpozim_plane'
}

export enum InsertAcquisPkieSourceOfFundingEnum {
  Donor = 'DONOR',
  StateBudget = 'STATE_BUDGET'
}

export interface InsertAcquisPkieSourceOfFundingEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieSourceOfFundingEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieSourceOfFundingEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieSourceOfFundingEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieSourceOfFundingEnum>>;
}

export interface InsertAcquisPkieTypeAlignmentActBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieTypeAlignmentActBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieTypeAlignmentActBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieTypeAlignmentActBoolExp>>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
  type?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieTypeAlignmentActConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeAlignmentActPkey = 'type_alignment_act_pkey'
}

export enum InsertAcquisPkieTypeAlignmentActEnum {
  DokumentPolitikash = 'DOKUMENT_POLITIKASH',
  Ligj = 'LIGJ',
  Udhezim = 'UDHEZIM',
  Urdher = 'URDHER',
  Vkm = 'VKM'
}

export interface InsertAcquisPkieTypeAlignmentActEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieTypeAlignmentActEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieTypeAlignmentActEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieTypeAlignmentActEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieTypeAlignmentActEnum>>;
}

export interface InsertAcquisPkieTypeAlignmentActInsertInput {
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieTypeAlignmentActObjRelInsertInput {
  data: InsertAcquisPkieTypeAlignmentActInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieTypeAlignmentActOnConflict>;
}

export interface InsertAcquisPkieTypeAlignmentActOnConflict {
  constraint: InsertAcquisPkieTypeAlignmentActConstraint;
  update_columns: Array<InsertAcquisPkieTypeAlignmentActUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieTypeAlignmentActBoolExp>;
}

export enum InsertAcquisPkieTypeAlignmentActUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertAcquisPkieTypeMeasureBudgetBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieTypeMeasureBudgetBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieTypeMeasureBudgetBoolExp>>;
  measure_budgets?: InputMaybe<InsertAcquisPkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<InsertAcquisPkieMeasureBudgetsAggregateBoolExp>;
  type?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisPkieTypeMeasureBudgetConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeMeasureBudgetPkey = 'type_measure_budget_pkey'
}

export enum InsertAcquisPkieTypeMeasureBudgetEnum {
  Existing = 'EXISTING',
  Extra = 'EXTRA'
}

export interface InsertAcquisPkieTypeMeasureBudgetEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieTypeMeasureBudgetEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieTypeMeasureBudgetEnum>>;
}

export interface InsertAcquisPkieTypeMeasureBudgetInsertInput {
  measure_budgets?: InputMaybe<InsertAcquisPkieMeasureBudgetsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisPkieTypeMeasureBudgetObjRelInsertInput {
  data: InsertAcquisPkieTypeMeasureBudgetInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetOnConflict>;
}

export interface InsertAcquisPkieTypeMeasureBudgetOnConflict {
  constraint: InsertAcquisPkieTypeMeasureBudgetConstraint;
  update_columns: Array<InsertAcquisPkieTypeMeasureBudgetUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieTypeMeasureBudgetBoolExp>;
}

export enum InsertAcquisPkieTypeMeasureBudgetUpdateColumn {
  /** column name */
  Type = 'type'
}

export enum InsertAcquisPkieTypeMeasureEnum {
  Investments = 'INVESTMENTS',
  LegalPolicy = 'LEGAL_POLICY',
  INstitutionalAdministrative = 'iNSTITUTIONAL_ADMINISTRATIVE'
}

export interface InsertAcquisPkieTypeMeasureEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieTypeMeasureEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieTypeMeasureEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieTypeMeasureEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieTypeMeasureEnum>>;
}

export interface InsertAcquisPkieWorkPlanCommentAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieWorkPlanCommentAggregateBoolExpCount>;
}

export interface InsertAcquisPkieWorkPlanCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieWorkPlanCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieWorkPlanCommentBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieWorkPlanCommentArrRelInsertInput {
  data: Array<InsertAcquisPkieWorkPlanCommentInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlanCommentOnConflict>;
}

export interface InsertAcquisPkieWorkPlanCommentBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieWorkPlanCommentBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieWorkPlanCommentBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieWorkPlanCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  comment?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisPkieWorkPlanCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanCommentPkey = 'work_plan_comment_pkey'
}

export interface InsertAcquisPkieWorkPlanCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisPkieWorkPlanCommentOnConflict {
  constraint: InsertAcquisPkieWorkPlanCommentConstraint;
  update_columns: Array<InsertAcquisPkieWorkPlanCommentUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieWorkPlanCommentBoolExp>;
}

export enum InsertAcquisPkieWorkPlanCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertAcquisPkieWorkPlanCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertAcquisPkieWorkPlanStatusesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieWorkPlanStatusesBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieWorkPlanStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieWorkPlanStatusesBoolExp>>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
  work_plans?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
  work_plans_aggregate?: InputMaybe<InsertAcquisPkieWorkPlansAggregateBoolExp>;
}

export enum InsertAcquisPkieWorkPlanStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanStatusesPkey = 'work_plan_statuses_pkey'
}

export interface InsertAcquisPkieWorkPlanStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plans?: InputMaybe<InsertAcquisPkieWorkPlansArrRelInsertInput>;
}

export interface InsertAcquisPkieWorkPlanStatusesObjRelInsertInput {
  data: InsertAcquisPkieWorkPlanStatusesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlanStatusesOnConflict>;
}

export interface InsertAcquisPkieWorkPlanStatusesOnConflict {
  constraint: InsertAcquisPkieWorkPlanStatusesConstraint;
  update_columns: Array<InsertAcquisPkieWorkPlanStatusesUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieWorkPlanStatusesBoolExp>;
}

export enum InsertAcquisPkieWorkPlanStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertAcquisPkieWorkPlanTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityAggregateBoolExpCount>;
}

export interface InsertAcquisPkieWorkPlanTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieWorkPlanTaskActivityArrRelInsertInput {
  data: Array<InsertAcquisPkieWorkPlanTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityOnConflict>;
}

export interface InsertAcquisPkieWorkPlanTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  status?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesEnumComparisonExp>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisPkieWorkPlanTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskActivityPkey = 'work_plan_task_activity_pkey'
}

export interface InsertAcquisPkieWorkPlanTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesEnum>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisPkieWorkPlanTaskActivityOnConflict {
  constraint: InsertAcquisPkieWorkPlanTaskActivityConstraint;
  update_columns: Array<InsertAcquisPkieWorkPlanTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityBoolExp>;
}

export enum InsertAcquisPkieWorkPlanTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertAcquisPkieWorkPlanTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertAcquisPkieWorkPlanTaskAggregateBoolExp {
  count?: InputMaybe<InsertAcquisPkieWorkPlanTaskAggregateBoolExpCount>;
}

export interface InsertAcquisPkieWorkPlanTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieWorkPlanTaskArrRelInsertInput {
  data: Array<InsertAcquisPkieWorkPlanTaskInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlanTaskOnConflict>;
}

export interface InsertAcquisPkieWorkPlanTaskBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskBoolExp>>;
  acquis_work_plan_tasks?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskBoolExp>;
  acquis_work_plan_tasks_aggregate?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskAggregateBoolExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  coordinator?: InputMaybe<InsertAcquisUsersBoolExp>;
  coordinator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  gnpie_member?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  negotiator?: InputMaybe<InsertAcquisUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  reporter_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  signed_documents?: InputMaybe<InsertAcquisSignedDocumentsBoolExp>;
  signed_documents_aggregate?: InputMaybe<InsertAcquisSignedDocumentsAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesEnumComparisonExp>;
  text?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  updated_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  userByUpdatedByUserId?: InputMaybe<InsertAcquisUsersBoolExp>;
  work_plan?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
  work_plan_comments?: InputMaybe<InsertAcquisPkieWorkPlanCommentBoolExp>;
  work_plan_comments_aggregate?: InputMaybe<InsertAcquisPkieWorkPlanCommentAggregateBoolExp>;
  work_plan_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  work_plan_task_activities?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityBoolExp>;
  work_plan_task_activities_aggregate?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityAggregateBoolExp>;
  work_plan_task_activity?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityBoolExp>;
  work_plan_task_activity_aggregate?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityAggregateBoolExp>;
  work_plan_task_status?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesBoolExp>;
}

export enum InsertAcquisPkieWorkPlanTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskPkey = 'work_plan_task_pkey'
}

export interface InsertAcquisPkieWorkPlanTaskInsertInput {
  acquis_work_plan_tasks?: InputMaybe<InsertAcquisPkieAcquisWorkPlanTaskArrRelInsertInput>;
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  coordinator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  gnpie_member?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  signed_documents?: InputMaybe<InsertAcquisSignedDocumentsArrRelInsertInput>;
  status?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  userByUpdatedByUserId?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  work_plan?: InputMaybe<InsertAcquisPkieWorkPlansObjRelInsertInput>;
  work_plan_comments?: InputMaybe<InsertAcquisPkieWorkPlanCommentArrRelInsertInput>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task_activities?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityArrRelInsertInput>;
  work_plan_task_activity?: InputMaybe<InsertAcquisPkieWorkPlanTaskActivityArrRelInsertInput>;
  work_plan_task_status?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesObjRelInsertInput>;
}

export interface InsertAcquisPkieWorkPlanTaskObjRelInsertInput {
  data: InsertAcquisPkieWorkPlanTaskInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlanTaskOnConflict>;
}

export interface InsertAcquisPkieWorkPlanTaskOnConflict {
  constraint: InsertAcquisPkieWorkPlanTaskConstraint;
  update_columns: Array<InsertAcquisPkieWorkPlanTaskUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
}

export enum InsertAcquisPkieWorkPlanTaskSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CoordinatorId = 'coordinator_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface InsertAcquisPkieWorkPlanTaskStatusesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskStatusesBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskStatusesBoolExp>>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
  work_plan_tasks?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertAcquisPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertAcquisPkieWorkPlanTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanTaskStatusesPkey = 'work_plan_task_statuses_pkey'
}

export enum InsertAcquisPkieWorkPlanTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertAcquisPkieWorkPlanTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisPkieWorkPlanTaskStatusesEnum>>;
}

export interface InsertAcquisPkieWorkPlanTaskStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plan_tasks?: InputMaybe<InsertAcquisPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertAcquisPkieWorkPlanTaskStatusesObjRelInsertInput {
  data: InsertAcquisPkieWorkPlanTaskStatusesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesOnConflict>;
}

export interface InsertAcquisPkieWorkPlanTaskStatusesOnConflict {
  constraint: InsertAcquisPkieWorkPlanTaskStatusesConstraint;
  update_columns: Array<InsertAcquisPkieWorkPlanTaskStatusesUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieWorkPlanTaskStatusesBoolExp>;
}

export enum InsertAcquisPkieWorkPlanTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export enum InsertAcquisPkieWorkPlanTaskUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CoordinatorId = 'coordinator_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface InsertAcquisPkieWorkPlansAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisPkieWorkPlansAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisPkieWorkPlansAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisPkieWorkPlansAggregateBoolExpCount>;
}

export interface InsertAcquisPkieWorkPlansAggregateBoolExpBoolAnd {
  arguments: InsertAcquisPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieWorkPlansAggregateBoolExpBoolOr {
  arguments: InsertAcquisPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisPkieWorkPlansAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisPkieWorkPlansSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisPkieWorkPlansArrRelInsertInput {
  data: Array<InsertAcquisPkieWorkPlansInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlansOnConflict>;
}

export interface InsertAcquisPkieWorkPlansBoolExp {
  _and?: InputMaybe<Array<InsertAcquisPkieWorkPlansBoolExp>>;
  _not?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisPkieWorkPlansBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  definition_deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisNumericComparisonExp>;
  end_year?: InputMaybe<InsertAcquisIntComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  start_year?: InputMaybe<InsertAcquisIntComparisonExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  work_plan_status?: InputMaybe<InsertAcquisPkieWorkPlanStatusesBoolExp>;
  work_plan_tasks?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertAcquisPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertAcquisPkieWorkPlansConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanPkey = 'work_plan_pkey'
}

export interface InsertAcquisPkieWorkPlansInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_status?: InputMaybe<InsertAcquisPkieWorkPlanStatusesObjRelInsertInput>;
  work_plan_tasks?: InputMaybe<InsertAcquisPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertAcquisPkieWorkPlansObjRelInsertInput {
  data: InsertAcquisPkieWorkPlansInsertInput;
  on_conflict?: InputMaybe<InsertAcquisPkieWorkPlansOnConflict>;
}

export interface InsertAcquisPkieWorkPlansOnConflict {
  constraint: InsertAcquisPkieWorkPlansConstraint;
  update_columns: Array<InsertAcquisPkieWorkPlansUpdateColumn>;
  where?: InputMaybe<InsertAcquisPkieWorkPlansBoolExp>;
}

export enum InsertAcquisPkieWorkPlansSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertAcquisPkieWorkPlansUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisQuestionTypesEnum {
  Integration = 'INTEGRATION',
  Report = 'REPORT'
}

export interface InsertAcquisQuestionTypesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisQuestionTypesEnum>;
  _in?: InputMaybe<Array<InsertAcquisQuestionTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisQuestionTypesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisQuestionTypesEnum>>;
}

export interface InsertAcquisQuestionsAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisQuestionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisQuestionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisQuestionsAggregateBoolExpCount>;
}

export interface InsertAcquisQuestionsAggregateBoolExpBoolAnd {
  arguments: InsertAcquisQuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisQuestionsAggregateBoolExpBoolOr {
  arguments: InsertAcquisQuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisQuestionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisQuestionsArrRelInsertInput {
  data: Array<InsertAcquisQuestionsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisQuestionsOnConflict>;
}

export interface InsertAcquisQuestionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisQuestionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisQuestionsBoolExp>>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_active?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  report_answers?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExp>;
  report_tasks?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertAcquisReportTasksAggregateBoolExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  tasks?: InputMaybe<InsertAcquisTaskBoolExp>;
  tasks_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  text?: InputMaybe<InsertAcquisStringComparisonExp>;
  topic_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  type?: InputMaybe<InsertAcquisQuestionTypesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisQuestionsConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'Questions_pkey',
  /** unique or primary key constraint on columns "text" */
  QuestionsTextKey = 'Questions_text_key'
}

export interface InsertAcquisQuestionsInsertInput {
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  report_answers?: InputMaybe<InsertAcquisReportAnswersArrRelInsertInput>;
  report_tasks?: InputMaybe<InsertAcquisReportTasksArrRelInsertInput>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  tasks?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<InsertAcquisQuestionTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisQuestionsObjRelInsertInput {
  data: InsertAcquisQuestionsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisQuestionsOnConflict>;
}

export interface InsertAcquisQuestionsOnConflict {
  constraint: InsertAcquisQuestionsConstraint;
  update_columns: Array<InsertAcquisQuestionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisQuestionsBoolExp>;
}

export enum InsertAcquisQuestionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisQuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

export enum InsertAcquisQuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

export enum InsertAcquisQuestionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisRecommendationsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisRecommendationsAggregateBoolExpCount>;
}

export interface InsertAcquisRecommendationsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisRecommendationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisRecommendationsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisRecommendationsArrRelInsertInput {
  data: Array<InsertAcquisRecommendationsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisRecommendationsOnConflict>;
}

export interface InsertAcquisRecommendationsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisRecommendationsBoolExp>>;
  _not?: InputMaybe<InsertAcquisRecommendationsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisRecommendationsBoolExp>>;
  bookmarks?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertAcquisBookmarksAggregateBoolExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report?: InputMaybe<InsertAcquisReportsBoolExp>;
  report_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisRecommendationsConstraint {
  /** unique or primary key constraint on columns "id" */
  RecommendationsPkey = 'recommendations_pkey'
}

export interface InsertAcquisRecommendationsInsertInput {
  bookmarks?: InputMaybe<InsertAcquisBookmarksArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertAcquisReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisRecommendationsObjRelInsertInput {
  data: InsertAcquisRecommendationsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisRecommendationsOnConflict>;
}

export interface InsertAcquisRecommendationsOnConflict {
  constraint: InsertAcquisRecommendationsConstraint;
  update_columns: Array<InsertAcquisRecommendationsUpdateColumn>;
  where?: InputMaybe<InsertAcquisRecommendationsBoolExp>;
}

export enum InsertAcquisRecommendationsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisRecommendationsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisReportAnswersAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExpCount>;
}

export interface InsertAcquisReportAnswersAggregateBoolExpBoolAnd {
  arguments: InsertAcquisReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisReportAnswersAggregateBoolExpBoolOr {
  arguments: InsertAcquisReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisReportAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisReportAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisReportAnswersArrRelInsertInput {
  data: Array<InsertAcquisReportAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisReportAnswersOnConflict>;
}

export interface InsertAcquisReportAnswersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportAnswersBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportAnswersBoolExp>>;
  answer?: InputMaybe<InsertAcquisStringComparisonExp>;
  answered_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  final_answer?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  final_answer_aggregate?: InputMaybe<InsertAcquisReportTasksAggregateBoolExp>;
  has_update?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  question?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  question_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report?: InputMaybe<InsertAcquisReportsBoolExp>;
  report_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report_task?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisReportAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportAnswersPkey = 'report_answers_pkey'
}

export interface InsertAcquisReportAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  final_answer?: InputMaybe<InsertAcquisReportTasksArrRelInsertInput>;
  has_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertAcquisQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertAcquisReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertAcquisReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
}

export interface InsertAcquisReportAnswersObjRelInsertInput {
  data: InsertAcquisReportAnswersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportAnswersOnConflict>;
}

export interface InsertAcquisReportAnswersOnConflict {
  constraint: InsertAcquisReportAnswersConstraint;
  update_columns: Array<InsertAcquisReportAnswersUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
}

export enum InsertAcquisReportAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update'
}

export enum InsertAcquisReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update'
}

export enum InsertAcquisReportAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisReportChapterStatusesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportChapterStatusesBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportChapterStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportChapterStatusesBoolExp>>;
  report_chapters?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertAcquisReportChaptersAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisReportChapterStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportChapterStatusesPkey = 'report_chapter_statuses_pkey'
}

export interface InsertAcquisReportChapterStatusesInsertInput {
  report_chapters?: InputMaybe<InsertAcquisReportChaptersArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisReportChapterStatusesObjRelInsertInput {
  data: InsertAcquisReportChapterStatusesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportChapterStatusesOnConflict>;
}

export interface InsertAcquisReportChapterStatusesOnConflict {
  constraint: InsertAcquisReportChapterStatusesConstraint;
  update_columns: Array<InsertAcquisReportChapterStatusesUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportChapterStatusesBoolExp>;
}

export enum InsertAcquisReportChapterStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertAcquisReportChaptersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisReportChaptersAggregateBoolExpCount>;
}

export interface InsertAcquisReportChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisReportChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisReportChaptersArrRelInsertInput {
  data: Array<InsertAcquisReportChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisReportChaptersOnConflict>;
}

export interface InsertAcquisReportChaptersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportChaptersBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportChaptersBoolExp>>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report?: InputMaybe<InsertAcquisReportsBoolExp>;
  report_chapter_status?: InputMaybe<InsertAcquisReportChapterStatusesBoolExp>;
  report_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  signed_documents?: InputMaybe<InsertAcquisSignedDocumentsBoolExp>;
  signed_documents_aggregate?: InputMaybe<InsertAcquisSignedDocumentsAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisReportChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportChaptersPkey = 'report_chapters_pkey'
}

export interface InsertAcquisReportChaptersInsertInput {
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertAcquisReportsObjRelInsertInput>;
  report_chapter_status?: InputMaybe<InsertAcquisReportChapterStatusesObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  signed_documents?: InputMaybe<InsertAcquisSignedDocumentsArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisReportChaptersObjRelInsertInput {
  data: InsertAcquisReportChaptersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportChaptersOnConflict>;
}

export interface InsertAcquisReportChaptersOnConflict {
  constraint: InsertAcquisReportChaptersConstraint;
  update_columns: Array<InsertAcquisReportChaptersUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
}

export enum InsertAcquisReportChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisReportChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisReportStatusesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportStatusesBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportStatusesBoolExp>>;
  reports?: InputMaybe<InsertAcquisReportsBoolExp>;
  reports_aggregate?: InputMaybe<InsertAcquisReportsAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisReportStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportStatusesPkey = 'report_statuses_pkey'
}

export enum InsertAcquisReportStatusesEnum {
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING'
}

export interface InsertAcquisReportStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisReportStatusesEnum>;
  _in?: InputMaybe<Array<InsertAcquisReportStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisReportStatusesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisReportStatusesEnum>>;
}

export interface InsertAcquisReportStatusesInsertInput {
  reports?: InputMaybe<InsertAcquisReportsArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisReportStatusesObjRelInsertInput {
  data: InsertAcquisReportStatusesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportStatusesOnConflict>;
}

export interface InsertAcquisReportStatusesOnConflict {
  constraint: InsertAcquisReportStatusesConstraint;
  update_columns: Array<InsertAcquisReportStatusesUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportStatusesBoolExp>;
}

export enum InsertAcquisReportStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertAcquisReportTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertAcquisReportTaskActivityAggregateBoolExpCount>;
}

export interface InsertAcquisReportTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisReportTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportTaskActivityBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisReportTaskActivityArrRelInsertInput {
  data: Array<InsertAcquisReportTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisReportTaskActivityOnConflict>;
}

export interface InsertAcquisReportTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report_task?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  status?: InputMaybe<InsertAcquisReportTaskStatusesEnumComparisonExp>;
}

export enum InsertAcquisReportTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskActivityPkey = 'report_task_activity_pkey'
}

export interface InsertAcquisReportTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertAcquisReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertAcquisReportTaskStatusesEnum>;
}

export interface InsertAcquisReportTaskActivityOnConflict {
  constraint: InsertAcquisReportTaskActivityConstraint;
  update_columns: Array<InsertAcquisReportTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportTaskActivityBoolExp>;
}

export enum InsertAcquisReportTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  Status = 'status'
}

export enum InsertAcquisReportTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  Status = 'status'
}

export interface InsertAcquisReportTaskCommentAggregateBoolExp {
  count?: InputMaybe<InsertAcquisReportTaskCommentAggregateBoolExpCount>;
}

export interface InsertAcquisReportTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisReportTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportTaskCommentBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisReportTaskCommentArrRelInsertInput {
  data: Array<InsertAcquisReportTaskCommentInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisReportTaskCommentOnConflict>;
}

export interface InsertAcquisReportTaskCommentBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportTaskCommentBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportTaskCommentBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportTaskCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  comment?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report_task?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisReportTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskCommentPkey = 'report_task_comment_pkey'
}

export interface InsertAcquisReportTaskCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertAcquisReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisReportTaskCommentOnConflict {
  constraint: InsertAcquisReportTaskCommentConstraint;
  update_columns: Array<InsertAcquisReportTaskCommentUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportTaskCommentBoolExp>;
}

export enum InsertAcquisReportTaskCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisReportTaskCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisReportTaskStatusesBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportTaskStatusesBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportTaskStatusesBoolExp>>;
  report_tasks?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertAcquisReportTasksAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisReportTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportTaskStatusesPkey = 'report_task_statuses_pkey'
}

export enum InsertAcquisReportTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertAcquisReportTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisReportTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertAcquisReportTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisReportTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisReportTaskStatusesEnum>>;
}

export interface InsertAcquisReportTaskStatusesInsertInput {
  report_tasks?: InputMaybe<InsertAcquisReportTasksArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisReportTaskStatusesObjRelInsertInput {
  data: InsertAcquisReportTaskStatusesInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportTaskStatusesOnConflict>;
}

export interface InsertAcquisReportTaskStatusesOnConflict {
  constraint: InsertAcquisReportTaskStatusesConstraint;
  update_columns: Array<InsertAcquisReportTaskStatusesUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportTaskStatusesBoolExp>;
}

export enum InsertAcquisReportTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertAcquisReportTasksAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisReportTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisReportTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisReportTasksAggregateBoolExpCount>;
}

export interface InsertAcquisReportTasksAggregateBoolExpBoolAnd {
  arguments: InsertAcquisReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisReportTasksAggregateBoolExpBoolOr {
  arguments: InsertAcquisReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisReportTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisReportTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisReportTasksArrRelInsertInput {
  data: Array<InsertAcquisReportTasksInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisReportTasksOnConflict>;
}

export interface InsertAcquisReportTasksBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportTasksBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportTasksBoolExp>>;
  assignee_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  completition_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  final_answer_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_member?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  is_priority?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  negotiator?: InputMaybe<InsertAcquisUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  question?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  question_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report?: InputMaybe<InsertAcquisReportsBoolExp>;
  report_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report_task_activities?: InputMaybe<InsertAcquisReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<InsertAcquisReportTaskActivityAggregateBoolExp>;
  report_task_answers?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  report_task_answers_aggregate?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExp>;
  report_task_comments?: InputMaybe<InsertAcquisReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<InsertAcquisReportTaskCommentAggregateBoolExp>;
  report_task_final_answer?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  report_task_status?: InputMaybe<InsertAcquisReportTaskStatusesBoolExp>;
  status?: InputMaybe<InsertAcquisReportTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  updated_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisReportTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTasksPkey = 'report_tasks_pkey'
}

export interface InsertAcquisReportTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  final_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_priority?: InputMaybe<Scalars['Boolean']['input']>;
  negotiator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertAcquisQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertAcquisReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_activities?: InputMaybe<InsertAcquisReportTaskActivityArrRelInsertInput>;
  report_task_answers?: InputMaybe<InsertAcquisReportAnswersArrRelInsertInput>;
  report_task_comments?: InputMaybe<InsertAcquisReportTaskCommentArrRelInsertInput>;
  report_task_final_answer?: InputMaybe<InsertAcquisReportAnswersObjRelInsertInput>;
  report_task_status?: InputMaybe<InsertAcquisReportTaskStatusesObjRelInsertInput>;
  status?: InputMaybe<InsertAcquisReportTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisReportTasksObjRelInsertInput {
  data: InsertAcquisReportTasksInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportTasksOnConflict>;
}

export interface InsertAcquisReportTasksOnConflict {
  constraint: InsertAcquisReportTasksConstraint;
  update_columns: Array<InsertAcquisReportTasksUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportTasksBoolExp>;
}

export enum InsertAcquisReportTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export enum InsertAcquisReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

export enum InsertAcquisReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

export enum InsertAcquisReportTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface InsertAcquisReportsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisReportsAggregateBoolExpCount>;
}

export interface InsertAcquisReportsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisReportsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisReportsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisReportsArrRelInsertInput {
  data: Array<InsertAcquisReportsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisReportsOnConflict>;
}

export interface InsertAcquisReportsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisReportsBoolExp>>;
  _not?: InputMaybe<InsertAcquisReportsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisReportsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by?: InputMaybe<InsertAcquisUuidComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  recommendation?: InputMaybe<InsertAcquisRecommendationsBoolExp>;
  recommendations?: InputMaybe<InsertAcquisRecommendationsBoolExp>;
  recommendations_aggregate?: InputMaybe<InsertAcquisRecommendationsAggregateBoolExp>;
  report_answers?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExp>;
  report_chapters?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertAcquisReportChaptersAggregateBoolExp>;
  report_name?: InputMaybe<InsertAcquisStringComparisonExp>;
  report_status?: InputMaybe<InsertAcquisReportStatusesBoolExp>;
  report_tasks?: InputMaybe<InsertAcquisReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertAcquisReportTasksAggregateBoolExp>;
  status?: InputMaybe<InsertAcquisReportStatusesEnumComparisonExp>;
  total_priorities?: InputMaybe<InsertAcquisBigintComparisonExp>;
  total_questions?: InputMaybe<InsertAcquisIntComparisonExp>;
  total_tasks_done?: InputMaybe<InsertAcquisBigintComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisReportsConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

export interface InsertAcquisReportsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  recommendation?: InputMaybe<InsertAcquisRecommendationsObjRelInsertInput>;
  recommendations?: InputMaybe<InsertAcquisRecommendationsArrRelInsertInput>;
  report_answers?: InputMaybe<InsertAcquisReportAnswersArrRelInsertInput>;
  report_chapters?: InputMaybe<InsertAcquisReportChaptersArrRelInsertInput>;
  report_name?: InputMaybe<Scalars['String']['input']>;
  report_status?: InputMaybe<InsertAcquisReportStatusesObjRelInsertInput>;
  report_tasks?: InputMaybe<InsertAcquisReportTasksArrRelInsertInput>;
  status?: InputMaybe<InsertAcquisReportStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisReportsObjRelInsertInput {
  data: InsertAcquisReportsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisReportsOnConflict>;
}

export interface InsertAcquisReportsOnConflict {
  constraint: InsertAcquisReportsConstraint;
  update_columns: Array<InsertAcquisReportsUpdateColumn>;
  where?: InputMaybe<InsertAcquisReportsBoolExp>;
}

export enum InsertAcquisReportsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisReportsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisRoadmapMeasureTypeEnum {
  KapacitetiZbatues = 'KAPACITETI_ZBATUES',
  KuadriStrategjik = 'KUADRI_STRATEGJIK',
  Performance = 'PERFORMANCE'
}

export interface InsertAcquisRoadmapMeasureTypeEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisRoadmapMeasureTypeEnum>;
  _in?: InputMaybe<Array<InsertAcquisRoadmapMeasureTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisRoadmapMeasureTypeEnum>;
  _nin?: InputMaybe<Array<InsertAcquisRoadmapMeasureTypeEnum>>;
}

export interface InsertAcquisSignedDocumentsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisSignedDocumentsAggregateBoolExpCount>;
}

export interface InsertAcquisSignedDocumentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisSignedDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisSignedDocumentsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisSignedDocumentsArrRelInsertInput {
  data: Array<InsertAcquisSignedDocumentsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisSignedDocumentsOnConflict>;
}

export interface InsertAcquisSignedDocumentsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisSignedDocumentsBoolExp>>;
  _not?: InputMaybe<InsertAcquisSignedDocumentsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisSignedDocumentsBoolExp>>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  document?: InputMaybe<InsertAcquisDocumentsBoolExp>;
  document_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  report_chapter?: InputMaybe<InsertAcquisReportChaptersBoolExp>;
  report_chapter_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  user?: InputMaybe<InsertAcquisUsersBoolExp>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
}

export enum InsertAcquisSignedDocumentsConstraint {
  /** unique or primary key constraint on columns "id" */
  SignedDocumentsPkey = 'signed_documents_pkey'
}

export interface InsertAcquisSignedDocumentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  document?: InputMaybe<InsertAcquisDocumentsObjRelInsertInput>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_chapter?: InputMaybe<InsertAcquisReportChaptersObjRelInsertInput>;
  report_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  work_plan_task?: InputMaybe<InsertAcquisPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertAcquisSignedDocumentsOnConflict {
  constraint: InsertAcquisSignedDocumentsConstraint;
  update_columns: Array<InsertAcquisSignedDocumentsUpdateColumn>;
  where?: InputMaybe<InsertAcquisSignedDocumentsBoolExp>;
}

export enum InsertAcquisSignedDocumentsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportChapterId = 'report_chapter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertAcquisSignedDocumentsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportChapterId = 'report_chapter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertAcquisStringArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
}

export interface InsertAcquisStringComparisonExp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  _niregex?: InputMaybe<Scalars['String']['input']>;
  _nlike?: InputMaybe<Scalars['String']['input']>;
  _nregex?: InputMaybe<Scalars['String']['input']>;
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  _regex?: InputMaybe<Scalars['String']['input']>;
  _similar?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisSubChapterLeadInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsAggregateBoolExpCount>;
}

export interface InsertAcquisSubChapterLeadInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisSubChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisSubChapterLeadInstitutionsArrRelInsertInput {
  data: Array<InsertAcquisSubChapterLeadInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsOnConflict>;
}

export interface InsertAcquisSubChapterLeadInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisSubChapterLeadInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisSubChapterLeadInstitutionsBoolExp>>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  institution?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
}

export enum InsertAcquisSubChapterLeadInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChapterLeadInstitutionsPkey = 'sub_chapter_lead_institutions_pkey'
}

export interface InsertAcquisSubChapterLeadInstitutionsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertAcquisInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

export interface InsertAcquisSubChapterLeadInstitutionsOnConflict {
  constraint: InsertAcquisSubChapterLeadInstitutionsConstraint;
  update_columns: Array<InsertAcquisSubChapterLeadInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsBoolExp>;
}

export enum InsertAcquisSubChapterLeadInstitutionsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

export enum InsertAcquisSubChapterLeadInstitutionsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

export interface InsertAcquisSubChaptersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisSubChaptersAggregateBoolExpCount>;
}

export interface InsertAcquisSubChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisSubChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisSubChaptersArrRelInsertInput {
  data: Array<InsertAcquisSubChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisSubChaptersOnConflict>;
}

export interface InsertAcquisSubChaptersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisSubChaptersBoolExp>>;
  _not?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisSubChaptersBoolExp>>;
  bookmarks?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertAcquisBookmarksAggregateBoolExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  gnpies?: InputMaybe<InsertAcquisGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertAcquisGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertAcquisIntComparisonExp>;
  questions?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<InsertAcquisQuestionsAggregateBoolExp>;
  sub_chapter_lead_institutions?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsBoolExp>;
  sub_chapter_lead_institutions_aggregate?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsAggregateBoolExp>;
  sub_chapter_number?: InputMaybe<InsertAcquisStringComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  title_en?: InputMaybe<InsertAcquisStringComparisonExp>;
  topics?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topics_aggregate?: InputMaybe<InsertAcquisTopicsAggregateBoolExp>;
}

export enum InsertAcquisSubChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChaptersPkey = 'sub_chapters_pkey',
  /** unique or primary key constraint on columns "sub_chapter_number" */
  SubChaptersSubChapterNumberKey = 'sub_chapters_sub_chapter_number_key'
}

export interface InsertAcquisSubChaptersInsertInput {
  bookmarks?: InputMaybe<InsertAcquisBookmarksArrRelInsertInput>;
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<InsertAcquisGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<InsertAcquisQuestionsArrRelInsertInput>;
  sub_chapter_lead_institutions?: InputMaybe<InsertAcquisSubChapterLeadInstitutionsArrRelInsertInput>;
  sub_chapter_number?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<InsertAcquisTopicsArrRelInsertInput>;
}

export interface InsertAcquisSubChaptersObjRelInsertInput {
  data: InsertAcquisSubChaptersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisSubChaptersOnConflict>;
}

export interface InsertAcquisSubChaptersOnConflict {
  constraint: InsertAcquisSubChaptersConstraint;
  update_columns: Array<InsertAcquisSubChaptersUpdateColumn>;
  where?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
}

export enum InsertAcquisSubChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export enum InsertAcquisSubChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export interface InsertAcquisTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertAcquisTaskActivityAggregateBoolExpCount>;
}

export interface InsertAcquisTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisTaskActivityBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisTaskActivityArrRelInsertInput {
  data: Array<InsertAcquisTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisTaskActivityOnConflict>;
}

export interface InsertAcquisTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertAcquisTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertAcquisTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  status?: InputMaybe<InsertAcquisTaskStatusesEnumComparisonExp>;
  task?: InputMaybe<InsertAcquisTaskBoolExp>;
  task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskActivityPkey = 'task_activity_pkey'
}

export interface InsertAcquisTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertAcquisTaskStatusesEnum>;
  task?: InputMaybe<InsertAcquisTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisTaskActivityOnConflict {
  constraint: InsertAcquisTaskActivityConstraint;
  update_columns: Array<InsertAcquisTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertAcquisTaskActivityBoolExp>;
}

export enum InsertAcquisTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisTaskAggregateBoolExp {
  count?: InputMaybe<InsertAcquisTaskAggregateBoolExpCount>;
}

export interface InsertAcquisTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisTaskBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisTaskAnswersAggregateBoolExp {
  count?: InputMaybe<InsertAcquisTaskAnswersAggregateBoolExpCount>;
}

export interface InsertAcquisTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisTaskAnswersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisTaskAnswersArrRelInsertInput {
  data: Array<InsertAcquisTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisTaskAnswersOnConflict>;
}

export interface InsertAcquisTaskAnswersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisTaskAnswersBoolExp>>;
  _not?: InputMaybe<InsertAcquisTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisTaskAnswersBoolExp>>;
  answer?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  integration_phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnumComparisonExp>;
  task?: InputMaybe<InsertAcquisTaskBoolExp>;
  task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskAnswersPkey = 'task_answers_pkey'
}

export interface InsertAcquisTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnum>;
  task?: InputMaybe<InsertAcquisTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisTaskAnswersOnConflict {
  constraint: InsertAcquisTaskAnswersConstraint;
  update_columns: Array<InsertAcquisTaskAnswersUpdateColumn>;
  where?: InputMaybe<InsertAcquisTaskAnswersBoolExp>;
}

export enum InsertAcquisTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationPhase = 'integration_phase',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationPhase = 'integration_phase',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisTaskArrRelInsertInput {
  data: Array<InsertAcquisTaskInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisTaskOnConflict>;
}

export interface InsertAcquisTaskBoolExp {
  _and?: InputMaybe<Array<InsertAcquisTaskBoolExp>>;
  _not?: InputMaybe<InsertAcquisTaskBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisTaskBoolExp>>;
  assignee?: InputMaybe<InsertAcquisUsersBoolExp>;
  assignee_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  children?: InputMaybe<InsertAcquisTaskBoolExp>;
  children_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  completition_date?: InputMaybe<InsertAcquisDateComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  deadline?: InputMaybe<InsertAcquisDateComparisonExp>;
  doc_ids?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  document?: InputMaybe<InsertAcquisDocumentsBoolExp>;
  document_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  event?: InputMaybe<InsertAcquisEventsBoolExp>;
  event_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_member?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  meeting?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  negotiator?: InputMaybe<InsertAcquisUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  parent?: InputMaybe<InsertAcquisTaskBoolExp>;
  parent_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  performance?: InputMaybe<InsertAcquisStringComparisonExp>;
  phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnumComparisonExp>;
  question?: InputMaybe<InsertAcquisQuestionsBoolExp>;
  question_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  reporter?: InputMaybe<InsertAcquisUsersBoolExp>;
  reporter_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  roadmap_measure_type?: InputMaybe<InsertAcquisRoadmapMeasureTypeEnumComparisonExp>;
  status?: InputMaybe<InsertAcquisTaskStatusesEnumComparisonExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  subchapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  task_activities?: InputMaybe<InsertAcquisTaskActivityBoolExp>;
  task_activities_aggregate?: InputMaybe<InsertAcquisTaskActivityAggregateBoolExp>;
  task_answers?: InputMaybe<InsertAcquisTaskAnswersBoolExp>;
  task_answers_aggregate?: InputMaybe<InsertAcquisTaskAnswersAggregateBoolExp>;
  task_comments?: InputMaybe<InsertAcquisTaskCommentsBoolExp>;
  task_comments_aggregate?: InputMaybe<InsertAcquisTaskCommentsAggregateBoolExp>;
  task_type?: InputMaybe<InsertAcquisTaskTypesEnumComparisonExp>;
  text?: InputMaybe<InsertAcquisStringComparisonExp>;
  topic?: InputMaybe<InsertAcquisTopicsBoolExp>;
  topic_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export interface InsertAcquisTaskCommentsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisTaskCommentsAggregateBoolExpCount>;
}

export interface InsertAcquisTaskCommentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisTaskCommentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisTaskCommentsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisTaskCommentsArrRelInsertInput {
  data: Array<InsertAcquisTaskCommentsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisTaskCommentsOnConflict>;
}

export interface InsertAcquisTaskCommentsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisTaskCommentsBoolExp>>;
  _not?: InputMaybe<InsertAcquisTaskCommentsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisTaskCommentsBoolExp>>;
  comment?: InputMaybe<InsertAcquisStringComparisonExp>;
  created_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertAcquisUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  task?: InputMaybe<InsertAcquisTaskBoolExp>;
  task_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  updated_at?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
}

export enum InsertAcquisTaskCommentsConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskCommentsPkey = 'task_comments_pkey'
}

export interface InsertAcquisTaskCommentsInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertAcquisTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisTaskCommentsOnConflict {
  constraint: InsertAcquisTaskCommentsConstraint;
  update_columns: Array<InsertAcquisTaskCommentsUpdateColumn>;
  where?: InputMaybe<InsertAcquisTaskCommentsBoolExp>;
}

export enum InsertAcquisTaskCommentsSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisTaskCommentsUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey'
}

export interface InsertAcquisTaskInsertInput {
  assignee?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  children?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document?: InputMaybe<InsertAcquisDocumentsObjRelInsertInput>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  event?: InputMaybe<InsertAcquisEventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertAcquisMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  parent?: InputMaybe<InsertAcquisTaskObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  performance?: InputMaybe<Scalars['String']['input']>;
  phase?: InputMaybe<InsertAcquisIntegrationPhasesEnumEnum>;
  question?: InputMaybe<InsertAcquisQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter?: InputMaybe<InsertAcquisUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  roadmap_measure_type?: InputMaybe<InsertAcquisRoadmapMeasureTypeEnum>;
  status?: InputMaybe<InsertAcquisTaskStatusesEnum>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  task_activities?: InputMaybe<InsertAcquisTaskActivityArrRelInsertInput>;
  task_answers?: InputMaybe<InsertAcquisTaskAnswersArrRelInsertInput>;
  task_comments?: InputMaybe<InsertAcquisTaskCommentsArrRelInsertInput>;
  task_type?: InputMaybe<InsertAcquisTaskTypesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<InsertAcquisTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertAcquisTaskObjRelInsertInput {
  data: InsertAcquisTaskInsertInput;
  on_conflict?: InputMaybe<InsertAcquisTaskOnConflict>;
}

export interface InsertAcquisTaskOnConflict {
  constraint: InsertAcquisTaskConstraint;
  update_columns: Array<InsertAcquisTaskUpdateColumn>;
  where?: InputMaybe<InsertAcquisTaskBoolExp>;
}

export enum InsertAcquisTaskSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Performance = 'performance',
  /** column name */
  Phase = 'phase',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  RoadmapMeasureType = 'roadmap_measure_type',
  /** column name */
  Status = 'status',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertAcquisTaskStatusesEnum {
  Canceled = 'CANCELED',
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING',
  WaitingForAssignee = 'WAITING_FOR_ASSIGNEE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForReporter = 'WAITING_FOR_REPORTER',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertAcquisTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertAcquisTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisTaskStatusesEnum>>;
}

export enum InsertAcquisTaskTypesEnum {
  Event = 'EVENT',
  Integration = 'INTEGRATION',
  MeetingTask = 'MEETING_TASK',
  Roadmap = 'ROADMAP'
}

export interface InsertAcquisTaskTypesEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisTaskTypesEnum>;
  _in?: InputMaybe<Array<InsertAcquisTaskTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisTaskTypesEnum>;
  _nin?: InputMaybe<Array<InsertAcquisTaskTypesEnum>>;
}

export enum InsertAcquisTaskUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Performance = 'performance',
  /** column name */
  Phase = 'phase',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  RoadmapMeasureType = 'roadmap_measure_type',
  /** column name */
  Status = 'status',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertAcquisTimestamptzComparisonExp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

export interface InsertAcquisTimetzComparisonExp {
  _eq?: InputMaybe<Scalars['timetz']['input']>;
  _gt?: InputMaybe<Scalars['timetz']['input']>;
  _gte?: InputMaybe<Scalars['timetz']['input']>;
  _in?: InputMaybe<Array<Scalars['timetz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timetz']['input']>;
  _lte?: InputMaybe<Scalars['timetz']['input']>;
  _neq?: InputMaybe<Scalars['timetz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timetz']['input']>>;
}

export interface InsertAcquisTopicsAggregateBoolExp {
  count?: InputMaybe<InsertAcquisTopicsAggregateBoolExpCount>;
}

export interface InsertAcquisTopicsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisTopicsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisTopicsBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisTopicsArrRelInsertInput {
  data: Array<InsertAcquisTopicsInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisTopicsOnConflict>;
}

export interface InsertAcquisTopicsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisTopicsBoolExp>>;
  _not?: InputMaybe<InsertAcquisTopicsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisTopicsBoolExp>>;
  bookmarks?: InputMaybe<InsertAcquisBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertAcquisBookmarksAggregateBoolExp>;
  chapter?: InputMaybe<InsertAcquisChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  gnpies?: InputMaybe<InsertAcquisGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertAcquisGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertAcquisIntComparisonExp>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersBoolExp>;
  subchapter_id?: InputMaybe<InsertAcquisIntComparisonExp>;
  title?: InputMaybe<InsertAcquisStringComparisonExp>;
  title_en?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisTopicsConstraint {
  /** unique or primary key constraint on columns "id" */
  TopicsNewIdKey = 'topics_new_id_key',
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

export interface InsertAcquisTopicsInsertInput {
  bookmarks?: InputMaybe<InsertAcquisBookmarksArrRelInsertInput>;
  chapter?: InputMaybe<InsertAcquisChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<InsertAcquisGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter?: InputMaybe<InsertAcquisSubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisTopicsObjRelInsertInput {
  data: InsertAcquisTopicsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisTopicsOnConflict>;
}

export interface InsertAcquisTopicsOnConflict {
  constraint: InsertAcquisTopicsConstraint;
  update_columns: Array<InsertAcquisTopicsUpdateColumn>;
  where?: InputMaybe<InsertAcquisTopicsBoolExp>;
}

export enum InsertAcquisTopicsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export enum InsertAcquisTopicsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export interface InsertAcquisTypeInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertAcquisTypeInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertAcquisTypeInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisTypeInstitutionsBoolExp>>;
  institutions?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  institutions_aggregate?: InputMaybe<InsertAcquisInstitutionsAggregateBoolExp>;
  type?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisTypeInstitutionsConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeInstitutionsPkey = 'type_institutions_pkey'
}

export enum InsertAcquisTypeInstitutionsEnum {
  Pavarur = 'PAVARUR',
  Qendror = 'QENDROR',
  Varesie = 'VARESIE'
}

export interface InsertAcquisTypeInstitutionsEnumComparisonExp {
  _eq?: InputMaybe<InsertAcquisTypeInstitutionsEnum>;
  _in?: InputMaybe<Array<InsertAcquisTypeInstitutionsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertAcquisTypeInstitutionsEnum>;
  _nin?: InputMaybe<Array<InsertAcquisTypeInstitutionsEnum>>;
}

export interface InsertAcquisTypeInstitutionsInsertInput {
  institutions?: InputMaybe<InsertAcquisInstitutionsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisTypeInstitutionsObjRelInsertInput {
  data: InsertAcquisTypeInstitutionsInsertInput;
  on_conflict?: InputMaybe<InsertAcquisTypeInstitutionsOnConflict>;
}

export interface InsertAcquisTypeInstitutionsOnConflict {
  constraint: InsertAcquisTypeInstitutionsConstraint;
  update_columns: Array<InsertAcquisTypeInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertAcquisTypeInstitutionsBoolExp>;
}

export enum InsertAcquisTypeInstitutionsUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertAcquisUsersAggregateBoolExp {
  bool_and?: InputMaybe<InsertAcquisUsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertAcquisUsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertAcquisUsersAggregateBoolExpCount>;
}

export interface InsertAcquisUsersAggregateBoolExpBoolAnd {
  arguments: InsertAcquisUsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisUsersBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisUsersAggregateBoolExpBoolOr {
  arguments: InsertAcquisUsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisUsersBoolExp>;
  predicate: InsertAcquisBooleanComparisonExp;
}

export interface InsertAcquisUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertAcquisUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertAcquisUsersBoolExp>;
  predicate: InsertAcquisIntComparisonExp;
}

export interface InsertAcquisUsersArrRelInsertInput {
  data: Array<InsertAcquisUsersInsertInput>;
  on_conflict?: InputMaybe<InsertAcquisUsersOnConflict>;
}

export interface InsertAcquisUsersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisUsersBoolExp>>;
  _not?: InputMaybe<InsertAcquisUsersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisUsersBoolExp>>;
  activeMfaType?: InputMaybe<InsertAcquisStringComparisonExp>;
  avatarUrl?: InputMaybe<InsertAcquisStringComparisonExp>;
  createdAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  currentChallenge?: InputMaybe<InsertAcquisStringComparisonExp>;
  defaultRole?: InputMaybe<InsertAcquisAuthrolesEnumComparisonExp>;
  defaultRoleByRole?: InputMaybe<InsertAcquisAuthrolesBoolExp>;
  departamenti?: InputMaybe<InsertAcquisStringComparisonExp>;
  disabled?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  displayName?: InputMaybe<InsertAcquisStringComparisonExp>;
  dms_id?: InputMaybe<InsertAcquisNumericComparisonExp>;
  email?: InputMaybe<InsertAcquisCitextComparisonExp>;
  emailVerified?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  funksioni?: InputMaybe<InsertAcquisStringComparisonExp>;
  gnpies?: InputMaybe<InsertAcquisGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertAcquisGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  institution?: InputMaybe<InsertAcquisInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertAcquisUuidComparisonExp>;
  isAnonymous?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  lastSeen?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  locale?: InputMaybe<InsertAcquisStringComparisonExp>;
  meeting_users?: InputMaybe<InsertAcquisMeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<InsertAcquisMeetingUsersAggregateBoolExp>;
  meetings?: InputMaybe<InsertAcquisMeetingsBoolExp>;
  meetings_aggregate?: InputMaybe<InsertAcquisMeetingsAggregateBoolExp>;
  metadata?: InputMaybe<InsertAcquisJsonbComparisonExp>;
  newEmail?: InputMaybe<InsertAcquisCitextComparisonExp>;
  otpHash?: InputMaybe<InsertAcquisStringComparisonExp>;
  otpHashExpiresAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  otpMethodLastUsed?: InputMaybe<InsertAcquisStringComparisonExp>;
  passwordHash?: InputMaybe<InsertAcquisStringComparisonExp>;
  phoneNumber?: InputMaybe<InsertAcquisStringComparisonExp>;
  phoneNumberVerified?: InputMaybe<InsertAcquisBooleanComparisonExp>;
  refreshTokens?: InputMaybe<InsertAcquisAuthrefreshtokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<InsertAcquisAuthrefreshtokensAggregateBoolExp>;
  report_answers?: InputMaybe<InsertAcquisReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertAcquisReportAnswersAggregateBoolExp>;
  report_task_activities?: InputMaybe<InsertAcquisReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<InsertAcquisReportTaskActivityAggregateBoolExp>;
  report_task_comments?: InputMaybe<InsertAcquisReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<InsertAcquisReportTaskCommentAggregateBoolExp>;
  roles?: InputMaybe<InsertAcquisAuthuserrolesBoolExp>;
  roles_aggregate?: InputMaybe<InsertAcquisAuthuserrolesAggregateBoolExp>;
  securityKeys?: InputMaybe<InsertAcquisAuthusersecuritykeysBoolExp>;
  securityKeys_aggregate?: InputMaybe<InsertAcquisAuthusersecuritykeysAggregateBoolExp>;
  tasksAssigned?: InputMaybe<InsertAcquisTaskBoolExp>;
  tasksAssigned_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  tasksToReport?: InputMaybe<InsertAcquisTaskBoolExp>;
  tasksToReport_aggregate?: InputMaybe<InsertAcquisTaskAggregateBoolExp>;
  ticket?: InputMaybe<InsertAcquisStringComparisonExp>;
  ticketExpiresAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  totpSecret?: InputMaybe<InsertAcquisStringComparisonExp>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertAcquisCapacTrainingNeedsAggregateBoolExp>;
  training_participations?: InputMaybe<InsertAcquisCapacTrainingParticipantsBoolExp>;
  training_participations_aggregate?: InputMaybe<InsertAcquisCapacTrainingParticipantsAggregateBoolExp>;
  trainings?: InputMaybe<InsertAcquisCapacTrainingsBoolExp>;
  trainings_aggregate?: InputMaybe<InsertAcquisCapacTrainingsAggregateBoolExp>;
  updatedAt?: InputMaybe<InsertAcquisTimestamptzComparisonExp>;
  userProviders?: InputMaybe<InsertAcquisAuthuserprovidersBoolExp>;
  userProviders_aggregate?: InputMaybe<InsertAcquisAuthuserprovidersAggregateBoolExp>;
  username?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisUsersConstraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

export interface InsertAcquisUsersInsertInput {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<InsertAcquisAuthrolesEnum>;
  defaultRoleByRole?: InputMaybe<InsertAcquisAuthrolesObjRelInsertInput>;
  departamenti?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  funksioni?: InputMaybe<Scalars['String']['input']>;
  gnpies?: InputMaybe<InsertAcquisGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertAcquisInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  meeting_users?: InputMaybe<InsertAcquisMeetingUsersArrRelInsertInput>;
  meetings?: InputMaybe<InsertAcquisMeetingsArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<InsertAcquisAuthrefreshtokensArrRelInsertInput>;
  report_answers?: InputMaybe<InsertAcquisReportAnswersArrRelInsertInput>;
  report_task_activities?: InputMaybe<InsertAcquisReportTaskActivityArrRelInsertInput>;
  report_task_comments?: InputMaybe<InsertAcquisReportTaskCommentArrRelInsertInput>;
  roles?: InputMaybe<InsertAcquisAuthuserrolesArrRelInsertInput>;
  securityKeys?: InputMaybe<InsertAcquisAuthusersecuritykeysArrRelInsertInput>;
  tasksAssigned?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  tasksToReport?: InputMaybe<InsertAcquisTaskArrRelInsertInput>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertAcquisCapacTrainingNeedsArrRelInsertInput>;
  training_participations?: InputMaybe<InsertAcquisCapacTrainingParticipantsArrRelInsertInput>;
  trainings?: InputMaybe<InsertAcquisCapacTrainingsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<InsertAcquisAuthuserprovidersArrRelInsertInput>;
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisUsersObjRelInsertInput {
  data: InsertAcquisUsersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisUsersOnConflict>;
}

export interface InsertAcquisUsersOnConflict {
  constraint: InsertAcquisUsersConstraint;
  update_columns: Array<InsertAcquisUsersUpdateColumn>;
  where?: InputMaybe<InsertAcquisUsersBoolExp>;
}

export enum InsertAcquisUsersSelectColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Departamenti = 'departamenti',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Funksioni = 'funksioni',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export enum InsertAcquisUsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

export enum InsertAcquisUsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

export enum InsertAcquisUsersUpdateColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Departamenti = 'departamenti',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Funksioni = 'funksioni',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export interface InsertAcquisUuidArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _contains?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _eq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _lte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _neq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
}

export interface InsertAcquisUuidComparisonExp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

export interface InsertAcquisYearlyQuartersBoolExp {
  _and?: InputMaybe<Array<InsertAcquisYearlyQuartersBoolExp>>;
  _not?: InputMaybe<InsertAcquisYearlyQuartersBoolExp>;
  _or?: InputMaybe<Array<InsertAcquisYearlyQuartersBoolExp>>;
  bookmark_tasks?: InputMaybe<InsertAcquisBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertAcquisBookmarkTasksAggregateBoolExp>;
  measures?: InputMaybe<InsertAcquisPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertAcquisPkieMeasureAggregateBoolExp>;
  quarter?: InputMaybe<InsertAcquisStringComparisonExp>;
}

export enum InsertAcquisYearlyQuartersConstraint {
  /** unique or primary key constraint on columns "quarter" */
  YearlyQuartersPkey = 'yearly_quarters_pkey'
}

export interface InsertAcquisYearlyQuartersInsertInput {
  bookmark_tasks?: InputMaybe<InsertAcquisBookmarkTasksArrRelInsertInput>;
  measures?: InputMaybe<InsertAcquisPkieMeasureArrRelInsertInput>;
  quarter?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertAcquisYearlyQuartersObjRelInsertInput {
  data: InsertAcquisYearlyQuartersInsertInput;
  on_conflict?: InputMaybe<InsertAcquisYearlyQuartersOnConflict>;
}

export interface InsertAcquisYearlyQuartersOnConflict {
  constraint: InsertAcquisYearlyQuartersConstraint;
  update_columns: Array<InsertAcquisYearlyQuartersUpdateColumn>;
  where?: InputMaybe<InsertAcquisYearlyQuartersBoolExp>;
}

export enum InsertAcquisYearlyQuartersUpdateColumn {
  /** column name */
  Quarter = 'quarter'
}

export interface InsertDocumentAuthprovidersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthprovidersBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthprovidersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthprovidersBoolExp>>;
  id?: InputMaybe<InsertDocumentStringComparisonExp>;
  userProviders?: InputMaybe<InsertDocumentAuthuserprovidersBoolExp>;
  userProviders_aggregate?: InputMaybe<InsertDocumentAuthuserprovidersAggregateBoolExp>;
}

export enum InsertDocumentAuthprovidersConstraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

export interface InsertDocumentAuthprovidersInsertInput {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<InsertDocumentAuthuserprovidersArrRelInsertInput>;
}

export interface InsertDocumentAuthprovidersObjRelInsertInput {
  data: InsertDocumentAuthprovidersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentAuthprovidersOnConflict>;
}

export interface InsertDocumentAuthprovidersOnConflict {
  constraint: InsertDocumentAuthprovidersConstraint;
  update_columns: Array<InsertDocumentAuthprovidersUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthprovidersBoolExp>;
}

export enum InsertDocumentAuthprovidersUpdateColumn {
  /** column name */
  Id = 'id'
}

export interface InsertDocumentAuthrefreshtokensAggregateBoolExp {
  count?: InputMaybe<InsertDocumentAuthrefreshtokensAggregateBoolExpCount>;
}

export interface InsertDocumentAuthrefreshtokensAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentAuthrefreshtokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentAuthrefreshtokensBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentAuthrefreshtokensArrRelInsertInput {
  data: Array<InsertDocumentAuthrefreshtokensInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentAuthrefreshtokensOnConflict>;
}

export interface InsertDocumentAuthrefreshtokensBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthrefreshtokensBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthrefreshtokensBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthrefreshtokensBoolExp>>;
  createdAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  expiresAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  metadata?: InputMaybe<InsertDocumentJsonbComparisonExp>;
  refreshTokenHash?: InputMaybe<InsertDocumentStringComparisonExp>;
  refresh_token_type?: InputMaybe<InsertDocumentAuthrefreshtokentypesBoolExp>;
  type?: InputMaybe<InsertDocumentAuthrefreshtokentypesEnumComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  userId?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentAuthrefreshtokensConstraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

export interface InsertDocumentAuthrefreshtokensInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  refresh_token_type?: InputMaybe<InsertDocumentAuthrefreshtokentypesObjRelInsertInput>;
  type?: InputMaybe<InsertDocumentAuthrefreshtokentypesEnum>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentAuthrefreshtokensOnConflict {
  constraint: InsertDocumentAuthrefreshtokensConstraint;
  update_columns: Array<InsertDocumentAuthrefreshtokensUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthrefreshtokensBoolExp>;
}

export enum InsertDocumentAuthrefreshtokensSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export enum InsertDocumentAuthrefreshtokensUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export interface InsertDocumentAuthrefreshtokentypesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthrefreshtokentypesBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthrefreshtokentypesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthrefreshtokentypesBoolExp>>;
  comment?: InputMaybe<InsertDocumentStringComparisonExp>;
  refreshTokens?: InputMaybe<InsertDocumentAuthrefreshtokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<InsertDocumentAuthrefreshtokensAggregateBoolExp>;
  value?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentAuthrefreshtokentypesConstraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey'
}

export enum InsertDocumentAuthrefreshtokentypesEnum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular'
}

export interface InsertDocumentAuthrefreshtokentypesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentAuthrefreshtokentypesEnum>;
  _in?: InputMaybe<Array<InsertDocumentAuthrefreshtokentypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentAuthrefreshtokentypesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentAuthrefreshtokentypesEnum>>;
}

export interface InsertDocumentAuthrefreshtokentypesInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<InsertDocumentAuthrefreshtokensArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentAuthrefreshtokentypesObjRelInsertInput {
  data: InsertDocumentAuthrefreshtokentypesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentAuthrefreshtokentypesOnConflict>;
}

export interface InsertDocumentAuthrefreshtokentypesOnConflict {
  constraint: InsertDocumentAuthrefreshtokentypesConstraint;
  update_columns: Array<InsertDocumentAuthrefreshtokentypesUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthrefreshtokentypesBoolExp>;
}

export enum InsertDocumentAuthrefreshtokentypesUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface InsertDocumentAuthrolesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthrolesBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthrolesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthrolesBoolExp>>;
  role?: InputMaybe<InsertDocumentStringComparisonExp>;
  userRoles?: InputMaybe<InsertDocumentAuthuserrolesBoolExp>;
  userRoles_aggregate?: InputMaybe<InsertDocumentAuthuserrolesAggregateBoolExp>;
  usersByDefaultRole?: InputMaybe<InsertDocumentUsersBoolExp>;
  usersByDefaultRole_aggregate?: InputMaybe<InsertDocumentUsersAggregateBoolExp>;
}

export enum InsertDocumentAuthrolesConstraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

export enum InsertDocumentAuthrolesEnum {
  ChapterCommunication = 'CHAPTER_COMMUNICATION',
  ChapterCoordinator = 'CHAPTER_COORDINATOR',
  ChapterDefined = 'CHAPTER_DEFINED',
  ChapterKnowledge = 'CHAPTER_KNOWLEDGE',
  ChapterMonitoring = 'CHAPTER_MONITORING',
  ChiefGnpieSecretariat = 'CHIEF_GNPIE_SECRETARIAT',
  GnpieMember = 'GNPIE_MEMBER',
  GnpieSecretariat = 'GNPIE_SECRETARIAT',
  MainNegotiator = 'MAIN_NEGOTIATOR',
  Member = 'MEMBER',
  Negotiator = 'NEGOTIATOR',
  PmoSecretariat = 'PMO_SECRETARIAT',
  Subnegotiator = 'SUBNEGOTIATOR',
  Anonymous = 'anonymous',
  Me = 'me',
  User = 'user'
}

export interface InsertDocumentAuthrolesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentAuthrolesEnum>;
  _in?: InputMaybe<Array<InsertDocumentAuthrolesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentAuthrolesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentAuthrolesEnum>>;
}

export interface InsertDocumentAuthrolesInsertInput {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<InsertDocumentAuthuserrolesArrRelInsertInput>;
  usersByDefaultRole?: InputMaybe<InsertDocumentUsersArrRelInsertInput>;
}

export interface InsertDocumentAuthrolesObjRelInsertInput {
  data: InsertDocumentAuthrolesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentAuthrolesOnConflict>;
}

export interface InsertDocumentAuthrolesOnConflict {
  constraint: InsertDocumentAuthrolesConstraint;
  update_columns: Array<InsertDocumentAuthrolesUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthrolesBoolExp>;
}

export enum InsertDocumentAuthrolesUpdateColumn {
  /** column name */
  Role = 'role'
}

export interface InsertDocumentAuthuserprovidersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentAuthuserprovidersAggregateBoolExpCount>;
}

export interface InsertDocumentAuthuserprovidersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentAuthuserprovidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentAuthuserprovidersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentAuthuserprovidersArrRelInsertInput {
  data: Array<InsertDocumentAuthuserprovidersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentAuthuserprovidersOnConflict>;
}

export interface InsertDocumentAuthuserprovidersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthuserprovidersBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthuserprovidersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthuserprovidersBoolExp>>;
  accessToken?: InputMaybe<InsertDocumentStringComparisonExp>;
  createdAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  provider?: InputMaybe<InsertDocumentAuthprovidersBoolExp>;
  providerId?: InputMaybe<InsertDocumentStringComparisonExp>;
  providerUserId?: InputMaybe<InsertDocumentStringComparisonExp>;
  refreshToken?: InputMaybe<InsertDocumentStringComparisonExp>;
  updatedAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  userId?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentAuthuserprovidersConstraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key'
}

export interface InsertDocumentAuthuserprovidersInsertInput {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<InsertDocumentAuthprovidersObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentAuthuserprovidersOnConflict {
  constraint: InsertDocumentAuthuserprovidersConstraint;
  update_columns: Array<InsertDocumentAuthuserprovidersUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthuserprovidersBoolExp>;
}

export enum InsertDocumentAuthuserprovidersSelectColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export enum InsertDocumentAuthuserprovidersUpdateColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export interface InsertDocumentAuthuserrolesAggregateBoolExp {
  count?: InputMaybe<InsertDocumentAuthuserrolesAggregateBoolExpCount>;
}

export interface InsertDocumentAuthuserrolesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentAuthuserrolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentAuthuserrolesBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentAuthuserrolesArrRelInsertInput {
  data: Array<InsertDocumentAuthuserrolesInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentAuthuserrolesOnConflict>;
}

export interface InsertDocumentAuthuserrolesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthuserrolesBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthuserrolesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthuserrolesBoolExp>>;
  createdAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  role?: InputMaybe<InsertDocumentAuthrolesEnumComparisonExp>;
  roleByRole?: InputMaybe<InsertDocumentAuthrolesBoolExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  userId?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentAuthuserrolesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key'
}

export interface InsertDocumentAuthuserrolesInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<InsertDocumentAuthrolesEnum>;
  roleByRole?: InputMaybe<InsertDocumentAuthrolesObjRelInsertInput>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentAuthuserrolesOnConflict {
  constraint: InsertDocumentAuthuserrolesConstraint;
  update_columns: Array<InsertDocumentAuthuserrolesUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthuserrolesBoolExp>;
}

export enum InsertDocumentAuthuserrolesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export enum InsertDocumentAuthuserrolesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export interface InsertDocumentAuthusersecuritykeysAggregateBoolExp {
  count?: InputMaybe<InsertDocumentAuthusersecuritykeysAggregateBoolExpCount>;
}

export interface InsertDocumentAuthusersecuritykeysAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentAuthusersecuritykeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentAuthusersecuritykeysBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentAuthusersecuritykeysArrRelInsertInput {
  data: Array<InsertDocumentAuthusersecuritykeysInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentAuthusersecuritykeysOnConflict>;
}

export interface InsertDocumentAuthusersecuritykeysBoolExp {
  _and?: InputMaybe<Array<InsertDocumentAuthusersecuritykeysBoolExp>>;
  _not?: InputMaybe<InsertDocumentAuthusersecuritykeysBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentAuthusersecuritykeysBoolExp>>;
  counter?: InputMaybe<InsertDocumentBigintComparisonExp>;
  credentialId?: InputMaybe<InsertDocumentStringComparisonExp>;
  credentialPublicKey?: InputMaybe<InsertDocumentByteaComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  nickname?: InputMaybe<InsertDocumentStringComparisonExp>;
  transports?: InputMaybe<InsertDocumentStringComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  userId?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentAuthusersecuritykeysConstraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey'
}

export interface InsertDocumentAuthusersecuritykeysInsertInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentAuthusersecuritykeysOnConflict {
  constraint: InsertDocumentAuthusersecuritykeysConstraint;
  update_columns: Array<InsertDocumentAuthusersecuritykeysUpdateColumn>;
  where?: InputMaybe<InsertDocumentAuthusersecuritykeysBoolExp>;
}

export enum InsertDocumentAuthusersecuritykeysSelectColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export enum InsertDocumentAuthusersecuritykeysUpdateColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export interface InsertDocumentBigintComparisonExp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

export interface InsertDocumentBookmarkTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertDocumentBookmarkTaskActivityAggregateBoolExpCount>;
}

export interface InsertDocumentBookmarkTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentBookmarkTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTaskActivityBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentBookmarkTaskActivityArrRelInsertInput {
  data: Array<InsertDocumentBookmarkTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentBookmarkTaskActivityOnConflict>;
}

export interface InsertDocumentBookmarkTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertDocumentBookmarkTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertDocumentBookmarkTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentBookmarkTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertDocumentStringComparisonExp>;
  bookmark_task?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentBookmarkTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskActivityPkey = 'bookmark_task_activity_pkey'
}

export interface InsertDocumentBookmarkTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  bookmark_task?: InputMaybe<InsertDocumentBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentBookmarkTaskActivityOnConflict {
  constraint: InsertDocumentBookmarkTaskActivityConstraint;
  update_columns: Array<InsertDocumentBookmarkTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertDocumentBookmarkTaskActivityBoolExp>;
}

export enum InsertDocumentBookmarkTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentBookmarkTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentBookmarkTaskAnswersAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentBookmarkTaskAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentBookmarkTaskAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentBookmarkTaskAnswersAggregateBoolExpCount>;
}

export interface InsertDocumentBookmarkTaskAnswersAggregateBoolExpBoolAnd {
  arguments: InsertDocumentBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTaskAnswersBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentBookmarkTaskAnswersAggregateBoolExpBoolOr {
  arguments: InsertDocumentBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTaskAnswersBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentBookmarkTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentBookmarkTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTaskAnswersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentBookmarkTaskAnswersArrRelInsertInput {
  data: Array<InsertDocumentBookmarkTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentBookmarkTaskAnswersOnConflict>;
}

export interface InsertDocumentBookmarkTaskAnswersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentBookmarkTaskAnswersBoolExp>>;
  _not?: InputMaybe<InsertDocumentBookmarkTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentBookmarkTaskAnswersBoolExp>>;
  answer?: InputMaybe<InsertDocumentStringComparisonExp>;
  asnwered_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  bookmark_task?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_completed?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentBookmarkTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskAnswersPkey = 'bookmark_task_answers_pkey'
}

export interface InsertDocumentBookmarkTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  asnwered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_task?: InputMaybe<InsertDocumentBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentBookmarkTaskAnswersOnConflict {
  constraint: InsertDocumentBookmarkTaskAnswersConstraint;
  update_columns: Array<InsertDocumentBookmarkTaskAnswersUpdateColumn>;
  where?: InputMaybe<InsertDocumentBookmarkTaskAnswersBoolExp>;
}

export enum InsertDocumentBookmarkTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertDocumentBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertDocumentBookmarkTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentBookmarkTaskCommentAggregateBoolExp {
  count?: InputMaybe<InsertDocumentBookmarkTaskCommentAggregateBoolExpCount>;
}

export interface InsertDocumentBookmarkTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentBookmarkTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTaskCommentBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentBookmarkTaskCommentArrRelInsertInput {
  data: Array<InsertDocumentBookmarkTaskCommentInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentBookmarkTaskCommentOnConflict>;
}

export interface InsertDocumentBookmarkTaskCommentBoolExp {
  _and?: InputMaybe<Array<InsertDocumentBookmarkTaskCommentBoolExp>>;
  _not?: InputMaybe<InsertDocumentBookmarkTaskCommentBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentBookmarkTaskCommentBoolExp>>;
  bookmark_task?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  comment?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentBookmarkTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskCommentPkey = 'bookmark_task_comment_pkey'
}

export interface InsertDocumentBookmarkTaskCommentInsertInput {
  bookmark_task?: InputMaybe<InsertDocumentBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentBookmarkTaskCommentOnConflict {
  constraint: InsertDocumentBookmarkTaskCommentConstraint;
  update_columns: Array<InsertDocumentBookmarkTaskCommentUpdateColumn>;
  where?: InputMaybe<InsertDocumentBookmarkTaskCommentBoolExp>;
}

export enum InsertDocumentBookmarkTaskCommentSelectColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentBookmarkTaskCommentUpdateColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentBookmarkTaskMeasuresBoolExp {
  _and?: InputMaybe<Array<InsertDocumentBookmarkTaskMeasuresBoolExp>>;
  _not?: InputMaybe<InsertDocumentBookmarkTaskMeasuresBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentBookmarkTaskMeasuresBoolExp>>;
  bookmark_tasks?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertDocumentBookmarkTasksAggregateBoolExp>;
  measure?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentBookmarkTaskMeasuresConstraint {
  /** unique or primary key constraint on columns "measure" */
  BookmarkTaskMeasuresPkey = 'bookmark_task_measures_pkey'
}

export interface InsertDocumentBookmarkTaskMeasuresInsertInput {
  bookmark_tasks?: InputMaybe<InsertDocumentBookmarkTasksArrRelInsertInput>;
  measure?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentBookmarkTaskMeasuresObjRelInsertInput {
  data: InsertDocumentBookmarkTaskMeasuresInsertInput;
  on_conflict?: InputMaybe<InsertDocumentBookmarkTaskMeasuresOnConflict>;
}

export interface InsertDocumentBookmarkTaskMeasuresOnConflict {
  constraint: InsertDocumentBookmarkTaskMeasuresConstraint;
  update_columns: Array<InsertDocumentBookmarkTaskMeasuresUpdateColumn>;
  where?: InputMaybe<InsertDocumentBookmarkTaskMeasuresBoolExp>;
}

export enum InsertDocumentBookmarkTaskMeasuresUpdateColumn {
  /** column name */
  Measure = 'measure'
}

export enum InsertDocumentBookmarkTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertDocumentBookmarkTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentBookmarkTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertDocumentBookmarkTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentBookmarkTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentBookmarkTaskStatusesEnum>>;
}

export interface InsertDocumentBookmarkTasksAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentBookmarkTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentBookmarkTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentBookmarkTasksAggregateBoolExpCount>;
}

export interface InsertDocumentBookmarkTasksAggregateBoolExpBoolAnd {
  arguments: InsertDocumentBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentBookmarkTasksAggregateBoolExpBoolOr {
  arguments: InsertDocumentBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentBookmarkTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentBookmarkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentBookmarkTasksArrRelInsertInput {
  data: Array<InsertDocumentBookmarkTasksInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentBookmarkTasksOnConflict>;
}

export interface InsertDocumentBookmarkTasksBoolExp {
  _and?: InputMaybe<Array<InsertDocumentBookmarkTasksBoolExp>>;
  _not?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentBookmarkTasksBoolExp>>;
  assignee_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  bookmark?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  bookmark_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  bookmark_task_activities?: InputMaybe<InsertDocumentBookmarkTaskActivityBoolExp>;
  bookmark_task_activities_aggregate?: InputMaybe<InsertDocumentBookmarkTaskActivityAggregateBoolExp>;
  bookmark_task_answers?: InputMaybe<InsertDocumentBookmarkTaskAnswersBoolExp>;
  bookmark_task_answers_aggregate?: InputMaybe<InsertDocumentBookmarkTaskAnswersAggregateBoolExp>;
  bookmark_task_comments?: InputMaybe<InsertDocumentBookmarkTaskCommentBoolExp>;
  bookmark_task_comments_aggregate?: InputMaybe<InsertDocumentBookmarkTaskCommentAggregateBoolExp>;
  bookmark_task_measure?: InputMaybe<InsertDocumentBookmarkTaskMeasuresBoolExp>;
  budget_allocated_at_quarter?: InputMaybe<InsertDocumentStringComparisonExp>;
  budget_allocated_at_year?: InputMaybe<InsertDocumentIntComparisonExp>;
  budget_needed?: InputMaybe<InsertDocumentNumericComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertDocumentUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertDocumentUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  completition_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  gnpie_member?: InputMaybe<InsertDocumentUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertDocumentUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_completed?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  measure?: InputMaybe<InsertDocumentStringComparisonExp>;
  negotiator?: InputMaybe<InsertDocumentUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  specific_measure?: InputMaybe<InsertDocumentStringComparisonExp>;
  status?: InputMaybe<InsertDocumentBookmarkTaskStatusesEnumComparisonExp>;
  text?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  updated_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  yearly_quarter?: InputMaybe<InsertDocumentYearlyQuartersBoolExp>;
}

export enum InsertDocumentBookmarkTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTasksPkey = 'bookmark_tasks_pkey'
}

export interface InsertDocumentBookmarkTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark?: InputMaybe<InsertDocumentBookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task_activities?: InputMaybe<InsertDocumentBookmarkTaskActivityArrRelInsertInput>;
  bookmark_task_answers?: InputMaybe<InsertDocumentBookmarkTaskAnswersArrRelInsertInput>;
  bookmark_task_comments?: InputMaybe<InsertDocumentBookmarkTaskCommentArrRelInsertInput>;
  bookmark_task_measure?: InputMaybe<InsertDocumentBookmarkTaskMeasuresObjRelInsertInput>;
  budget_allocated_at_quarter?: InputMaybe<Scalars['String']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
  chapter_coordinator?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  gnpie_member?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<Scalars['String']['input']>;
  negotiator?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  specific_measure?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InsertDocumentBookmarkTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  yearly_quarter?: InputMaybe<InsertDocumentYearlyQuartersObjRelInsertInput>;
}

export interface InsertDocumentBookmarkTasksObjRelInsertInput {
  data: InsertDocumentBookmarkTasksInsertInput;
  on_conflict?: InputMaybe<InsertDocumentBookmarkTasksOnConflict>;
}

export interface InsertDocumentBookmarkTasksOnConflict {
  constraint: InsertDocumentBookmarkTasksConstraint;
  update_columns: Array<InsertDocumentBookmarkTasksUpdateColumn>;
  where?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
}

export enum InsertDocumentBookmarkTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export enum InsertDocumentBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertDocumentBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertDocumentBookmarkTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface InsertDocumentBookmarksAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentBookmarksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentBookmarksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentBookmarksAggregateBoolExpCount>;
}

export interface InsertDocumentBookmarksAggregateBoolExpBoolAnd {
  arguments: InsertDocumentBookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentBookmarksAggregateBoolExpBoolOr {
  arguments: InsertDocumentBookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentBookmarksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentBookmarksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentBookmarksArrRelInsertInput {
  data: Array<InsertDocumentBookmarksInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentBookmarksOnConflict>;
}

export interface InsertDocumentBookmarksBoolExp {
  _and?: InputMaybe<Array<InsertDocumentBookmarksBoolExp>>;
  _not?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentBookmarksBoolExp>>;
  assigned_to_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  assignee?: InputMaybe<InsertDocumentUsersBoolExp>;
  bookmark_tasks?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertDocumentBookmarkTasksAggregateBoolExp>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  code_id?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  id?: InputMaybe<InsertDocumentIntComparisonExp>;
  is_completed?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  recommendation?: InputMaybe<InsertDocumentRecommendationsBoolExp>;
  recommendation_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  reference?: InputMaybe<InsertDocumentStringComparisonExp>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  topic?: InputMaybe<InsertDocumentTopicsBoolExp>;
  topic_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
}

export enum InsertDocumentBookmarksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarksPkey = 'bookmarks_pkey'
}

export interface InsertDocumentBookmarksInsertInput {
  assigned_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  assignee?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  bookmark_tasks?: InputMaybe<InsertDocumentBookmarkTasksArrRelInsertInput>;
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  code_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  recommendation?: InputMaybe<InsertDocumentRecommendationsObjRelInsertInput>;
  recommendation_id?: InputMaybe<Scalars['uuid']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<InsertDocumentTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
}

export interface InsertDocumentBookmarksObjRelInsertInput {
  data: InsertDocumentBookmarksInsertInput;
  on_conflict?: InputMaybe<InsertDocumentBookmarksOnConflict>;
}

export interface InsertDocumentBookmarksOnConflict {
  constraint: InsertDocumentBookmarksConstraint;
  update_columns: Array<InsertDocumentBookmarksUpdateColumn>;
  where?: InputMaybe<InsertDocumentBookmarksBoolExp>;
}

export enum InsertDocumentBookmarksSelectColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentBookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentBookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentBookmarksUpdateColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentBooleanComparisonExp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

export interface InsertDocumentByteaComparisonExp {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
}

export interface InsertDocumentCapacBeneficiariesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacBeneficiariesBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacBeneficiariesBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user_Id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  email?: InputMaybe<InsertDocumentStringComparisonExp>;
  first_name?: InputMaybe<InsertDocumentStringComparisonExp>;
  function?: InputMaybe<InsertDocumentStringComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  institution_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  number?: InputMaybe<InsertDocumentStringComparisonExp>;
  project_beneficiaries?: InputMaybe<InsertDocumentCapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<InsertDocumentCapacProjectBeneficiariesAggregateBoolExp>;
  surname?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  BeneficiariesPkey = 'beneficiaries_pkey'
}

export interface InsertDocumentCapacBeneficiariesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_Id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<InsertDocumentCapacProjectBeneficiariesArrRelInsertInput>;
  surname?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacBeneficiariesObjRelInsertInput {
  data: InsertDocumentCapacBeneficiariesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacBeneficiariesOnConflict>;
}

export interface InsertDocumentCapacBeneficiariesOnConflict {
  constraint: InsertDocumentCapacBeneficiariesConstraint;
  update_columns: Array<InsertDocumentCapacBeneficiariesUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacBeneficiariesBoolExp>;
}

export enum InsertDocumentCapacBeneficiariesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_Id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Function = 'function',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  Number = 'number',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacChapterDonatorAggregateBoolExp {
  count?: InputMaybe<InsertDocumentCapacChapterDonatorAggregateBoolExpCount>;
}

export interface InsertDocumentCapacChapterDonatorAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacChapterDonatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacChapterDonatorBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacChapterDonatorArrRelInsertInput {
  data: Array<InsertDocumentCapacChapterDonatorInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacChapterDonatorOnConflict>;
}

export interface InsertDocumentCapacChapterDonatorBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacChapterDonatorBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacChapterDonatorBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacChapterDonatorBoolExp>>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  donator?: InputMaybe<InsertDocumentCapacDonatorsBoolExp>;
  donator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentCapacChapterDonatorConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterDonatorPkey = 'chapter_donator_pkey'
}

export interface InsertDocumentCapacChapterDonatorInsertInput {
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  donator?: InputMaybe<InsertDocumentCapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentCapacChapterDonatorOnConflict {
  constraint: InsertDocumentCapacChapterDonatorConstraint;
  update_columns: Array<InsertDocumentCapacChapterDonatorUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacChapterDonatorBoolExp>;
}

export enum InsertDocumentCapacChapterDonatorSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export enum InsertDocumentCapacChapterDonatorUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export interface InsertDocumentCapacDonatorsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacDonatorsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacDonatorsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacDonatorsBoolExp>>;
  address?: InputMaybe<InsertDocumentStringComparisonExp>;
  chapter_donators?: InputMaybe<InsertDocumentCapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<InsertDocumentCapacChapterDonatorAggregateBoolExp>;
  contact_email?: InputMaybe<InsertDocumentStringComparisonExp>;
  contact_function?: InputMaybe<InsertDocumentStringComparisonExp>;
  contact_person?: InputMaybe<InsertDocumentStringComparisonExp>;
  contact_phone?: InputMaybe<InsertDocumentStringComparisonExp>;
  country?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  integration_steps_supported?: InputMaybe<InsertDocumentNumericArrayComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  organisation?: InputMaybe<InsertDocumentStringComparisonExp>;
  planned_new_support?: InputMaybe<InsertDocumentStringComparisonExp>;
  projects?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<InsertDocumentCapacProjectsAggregateBoolExp>;
  suggestions?: InputMaybe<InsertDocumentStringComparisonExp>;
  supports_accelerated_integration?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacDonatorsConstraint {
  /** unique or primary key constraint on columns "id" */
  DonatorsPkey = 'donators_pkey'
}

export interface InsertDocumentCapacDonatorsInsertInput {
  address?: InputMaybe<Scalars['String']['input']>;
  chapter_donators?: InputMaybe<InsertDocumentCapacChapterDonatorArrRelInsertInput>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_function?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps_supported?: InputMaybe<Array<Scalars['numeric']['input']>>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<InsertDocumentCapacProjectsArrRelInsertInput>;
  suggestions?: InputMaybe<Scalars['String']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacDonatorsObjRelInsertInput {
  data: InsertDocumentCapacDonatorsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacDonatorsOnConflict>;
}

export interface InsertDocumentCapacDonatorsOnConflict {
  constraint: InsertDocumentCapacDonatorsConstraint;
  update_columns: Array<InsertDocumentCapacDonatorsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacDonatorsBoolExp>;
}

export enum InsertDocumentCapacDonatorsUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFunction = 'contact_function',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactPhone = 'contact_phone',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationStepsSupported = 'integration_steps_supported',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  Suggestions = 'suggestions',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacExpertsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacExpertsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacExpertsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacExpertsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  email?: InputMaybe<InsertDocumentStringComparisonExp>;
  expertise?: InputMaybe<InsertDocumentStringComparisonExp>;
  full_name?: InputMaybe<InsertDocumentStringComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  organization?: InputMaybe<InsertDocumentStringComparisonExp>;
  phone?: InputMaybe<InsertDocumentStringComparisonExp>;
  position?: InputMaybe<InsertDocumentStringComparisonExp>;
  trainings_experts?: InputMaybe<InsertDocumentCapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<InsertDocumentCapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacExpertsConstraint {
  /** unique or primary key constraint on columns "full_name" */
  ExpertsFullNameKey = 'experts_full_name_key',
  /** unique or primary key constraint on columns "id" */
  ExpertsPkey = 'experts_pkey'
}

export interface InsertDocumentCapacExpertsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  trainings_experts?: InputMaybe<InsertDocumentCapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacExpertsObjRelInsertInput {
  data: InsertDocumentCapacExpertsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacExpertsOnConflict>;
}

export interface InsertDocumentCapacExpertsOnConflict {
  constraint: InsertDocumentCapacExpertsConstraint;
  update_columns: Array<InsertDocumentCapacExpertsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacExpertsBoolExp>;
}

export enum InsertDocumentCapacExpertsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expertise = 'expertise',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacPriorityListsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacPriorityListsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacPriorityListsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacPriorityListsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  end_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_active?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  start_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacPriorityListsConstraint {
  /** unique or primary key constraint on columns "id" */
  PriorityListsPkey = 'priority_lists_pkey'
}

export interface InsertDocumentCapacPriorityListsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacPriorityListsObjRelInsertInput {
  data: InsertDocumentCapacPriorityListsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacPriorityListsOnConflict>;
}

export interface InsertDocumentCapacPriorityListsOnConflict {
  constraint: InsertDocumentCapacPriorityListsConstraint;
  update_columns: Array<InsertDocumentCapacPriorityListsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacPriorityListsBoolExp>;
}

export enum InsertDocumentCapacPriorityListsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacProjectBeneficiariesAggregateBoolExp {
  count?: InputMaybe<InsertDocumentCapacProjectBeneficiariesAggregateBoolExpCount>;
}

export interface InsertDocumentCapacProjectBeneficiariesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacProjectBeneficiariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacProjectBeneficiariesBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacProjectBeneficiariesArrRelInsertInput {
  data: Array<InsertDocumentCapacProjectBeneficiariesInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacProjectBeneficiariesOnConflict>;
}

export interface InsertDocumentCapacProjectBeneficiariesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacProjectBeneficiariesBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacProjectBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacProjectBeneficiariesBoolExp>>;
  beneficiary?: InputMaybe<InsertDocumentCapacBeneficiariesBoolExp>;
  beneficiary_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  project?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  project_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentCapacProjectBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectBeneficiariesPkey = 'project_beneficiaries_pkey'
}

export interface InsertDocumentCapacProjectBeneficiariesInsertInput {
  beneficiary?: InputMaybe<InsertDocumentCapacBeneficiariesObjRelInsertInput>;
  beneficiary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  project?: InputMaybe<InsertDocumentCapacProjectsObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentCapacProjectBeneficiariesOnConflict {
  constraint: InsertDocumentCapacProjectBeneficiariesConstraint;
  update_columns: Array<InsertDocumentCapacProjectBeneficiariesUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacProjectBeneficiariesBoolExp>;
}

export enum InsertDocumentCapacProjectBeneficiariesSelectColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export enum InsertDocumentCapacProjectBeneficiariesUpdateColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export interface InsertDocumentCapacProjectsAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentCapacProjectsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentCapacProjectsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentCapacProjectsAggregateBoolExpCount>;
}

export interface InsertDocumentCapacProjectsAggregateBoolExpBoolAnd {
  arguments: InsertDocumentCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentCapacProjectsAggregateBoolExpBoolOr {
  arguments: InsertDocumentCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentCapacProjectsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacProjectsArrRelInsertInput {
  data: Array<InsertDocumentCapacProjectsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacProjectsOnConflict>;
}

export interface InsertDocumentCapacProjectsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacProjectsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacProjectsBoolExp>>;
  activities?: InputMaybe<InsertDocumentStringComparisonExp>;
  beneficiary?: InputMaybe<InsertDocumentStringComparisonExp>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  disbursement?: InputMaybe<InsertDocumentNumericComparisonExp>;
  donator?: InputMaybe<InsertDocumentCapacDonatorsBoolExp>;
  donator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  end_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  expected_results?: InputMaybe<InsertDocumentStringComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  last_disbusement_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  other_suggestions?: InputMaybe<InsertDocumentStringComparisonExp>;
  outputs_delivered?: InputMaybe<InsertDocumentStringComparisonExp>;
  planned_new_support?: InputMaybe<InsertDocumentStringComparisonExp>;
  project_beneficiaries?: InputMaybe<InsertDocumentCapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<InsertDocumentCapacProjectBeneficiariesAggregateBoolExp>;
  start_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  supports_accelerated_integration?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  value?: InputMaybe<InsertDocumentNumericComparisonExp>;
}

export enum InsertDocumentCapacProjectsConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

export interface InsertDocumentCapacProjectsInsertInput {
  activities?: InputMaybe<Scalars['String']['input']>;
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  donator?: InputMaybe<InsertDocumentCapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  expected_results?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  last_disbusement_date?: InputMaybe<Scalars['date']['input']>;
  other_suggestions?: InputMaybe<Scalars['String']['input']>;
  outputs_delivered?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<InsertDocumentCapacProjectBeneficiariesArrRelInsertInput>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

export interface InsertDocumentCapacProjectsObjRelInsertInput {
  data: InsertDocumentCapacProjectsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacProjectsOnConflict>;
}

export interface InsertDocumentCapacProjectsOnConflict {
  constraint: InsertDocumentCapacProjectsConstraint;
  update_columns: Array<InsertDocumentCapacProjectsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
}

export enum InsertDocumentCapacProjectsSelectColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export enum InsertDocumentCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

export enum InsertDocumentCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

export enum InsertDocumentCapacProjectsUpdateColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export interface InsertDocumentCapacTaiexBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTaiexBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTaiexBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTaiexBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacTaiexConstraint {
  /** unique or primary key constraint on columns "id" */
  TaiexPkey = 'taiex_pkey'
}

export interface InsertDocumentCapacTaiexInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacTaiexObjRelInsertInput {
  data: InsertDocumentCapacTaiexInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacTaiexOnConflict>;
}

export interface InsertDocumentCapacTaiexOnConflict {
  constraint: InsertDocumentCapacTaiexConstraint;
  update_columns: Array<InsertDocumentCapacTaiexUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTaiexBoolExp>;
}

export enum InsertDocumentCapacTaiexUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacTrainingNeedsAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExpCount>;
}

export interface InsertDocumentCapacTrainingNeedsAggregateBoolExpBoolAnd {
  arguments: InsertDocumentCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentCapacTrainingNeedsAggregateBoolExpBoolOr {
  arguments: InsertDocumentCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentCapacTrainingNeedsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacTrainingNeedsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacTrainingNeedsArrRelInsertInput {
  data: Array<InsertDocumentCapacTrainingNeedsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacTrainingNeedsOnConflict>;
}

export interface InsertDocumentCapacTrainingNeedsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTrainingNeedsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTrainingNeedsBoolExp>>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_approved?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  need?: InputMaybe<InsertDocumentStringComparisonExp>;
  priority_list?: InputMaybe<InsertDocumentCapacPriorityListsBoolExp>;
  priority_list_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  taiex?: InputMaybe<InsertDocumentCapacTaiexBoolExp>;
  taiex_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  training?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  twinning?: InputMaybe<InsertDocumentCapacTwinningBoolExp>;
  twinning_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacTrainingNeedsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingNeedsPkey = 'training_needs_pkey'
}

export interface InsertDocumentCapacTrainingNeedsInsertInput {
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  need?: InputMaybe<Scalars['String']['input']>;
  priority_list?: InputMaybe<InsertDocumentCapacPriorityListsObjRelInsertInput>;
  priority_list_id?: InputMaybe<Scalars['uuid']['input']>;
  taiex?: InputMaybe<InsertDocumentCapacTaiexObjRelInsertInput>;
  taiex_id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertDocumentCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  twinning?: InputMaybe<InsertDocumentCapacTwinningObjRelInsertInput>;
  twinning_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacTrainingNeedsOnConflict {
  constraint: InsertDocumentCapacTrainingNeedsConstraint;
  update_columns: Array<InsertDocumentCapacTrainingNeedsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
}

export enum InsertDocumentCapacTrainingNeedsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

export enum InsertDocumentCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

export enum InsertDocumentCapacTrainingNeedsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacTrainingParticipantsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentCapacTrainingParticipantsAggregateBoolExpCount>;
}

export interface InsertDocumentCapacTrainingParticipantsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacTrainingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingParticipantsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacTrainingParticipantsArrRelInsertInput {
  data: Array<InsertDocumentCapacTrainingParticipantsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacTrainingParticipantsOnConflict>;
}

export interface InsertDocumentCapacTrainingParticipantsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTrainingParticipantsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTrainingParticipantsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTrainingParticipantsBoolExp>>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  training?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentCapacTrainingParticipantsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey = 'training_sessions_pkey'
}

export interface InsertDocumentCapacTrainingParticipantsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertDocumentCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentCapacTrainingParticipantsOnConflict {
  constraint: InsertDocumentCapacTrainingParticipantsConstraint;
  update_columns: Array<InsertDocumentCapacTrainingParticipantsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTrainingParticipantsBoolExp>;
}

export enum InsertDocumentCapacTrainingParticipantsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertDocumentCapacTrainingParticipantsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertDocumentCapacTrainingSessionsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentCapacTrainingSessionsAggregateBoolExpCount>;
}

export interface InsertDocumentCapacTrainingSessionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacTrainingSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingSessionsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacTrainingSessionsArrRelInsertInput {
  data: Array<InsertDocumentCapacTrainingSessionsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacTrainingSessionsOnConflict>;
}

export interface InsertDocumentCapacTrainingSessionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTrainingSessionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTrainingSessionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTrainingSessionsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  duration_mins?: InputMaybe<InsertDocumentNumericComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  location?: InputMaybe<InsertDocumentStringComparisonExp>;
  participant_ids?: InputMaybe<InsertDocumentUuidArrayComparisonExp>;
  starting_datetime?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  training?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacTrainingSessionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey1 = 'training_sessions_pkey1'
}

export interface InsertDocumentCapacTrainingSessionsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  participant_ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  starting_datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  training?: InputMaybe<InsertDocumentCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacTrainingSessionsOnConflict {
  constraint: InsertDocumentCapacTrainingSessionsConstraint;
  update_columns: Array<InsertDocumentCapacTrainingSessionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTrainingSessionsBoolExp>;
}

export enum InsertDocumentCapacTrainingSessionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentCapacTrainingSessionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacTrainingsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentCapacTrainingsAggregateBoolExpCount>;
}

export interface InsertDocumentCapacTrainingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacTrainingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacTrainingsArrRelInsertInput {
  data: Array<InsertDocumentCapacTrainingsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacTrainingsOnConflict>;
}

export interface InsertDocumentCapacTrainingsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTrainingsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTrainingsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  description?: InputMaybe<InsertDocumentStringComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  location?: InputMaybe<InsertDocumentStringComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  trainers?: InputMaybe<InsertDocumentStringComparisonExp>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExp>;
  training_participants?: InputMaybe<InsertDocumentCapacTrainingParticipantsBoolExp>;
  training_participants_aggregate?: InputMaybe<InsertDocumentCapacTrainingParticipantsAggregateBoolExp>;
  training_sessions?: InputMaybe<InsertDocumentCapacTrainingSessionsBoolExp>;
  training_sessions_aggregate?: InputMaybe<InsertDocumentCapacTrainingSessionsAggregateBoolExp>;
  trainings_experts?: InputMaybe<InsertDocumentCapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<InsertDocumentCapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacTrainingsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsPkey = 'trainings_pkey'
}

export interface InsertDocumentCapacTrainingsExpertsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentCapacTrainingsExpertsAggregateBoolExpCount>;
}

export interface InsertDocumentCapacTrainingsExpertsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentCapacTrainingsExpertsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentCapacTrainingsExpertsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentCapacTrainingsExpertsArrRelInsertInput {
  data: Array<InsertDocumentCapacTrainingsExpertsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentCapacTrainingsExpertsOnConflict>;
}

export interface InsertDocumentCapacTrainingsExpertsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTrainingsExpertsBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTrainingsExpertsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTrainingsExpertsBoolExp>>;
  expert?: InputMaybe<InsertDocumentCapacExpertsBoolExp>;
  expert_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  training?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentCapacTrainingsExpertsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsExpertsPkey = 'trainings_experts_pkey'
}

export interface InsertDocumentCapacTrainingsExpertsInsertInput {
  expert?: InputMaybe<InsertDocumentCapacExpertsObjRelInsertInput>;
  expert_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertDocumentCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentCapacTrainingsExpertsOnConflict {
  constraint: InsertDocumentCapacTrainingsExpertsConstraint;
  update_columns: Array<InsertDocumentCapacTrainingsExpertsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTrainingsExpertsBoolExp>;
}

export enum InsertDocumentCapacTrainingsExpertsSelectColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export enum InsertDocumentCapacTrainingsExpertsUpdateColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export interface InsertDocumentCapacTrainingsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainers?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsArrRelInsertInput>;
  training_participants?: InputMaybe<InsertDocumentCapacTrainingParticipantsArrRelInsertInput>;
  training_sessions?: InputMaybe<InsertDocumentCapacTrainingSessionsArrRelInsertInput>;
  trainings_experts?: InputMaybe<InsertDocumentCapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacTrainingsObjRelInsertInput {
  data: InsertDocumentCapacTrainingsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacTrainingsOnConflict>;
}

export interface InsertDocumentCapacTrainingsOnConflict {
  constraint: InsertDocumentCapacTrainingsConstraint;
  update_columns: Array<InsertDocumentCapacTrainingsUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
}

export enum InsertDocumentCapacTrainingsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentCapacTrainingsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentCapacTwinningBoolExp {
  _and?: InputMaybe<Array<InsertDocumentCapacTwinningBoolExp>>;
  _not?: InputMaybe<InsertDocumentCapacTwinningBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentCapacTwinningBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentCapacTwinningConstraint {
  /** unique or primary key constraint on columns "id" */
  TwinningPkey = 'twinning_pkey'
}

export interface InsertDocumentCapacTwinningInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentCapacTwinningObjRelInsertInput {
  data: InsertDocumentCapacTwinningInsertInput;
  on_conflict?: InputMaybe<InsertDocumentCapacTwinningOnConflict>;
}

export interface InsertDocumentCapacTwinningOnConflict {
  constraint: InsertDocumentCapacTwinningConstraint;
  update_columns: Array<InsertDocumentCapacTwinningUpdateColumn>;
  where?: InputMaybe<InsertDocumentCapacTwinningBoolExp>;
}

export enum InsertDocumentCapacTwinningUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentChapterLeadInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentChapterLeadInstitutionsAggregateBoolExpCount>;
}

export interface InsertDocumentChapterLeadInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentChapterLeadInstitutionsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentChapterLeadInstitutionsArrRelInsertInput {
  data: Array<InsertDocumentChapterLeadInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentChapterLeadInstitutionsOnConflict>;
}

export interface InsertDocumentChapterLeadInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentChapterLeadInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentChapterLeadInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentChapterLeadInstitutionsBoolExp>>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  institution_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentChapterLeadInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterLeadInstitutionsPkey = 'chapter_lead_institutions_pkey'
}

export interface InsertDocumentChapterLeadInstitutionsInsertInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentChapterLeadInstitutionsOnConflict {
  constraint: InsertDocumentChapterLeadInstitutionsConstraint;
  update_columns: Array<InsertDocumentChapterLeadInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentChapterLeadInstitutionsBoolExp>;
}

export enum InsertDocumentChapterLeadInstitutionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export enum InsertDocumentChapterLeadInstitutionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface InsertDocumentChaptersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentChaptersAggregateBoolExpCount>;
}

export interface InsertDocumentChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentChaptersArrRelInsertInput {
  data: Array<InsertDocumentChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentChaptersOnConflict>;
}

export interface InsertDocumentChaptersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentChaptersBoolExp>>;
  _not?: InputMaybe<InsertDocumentChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentChaptersBoolExp>>;
  acquis?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertDocumentBookmarksAggregateBoolExp>;
  chapter_donators?: InputMaybe<InsertDocumentCapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<InsertDocumentCapacChapterDonatorAggregateBoolExp>;
  chapter_lead_institutions?: InputMaybe<InsertDocumentChapterLeadInstitutionsBoolExp>;
  chapter_lead_institutions_aggregate?: InputMaybe<InsertDocumentChapterLeadInstitutionsAggregateBoolExp>;
  chapter_number?: InputMaybe<InsertDocumentStringComparisonExp>;
  chapter_title?: InputMaybe<InsertDocumentStringComparisonExp>;
  cluster?: InputMaybe<InsertDocumentClustersBoolExp>;
  cluster_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  gnpies?: InputMaybe<InsertDocumentGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertDocumentGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertDocumentIntComparisonExp>;
  new_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  phase?: InputMaybe<InsertDocumentStringComparisonExp>;
  projects?: InputMaybe<InsertDocumentCapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<InsertDocumentCapacProjectsAggregateBoolExp>;
  questions?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<InsertDocumentQuestionsAggregateBoolExp>;
  report_chapters?: InputMaybe<InsertDocumentReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertDocumentReportChaptersAggregateBoolExp>;
  sub_chapters?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  sub_chapters_aggregate?: InputMaybe<InsertDocumentSubChaptersAggregateBoolExp>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExp>;
  work_plan_tasks?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertDocumentPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertDocumentChaptersConstraint {
  /** unique or primary key constraint on columns "chapter_number" */
  ChaptersChapterNumberKey = 'chapters_chapter_number_key',
  /** unique or primary key constraint on columns "chapter_title" */
  ChaptersChapterTitleKey = 'chapters_chapter_title_key',
  /** unique or primary key constraint on columns "new_id" */
  ChaptersNewIdKey = 'chapters_new_id_key',
  /** unique or primary key constraint on columns "id" */
  ChaptersPkey = 'chapters_pkey'
}

export interface InsertDocumentChaptersInsertInput {
  acquis?: InputMaybe<InsertDocumentPkieAcquisArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksArrRelInsertInput>;
  chapter_donators?: InputMaybe<InsertDocumentCapacChapterDonatorArrRelInsertInput>;
  chapter_lead_institutions?: InputMaybe<InsertDocumentChapterLeadInstitutionsArrRelInsertInput>;
  chapter_number?: InputMaybe<Scalars['String']['input']>;
  chapter_title?: InputMaybe<Scalars['String']['input']>;
  cluster?: InputMaybe<InsertDocumentClustersObjRelInsertInput>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<InsertDocumentGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<InsertDocumentCapacProjectsArrRelInsertInput>;
  questions?: InputMaybe<InsertDocumentQuestionsArrRelInsertInput>;
  report_chapters?: InputMaybe<InsertDocumentReportChaptersArrRelInsertInput>;
  sub_chapters?: InputMaybe<InsertDocumentSubChaptersArrRelInsertInput>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsArrRelInsertInput>;
  work_plan_tasks?: InputMaybe<InsertDocumentPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertDocumentChaptersObjRelInsertInput {
  data: InsertDocumentChaptersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentChaptersOnConflict>;
}

export interface InsertDocumentChaptersOnConflict {
  constraint: InsertDocumentChaptersConstraint;
  update_columns: Array<InsertDocumentChaptersUpdateColumn>;
  where?: InputMaybe<InsertDocumentChaptersBoolExp>;
}

export enum InsertDocumentChaptersSelectColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Phase = 'phase'
}

export enum InsertDocumentChaptersUpdateColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Phase = 'phase'
}

export interface InsertDocumentCitextComparisonExp {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  _regex?: InputMaybe<Scalars['citext']['input']>;
  _similar?: InputMaybe<Scalars['citext']['input']>;
}

export interface InsertDocumentClustersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentClustersBoolExp>>;
  _not?: InputMaybe<InsertDocumentClustersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentClustersBoolExp>>;
  chapters?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapters_aggregate?: InputMaybe<InsertDocumentChaptersAggregateBoolExp>;
  color?: InputMaybe<InsertDocumentStringComparisonExp>;
  id?: InputMaybe<InsertDocumentIntComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentClustersConstraint {
  /** unique or primary key constraint on columns "id" */
  ClustersPkey = 'clusters_pkey',
  /** unique or primary key constraint on columns "title" */
  ClustersTitleKey = 'clusters_title_key'
}

export interface InsertDocumentClustersInsertInput {
  chapters?: InputMaybe<InsertDocumentChaptersArrRelInsertInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentClustersObjRelInsertInput {
  data: InsertDocumentClustersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentClustersOnConflict>;
}

export interface InsertDocumentClustersOnConflict {
  constraint: InsertDocumentClustersConstraint;
  update_columns: Array<InsertDocumentClustersUpdateColumn>;
  where?: InputMaybe<InsertDocumentClustersBoolExp>;
}

export enum InsertDocumentClustersUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export interface InsertDocumentDateComparisonExp {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
}

export interface InsertDocumentDocumentsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentDocumentsBoolExp>>;
  _not?: InputMaybe<InsertDocumentDocumentsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentDocumentsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  status?: InputMaybe<InsertDocumentTaskStatusesEnumComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentDocumentsConstraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey'
}

export interface InsertDocumentDocumentsInsertInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertDocumentTaskStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentDocumentsObjRelInsertInput {
  data: InsertDocumentDocumentsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentDocumentsOnConflict>;
}

export interface InsertDocumentDocumentsOnConflict {
  constraint: InsertDocumentDocumentsConstraint;
  update_columns: Array<InsertDocumentDocumentsUpdateColumn>;
  where?: InputMaybe<InsertDocumentDocumentsBoolExp>;
}

export enum InsertDocumentDocumentsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentGnpieAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentGnpieAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentGnpieAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentGnpieAggregateBoolExpCount>;
}

export interface InsertDocumentGnpieAggregateBoolExpBoolAnd {
  arguments: InsertDocumentGnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentGnpieBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentGnpieAggregateBoolExpBoolOr {
  arguments: InsertDocumentGnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentGnpieBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentGnpieAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentGnpieSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentGnpieBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentGnpieArrRelInsertInput {
  data: Array<InsertDocumentGnpieInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentGnpieOnConflict>;
}

export interface InsertDocumentGnpieBoolExp {
  _and?: InputMaybe<Array<InsertDocumentGnpieBoolExp>>;
  _not?: InputMaybe<InsertDocumentGnpieBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentGnpieBoolExp>>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_main_negotiator?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  role?: InputMaybe<InsertDocumentAuthrolesEnumComparisonExp>;
  roleByRole?: InputMaybe<InsertDocumentAuthrolesBoolExp>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  topic?: InputMaybe<InsertDocumentTopicsBoolExp>;
  topic_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentGnpieConstraint {
  /** unique or primary key constraint on columns "id" */
  GnpiePkey = 'gnpie_pkey'
}

export interface InsertDocumentGnpieInsertInput {
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_main_negotiator?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<InsertDocumentAuthrolesEnum>;
  roleByRole?: InputMaybe<InsertDocumentAuthrolesObjRelInsertInput>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic?: InputMaybe<InsertDocumentTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentGnpieOnConflict {
  constraint: InsertDocumentGnpieConstraint;
  update_columns: Array<InsertDocumentGnpieUpdateColumn>;
  where?: InputMaybe<InsertDocumentGnpieBoolExp>;
}

export enum InsertDocumentGnpieSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertDocumentGnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

export enum InsertDocumentGnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

export enum InsertDocumentGnpieUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertDocumentInstitutionsAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentInstitutionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentInstitutionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentInstitutionsAggregateBoolExpCount>;
}

export interface InsertDocumentInstitutionsAggregateBoolExpBoolAnd {
  arguments: InsertDocumentInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentInstitutionsAggregateBoolExpBoolOr {
  arguments: InsertDocumentInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentInstitutionsArrRelInsertInput {
  data: Array<InsertDocumentInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentInstitutionsOnConflict>;
}

export interface InsertDocumentInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentInstitutionsBoolExp>>;
  acquis_institutions?: InputMaybe<InsertDocumentPkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<InsertDocumentPkieAcquisInstitutionsAggregateBoolExp>;
  child_institutions?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  child_institutions_aggregate?: InputMaybe<InsertDocumentInstitutionsAggregateBoolExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  measures?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  name?: InputMaybe<InsertDocumentStringComparisonExp>;
  parent_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  parent_institution?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  short_name?: InputMaybe<InsertDocumentStringComparisonExp>;
  type?: InputMaybe<InsertDocumentTypeInstitutionsEnumComparisonExp>;
  type_institution?: InputMaybe<InsertDocumentTypeInstitutionsBoolExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  users?: InputMaybe<InsertDocumentUsersBoolExp>;
}

export enum InsertDocumentInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  InstitutionsPkey = 'institutions_pkey'
}

export interface InsertDocumentInstitutionsInsertInput {
  acquis_institutions?: InputMaybe<InsertDocumentPkieAcquisInstitutionsArrRelInsertInput>;
  child_institutions?: InputMaybe<InsertDocumentInstitutionsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measures?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_institution?: InputMaybe<InsertDocumentInstitutionsObjRelInsertInput>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InsertDocumentTypeInstitutionsEnum>;
  type_institution?: InputMaybe<InsertDocumentTypeInstitutionsObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
}

export interface InsertDocumentInstitutionsObjRelInsertInput {
  data: InsertDocumentInstitutionsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentInstitutionsOnConflict>;
}

export interface InsertDocumentInstitutionsOnConflict {
  constraint: InsertDocumentInstitutionsConstraint;
  update_columns: Array<InsertDocumentInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
}

export enum InsertDocumentInstitutionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentInstitutionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentIntComparisonExp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface InsertDocumentJsonbCastExp {
  String?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export interface InsertDocumentJsonbComparisonExp {
  _cast?: InputMaybe<InsertDocumentJsonbCastExp>;
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  _has_key?: InputMaybe<Scalars['String']['input']>;
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

export interface InsertDocumentMeetingNotesAggregateBoolExp {
  count?: InputMaybe<InsertDocumentMeetingNotesAggregateBoolExpCount>;
}

export interface InsertDocumentMeetingNotesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentMeetingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentMeetingNotesBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentMeetingNotesArrRelInsertInput {
  data: Array<InsertDocumentMeetingNotesInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentMeetingNotesOnConflict>;
}

export interface InsertDocumentMeetingNotesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentMeetingNotesBoolExp>>;
  _not?: InputMaybe<InsertDocumentMeetingNotesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentMeetingNotesBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  meeting?: InputMaybe<InsertDocumentMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  note?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
}

export enum InsertDocumentMeetingNotesConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingNotesPkey = 'meeting_notes_pkey'
}

export interface InsertDocumentMeetingNotesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertDocumentMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
}

export interface InsertDocumentMeetingNotesOnConflict {
  constraint: InsertDocumentMeetingNotesConstraint;
  update_columns: Array<InsertDocumentMeetingNotesUpdateColumn>;
  where?: InputMaybe<InsertDocumentMeetingNotesBoolExp>;
}

export enum InsertDocumentMeetingNotesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentMeetingNotesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentMeetingUsersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentMeetingUsersAggregateBoolExpCount>;
}

export interface InsertDocumentMeetingUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentMeetingUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentMeetingUsersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentMeetingUsersArrRelInsertInput {
  data: Array<InsertDocumentMeetingUsersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentMeetingUsersOnConflict>;
}

export interface InsertDocumentMeetingUsersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentMeetingUsersBoolExp>>;
  _not?: InputMaybe<InsertDocumentMeetingUsersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentMeetingUsersBoolExp>>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  meeting?: InputMaybe<InsertDocumentMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
  user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentMeetingUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingUsersPkey = 'meeting_users_pkey'
}

export interface InsertDocumentMeetingUsersInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertDocumentMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentMeetingUsersOnConflict {
  constraint: InsertDocumentMeetingUsersConstraint;
  update_columns: Array<InsertDocumentMeetingUsersUpdateColumn>;
  where?: InputMaybe<InsertDocumentMeetingUsersBoolExp>;
}

export enum InsertDocumentMeetingUsersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertDocumentMeetingUsersUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertDocumentMeetingsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentMeetingsAggregateBoolExpCount>;
}

export interface InsertDocumentMeetingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentMeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentMeetingsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentMeetingsArrRelInsertInput {
  data: Array<InsertDocumentMeetingsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentMeetingsOnConflict>;
}

export interface InsertDocumentMeetingsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentMeetingsBoolExp>>;
  _not?: InputMaybe<InsertDocumentMeetingsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentMeetingsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  meeting_date?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  meeting_notes?: InputMaybe<InsertDocumentMeetingNotesBoolExp>;
  meeting_notes_aggregate?: InputMaybe<InsertDocumentMeetingNotesAggregateBoolExp>;
  meeting_users?: InputMaybe<InsertDocumentMeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<InsertDocumentMeetingUsersAggregateBoolExp>;
  minutes_of_meeting?: InputMaybe<InsertDocumentStringComparisonExp>;
  tasks?: InputMaybe<InsertDocumentTaskBoolExp>;
  tasks_aggregate?: InputMaybe<InsertDocumentTaskAggregateBoolExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
}

export enum InsertDocumentMeetingsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingsPkey = 'meetings_pkey'
}

export interface InsertDocumentMeetingsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_date?: InputMaybe<Scalars['timestamptz']['input']>;
  meeting_notes?: InputMaybe<InsertDocumentMeetingNotesArrRelInsertInput>;
  meeting_users?: InputMaybe<InsertDocumentMeetingUsersArrRelInsertInput>;
  minutes_of_meeting?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<InsertDocumentTaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
}

export interface InsertDocumentMeetingsObjRelInsertInput {
  data: InsertDocumentMeetingsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentMeetingsOnConflict>;
}

export interface InsertDocumentMeetingsOnConflict {
  constraint: InsertDocumentMeetingsConstraint;
  update_columns: Array<InsertDocumentMeetingsUpdateColumn>;
  where?: InputMaybe<InsertDocumentMeetingsBoolExp>;
}

export enum InsertDocumentMeetingsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentMeetingsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentNumericArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _contains?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _eq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _lte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _neq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
}

export interface InsertDocumentNumericComparisonExp {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
}

export interface InsertDocumentOutput {
  __typename?: 'InsertDocumentOutput';
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  deadline: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

export interface InsertDocumentPkieAcquisAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExpCount>;
}

export interface InsertDocumentPkieAcquisAggregateBoolExpBoolAnd {
  arguments: InsertDocumentPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieAcquisAggregateBoolExpBoolOr {
  arguments: InsertDocumentPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieAcquisAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieAcquisSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieAcquisArrRelInsertInput {
  data: Array<InsertDocumentPkieAcquisInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieAcquisOnConflict>;
}

export interface InsertDocumentPkieAcquisBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieAcquisBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieAcquisBoolExp>>;
  acquis_institutions?: InputMaybe<InsertDocumentPkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<InsertDocumentPkieAcquisInstitutionsAggregateBoolExp>;
  author?: InputMaybe<InsertDocumentStringComparisonExp>;
  celex?: InputMaybe<InsertDocumentStringComparisonExp>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  current_consolidated_version?: InputMaybe<InsertDocumentStringComparisonExp>;
  date_created?: InputMaybe<InsertDocumentDateComparisonExp>;
  date_of_effect?: InputMaybe<InsertDocumentStringComparisonExp>;
  date_of_end_of_validity?: InputMaybe<InsertDocumentStringComparisonExp>;
  date_of_publication?: InputMaybe<InsertDocumentDateComparisonExp>;
  date_of_transposition?: InputMaybe<InsertDocumentStringComparisonExp>;
  directory_code?: InputMaybe<InsertDocumentStringComparisonExp>;
  eli?: InputMaybe<InsertDocumentStringComparisonExp>;
  eurovoc_descriptor?: InputMaybe<InsertDocumentStringComparisonExp>;
  form?: InputMaybe<InsertDocumentStringComparisonExp>;
  in_force_indicator?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  is_aligned?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  latest_conslolidated_version?: InputMaybe<InsertDocumentStringComparisonExp>;
  latest_modification_notice?: InputMaybe<InsertDocumentStringComparisonExp>;
  level_of_alignment?: InputMaybe<InsertDocumentPkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<InsertDocumentPkieLevelOfAlignmentBoolExp>;
  measures?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  national_acts?: InputMaybe<InsertDocumentPkieAcquisNationalActsBoolExp>;
  national_acts_aggregate?: InputMaybe<InsertDocumentPkieAcquisNationalActsAggregateBoolExp>;
  new_chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  new_subchapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  new_topic_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  publication_reference?: InputMaybe<InsertDocumentStringComparisonExp>;
  reference?: InputMaybe<InsertDocumentStringComparisonExp>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  subchapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  subject_matter?: InputMaybe<InsertDocumentStringComparisonExp>;
  subtitle?: InputMaybe<InsertDocumentStringComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  to_be_transposed?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  to_be_transposed_general_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  to_be_transposed_work_plan_task_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  topic?: InputMaybe<InsertDocumentTopicsBoolExp>;
  topic_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  work_plan_task?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentPkieAcquisConstraint {
  /** unique or primary key constraint on columns "celex" */
  AcquisPkey = 'acquis_pkey'
}

export interface InsertDocumentPkieAcquisInsertInput {
  acquis_institutions?: InputMaybe<InsertDocumentPkieAcquisInstitutionsArrRelInsertInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  current_consolidated_version?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['date']['input']>;
  date_of_effect?: InputMaybe<Scalars['String']['input']>;
  date_of_end_of_validity?: InputMaybe<Scalars['String']['input']>;
  date_of_publication?: InputMaybe<Scalars['date']['input']>;
  date_of_transposition?: InputMaybe<Scalars['String']['input']>;
  directory_code?: InputMaybe<Scalars['String']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  eurovoc_descriptor?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  in_force_indicator?: InputMaybe<Scalars['Boolean']['input']>;
  is_aligned?: InputMaybe<Scalars['Boolean']['input']>;
  latest_conslolidated_version?: InputMaybe<Scalars['String']['input']>;
  latest_modification_notice?: InputMaybe<Scalars['String']['input']>;
  level_of_alignment?: InputMaybe<InsertDocumentPkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<InsertDocumentPkieLevelOfAlignmentObjRelInsertInput>;
  measures?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  national_acts?: InputMaybe<InsertDocumentPkieAcquisNationalActsArrRelInsertInput>;
  new_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  new_subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  new_topic_id?: InputMaybe<Scalars['Int']['input']>;
  publication_reference?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  subject_matter?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to_be_transposed?: InputMaybe<Scalars['Boolean']['input']>;
  to_be_transposed_general_date?: InputMaybe<Scalars['date']['input']>;
  to_be_transposed_work_plan_task_date?: InputMaybe<Scalars['date']['input']>;
  topic?: InputMaybe<InsertDocumentTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  work_plan_task?: InputMaybe<InsertDocumentPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentPkieAcquisInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieAcquisInstitutionsAggregateBoolExpCount>;
}

export interface InsertDocumentPkieAcquisInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieAcquisInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieAcquisInstitutionsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieAcquisInstitutionsArrRelInsertInput {
  data: Array<InsertDocumentPkieAcquisInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieAcquisInstitutionsOnConflict>;
}

export interface InsertDocumentPkieAcquisInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieAcquisInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieAcquisInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieAcquisInstitutionsBoolExp>>;
  acqui?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acqui_celex?: InputMaybe<InsertDocumentStringComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  institution?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentPkieAcquisInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  AcquisInstitutionsPkey = 'acquis_institutions_pkey'
}

export interface InsertDocumentPkieAcquisInstitutionsInsertInput {
  acqui?: InputMaybe<InsertDocumentPkieAcquisObjRelInsertInput>;
  acqui_celex?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertDocumentInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentPkieAcquisInstitutionsOnConflict {
  constraint: InsertDocumentPkieAcquisInstitutionsConstraint;
  update_columns: Array<InsertDocumentPkieAcquisInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieAcquisInstitutionsBoolExp>;
}

export enum InsertDocumentPkieAcquisInstitutionsSelectColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export enum InsertDocumentPkieAcquisInstitutionsUpdateColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface InsertDocumentPkieAcquisNationalActsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieAcquisNationalActsAggregateBoolExpCount>;
}

export interface InsertDocumentPkieAcquisNationalActsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieAcquisNationalActsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieAcquisNationalActsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieAcquisNationalActsArrRelInsertInput {
  data: Array<InsertDocumentPkieAcquisNationalActsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieAcquisNationalActsOnConflict>;
}

export interface InsertDocumentPkieAcquisNationalActsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieAcquisNationalActsBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieAcquisNationalActsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieAcquisNationalActsBoolExp>>;
  acqui?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_celex?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  national_act?: InputMaybe<InsertDocumentPkieNationalActsBoolExp>;
  national_act_eli?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentPkieAcquisNationalActsConstraint {
  /** unique or primary key constraint on columns "id" */
  AcquisNationalActsPkey = 'acquis_national_acts_pkey'
}

export interface InsertDocumentPkieAcquisNationalActsInsertInput {
  acqui?: InputMaybe<InsertDocumentPkieAcquisObjRelInsertInput>;
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  national_act?: InputMaybe<InsertDocumentPkieNationalActsObjRelInsertInput>;
  national_act_eli?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentPkieAcquisNationalActsObjRelInsertInput {
  data: InsertDocumentPkieAcquisNationalActsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieAcquisNationalActsOnConflict>;
}

export interface InsertDocumentPkieAcquisNationalActsOnConflict {
  constraint: InsertDocumentPkieAcquisNationalActsConstraint;
  update_columns: Array<InsertDocumentPkieAcquisNationalActsUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieAcquisNationalActsBoolExp>;
}

export enum InsertDocumentPkieAcquisNationalActsSelectColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export enum InsertDocumentPkieAcquisNationalActsUpdateColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export interface InsertDocumentPkieAcquisObjRelInsertInput {
  data: InsertDocumentPkieAcquisInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieAcquisOnConflict>;
}

export interface InsertDocumentPkieAcquisOnConflict {
  constraint: InsertDocumentPkieAcquisConstraint;
  update_columns: Array<InsertDocumentPkieAcquisUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
}

export enum InsertDocumentPkieAcquisSelectColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NewChapterId = 'new_chapter_id',
  /** column name */
  NewSubchapterId = 'new_subchapter_id',
  /** column name */
  NewTopicId = 'new_topic_id',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertDocumentPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

export enum InsertDocumentPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

export enum InsertDocumentPkieAcquisUpdateColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NewChapterId = 'new_chapter_id',
  /** column name */
  NewSubchapterId = 'new_subchapter_id',
  /** column name */
  NewTopicId = 'new_topic_id',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertDocumentPkieLevelOfAlignmentBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieLevelOfAlignmentBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieLevelOfAlignmentBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieLevelOfAlignmentBoolExp>>;
  acquis?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExp>;
  level?: InputMaybe<InsertDocumentStringComparisonExp>;
  measures?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
}

export enum InsertDocumentPkieLevelOfAlignmentConstraint {
  /** unique or primary key constraint on columns "level" */
  LevelOfAlignmentPkey = 'level_of_alignment_pkey'
}

export enum InsertDocumentPkieLevelOfAlignmentEnum {
  ELarte = 'E_LARTE',
  EMire = 'E_MIRE',
  EModeruar = 'E_MODERUAR',
  EPlote = 'E_PLOTE',
  Fillestare = 'FILLESTARE',
  Na = 'NA'
}

export interface InsertDocumentPkieLevelOfAlignmentEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentPkieLevelOfAlignmentEnum>;
  _in?: InputMaybe<Array<InsertDocumentPkieLevelOfAlignmentEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentPkieLevelOfAlignmentEnum>;
  _nin?: InputMaybe<Array<InsertDocumentPkieLevelOfAlignmentEnum>>;
}

export interface InsertDocumentPkieLevelOfAlignmentInsertInput {
  acquis?: InputMaybe<InsertDocumentPkieAcquisArrRelInsertInput>;
  level?: InputMaybe<Scalars['String']['input']>;
  measures?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
}

export interface InsertDocumentPkieLevelOfAlignmentObjRelInsertInput {
  data: InsertDocumentPkieLevelOfAlignmentInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieLevelOfAlignmentOnConflict>;
}

export interface InsertDocumentPkieLevelOfAlignmentOnConflict {
  constraint: InsertDocumentPkieLevelOfAlignmentConstraint;
  update_columns: Array<InsertDocumentPkieLevelOfAlignmentUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieLevelOfAlignmentBoolExp>;
}

export enum InsertDocumentPkieLevelOfAlignmentUpdateColumn {
  /** column name */
  Level = 'level'
}

export interface InsertDocumentPkieMeasureActivityAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieMeasureActivityAggregateBoolExpCount>;
}

export interface InsertDocumentPkieMeasureActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieMeasureActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureActivityBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieMeasureActivityArrRelInsertInput {
  data: Array<InsertDocumentPkieMeasureActivityInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureActivityOnConflict>;
}

export interface InsertDocumentPkieMeasureActivityBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieMeasureActivityBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieMeasureActivityBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieMeasureActivityBoolExp>>;
  activity?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  measure?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentPkieMeasureActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureActivityPkey = 'measure_activity_pkey'
}

export interface InsertDocumentPkieMeasureActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertDocumentPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentPkieMeasureActivityOnConflict {
  constraint: InsertDocumentPkieMeasureActivityConstraint;
  update_columns: Array<InsertDocumentPkieMeasureActivityUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieMeasureActivityBoolExp>;
}

export enum InsertDocumentPkieMeasureActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export enum InsertDocumentPkieMeasureActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export interface InsertDocumentPkieMeasureAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExpCount>;
}

export interface InsertDocumentPkieMeasureAggregateBoolExpBoolAnd {
  arguments: InsertDocumentPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieMeasureAggregateBoolExpBoolOr {
  arguments: InsertDocumentPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieMeasureAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieMeasureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieMeasureAnswersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieMeasureAnswersAggregateBoolExpCount>;
}

export interface InsertDocumentPkieMeasureAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieMeasureAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureAnswersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieMeasureAnswersArrRelInsertInput {
  data: Array<InsertDocumentPkieMeasureAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureAnswersOnConflict>;
}

export interface InsertDocumentPkieMeasureAnswersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieMeasureAnswersBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieMeasureAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieMeasureAnswersBoolExp>>;
  answer?: InputMaybe<InsertDocumentStringComparisonExp>;
  answered_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  measure?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentPkieMeasureAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureAnswersPkey = 'measure_answers_pkey'
}

export interface InsertDocumentPkieMeasureAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertDocumentPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentPkieMeasureAnswersOnConflict {
  constraint: InsertDocumentPkieMeasureAnswersConstraint;
  update_columns: Array<InsertDocumentPkieMeasureAnswersUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieMeasureAnswersBoolExp>;
}

export enum InsertDocumentPkieMeasureAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentPkieMeasureAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentPkieMeasureArrRelInsertInput {
  data: Array<InsertDocumentPkieMeasureInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureOnConflict>;
}

export interface InsertDocumentPkieMeasureBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieMeasureBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieMeasureBoolExp>>;
  acqui?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_celex?: InputMaybe<InsertDocumentStringComparisonExp>;
  assignee?: InputMaybe<InsertDocumentUsersBoolExp>;
  assignee_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  crated_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  impact?: InputMaybe<InsertDocumentStringComparisonExp>;
  is_completed?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  level_of_alignment?: InputMaybe<InsertDocumentPkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<InsertDocumentPkieLevelOfAlignmentBoolExp>;
  measure_activities?: InputMaybe<InsertDocumentPkieMeasureActivityBoolExp>;
  measure_activities_aggregate?: InputMaybe<InsertDocumentPkieMeasureActivityAggregateBoolExp>;
  measure_answers?: InputMaybe<InsertDocumentPkieMeasureAnswersBoolExp>;
  measure_answers_aggregate?: InputMaybe<InsertDocumentPkieMeasureAnswersAggregateBoolExp>;
  measure_budgets?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<InsertDocumentPkieMeasureBudgetsAggregateBoolExp>;
  measure_comments?: InputMaybe<InsertDocumentPkieMeasureCommentBoolExp>;
  measure_comments_aggregate?: InputMaybe<InsertDocumentPkieMeasureCommentAggregateBoolExp>;
  measure_status?: InputMaybe<InsertDocumentPkieMeasureStatusBoolExp>;
  proposing_institution?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  proposing_institution_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  quadrennial_scheduled_approval?: InputMaybe<InsertDocumentStringComparisonExp>;
  reporter?: InputMaybe<InsertDocumentUsersBoolExp>;
  reporter_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  status?: InputMaybe<InsertDocumentPkieMeasureStatusEnumComparisonExp>;
  text?: InputMaybe<InsertDocumentStringComparisonExp>;
  type?: InputMaybe<InsertDocumentStringComparisonExp>;
  type_alignment_act?: InputMaybe<InsertDocumentPkieTypeAlignmentActBoolExp>;
  type_of_alignment?: InputMaybe<InsertDocumentPkieTypeAlignmentActEnumComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  weight_transposition?: InputMaybe<InsertDocumentIntComparisonExp>;
  year_of_completition?: InputMaybe<InsertDocumentIntComparisonExp>;
  yearly_quarter?: InputMaybe<InsertDocumentYearlyQuartersBoolExp>;
}

export interface InsertDocumentPkieMeasureBudgetsAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentPkieMeasureBudgetsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentPkieMeasureBudgetsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentPkieMeasureBudgetsAggregateBoolExpCount>;
}

export interface InsertDocumentPkieMeasureBudgetsAggregateBoolExpBoolAnd {
  arguments: InsertDocumentPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieMeasureBudgetsAggregateBoolExpBoolOr {
  arguments: InsertDocumentPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieMeasureBudgetsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieMeasureBudgetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieMeasureBudgetsArrRelInsertInput {
  data: Array<InsertDocumentPkieMeasureBudgetsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureBudgetsOnConflict>;
}

export interface InsertDocumentPkieMeasureBudgetsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieMeasureBudgetsBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieMeasureBudgetsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  measure?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  source_of_funding?: InputMaybe<InsertDocumentStringComparisonExp>;
  type_budget?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetBoolExp>;
  type_of_budget?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetEnumComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  value?: InputMaybe<InsertDocumentNumericComparisonExp>;
  year?: InputMaybe<InsertDocumentIntComparisonExp>;
}

export enum InsertDocumentPkieMeasureBudgetsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureBudgetsPkey = 'measure_budgets_pkey'
}

export interface InsertDocumentPkieMeasureBudgetsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<InsertDocumentPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  source_of_funding?: InputMaybe<Scalars['String']['input']>;
  type_budget?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetObjRelInsertInput>;
  type_of_budget?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface InsertDocumentPkieMeasureBudgetsOnConflict {
  constraint: InsertDocumentPkieMeasureBudgetsConstraint;
  update_columns: Array<InsertDocumentPkieMeasureBudgetsUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
}

export enum InsertDocumentPkieMeasureBudgetsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export enum InsertDocumentPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentPkieMeasureBudgetsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export interface InsertDocumentPkieMeasureCommentAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieMeasureCommentAggregateBoolExpCount>;
}

export interface InsertDocumentPkieMeasureCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieMeasureCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieMeasureCommentBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieMeasureCommentArrRelInsertInput {
  data: Array<InsertDocumentPkieMeasureCommentInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureCommentOnConflict>;
}

export interface InsertDocumentPkieMeasureCommentBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieMeasureCommentBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieMeasureCommentBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieMeasureCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  comment?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  measure?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentPkieMeasureCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureCommentPkey = 'measure_comment_pkey'
}

export interface InsertDocumentPkieMeasureCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertDocumentPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentPkieMeasureCommentOnConflict {
  constraint: InsertDocumentPkieMeasureCommentConstraint;
  update_columns: Array<InsertDocumentPkieMeasureCommentUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieMeasureCommentBoolExp>;
}

export enum InsertDocumentPkieMeasureCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentPkieMeasureCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentPkieMeasureConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasurePkey = 'measure_pkey'
}

export interface InsertDocumentPkieMeasureInsertInput {
  acqui?: InputMaybe<InsertDocumentPkieAcquisObjRelInsertInput>;
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  assignee?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  crated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Scalars['String']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  level_of_alignment?: InputMaybe<InsertDocumentPkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<InsertDocumentPkieLevelOfAlignmentObjRelInsertInput>;
  measure_activities?: InputMaybe<InsertDocumentPkieMeasureActivityArrRelInsertInput>;
  measure_answers?: InputMaybe<InsertDocumentPkieMeasureAnswersArrRelInsertInput>;
  measure_budgets?: InputMaybe<InsertDocumentPkieMeasureBudgetsArrRelInsertInput>;
  measure_comments?: InputMaybe<InsertDocumentPkieMeasureCommentArrRelInsertInput>;
  measure_status?: InputMaybe<InsertDocumentPkieMeasureStatusObjRelInsertInput>;
  proposing_institution?: InputMaybe<InsertDocumentInstitutionsObjRelInsertInput>;
  proposing_institution_id?: InputMaybe<Scalars['uuid']['input']>;
  quadrennial_scheduled_approval?: InputMaybe<Scalars['String']['input']>;
  reporter?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertDocumentPkieMeasureStatusEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_alignment_act?: InputMaybe<InsertDocumentPkieTypeAlignmentActObjRelInsertInput>;
  type_of_alignment?: InputMaybe<InsertDocumentPkieTypeAlignmentActEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
  yearly_quarter?: InputMaybe<InsertDocumentYearlyQuartersObjRelInsertInput>;
}

export interface InsertDocumentPkieMeasureObjRelInsertInput {
  data: InsertDocumentPkieMeasureInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureOnConflict>;
}

export interface InsertDocumentPkieMeasureOnConflict {
  constraint: InsertDocumentPkieMeasureConstraint;
  update_columns: Array<InsertDocumentPkieMeasureUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
}

export enum InsertDocumentPkieMeasureSelectColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export enum InsertDocumentPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertDocumentPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export interface InsertDocumentPkieMeasureStatusBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieMeasureStatusBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieMeasureStatusBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieMeasureStatusBoolExp>>;
  measures?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentPkieMeasureStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  MeasureStatusPkey = 'measure_status_pkey'
}

export enum InsertDocumentPkieMeasureStatusEnum {
  HartimKonceptiInstitucioni = 'HARTIM_KONCEPTI_INSTITUCIONI',
  HyrjaNeFuqi = 'HYRJA_NE_FUQI',
  MiratimiNeKm = 'MIRATIMI_NE_KM',
  MiratimiNgaMinistri = 'MIRATIMI_NGA_MINISTRI',
  MiratimNgaKuvendi = 'MIRATIM_NGA_KUVENDI',
  PergatitjaEAktitLigjorInstitucioni = 'PERGATITJA_E_AKTIT_LIGJOR_INSTITUCIONI',
  PerKonsultimMeInstitucionet = 'PER_KONSULTIM_ME_INSTITUCIONET',
  PerKonsultimPublik = 'PER_KONSULTIM_PUBLIK',
  ShqyrtimNgaKuvendi = 'SHQYRTIM_NGA_KUVENDI'
}

export interface InsertDocumentPkieMeasureStatusEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentPkieMeasureStatusEnum>;
  _in?: InputMaybe<Array<InsertDocumentPkieMeasureStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentPkieMeasureStatusEnum>;
  _nin?: InputMaybe<Array<InsertDocumentPkieMeasureStatusEnum>>;
}

export interface InsertDocumentPkieMeasureStatusInsertInput {
  measures?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentPkieMeasureStatusObjRelInsertInput {
  data: InsertDocumentPkieMeasureStatusInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieMeasureStatusOnConflict>;
}

export interface InsertDocumentPkieMeasureStatusOnConflict {
  constraint: InsertDocumentPkieMeasureStatusConstraint;
  update_columns: Array<InsertDocumentPkieMeasureStatusUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieMeasureStatusBoolExp>;
}

export enum InsertDocumentPkieMeasureStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export enum InsertDocumentPkieMeasureUpdateColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export interface InsertDocumentPkieNationalActsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieNationalActsBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieNationalActsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieNationalActsBoolExp>>;
  acquis?: InputMaybe<InsertDocumentPkieAcquisNationalActsBoolExp>;
  acquis_aggregate?: InputMaybe<InsertDocumentPkieAcquisNationalActsAggregateBoolExp>;
  acquis_national_act?: InputMaybe<InsertDocumentPkieAcquisNationalActsBoolExp>;
  eli?: InputMaybe<InsertDocumentStringComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentPkieNationalActsConstraint {
  /** unique or primary key constraint on columns "eli" */
  NationalActsPkey = 'national_acts_pkey'
}

export interface InsertDocumentPkieNationalActsInsertInput {
  acquis?: InputMaybe<InsertDocumentPkieAcquisNationalActsArrRelInsertInput>;
  acquis_national_act?: InputMaybe<InsertDocumentPkieAcquisNationalActsObjRelInsertInput>;
  eli?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentPkieNationalActsObjRelInsertInput {
  data: InsertDocumentPkieNationalActsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieNationalActsOnConflict>;
}

export interface InsertDocumentPkieNationalActsOnConflict {
  constraint: InsertDocumentPkieNationalActsConstraint;
  update_columns: Array<InsertDocumentPkieNationalActsUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieNationalActsBoolExp>;
}

export enum InsertDocumentPkieNationalActsUpdateColumn {
  /** column name */
  Eli = 'eli',
  /** column name */
  Title = 'title'
}

export interface InsertDocumentPkieTypeAlignmentActBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieTypeAlignmentActBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieTypeAlignmentActBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieTypeAlignmentActBoolExp>>;
  measures?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  type?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentPkieTypeAlignmentActConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeAlignmentActPkey = 'type_alignment_act_pkey'
}

export enum InsertDocumentPkieTypeAlignmentActEnum {
  DokumentPolitikash = 'DOKUMENT_POLITIKASH',
  Ligj = 'LIGJ',
  Udhezim = 'UDHEZIM',
  Urdher = 'URDHER',
  Vkm = 'VKM'
}

export interface InsertDocumentPkieTypeAlignmentActEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentPkieTypeAlignmentActEnum>;
  _in?: InputMaybe<Array<InsertDocumentPkieTypeAlignmentActEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentPkieTypeAlignmentActEnum>;
  _nin?: InputMaybe<Array<InsertDocumentPkieTypeAlignmentActEnum>>;
}

export interface InsertDocumentPkieTypeAlignmentActInsertInput {
  measures?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentPkieTypeAlignmentActObjRelInsertInput {
  data: InsertDocumentPkieTypeAlignmentActInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieTypeAlignmentActOnConflict>;
}

export interface InsertDocumentPkieTypeAlignmentActOnConflict {
  constraint: InsertDocumentPkieTypeAlignmentActConstraint;
  update_columns: Array<InsertDocumentPkieTypeAlignmentActUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieTypeAlignmentActBoolExp>;
}

export enum InsertDocumentPkieTypeAlignmentActUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertDocumentPkieTypeMeasureBudgetBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieTypeMeasureBudgetBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieTypeMeasureBudgetBoolExp>>;
  measure_budgets?: InputMaybe<InsertDocumentPkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<InsertDocumentPkieMeasureBudgetsAggregateBoolExp>;
  type?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentPkieTypeMeasureBudgetConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeMeasureBudgetPkey = 'type_measure_budget_pkey'
}

export enum InsertDocumentPkieTypeMeasureBudgetEnum {
  Existing = 'EXISTING',
  Extra = 'EXTRA'
}

export interface InsertDocumentPkieTypeMeasureBudgetEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetEnum>;
  _in?: InputMaybe<Array<InsertDocumentPkieTypeMeasureBudgetEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetEnum>;
  _nin?: InputMaybe<Array<InsertDocumentPkieTypeMeasureBudgetEnum>>;
}

export interface InsertDocumentPkieTypeMeasureBudgetInsertInput {
  measure_budgets?: InputMaybe<InsertDocumentPkieMeasureBudgetsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentPkieTypeMeasureBudgetObjRelInsertInput {
  data: InsertDocumentPkieTypeMeasureBudgetInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetOnConflict>;
}

export interface InsertDocumentPkieTypeMeasureBudgetOnConflict {
  constraint: InsertDocumentPkieTypeMeasureBudgetConstraint;
  update_columns: Array<InsertDocumentPkieTypeMeasureBudgetUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieTypeMeasureBudgetBoolExp>;
}

export enum InsertDocumentPkieTypeMeasureBudgetUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertDocumentPkieWorkPlanCommentAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieWorkPlanCommentAggregateBoolExpCount>;
}

export interface InsertDocumentPkieWorkPlanCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieWorkPlanCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieWorkPlanCommentBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieWorkPlanCommentArrRelInsertInput {
  data: Array<InsertDocumentPkieWorkPlanCommentInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanCommentOnConflict>;
}

export interface InsertDocumentPkieWorkPlanCommentBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieWorkPlanCommentBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieWorkPlanCommentBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieWorkPlanCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  comment?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  work_plan_task?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentPkieWorkPlanCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanCommentPkey = 'work_plan_comment_pkey'
}

export interface InsertDocumentPkieWorkPlanCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task?: InputMaybe<InsertDocumentPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentPkieWorkPlanCommentOnConflict {
  constraint: InsertDocumentPkieWorkPlanCommentConstraint;
  update_columns: Array<InsertDocumentPkieWorkPlanCommentUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieWorkPlanCommentBoolExp>;
}

export enum InsertDocumentPkieWorkPlanCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertDocumentPkieWorkPlanCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertDocumentPkieWorkPlanStatusesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieWorkPlanStatusesBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieWorkPlanStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieWorkPlanStatusesBoolExp>>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
  work_plans?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
  work_plans_aggregate?: InputMaybe<InsertDocumentPkieWorkPlansAggregateBoolExp>;
}

export enum InsertDocumentPkieWorkPlanStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanStatusesPkey = 'work_plan_statuses_pkey'
}

export interface InsertDocumentPkieWorkPlanStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plans?: InputMaybe<InsertDocumentPkieWorkPlansArrRelInsertInput>;
}

export interface InsertDocumentPkieWorkPlanStatusesObjRelInsertInput {
  data: InsertDocumentPkieWorkPlanStatusesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanStatusesOnConflict>;
}

export interface InsertDocumentPkieWorkPlanStatusesOnConflict {
  constraint: InsertDocumentPkieWorkPlanStatusesConstraint;
  update_columns: Array<InsertDocumentPkieWorkPlanStatusesUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieWorkPlanStatusesBoolExp>;
}

export enum InsertDocumentPkieWorkPlanStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertDocumentPkieWorkPlanTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityAggregateBoolExpCount>;
}

export interface InsertDocumentPkieWorkPlanTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieWorkPlanTaskActivityArrRelInsertInput {
  data: Array<InsertDocumentPkieWorkPlanTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityOnConflict>;
}

export interface InsertDocumentPkieWorkPlanTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  work_plan_task?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentPkieWorkPlanTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskActivityPkey = 'work_plan_task_activity_pkey',
  /** unique or primary key constraint on columns "work_plan_task_id" */
  WorkPlanTaskActivityWorkPlanTaskIdKey = 'work_plan_task_activity_work_plan_task_id_key'
}

export interface InsertDocumentPkieWorkPlanTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task?: InputMaybe<InsertDocumentPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentPkieWorkPlanTaskActivityObjRelInsertInput {
  data: InsertDocumentPkieWorkPlanTaskActivityInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityOnConflict>;
}

export interface InsertDocumentPkieWorkPlanTaskActivityOnConflict {
  constraint: InsertDocumentPkieWorkPlanTaskActivityConstraint;
  update_columns: Array<InsertDocumentPkieWorkPlanTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityBoolExp>;
}

export enum InsertDocumentPkieWorkPlanTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertDocumentPkieWorkPlanTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertDocumentPkieWorkPlanTaskAggregateBoolExp {
  count?: InputMaybe<InsertDocumentPkieWorkPlanTaskAggregateBoolExpCount>;
}

export interface InsertDocumentPkieWorkPlanTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieWorkPlanTaskArrRelInsertInput {
  data: Array<InsertDocumentPkieWorkPlanTaskInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanTaskOnConflict>;
}

export interface InsertDocumentPkieWorkPlanTaskBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskBoolExp>>;
  acquis?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExp>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  reporter?: InputMaybe<InsertDocumentUsersBoolExp>;
  reporter_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  status?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  updated_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  userByUpdatedByUserId?: InputMaybe<InsertDocumentUsersBoolExp>;
  workPlanTaskActivityById?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityBoolExp>;
  work_plan?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
  work_plan_comments?: InputMaybe<InsertDocumentPkieWorkPlanCommentBoolExp>;
  work_plan_comments_aggregate?: InputMaybe<InsertDocumentPkieWorkPlanCommentAggregateBoolExp>;
  work_plan_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  work_plan_task_activity?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityBoolExp>;
  work_plan_task_activity_aggregate?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityAggregateBoolExp>;
  work_plan_task_status?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesBoolExp>;
}

export enum InsertDocumentPkieWorkPlanTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskPkey = 'work_plan_task_pkey'
}

export interface InsertDocumentPkieWorkPlanTaskInsertInput {
  acquis?: InputMaybe<InsertDocumentPkieAcquisArrRelInsertInput>;
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reporter?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  userByUpdatedByUserId?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  workPlanTaskActivityById?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityObjRelInsertInput>;
  work_plan?: InputMaybe<InsertDocumentPkieWorkPlansObjRelInsertInput>;
  work_plan_comments?: InputMaybe<InsertDocumentPkieWorkPlanCommentArrRelInsertInput>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task_activity?: InputMaybe<InsertDocumentPkieWorkPlanTaskActivityArrRelInsertInput>;
  work_plan_task_status?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesObjRelInsertInput>;
}

export interface InsertDocumentPkieWorkPlanTaskObjRelInsertInput {
  data: InsertDocumentPkieWorkPlanTaskInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanTaskOnConflict>;
}

export interface InsertDocumentPkieWorkPlanTaskOnConflict {
  constraint: InsertDocumentPkieWorkPlanTaskConstraint;
  update_columns: Array<InsertDocumentPkieWorkPlanTaskUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
}

export enum InsertDocumentPkieWorkPlanTaskSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface InsertDocumentPkieWorkPlanTaskStatusesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskStatusesBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskStatusesBoolExp>>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
  work_plan_tasks?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertDocumentPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertDocumentPkieWorkPlanTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanTaskStatusesPkey = 'work_plan_task_statuses_pkey'
}

export enum InsertDocumentPkieWorkPlanTaskStatusesEnum {
  Done = 'DONE',
  ToDo = 'TO_DO',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForSecretariat = 'WAITING_FOR_SECRETARIAT'
}

export interface InsertDocumentPkieWorkPlanTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentPkieWorkPlanTaskStatusesEnum>>;
}

export interface InsertDocumentPkieWorkPlanTaskStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plan_tasks?: InputMaybe<InsertDocumentPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertDocumentPkieWorkPlanTaskStatusesObjRelInsertInput {
  data: InsertDocumentPkieWorkPlanTaskStatusesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesOnConflict>;
}

export interface InsertDocumentPkieWorkPlanTaskStatusesOnConflict {
  constraint: InsertDocumentPkieWorkPlanTaskStatusesConstraint;
  update_columns: Array<InsertDocumentPkieWorkPlanTaskStatusesUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieWorkPlanTaskStatusesBoolExp>;
}

export enum InsertDocumentPkieWorkPlanTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export enum InsertDocumentPkieWorkPlanTaskUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface InsertDocumentPkieWorkPlansAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentPkieWorkPlansAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentPkieWorkPlansAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentPkieWorkPlansAggregateBoolExpCount>;
}

export interface InsertDocumentPkieWorkPlansAggregateBoolExpBoolAnd {
  arguments: InsertDocumentPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieWorkPlansAggregateBoolExpBoolOr {
  arguments: InsertDocumentPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentPkieWorkPlansAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentPkieWorkPlansSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentPkieWorkPlansArrRelInsertInput {
  data: Array<InsertDocumentPkieWorkPlansInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlansOnConflict>;
}

export interface InsertDocumentPkieWorkPlansBoolExp {
  _and?: InputMaybe<Array<InsertDocumentPkieWorkPlansBoolExp>>;
  _not?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentPkieWorkPlansBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  definition_deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  end_year?: InputMaybe<InsertDocumentIntComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  start_year?: InputMaybe<InsertDocumentIntComparisonExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  work_plan_status?: InputMaybe<InsertDocumentPkieWorkPlanStatusesBoolExp>;
  work_plan_tasks?: InputMaybe<InsertDocumentPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertDocumentPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertDocumentPkieWorkPlansConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanPkey = 'work_plan_pkey'
}

export interface InsertDocumentPkieWorkPlansInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_status?: InputMaybe<InsertDocumentPkieWorkPlanStatusesObjRelInsertInput>;
  work_plan_tasks?: InputMaybe<InsertDocumentPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertDocumentPkieWorkPlansObjRelInsertInput {
  data: InsertDocumentPkieWorkPlansInsertInput;
  on_conflict?: InputMaybe<InsertDocumentPkieWorkPlansOnConflict>;
}

export interface InsertDocumentPkieWorkPlansOnConflict {
  constraint: InsertDocumentPkieWorkPlansConstraint;
  update_columns: Array<InsertDocumentPkieWorkPlansUpdateColumn>;
  where?: InputMaybe<InsertDocumentPkieWorkPlansBoolExp>;
}

export enum InsertDocumentPkieWorkPlansSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertDocumentPkieWorkPlansUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentQuestionsAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentQuestionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentQuestionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentQuestionsAggregateBoolExpCount>;
}

export interface InsertDocumentQuestionsAggregateBoolExpBoolAnd {
  arguments: InsertDocumentQuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentQuestionsAggregateBoolExpBoolOr {
  arguments: InsertDocumentQuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentQuestionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentQuestionsArrRelInsertInput {
  data: Array<InsertDocumentQuestionsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentQuestionsOnConflict>;
}

export interface InsertDocumentQuestionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentQuestionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentQuestionsBoolExp>>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_active?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  report_answers?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExp>;
  report_tasks?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertDocumentReportTasksAggregateBoolExp>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  text?: InputMaybe<InsertDocumentStringComparisonExp>;
  type?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentQuestionsConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'Questions_pkey',
  /** unique or primary key constraint on columns "text" */
  QuestionsTextKey = 'Questions_text_key'
}

export interface InsertDocumentQuestionsInsertInput {
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  report_answers?: InputMaybe<InsertDocumentReportAnswersArrRelInsertInput>;
  report_tasks?: InputMaybe<InsertDocumentReportTasksArrRelInsertInput>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentQuestionsObjRelInsertInput {
  data: InsertDocumentQuestionsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentQuestionsOnConflict>;
}

export interface InsertDocumentQuestionsOnConflict {
  constraint: InsertDocumentQuestionsConstraint;
  update_columns: Array<InsertDocumentQuestionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentQuestionsBoolExp>;
}

export enum InsertDocumentQuestionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentQuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

export enum InsertDocumentQuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

export enum InsertDocumentQuestionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentRecommendationsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentRecommendationsAggregateBoolExpCount>;
}

export interface InsertDocumentRecommendationsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentRecommendationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentRecommendationsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentRecommendationsArrRelInsertInput {
  data: Array<InsertDocumentRecommendationsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentRecommendationsOnConflict>;
}

export interface InsertDocumentRecommendationsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentRecommendationsBoolExp>>;
  _not?: InputMaybe<InsertDocumentRecommendationsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentRecommendationsBoolExp>>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertDocumentBookmarksAggregateBoolExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report?: InputMaybe<InsertDocumentReportsBoolExp>;
  report_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentRecommendationsConstraint {
  /** unique or primary key constraint on columns "id" */
  RecommendationsPkey = 'recommendations_pkey'
}

export interface InsertDocumentRecommendationsInsertInput {
  bookmarks?: InputMaybe<InsertDocumentBookmarksArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertDocumentReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentRecommendationsObjRelInsertInput {
  data: InsertDocumentRecommendationsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentRecommendationsOnConflict>;
}

export interface InsertDocumentRecommendationsOnConflict {
  constraint: InsertDocumentRecommendationsConstraint;
  update_columns: Array<InsertDocumentRecommendationsUpdateColumn>;
  where?: InputMaybe<InsertDocumentRecommendationsBoolExp>;
}

export enum InsertDocumentRecommendationsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentRecommendationsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentReportAnswersAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExpCount>;
}

export interface InsertDocumentReportAnswersAggregateBoolExpBoolAnd {
  arguments: InsertDocumentReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentReportAnswersAggregateBoolExpBoolOr {
  arguments: InsertDocumentReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentReportAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentReportAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentReportAnswersArrRelInsertInput {
  data: Array<InsertDocumentReportAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentReportAnswersOnConflict>;
}

export interface InsertDocumentReportAnswersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportAnswersBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportAnswersBoolExp>>;
  answer?: InputMaybe<InsertDocumentStringComparisonExp>;
  answered_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  final_answer?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  final_answer_aggregate?: InputMaybe<InsertDocumentReportTasksAggregateBoolExp>;
  has_update?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  question?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  question_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report?: InputMaybe<InsertDocumentReportsBoolExp>;
  report_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report_task?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  user?: InputMaybe<InsertDocumentUsersBoolExp>;
}

export enum InsertDocumentReportAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportAnswersPkey = 'report_answers_pkey'
}

export interface InsertDocumentReportAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  final_answer?: InputMaybe<InsertDocumentReportTasksArrRelInsertInput>;
  has_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertDocumentQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertDocumentReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertDocumentReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
}

export interface InsertDocumentReportAnswersObjRelInsertInput {
  data: InsertDocumentReportAnswersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentReportAnswersOnConflict>;
}

export interface InsertDocumentReportAnswersOnConflict {
  constraint: InsertDocumentReportAnswersConstraint;
  update_columns: Array<InsertDocumentReportAnswersUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
}

export enum InsertDocumentReportAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update'
}

export enum InsertDocumentReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update'
}

export enum InsertDocumentReportAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentReportChapterStatusesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportChapterStatusesBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportChapterStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportChapterStatusesBoolExp>>;
  report_chapters?: InputMaybe<InsertDocumentReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertDocumentReportChaptersAggregateBoolExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentReportChapterStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportChapterStatusesPkey = 'report_chapter_statuses_pkey'
}

export interface InsertDocumentReportChapterStatusesInsertInput {
  report_chapters?: InputMaybe<InsertDocumentReportChaptersArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentReportChapterStatusesObjRelInsertInput {
  data: InsertDocumentReportChapterStatusesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentReportChapterStatusesOnConflict>;
}

export interface InsertDocumentReportChapterStatusesOnConflict {
  constraint: InsertDocumentReportChapterStatusesConstraint;
  update_columns: Array<InsertDocumentReportChapterStatusesUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportChapterStatusesBoolExp>;
}

export enum InsertDocumentReportChapterStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertDocumentReportChaptersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentReportChaptersAggregateBoolExpCount>;
}

export interface InsertDocumentReportChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentReportChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportChaptersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentReportChaptersArrRelInsertInput {
  data: Array<InsertDocumentReportChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentReportChaptersOnConflict>;
}

export interface InsertDocumentReportChaptersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportChaptersBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportChaptersBoolExp>>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report?: InputMaybe<InsertDocumentReportsBoolExp>;
  report_chapter_status?: InputMaybe<InsertDocumentReportChapterStatusesBoolExp>;
  report_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentReportChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportChaptersPkey = 'report_chapters_pkey'
}

export interface InsertDocumentReportChaptersInsertInput {
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertDocumentReportsObjRelInsertInput>;
  report_chapter_status?: InputMaybe<InsertDocumentReportChapterStatusesObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentReportChaptersOnConflict {
  constraint: InsertDocumentReportChaptersConstraint;
  update_columns: Array<InsertDocumentReportChaptersUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportChaptersBoolExp>;
}

export enum InsertDocumentReportChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentReportChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentReportStatusesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportStatusesBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportStatusesBoolExp>>;
  reports?: InputMaybe<InsertDocumentReportsBoolExp>;
  reports_aggregate?: InputMaybe<InsertDocumentReportsAggregateBoolExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentReportStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportStatusesPkey = 'report_statuses_pkey'
}

export enum InsertDocumentReportStatusesEnum {
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING'
}

export interface InsertDocumentReportStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentReportStatusesEnum>;
  _in?: InputMaybe<Array<InsertDocumentReportStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentReportStatusesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentReportStatusesEnum>>;
}

export interface InsertDocumentReportStatusesInsertInput {
  reports?: InputMaybe<InsertDocumentReportsArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentReportStatusesObjRelInsertInput {
  data: InsertDocumentReportStatusesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentReportStatusesOnConflict>;
}

export interface InsertDocumentReportStatusesOnConflict {
  constraint: InsertDocumentReportStatusesConstraint;
  update_columns: Array<InsertDocumentReportStatusesUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportStatusesBoolExp>;
}

export enum InsertDocumentReportStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertDocumentReportTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertDocumentReportTaskActivityAggregateBoolExpCount>;
}

export interface InsertDocumentReportTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentReportTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportTaskActivityBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentReportTaskActivityArrRelInsertInput {
  data: Array<InsertDocumentReportTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentReportTaskActivityOnConflict>;
}

export interface InsertDocumentReportTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report_task?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentReportTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskActivityPkey = 'report_task_activity_pkey'
}

export interface InsertDocumentReportTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertDocumentReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentReportTaskActivityOnConflict {
  constraint: InsertDocumentReportTaskActivityConstraint;
  update_columns: Array<InsertDocumentReportTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportTaskActivityBoolExp>;
}

export enum InsertDocumentReportTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id'
}

export enum InsertDocumentReportTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id'
}

export interface InsertDocumentReportTaskCommentAggregateBoolExp {
  count?: InputMaybe<InsertDocumentReportTaskCommentAggregateBoolExpCount>;
}

export interface InsertDocumentReportTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentReportTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportTaskCommentBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentReportTaskCommentArrRelInsertInput {
  data: Array<InsertDocumentReportTaskCommentInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentReportTaskCommentOnConflict>;
}

export interface InsertDocumentReportTaskCommentBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportTaskCommentBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportTaskCommentBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportTaskCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  comment?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report_task?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentReportTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskCommentPkey = 'report_task_comment_pkey'
}

export interface InsertDocumentReportTaskCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertDocumentReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentReportTaskCommentOnConflict {
  constraint: InsertDocumentReportTaskCommentConstraint;
  update_columns: Array<InsertDocumentReportTaskCommentUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportTaskCommentBoolExp>;
}

export enum InsertDocumentReportTaskCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentReportTaskCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentReportTaskStatusesBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportTaskStatusesBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportTaskStatusesBoolExp>>;
  report_tasks?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertDocumentReportTasksAggregateBoolExp>;
  status?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentReportTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportTaskStatusesPkey = 'report_task_statuses_pkey'
}

export enum InsertDocumentReportTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertDocumentReportTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentReportTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertDocumentReportTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentReportTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentReportTaskStatusesEnum>>;
}

export interface InsertDocumentReportTaskStatusesInsertInput {
  report_tasks?: InputMaybe<InsertDocumentReportTasksArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentReportTaskStatusesObjRelInsertInput {
  data: InsertDocumentReportTaskStatusesInsertInput;
  on_conflict?: InputMaybe<InsertDocumentReportTaskStatusesOnConflict>;
}

export interface InsertDocumentReportTaskStatusesOnConflict {
  constraint: InsertDocumentReportTaskStatusesConstraint;
  update_columns: Array<InsertDocumentReportTaskStatusesUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportTaskStatusesBoolExp>;
}

export enum InsertDocumentReportTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertDocumentReportTasksAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentReportTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentReportTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentReportTasksAggregateBoolExpCount>;
}

export interface InsertDocumentReportTasksAggregateBoolExpBoolAnd {
  arguments: InsertDocumentReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentReportTasksAggregateBoolExpBoolOr {
  arguments: InsertDocumentReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentReportTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentReportTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentReportTasksArrRelInsertInput {
  data: Array<InsertDocumentReportTasksInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentReportTasksOnConflict>;
}

export interface InsertDocumentReportTasksBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportTasksBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportTasksBoolExp>>;
  chapter_coordinator?: InputMaybe<InsertDocumentUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertDocumentUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  completition_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  final_answer_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  gnpie_member?: InputMaybe<InsertDocumentUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertDocumentUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  is_priority?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  negotiator?: InputMaybe<InsertDocumentUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  question?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  question_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report?: InputMaybe<InsertDocumentReportsBoolExp>;
  report_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  report_task_activities?: InputMaybe<InsertDocumentReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<InsertDocumentReportTaskActivityAggregateBoolExp>;
  report_task_answers?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  report_task_answers_aggregate?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExp>;
  report_task_comments?: InputMaybe<InsertDocumentReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<InsertDocumentReportTaskCommentAggregateBoolExp>;
  report_task_final_answer?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  report_task_status?: InputMaybe<InsertDocumentReportTaskStatusesBoolExp>;
  status?: InputMaybe<InsertDocumentReportTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  updated_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
}

export enum InsertDocumentReportTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTasksPkey = 'report_tasks_pkey'
}

export interface InsertDocumentReportTasksInsertInput {
  chapter_coordinator?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  final_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_priority?: InputMaybe<Scalars['Boolean']['input']>;
  negotiator?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertDocumentQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertDocumentReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_activities?: InputMaybe<InsertDocumentReportTaskActivityArrRelInsertInput>;
  report_task_answers?: InputMaybe<InsertDocumentReportAnswersArrRelInsertInput>;
  report_task_comments?: InputMaybe<InsertDocumentReportTaskCommentArrRelInsertInput>;
  report_task_final_answer?: InputMaybe<InsertDocumentReportAnswersObjRelInsertInput>;
  report_task_status?: InputMaybe<InsertDocumentReportTaskStatusesObjRelInsertInput>;
  status?: InputMaybe<InsertDocumentReportTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertDocumentReportTasksObjRelInsertInput {
  data: InsertDocumentReportTasksInsertInput;
  on_conflict?: InputMaybe<InsertDocumentReportTasksOnConflict>;
}

export interface InsertDocumentReportTasksOnConflict {
  constraint: InsertDocumentReportTasksConstraint;
  update_columns: Array<InsertDocumentReportTasksUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportTasksBoolExp>;
}

export enum InsertDocumentReportTasksSelectColumn {
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export enum InsertDocumentReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

export enum InsertDocumentReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

export enum InsertDocumentReportTasksUpdateColumn {
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface InsertDocumentReportsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentReportsAggregateBoolExpCount>;
}

export interface InsertDocumentReportsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentReportsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentReportsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentReportsArrRelInsertInput {
  data: Array<InsertDocumentReportsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentReportsOnConflict>;
}

export interface InsertDocumentReportsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentReportsBoolExp>>;
  _not?: InputMaybe<InsertDocumentReportsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentReportsBoolExp>>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by?: InputMaybe<InsertDocumentUuidComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  recommendation?: InputMaybe<InsertDocumentRecommendationsBoolExp>;
  recommendations?: InputMaybe<InsertDocumentRecommendationsBoolExp>;
  recommendations_aggregate?: InputMaybe<InsertDocumentRecommendationsAggregateBoolExp>;
  report_answers?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExp>;
  report_chapters?: InputMaybe<InsertDocumentReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertDocumentReportChaptersAggregateBoolExp>;
  report_name?: InputMaybe<InsertDocumentStringComparisonExp>;
  report_status?: InputMaybe<InsertDocumentReportStatusesBoolExp>;
  report_tasks?: InputMaybe<InsertDocumentReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertDocumentReportTasksAggregateBoolExp>;
  status?: InputMaybe<InsertDocumentReportStatusesEnumComparisonExp>;
  total_priorities?: InputMaybe<InsertDocumentBigintComparisonExp>;
  total_questions?: InputMaybe<InsertDocumentIntComparisonExp>;
  total_tasks_done?: InputMaybe<InsertDocumentBigintComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentReportsConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

export interface InsertDocumentReportsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  recommendation?: InputMaybe<InsertDocumentRecommendationsObjRelInsertInput>;
  recommendations?: InputMaybe<InsertDocumentRecommendationsArrRelInsertInput>;
  report_answers?: InputMaybe<InsertDocumentReportAnswersArrRelInsertInput>;
  report_chapters?: InputMaybe<InsertDocumentReportChaptersArrRelInsertInput>;
  report_name?: InputMaybe<Scalars['String']['input']>;
  report_status?: InputMaybe<InsertDocumentReportStatusesObjRelInsertInput>;
  report_tasks?: InputMaybe<InsertDocumentReportTasksArrRelInsertInput>;
  status?: InputMaybe<InsertDocumentReportStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentReportsObjRelInsertInput {
  data: InsertDocumentReportsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentReportsOnConflict>;
}

export interface InsertDocumentReportsOnConflict {
  constraint: InsertDocumentReportsConstraint;
  update_columns: Array<InsertDocumentReportsUpdateColumn>;
  where?: InputMaybe<InsertDocumentReportsBoolExp>;
}

export enum InsertDocumentReportsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentReportsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentStringComparisonExp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  _niregex?: InputMaybe<Scalars['String']['input']>;
  _nlike?: InputMaybe<Scalars['String']['input']>;
  _nregex?: InputMaybe<Scalars['String']['input']>;
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  _regex?: InputMaybe<Scalars['String']['input']>;
  _similar?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentSubChapterLeadInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsAggregateBoolExpCount>;
}

export interface InsertDocumentSubChapterLeadInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentSubChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentSubChapterLeadInstitutionsArrRelInsertInput {
  data: Array<InsertDocumentSubChapterLeadInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsOnConflict>;
}

export interface InsertDocumentSubChapterLeadInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentSubChapterLeadInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentSubChapterLeadInstitutionsBoolExp>>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  institution_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  sub_chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
}

export enum InsertDocumentSubChapterLeadInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChapterLeadInstitutionsPkey = 'sub_chapter_lead_institutions_pkey'
}

export interface InsertDocumentSubChapterLeadInstitutionsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

export interface InsertDocumentSubChapterLeadInstitutionsOnConflict {
  constraint: InsertDocumentSubChapterLeadInstitutionsConstraint;
  update_columns: Array<InsertDocumentSubChapterLeadInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsBoolExp>;
}

export enum InsertDocumentSubChapterLeadInstitutionsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

export enum InsertDocumentSubChapterLeadInstitutionsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

export interface InsertDocumentSubChaptersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentSubChaptersAggregateBoolExpCount>;
}

export interface InsertDocumentSubChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentSubChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentSubChaptersArrRelInsertInput {
  data: Array<InsertDocumentSubChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentSubChaptersOnConflict>;
}

export interface InsertDocumentSubChaptersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentSubChaptersBoolExp>>;
  _not?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentSubChaptersBoolExp>>;
  acquis?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertDocumentBookmarksAggregateBoolExp>;
  chapter?: InputMaybe<InsertDocumentChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  gnpies?: InputMaybe<InsertDocumentGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertDocumentGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertDocumentIntComparisonExp>;
  questions?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<InsertDocumentQuestionsAggregateBoolExp>;
  sub_chapter_lead_institutions?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsBoolExp>;
  sub_chapter_lead_institutions_aggregate?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsAggregateBoolExp>;
  sub_chapter_number?: InputMaybe<InsertDocumentStringComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
  topics?: InputMaybe<InsertDocumentTopicsBoolExp>;
  topics_aggregate?: InputMaybe<InsertDocumentTopicsAggregateBoolExp>;
}

export enum InsertDocumentSubChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChaptersPkey = 'sub_chapters_pkey',
  /** unique or primary key constraint on columns "sub_chapter_number" */
  SubChaptersSubChapterNumberKey = 'sub_chapters_sub_chapter_number_key',
  /** unique or primary key constraint on columns "title" */
  SubChaptersTitleKey = 'sub_chapters_title_key'
}

export interface InsertDocumentSubChaptersInsertInput {
  acquis?: InputMaybe<InsertDocumentPkieAcquisArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksArrRelInsertInput>;
  chapter?: InputMaybe<InsertDocumentChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<InsertDocumentGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<InsertDocumentQuestionsArrRelInsertInput>;
  sub_chapter_lead_institutions?: InputMaybe<InsertDocumentSubChapterLeadInstitutionsArrRelInsertInput>;
  sub_chapter_number?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<InsertDocumentTopicsArrRelInsertInput>;
}

export interface InsertDocumentSubChaptersObjRelInsertInput {
  data: InsertDocumentSubChaptersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentSubChaptersOnConflict>;
}

export interface InsertDocumentSubChaptersOnConflict {
  constraint: InsertDocumentSubChaptersConstraint;
  update_columns: Array<InsertDocumentSubChaptersUpdateColumn>;
  where?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
}

export enum InsertDocumentSubChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title'
}

export enum InsertDocumentSubChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title'
}

export interface InsertDocumentTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertDocumentTaskActivityAggregateBoolExpCount>;
}

export interface InsertDocumentTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentTaskActivityBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentTaskActivityArrRelInsertInput {
  data: Array<InsertDocumentTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentTaskActivityOnConflict>;
}

export interface InsertDocumentTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertDocumentTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertDocumentTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  task?: InputMaybe<InsertDocumentTaskBoolExp>;
  task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskActivityPkey = 'task_activity_pkey'
}

export interface InsertDocumentTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertDocumentTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentTaskActivityOnConflict {
  constraint: InsertDocumentTaskActivityConstraint;
  update_columns: Array<InsertDocumentTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertDocumentTaskActivityBoolExp>;
}

export enum InsertDocumentTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentTaskAggregateBoolExp {
  count?: InputMaybe<InsertDocumentTaskAggregateBoolExpCount>;
}

export interface InsertDocumentTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentTaskBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentTaskAnswersAggregateBoolExp {
  count?: InputMaybe<InsertDocumentTaskAnswersAggregateBoolExpCount>;
}

export interface InsertDocumentTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentTaskAnswersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentTaskAnswersArrRelInsertInput {
  data: Array<InsertDocumentTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentTaskAnswersOnConflict>;
}

export interface InsertDocumentTaskAnswersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentTaskAnswersBoolExp>>;
  _not?: InputMaybe<InsertDocumentTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentTaskAnswersBoolExp>>;
  answer?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  task?: InputMaybe<InsertDocumentTaskBoolExp>;
  task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskAnswersPkey = 'task_answers_pkey'
}

export interface InsertDocumentTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertDocumentTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentTaskAnswersOnConflict {
  constraint: InsertDocumentTaskAnswersConstraint;
  update_columns: Array<InsertDocumentTaskAnswersUpdateColumn>;
  where?: InputMaybe<InsertDocumentTaskAnswersBoolExp>;
}

export enum InsertDocumentTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentTaskArrRelInsertInput {
  data: Array<InsertDocumentTaskInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentTaskOnConflict>;
}

export interface InsertDocumentTaskBoolExp {
  _and?: InputMaybe<Array<InsertDocumentTaskBoolExp>>;
  _not?: InputMaybe<InsertDocumentTaskBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentTaskBoolExp>>;
  assignee?: InputMaybe<InsertDocumentUsersBoolExp>;
  assignee_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertDocumentUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertDocumentUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  children?: InputMaybe<InsertDocumentTaskBoolExp>;
  children_aggregate?: InputMaybe<InsertDocumentTaskAggregateBoolExp>;
  completition_date?: InputMaybe<InsertDocumentDateComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  deadline?: InputMaybe<InsertDocumentDateComparisonExp>;
  document?: InputMaybe<InsertDocumentDocumentsBoolExp>;
  document_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  gnpie_member?: InputMaybe<InsertDocumentUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertDocumentUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  meeting?: InputMaybe<InsertDocumentMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  negotiator?: InputMaybe<InsertDocumentUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  parent?: InputMaybe<InsertDocumentTaskBoolExp>;
  parent_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  question?: InputMaybe<InsertDocumentQuestionsBoolExp>;
  question_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  reporter?: InputMaybe<InsertDocumentUsersBoolExp>;
  reporter_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  status?: InputMaybe<InsertDocumentTaskStatusesEnumComparisonExp>;
  task_activities?: InputMaybe<InsertDocumentTaskActivityBoolExp>;
  task_activities_aggregate?: InputMaybe<InsertDocumentTaskActivityAggregateBoolExp>;
  task_answers?: InputMaybe<InsertDocumentTaskAnswersBoolExp>;
  task_answers_aggregate?: InputMaybe<InsertDocumentTaskAnswersAggregateBoolExp>;
  task_comments?: InputMaybe<InsertDocumentTaskCommentsBoolExp>;
  task_comments_aggregate?: InputMaybe<InsertDocumentTaskCommentsAggregateBoolExp>;
  task_type?: InputMaybe<InsertDocumentTaskTypesEnumComparisonExp>;
  text?: InputMaybe<InsertDocumentStringComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export interface InsertDocumentTaskCommentsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentTaskCommentsAggregateBoolExpCount>;
}

export interface InsertDocumentTaskCommentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentTaskCommentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentTaskCommentsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentTaskCommentsArrRelInsertInput {
  data: Array<InsertDocumentTaskCommentsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentTaskCommentsOnConflict>;
}

export interface InsertDocumentTaskCommentsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentTaskCommentsBoolExp>>;
  _not?: InputMaybe<InsertDocumentTaskCommentsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentTaskCommentsBoolExp>>;
  comment?: InputMaybe<InsertDocumentStringComparisonExp>;
  created_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertDocumentUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  task?: InputMaybe<InsertDocumentTaskBoolExp>;
  task_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  updated_at?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
}

export enum InsertDocumentTaskCommentsConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskCommentsPkey = 'task_comments_pkey'
}

export interface InsertDocumentTaskCommentsInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertDocumentTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentTaskCommentsOnConflict {
  constraint: InsertDocumentTaskCommentsConstraint;
  update_columns: Array<InsertDocumentTaskCommentsUpdateColumn>;
  where?: InputMaybe<InsertDocumentTaskCommentsBoolExp>;
}

export enum InsertDocumentTaskCommentsSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentTaskCommentsUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey'
}

export interface InsertDocumentTaskInsertInput {
  assignee?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  children?: InputMaybe<InsertDocumentTaskArrRelInsertInput>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  document?: InputMaybe<InsertDocumentDocumentsObjRelInsertInput>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertDocumentMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  parent?: InputMaybe<InsertDocumentTaskObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertDocumentQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter?: InputMaybe<InsertDocumentUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertDocumentTaskStatusesEnum>;
  task_activities?: InputMaybe<InsertDocumentTaskActivityArrRelInsertInput>;
  task_answers?: InputMaybe<InsertDocumentTaskAnswersArrRelInsertInput>;
  task_comments?: InputMaybe<InsertDocumentTaskCommentsArrRelInsertInput>;
  task_type?: InputMaybe<InsertDocumentTaskTypesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertDocumentTaskObjRelInsertInput {
  data: InsertDocumentTaskInsertInput;
  on_conflict?: InputMaybe<InsertDocumentTaskOnConflict>;
}

export interface InsertDocumentTaskOnConflict {
  constraint: InsertDocumentTaskConstraint;
  update_columns: Array<InsertDocumentTaskUpdateColumn>;
  where?: InputMaybe<InsertDocumentTaskBoolExp>;
}

export enum InsertDocumentTaskSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertDocumentTaskStatusesEnum {
  Done = 'DONE',
  WaitingForAssignee = 'WAITING_FOR_ASSIGNEE',
  WaitingForReporter = 'WAITING_FOR_REPORTER'
}

export interface InsertDocumentTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertDocumentTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentTaskStatusesEnum>>;
}

export enum InsertDocumentTaskTypesEnum {
  MeetingTask = 'MEETING_TASK'
}

export interface InsertDocumentTaskTypesEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentTaskTypesEnum>;
  _in?: InputMaybe<Array<InsertDocumentTaskTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentTaskTypesEnum>;
  _nin?: InputMaybe<Array<InsertDocumentTaskTypesEnum>>;
}

export enum InsertDocumentTaskUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertDocumentTimestamptzComparisonExp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

export interface InsertDocumentTopicsAggregateBoolExp {
  count?: InputMaybe<InsertDocumentTopicsAggregateBoolExpCount>;
}

export interface InsertDocumentTopicsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentTopicsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentTopicsBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentTopicsArrRelInsertInput {
  data: Array<InsertDocumentTopicsInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentTopicsOnConflict>;
}

export interface InsertDocumentTopicsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentTopicsBoolExp>>;
  _not?: InputMaybe<InsertDocumentTopicsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentTopicsBoolExp>>;
  acquis?: InputMaybe<InsertDocumentPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertDocumentPkieAcquisAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertDocumentBookmarksAggregateBoolExp>;
  gnpies?: InputMaybe<InsertDocumentGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertDocumentGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertDocumentIntComparisonExp>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersBoolExp>;
  subchapter_id?: InputMaybe<InsertDocumentIntComparisonExp>;
  title?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentTopicsConstraint {
  /** unique or primary key constraint on columns "id" */
  TopicsNewIdKey = 'topics_new_id_key',
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

export interface InsertDocumentTopicsInsertInput {
  acquis?: InputMaybe<InsertDocumentPkieAcquisArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertDocumentBookmarksArrRelInsertInput>;
  gnpies?: InputMaybe<InsertDocumentGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter?: InputMaybe<InsertDocumentSubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentTopicsObjRelInsertInput {
  data: InsertDocumentTopicsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentTopicsOnConflict>;
}

export interface InsertDocumentTopicsOnConflict {
  constraint: InsertDocumentTopicsConstraint;
  update_columns: Array<InsertDocumentTopicsUpdateColumn>;
  where?: InputMaybe<InsertDocumentTopicsBoolExp>;
}

export enum InsertDocumentTopicsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title'
}

export enum InsertDocumentTopicsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title'
}

export interface InsertDocumentTypeInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertDocumentTypeInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertDocumentTypeInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentTypeInstitutionsBoolExp>>;
  institutions?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  institutions_aggregate?: InputMaybe<InsertDocumentInstitutionsAggregateBoolExp>;
  type?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentTypeInstitutionsConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeInstitutionsPkey = 'type_institutions_pkey'
}

export enum InsertDocumentTypeInstitutionsEnum {
  Pavarur = 'PAVARUR',
  Qendror = 'QENDROR',
  Varesie = 'VARESIE'
}

export interface InsertDocumentTypeInstitutionsEnumComparisonExp {
  _eq?: InputMaybe<InsertDocumentTypeInstitutionsEnum>;
  _in?: InputMaybe<Array<InsertDocumentTypeInstitutionsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertDocumentTypeInstitutionsEnum>;
  _nin?: InputMaybe<Array<InsertDocumentTypeInstitutionsEnum>>;
}

export interface InsertDocumentTypeInstitutionsInsertInput {
  institutions?: InputMaybe<InsertDocumentInstitutionsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentTypeInstitutionsObjRelInsertInput {
  data: InsertDocumentTypeInstitutionsInsertInput;
  on_conflict?: InputMaybe<InsertDocumentTypeInstitutionsOnConflict>;
}

export interface InsertDocumentTypeInstitutionsOnConflict {
  constraint: InsertDocumentTypeInstitutionsConstraint;
  update_columns: Array<InsertDocumentTypeInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertDocumentTypeInstitutionsBoolExp>;
}

export enum InsertDocumentTypeInstitutionsUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertDocumentUsersAggregateBoolExp {
  bool_and?: InputMaybe<InsertDocumentUsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertDocumentUsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertDocumentUsersAggregateBoolExpCount>;
}

export interface InsertDocumentUsersAggregateBoolExpBoolAnd {
  arguments: InsertDocumentUsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentUsersBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentUsersAggregateBoolExpBoolOr {
  arguments: InsertDocumentUsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentUsersBoolExp>;
  predicate: InsertDocumentBooleanComparisonExp;
}

export interface InsertDocumentUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertDocumentUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertDocumentUsersBoolExp>;
  predicate: InsertDocumentIntComparisonExp;
}

export interface InsertDocumentUsersArrRelInsertInput {
  data: Array<InsertDocumentUsersInsertInput>;
  on_conflict?: InputMaybe<InsertDocumentUsersOnConflict>;
}

export interface InsertDocumentUsersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentUsersBoolExp>>;
  _not?: InputMaybe<InsertDocumentUsersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentUsersBoolExp>>;
  activeMfaType?: InputMaybe<InsertDocumentStringComparisonExp>;
  avatarUrl?: InputMaybe<InsertDocumentStringComparisonExp>;
  createdAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  currentChallenge?: InputMaybe<InsertDocumentStringComparisonExp>;
  defaultRole?: InputMaybe<InsertDocumentAuthrolesEnumComparisonExp>;
  defaultRoleByRole?: InputMaybe<InsertDocumentAuthrolesBoolExp>;
  disabled?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  displayName?: InputMaybe<InsertDocumentStringComparisonExp>;
  dms_id?: InputMaybe<InsertDocumentNumericComparisonExp>;
  email?: InputMaybe<InsertDocumentCitextComparisonExp>;
  emailVerified?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  gnpies?: InputMaybe<InsertDocumentGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertDocumentGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  institution?: InputMaybe<InsertDocumentInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertDocumentUuidComparisonExp>;
  isAnonymous?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  lastSeen?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  locale?: InputMaybe<InsertDocumentStringComparisonExp>;
  measuresAssigned?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measuresAssigned_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  measuresToReport?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measuresToReport_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  meeting_users?: InputMaybe<InsertDocumentMeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<InsertDocumentMeetingUsersAggregateBoolExp>;
  meetings?: InputMaybe<InsertDocumentMeetingsBoolExp>;
  meetings_aggregate?: InputMaybe<InsertDocumentMeetingsAggregateBoolExp>;
  metadata?: InputMaybe<InsertDocumentJsonbComparisonExp>;
  newEmail?: InputMaybe<InsertDocumentCitextComparisonExp>;
  otpHash?: InputMaybe<InsertDocumentStringComparisonExp>;
  otpHashExpiresAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  otpMethodLastUsed?: InputMaybe<InsertDocumentStringComparisonExp>;
  passwordHash?: InputMaybe<InsertDocumentStringComparisonExp>;
  phoneNumber?: InputMaybe<InsertDocumentStringComparisonExp>;
  phoneNumberVerified?: InputMaybe<InsertDocumentBooleanComparisonExp>;
  refreshTokens?: InputMaybe<InsertDocumentAuthrefreshtokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<InsertDocumentAuthrefreshtokensAggregateBoolExp>;
  report_answers?: InputMaybe<InsertDocumentReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertDocumentReportAnswersAggregateBoolExp>;
  report_task_activities?: InputMaybe<InsertDocumentReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<InsertDocumentReportTaskActivityAggregateBoolExp>;
  report_task_comments?: InputMaybe<InsertDocumentReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<InsertDocumentReportTaskCommentAggregateBoolExp>;
  roles?: InputMaybe<InsertDocumentAuthuserrolesBoolExp>;
  roles_aggregate?: InputMaybe<InsertDocumentAuthuserrolesAggregateBoolExp>;
  securityKeys?: InputMaybe<InsertDocumentAuthusersecuritykeysBoolExp>;
  securityKeys_aggregate?: InputMaybe<InsertDocumentAuthusersecuritykeysAggregateBoolExp>;
  tasksAssigned?: InputMaybe<InsertDocumentTaskBoolExp>;
  tasksAssigned_aggregate?: InputMaybe<InsertDocumentTaskAggregateBoolExp>;
  tasksToReport?: InputMaybe<InsertDocumentTaskBoolExp>;
  tasksToReport_aggregate?: InputMaybe<InsertDocumentTaskAggregateBoolExp>;
  ticket?: InputMaybe<InsertDocumentStringComparisonExp>;
  ticketExpiresAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  totpSecret?: InputMaybe<InsertDocumentStringComparisonExp>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertDocumentCapacTrainingNeedsAggregateBoolExp>;
  training_participations?: InputMaybe<InsertDocumentCapacTrainingParticipantsBoolExp>;
  training_participations_aggregate?: InputMaybe<InsertDocumentCapacTrainingParticipantsAggregateBoolExp>;
  trainings?: InputMaybe<InsertDocumentCapacTrainingsBoolExp>;
  trainings_aggregate?: InputMaybe<InsertDocumentCapacTrainingsAggregateBoolExp>;
  updatedAt?: InputMaybe<InsertDocumentTimestamptzComparisonExp>;
  userProviders?: InputMaybe<InsertDocumentAuthuserprovidersBoolExp>;
  userProviders_aggregate?: InputMaybe<InsertDocumentAuthuserprovidersAggregateBoolExp>;
  username?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentUsersConstraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

export interface InsertDocumentUsersInsertInput {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<InsertDocumentAuthrolesEnum>;
  defaultRoleByRole?: InputMaybe<InsertDocumentAuthrolesObjRelInsertInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  gnpies?: InputMaybe<InsertDocumentGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertDocumentInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  measuresAssigned?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  measuresToReport?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  meeting_users?: InputMaybe<InsertDocumentMeetingUsersArrRelInsertInput>;
  meetings?: InputMaybe<InsertDocumentMeetingsArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<InsertDocumentAuthrefreshtokensArrRelInsertInput>;
  report_answers?: InputMaybe<InsertDocumentReportAnswersArrRelInsertInput>;
  report_task_activities?: InputMaybe<InsertDocumentReportTaskActivityArrRelInsertInput>;
  report_task_comments?: InputMaybe<InsertDocumentReportTaskCommentArrRelInsertInput>;
  roles?: InputMaybe<InsertDocumentAuthuserrolesArrRelInsertInput>;
  securityKeys?: InputMaybe<InsertDocumentAuthusersecuritykeysArrRelInsertInput>;
  tasksAssigned?: InputMaybe<InsertDocumentTaskArrRelInsertInput>;
  tasksToReport?: InputMaybe<InsertDocumentTaskArrRelInsertInput>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertDocumentCapacTrainingNeedsArrRelInsertInput>;
  training_participations?: InputMaybe<InsertDocumentCapacTrainingParticipantsArrRelInsertInput>;
  trainings?: InputMaybe<InsertDocumentCapacTrainingsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<InsertDocumentAuthuserprovidersArrRelInsertInput>;
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentUsersObjRelInsertInput {
  data: InsertDocumentUsersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentUsersOnConflict>;
}

export interface InsertDocumentUsersOnConflict {
  constraint: InsertDocumentUsersConstraint;
  update_columns: Array<InsertDocumentUsersUpdateColumn>;
  where?: InputMaybe<InsertDocumentUsersBoolExp>;
}

export enum InsertDocumentUsersSelectColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export enum InsertDocumentUsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

export enum InsertDocumentUsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

export enum InsertDocumentUsersUpdateColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export interface InsertDocumentUuidArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _contains?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _eq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _lte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _neq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
}

export interface InsertDocumentUuidComparisonExp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

export interface InsertDocumentYearlyQuartersBoolExp {
  _and?: InputMaybe<Array<InsertDocumentYearlyQuartersBoolExp>>;
  _not?: InputMaybe<InsertDocumentYearlyQuartersBoolExp>;
  _or?: InputMaybe<Array<InsertDocumentYearlyQuartersBoolExp>>;
  bookmark_tasks?: InputMaybe<InsertDocumentBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertDocumentBookmarkTasksAggregateBoolExp>;
  measures?: InputMaybe<InsertDocumentPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertDocumentPkieMeasureAggregateBoolExp>;
  quarter?: InputMaybe<InsertDocumentStringComparisonExp>;
}

export enum InsertDocumentYearlyQuartersConstraint {
  /** unique or primary key constraint on columns "quarter" */
  YearlyQuartersPkey = 'yearly_quarters_pkey'
}

export interface InsertDocumentYearlyQuartersInsertInput {
  bookmark_tasks?: InputMaybe<InsertDocumentBookmarkTasksArrRelInsertInput>;
  measures?: InputMaybe<InsertDocumentPkieMeasureArrRelInsertInput>;
  quarter?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertDocumentYearlyQuartersObjRelInsertInput {
  data: InsertDocumentYearlyQuartersInsertInput;
  on_conflict?: InputMaybe<InsertDocumentYearlyQuartersOnConflict>;
}

export interface InsertDocumentYearlyQuartersOnConflict {
  constraint: InsertDocumentYearlyQuartersConstraint;
  update_columns: Array<InsertDocumentYearlyQuartersUpdateColumn>;
  where?: InputMaybe<InsertDocumentYearlyQuartersBoolExp>;
}

export enum InsertDocumentYearlyQuartersUpdateColumn {
  /** column name */
  Quarter = 'quarter'
}

export interface InsertFolderAuthprovidersBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthprovidersBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthprovidersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthprovidersBoolExp>>;
  id?: InputMaybe<InsertFolderStringComparisonExp>;
  userProviders?: InputMaybe<InsertFolderAuthuserprovidersBoolExp>;
  userProviders_aggregate?: InputMaybe<InsertFolderAuthuserprovidersAggregateBoolExp>;
}

export enum InsertFolderAuthprovidersConstraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

export interface InsertFolderAuthprovidersInsertInput {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<InsertFolderAuthuserprovidersArrRelInsertInput>;
}

export interface InsertFolderAuthprovidersObjRelInsertInput {
  data: InsertFolderAuthprovidersInsertInput;
  on_conflict?: InputMaybe<InsertFolderAuthprovidersOnConflict>;
}

export interface InsertFolderAuthprovidersOnConflict {
  constraint: InsertFolderAuthprovidersConstraint;
  update_columns: Array<InsertFolderAuthprovidersUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthprovidersBoolExp>;
}

export enum InsertFolderAuthprovidersUpdateColumn {
  /** column name */
  Id = 'id'
}

export interface InsertFolderAuthrefreshtokensAggregateBoolExp {
  count?: InputMaybe<InsertFolderAuthrefreshtokensAggregateBoolExpCount>;
}

export interface InsertFolderAuthrefreshtokensAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderAuthrefreshtokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderAuthrefreshtokensBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderAuthrefreshtokensArrRelInsertInput {
  data: Array<InsertFolderAuthrefreshtokensInsertInput>;
  on_conflict?: InputMaybe<InsertFolderAuthrefreshtokensOnConflict>;
}

export interface InsertFolderAuthrefreshtokensBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthrefreshtokensBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthrefreshtokensBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthrefreshtokensBoolExp>>;
  createdAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  expiresAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  metadata?: InputMaybe<InsertFolderJsonbComparisonExp>;
  refreshTokenHash?: InputMaybe<InsertFolderStringComparisonExp>;
  refresh_token_type?: InputMaybe<InsertFolderAuthrefreshtokentypesBoolExp>;
  type?: InputMaybe<InsertFolderAuthrefreshtokentypesEnumComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  userId?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderAuthrefreshtokensConstraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

export interface InsertFolderAuthrefreshtokensInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  refresh_token_type?: InputMaybe<InsertFolderAuthrefreshtokentypesObjRelInsertInput>;
  type?: InputMaybe<InsertFolderAuthrefreshtokentypesEnum>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderAuthrefreshtokensOnConflict {
  constraint: InsertFolderAuthrefreshtokensConstraint;
  update_columns: Array<InsertFolderAuthrefreshtokensUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthrefreshtokensBoolExp>;
}

export enum InsertFolderAuthrefreshtokensSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export enum InsertFolderAuthrefreshtokensUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export interface InsertFolderAuthrefreshtokentypesBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthrefreshtokentypesBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthrefreshtokentypesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthrefreshtokentypesBoolExp>>;
  comment?: InputMaybe<InsertFolderStringComparisonExp>;
  refreshTokens?: InputMaybe<InsertFolderAuthrefreshtokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<InsertFolderAuthrefreshtokensAggregateBoolExp>;
  value?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderAuthrefreshtokentypesConstraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey'
}

export enum InsertFolderAuthrefreshtokentypesEnum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular'
}

export interface InsertFolderAuthrefreshtokentypesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderAuthrefreshtokentypesEnum>;
  _in?: InputMaybe<Array<InsertFolderAuthrefreshtokentypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderAuthrefreshtokentypesEnum>;
  _nin?: InputMaybe<Array<InsertFolderAuthrefreshtokentypesEnum>>;
}

export interface InsertFolderAuthrefreshtokentypesInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<InsertFolderAuthrefreshtokensArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderAuthrefreshtokentypesObjRelInsertInput {
  data: InsertFolderAuthrefreshtokentypesInsertInput;
  on_conflict?: InputMaybe<InsertFolderAuthrefreshtokentypesOnConflict>;
}

export interface InsertFolderAuthrefreshtokentypesOnConflict {
  constraint: InsertFolderAuthrefreshtokentypesConstraint;
  update_columns: Array<InsertFolderAuthrefreshtokentypesUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthrefreshtokentypesBoolExp>;
}

export enum InsertFolderAuthrefreshtokentypesUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface InsertFolderAuthrolesBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthrolesBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthrolesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthrolesBoolExp>>;
  role?: InputMaybe<InsertFolderStringComparisonExp>;
  userRoles?: InputMaybe<InsertFolderAuthuserrolesBoolExp>;
  userRoles_aggregate?: InputMaybe<InsertFolderAuthuserrolesAggregateBoolExp>;
  usersByDefaultRole?: InputMaybe<InsertFolderUsersBoolExp>;
  usersByDefaultRole_aggregate?: InputMaybe<InsertFolderUsersAggregateBoolExp>;
}

export enum InsertFolderAuthrolesConstraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

export enum InsertFolderAuthrolesEnum {
  ChapterCommunication = 'CHAPTER_COMMUNICATION',
  ChapterCoordinator = 'CHAPTER_COORDINATOR',
  ChapterDefined = 'CHAPTER_DEFINED',
  ChapterKnowledge = 'CHAPTER_KNOWLEDGE',
  ChapterMonitoring = 'CHAPTER_MONITORING',
  ChiefGnpieSecretariat = 'CHIEF_GNPIE_SECRETARIAT',
  GnpieMember = 'GNPIE_MEMBER',
  GnpieSecretariat = 'GNPIE_SECRETARIAT',
  MainNegotiator = 'MAIN_NEGOTIATOR',
  Member = 'MEMBER',
  Negotiator = 'NEGOTIATOR',
  PmoSecretariat = 'PMO_SECRETARIAT',
  Subnegotiator = 'SUBNEGOTIATOR',
  Anonymous = 'anonymous',
  Me = 'me',
  User = 'user'
}

export interface InsertFolderAuthrolesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderAuthrolesEnum>;
  _in?: InputMaybe<Array<InsertFolderAuthrolesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderAuthrolesEnum>;
  _nin?: InputMaybe<Array<InsertFolderAuthrolesEnum>>;
}

export interface InsertFolderAuthrolesInsertInput {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<InsertFolderAuthuserrolesArrRelInsertInput>;
  usersByDefaultRole?: InputMaybe<InsertFolderUsersArrRelInsertInput>;
}

export interface InsertFolderAuthrolesObjRelInsertInput {
  data: InsertFolderAuthrolesInsertInput;
  on_conflict?: InputMaybe<InsertFolderAuthrolesOnConflict>;
}

export interface InsertFolderAuthrolesOnConflict {
  constraint: InsertFolderAuthrolesConstraint;
  update_columns: Array<InsertFolderAuthrolesUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthrolesBoolExp>;
}

export enum InsertFolderAuthrolesUpdateColumn {
  /** column name */
  Role = 'role'
}

export interface InsertFolderAuthuserprovidersAggregateBoolExp {
  count?: InputMaybe<InsertFolderAuthuserprovidersAggregateBoolExpCount>;
}

export interface InsertFolderAuthuserprovidersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderAuthuserprovidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderAuthuserprovidersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderAuthuserprovidersArrRelInsertInput {
  data: Array<InsertFolderAuthuserprovidersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderAuthuserprovidersOnConflict>;
}

export interface InsertFolderAuthuserprovidersBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthuserprovidersBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthuserprovidersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthuserprovidersBoolExp>>;
  accessToken?: InputMaybe<InsertFolderStringComparisonExp>;
  createdAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  provider?: InputMaybe<InsertFolderAuthprovidersBoolExp>;
  providerId?: InputMaybe<InsertFolderStringComparisonExp>;
  providerUserId?: InputMaybe<InsertFolderStringComparisonExp>;
  refreshToken?: InputMaybe<InsertFolderStringComparisonExp>;
  updatedAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  userId?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderAuthuserprovidersConstraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key'
}

export interface InsertFolderAuthuserprovidersInsertInput {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<InsertFolderAuthprovidersObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderAuthuserprovidersOnConflict {
  constraint: InsertFolderAuthuserprovidersConstraint;
  update_columns: Array<InsertFolderAuthuserprovidersUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthuserprovidersBoolExp>;
}

export enum InsertFolderAuthuserprovidersSelectColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export enum InsertFolderAuthuserprovidersUpdateColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export interface InsertFolderAuthuserrolesAggregateBoolExp {
  count?: InputMaybe<InsertFolderAuthuserrolesAggregateBoolExpCount>;
}

export interface InsertFolderAuthuserrolesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderAuthuserrolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderAuthuserrolesBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderAuthuserrolesArrRelInsertInput {
  data: Array<InsertFolderAuthuserrolesInsertInput>;
  on_conflict?: InputMaybe<InsertFolderAuthuserrolesOnConflict>;
}

export interface InsertFolderAuthuserrolesBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthuserrolesBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthuserrolesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthuserrolesBoolExp>>;
  createdAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  role?: InputMaybe<InsertFolderAuthrolesEnumComparisonExp>;
  roleByRole?: InputMaybe<InsertFolderAuthrolesBoolExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  userId?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderAuthuserrolesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key'
}

export interface InsertFolderAuthuserrolesInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<InsertFolderAuthrolesEnum>;
  roleByRole?: InputMaybe<InsertFolderAuthrolesObjRelInsertInput>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderAuthuserrolesOnConflict {
  constraint: InsertFolderAuthuserrolesConstraint;
  update_columns: Array<InsertFolderAuthuserrolesUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthuserrolesBoolExp>;
}

export enum InsertFolderAuthuserrolesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export enum InsertFolderAuthuserrolesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export interface InsertFolderAuthusersecuritykeysAggregateBoolExp {
  count?: InputMaybe<InsertFolderAuthusersecuritykeysAggregateBoolExpCount>;
}

export interface InsertFolderAuthusersecuritykeysAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderAuthusersecuritykeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderAuthusersecuritykeysBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderAuthusersecuritykeysArrRelInsertInput {
  data: Array<InsertFolderAuthusersecuritykeysInsertInput>;
  on_conflict?: InputMaybe<InsertFolderAuthusersecuritykeysOnConflict>;
}

export interface InsertFolderAuthusersecuritykeysBoolExp {
  _and?: InputMaybe<Array<InsertFolderAuthusersecuritykeysBoolExp>>;
  _not?: InputMaybe<InsertFolderAuthusersecuritykeysBoolExp>;
  _or?: InputMaybe<Array<InsertFolderAuthusersecuritykeysBoolExp>>;
  counter?: InputMaybe<InsertFolderBigintComparisonExp>;
  credentialId?: InputMaybe<InsertFolderStringComparisonExp>;
  credentialPublicKey?: InputMaybe<InsertFolderByteaComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  nickname?: InputMaybe<InsertFolderStringComparisonExp>;
  transports?: InputMaybe<InsertFolderStringComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  userId?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderAuthusersecuritykeysConstraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey'
}

export interface InsertFolderAuthusersecuritykeysInsertInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderAuthusersecuritykeysOnConflict {
  constraint: InsertFolderAuthusersecuritykeysConstraint;
  update_columns: Array<InsertFolderAuthusersecuritykeysUpdateColumn>;
  where?: InputMaybe<InsertFolderAuthusersecuritykeysBoolExp>;
}

export enum InsertFolderAuthusersecuritykeysSelectColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export enum InsertFolderAuthusersecuritykeysUpdateColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export interface InsertFolderBigintComparisonExp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

export interface InsertFolderBookmarkTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertFolderBookmarkTaskActivityAggregateBoolExpCount>;
}

export interface InsertFolderBookmarkTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderBookmarkTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTaskActivityBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderBookmarkTaskActivityArrRelInsertInput {
  data: Array<InsertFolderBookmarkTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertFolderBookmarkTaskActivityOnConflict>;
}

export interface InsertFolderBookmarkTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertFolderBookmarkTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertFolderBookmarkTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertFolderBookmarkTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertFolderStringComparisonExp>;
  bookmark_task?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderBookmarkTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskActivityPkey = 'bookmark_task_activity_pkey'
}

export interface InsertFolderBookmarkTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  bookmark_task?: InputMaybe<InsertFolderBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderBookmarkTaskActivityOnConflict {
  constraint: InsertFolderBookmarkTaskActivityConstraint;
  update_columns: Array<InsertFolderBookmarkTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertFolderBookmarkTaskActivityBoolExp>;
}

export enum InsertFolderBookmarkTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderBookmarkTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderBookmarkTaskAnswersAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderBookmarkTaskAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderBookmarkTaskAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderBookmarkTaskAnswersAggregateBoolExpCount>;
}

export interface InsertFolderBookmarkTaskAnswersAggregateBoolExpBoolAnd {
  arguments: InsertFolderBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTaskAnswersBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderBookmarkTaskAnswersAggregateBoolExpBoolOr {
  arguments: InsertFolderBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTaskAnswersBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderBookmarkTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderBookmarkTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTaskAnswersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderBookmarkTaskAnswersArrRelInsertInput {
  data: Array<InsertFolderBookmarkTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderBookmarkTaskAnswersOnConflict>;
}

export interface InsertFolderBookmarkTaskAnswersBoolExp {
  _and?: InputMaybe<Array<InsertFolderBookmarkTaskAnswersBoolExp>>;
  _not?: InputMaybe<InsertFolderBookmarkTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderBookmarkTaskAnswersBoolExp>>;
  answer?: InputMaybe<InsertFolderStringComparisonExp>;
  asnwered_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  bookmark_task?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_completed?: InputMaybe<InsertFolderBooleanComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderBookmarkTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskAnswersPkey = 'bookmark_task_answers_pkey'
}

export interface InsertFolderBookmarkTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  asnwered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_task?: InputMaybe<InsertFolderBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderBookmarkTaskAnswersOnConflict {
  constraint: InsertFolderBookmarkTaskAnswersConstraint;
  update_columns: Array<InsertFolderBookmarkTaskAnswersUpdateColumn>;
  where?: InputMaybe<InsertFolderBookmarkTaskAnswersBoolExp>;
}

export enum InsertFolderBookmarkTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertFolderBookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertFolderBookmarkTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderBookmarkTaskCommentAggregateBoolExp {
  count?: InputMaybe<InsertFolderBookmarkTaskCommentAggregateBoolExpCount>;
}

export interface InsertFolderBookmarkTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderBookmarkTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTaskCommentBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderBookmarkTaskCommentArrRelInsertInput {
  data: Array<InsertFolderBookmarkTaskCommentInsertInput>;
  on_conflict?: InputMaybe<InsertFolderBookmarkTaskCommentOnConflict>;
}

export interface InsertFolderBookmarkTaskCommentBoolExp {
  _and?: InputMaybe<Array<InsertFolderBookmarkTaskCommentBoolExp>>;
  _not?: InputMaybe<InsertFolderBookmarkTaskCommentBoolExp>;
  _or?: InputMaybe<Array<InsertFolderBookmarkTaskCommentBoolExp>>;
  bookmark_task?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  comment?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderBookmarkTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskCommentPkey = 'bookmark_task_comment_pkey'
}

export interface InsertFolderBookmarkTaskCommentInsertInput {
  bookmark_task?: InputMaybe<InsertFolderBookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderBookmarkTaskCommentOnConflict {
  constraint: InsertFolderBookmarkTaskCommentConstraint;
  update_columns: Array<InsertFolderBookmarkTaskCommentUpdateColumn>;
  where?: InputMaybe<InsertFolderBookmarkTaskCommentBoolExp>;
}

export enum InsertFolderBookmarkTaskCommentSelectColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderBookmarkTaskCommentUpdateColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderBookmarkTaskMeasuresBoolExp {
  _and?: InputMaybe<Array<InsertFolderBookmarkTaskMeasuresBoolExp>>;
  _not?: InputMaybe<InsertFolderBookmarkTaskMeasuresBoolExp>;
  _or?: InputMaybe<Array<InsertFolderBookmarkTaskMeasuresBoolExp>>;
  bookmark_tasks?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertFolderBookmarkTasksAggregateBoolExp>;
  measure?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderBookmarkTaskMeasuresConstraint {
  /** unique or primary key constraint on columns "measure" */
  BookmarkTaskMeasuresPkey = 'bookmark_task_measures_pkey'
}

export interface InsertFolderBookmarkTaskMeasuresInsertInput {
  bookmark_tasks?: InputMaybe<InsertFolderBookmarkTasksArrRelInsertInput>;
  measure?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderBookmarkTaskMeasuresObjRelInsertInput {
  data: InsertFolderBookmarkTaskMeasuresInsertInput;
  on_conflict?: InputMaybe<InsertFolderBookmarkTaskMeasuresOnConflict>;
}

export interface InsertFolderBookmarkTaskMeasuresOnConflict {
  constraint: InsertFolderBookmarkTaskMeasuresConstraint;
  update_columns: Array<InsertFolderBookmarkTaskMeasuresUpdateColumn>;
  where?: InputMaybe<InsertFolderBookmarkTaskMeasuresBoolExp>;
}

export enum InsertFolderBookmarkTaskMeasuresUpdateColumn {
  /** column name */
  Measure = 'measure'
}

export enum InsertFolderBookmarkTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertFolderBookmarkTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderBookmarkTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertFolderBookmarkTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderBookmarkTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertFolderBookmarkTaskStatusesEnum>>;
}

export interface InsertFolderBookmarkTasksAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderBookmarkTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderBookmarkTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderBookmarkTasksAggregateBoolExpCount>;
}

export interface InsertFolderBookmarkTasksAggregateBoolExpBoolAnd {
  arguments: InsertFolderBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderBookmarkTasksAggregateBoolExpBoolOr {
  arguments: InsertFolderBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderBookmarkTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderBookmarkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderBookmarkTasksArrRelInsertInput {
  data: Array<InsertFolderBookmarkTasksInsertInput>;
  on_conflict?: InputMaybe<InsertFolderBookmarkTasksOnConflict>;
}

export interface InsertFolderBookmarkTasksBoolExp {
  _and?: InputMaybe<Array<InsertFolderBookmarkTasksBoolExp>>;
  _not?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  _or?: InputMaybe<Array<InsertFolderBookmarkTasksBoolExp>>;
  assignee_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  bookmark?: InputMaybe<InsertFolderBookmarksBoolExp>;
  bookmark_id?: InputMaybe<InsertFolderIntComparisonExp>;
  bookmark_task_activities?: InputMaybe<InsertFolderBookmarkTaskActivityBoolExp>;
  bookmark_task_activities_aggregate?: InputMaybe<InsertFolderBookmarkTaskActivityAggregateBoolExp>;
  bookmark_task_answers?: InputMaybe<InsertFolderBookmarkTaskAnswersBoolExp>;
  bookmark_task_answers_aggregate?: InputMaybe<InsertFolderBookmarkTaskAnswersAggregateBoolExp>;
  bookmark_task_comments?: InputMaybe<InsertFolderBookmarkTaskCommentBoolExp>;
  bookmark_task_comments_aggregate?: InputMaybe<InsertFolderBookmarkTaskCommentAggregateBoolExp>;
  bookmark_task_measure?: InputMaybe<InsertFolderBookmarkTaskMeasuresBoolExp>;
  budget_allocated_at_quarter?: InputMaybe<InsertFolderStringComparisonExp>;
  budget_allocated_at_year?: InputMaybe<InsertFolderIntComparisonExp>;
  budget_needed?: InputMaybe<InsertFolderNumericComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertFolderUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertFolderUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  completition_date?: InputMaybe<InsertFolderDateComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  gnpie_member?: InputMaybe<InsertFolderUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertFolderUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_completed?: InputMaybe<InsertFolderBooleanComparisonExp>;
  measure?: InputMaybe<InsertFolderStringComparisonExp>;
  negotiator?: InputMaybe<InsertFolderUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  specific_measure?: InputMaybe<InsertFolderStringComparisonExp>;
  status?: InputMaybe<InsertFolderBookmarkTaskStatusesEnumComparisonExp>;
  text?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  updated_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  yearly_quarter?: InputMaybe<InsertFolderYearlyQuartersBoolExp>;
}

export enum InsertFolderBookmarkTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTasksPkey = 'bookmark_tasks_pkey'
}

export interface InsertFolderBookmarkTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark?: InputMaybe<InsertFolderBookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task_activities?: InputMaybe<InsertFolderBookmarkTaskActivityArrRelInsertInput>;
  bookmark_task_answers?: InputMaybe<InsertFolderBookmarkTaskAnswersArrRelInsertInput>;
  bookmark_task_comments?: InputMaybe<InsertFolderBookmarkTaskCommentArrRelInsertInput>;
  bookmark_task_measure?: InputMaybe<InsertFolderBookmarkTaskMeasuresObjRelInsertInput>;
  budget_allocated_at_quarter?: InputMaybe<Scalars['String']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
  chapter_coordinator?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  gnpie_member?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<Scalars['String']['input']>;
  negotiator?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  specific_measure?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InsertFolderBookmarkTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  yearly_quarter?: InputMaybe<InsertFolderYearlyQuartersObjRelInsertInput>;
}

export interface InsertFolderBookmarkTasksObjRelInsertInput {
  data: InsertFolderBookmarkTasksInsertInput;
  on_conflict?: InputMaybe<InsertFolderBookmarkTasksOnConflict>;
}

export interface InsertFolderBookmarkTasksOnConflict {
  constraint: InsertFolderBookmarkTasksConstraint;
  update_columns: Array<InsertFolderBookmarkTasksUpdateColumn>;
  where?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
}

export enum InsertFolderBookmarkTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export enum InsertFolderBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertFolderBookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertFolderBookmarkTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface InsertFolderBookmarksAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderBookmarksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderBookmarksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderBookmarksAggregateBoolExpCount>;
}

export interface InsertFolderBookmarksAggregateBoolExpBoolAnd {
  arguments: InsertFolderBookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarksBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderBookmarksAggregateBoolExpBoolOr {
  arguments: InsertFolderBookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarksBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderBookmarksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderBookmarksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderBookmarksBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderBookmarksArrRelInsertInput {
  data: Array<InsertFolderBookmarksInsertInput>;
  on_conflict?: InputMaybe<InsertFolderBookmarksOnConflict>;
}

export interface InsertFolderBookmarksBoolExp {
  _and?: InputMaybe<Array<InsertFolderBookmarksBoolExp>>;
  _not?: InputMaybe<InsertFolderBookmarksBoolExp>;
  _or?: InputMaybe<Array<InsertFolderBookmarksBoolExp>>;
  assigned_to_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  assignee?: InputMaybe<InsertFolderUsersBoolExp>;
  bookmark_tasks?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertFolderBookmarkTasksAggregateBoolExp>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  code_id?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  id?: InputMaybe<InsertFolderIntComparisonExp>;
  is_completed?: InputMaybe<InsertFolderBooleanComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  recommendation?: InputMaybe<InsertFolderRecommendationsBoolExp>;
  recommendation_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  reference?: InputMaybe<InsertFolderStringComparisonExp>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  topic?: InputMaybe<InsertFolderTopicsBoolExp>;
  topic_id?: InputMaybe<InsertFolderIntComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
}

export enum InsertFolderBookmarksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarksPkey = 'bookmarks_pkey'
}

export interface InsertFolderBookmarksInsertInput {
  assigned_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  assignee?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  bookmark_tasks?: InputMaybe<InsertFolderBookmarkTasksArrRelInsertInput>;
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  code_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  recommendation?: InputMaybe<InsertFolderRecommendationsObjRelInsertInput>;
  recommendation_id?: InputMaybe<Scalars['uuid']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<InsertFolderTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
}

export interface InsertFolderBookmarksObjRelInsertInput {
  data: InsertFolderBookmarksInsertInput;
  on_conflict?: InputMaybe<InsertFolderBookmarksOnConflict>;
}

export interface InsertFolderBookmarksOnConflict {
  constraint: InsertFolderBookmarksConstraint;
  update_columns: Array<InsertFolderBookmarksUpdateColumn>;
  where?: InputMaybe<InsertFolderBookmarksBoolExp>;
}

export enum InsertFolderBookmarksSelectColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderBookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderBookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderBookmarksUpdateColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderBooleanComparisonExp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

export interface InsertFolderByteaComparisonExp {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
}

export interface InsertFolderCapacBeneficiariesBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacBeneficiariesBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacBeneficiariesBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_Id?: InputMaybe<InsertFolderUuidComparisonExp>;
  email?: InputMaybe<InsertFolderStringComparisonExp>;
  first_name?: InputMaybe<InsertFolderStringComparisonExp>;
  function?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  institution_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  number?: InputMaybe<InsertFolderStringComparisonExp>;
  project_beneficiaries?: InputMaybe<InsertFolderCapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<InsertFolderCapacProjectBeneficiariesAggregateBoolExp>;
  surname?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  BeneficiariesPkey = 'beneficiaries_pkey'
}

export interface InsertFolderCapacBeneficiariesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_Id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<InsertFolderCapacProjectBeneficiariesArrRelInsertInput>;
  surname?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacBeneficiariesObjRelInsertInput {
  data: InsertFolderCapacBeneficiariesInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacBeneficiariesOnConflict>;
}

export interface InsertFolderCapacBeneficiariesOnConflict {
  constraint: InsertFolderCapacBeneficiariesConstraint;
  update_columns: Array<InsertFolderCapacBeneficiariesUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacBeneficiariesBoolExp>;
}

export enum InsertFolderCapacBeneficiariesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_Id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Function = 'function',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  Number = 'number',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacChapterDonatorAggregateBoolExp {
  count?: InputMaybe<InsertFolderCapacChapterDonatorAggregateBoolExpCount>;
}

export interface InsertFolderCapacChapterDonatorAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacChapterDonatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacChapterDonatorBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacChapterDonatorArrRelInsertInput {
  data: Array<InsertFolderCapacChapterDonatorInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacChapterDonatorOnConflict>;
}

export interface InsertFolderCapacChapterDonatorBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacChapterDonatorBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacChapterDonatorBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacChapterDonatorBoolExp>>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  donator?: InputMaybe<InsertFolderCapacDonatorsBoolExp>;
  donator_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderCapacChapterDonatorConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterDonatorPkey = 'chapter_donator_pkey'
}

export interface InsertFolderCapacChapterDonatorInsertInput {
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  donator?: InputMaybe<InsertFolderCapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderCapacChapterDonatorOnConflict {
  constraint: InsertFolderCapacChapterDonatorConstraint;
  update_columns: Array<InsertFolderCapacChapterDonatorUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacChapterDonatorBoolExp>;
}

export enum InsertFolderCapacChapterDonatorSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export enum InsertFolderCapacChapterDonatorUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export interface InsertFolderCapacDonatorsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacDonatorsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacDonatorsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacDonatorsBoolExp>>;
  address?: InputMaybe<InsertFolderStringComparisonExp>;
  chapter_donators?: InputMaybe<InsertFolderCapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<InsertFolderCapacChapterDonatorAggregateBoolExp>;
  contact_email?: InputMaybe<InsertFolderStringComparisonExp>;
  contact_function?: InputMaybe<InsertFolderStringComparisonExp>;
  contact_person?: InputMaybe<InsertFolderStringComparisonExp>;
  contact_phone?: InputMaybe<InsertFolderStringComparisonExp>;
  country?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  integration_steps_supported?: InputMaybe<InsertFolderNumericArrayComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  organisation?: InputMaybe<InsertFolderStringComparisonExp>;
  planned_new_support?: InputMaybe<InsertFolderStringComparisonExp>;
  projects?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<InsertFolderCapacProjectsAggregateBoolExp>;
  suggestions?: InputMaybe<InsertFolderStringComparisonExp>;
  supports_accelerated_integration?: InputMaybe<InsertFolderBooleanComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacDonatorsConstraint {
  /** unique or primary key constraint on columns "id" */
  DonatorsPkey = 'donators_pkey'
}

export interface InsertFolderCapacDonatorsInsertInput {
  address?: InputMaybe<Scalars['String']['input']>;
  chapter_donators?: InputMaybe<InsertFolderCapacChapterDonatorArrRelInsertInput>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_function?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps_supported?: InputMaybe<Array<Scalars['numeric']['input']>>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<InsertFolderCapacProjectsArrRelInsertInput>;
  suggestions?: InputMaybe<Scalars['String']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacDonatorsObjRelInsertInput {
  data: InsertFolderCapacDonatorsInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacDonatorsOnConflict>;
}

export interface InsertFolderCapacDonatorsOnConflict {
  constraint: InsertFolderCapacDonatorsConstraint;
  update_columns: Array<InsertFolderCapacDonatorsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacDonatorsBoolExp>;
}

export enum InsertFolderCapacDonatorsUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFunction = 'contact_function',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactPhone = 'contact_phone',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationStepsSupported = 'integration_steps_supported',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  Suggestions = 'suggestions',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacExpertsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacExpertsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacExpertsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacExpertsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  email?: InputMaybe<InsertFolderStringComparisonExp>;
  expertise?: InputMaybe<InsertFolderStringComparisonExp>;
  full_name?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  organization?: InputMaybe<InsertFolderStringComparisonExp>;
  phone?: InputMaybe<InsertFolderStringComparisonExp>;
  position?: InputMaybe<InsertFolderStringComparisonExp>;
  trainings_experts?: InputMaybe<InsertFolderCapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<InsertFolderCapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacExpertsConstraint {
  /** unique or primary key constraint on columns "full_name" */
  ExpertsFullNameKey = 'experts_full_name_key',
  /** unique or primary key constraint on columns "id" */
  ExpertsPkey = 'experts_pkey'
}

export interface InsertFolderCapacExpertsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  trainings_experts?: InputMaybe<InsertFolderCapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacExpertsObjRelInsertInput {
  data: InsertFolderCapacExpertsInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacExpertsOnConflict>;
}

export interface InsertFolderCapacExpertsOnConflict {
  constraint: InsertFolderCapacExpertsConstraint;
  update_columns: Array<InsertFolderCapacExpertsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacExpertsBoolExp>;
}

export enum InsertFolderCapacExpertsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expertise = 'expertise',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacPriorityListsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacPriorityListsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacPriorityListsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacPriorityListsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  end_date?: InputMaybe<InsertFolderDateComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_active?: InputMaybe<InsertFolderBooleanComparisonExp>;
  start_date?: InputMaybe<InsertFolderDateComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacPriorityListsConstraint {
  /** unique or primary key constraint on columns "id" */
  PriorityListsPkey = 'priority_lists_pkey'
}

export interface InsertFolderCapacPriorityListsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacPriorityListsObjRelInsertInput {
  data: InsertFolderCapacPriorityListsInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacPriorityListsOnConflict>;
}

export interface InsertFolderCapacPriorityListsOnConflict {
  constraint: InsertFolderCapacPriorityListsConstraint;
  update_columns: Array<InsertFolderCapacPriorityListsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacPriorityListsBoolExp>;
}

export enum InsertFolderCapacPriorityListsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacProjectBeneficiariesAggregateBoolExp {
  count?: InputMaybe<InsertFolderCapacProjectBeneficiariesAggregateBoolExpCount>;
}

export interface InsertFolderCapacProjectBeneficiariesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacProjectBeneficiariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacProjectBeneficiariesBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacProjectBeneficiariesArrRelInsertInput {
  data: Array<InsertFolderCapacProjectBeneficiariesInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacProjectBeneficiariesOnConflict>;
}

export interface InsertFolderCapacProjectBeneficiariesBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacProjectBeneficiariesBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacProjectBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacProjectBeneficiariesBoolExp>>;
  beneficiary?: InputMaybe<InsertFolderCapacBeneficiariesBoolExp>;
  beneficiary_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  project?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  project_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderCapacProjectBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectBeneficiariesPkey = 'project_beneficiaries_pkey'
}

export interface InsertFolderCapacProjectBeneficiariesInsertInput {
  beneficiary?: InputMaybe<InsertFolderCapacBeneficiariesObjRelInsertInput>;
  beneficiary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  project?: InputMaybe<InsertFolderCapacProjectsObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderCapacProjectBeneficiariesOnConflict {
  constraint: InsertFolderCapacProjectBeneficiariesConstraint;
  update_columns: Array<InsertFolderCapacProjectBeneficiariesUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacProjectBeneficiariesBoolExp>;
}

export enum InsertFolderCapacProjectBeneficiariesSelectColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export enum InsertFolderCapacProjectBeneficiariesUpdateColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export interface InsertFolderCapacProjectsAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderCapacProjectsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderCapacProjectsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderCapacProjectsAggregateBoolExpCount>;
}

export interface InsertFolderCapacProjectsAggregateBoolExpBoolAnd {
  arguments: InsertFolderCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderCapacProjectsAggregateBoolExpBoolOr {
  arguments: InsertFolderCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderCapacProjectsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacProjectsArrRelInsertInput {
  data: Array<InsertFolderCapacProjectsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacProjectsOnConflict>;
}

export interface InsertFolderCapacProjectsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacProjectsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacProjectsBoolExp>>;
  activities?: InputMaybe<InsertFolderStringComparisonExp>;
  beneficiary?: InputMaybe<InsertFolderStringComparisonExp>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  disbursement?: InputMaybe<InsertFolderNumericComparisonExp>;
  donator?: InputMaybe<InsertFolderCapacDonatorsBoolExp>;
  donator_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  end_date?: InputMaybe<InsertFolderDateComparisonExp>;
  expected_results?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  last_disbusement_date?: InputMaybe<InsertFolderDateComparisonExp>;
  other_suggestions?: InputMaybe<InsertFolderStringComparisonExp>;
  outputs_delivered?: InputMaybe<InsertFolderStringComparisonExp>;
  planned_new_support?: InputMaybe<InsertFolderStringComparisonExp>;
  project_beneficiaries?: InputMaybe<InsertFolderCapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<InsertFolderCapacProjectBeneficiariesAggregateBoolExp>;
  start_date?: InputMaybe<InsertFolderDateComparisonExp>;
  supports_accelerated_integration?: InputMaybe<InsertFolderBooleanComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  value?: InputMaybe<InsertFolderNumericComparisonExp>;
}

export enum InsertFolderCapacProjectsConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

export interface InsertFolderCapacProjectsInsertInput {
  activities?: InputMaybe<Scalars['String']['input']>;
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  donator?: InputMaybe<InsertFolderCapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  expected_results?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  last_disbusement_date?: InputMaybe<Scalars['date']['input']>;
  other_suggestions?: InputMaybe<Scalars['String']['input']>;
  outputs_delivered?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<InsertFolderCapacProjectBeneficiariesArrRelInsertInput>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

export interface InsertFolderCapacProjectsObjRelInsertInput {
  data: InsertFolderCapacProjectsInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacProjectsOnConflict>;
}

export interface InsertFolderCapacProjectsOnConflict {
  constraint: InsertFolderCapacProjectsConstraint;
  update_columns: Array<InsertFolderCapacProjectsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
}

export enum InsertFolderCapacProjectsSelectColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export enum InsertFolderCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

export enum InsertFolderCapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

export enum InsertFolderCapacProjectsUpdateColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export interface InsertFolderCapacTaiexBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTaiexBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTaiexBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTaiexBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacTaiexConstraint {
  /** unique or primary key constraint on columns "id" */
  TaiexPkey = 'taiex_pkey'
}

export interface InsertFolderCapacTaiexInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacTaiexObjRelInsertInput {
  data: InsertFolderCapacTaiexInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacTaiexOnConflict>;
}

export interface InsertFolderCapacTaiexOnConflict {
  constraint: InsertFolderCapacTaiexConstraint;
  update_columns: Array<InsertFolderCapacTaiexUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTaiexBoolExp>;
}

export enum InsertFolderCapacTaiexUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacTrainingNeedsAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExpCount>;
}

export interface InsertFolderCapacTrainingNeedsAggregateBoolExpBoolAnd {
  arguments: InsertFolderCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderCapacTrainingNeedsAggregateBoolExpBoolOr {
  arguments: InsertFolderCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderCapacTrainingNeedsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacTrainingNeedsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacTrainingNeedsArrRelInsertInput {
  data: Array<InsertFolderCapacTrainingNeedsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacTrainingNeedsOnConflict>;
}

export interface InsertFolderCapacTrainingNeedsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTrainingNeedsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTrainingNeedsBoolExp>>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_approved?: InputMaybe<InsertFolderBooleanComparisonExp>;
  need?: InputMaybe<InsertFolderStringComparisonExp>;
  priority_list?: InputMaybe<InsertFolderCapacPriorityListsBoolExp>;
  priority_list_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  taiex?: InputMaybe<InsertFolderCapacTaiexBoolExp>;
  taiex_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  training?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  twinning?: InputMaybe<InsertFolderCapacTwinningBoolExp>;
  twinning_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacTrainingNeedsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingNeedsPkey = 'training_needs_pkey'
}

export interface InsertFolderCapacTrainingNeedsInsertInput {
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  need?: InputMaybe<Scalars['String']['input']>;
  priority_list?: InputMaybe<InsertFolderCapacPriorityListsObjRelInsertInput>;
  priority_list_id?: InputMaybe<Scalars['uuid']['input']>;
  taiex?: InputMaybe<InsertFolderCapacTaiexObjRelInsertInput>;
  taiex_id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertFolderCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  twinning?: InputMaybe<InsertFolderCapacTwinningObjRelInsertInput>;
  twinning_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacTrainingNeedsOnConflict {
  constraint: InsertFolderCapacTrainingNeedsConstraint;
  update_columns: Array<InsertFolderCapacTrainingNeedsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
}

export enum InsertFolderCapacTrainingNeedsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

export enum InsertFolderCapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

export enum InsertFolderCapacTrainingNeedsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacTrainingParticipantsAggregateBoolExp {
  count?: InputMaybe<InsertFolderCapacTrainingParticipantsAggregateBoolExpCount>;
}

export interface InsertFolderCapacTrainingParticipantsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacTrainingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingParticipantsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacTrainingParticipantsArrRelInsertInput {
  data: Array<InsertFolderCapacTrainingParticipantsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacTrainingParticipantsOnConflict>;
}

export interface InsertFolderCapacTrainingParticipantsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTrainingParticipantsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTrainingParticipantsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTrainingParticipantsBoolExp>>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  training?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderCapacTrainingParticipantsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey = 'training_sessions_pkey'
}

export interface InsertFolderCapacTrainingParticipantsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertFolderCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderCapacTrainingParticipantsOnConflict {
  constraint: InsertFolderCapacTrainingParticipantsConstraint;
  update_columns: Array<InsertFolderCapacTrainingParticipantsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTrainingParticipantsBoolExp>;
}

export enum InsertFolderCapacTrainingParticipantsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertFolderCapacTrainingParticipantsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertFolderCapacTrainingSessionsAggregateBoolExp {
  count?: InputMaybe<InsertFolderCapacTrainingSessionsAggregateBoolExpCount>;
}

export interface InsertFolderCapacTrainingSessionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacTrainingSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingSessionsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacTrainingSessionsArrRelInsertInput {
  data: Array<InsertFolderCapacTrainingSessionsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacTrainingSessionsOnConflict>;
}

export interface InsertFolderCapacTrainingSessionsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTrainingSessionsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTrainingSessionsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTrainingSessionsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  duration_mins?: InputMaybe<InsertFolderNumericComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  location?: InputMaybe<InsertFolderStringComparisonExp>;
  participant_ids?: InputMaybe<InsertFolderUuidArrayComparisonExp>;
  starting_datetime?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  training?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacTrainingSessionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey1 = 'training_sessions_pkey1'
}

export interface InsertFolderCapacTrainingSessionsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  participant_ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  starting_datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  training?: InputMaybe<InsertFolderCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacTrainingSessionsOnConflict {
  constraint: InsertFolderCapacTrainingSessionsConstraint;
  update_columns: Array<InsertFolderCapacTrainingSessionsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTrainingSessionsBoolExp>;
}

export enum InsertFolderCapacTrainingSessionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderCapacTrainingSessionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacTrainingsAggregateBoolExp {
  count?: InputMaybe<InsertFolderCapacTrainingsAggregateBoolExpCount>;
}

export interface InsertFolderCapacTrainingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacTrainingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacTrainingsArrRelInsertInput {
  data: Array<InsertFolderCapacTrainingsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacTrainingsOnConflict>;
}

export interface InsertFolderCapacTrainingsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTrainingsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTrainingsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  description?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  location?: InputMaybe<InsertFolderStringComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  trainers?: InputMaybe<InsertFolderStringComparisonExp>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExp>;
  training_participants?: InputMaybe<InsertFolderCapacTrainingParticipantsBoolExp>;
  training_participants_aggregate?: InputMaybe<InsertFolderCapacTrainingParticipantsAggregateBoolExp>;
  training_sessions?: InputMaybe<InsertFolderCapacTrainingSessionsBoolExp>;
  training_sessions_aggregate?: InputMaybe<InsertFolderCapacTrainingSessionsAggregateBoolExp>;
  trainings_experts?: InputMaybe<InsertFolderCapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<InsertFolderCapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacTrainingsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsPkey = 'trainings_pkey'
}

export interface InsertFolderCapacTrainingsExpertsAggregateBoolExp {
  count?: InputMaybe<InsertFolderCapacTrainingsExpertsAggregateBoolExpCount>;
}

export interface InsertFolderCapacTrainingsExpertsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderCapacTrainingsExpertsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderCapacTrainingsExpertsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderCapacTrainingsExpertsArrRelInsertInput {
  data: Array<InsertFolderCapacTrainingsExpertsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderCapacTrainingsExpertsOnConflict>;
}

export interface InsertFolderCapacTrainingsExpertsBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTrainingsExpertsBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTrainingsExpertsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTrainingsExpertsBoolExp>>;
  expert?: InputMaybe<InsertFolderCapacExpertsBoolExp>;
  expert_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  training?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  training_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderCapacTrainingsExpertsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsExpertsPkey = 'trainings_experts_pkey'
}

export interface InsertFolderCapacTrainingsExpertsInsertInput {
  expert?: InputMaybe<InsertFolderCapacExpertsObjRelInsertInput>;
  expert_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<InsertFolderCapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderCapacTrainingsExpertsOnConflict {
  constraint: InsertFolderCapacTrainingsExpertsConstraint;
  update_columns: Array<InsertFolderCapacTrainingsExpertsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTrainingsExpertsBoolExp>;
}

export enum InsertFolderCapacTrainingsExpertsSelectColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export enum InsertFolderCapacTrainingsExpertsUpdateColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export interface InsertFolderCapacTrainingsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainers?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsArrRelInsertInput>;
  training_participants?: InputMaybe<InsertFolderCapacTrainingParticipantsArrRelInsertInput>;
  training_sessions?: InputMaybe<InsertFolderCapacTrainingSessionsArrRelInsertInput>;
  trainings_experts?: InputMaybe<InsertFolderCapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacTrainingsObjRelInsertInput {
  data: InsertFolderCapacTrainingsInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacTrainingsOnConflict>;
}

export interface InsertFolderCapacTrainingsOnConflict {
  constraint: InsertFolderCapacTrainingsConstraint;
  update_columns: Array<InsertFolderCapacTrainingsUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
}

export enum InsertFolderCapacTrainingsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderCapacTrainingsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderCapacTwinningBoolExp {
  _and?: InputMaybe<Array<InsertFolderCapacTwinningBoolExp>>;
  _not?: InputMaybe<InsertFolderCapacTwinningBoolExp>;
  _or?: InputMaybe<Array<InsertFolderCapacTwinningBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderCapacTwinningConstraint {
  /** unique or primary key constraint on columns "id" */
  TwinningPkey = 'twinning_pkey'
}

export interface InsertFolderCapacTwinningInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderCapacTwinningObjRelInsertInput {
  data: InsertFolderCapacTwinningInsertInput;
  on_conflict?: InputMaybe<InsertFolderCapacTwinningOnConflict>;
}

export interface InsertFolderCapacTwinningOnConflict {
  constraint: InsertFolderCapacTwinningConstraint;
  update_columns: Array<InsertFolderCapacTwinningUpdateColumn>;
  where?: InputMaybe<InsertFolderCapacTwinningBoolExp>;
}

export enum InsertFolderCapacTwinningUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderChaptersAggregateBoolExp {
  count?: InputMaybe<InsertFolderChaptersAggregateBoolExpCount>;
}

export interface InsertFolderChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderChaptersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderChaptersArrRelInsertInput {
  data: Array<InsertFolderChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderChaptersOnConflict>;
}

export interface InsertFolderChaptersBoolExp {
  _and?: InputMaybe<Array<InsertFolderChaptersBoolExp>>;
  _not?: InputMaybe<InsertFolderChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderChaptersBoolExp>>;
  acquis?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertFolderBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertFolderBookmarksAggregateBoolExp>;
  chapter_donators?: InputMaybe<InsertFolderCapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<InsertFolderCapacChapterDonatorAggregateBoolExp>;
  chapter_number?: InputMaybe<InsertFolderStringComparisonExp>;
  chapter_title?: InputMaybe<InsertFolderStringComparisonExp>;
  cluster?: InputMaybe<InsertFolderClustersBoolExp>;
  cluster_id?: InputMaybe<InsertFolderIntComparisonExp>;
  gnpies?: InputMaybe<InsertFolderGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertFolderGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertFolderIntComparisonExp>;
  new_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  projects?: InputMaybe<InsertFolderCapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<InsertFolderCapacProjectsAggregateBoolExp>;
  questions?: InputMaybe<InsertFolderQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<InsertFolderQuestionsAggregateBoolExp>;
  report_chapters?: InputMaybe<InsertFolderReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertFolderReportChaptersAggregateBoolExp>;
  sub_chapters?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  sub_chapters_aggregate?: InputMaybe<InsertFolderSubChaptersAggregateBoolExp>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExp>;
  work_plan_tasks?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertFolderPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertFolderChaptersConstraint {
  /** unique or primary key constraint on columns "chapter_number" */
  ChaptersChapterNumberKey = 'chapters_chapter_number_key',
  /** unique or primary key constraint on columns "chapter_title" */
  ChaptersChapterTitleKey = 'chapters_chapter_title_key',
  /** unique or primary key constraint on columns "new_id" */
  ChaptersNewIdKey = 'chapters_new_id_key',
  /** unique or primary key constraint on columns "id" */
  ChaptersPkey = 'chapters_pkey'
}

export interface InsertFolderChaptersInsertInput {
  acquis?: InputMaybe<InsertFolderPkieAcquisArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertFolderBookmarksArrRelInsertInput>;
  chapter_donators?: InputMaybe<InsertFolderCapacChapterDonatorArrRelInsertInput>;
  chapter_number?: InputMaybe<Scalars['String']['input']>;
  chapter_title?: InputMaybe<Scalars['String']['input']>;
  cluster?: InputMaybe<InsertFolderClustersObjRelInsertInput>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<InsertFolderGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  projects?: InputMaybe<InsertFolderCapacProjectsArrRelInsertInput>;
  questions?: InputMaybe<InsertFolderQuestionsArrRelInsertInput>;
  report_chapters?: InputMaybe<InsertFolderReportChaptersArrRelInsertInput>;
  sub_chapters?: InputMaybe<InsertFolderSubChaptersArrRelInsertInput>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsArrRelInsertInput>;
  work_plan_tasks?: InputMaybe<InsertFolderPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertFolderChaptersObjRelInsertInput {
  data: InsertFolderChaptersInsertInput;
  on_conflict?: InputMaybe<InsertFolderChaptersOnConflict>;
}

export interface InsertFolderChaptersOnConflict {
  constraint: InsertFolderChaptersConstraint;
  update_columns: Array<InsertFolderChaptersUpdateColumn>;
  where?: InputMaybe<InsertFolderChaptersBoolExp>;
}

export enum InsertFolderChaptersSelectColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id'
}

export enum InsertFolderChaptersUpdateColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id'
}

export interface InsertFolderCitextComparisonExp {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  _regex?: InputMaybe<Scalars['citext']['input']>;
  _similar?: InputMaybe<Scalars['citext']['input']>;
}

export interface InsertFolderClustersBoolExp {
  _and?: InputMaybe<Array<InsertFolderClustersBoolExp>>;
  _not?: InputMaybe<InsertFolderClustersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderClustersBoolExp>>;
  chapters?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapters_aggregate?: InputMaybe<InsertFolderChaptersAggregateBoolExp>;
  color?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderIntComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderClustersConstraint {
  /** unique or primary key constraint on columns "id" */
  ClustersPkey = 'clusters_pkey',
  /** unique or primary key constraint on columns "title" */
  ClustersTitleKey = 'clusters_title_key'
}

export interface InsertFolderClustersInsertInput {
  chapters?: InputMaybe<InsertFolderChaptersArrRelInsertInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderClustersObjRelInsertInput {
  data: InsertFolderClustersInsertInput;
  on_conflict?: InputMaybe<InsertFolderClustersOnConflict>;
}

export interface InsertFolderClustersOnConflict {
  constraint: InsertFolderClustersConstraint;
  update_columns: Array<InsertFolderClustersUpdateColumn>;
  where?: InputMaybe<InsertFolderClustersBoolExp>;
}

export enum InsertFolderClustersUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export interface InsertFolderDateComparisonExp {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
}

export interface InsertFolderDmsFolderAggregateBoolExp {
  count?: InputMaybe<InsertFolderDmsFolderAggregateBoolExpCount>;
}

export interface InsertFolderDmsFolderAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderDmsFolderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderDmsFolderBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderDmsFolderArrRelInsertInput {
  data: Array<InsertFolderDmsFolderInsertInput>;
  on_conflict?: InputMaybe<InsertFolderDmsFolderOnConflict>;
}

export interface InsertFolderDmsFolderBoolExp {
  _and?: InputMaybe<Array<InsertFolderDmsFolderBoolExp>>;
  _not?: InputMaybe<InsertFolderDmsFolderBoolExp>;
  _or?: InputMaybe<Array<InsertFolderDmsFolderBoolExp>>;
  change?: InputMaybe<InsertFolderJsonbComparisonExp>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  children?: InputMaybe<InsertFolderDmsFolderBoolExp>;
  children_aggregate?: InputMaybe<InsertFolderDmsFolderAggregateBoolExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  dms_id?: InputMaybe<InsertFolderNumericComparisonExp>;
  feature?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  name?: InputMaybe<InsertFolderStringComparisonExp>;
  owner?: InputMaybe<InsertFolderUsersBoolExp>;
  parent?: InputMaybe<InsertFolderDmsFolderBoolExp>;
  parent_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  path?: InputMaybe<InsertFolderStringComparisonExp>;
  type?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  view?: InputMaybe<InsertFolderJsonbComparisonExp>;
}

export enum InsertFolderDmsFolderConstraint {
  /** unique or primary key constraint on columns "id" */
  FolderPkey = 'folder_pkey'
}

export interface InsertFolderDmsFolderInsertInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  children?: InputMaybe<InsertFolderDmsFolderArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  feature?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  parent?: InputMaybe<InsertFolderDmsFolderObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

export interface InsertFolderDmsFolderObjRelInsertInput {
  data: InsertFolderDmsFolderInsertInput;
  on_conflict?: InputMaybe<InsertFolderDmsFolderOnConflict>;
}

export interface InsertFolderDmsFolderOnConflict {
  constraint: InsertFolderDmsFolderConstraint;
  update_columns: Array<InsertFolderDmsFolderUpdateColumn>;
  where?: InputMaybe<InsertFolderDmsFolderBoolExp>;
}

export enum InsertFolderDmsFolderSelectColumn {
  /** column name */
  Change = 'change',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  View = 'view'
}

export enum InsertFolderDmsFolderUpdateColumn {
  /** column name */
  Change = 'change',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  View = 'view'
}

export interface InsertFolderGnpieAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderGnpieAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderGnpieAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderGnpieAggregateBoolExpCount>;
}

export interface InsertFolderGnpieAggregateBoolExpBoolAnd {
  arguments: InsertFolderGnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderGnpieBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderGnpieAggregateBoolExpBoolOr {
  arguments: InsertFolderGnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderGnpieBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderGnpieAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderGnpieSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderGnpieBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderGnpieArrRelInsertInput {
  data: Array<InsertFolderGnpieInsertInput>;
  on_conflict?: InputMaybe<InsertFolderGnpieOnConflict>;
}

export interface InsertFolderGnpieBoolExp {
  _and?: InputMaybe<Array<InsertFolderGnpieBoolExp>>;
  _not?: InputMaybe<InsertFolderGnpieBoolExp>;
  _or?: InputMaybe<Array<InsertFolderGnpieBoolExp>>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_main_negotiator?: InputMaybe<InsertFolderBooleanComparisonExp>;
  role?: InputMaybe<InsertFolderAuthrolesEnumComparisonExp>;
  roleByRole?: InputMaybe<InsertFolderAuthrolesBoolExp>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  topic?: InputMaybe<InsertFolderTopicsBoolExp>;
  topic_id?: InputMaybe<InsertFolderIntComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderGnpieConstraint {
  /** unique or primary key constraint on columns "id" */
  GnpiePkey = 'gnpie_pkey'
}

export interface InsertFolderGnpieInsertInput {
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_main_negotiator?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<InsertFolderAuthrolesEnum>;
  roleByRole?: InputMaybe<InsertFolderAuthrolesObjRelInsertInput>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic?: InputMaybe<InsertFolderTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderGnpieOnConflict {
  constraint: InsertFolderGnpieConstraint;
  update_columns: Array<InsertFolderGnpieUpdateColumn>;
  where?: InputMaybe<InsertFolderGnpieBoolExp>;
}

export enum InsertFolderGnpieSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertFolderGnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

export enum InsertFolderGnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

export enum InsertFolderGnpieUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertFolderInstitutionsAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderInstitutionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderInstitutionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderInstitutionsAggregateBoolExpCount>;
}

export interface InsertFolderInstitutionsAggregateBoolExpBoolAnd {
  arguments: InsertFolderInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderInstitutionsAggregateBoolExpBoolOr {
  arguments: InsertFolderInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderInstitutionsArrRelInsertInput {
  data: Array<InsertFolderInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderInstitutionsOnConflict>;
}

export interface InsertFolderInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertFolderInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderInstitutionsBoolExp>>;
  acquis_institutions?: InputMaybe<InsertFolderPkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<InsertFolderPkieAcquisInstitutionsAggregateBoolExp>;
  child_institutions?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  child_institutions_aggregate?: InputMaybe<InsertFolderInstitutionsAggregateBoolExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  measures?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  name?: InputMaybe<InsertFolderStringComparisonExp>;
  parent_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  parent_institution?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  short_name?: InputMaybe<InsertFolderStringComparisonExp>;
  type?: InputMaybe<InsertFolderTypeInstitutionsEnumComparisonExp>;
  type_institution?: InputMaybe<InsertFolderTypeInstitutionsBoolExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  users?: InputMaybe<InsertFolderUsersBoolExp>;
}

export enum InsertFolderInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  InstitutionsPkey = 'institutions_pkey'
}

export interface InsertFolderInstitutionsInsertInput {
  acquis_institutions?: InputMaybe<InsertFolderPkieAcquisInstitutionsArrRelInsertInput>;
  child_institutions?: InputMaybe<InsertFolderInstitutionsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measures?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_institution?: InputMaybe<InsertFolderInstitutionsObjRelInsertInput>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InsertFolderTypeInstitutionsEnum>;
  type_institution?: InputMaybe<InsertFolderTypeInstitutionsObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
}

export interface InsertFolderInstitutionsObjRelInsertInput {
  data: InsertFolderInstitutionsInsertInput;
  on_conflict?: InputMaybe<InsertFolderInstitutionsOnConflict>;
}

export interface InsertFolderInstitutionsOnConflict {
  constraint: InsertFolderInstitutionsConstraint;
  update_columns: Array<InsertFolderInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertFolderInstitutionsBoolExp>;
}

export enum InsertFolderInstitutionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderInstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderInstitutionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderIntComparisonExp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface InsertFolderJsonbCastExp {
  String?: InputMaybe<InsertFolderStringComparisonExp>;
}

export interface InsertFolderJsonbComparisonExp {
  _cast?: InputMaybe<InsertFolderJsonbCastExp>;
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  _has_key?: InputMaybe<Scalars['String']['input']>;
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

export interface InsertFolderMeetingNotesAggregateBoolExp {
  count?: InputMaybe<InsertFolderMeetingNotesAggregateBoolExpCount>;
}

export interface InsertFolderMeetingNotesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderMeetingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderMeetingNotesBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderMeetingNotesArrRelInsertInput {
  data: Array<InsertFolderMeetingNotesInsertInput>;
  on_conflict?: InputMaybe<InsertFolderMeetingNotesOnConflict>;
}

export interface InsertFolderMeetingNotesBoolExp {
  _and?: InputMaybe<Array<InsertFolderMeetingNotesBoolExp>>;
  _not?: InputMaybe<InsertFolderMeetingNotesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderMeetingNotesBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  meeting?: InputMaybe<InsertFolderMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  note?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
}

export enum InsertFolderMeetingNotesConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingNotesPkey = 'meeting_notes_pkey'
}

export interface InsertFolderMeetingNotesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertFolderMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
}

export interface InsertFolderMeetingNotesOnConflict {
  constraint: InsertFolderMeetingNotesConstraint;
  update_columns: Array<InsertFolderMeetingNotesUpdateColumn>;
  where?: InputMaybe<InsertFolderMeetingNotesBoolExp>;
}

export enum InsertFolderMeetingNotesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderMeetingNotesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderMeetingUsersAggregateBoolExp {
  count?: InputMaybe<InsertFolderMeetingUsersAggregateBoolExpCount>;
}

export interface InsertFolderMeetingUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderMeetingUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderMeetingUsersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderMeetingUsersArrRelInsertInput {
  data: Array<InsertFolderMeetingUsersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderMeetingUsersOnConflict>;
}

export interface InsertFolderMeetingUsersBoolExp {
  _and?: InputMaybe<Array<InsertFolderMeetingUsersBoolExp>>;
  _not?: InputMaybe<InsertFolderMeetingUsersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderMeetingUsersBoolExp>>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  meeting?: InputMaybe<InsertFolderMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
  user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderMeetingUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingUsersPkey = 'meeting_users_pkey'
}

export interface InsertFolderMeetingUsersInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertFolderMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderMeetingUsersOnConflict {
  constraint: InsertFolderMeetingUsersConstraint;
  update_columns: Array<InsertFolderMeetingUsersUpdateColumn>;
  where?: InputMaybe<InsertFolderMeetingUsersBoolExp>;
}

export enum InsertFolderMeetingUsersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export enum InsertFolderMeetingUsersUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export interface InsertFolderMeetingsAggregateBoolExp {
  count?: InputMaybe<InsertFolderMeetingsAggregateBoolExpCount>;
}

export interface InsertFolderMeetingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderMeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderMeetingsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderMeetingsArrRelInsertInput {
  data: Array<InsertFolderMeetingsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderMeetingsOnConflict>;
}

export interface InsertFolderMeetingsBoolExp {
  _and?: InputMaybe<Array<InsertFolderMeetingsBoolExp>>;
  _not?: InputMaybe<InsertFolderMeetingsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderMeetingsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  meeting_date?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  meeting_notes?: InputMaybe<InsertFolderMeetingNotesBoolExp>;
  meeting_notes_aggregate?: InputMaybe<InsertFolderMeetingNotesAggregateBoolExp>;
  meeting_users?: InputMaybe<InsertFolderMeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<InsertFolderMeetingUsersAggregateBoolExp>;
  minutes_of_meeting?: InputMaybe<InsertFolderStringComparisonExp>;
  tasks?: InputMaybe<InsertFolderTaskBoolExp>;
  tasks_aggregate?: InputMaybe<InsertFolderTaskAggregateBoolExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
}

export enum InsertFolderMeetingsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingsPkey = 'meetings_pkey'
}

export interface InsertFolderMeetingsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_date?: InputMaybe<Scalars['timestamptz']['input']>;
  meeting_notes?: InputMaybe<InsertFolderMeetingNotesArrRelInsertInput>;
  meeting_users?: InputMaybe<InsertFolderMeetingUsersArrRelInsertInput>;
  minutes_of_meeting?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<InsertFolderTaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
}

export interface InsertFolderMeetingsObjRelInsertInput {
  data: InsertFolderMeetingsInsertInput;
  on_conflict?: InputMaybe<InsertFolderMeetingsOnConflict>;
}

export interface InsertFolderMeetingsOnConflict {
  constraint: InsertFolderMeetingsConstraint;
  update_columns: Array<InsertFolderMeetingsUpdateColumn>;
  where?: InputMaybe<InsertFolderMeetingsBoolExp>;
}

export enum InsertFolderMeetingsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderMeetingsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderNumericArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _contains?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _eq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _lte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _neq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
}

export interface InsertFolderNumericComparisonExp {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
}

export interface InsertFolderOutput {
  __typename?: 'InsertFolderOutput';
  change?: Maybe<Scalars['jsonb']['output']>;
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  children: Array<DmsFolder>;
  /** An aggregate relationship */
  children_aggregate: DmsFolderAggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  dms_id?: Maybe<Scalars['numeric']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Users>;
  parent?: Maybe<DmsFolder>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  path: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  view?: Maybe<Scalars['jsonb']['output']>;
}


export interface InsertFolderOutputChildrenArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface InsertFolderOutputChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}

export interface InsertFolderPkieAcquisAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExpCount>;
}

export interface InsertFolderPkieAcquisAggregateBoolExpBoolAnd {
  arguments: InsertFolderPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieAcquisAggregateBoolExpBoolOr {
  arguments: InsertFolderPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieAcquisAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieAcquisSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieAcquisArrRelInsertInput {
  data: Array<InsertFolderPkieAcquisInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieAcquisOnConflict>;
}

export interface InsertFolderPkieAcquisBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieAcquisBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieAcquisBoolExp>>;
  acquis_institutions?: InputMaybe<InsertFolderPkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<InsertFolderPkieAcquisInstitutionsAggregateBoolExp>;
  author?: InputMaybe<InsertFolderStringComparisonExp>;
  celex?: InputMaybe<InsertFolderStringComparisonExp>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  current_consolidated_version?: InputMaybe<InsertFolderStringComparisonExp>;
  date_created?: InputMaybe<InsertFolderDateComparisonExp>;
  date_of_effect?: InputMaybe<InsertFolderStringComparisonExp>;
  date_of_end_of_validity?: InputMaybe<InsertFolderStringComparisonExp>;
  date_of_publication?: InputMaybe<InsertFolderDateComparisonExp>;
  date_of_transposition?: InputMaybe<InsertFolderStringComparisonExp>;
  directory_code?: InputMaybe<InsertFolderStringComparisonExp>;
  eli?: InputMaybe<InsertFolderStringComparisonExp>;
  eurovoc_descriptor?: InputMaybe<InsertFolderStringComparisonExp>;
  form?: InputMaybe<InsertFolderStringComparisonExp>;
  in_force_indicator?: InputMaybe<InsertFolderBooleanComparisonExp>;
  is_aligned?: InputMaybe<InsertFolderBooleanComparisonExp>;
  latest_conslolidated_version?: InputMaybe<InsertFolderStringComparisonExp>;
  latest_modification_notice?: InputMaybe<InsertFolderStringComparisonExp>;
  level_of_alignment?: InputMaybe<InsertFolderPkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<InsertFolderPkieLevelOfAlignmentBoolExp>;
  measures?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  national_acts?: InputMaybe<InsertFolderPkieAcquisNationalActsBoolExp>;
  national_acts_aggregate?: InputMaybe<InsertFolderPkieAcquisNationalActsAggregateBoolExp>;
  new_chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  new_subchapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  new_topic_id?: InputMaybe<InsertFolderIntComparisonExp>;
  publication_reference?: InputMaybe<InsertFolderStringComparisonExp>;
  reference?: InputMaybe<InsertFolderStringComparisonExp>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  subchapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  subject_matter?: InputMaybe<InsertFolderStringComparisonExp>;
  subtitle?: InputMaybe<InsertFolderStringComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  to_be_transposed?: InputMaybe<InsertFolderBooleanComparisonExp>;
  to_be_transposed_general_date?: InputMaybe<InsertFolderDateComparisonExp>;
  to_be_transposed_work_plan_task_date?: InputMaybe<InsertFolderDateComparisonExp>;
  topic?: InputMaybe<InsertFolderTopicsBoolExp>;
  topic_id?: InputMaybe<InsertFolderIntComparisonExp>;
  work_plan_task?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderPkieAcquisConstraint {
  /** unique or primary key constraint on columns "celex" */
  AcquisPkey = 'acquis_pkey'
}

export interface InsertFolderPkieAcquisInsertInput {
  acquis_institutions?: InputMaybe<InsertFolderPkieAcquisInstitutionsArrRelInsertInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  current_consolidated_version?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['date']['input']>;
  date_of_effect?: InputMaybe<Scalars['String']['input']>;
  date_of_end_of_validity?: InputMaybe<Scalars['String']['input']>;
  date_of_publication?: InputMaybe<Scalars['date']['input']>;
  date_of_transposition?: InputMaybe<Scalars['String']['input']>;
  directory_code?: InputMaybe<Scalars['String']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  eurovoc_descriptor?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  in_force_indicator?: InputMaybe<Scalars['Boolean']['input']>;
  is_aligned?: InputMaybe<Scalars['Boolean']['input']>;
  latest_conslolidated_version?: InputMaybe<Scalars['String']['input']>;
  latest_modification_notice?: InputMaybe<Scalars['String']['input']>;
  level_of_alignment?: InputMaybe<InsertFolderPkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<InsertFolderPkieLevelOfAlignmentObjRelInsertInput>;
  measures?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  national_acts?: InputMaybe<InsertFolderPkieAcquisNationalActsArrRelInsertInput>;
  new_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  new_subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  new_topic_id?: InputMaybe<Scalars['Int']['input']>;
  publication_reference?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  subject_matter?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to_be_transposed?: InputMaybe<Scalars['Boolean']['input']>;
  to_be_transposed_general_date?: InputMaybe<Scalars['date']['input']>;
  to_be_transposed_work_plan_task_date?: InputMaybe<Scalars['date']['input']>;
  topic?: InputMaybe<InsertFolderTopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  work_plan_task?: InputMaybe<InsertFolderPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderPkieAcquisInstitutionsAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieAcquisInstitutionsAggregateBoolExpCount>;
}

export interface InsertFolderPkieAcquisInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieAcquisInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieAcquisInstitutionsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieAcquisInstitutionsArrRelInsertInput {
  data: Array<InsertFolderPkieAcquisInstitutionsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieAcquisInstitutionsOnConflict>;
}

export interface InsertFolderPkieAcquisInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieAcquisInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieAcquisInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieAcquisInstitutionsBoolExp>>;
  acqui?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acqui_celex?: InputMaybe<InsertFolderStringComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  institution?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderPkieAcquisInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  AcquisInstitutionsPkey = 'acquis_institutions_pkey'
}

export interface InsertFolderPkieAcquisInstitutionsInsertInput {
  acqui?: InputMaybe<InsertFolderPkieAcquisObjRelInsertInput>;
  acqui_celex?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertFolderInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderPkieAcquisInstitutionsOnConflict {
  constraint: InsertFolderPkieAcquisInstitutionsConstraint;
  update_columns: Array<InsertFolderPkieAcquisInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieAcquisInstitutionsBoolExp>;
}

export enum InsertFolderPkieAcquisInstitutionsSelectColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export enum InsertFolderPkieAcquisInstitutionsUpdateColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface InsertFolderPkieAcquisNationalActsAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieAcquisNationalActsAggregateBoolExpCount>;
}

export interface InsertFolderPkieAcquisNationalActsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieAcquisNationalActsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieAcquisNationalActsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieAcquisNationalActsArrRelInsertInput {
  data: Array<InsertFolderPkieAcquisNationalActsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieAcquisNationalActsOnConflict>;
}

export interface InsertFolderPkieAcquisNationalActsBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieAcquisNationalActsBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieAcquisNationalActsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieAcquisNationalActsBoolExp>>;
  acqui?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_celex?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  national_act?: InputMaybe<InsertFolderPkieNationalActsBoolExp>;
  national_act_eli?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderPkieAcquisNationalActsConstraint {
  /** unique or primary key constraint on columns "id" */
  AcquisNationalActsPkey = 'acquis_national_acts_pkey'
}

export interface InsertFolderPkieAcquisNationalActsInsertInput {
  acqui?: InputMaybe<InsertFolderPkieAcquisObjRelInsertInput>;
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  national_act?: InputMaybe<InsertFolderPkieNationalActsObjRelInsertInput>;
  national_act_eli?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderPkieAcquisNationalActsObjRelInsertInput {
  data: InsertFolderPkieAcquisNationalActsInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieAcquisNationalActsOnConflict>;
}

export interface InsertFolderPkieAcquisNationalActsOnConflict {
  constraint: InsertFolderPkieAcquisNationalActsConstraint;
  update_columns: Array<InsertFolderPkieAcquisNationalActsUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieAcquisNationalActsBoolExp>;
}

export enum InsertFolderPkieAcquisNationalActsSelectColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export enum InsertFolderPkieAcquisNationalActsUpdateColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export interface InsertFolderPkieAcquisObjRelInsertInput {
  data: InsertFolderPkieAcquisInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieAcquisOnConflict>;
}

export interface InsertFolderPkieAcquisOnConflict {
  constraint: InsertFolderPkieAcquisConstraint;
  update_columns: Array<InsertFolderPkieAcquisUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
}

export enum InsertFolderPkieAcquisSelectColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NewChapterId = 'new_chapter_id',
  /** column name */
  NewSubchapterId = 'new_subchapter_id',
  /** column name */
  NewTopicId = 'new_topic_id',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertFolderPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

export enum InsertFolderPkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

export enum InsertFolderPkieAcquisUpdateColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NewChapterId = 'new_chapter_id',
  /** column name */
  NewSubchapterId = 'new_subchapter_id',
  /** column name */
  NewTopicId = 'new_topic_id',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertFolderPkieLevelOfAlignmentBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieLevelOfAlignmentBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieLevelOfAlignmentBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieLevelOfAlignmentBoolExp>>;
  acquis?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExp>;
  level?: InputMaybe<InsertFolderStringComparisonExp>;
  measures?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
}

export enum InsertFolderPkieLevelOfAlignmentConstraint {
  /** unique or primary key constraint on columns "level" */
  LevelOfAlignmentPkey = 'level_of_alignment_pkey'
}

export enum InsertFolderPkieLevelOfAlignmentEnum {
  ELarte = 'E_LARTE',
  EMire = 'E_MIRE',
  EModeruar = 'E_MODERUAR',
  EPlote = 'E_PLOTE',
  Fillestare = 'FILLESTARE',
  Na = 'NA'
}

export interface InsertFolderPkieLevelOfAlignmentEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderPkieLevelOfAlignmentEnum>;
  _in?: InputMaybe<Array<InsertFolderPkieLevelOfAlignmentEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderPkieLevelOfAlignmentEnum>;
  _nin?: InputMaybe<Array<InsertFolderPkieLevelOfAlignmentEnum>>;
}

export interface InsertFolderPkieLevelOfAlignmentInsertInput {
  acquis?: InputMaybe<InsertFolderPkieAcquisArrRelInsertInput>;
  level?: InputMaybe<Scalars['String']['input']>;
  measures?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
}

export interface InsertFolderPkieLevelOfAlignmentObjRelInsertInput {
  data: InsertFolderPkieLevelOfAlignmentInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieLevelOfAlignmentOnConflict>;
}

export interface InsertFolderPkieLevelOfAlignmentOnConflict {
  constraint: InsertFolderPkieLevelOfAlignmentConstraint;
  update_columns: Array<InsertFolderPkieLevelOfAlignmentUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieLevelOfAlignmentBoolExp>;
}

export enum InsertFolderPkieLevelOfAlignmentUpdateColumn {
  /** column name */
  Level = 'level'
}

export interface InsertFolderPkieMeasureActivityAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieMeasureActivityAggregateBoolExpCount>;
}

export interface InsertFolderPkieMeasureActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieMeasureActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureActivityBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieMeasureActivityArrRelInsertInput {
  data: Array<InsertFolderPkieMeasureActivityInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureActivityOnConflict>;
}

export interface InsertFolderPkieMeasureActivityBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieMeasureActivityBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieMeasureActivityBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieMeasureActivityBoolExp>>;
  activity?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  measure?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderPkieMeasureActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureActivityPkey = 'measure_activity_pkey'
}

export interface InsertFolderPkieMeasureActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertFolderPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderPkieMeasureActivityOnConflict {
  constraint: InsertFolderPkieMeasureActivityConstraint;
  update_columns: Array<InsertFolderPkieMeasureActivityUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieMeasureActivityBoolExp>;
}

export enum InsertFolderPkieMeasureActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export enum InsertFolderPkieMeasureActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export interface InsertFolderPkieMeasureAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExpCount>;
}

export interface InsertFolderPkieMeasureAggregateBoolExpBoolAnd {
  arguments: InsertFolderPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieMeasureAggregateBoolExpBoolOr {
  arguments: InsertFolderPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieMeasureAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieMeasureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieMeasureAnswersAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieMeasureAnswersAggregateBoolExpCount>;
}

export interface InsertFolderPkieMeasureAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieMeasureAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureAnswersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieMeasureAnswersArrRelInsertInput {
  data: Array<InsertFolderPkieMeasureAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureAnswersOnConflict>;
}

export interface InsertFolderPkieMeasureAnswersBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieMeasureAnswersBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieMeasureAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieMeasureAnswersBoolExp>>;
  answer?: InputMaybe<InsertFolderStringComparisonExp>;
  answered_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  measure?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderPkieMeasureAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureAnswersPkey = 'measure_answers_pkey'
}

export interface InsertFolderPkieMeasureAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertFolderPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderPkieMeasureAnswersOnConflict {
  constraint: InsertFolderPkieMeasureAnswersConstraint;
  update_columns: Array<InsertFolderPkieMeasureAnswersUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieMeasureAnswersBoolExp>;
}

export enum InsertFolderPkieMeasureAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderPkieMeasureAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderPkieMeasureArrRelInsertInput {
  data: Array<InsertFolderPkieMeasureInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureOnConflict>;
}

export interface InsertFolderPkieMeasureBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieMeasureBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieMeasureBoolExp>>;
  acqui?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_celex?: InputMaybe<InsertFolderStringComparisonExp>;
  assignee?: InputMaybe<InsertFolderUsersBoolExp>;
  assignee_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  crated_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  impact?: InputMaybe<InsertFolderStringComparisonExp>;
  is_completed?: InputMaybe<InsertFolderBooleanComparisonExp>;
  level_of_alignment?: InputMaybe<InsertFolderPkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<InsertFolderPkieLevelOfAlignmentBoolExp>;
  measure_activities?: InputMaybe<InsertFolderPkieMeasureActivityBoolExp>;
  measure_activities_aggregate?: InputMaybe<InsertFolderPkieMeasureActivityAggregateBoolExp>;
  measure_answers?: InputMaybe<InsertFolderPkieMeasureAnswersBoolExp>;
  measure_answers_aggregate?: InputMaybe<InsertFolderPkieMeasureAnswersAggregateBoolExp>;
  measure_budgets?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<InsertFolderPkieMeasureBudgetsAggregateBoolExp>;
  measure_comments?: InputMaybe<InsertFolderPkieMeasureCommentBoolExp>;
  measure_comments_aggregate?: InputMaybe<InsertFolderPkieMeasureCommentAggregateBoolExp>;
  measure_status?: InputMaybe<InsertFolderPkieMeasureStatusBoolExp>;
  proposing_institution?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  proposing_institution_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  quadrennial_scheduled_approval?: InputMaybe<InsertFolderStringComparisonExp>;
  reporter?: InputMaybe<InsertFolderUsersBoolExp>;
  reporter_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  status?: InputMaybe<InsertFolderPkieMeasureStatusEnumComparisonExp>;
  text?: InputMaybe<InsertFolderStringComparisonExp>;
  type?: InputMaybe<InsertFolderStringComparisonExp>;
  type_alignment_act?: InputMaybe<InsertFolderPkieTypeAlignmentActBoolExp>;
  type_of_alignment?: InputMaybe<InsertFolderPkieTypeAlignmentActEnumComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  weight_transposition?: InputMaybe<InsertFolderIntComparisonExp>;
  year_of_completition?: InputMaybe<InsertFolderIntComparisonExp>;
  yearly_quarter?: InputMaybe<InsertFolderYearlyQuartersBoolExp>;
}

export interface InsertFolderPkieMeasureBudgetsAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderPkieMeasureBudgetsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderPkieMeasureBudgetsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderPkieMeasureBudgetsAggregateBoolExpCount>;
}

export interface InsertFolderPkieMeasureBudgetsAggregateBoolExpBoolAnd {
  arguments: InsertFolderPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieMeasureBudgetsAggregateBoolExpBoolOr {
  arguments: InsertFolderPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieMeasureBudgetsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieMeasureBudgetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieMeasureBudgetsArrRelInsertInput {
  data: Array<InsertFolderPkieMeasureBudgetsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureBudgetsOnConflict>;
}

export interface InsertFolderPkieMeasureBudgetsBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieMeasureBudgetsBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieMeasureBudgetsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  measure?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  source_of_funding?: InputMaybe<InsertFolderStringComparisonExp>;
  type_budget?: InputMaybe<InsertFolderPkieTypeMeasureBudgetBoolExp>;
  type_of_budget?: InputMaybe<InsertFolderPkieTypeMeasureBudgetEnumComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  value?: InputMaybe<InsertFolderNumericComparisonExp>;
  year?: InputMaybe<InsertFolderIntComparisonExp>;
}

export enum InsertFolderPkieMeasureBudgetsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureBudgetsPkey = 'measure_budgets_pkey'
}

export interface InsertFolderPkieMeasureBudgetsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<InsertFolderPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  source_of_funding?: InputMaybe<Scalars['String']['input']>;
  type_budget?: InputMaybe<InsertFolderPkieTypeMeasureBudgetObjRelInsertInput>;
  type_of_budget?: InputMaybe<InsertFolderPkieTypeMeasureBudgetEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

export interface InsertFolderPkieMeasureBudgetsOnConflict {
  constraint: InsertFolderPkieMeasureBudgetsConstraint;
  update_columns: Array<InsertFolderPkieMeasureBudgetsUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
}

export enum InsertFolderPkieMeasureBudgetsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export enum InsertFolderPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderPkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderPkieMeasureBudgetsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export interface InsertFolderPkieMeasureCommentAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieMeasureCommentAggregateBoolExpCount>;
}

export interface InsertFolderPkieMeasureCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieMeasureCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieMeasureCommentBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieMeasureCommentArrRelInsertInput {
  data: Array<InsertFolderPkieMeasureCommentInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureCommentOnConflict>;
}

export interface InsertFolderPkieMeasureCommentBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieMeasureCommentBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieMeasureCommentBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieMeasureCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  comment?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  measure?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measure_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderPkieMeasureCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureCommentPkey = 'measure_comment_pkey'
}

export interface InsertFolderPkieMeasureCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<InsertFolderPkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderPkieMeasureCommentOnConflict {
  constraint: InsertFolderPkieMeasureCommentConstraint;
  update_columns: Array<InsertFolderPkieMeasureCommentUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieMeasureCommentBoolExp>;
}

export enum InsertFolderPkieMeasureCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderPkieMeasureCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderPkieMeasureConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasurePkey = 'measure_pkey'
}

export interface InsertFolderPkieMeasureInsertInput {
  acqui?: InputMaybe<InsertFolderPkieAcquisObjRelInsertInput>;
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  assignee?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  crated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Scalars['String']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  level_of_alignment?: InputMaybe<InsertFolderPkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<InsertFolderPkieLevelOfAlignmentObjRelInsertInput>;
  measure_activities?: InputMaybe<InsertFolderPkieMeasureActivityArrRelInsertInput>;
  measure_answers?: InputMaybe<InsertFolderPkieMeasureAnswersArrRelInsertInput>;
  measure_budgets?: InputMaybe<InsertFolderPkieMeasureBudgetsArrRelInsertInput>;
  measure_comments?: InputMaybe<InsertFolderPkieMeasureCommentArrRelInsertInput>;
  measure_status?: InputMaybe<InsertFolderPkieMeasureStatusObjRelInsertInput>;
  proposing_institution?: InputMaybe<InsertFolderInstitutionsObjRelInsertInput>;
  proposing_institution_id?: InputMaybe<Scalars['uuid']['input']>;
  quadrennial_scheduled_approval?: InputMaybe<Scalars['String']['input']>;
  reporter?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertFolderPkieMeasureStatusEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_alignment_act?: InputMaybe<InsertFolderPkieTypeAlignmentActObjRelInsertInput>;
  type_of_alignment?: InputMaybe<InsertFolderPkieTypeAlignmentActEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
  yearly_quarter?: InputMaybe<InsertFolderYearlyQuartersObjRelInsertInput>;
}

export interface InsertFolderPkieMeasureObjRelInsertInput {
  data: InsertFolderPkieMeasureInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureOnConflict>;
}

export interface InsertFolderPkieMeasureOnConflict {
  constraint: InsertFolderPkieMeasureConstraint;
  update_columns: Array<InsertFolderPkieMeasureUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
}

export enum InsertFolderPkieMeasureSelectColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export enum InsertFolderPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export enum InsertFolderPkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

export interface InsertFolderPkieMeasureStatusBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieMeasureStatusBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieMeasureStatusBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieMeasureStatusBoolExp>>;
  measures?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderPkieMeasureStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  MeasureStatusPkey = 'measure_status_pkey'
}

export enum InsertFolderPkieMeasureStatusEnum {
  HartimKonceptiInstitucioni = 'HARTIM_KONCEPTI_INSTITUCIONI',
  HyrjaNeFuqi = 'HYRJA_NE_FUQI',
  MiratimiNeKm = 'MIRATIMI_NE_KM',
  MiratimiNgaMinistri = 'MIRATIMI_NGA_MINISTRI',
  MiratimNgaKuvendi = 'MIRATIM_NGA_KUVENDI',
  PergatitjaEAktitLigjorInstitucioni = 'PERGATITJA_E_AKTIT_LIGJOR_INSTITUCIONI',
  PerKonsultimMeInstitucionet = 'PER_KONSULTIM_ME_INSTITUCIONET',
  PerKonsultimPublik = 'PER_KONSULTIM_PUBLIK',
  ShqyrtimNgaKuvendi = 'SHQYRTIM_NGA_KUVENDI'
}

export interface InsertFolderPkieMeasureStatusEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderPkieMeasureStatusEnum>;
  _in?: InputMaybe<Array<InsertFolderPkieMeasureStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderPkieMeasureStatusEnum>;
  _nin?: InputMaybe<Array<InsertFolderPkieMeasureStatusEnum>>;
}

export interface InsertFolderPkieMeasureStatusInsertInput {
  measures?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderPkieMeasureStatusObjRelInsertInput {
  data: InsertFolderPkieMeasureStatusInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieMeasureStatusOnConflict>;
}

export interface InsertFolderPkieMeasureStatusOnConflict {
  constraint: InsertFolderPkieMeasureStatusConstraint;
  update_columns: Array<InsertFolderPkieMeasureStatusUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieMeasureStatusBoolExp>;
}

export enum InsertFolderPkieMeasureStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export enum InsertFolderPkieMeasureUpdateColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export interface InsertFolderPkieNationalActsBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieNationalActsBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieNationalActsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieNationalActsBoolExp>>;
  acquis?: InputMaybe<InsertFolderPkieAcquisNationalActsBoolExp>;
  acquis_aggregate?: InputMaybe<InsertFolderPkieAcquisNationalActsAggregateBoolExp>;
  acquis_national_act?: InputMaybe<InsertFolderPkieAcquisNationalActsBoolExp>;
  eli?: InputMaybe<InsertFolderStringComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderPkieNationalActsConstraint {
  /** unique or primary key constraint on columns "eli" */
  NationalActsPkey = 'national_acts_pkey'
}

export interface InsertFolderPkieNationalActsInsertInput {
  acquis?: InputMaybe<InsertFolderPkieAcquisNationalActsArrRelInsertInput>;
  acquis_national_act?: InputMaybe<InsertFolderPkieAcquisNationalActsObjRelInsertInput>;
  eli?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderPkieNationalActsObjRelInsertInput {
  data: InsertFolderPkieNationalActsInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieNationalActsOnConflict>;
}

export interface InsertFolderPkieNationalActsOnConflict {
  constraint: InsertFolderPkieNationalActsConstraint;
  update_columns: Array<InsertFolderPkieNationalActsUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieNationalActsBoolExp>;
}

export enum InsertFolderPkieNationalActsUpdateColumn {
  /** column name */
  Eli = 'eli',
  /** column name */
  Title = 'title'
}

export interface InsertFolderPkieTypeAlignmentActBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieTypeAlignmentActBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieTypeAlignmentActBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieTypeAlignmentActBoolExp>>;
  measures?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  type?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderPkieTypeAlignmentActConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeAlignmentActPkey = 'type_alignment_act_pkey'
}

export enum InsertFolderPkieTypeAlignmentActEnum {
  DokumentPolitikash = 'DOKUMENT_POLITIKASH',
  Ligj = 'LIGJ',
  Udhezim = 'UDHEZIM',
  Urdher = 'URDHER',
  Vkm = 'VKM'
}

export interface InsertFolderPkieTypeAlignmentActEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderPkieTypeAlignmentActEnum>;
  _in?: InputMaybe<Array<InsertFolderPkieTypeAlignmentActEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderPkieTypeAlignmentActEnum>;
  _nin?: InputMaybe<Array<InsertFolderPkieTypeAlignmentActEnum>>;
}

export interface InsertFolderPkieTypeAlignmentActInsertInput {
  measures?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderPkieTypeAlignmentActObjRelInsertInput {
  data: InsertFolderPkieTypeAlignmentActInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieTypeAlignmentActOnConflict>;
}

export interface InsertFolderPkieTypeAlignmentActOnConflict {
  constraint: InsertFolderPkieTypeAlignmentActConstraint;
  update_columns: Array<InsertFolderPkieTypeAlignmentActUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieTypeAlignmentActBoolExp>;
}

export enum InsertFolderPkieTypeAlignmentActUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertFolderPkieTypeMeasureBudgetBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieTypeMeasureBudgetBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieTypeMeasureBudgetBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieTypeMeasureBudgetBoolExp>>;
  measure_budgets?: InputMaybe<InsertFolderPkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<InsertFolderPkieMeasureBudgetsAggregateBoolExp>;
  type?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderPkieTypeMeasureBudgetConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeMeasureBudgetPkey = 'type_measure_budget_pkey'
}

export enum InsertFolderPkieTypeMeasureBudgetEnum {
  Existing = 'EXISTING',
  Extra = 'EXTRA'
}

export interface InsertFolderPkieTypeMeasureBudgetEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderPkieTypeMeasureBudgetEnum>;
  _in?: InputMaybe<Array<InsertFolderPkieTypeMeasureBudgetEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderPkieTypeMeasureBudgetEnum>;
  _nin?: InputMaybe<Array<InsertFolderPkieTypeMeasureBudgetEnum>>;
}

export interface InsertFolderPkieTypeMeasureBudgetInsertInput {
  measure_budgets?: InputMaybe<InsertFolderPkieMeasureBudgetsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderPkieTypeMeasureBudgetObjRelInsertInput {
  data: InsertFolderPkieTypeMeasureBudgetInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieTypeMeasureBudgetOnConflict>;
}

export interface InsertFolderPkieTypeMeasureBudgetOnConflict {
  constraint: InsertFolderPkieTypeMeasureBudgetConstraint;
  update_columns: Array<InsertFolderPkieTypeMeasureBudgetUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieTypeMeasureBudgetBoolExp>;
}

export enum InsertFolderPkieTypeMeasureBudgetUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertFolderPkieWorkPlanCommentAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieWorkPlanCommentAggregateBoolExpCount>;
}

export interface InsertFolderPkieWorkPlanCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieWorkPlanCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieWorkPlanCommentBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieWorkPlanCommentArrRelInsertInput {
  data: Array<InsertFolderPkieWorkPlanCommentInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanCommentOnConflict>;
}

export interface InsertFolderPkieWorkPlanCommentBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieWorkPlanCommentBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieWorkPlanCommentBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieWorkPlanCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  comment?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  work_plan_task?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderPkieWorkPlanCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanCommentPkey = 'work_plan_comment_pkey'
}

export interface InsertFolderPkieWorkPlanCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task?: InputMaybe<InsertFolderPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderPkieWorkPlanCommentOnConflict {
  constraint: InsertFolderPkieWorkPlanCommentConstraint;
  update_columns: Array<InsertFolderPkieWorkPlanCommentUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieWorkPlanCommentBoolExp>;
}

export enum InsertFolderPkieWorkPlanCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertFolderPkieWorkPlanCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertFolderPkieWorkPlanStatusesBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieWorkPlanStatusesBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieWorkPlanStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieWorkPlanStatusesBoolExp>>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
  work_plans?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
  work_plans_aggregate?: InputMaybe<InsertFolderPkieWorkPlansAggregateBoolExp>;
}

export enum InsertFolderPkieWorkPlanStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanStatusesPkey = 'work_plan_statuses_pkey'
}

export interface InsertFolderPkieWorkPlanStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plans?: InputMaybe<InsertFolderPkieWorkPlansArrRelInsertInput>;
}

export interface InsertFolderPkieWorkPlanStatusesObjRelInsertInput {
  data: InsertFolderPkieWorkPlanStatusesInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanStatusesOnConflict>;
}

export interface InsertFolderPkieWorkPlanStatusesOnConflict {
  constraint: InsertFolderPkieWorkPlanStatusesConstraint;
  update_columns: Array<InsertFolderPkieWorkPlanStatusesUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieWorkPlanStatusesBoolExp>;
}

export enum InsertFolderPkieWorkPlanStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertFolderPkieWorkPlanTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityAggregateBoolExpCount>;
}

export interface InsertFolderPkieWorkPlanTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieWorkPlanTaskActivityArrRelInsertInput {
  data: Array<InsertFolderPkieWorkPlanTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityOnConflict>;
}

export interface InsertFolderPkieWorkPlanTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  work_plan_task?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderPkieWorkPlanTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskActivityPkey = 'work_plan_task_activity_pkey',
  /** unique or primary key constraint on columns "work_plan_task_id" */
  WorkPlanTaskActivityWorkPlanTaskIdKey = 'work_plan_task_activity_work_plan_task_id_key'
}

export interface InsertFolderPkieWorkPlanTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task?: InputMaybe<InsertFolderPkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderPkieWorkPlanTaskActivityObjRelInsertInput {
  data: InsertFolderPkieWorkPlanTaskActivityInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityOnConflict>;
}

export interface InsertFolderPkieWorkPlanTaskActivityOnConflict {
  constraint: InsertFolderPkieWorkPlanTaskActivityConstraint;
  update_columns: Array<InsertFolderPkieWorkPlanTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityBoolExp>;
}

export enum InsertFolderPkieWorkPlanTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export enum InsertFolderPkieWorkPlanTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface InsertFolderPkieWorkPlanTaskAggregateBoolExp {
  count?: InputMaybe<InsertFolderPkieWorkPlanTaskAggregateBoolExpCount>;
}

export interface InsertFolderPkieWorkPlanTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieWorkPlanTaskArrRelInsertInput {
  data: Array<InsertFolderPkieWorkPlanTaskInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanTaskOnConflict>;
}

export interface InsertFolderPkieWorkPlanTaskBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskBoolExp>>;
  acquis?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExp>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  reporter?: InputMaybe<InsertFolderUsersBoolExp>;
  reporter_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  status?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  updated_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  userByUpdatedByUserId?: InputMaybe<InsertFolderUsersBoolExp>;
  workPlanTaskActivityById?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityBoolExp>;
  work_plan?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
  work_plan_comments?: InputMaybe<InsertFolderPkieWorkPlanCommentBoolExp>;
  work_plan_comments_aggregate?: InputMaybe<InsertFolderPkieWorkPlanCommentAggregateBoolExp>;
  work_plan_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  work_plan_task_activity?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityBoolExp>;
  work_plan_task_activity_aggregate?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityAggregateBoolExp>;
  work_plan_task_status?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesBoolExp>;
}

export enum InsertFolderPkieWorkPlanTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskPkey = 'work_plan_task_pkey'
}

export interface InsertFolderPkieWorkPlanTaskInsertInput {
  acquis?: InputMaybe<InsertFolderPkieAcquisArrRelInsertInput>;
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reporter?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  userByUpdatedByUserId?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  workPlanTaskActivityById?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityObjRelInsertInput>;
  work_plan?: InputMaybe<InsertFolderPkieWorkPlansObjRelInsertInput>;
  work_plan_comments?: InputMaybe<InsertFolderPkieWorkPlanCommentArrRelInsertInput>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task_activity?: InputMaybe<InsertFolderPkieWorkPlanTaskActivityArrRelInsertInput>;
  work_plan_task_status?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesObjRelInsertInput>;
}

export interface InsertFolderPkieWorkPlanTaskObjRelInsertInput {
  data: InsertFolderPkieWorkPlanTaskInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanTaskOnConflict>;
}

export interface InsertFolderPkieWorkPlanTaskOnConflict {
  constraint: InsertFolderPkieWorkPlanTaskConstraint;
  update_columns: Array<InsertFolderPkieWorkPlanTaskUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
}

export enum InsertFolderPkieWorkPlanTaskSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface InsertFolderPkieWorkPlanTaskStatusesBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskStatusesBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskStatusesBoolExp>>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
  work_plan_tasks?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertFolderPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertFolderPkieWorkPlanTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanTaskStatusesPkey = 'work_plan_task_statuses_pkey'
}

export enum InsertFolderPkieWorkPlanTaskStatusesEnum {
  Done = 'DONE',
  ToDo = 'TO_DO',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForSecretariat = 'WAITING_FOR_SECRETARIAT'
}

export interface InsertFolderPkieWorkPlanTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertFolderPkieWorkPlanTaskStatusesEnum>>;
}

export interface InsertFolderPkieWorkPlanTaskStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plan_tasks?: InputMaybe<InsertFolderPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertFolderPkieWorkPlanTaskStatusesObjRelInsertInput {
  data: InsertFolderPkieWorkPlanTaskStatusesInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesOnConflict>;
}

export interface InsertFolderPkieWorkPlanTaskStatusesOnConflict {
  constraint: InsertFolderPkieWorkPlanTaskStatusesConstraint;
  update_columns: Array<InsertFolderPkieWorkPlanTaskStatusesUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieWorkPlanTaskStatusesBoolExp>;
}

export enum InsertFolderPkieWorkPlanTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export enum InsertFolderPkieWorkPlanTaskUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface InsertFolderPkieWorkPlansAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderPkieWorkPlansAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderPkieWorkPlansAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderPkieWorkPlansAggregateBoolExpCount>;
}

export interface InsertFolderPkieWorkPlansAggregateBoolExpBoolAnd {
  arguments: InsertFolderPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieWorkPlansAggregateBoolExpBoolOr {
  arguments: InsertFolderPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderPkieWorkPlansAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderPkieWorkPlansSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderPkieWorkPlansArrRelInsertInput {
  data: Array<InsertFolderPkieWorkPlansInsertInput>;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlansOnConflict>;
}

export interface InsertFolderPkieWorkPlansBoolExp {
  _and?: InputMaybe<Array<InsertFolderPkieWorkPlansBoolExp>>;
  _not?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
  _or?: InputMaybe<Array<InsertFolderPkieWorkPlansBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  definition_deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  end_year?: InputMaybe<InsertFolderIntComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_deleted?: InputMaybe<InsertFolderBooleanComparisonExp>;
  start_year?: InputMaybe<InsertFolderIntComparisonExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  work_plan_status?: InputMaybe<InsertFolderPkieWorkPlanStatusesBoolExp>;
  work_plan_tasks?: InputMaybe<InsertFolderPkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<InsertFolderPkieWorkPlanTaskAggregateBoolExp>;
}

export enum InsertFolderPkieWorkPlansConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanPkey = 'work_plan_pkey'
}

export interface InsertFolderPkieWorkPlansInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_status?: InputMaybe<InsertFolderPkieWorkPlanStatusesObjRelInsertInput>;
  work_plan_tasks?: InputMaybe<InsertFolderPkieWorkPlanTaskArrRelInsertInput>;
}

export interface InsertFolderPkieWorkPlansObjRelInsertInput {
  data: InsertFolderPkieWorkPlansInsertInput;
  on_conflict?: InputMaybe<InsertFolderPkieWorkPlansOnConflict>;
}

export interface InsertFolderPkieWorkPlansOnConflict {
  constraint: InsertFolderPkieWorkPlansConstraint;
  update_columns: Array<InsertFolderPkieWorkPlansUpdateColumn>;
  where?: InputMaybe<InsertFolderPkieWorkPlansBoolExp>;
}

export enum InsertFolderPkieWorkPlansSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderPkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

export enum InsertFolderPkieWorkPlansUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderQuestionsAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderQuestionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderQuestionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderQuestionsAggregateBoolExpCount>;
}

export interface InsertFolderQuestionsAggregateBoolExpBoolAnd {
  arguments: InsertFolderQuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderQuestionsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderQuestionsAggregateBoolExpBoolOr {
  arguments: InsertFolderQuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderQuestionsBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderQuestionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderQuestionsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderQuestionsArrRelInsertInput {
  data: Array<InsertFolderQuestionsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderQuestionsOnConflict>;
}

export interface InsertFolderQuestionsBoolExp {
  _and?: InputMaybe<Array<InsertFolderQuestionsBoolExp>>;
  _not?: InputMaybe<InsertFolderQuestionsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderQuestionsBoolExp>>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_active?: InputMaybe<InsertFolderBooleanComparisonExp>;
  report_answers?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertFolderReportAnswersAggregateBoolExp>;
  report_tasks?: InputMaybe<InsertFolderReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertFolderReportTasksAggregateBoolExp>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  text?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderQuestionsConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'Questions_pkey',
  /** unique or primary key constraint on columns "text" */
  QuestionsTextKey = 'Questions_text_key'
}

export interface InsertFolderQuestionsInsertInput {
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  report_answers?: InputMaybe<InsertFolderReportAnswersArrRelInsertInput>;
  report_tasks?: InputMaybe<InsertFolderReportTasksArrRelInsertInput>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderQuestionsObjRelInsertInput {
  data: InsertFolderQuestionsInsertInput;
  on_conflict?: InputMaybe<InsertFolderQuestionsOnConflict>;
}

export interface InsertFolderQuestionsOnConflict {
  constraint: InsertFolderQuestionsConstraint;
  update_columns: Array<InsertFolderQuestionsUpdateColumn>;
  where?: InputMaybe<InsertFolderQuestionsBoolExp>;
}

export enum InsertFolderQuestionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderQuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

export enum InsertFolderQuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

export enum InsertFolderQuestionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderRecommendationsAggregateBoolExp {
  count?: InputMaybe<InsertFolderRecommendationsAggregateBoolExpCount>;
}

export interface InsertFolderRecommendationsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderRecommendationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderRecommendationsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderRecommendationsArrRelInsertInput {
  data: Array<InsertFolderRecommendationsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderRecommendationsOnConflict>;
}

export interface InsertFolderRecommendationsBoolExp {
  _and?: InputMaybe<Array<InsertFolderRecommendationsBoolExp>>;
  _not?: InputMaybe<InsertFolderRecommendationsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderRecommendationsBoolExp>>;
  bookmarks?: InputMaybe<InsertFolderBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertFolderBookmarksAggregateBoolExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report?: InputMaybe<InsertFolderReportsBoolExp>;
  report_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderRecommendationsConstraint {
  /** unique or primary key constraint on columns "id" */
  RecommendationsPkey = 'recommendations_pkey'
}

export interface InsertFolderRecommendationsInsertInput {
  bookmarks?: InputMaybe<InsertFolderBookmarksArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertFolderReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderRecommendationsObjRelInsertInput {
  data: InsertFolderRecommendationsInsertInput;
  on_conflict?: InputMaybe<InsertFolderRecommendationsOnConflict>;
}

export interface InsertFolderRecommendationsOnConflict {
  constraint: InsertFolderRecommendationsConstraint;
  update_columns: Array<InsertFolderRecommendationsUpdateColumn>;
  where?: InputMaybe<InsertFolderRecommendationsBoolExp>;
}

export enum InsertFolderRecommendationsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderRecommendationsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderReportAnswersAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderReportAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderReportAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderReportAnswersAggregateBoolExpCount>;
}

export interface InsertFolderReportAnswersAggregateBoolExpBoolAnd {
  arguments: InsertFolderReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderReportAnswersAggregateBoolExpBoolOr {
  arguments: InsertFolderReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderReportAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderReportAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderReportAnswersArrRelInsertInput {
  data: Array<InsertFolderReportAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderReportAnswersOnConflict>;
}

export interface InsertFolderReportAnswersBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportAnswersBoolExp>>;
  _not?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportAnswersBoolExp>>;
  answer?: InputMaybe<InsertFolderStringComparisonExp>;
  answered_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  final_answer?: InputMaybe<InsertFolderReportTasksBoolExp>;
  final_answer_aggregate?: InputMaybe<InsertFolderReportTasksAggregateBoolExp>;
  has_update?: InputMaybe<InsertFolderBooleanComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  question?: InputMaybe<InsertFolderQuestionsBoolExp>;
  question_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report?: InputMaybe<InsertFolderReportsBoolExp>;
  report_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report_task?: InputMaybe<InsertFolderReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  user?: InputMaybe<InsertFolderUsersBoolExp>;
}

export enum InsertFolderReportAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportAnswersPkey = 'report_answers_pkey'
}

export interface InsertFolderReportAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  final_answer?: InputMaybe<InsertFolderReportTasksArrRelInsertInput>;
  has_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertFolderQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertFolderReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertFolderReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
}

export interface InsertFolderReportAnswersObjRelInsertInput {
  data: InsertFolderReportAnswersInsertInput;
  on_conflict?: InputMaybe<InsertFolderReportAnswersOnConflict>;
}

export interface InsertFolderReportAnswersOnConflict {
  constraint: InsertFolderReportAnswersConstraint;
  update_columns: Array<InsertFolderReportAnswersUpdateColumn>;
  where?: InputMaybe<InsertFolderReportAnswersBoolExp>;
}

export enum InsertFolderReportAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update'
}

export enum InsertFolderReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update'
}

export enum InsertFolderReportAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderReportChapterStatusesBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportChapterStatusesBoolExp>>;
  _not?: InputMaybe<InsertFolderReportChapterStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportChapterStatusesBoolExp>>;
  report_chapters?: InputMaybe<InsertFolderReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertFolderReportChaptersAggregateBoolExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderReportChapterStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportChapterStatusesPkey = 'report_chapter_statuses_pkey'
}

export interface InsertFolderReportChapterStatusesInsertInput {
  report_chapters?: InputMaybe<InsertFolderReportChaptersArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderReportChapterStatusesObjRelInsertInput {
  data: InsertFolderReportChapterStatusesInsertInput;
  on_conflict?: InputMaybe<InsertFolderReportChapterStatusesOnConflict>;
}

export interface InsertFolderReportChapterStatusesOnConflict {
  constraint: InsertFolderReportChapterStatusesConstraint;
  update_columns: Array<InsertFolderReportChapterStatusesUpdateColumn>;
  where?: InputMaybe<InsertFolderReportChapterStatusesBoolExp>;
}

export enum InsertFolderReportChapterStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertFolderReportChaptersAggregateBoolExp {
  count?: InputMaybe<InsertFolderReportChaptersAggregateBoolExpCount>;
}

export interface InsertFolderReportChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderReportChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportChaptersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderReportChaptersArrRelInsertInput {
  data: Array<InsertFolderReportChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderReportChaptersOnConflict>;
}

export interface InsertFolderReportChaptersBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportChaptersBoolExp>>;
  _not?: InputMaybe<InsertFolderReportChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportChaptersBoolExp>>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report?: InputMaybe<InsertFolderReportsBoolExp>;
  report_chapter_status?: InputMaybe<InsertFolderReportChapterStatusesBoolExp>;
  report_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderReportChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportChaptersPkey = 'report_chapters_pkey'
}

export interface InsertFolderReportChaptersInsertInput {
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertFolderReportsObjRelInsertInput>;
  report_chapter_status?: InputMaybe<InsertFolderReportChapterStatusesObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderReportChaptersOnConflict {
  constraint: InsertFolderReportChaptersConstraint;
  update_columns: Array<InsertFolderReportChaptersUpdateColumn>;
  where?: InputMaybe<InsertFolderReportChaptersBoolExp>;
}

export enum InsertFolderReportChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderReportChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderReportStatusesBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportStatusesBoolExp>>;
  _not?: InputMaybe<InsertFolderReportStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportStatusesBoolExp>>;
  reports?: InputMaybe<InsertFolderReportsBoolExp>;
  reports_aggregate?: InputMaybe<InsertFolderReportsAggregateBoolExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderReportStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportStatusesPkey = 'report_statuses_pkey'
}

export enum InsertFolderReportStatusesEnum {
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING'
}

export interface InsertFolderReportStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderReportStatusesEnum>;
  _in?: InputMaybe<Array<InsertFolderReportStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderReportStatusesEnum>;
  _nin?: InputMaybe<Array<InsertFolderReportStatusesEnum>>;
}

export interface InsertFolderReportStatusesInsertInput {
  reports?: InputMaybe<InsertFolderReportsArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderReportStatusesObjRelInsertInput {
  data: InsertFolderReportStatusesInsertInput;
  on_conflict?: InputMaybe<InsertFolderReportStatusesOnConflict>;
}

export interface InsertFolderReportStatusesOnConflict {
  constraint: InsertFolderReportStatusesConstraint;
  update_columns: Array<InsertFolderReportStatusesUpdateColumn>;
  where?: InputMaybe<InsertFolderReportStatusesBoolExp>;
}

export enum InsertFolderReportStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertFolderReportTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertFolderReportTaskActivityAggregateBoolExpCount>;
}

export interface InsertFolderReportTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderReportTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportTaskActivityBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderReportTaskActivityArrRelInsertInput {
  data: Array<InsertFolderReportTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertFolderReportTaskActivityOnConflict>;
}

export interface InsertFolderReportTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertFolderReportTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report_task?: InputMaybe<InsertFolderReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderReportTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskActivityPkey = 'report_task_activity_pkey'
}

export interface InsertFolderReportTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertFolderReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderReportTaskActivityOnConflict {
  constraint: InsertFolderReportTaskActivityConstraint;
  update_columns: Array<InsertFolderReportTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertFolderReportTaskActivityBoolExp>;
}

export enum InsertFolderReportTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id'
}

export enum InsertFolderReportTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id'
}

export interface InsertFolderReportTaskCommentAggregateBoolExp {
  count?: InputMaybe<InsertFolderReportTaskCommentAggregateBoolExpCount>;
}

export interface InsertFolderReportTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderReportTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportTaskCommentBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderReportTaskCommentArrRelInsertInput {
  data: Array<InsertFolderReportTaskCommentInsertInput>;
  on_conflict?: InputMaybe<InsertFolderReportTaskCommentOnConflict>;
}

export interface InsertFolderReportTaskCommentBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportTaskCommentBoolExp>>;
  _not?: InputMaybe<InsertFolderReportTaskCommentBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportTaskCommentBoolExp>>;
  by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  comment?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report_task?: InputMaybe<InsertFolderReportTasksBoolExp>;
  report_task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderReportTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskCommentPkey = 'report_task_comment_pkey'
}

export interface InsertFolderReportTaskCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<InsertFolderReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderReportTaskCommentOnConflict {
  constraint: InsertFolderReportTaskCommentConstraint;
  update_columns: Array<InsertFolderReportTaskCommentUpdateColumn>;
  where?: InputMaybe<InsertFolderReportTaskCommentBoolExp>;
}

export enum InsertFolderReportTaskCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderReportTaskCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderReportTaskStatusesBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportTaskStatusesBoolExp>>;
  _not?: InputMaybe<InsertFolderReportTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportTaskStatusesBoolExp>>;
  report_tasks?: InputMaybe<InsertFolderReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertFolderReportTasksAggregateBoolExp>;
  status?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderReportTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportTaskStatusesPkey = 'report_task_statuses_pkey'
}

export enum InsertFolderReportTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export interface InsertFolderReportTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderReportTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertFolderReportTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderReportTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertFolderReportTaskStatusesEnum>>;
}

export interface InsertFolderReportTaskStatusesInsertInput {
  report_tasks?: InputMaybe<InsertFolderReportTasksArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderReportTaskStatusesObjRelInsertInput {
  data: InsertFolderReportTaskStatusesInsertInput;
  on_conflict?: InputMaybe<InsertFolderReportTaskStatusesOnConflict>;
}

export interface InsertFolderReportTaskStatusesOnConflict {
  constraint: InsertFolderReportTaskStatusesConstraint;
  update_columns: Array<InsertFolderReportTaskStatusesUpdateColumn>;
  where?: InputMaybe<InsertFolderReportTaskStatusesBoolExp>;
}

export enum InsertFolderReportTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface InsertFolderReportTasksAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderReportTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderReportTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderReportTasksAggregateBoolExpCount>;
}

export interface InsertFolderReportTasksAggregateBoolExpBoolAnd {
  arguments: InsertFolderReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportTasksBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderReportTasksAggregateBoolExpBoolOr {
  arguments: InsertFolderReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportTasksBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderReportTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderReportTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportTasksBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderReportTasksArrRelInsertInput {
  data: Array<InsertFolderReportTasksInsertInput>;
  on_conflict?: InputMaybe<InsertFolderReportTasksOnConflict>;
}

export interface InsertFolderReportTasksBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportTasksBoolExp>>;
  _not?: InputMaybe<InsertFolderReportTasksBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportTasksBoolExp>>;
  assignee_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  chapter_coordinator?: InputMaybe<InsertFolderUsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<InsertFolderUsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  completition_date?: InputMaybe<InsertFolderDateComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  final_answer_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  gnpie_member?: InputMaybe<InsertFolderUsersBoolExp>;
  gnpie_member_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<InsertFolderUsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  is_priority?: InputMaybe<InsertFolderBooleanComparisonExp>;
  negotiator?: InputMaybe<InsertFolderUsersBoolExp>;
  negotiator_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  question?: InputMaybe<InsertFolderQuestionsBoolExp>;
  question_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report?: InputMaybe<InsertFolderReportsBoolExp>;
  report_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  report_task_activities?: InputMaybe<InsertFolderReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<InsertFolderReportTaskActivityAggregateBoolExp>;
  report_task_answers?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  report_task_answers_aggregate?: InputMaybe<InsertFolderReportAnswersAggregateBoolExp>;
  report_task_comments?: InputMaybe<InsertFolderReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<InsertFolderReportTaskCommentAggregateBoolExp>;
  report_task_final_answer?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  report_task_status?: InputMaybe<InsertFolderReportTaskStatusesBoolExp>;
  status?: InputMaybe<InsertFolderReportTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  updated_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
}

export enum InsertFolderReportTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTasksPkey = 'report_tasks_pkey'
}

export interface InsertFolderReportTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  final_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_priority?: InputMaybe<Scalars['Boolean']['input']>;
  negotiator?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<InsertFolderQuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<InsertFolderReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_activities?: InputMaybe<InsertFolderReportTaskActivityArrRelInsertInput>;
  report_task_answers?: InputMaybe<InsertFolderReportAnswersArrRelInsertInput>;
  report_task_comments?: InputMaybe<InsertFolderReportTaskCommentArrRelInsertInput>;
  report_task_final_answer?: InputMaybe<InsertFolderReportAnswersObjRelInsertInput>;
  report_task_status?: InputMaybe<InsertFolderReportTaskStatusesObjRelInsertInput>;
  status?: InputMaybe<InsertFolderReportTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface InsertFolderReportTasksObjRelInsertInput {
  data: InsertFolderReportTasksInsertInput;
  on_conflict?: InputMaybe<InsertFolderReportTasksOnConflict>;
}

export interface InsertFolderReportTasksOnConflict {
  constraint: InsertFolderReportTasksConstraint;
  update_columns: Array<InsertFolderReportTasksUpdateColumn>;
  where?: InputMaybe<InsertFolderReportTasksBoolExp>;
}

export enum InsertFolderReportTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export enum InsertFolderReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

export enum InsertFolderReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

export enum InsertFolderReportTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface InsertFolderReportsAggregateBoolExp {
  count?: InputMaybe<InsertFolderReportsAggregateBoolExpCount>;
}

export interface InsertFolderReportsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderReportsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderReportsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderReportsArrRelInsertInput {
  data: Array<InsertFolderReportsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderReportsOnConflict>;
}

export interface InsertFolderReportsBoolExp {
  _and?: InputMaybe<Array<InsertFolderReportsBoolExp>>;
  _not?: InputMaybe<InsertFolderReportsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderReportsBoolExp>>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by?: InputMaybe<InsertFolderUuidComparisonExp>;
  deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  recommendation?: InputMaybe<InsertFolderRecommendationsBoolExp>;
  recommendations?: InputMaybe<InsertFolderRecommendationsBoolExp>;
  recommendations_aggregate?: InputMaybe<InsertFolderRecommendationsAggregateBoolExp>;
  report_answers?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertFolderReportAnswersAggregateBoolExp>;
  report_chapters?: InputMaybe<InsertFolderReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<InsertFolderReportChaptersAggregateBoolExp>;
  report_name?: InputMaybe<InsertFolderStringComparisonExp>;
  report_status?: InputMaybe<InsertFolderReportStatusesBoolExp>;
  report_tasks?: InputMaybe<InsertFolderReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<InsertFolderReportTasksAggregateBoolExp>;
  status?: InputMaybe<InsertFolderReportStatusesEnumComparisonExp>;
  total_priorities?: InputMaybe<InsertFolderBigintComparisonExp>;
  total_questions?: InputMaybe<InsertFolderIntComparisonExp>;
  total_tasks_done?: InputMaybe<InsertFolderBigintComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderReportsConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

export interface InsertFolderReportsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  recommendation?: InputMaybe<InsertFolderRecommendationsObjRelInsertInput>;
  recommendations?: InputMaybe<InsertFolderRecommendationsArrRelInsertInput>;
  report_answers?: InputMaybe<InsertFolderReportAnswersArrRelInsertInput>;
  report_chapters?: InputMaybe<InsertFolderReportChaptersArrRelInsertInput>;
  report_name?: InputMaybe<Scalars['String']['input']>;
  report_status?: InputMaybe<InsertFolderReportStatusesObjRelInsertInput>;
  report_tasks?: InputMaybe<InsertFolderReportTasksArrRelInsertInput>;
  status?: InputMaybe<InsertFolderReportStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderReportsObjRelInsertInput {
  data: InsertFolderReportsInsertInput;
  on_conflict?: InputMaybe<InsertFolderReportsOnConflict>;
}

export interface InsertFolderReportsOnConflict {
  constraint: InsertFolderReportsConstraint;
  update_columns: Array<InsertFolderReportsUpdateColumn>;
  where?: InputMaybe<InsertFolderReportsBoolExp>;
}

export enum InsertFolderReportsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderReportsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderStringComparisonExp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  _niregex?: InputMaybe<Scalars['String']['input']>;
  _nlike?: InputMaybe<Scalars['String']['input']>;
  _nregex?: InputMaybe<Scalars['String']['input']>;
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  _regex?: InputMaybe<Scalars['String']['input']>;
  _similar?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderSubChaptersAggregateBoolExp {
  count?: InputMaybe<InsertFolderSubChaptersAggregateBoolExpCount>;
}

export interface InsertFolderSubChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderSubChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderSubChaptersArrRelInsertInput {
  data: Array<InsertFolderSubChaptersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderSubChaptersOnConflict>;
}

export interface InsertFolderSubChaptersBoolExp {
  _and?: InputMaybe<Array<InsertFolderSubChaptersBoolExp>>;
  _not?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderSubChaptersBoolExp>>;
  acquis?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertFolderBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertFolderBookmarksAggregateBoolExp>;
  chapter?: InputMaybe<InsertFolderChaptersBoolExp>;
  chapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  gnpies?: InputMaybe<InsertFolderGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertFolderGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertFolderIntComparisonExp>;
  questions?: InputMaybe<InsertFolderQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<InsertFolderQuestionsAggregateBoolExp>;
  sub_chapter_number?: InputMaybe<InsertFolderStringComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
  topics?: InputMaybe<InsertFolderTopicsBoolExp>;
  topics_aggregate?: InputMaybe<InsertFolderTopicsAggregateBoolExp>;
}

export enum InsertFolderSubChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChaptersPkey = 'sub_chapters_pkey',
  /** unique or primary key constraint on columns "sub_chapter_number" */
  SubChaptersSubChapterNumberKey = 'sub_chapters_sub_chapter_number_key',
  /** unique or primary key constraint on columns "title" */
  SubChaptersTitleKey = 'sub_chapters_title_key'
}

export interface InsertFolderSubChaptersInsertInput {
  acquis?: InputMaybe<InsertFolderPkieAcquisArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertFolderBookmarksArrRelInsertInput>;
  chapter?: InputMaybe<InsertFolderChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<InsertFolderGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<InsertFolderQuestionsArrRelInsertInput>;
  sub_chapter_number?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<InsertFolderTopicsArrRelInsertInput>;
}

export interface InsertFolderSubChaptersObjRelInsertInput {
  data: InsertFolderSubChaptersInsertInput;
  on_conflict?: InputMaybe<InsertFolderSubChaptersOnConflict>;
}

export interface InsertFolderSubChaptersOnConflict {
  constraint: InsertFolderSubChaptersConstraint;
  update_columns: Array<InsertFolderSubChaptersUpdateColumn>;
  where?: InputMaybe<InsertFolderSubChaptersBoolExp>;
}

export enum InsertFolderSubChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title'
}

export enum InsertFolderSubChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title'
}

export interface InsertFolderTaskActivityAggregateBoolExp {
  count?: InputMaybe<InsertFolderTaskActivityAggregateBoolExpCount>;
}

export interface InsertFolderTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderTaskActivityBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderTaskActivityArrRelInsertInput {
  data: Array<InsertFolderTaskActivityInsertInput>;
  on_conflict?: InputMaybe<InsertFolderTaskActivityOnConflict>;
}

export interface InsertFolderTaskActivityBoolExp {
  _and?: InputMaybe<Array<InsertFolderTaskActivityBoolExp>>;
  _not?: InputMaybe<InsertFolderTaskActivityBoolExp>;
  _or?: InputMaybe<Array<InsertFolderTaskActivityBoolExp>>;
  activity?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  done_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  task?: InputMaybe<InsertFolderTaskBoolExp>;
  task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskActivityPkey = 'task_activity_pkey'
}

export interface InsertFolderTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertFolderTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderTaskActivityOnConflict {
  constraint: InsertFolderTaskActivityConstraint;
  update_columns: Array<InsertFolderTaskActivityUpdateColumn>;
  where?: InputMaybe<InsertFolderTaskActivityBoolExp>;
}

export enum InsertFolderTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderTaskAggregateBoolExp {
  count?: InputMaybe<InsertFolderTaskAggregateBoolExpCount>;
}

export interface InsertFolderTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderTaskBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderTaskAnswersAggregateBoolExp {
  count?: InputMaybe<InsertFolderTaskAnswersAggregateBoolExpCount>;
}

export interface InsertFolderTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderTaskAnswersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderTaskAnswersArrRelInsertInput {
  data: Array<InsertFolderTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderTaskAnswersOnConflict>;
}

export interface InsertFolderTaskAnswersBoolExp {
  _and?: InputMaybe<Array<InsertFolderTaskAnswersBoolExp>>;
  _not?: InputMaybe<InsertFolderTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderTaskAnswersBoolExp>>;
  answer?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  task?: InputMaybe<InsertFolderTaskBoolExp>;
  task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskAnswersPkey = 'task_answers_pkey'
}

export interface InsertFolderTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertFolderTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderTaskAnswersOnConflict {
  constraint: InsertFolderTaskAnswersConstraint;
  update_columns: Array<InsertFolderTaskAnswersUpdateColumn>;
  where?: InputMaybe<InsertFolderTaskAnswersBoolExp>;
}

export enum InsertFolderTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderTaskArrRelInsertInput {
  data: Array<InsertFolderTaskInsertInput>;
  on_conflict?: InputMaybe<InsertFolderTaskOnConflict>;
}

export interface InsertFolderTaskBoolExp {
  _and?: InputMaybe<Array<InsertFolderTaskBoolExp>>;
  _not?: InputMaybe<InsertFolderTaskBoolExp>;
  _or?: InputMaybe<Array<InsertFolderTaskBoolExp>>;
  assignee?: InputMaybe<InsertFolderUsersBoolExp>;
  assignee_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  completition_date?: InputMaybe<InsertFolderDateComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  deadline?: InputMaybe<InsertFolderDateComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  meeting?: InputMaybe<InsertFolderMeetingsBoolExp>;
  meeting_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  reporter?: InputMaybe<InsertFolderUsersBoolExp>;
  reporter_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  status?: InputMaybe<InsertFolderTaskStatusesEnumComparisonExp>;
  task_activities?: InputMaybe<InsertFolderTaskActivityBoolExp>;
  task_activities_aggregate?: InputMaybe<InsertFolderTaskActivityAggregateBoolExp>;
  task_answers?: InputMaybe<InsertFolderTaskAnswersBoolExp>;
  task_answers_aggregate?: InputMaybe<InsertFolderTaskAnswersAggregateBoolExp>;
  task_comments?: InputMaybe<InsertFolderTaskCommentsBoolExp>;
  task_comments_aggregate?: InputMaybe<InsertFolderTaskCommentsAggregateBoolExp>;
  task_type?: InputMaybe<InsertFolderTaskTypesEnumComparisonExp>;
  text?: InputMaybe<InsertFolderStringComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export interface InsertFolderTaskCommentsAggregateBoolExp {
  count?: InputMaybe<InsertFolderTaskCommentsAggregateBoolExpCount>;
}

export interface InsertFolderTaskCommentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderTaskCommentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderTaskCommentsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderTaskCommentsArrRelInsertInput {
  data: Array<InsertFolderTaskCommentsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderTaskCommentsOnConflict>;
}

export interface InsertFolderTaskCommentsBoolExp {
  _and?: InputMaybe<Array<InsertFolderTaskCommentsBoolExp>>;
  _not?: InputMaybe<InsertFolderTaskCommentsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderTaskCommentsBoolExp>>;
  comment?: InputMaybe<InsertFolderStringComparisonExp>;
  created_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  created_by_user?: InputMaybe<InsertFolderUsersBoolExp>;
  created_by_user_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  task?: InputMaybe<InsertFolderTaskBoolExp>;
  task_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  updated_at?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
}

export enum InsertFolderTaskCommentsConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskCommentsPkey = 'task_comments_pkey'
}

export interface InsertFolderTaskCommentsInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<InsertFolderTaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderTaskCommentsOnConflict {
  constraint: InsertFolderTaskCommentsConstraint;
  update_columns: Array<InsertFolderTaskCommentsUpdateColumn>;
  where?: InputMaybe<InsertFolderTaskCommentsBoolExp>;
}

export enum InsertFolderTaskCommentsSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderTaskCommentsUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey'
}

export interface InsertFolderTaskInsertInput {
  assignee?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<InsertFolderMeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter?: InputMaybe<InsertFolderUsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<InsertFolderTaskStatusesEnum>;
  task_activities?: InputMaybe<InsertFolderTaskActivityArrRelInsertInput>;
  task_answers?: InputMaybe<InsertFolderTaskAnswersArrRelInsertInput>;
  task_comments?: InputMaybe<InsertFolderTaskCommentsArrRelInsertInput>;
  task_type?: InputMaybe<InsertFolderTaskTypesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface InsertFolderTaskObjRelInsertInput {
  data: InsertFolderTaskInsertInput;
  on_conflict?: InputMaybe<InsertFolderTaskOnConflict>;
}

export interface InsertFolderTaskOnConflict {
  constraint: InsertFolderTaskConstraint;
  update_columns: Array<InsertFolderTaskUpdateColumn>;
  where?: InputMaybe<InsertFolderTaskBoolExp>;
}

export enum InsertFolderTaskSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export enum InsertFolderTaskStatusesEnum {
  Done = 'DONE',
  WaitingForAssignee = 'WAITING_FOR_ASSIGNEE',
  WaitingForReporter = 'WAITING_FOR_REPORTER'
}

export interface InsertFolderTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderTaskStatusesEnum>;
  _in?: InputMaybe<Array<InsertFolderTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderTaskStatusesEnum>;
  _nin?: InputMaybe<Array<InsertFolderTaskStatusesEnum>>;
}

export enum InsertFolderTaskTypesEnum {
  MeetingTask = 'MEETING_TASK'
}

export interface InsertFolderTaskTypesEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderTaskTypesEnum>;
  _in?: InputMaybe<Array<InsertFolderTaskTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderTaskTypesEnum>;
  _nin?: InputMaybe<Array<InsertFolderTaskTypesEnum>>;
}

export enum InsertFolderTaskUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InsertFolderTimestamptzComparisonExp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

export interface InsertFolderTopicsAggregateBoolExp {
  count?: InputMaybe<InsertFolderTopicsAggregateBoolExpCount>;
}

export interface InsertFolderTopicsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderTopicsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderTopicsBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderTopicsArrRelInsertInput {
  data: Array<InsertFolderTopicsInsertInput>;
  on_conflict?: InputMaybe<InsertFolderTopicsOnConflict>;
}

export interface InsertFolderTopicsBoolExp {
  _and?: InputMaybe<Array<InsertFolderTopicsBoolExp>>;
  _not?: InputMaybe<InsertFolderTopicsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderTopicsBoolExp>>;
  acquis?: InputMaybe<InsertFolderPkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<InsertFolderPkieAcquisAggregateBoolExp>;
  bookmarks?: InputMaybe<InsertFolderBookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<InsertFolderBookmarksAggregateBoolExp>;
  gnpies?: InputMaybe<InsertFolderGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertFolderGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertFolderIntComparisonExp>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersBoolExp>;
  subchapter_id?: InputMaybe<InsertFolderIntComparisonExp>;
  title?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderTopicsConstraint {
  /** unique or primary key constraint on columns "id" */
  TopicsNewIdKey = 'topics_new_id_key',
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

export interface InsertFolderTopicsInsertInput {
  acquis?: InputMaybe<InsertFolderPkieAcquisArrRelInsertInput>;
  bookmarks?: InputMaybe<InsertFolderBookmarksArrRelInsertInput>;
  gnpies?: InputMaybe<InsertFolderGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter?: InputMaybe<InsertFolderSubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderTopicsObjRelInsertInput {
  data: InsertFolderTopicsInsertInput;
  on_conflict?: InputMaybe<InsertFolderTopicsOnConflict>;
}

export interface InsertFolderTopicsOnConflict {
  constraint: InsertFolderTopicsConstraint;
  update_columns: Array<InsertFolderTopicsUpdateColumn>;
  where?: InputMaybe<InsertFolderTopicsBoolExp>;
}

export enum InsertFolderTopicsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title'
}

export enum InsertFolderTopicsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title'
}

export interface InsertFolderTypeInstitutionsBoolExp {
  _and?: InputMaybe<Array<InsertFolderTypeInstitutionsBoolExp>>;
  _not?: InputMaybe<InsertFolderTypeInstitutionsBoolExp>;
  _or?: InputMaybe<Array<InsertFolderTypeInstitutionsBoolExp>>;
  institutions?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  institutions_aggregate?: InputMaybe<InsertFolderInstitutionsAggregateBoolExp>;
  type?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderTypeInstitutionsConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeInstitutionsPkey = 'type_institutions_pkey'
}

export enum InsertFolderTypeInstitutionsEnum {
  Pavarur = 'PAVARUR',
  Qendror = 'QENDROR',
  Varesie = 'VARESIE'
}

export interface InsertFolderTypeInstitutionsEnumComparisonExp {
  _eq?: InputMaybe<InsertFolderTypeInstitutionsEnum>;
  _in?: InputMaybe<Array<InsertFolderTypeInstitutionsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<InsertFolderTypeInstitutionsEnum>;
  _nin?: InputMaybe<Array<InsertFolderTypeInstitutionsEnum>>;
}

export interface InsertFolderTypeInstitutionsInsertInput {
  institutions?: InputMaybe<InsertFolderInstitutionsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderTypeInstitutionsObjRelInsertInput {
  data: InsertFolderTypeInstitutionsInsertInput;
  on_conflict?: InputMaybe<InsertFolderTypeInstitutionsOnConflict>;
}

export interface InsertFolderTypeInstitutionsOnConflict {
  constraint: InsertFolderTypeInstitutionsConstraint;
  update_columns: Array<InsertFolderTypeInstitutionsUpdateColumn>;
  where?: InputMaybe<InsertFolderTypeInstitutionsBoolExp>;
}

export enum InsertFolderTypeInstitutionsUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface InsertFolderUsersAggregateBoolExp {
  bool_and?: InputMaybe<InsertFolderUsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InsertFolderUsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<InsertFolderUsersAggregateBoolExpCount>;
}

export interface InsertFolderUsersAggregateBoolExpBoolAnd {
  arguments: InsertFolderUsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderUsersBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderUsersAggregateBoolExpBoolOr {
  arguments: InsertFolderUsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderUsersBoolExp>;
  predicate: InsertFolderBooleanComparisonExp;
}

export interface InsertFolderUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InsertFolderUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertFolderUsersBoolExp>;
  predicate: InsertFolderIntComparisonExp;
}

export interface InsertFolderUsersArrRelInsertInput {
  data: Array<InsertFolderUsersInsertInput>;
  on_conflict?: InputMaybe<InsertFolderUsersOnConflict>;
}

export interface InsertFolderUsersBoolExp {
  _and?: InputMaybe<Array<InsertFolderUsersBoolExp>>;
  _not?: InputMaybe<InsertFolderUsersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderUsersBoolExp>>;
  activeMfaType?: InputMaybe<InsertFolderStringComparisonExp>;
  avatarUrl?: InputMaybe<InsertFolderStringComparisonExp>;
  createdAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  currentChallenge?: InputMaybe<InsertFolderStringComparisonExp>;
  defaultRole?: InputMaybe<InsertFolderAuthrolesEnumComparisonExp>;
  defaultRoleByRole?: InputMaybe<InsertFolderAuthrolesBoolExp>;
  disabled?: InputMaybe<InsertFolderBooleanComparisonExp>;
  displayName?: InputMaybe<InsertFolderStringComparisonExp>;
  dms_id?: InputMaybe<InsertFolderNumericComparisonExp>;
  email?: InputMaybe<InsertFolderCitextComparisonExp>;
  emailVerified?: InputMaybe<InsertFolderBooleanComparisonExp>;
  gnpies?: InputMaybe<InsertFolderGnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<InsertFolderGnpieAggregateBoolExp>;
  id?: InputMaybe<InsertFolderUuidComparisonExp>;
  institution?: InputMaybe<InsertFolderInstitutionsBoolExp>;
  institution_id?: InputMaybe<InsertFolderUuidComparisonExp>;
  isAnonymous?: InputMaybe<InsertFolderBooleanComparisonExp>;
  lastSeen?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  locale?: InputMaybe<InsertFolderStringComparisonExp>;
  measuresAssigned?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measuresAssigned_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  measuresToReport?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measuresToReport_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  meeting_users?: InputMaybe<InsertFolderMeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<InsertFolderMeetingUsersAggregateBoolExp>;
  meetings?: InputMaybe<InsertFolderMeetingsBoolExp>;
  meetings_aggregate?: InputMaybe<InsertFolderMeetingsAggregateBoolExp>;
  metadata?: InputMaybe<InsertFolderJsonbComparisonExp>;
  newEmail?: InputMaybe<InsertFolderCitextComparisonExp>;
  otpHash?: InputMaybe<InsertFolderStringComparisonExp>;
  otpHashExpiresAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  otpMethodLastUsed?: InputMaybe<InsertFolderStringComparisonExp>;
  passwordHash?: InputMaybe<InsertFolderStringComparisonExp>;
  phoneNumber?: InputMaybe<InsertFolderStringComparisonExp>;
  phoneNumberVerified?: InputMaybe<InsertFolderBooleanComparisonExp>;
  refreshTokens?: InputMaybe<InsertFolderAuthrefreshtokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<InsertFolderAuthrefreshtokensAggregateBoolExp>;
  report_answers?: InputMaybe<InsertFolderReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<InsertFolderReportAnswersAggregateBoolExp>;
  report_task_activities?: InputMaybe<InsertFolderReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<InsertFolderReportTaskActivityAggregateBoolExp>;
  report_task_comments?: InputMaybe<InsertFolderReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<InsertFolderReportTaskCommentAggregateBoolExp>;
  roles?: InputMaybe<InsertFolderAuthuserrolesBoolExp>;
  roles_aggregate?: InputMaybe<InsertFolderAuthuserrolesAggregateBoolExp>;
  securityKeys?: InputMaybe<InsertFolderAuthusersecuritykeysBoolExp>;
  securityKeys_aggregate?: InputMaybe<InsertFolderAuthusersecuritykeysAggregateBoolExp>;
  tasksAssigned?: InputMaybe<InsertFolderTaskBoolExp>;
  tasksAssigned_aggregate?: InputMaybe<InsertFolderTaskAggregateBoolExp>;
  tasksToReport?: InputMaybe<InsertFolderTaskBoolExp>;
  tasksToReport_aggregate?: InputMaybe<InsertFolderTaskAggregateBoolExp>;
  ticket?: InputMaybe<InsertFolderStringComparisonExp>;
  ticketExpiresAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  totpSecret?: InputMaybe<InsertFolderStringComparisonExp>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<InsertFolderCapacTrainingNeedsAggregateBoolExp>;
  training_participations?: InputMaybe<InsertFolderCapacTrainingParticipantsBoolExp>;
  training_participations_aggregate?: InputMaybe<InsertFolderCapacTrainingParticipantsAggregateBoolExp>;
  trainings?: InputMaybe<InsertFolderCapacTrainingsBoolExp>;
  trainings_aggregate?: InputMaybe<InsertFolderCapacTrainingsAggregateBoolExp>;
  updatedAt?: InputMaybe<InsertFolderTimestamptzComparisonExp>;
  userProviders?: InputMaybe<InsertFolderAuthuserprovidersBoolExp>;
  userProviders_aggregate?: InputMaybe<InsertFolderAuthuserprovidersAggregateBoolExp>;
  username?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderUsersConstraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

export interface InsertFolderUsersInsertInput {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<InsertFolderAuthrolesEnum>;
  defaultRoleByRole?: InputMaybe<InsertFolderAuthrolesObjRelInsertInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  gnpies?: InputMaybe<InsertFolderGnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InsertFolderInstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  measuresAssigned?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  measuresToReport?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  meeting_users?: InputMaybe<InsertFolderMeetingUsersArrRelInsertInput>;
  meetings?: InputMaybe<InsertFolderMeetingsArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<InsertFolderAuthrefreshtokensArrRelInsertInput>;
  report_answers?: InputMaybe<InsertFolderReportAnswersArrRelInsertInput>;
  report_task_activities?: InputMaybe<InsertFolderReportTaskActivityArrRelInsertInput>;
  report_task_comments?: InputMaybe<InsertFolderReportTaskCommentArrRelInsertInput>;
  roles?: InputMaybe<InsertFolderAuthuserrolesArrRelInsertInput>;
  securityKeys?: InputMaybe<InsertFolderAuthusersecuritykeysArrRelInsertInput>;
  tasksAssigned?: InputMaybe<InsertFolderTaskArrRelInsertInput>;
  tasksToReport?: InputMaybe<InsertFolderTaskArrRelInsertInput>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<InsertFolderCapacTrainingNeedsArrRelInsertInput>;
  training_participations?: InputMaybe<InsertFolderCapacTrainingParticipantsArrRelInsertInput>;
  trainings?: InputMaybe<InsertFolderCapacTrainingsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<InsertFolderAuthuserprovidersArrRelInsertInput>;
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderUsersObjRelInsertInput {
  data: InsertFolderUsersInsertInput;
  on_conflict?: InputMaybe<InsertFolderUsersOnConflict>;
}

export interface InsertFolderUsersOnConflict {
  constraint: InsertFolderUsersConstraint;
  update_columns: Array<InsertFolderUsersUpdateColumn>;
  where?: InputMaybe<InsertFolderUsersBoolExp>;
}

export enum InsertFolderUsersSelectColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export enum InsertFolderUsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

export enum InsertFolderUsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

export enum InsertFolderUsersUpdateColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export interface InsertFolderUuidArrayComparisonExp {
  _contained_in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _contains?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _eq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _lte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _neq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
}

export interface InsertFolderUuidComparisonExp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

export interface InsertFolderYearlyQuartersBoolExp {
  _and?: InputMaybe<Array<InsertFolderYearlyQuartersBoolExp>>;
  _not?: InputMaybe<InsertFolderYearlyQuartersBoolExp>;
  _or?: InputMaybe<Array<InsertFolderYearlyQuartersBoolExp>>;
  bookmark_tasks?: InputMaybe<InsertFolderBookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<InsertFolderBookmarkTasksAggregateBoolExp>;
  measures?: InputMaybe<InsertFolderPkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<InsertFolderPkieMeasureAggregateBoolExp>;
  quarter?: InputMaybe<InsertFolderStringComparisonExp>;
}

export enum InsertFolderYearlyQuartersConstraint {
  /** unique or primary key constraint on columns "quarter" */
  YearlyQuartersPkey = 'yearly_quarters_pkey'
}

export interface InsertFolderYearlyQuartersInsertInput {
  bookmark_tasks?: InputMaybe<InsertFolderBookmarkTasksArrRelInsertInput>;
  measures?: InputMaybe<InsertFolderPkieMeasureArrRelInsertInput>;
  quarter?: InputMaybe<Scalars['String']['input']>;
}

export interface InsertFolderYearlyQuartersObjRelInsertInput {
  data: InsertFolderYearlyQuartersInsertInput;
  on_conflict?: InputMaybe<InsertFolderYearlyQuartersOnConflict>;
}

export interface InsertFolderYearlyQuartersOnConflict {
  constraint: InsertFolderYearlyQuartersConstraint;
  update_columns: Array<InsertFolderYearlyQuartersUpdateColumn>;
  where?: InputMaybe<InsertFolderYearlyQuartersBoolExp>;
}

export enum InsertFolderYearlyQuartersUpdateColumn {
  /** column name */
  Quarter = 'quarter'
}

export interface InsertProgressReportOutput {
  __typename?: 'InsertProgressReportOutput';
  id: Scalars['uuid']['output'];
}

export interface InsertProgressReportReportsInsertInput {
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  report_name?: InputMaybe<Scalars['String']['input']>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IntComparisonExp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface StringArrayComparisonExp {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateDocumentByPkDocumentsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

export interface UpdateDocumentByPkDocumentsSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_type?: InputMaybe<UpdateDocumentByPkTaskTypesEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UpdateDocumentByPkTaskStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

export interface UpdateDocumentByPkOutput {
  __typename?: 'UpdateDocumentByPkOutput';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id: Scalars['uuid']['output'];
  deadline: Scalars['date']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UpdateDocumentByPkTaskStatusesEnum>;
  title: Scalars['String']['output'];
}

export enum UpdateDocumentByPkTaskStatusesEnum {
  Canceled = 'CANCELED',
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING',
  WaitingForAssignee = 'WAITING_FOR_ASSIGNEE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForReporter = 'WAITING_FOR_REPORTER',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

export enum UpdateDocumentByPkTaskTypesEnum {
  Integration = 'INTEGRATION',
  MeetingTask = 'MEETING_TASK'
}

export interface UpdateFolderDmsFolderPkColumnsInput {
  id: Scalars['uuid']['input'];
}

export interface UpdateFolderDmsFolderSetInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  feature?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

export interface UpdateFolderOutput {
  __typename?: 'UpdateFolderOutput';
  change?: Maybe<Scalars['jsonb']['output']>;
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  children: Array<DmsFolder>;
  /** An aggregate relationship */
  children_aggregate: DmsFolderAggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Users>;
  parent?: Maybe<DmsFolder>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  path: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  view?: Maybe<Scalars['jsonb']['output']>;
}


export interface UpdateFolderOutputChildrenArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface UpdateFolderOutputChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}

export interface UpdateReportChaptersByPkOutput {
  __typename?: 'UpdateReportChaptersByPKOutput';
  chapter_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

export interface UpdateReportChaptersByPkReportChaptersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

export interface UpdateReportChaptersByPkReportChaptersSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthProviderRequests {
  __typename?: 'authProviderRequests';
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
}


/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthProviderRequestsOptionsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "auth.provider_requests" */
export interface AuthProviderRequestsAggregate {
  __typename?: 'authProviderRequests_aggregate';
  aggregate?: Maybe<AuthProviderRequestsAggregateFields>;
  nodes: Array<AuthProviderRequests>;
}

/** aggregate fields of "auth.provider_requests" */
export interface AuthProviderRequestsAggregateFields {
  __typename?: 'authProviderRequests_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviderRequestsMaxFields>;
  min?: Maybe<AuthProviderRequestsMinFields>;
}


/** aggregate fields of "auth.provider_requests" */
export interface AuthProviderRequestsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface AuthProviderRequestsAppendInput {
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
export interface AuthProviderRequestsBoolExp {
  _and?: InputMaybe<Array<AuthProviderRequestsBoolExp>>;
  _not?: InputMaybe<AuthProviderRequestsBoolExp>;
  _or?: InputMaybe<Array<AuthProviderRequestsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  options?: InputMaybe<JsonbComparisonExp>;
}

/** unique or primary key constraints on table "auth.provider_requests" */
export enum AuthProviderRequestsConstraint {
  /** unique or primary key constraint on columns "id" */
  ProviderRequestsPkey = 'provider_requests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface AuthProviderRequestsDeleteAtPathInput {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface AuthProviderRequestsDeleteElemInput {
  options?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface AuthProviderRequestsDeleteKeyInput {
  options?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "auth.provider_requests" */
export interface AuthProviderRequestsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate max on columns */
export interface AuthProviderRequestsMaxFields {
  __typename?: 'authProviderRequests_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregate min on columns */
export interface AuthProviderRequestsMinFields {
  __typename?: 'authProviderRequests_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
}

/** response of any mutation on the table "auth.provider_requests" */
export interface AuthProviderRequestsMutationResponse {
  __typename?: 'authProviderRequests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviderRequests>;
}

/** on_conflict condition type for table "auth.provider_requests" */
export interface AuthProviderRequestsOnConflict {
  constraint: AuthProviderRequestsConstraint;
  update_columns?: Array<AuthProviderRequestsUpdateColumn>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
}

/** Ordering options when selecting data from "auth.provider_requests". */
export interface AuthProviderRequestsOrderBy {
  id?: InputMaybe<OrderBy>;
  options?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.provider_requests */
export interface AuthProviderRequestsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface AuthProviderRequestsPrependInput {
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.provider_requests" */
export enum AuthProviderRequestsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

/** input type for updating data in table "auth.provider_requests" */
export interface AuthProviderRequestsSetInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** Streaming cursor of the table "authProviderRequests" */
export interface AuthProviderRequestsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthProviderRequestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthProviderRequestsStreamCursorValueInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** update columns of table "auth.provider_requests" */
export enum AuthProviderRequestsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

export interface AuthProviderRequestsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthProviderRequestsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthProviderRequestsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthProviderRequestsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthProviderRequestsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthProviderRequestsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviderRequestsSetInput>;
  /** filter the rows which have to be updated */
  where: AuthProviderRequestsBoolExp;
}

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthProviders {
  __typename?: 'authProviders';
  id: Scalars['String']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProvidersAggregate;
}


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthProvidersUserProvidersArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthProvidersUserProvidersAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}

/** aggregated selection of "auth.providers" */
export interface AuthProvidersAggregate {
  __typename?: 'authProviders_aggregate';
  aggregate?: Maybe<AuthProvidersAggregateFields>;
  nodes: Array<AuthProviders>;
}

/** aggregate fields of "auth.providers" */
export interface AuthProvidersAggregateFields {
  __typename?: 'authProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProvidersMaxFields>;
  min?: Maybe<AuthProvidersMinFields>;
}


/** aggregate fields of "auth.providers" */
export interface AuthProvidersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export interface AuthProvidersBoolExp {
  _and?: InputMaybe<Array<AuthProvidersBoolExp>>;
  _not?: InputMaybe<AuthProvidersBoolExp>;
  _or?: InputMaybe<Array<AuthProvidersBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  userProviders?: InputMaybe<AuthUserProvidersBoolExp>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateBoolExp>;
}

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProvidersConstraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export interface AuthProvidersInsertInput {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<AuthUserProvidersArrRelInsertInput>;
}

/** aggregate max on columns */
export interface AuthProvidersMaxFields {
  __typename?: 'authProviders_max_fields';
  id?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface AuthProvidersMinFields {
  __typename?: 'authProviders_min_fields';
  id?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "auth.providers" */
export interface AuthProvidersMutationResponse {
  __typename?: 'authProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviders>;
}

/** input type for inserting object relation for remote table "auth.providers" */
export interface AuthProvidersObjRelInsertInput {
  data: AuthProvidersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProvidersOnConflict>;
}

/** on_conflict condition type for table "auth.providers" */
export interface AuthProvidersOnConflict {
  constraint: AuthProvidersConstraint;
  update_columns?: Array<AuthProvidersUpdateColumn>;
  where?: InputMaybe<AuthProvidersBoolExp>;
}

/** Ordering options when selecting data from "auth.providers". */
export interface AuthProvidersOrderBy {
  id?: InputMaybe<OrderBy>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateOrderBy>;
}

/** primary key columns input for table: auth.providers */
export interface AuthProvidersPkColumnsInput {
  id: Scalars['String']['input'];
}

/** select columns of table "auth.providers" */
export enum AuthProvidersSelectColumn {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.providers" */
export interface AuthProvidersSetInput {
  id?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "authProviders" */
export interface AuthProvidersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthProvidersStreamCursorValueInput {
  id?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.providers" */
export enum AuthProvidersUpdateColumn {
  /** column name */
  Id = 'id'
}

export interface AuthProvidersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: AuthProvidersBoolExp;
}

/** columns and relationships of "auth.refresh_token_types" */
export interface AuthRefreshTokenTypes {
  __typename?: 'authRefreshTokenTypes';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokensAggregate;
  value: Scalars['String']['output'];
}


/** columns and relationships of "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


/** columns and relationships of "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesRefreshTokensAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}

/** aggregated selection of "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesAggregate {
  __typename?: 'authRefreshTokenTypes_aggregate';
  aggregate?: Maybe<AuthRefreshTokenTypesAggregateFields>;
  nodes: Array<AuthRefreshTokenTypes>;
}

/** aggregate fields of "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesAggregateFields {
  __typename?: 'authRefreshTokenTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokenTypesMaxFields>;
  min?: Maybe<AuthRefreshTokenTypesMinFields>;
}


/** aggregate fields of "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
export interface AuthRefreshTokenTypesBoolExp {
  _and?: InputMaybe<Array<AuthRefreshTokenTypesBoolExp>>;
  _not?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
  _or?: InputMaybe<Array<AuthRefreshTokenTypesBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  refreshTokens?: InputMaybe<AuthRefreshTokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypesConstraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey'
}

export enum AuthRefreshTokenTypesEnum {
  /** Entra ID refresh token */
  Entraid = 'entraid',
  /** NAIS B2C refresh token */
  Naisb2c = 'naisb2c',
  /** OIDC refresh token */
  Oidc = 'oidc',
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular'
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export interface AuthRefreshTokenTypesEnumComparisonExp {
  _eq?: InputMaybe<AuthRefreshTokenTypesEnum>;
  _in?: InputMaybe<Array<AuthRefreshTokenTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRefreshTokenTypesEnum>;
  _nin?: InputMaybe<Array<AuthRefreshTokenTypesEnum>>;
}

/** input type for inserting data into table "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokensArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface AuthRefreshTokenTypesMaxFields {
  __typename?: 'authRefreshTokenTypes_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface AuthRefreshTokenTypesMinFields {
  __typename?: 'authRefreshTokenTypes_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesMutationResponse {
  __typename?: 'authRefreshTokenTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokenTypes>;
}

/** input type for inserting object relation for remote table "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesObjRelInsertInput {
  data: AuthRefreshTokenTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokenTypesOnConflict>;
}

/** on_conflict condition type for table "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesOnConflict {
  constraint: AuthRefreshTokenTypesConstraint;
  update_columns?: Array<AuthRefreshTokenTypesUpdateColumn>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
}

/** Ordering options when selecting data from "auth.refresh_token_types". */
export interface AuthRefreshTokenTypesOrderBy {
  comment?: InputMaybe<OrderBy>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.refresh_token_types */
export interface AuthRefreshTokenTypesPkColumnsInput {
  value: Scalars['String']['input'];
}

/** select columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypesSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth.refresh_token_types" */
export interface AuthRefreshTokenTypesSetInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "authRefreshTokenTypes" */
export interface AuthRefreshTokenTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokenTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthRefreshTokenTypesStreamCursorValueInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypesUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export interface AuthRefreshTokenTypesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokenTypesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokenTypesBoolExp;
}

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRefreshTokens {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  refresh_token_type: AuthRefreshTokenTypes;
  type: AuthRefreshTokenTypesEnum;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
}


/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRefreshTokensMetadataArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "auth.refresh_tokens" */
export interface AuthRefreshTokensAggregate {
  __typename?: 'authRefreshTokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokensAggregateFields>;
  nodes: Array<AuthRefreshTokens>;
}

export interface AuthRefreshTokensAggregateBoolExp {
  count?: InputMaybe<AuthRefreshTokensAggregateBoolExpCount>;
}

export interface AuthRefreshTokensAggregateBoolExpCount {
  arguments?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthRefreshTokensBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "auth.refresh_tokens" */
export interface AuthRefreshTokensAggregateFields {
  __typename?: 'authRefreshTokens_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokensMaxFields>;
  min?: Maybe<AuthRefreshTokensMinFields>;
}


/** aggregate fields of "auth.refresh_tokens" */
export interface AuthRefreshTokensAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "auth.refresh_tokens" */
export interface AuthRefreshTokensAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthRefreshTokensMaxOrderBy>;
  min?: InputMaybe<AuthRefreshTokensMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface AuthRefreshTokensAppendInput {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export interface AuthRefreshTokensArrRelInsertInput {
  data: Array<AuthRefreshTokensInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokensOnConflict>;
}

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export interface AuthRefreshTokensBoolExp {
  _and?: InputMaybe<Array<AuthRefreshTokensBoolExp>>;
  _not?: InputMaybe<AuthRefreshTokensBoolExp>;
  _or?: InputMaybe<Array<AuthRefreshTokensBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  refreshTokenHash?: InputMaybe<StringComparisonExp>;
  refresh_token_type?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
  type?: InputMaybe<AuthRefreshTokenTypesEnumComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokensConstraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface AuthRefreshTokensDeleteAtPathInput {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface AuthRefreshTokensDeleteElemInput {
  metadata?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface AuthRefreshTokensDeleteKeyInput {
  metadata?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "auth.refresh_tokens" */
export interface AuthRefreshTokensInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  refresh_token_type?: InputMaybe<AuthRefreshTokenTypesObjRelInsertInput>;
  type?: InputMaybe<AuthRefreshTokenTypesEnum>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface AuthRefreshTokensMaxFields {
  __typename?: 'authRefreshTokens_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "auth.refresh_tokens" */
export interface AuthRefreshTokensMaxOrderBy {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  refreshTokenHash?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface AuthRefreshTokensMinFields {
  __typename?: 'authRefreshTokens_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "auth.refresh_tokens" */
export interface AuthRefreshTokensMinOrderBy {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  refreshTokenHash?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "auth.refresh_tokens" */
export interface AuthRefreshTokensMutationResponse {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
}

/** on_conflict condition type for table "auth.refresh_tokens" */
export interface AuthRefreshTokensOnConflict {
  constraint: AuthRefreshTokensConstraint;
  update_columns?: Array<AuthRefreshTokensUpdateColumn>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}

/** Ordering options when selecting data from "auth.refresh_tokens". */
export interface AuthRefreshTokensOrderBy {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  refreshTokenHash?: InputMaybe<OrderBy>;
  refresh_token_type?: InputMaybe<AuthRefreshTokenTypesOrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.refresh_tokens */
export interface AuthRefreshTokensPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface AuthRefreshTokensPrependInput {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.refresh_tokens" */
export interface AuthRefreshTokensSetInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "authRefreshTokens" */
export interface AuthRefreshTokensStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthRefreshTokensStreamCursorValueInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export interface AuthRefreshTokensUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthRefreshTokensAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthRefreshTokensDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthRefreshTokensDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthRefreshTokensDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthRefreshTokensPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokensSetInput>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokensBoolExp;
}

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRoles {
  __typename?: 'authRoles';
  role: Scalars['String']['output'];
  /** An array relationship */
  userRoles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  userRoles_aggregate: AuthUserRolesAggregate;
  /** An array relationship */
  usersByDefaultRole: Array<Users>;
  /** An aggregate relationship */
  usersByDefaultRole_aggregate: UsersAggregate;
}


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRolesUserRolesArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRolesUserRolesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRolesUsersByDefaultRoleArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthRolesUsersByDefaultRoleAggregateArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}

/** aggregated selection of "auth.roles" */
export interface AuthRolesAggregate {
  __typename?: 'authRoles_aggregate';
  aggregate?: Maybe<AuthRolesAggregateFields>;
  nodes: Array<AuthRoles>;
}

/** aggregate fields of "auth.roles" */
export interface AuthRolesAggregateFields {
  __typename?: 'authRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRolesMaxFields>;
  min?: Maybe<AuthRolesMinFields>;
}


/** aggregate fields of "auth.roles" */
export interface AuthRolesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export interface AuthRolesBoolExp {
  _and?: InputMaybe<Array<AuthRolesBoolExp>>;
  _not?: InputMaybe<AuthRolesBoolExp>;
  _or?: InputMaybe<Array<AuthRolesBoolExp>>;
  role?: InputMaybe<StringComparisonExp>;
  userRoles?: InputMaybe<AuthUserRolesBoolExp>;
  userRoles_aggregate?: InputMaybe<AuthUserRolesAggregateBoolExp>;
  usersByDefaultRole?: InputMaybe<UsersBoolExp>;
  usersByDefaultRole_aggregate?: InputMaybe<UsersAggregateBoolExp>;
}

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRolesConstraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

export enum AuthRolesEnum {
  ChapterCommunication = 'CHAPTER_COMMUNICATION',
  ChapterCoordinator = 'CHAPTER_COORDINATOR',
  ChapterDefined = 'CHAPTER_DEFINED',
  ChapterKnowledge = 'CHAPTER_KNOWLEDGE',
  ChapterMonitoring = 'CHAPTER_MONITORING',
  ChiefGnpieSecretariat = 'CHIEF_GNPIE_SECRETARIAT',
  ClusterCoordinator = 'CLUSTER_COORDINATOR',
  GnpieMember = 'GNPIE_MEMBER',
  GnpieSecretariat = 'GNPIE_SECRETARIAT',
  HorizontalCoordinator = 'HORIZONTAL_COORDINATOR',
  MainNegotiator = 'MAIN_NEGOTIATOR',
  Member = 'MEMBER',
  Negotiator = 'NEGOTIATOR',
  PmoSecretariat = 'PMO_SECRETARIAT',
  Subnegotiator = 'SUBNEGOTIATOR',
  UserManager = 'USER_MANAGER',
  Anonymous = 'anonymous',
  Me = 'me',
  User = 'user'
}

/** Boolean expression to compare columns of type "authRoles_enum". All fields are combined with logical 'AND'. */
export interface AuthRolesEnumComparisonExp {
  _eq?: InputMaybe<AuthRolesEnum>;
  _in?: InputMaybe<Array<AuthRolesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRolesEnum>;
  _nin?: InputMaybe<Array<AuthRolesEnum>>;
}

/** input type for inserting data into table "auth.roles" */
export interface AuthRolesInsertInput {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<AuthUserRolesArrRelInsertInput>;
  usersByDefaultRole?: InputMaybe<UsersArrRelInsertInput>;
}

/** aggregate max on columns */
export interface AuthRolesMaxFields {
  __typename?: 'authRoles_max_fields';
  role?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface AuthRolesMinFields {
  __typename?: 'authRoles_min_fields';
  role?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "auth.roles" */
export interface AuthRolesMutationResponse {
  __typename?: 'authRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRoles>;
}

/** input type for inserting object relation for remote table "auth.roles" */
export interface AuthRolesObjRelInsertInput {
  data: AuthRolesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRolesOnConflict>;
}

/** on_conflict condition type for table "auth.roles" */
export interface AuthRolesOnConflict {
  constraint: AuthRolesConstraint;
  update_columns?: Array<AuthRolesUpdateColumn>;
  where?: InputMaybe<AuthRolesBoolExp>;
}

/** Ordering options when selecting data from "auth.roles". */
export interface AuthRolesOrderBy {
  role?: InputMaybe<OrderBy>;
  userRoles_aggregate?: InputMaybe<AuthUserRolesAggregateOrderBy>;
  usersByDefaultRole_aggregate?: InputMaybe<UsersAggregateOrderBy>;
}

/** primary key columns input for table: auth.roles */
export interface AuthRolesPkColumnsInput {
  role: Scalars['String']['input'];
}

/** select columns of table "auth.roles" */
export enum AuthRolesSelectColumn {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export interface AuthRolesSetInput {
  role?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "authRoles" */
export interface AuthRolesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthRolesStreamCursorValueInput {
  role?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.roles" */
export enum AuthRolesUpdateColumn {
  /** column name */
  Role = 'role'
}

export interface AuthRolesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRolesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthRolesBoolExp;
}

/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthUserProviders {
  __typename?: 'authUserProviders';
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  provider: AuthProviders;
  providerId: Scalars['String']['output'];
  providerUserId: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
}

/** aggregated selection of "auth.user_providers" */
export interface AuthUserProvidersAggregate {
  __typename?: 'authUserProviders_aggregate';
  aggregate?: Maybe<AuthUserProvidersAggregateFields>;
  nodes: Array<AuthUserProviders>;
}

export interface AuthUserProvidersAggregateBoolExp {
  count?: InputMaybe<AuthUserProvidersAggregateBoolExpCount>;
}

export interface AuthUserProvidersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserProvidersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "auth.user_providers" */
export interface AuthUserProvidersAggregateFields {
  __typename?: 'authUserProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserProvidersMaxFields>;
  min?: Maybe<AuthUserProvidersMinFields>;
}


/** aggregate fields of "auth.user_providers" */
export interface AuthUserProvidersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "auth.user_providers" */
export interface AuthUserProvidersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthUserProvidersMaxOrderBy>;
  min?: InputMaybe<AuthUserProvidersMinOrderBy>;
}

/** input type for inserting array relation for remote table "auth.user_providers" */
export interface AuthUserProvidersArrRelInsertInput {
  data: Array<AuthUserProvidersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserProvidersOnConflict>;
}

/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
export interface AuthUserProvidersBoolExp {
  _and?: InputMaybe<Array<AuthUserProvidersBoolExp>>;
  _not?: InputMaybe<AuthUserProvidersBoolExp>;
  _or?: InputMaybe<Array<AuthUserProvidersBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  provider?: InputMaybe<AuthProvidersBoolExp>;
  providerId?: InputMaybe<StringComparisonExp>;
  providerUserId?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "auth.user_providers" */
export enum AuthUserProvidersConstraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key'
}

/** input type for inserting data into table "auth.user_providers" */
export interface AuthUserProvidersInsertInput {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<AuthProvidersObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface AuthUserProvidersMaxFields {
  __typename?: 'authUserProviders_max_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "auth.user_providers" */
export interface AuthUserProvidersMaxOrderBy {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerUserId?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface AuthUserProvidersMinFields {
  __typename?: 'authUserProviders_min_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "auth.user_providers" */
export interface AuthUserProvidersMinOrderBy {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerUserId?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "auth.user_providers" */
export interface AuthUserProvidersMutationResponse {
  __typename?: 'authUserProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserProviders>;
}

/** on_conflict condition type for table "auth.user_providers" */
export interface AuthUserProvidersOnConflict {
  constraint: AuthUserProvidersConstraint;
  update_columns?: Array<AuthUserProvidersUpdateColumn>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}

/** Ordering options when selecting data from "auth.user_providers". */
export interface AuthUserProvidersOrderBy {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  provider?: InputMaybe<AuthProvidersOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerUserId?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.user_providers */
export interface AuthUserProvidersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.user_providers" */
export enum AuthUserProvidersSelectColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_providers" */
export interface AuthUserProvidersSetInput {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "authUserProviders" */
export interface AuthUserProvidersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthUserProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthUserProvidersStreamCursorValueInput {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.user_providers" */
export enum AuthUserProvidersUpdateColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export interface AuthUserProvidersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserProvidersBoolExp;
}

/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthUserRoles {
  __typename?: 'authUserRoles';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  modules?: Maybe<Array<Scalars['String']['output']>>;
  /** A computed field, executes function "array_to_string" */
  modules_string?: Maybe<Scalars['String']['output']>;
  role: AuthRolesEnum;
  /** An object relationship */
  roleByRole: AuthRoles;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
}

/** aggregated selection of "auth.user_roles" */
export interface AuthUserRolesAggregate {
  __typename?: 'authUserRoles_aggregate';
  aggregate?: Maybe<AuthUserRolesAggregateFields>;
  nodes: Array<AuthUserRoles>;
}

export interface AuthUserRolesAggregateBoolExp {
  count?: InputMaybe<AuthUserRolesAggregateBoolExpCount>;
}

export interface AuthUserRolesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserRolesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "auth.user_roles" */
export interface AuthUserRolesAggregateFields {
  __typename?: 'authUserRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserRolesMaxFields>;
  min?: Maybe<AuthUserRolesMinFields>;
}


/** aggregate fields of "auth.user_roles" */
export interface AuthUserRolesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "auth.user_roles" */
export interface AuthUserRolesAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthUserRolesMaxOrderBy>;
  min?: InputMaybe<AuthUserRolesMinOrderBy>;
}

/** input type for inserting array relation for remote table "auth.user_roles" */
export interface AuthUserRolesArrRelInsertInput {
  data: Array<AuthUserRolesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserRolesOnConflict>;
}

/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
export interface AuthUserRolesBoolExp {
  _and?: InputMaybe<Array<AuthUserRolesBoolExp>>;
  _not?: InputMaybe<AuthUserRolesBoolExp>;
  _or?: InputMaybe<Array<AuthUserRolesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  modules?: InputMaybe<StringArrayComparisonExp>;
  modules_string?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<AuthRolesEnumComparisonExp>;
  roleByRole?: InputMaybe<AuthRolesBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "auth.user_roles" */
export enum AuthUserRolesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key'
}

/** input type for inserting data into table "auth.user_roles" */
export interface AuthUserRolesInsertInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<AuthRolesEnum>;
  roleByRole?: InputMaybe<AuthRolesObjRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface AuthUserRolesMaxFields {
  __typename?: 'authUserRoles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modules?: Maybe<Array<Scalars['String']['output']>>;
  /** A computed field, executes function "array_to_string" */
  modules_string?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "auth.user_roles" */
export interface AuthUserRolesMaxOrderBy {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modules?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface AuthUserRolesMinFields {
  __typename?: 'authUserRoles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modules?: Maybe<Array<Scalars['String']['output']>>;
  /** A computed field, executes function "array_to_string" */
  modules_string?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "auth.user_roles" */
export interface AuthUserRolesMinOrderBy {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modules?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "auth.user_roles" */
export interface AuthUserRolesMutationResponse {
  __typename?: 'authUserRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserRoles>;
}

/** on_conflict condition type for table "auth.user_roles" */
export interface AuthUserRolesOnConflict {
  constraint: AuthUserRolesConstraint;
  update_columns?: Array<AuthUserRolesUpdateColumn>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}

/** Ordering options when selecting data from "auth.user_roles". */
export interface AuthUserRolesOrderBy {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modules?: InputMaybe<OrderBy>;
  modules_string?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  roleByRole?: InputMaybe<AuthRolesOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.user_roles */
export interface AuthUserRolesPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.user_roles" */
export enum AuthUserRolesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Modules = 'modules',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_roles" */
export interface AuthUserRolesSetInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<AuthRolesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "authUserRoles" */
export interface AuthUserRolesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthUserRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthUserRolesStreamCursorValueInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<AuthRolesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "auth.user_roles" */
export enum AuthUserRolesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Modules = 'modules',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export interface AuthUserRolesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserRolesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserRolesBoolExp;
}

/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface AuthUserSecurityKeys {
  __typename?: 'authUserSecurityKeys';
  counter: Scalars['bigint']['output'];
  credentialId: Scalars['String']['output'];
  credentialPublicKey?: Maybe<Scalars['bytea']['output']>;
  id: Scalars['uuid']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  transports: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
}

/** aggregated selection of "auth.user_security_keys" */
export interface AuthUserSecurityKeysAggregate {
  __typename?: 'authUserSecurityKeys_aggregate';
  aggregate?: Maybe<AuthUserSecurityKeysAggregateFields>;
  nodes: Array<AuthUserSecurityKeys>;
}

export interface AuthUserSecurityKeysAggregateBoolExp {
  count?: InputMaybe<AuthUserSecurityKeysAggregateBoolExpCount>;
}

export interface AuthUserSecurityKeysAggregateBoolExpCount {
  arguments?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserSecurityKeysBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "auth.user_security_keys" */
export interface AuthUserSecurityKeysAggregateFields {
  __typename?: 'authUserSecurityKeys_aggregate_fields';
  avg?: Maybe<AuthUserSecurityKeysAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserSecurityKeysMaxFields>;
  min?: Maybe<AuthUserSecurityKeysMinFields>;
  stddev?: Maybe<AuthUserSecurityKeysStddevFields>;
  stddev_pop?: Maybe<AuthUserSecurityKeysStddevPopFields>;
  stddev_samp?: Maybe<AuthUserSecurityKeysStddevSampFields>;
  sum?: Maybe<AuthUserSecurityKeysSumFields>;
  var_pop?: Maybe<AuthUserSecurityKeysVarPopFields>;
  var_samp?: Maybe<AuthUserSecurityKeysVarSampFields>;
  variance?: Maybe<AuthUserSecurityKeysVarianceFields>;
}


/** aggregate fields of "auth.user_security_keys" */
export interface AuthUserSecurityKeysAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysAggregateOrderBy {
  avg?: InputMaybe<AuthUserSecurityKeysAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthUserSecurityKeysMaxOrderBy>;
  min?: InputMaybe<AuthUserSecurityKeysMinOrderBy>;
  stddev?: InputMaybe<AuthUserSecurityKeysStddevOrderBy>;
  stddev_pop?: InputMaybe<AuthUserSecurityKeysStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AuthUserSecurityKeysStddevSampOrderBy>;
  sum?: InputMaybe<AuthUserSecurityKeysSumOrderBy>;
  var_pop?: InputMaybe<AuthUserSecurityKeysVarPopOrderBy>;
  var_samp?: InputMaybe<AuthUserSecurityKeysVarSampOrderBy>;
  variance?: InputMaybe<AuthUserSecurityKeysVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "auth.user_security_keys" */
export interface AuthUserSecurityKeysArrRelInsertInput {
  data: Array<AuthUserSecurityKeysInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserSecurityKeysOnConflict>;
}

/** aggregate avg on columns */
export interface AuthUserSecurityKeysAvgFields {
  __typename?: 'authUserSecurityKeys_avg_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysAvgOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
export interface AuthUserSecurityKeysBoolExp {
  _and?: InputMaybe<Array<AuthUserSecurityKeysBoolExp>>;
  _not?: InputMaybe<AuthUserSecurityKeysBoolExp>;
  _or?: InputMaybe<Array<AuthUserSecurityKeysBoolExp>>;
  counter?: InputMaybe<BigintComparisonExp>;
  credentialId?: InputMaybe<StringComparisonExp>;
  credentialPublicKey?: InputMaybe<ByteaComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  nickname?: InputMaybe<StringComparisonExp>;
  transports?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "auth.user_security_keys" */
export enum AuthUserSecurityKeysConstraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey'
}

/** input type for incrementing numeric columns in table "auth.user_security_keys" */
export interface AuthUserSecurityKeysIncInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
}

/** input type for inserting data into table "auth.user_security_keys" */
export interface AuthUserSecurityKeysInsertInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface AuthUserSecurityKeysMaxFields {
  __typename?: 'authUserSecurityKeys_max_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysMaxOrderBy {
  counter?: InputMaybe<OrderBy>;
  credentialId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  transports?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface AuthUserSecurityKeysMinFields {
  __typename?: 'authUserSecurityKeys_min_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysMinOrderBy {
  counter?: InputMaybe<OrderBy>;
  credentialId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  transports?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "auth.user_security_keys" */
export interface AuthUserSecurityKeysMutationResponse {
  __typename?: 'authUserSecurityKeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserSecurityKeys>;
}

/** on_conflict condition type for table "auth.user_security_keys" */
export interface AuthUserSecurityKeysOnConflict {
  constraint: AuthUserSecurityKeysConstraint;
  update_columns?: Array<AuthUserSecurityKeysUpdateColumn>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}

/** Ordering options when selecting data from "auth.user_security_keys". */
export interface AuthUserSecurityKeysOrderBy {
  counter?: InputMaybe<OrderBy>;
  credentialId?: InputMaybe<OrderBy>;
  credentialPublicKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  transports?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.user_security_keys */
export interface AuthUserSecurityKeysPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeysSelectColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_security_keys" */
export interface AuthUserSecurityKeysSetInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface AuthUserSecurityKeysStddevFields {
  __typename?: 'authUserSecurityKeys_stddev_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysStddevOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface AuthUserSecurityKeysStddevPopFields {
  __typename?: 'authUserSecurityKeys_stddev_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysStddevPopOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface AuthUserSecurityKeysStddevSampFields {
  __typename?: 'authUserSecurityKeys_stddev_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysStddevSampOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "authUserSecurityKeys" */
export interface AuthUserSecurityKeysStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthUserSecurityKeysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthUserSecurityKeysStreamCursorValueInput {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface AuthUserSecurityKeysSumFields {
  __typename?: 'authUserSecurityKeys_sum_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
}

/** order by sum() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysSumOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** update columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeysUpdateColumn {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export interface AuthUserSecurityKeysUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthUserSecurityKeysIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSecurityKeysSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserSecurityKeysBoolExp;
}

/** aggregate var_pop on columns */
export interface AuthUserSecurityKeysVarPopFields {
  __typename?: 'authUserSecurityKeys_var_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysVarPopOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface AuthUserSecurityKeysVarSampFields {
  __typename?: 'authUserSecurityKeys_var_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysVarSampOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface AuthUserSecurityKeysVarianceFields {
  __typename?: 'authUserSecurityKeys_variance_fields';
  counter?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "auth.user_security_keys" */
export interface AuthUserSecurityKeysVarianceOrderBy {
  counter?: InputMaybe<OrderBy>;
}

/** columns and relationships of "auth.modules" */
export interface AuthModules {
  __typename?: 'auth_modules';
  module: Scalars['String']['output'];
}

/** aggregated selection of "auth.modules" */
export interface AuthModulesAggregate {
  __typename?: 'auth_modules_aggregate';
  aggregate?: Maybe<AuthModulesAggregateFields>;
  nodes: Array<AuthModules>;
}

/** aggregate fields of "auth.modules" */
export interface AuthModulesAggregateFields {
  __typename?: 'auth_modules_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthModulesMaxFields>;
  min?: Maybe<AuthModulesMinFields>;
}


/** aggregate fields of "auth.modules" */
export interface AuthModulesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<AuthModulesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "auth.modules". All fields are combined with a logical 'AND'. */
export interface AuthModulesBoolExp {
  _and?: InputMaybe<Array<AuthModulesBoolExp>>;
  _not?: InputMaybe<AuthModulesBoolExp>;
  _or?: InputMaybe<Array<AuthModulesBoolExp>>;
  module?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "auth.modules" */
export enum AuthModulesConstraint {
  /** unique or primary key constraint on columns "module" */
  ModulesPkey = 'modules_pkey'
}

/** input type for inserting data into table "auth.modules" */
export interface AuthModulesInsertInput {
  module?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface AuthModulesMaxFields {
  __typename?: 'auth_modules_max_fields';
  module?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface AuthModulesMinFields {
  __typename?: 'auth_modules_min_fields';
  module?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "auth.modules" */
export interface AuthModulesMutationResponse {
  __typename?: 'auth_modules_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthModules>;
}

/** on_conflict condition type for table "auth.modules" */
export interface AuthModulesOnConflict {
  constraint: AuthModulesConstraint;
  update_columns?: Array<AuthModulesUpdateColumn>;
  where?: InputMaybe<AuthModulesBoolExp>;
}

/** Ordering options when selecting data from "auth.modules". */
export interface AuthModulesOrderBy {
  module?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.modules */
export interface AuthModulesPkColumnsInput {
  module: Scalars['String']['input'];
}

/** select columns of table "auth.modules" */
export enum AuthModulesSelectColumn {
  /** column name */
  Module = 'module'
}

/** input type for updating data in table "auth.modules" */
export interface AuthModulesSetInput {
  module?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "auth_modules" */
export interface AuthModulesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AuthModulesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AuthModulesStreamCursorValueInput {
  module?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "auth.modules" */
export enum AuthModulesUpdateColumn {
  /** column name */
  Module = 'module'
}

export interface AuthModulesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthModulesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthModulesBoolExp;
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface BigintComparisonExp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

/** columns and relationships of "bookmark_gnpie_members" */
export interface BookmarkGnpieMembers {
  __typename?: 'bookmark_gnpie_members';
  /** An object relationship */
  bookmark_task: BookmarkTasks;
  bookmark_task_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  gnpie_member: Users;
  gnpie_member_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersAggregate {
  __typename?: 'bookmark_gnpie_members_aggregate';
  aggregate?: Maybe<BookmarkGnpieMembersAggregateFields>;
  nodes: Array<BookmarkGnpieMembers>;
}

export interface BookmarkGnpieMembersAggregateBoolExp {
  count?: InputMaybe<BookmarkGnpieMembersAggregateBoolExpCount>;
}

export interface BookmarkGnpieMembersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkGnpieMembersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersAggregateFields {
  __typename?: 'bookmark_gnpie_members_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkGnpieMembersMaxFields>;
  min?: Maybe<BookmarkGnpieMembersMinFields>;
}


/** aggregate fields of "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookmarkGnpieMembersMaxOrderBy>;
  min?: InputMaybe<BookmarkGnpieMembersMinOrderBy>;
}

/** input type for inserting array relation for remote table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersArrRelInsertInput {
  data: Array<BookmarkGnpieMembersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarkGnpieMembersOnConflict>;
}

/** Boolean expression to filter rows from the table "bookmark_gnpie_members". All fields are combined with a logical 'AND'. */
export interface BookmarkGnpieMembersBoolExp {
  _and?: InputMaybe<Array<BookmarkGnpieMembersBoolExp>>;
  _not?: InputMaybe<BookmarkGnpieMembersBoolExp>;
  _or?: InputMaybe<Array<BookmarkGnpieMembersBoolExp>>;
  bookmark_task?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_gnpie_members" */
export enum BookmarkGnpieMembersConstraint {
  /** unique or primary key constraint on columns "bookmark_task_id", "gnpie_member_id" */
  BookmarkGnpieMembersBookmarkTaskIdGnpieMemberIdKey = 'bookmark_gnpie_members_bookmark_task_id_gnpie_member_id_key',
  /** unique or primary key constraint on columns "id" */
  BookmarkGnpieMembersPkey = 'bookmark_gnpie_members_pkey'
}

/** input type for inserting data into table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersInsertInput {
  bookmark_task?: InputMaybe<BookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface BookmarkGnpieMembersMaxFields {
  __typename?: 'bookmark_gnpie_members_max_fields';
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersMaxOrderBy {
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface BookmarkGnpieMembersMinFields {
  __typename?: 'bookmark_gnpie_members_min_fields';
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersMinOrderBy {
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersMutationResponse {
  __typename?: 'bookmark_gnpie_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkGnpieMembers>;
}

/** on_conflict condition type for table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersOnConflict {
  constraint: BookmarkGnpieMembersConstraint;
  update_columns?: Array<BookmarkGnpieMembersUpdateColumn>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}

/** Ordering options when selecting data from "bookmark_gnpie_members". */
export interface BookmarkGnpieMembersOrderBy {
  bookmark_task?: InputMaybe<BookmarkTasksOrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_gnpie_members */
export interface BookmarkGnpieMembersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "bookmark_gnpie_members" */
export enum BookmarkGnpieMembersSelectColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersSetInput {
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "bookmark_gnpie_members" */
export interface BookmarkGnpieMembersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkGnpieMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkGnpieMembersStreamCursorValueInput {
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "bookmark_gnpie_members" */
export enum BookmarkGnpieMembersUpdateColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface BookmarkGnpieMembersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkGnpieMembersSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkGnpieMembersBoolExp;
}

/** statuses of bookmarks */
export interface BookmarkStatus {
  __typename?: 'bookmark_status';
  status: Scalars['String']['output'];
}

/** aggregated selection of "bookmark_status" */
export interface BookmarkStatusAggregate {
  __typename?: 'bookmark_status_aggregate';
  aggregate?: Maybe<BookmarkStatusAggregateFields>;
  nodes: Array<BookmarkStatus>;
}

/** aggregate fields of "bookmark_status" */
export interface BookmarkStatusAggregateFields {
  __typename?: 'bookmark_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkStatusMaxFields>;
  min?: Maybe<BookmarkStatusMinFields>;
}


/** aggregate fields of "bookmark_status" */
export interface BookmarkStatusAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "bookmark_status". All fields are combined with a logical 'AND'. */
export interface BookmarkStatusBoolExp {
  _and?: InputMaybe<Array<BookmarkStatusBoolExp>>;
  _not?: InputMaybe<BookmarkStatusBoolExp>;
  _or?: InputMaybe<Array<BookmarkStatusBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_status" */
export enum BookmarkStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  BookmarkStatusPkey = 'bookmark_status_pkey'
}

export enum BookmarkStatusEnum {
  Done = 'DONE',
  Implementation = 'IMPLEMENTATION',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO'
}

/** Boolean expression to compare columns of type "bookmark_status_enum". All fields are combined with logical 'AND'. */
export interface BookmarkStatusEnumComparisonExp {
  _eq?: InputMaybe<BookmarkStatusEnum>;
  _in?: InputMaybe<Array<BookmarkStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<BookmarkStatusEnum>;
  _nin?: InputMaybe<Array<BookmarkStatusEnum>>;
}

/** input type for inserting data into table "bookmark_status" */
export interface BookmarkStatusInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface BookmarkStatusMaxFields {
  __typename?: 'bookmark_status_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface BookmarkStatusMinFields {
  __typename?: 'bookmark_status_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "bookmark_status" */
export interface BookmarkStatusMutationResponse {
  __typename?: 'bookmark_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkStatus>;
}

/** on_conflict condition type for table "bookmark_status" */
export interface BookmarkStatusOnConflict {
  constraint: BookmarkStatusConstraint;
  update_columns?: Array<BookmarkStatusUpdateColumn>;
  where?: InputMaybe<BookmarkStatusBoolExp>;
}

/** Ordering options when selecting data from "bookmark_status". */
export interface BookmarkStatusOrderBy {
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_status */
export interface BookmarkStatusPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "bookmark_status" */
export enum BookmarkStatusSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "bookmark_status" */
export interface BookmarkStatusSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "bookmark_status" */
export interface BookmarkStatusStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkStatusStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "bookmark_status" */
export enum BookmarkStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface BookmarkStatusUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkStatusSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkStatusBoolExp;
}

/** keeps all the logs of the activity around a bookmark task report, will be inserted automatically */
export interface BookmarkTaskActivity {
  __typename?: 'bookmark_task_activity';
  activity: Scalars['String']['output'];
  /** An object relationship */
  bookmark_task: BookmarkTasks;
  bookmark_task_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  status?: Maybe<BookmarkTaskStatusesEnum>;
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "bookmark_task_activity" */
export interface BookmarkTaskActivityAggregate {
  __typename?: 'bookmark_task_activity_aggregate';
  aggregate?: Maybe<BookmarkTaskActivityAggregateFields>;
  nodes: Array<BookmarkTaskActivity>;
}

export interface BookmarkTaskActivityAggregateBoolExp {
  count?: InputMaybe<BookmarkTaskActivityAggregateBoolExpCount>;
}

export interface BookmarkTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTaskActivityBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "bookmark_task_activity" */
export interface BookmarkTaskActivityAggregateFields {
  __typename?: 'bookmark_task_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkTaskActivityMaxFields>;
  min?: Maybe<BookmarkTaskActivityMinFields>;
}


/** aggregate fields of "bookmark_task_activity" */
export interface BookmarkTaskActivityAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "bookmark_task_activity" */
export interface BookmarkTaskActivityAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookmarkTaskActivityMaxOrderBy>;
  min?: InputMaybe<BookmarkTaskActivityMinOrderBy>;
}

/** input type for inserting array relation for remote table "bookmark_task_activity" */
export interface BookmarkTaskActivityArrRelInsertInput {
  data: Array<BookmarkTaskActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarkTaskActivityOnConflict>;
}

/** Boolean expression to filter rows from the table "bookmark_task_activity". All fields are combined with a logical 'AND'. */
export interface BookmarkTaskActivityBoolExp {
  _and?: InputMaybe<Array<BookmarkTaskActivityBoolExp>>;
  _not?: InputMaybe<BookmarkTaskActivityBoolExp>;
  _or?: InputMaybe<Array<BookmarkTaskActivityBoolExp>>;
  activity?: InputMaybe<StringComparisonExp>;
  bookmark_task?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<BookmarkTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_task_activity" */
export enum BookmarkTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskActivityPkey = 'bookmark_task_activity_pkey'
}

/** input type for inserting data into table "bookmark_task_activity" */
export interface BookmarkTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  bookmark_task?: InputMaybe<BookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<BookmarkTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface BookmarkTaskActivityMaxFields {
  __typename?: 'bookmark_task_activity_max_fields';
  activity?: Maybe<Scalars['String']['output']>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "bookmark_task_activity" */
export interface BookmarkTaskActivityMaxOrderBy {
  activity?: InputMaybe<OrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface BookmarkTaskActivityMinFields {
  __typename?: 'bookmark_task_activity_min_fields';
  activity?: Maybe<Scalars['String']['output']>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "bookmark_task_activity" */
export interface BookmarkTaskActivityMinOrderBy {
  activity?: InputMaybe<OrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "bookmark_task_activity" */
export interface BookmarkTaskActivityMutationResponse {
  __typename?: 'bookmark_task_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkTaskActivity>;
}

/** on_conflict condition type for table "bookmark_task_activity" */
export interface BookmarkTaskActivityOnConflict {
  constraint: BookmarkTaskActivityConstraint;
  update_columns?: Array<BookmarkTaskActivityUpdateColumn>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}

/** Ordering options when selecting data from "bookmark_task_activity". */
export interface BookmarkTaskActivityOrderBy {
  activity?: InputMaybe<OrderBy>;
  bookmark_task?: InputMaybe<BookmarkTasksOrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_task_activity */
export interface BookmarkTaskActivityPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "bookmark_task_activity" */
export enum BookmarkTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bookmark_task_activity" */
export interface BookmarkTaskActivitySetInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<BookmarkTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "bookmark_task_activity" */
export interface BookmarkTaskActivityStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkTaskActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkTaskActivityStreamCursorValueInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<BookmarkTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "bookmark_task_activity" */
export enum BookmarkTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface BookmarkTaskActivityUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkTaskActivitySetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkTaskActivityBoolExp;
}

/** contains all the answers for a given bookmark task */
export interface BookmarkTaskAnswers {
  __typename?: 'bookmark_task_answers';
  answer?: Maybe<Scalars['String']['output']>;
  asnwered_by_user_id: Scalars['uuid']['output'];
  /** An object relationship */
  bookmark_task: BookmarkTasks;
  bookmark_task_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  is_completed?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
}


/** contains all the answers for a given bookmark task */
export interface BookmarkTaskAnswersDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "bookmark_task_answers" */
export interface BookmarkTaskAnswersAggregate {
  __typename?: 'bookmark_task_answers_aggregate';
  aggregate?: Maybe<BookmarkTaskAnswersAggregateFields>;
  nodes: Array<BookmarkTaskAnswers>;
}

export interface BookmarkTaskAnswersAggregateBoolExp {
  bool_and?: InputMaybe<BookmarkTaskAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<BookmarkTaskAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<BookmarkTaskAnswersAggregateBoolExpCount>;
}

export interface BookmarkTaskAnswersAggregateBoolExpBoolAnd {
  arguments: BookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTaskAnswersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface BookmarkTaskAnswersAggregateBoolExpBoolOr {
  arguments: BookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTaskAnswersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface BookmarkTaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTaskAnswersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "bookmark_task_answers" */
export interface BookmarkTaskAnswersAggregateFields {
  __typename?: 'bookmark_task_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkTaskAnswersMaxFields>;
  min?: Maybe<BookmarkTaskAnswersMinFields>;
}


/** aggregate fields of "bookmark_task_answers" */
export interface BookmarkTaskAnswersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "bookmark_task_answers" */
export interface BookmarkTaskAnswersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookmarkTaskAnswersMaxOrderBy>;
  min?: InputMaybe<BookmarkTaskAnswersMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface BookmarkTaskAnswersAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "bookmark_task_answers" */
export interface BookmarkTaskAnswersArrRelInsertInput {
  data: Array<BookmarkTaskAnswersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarkTaskAnswersOnConflict>;
}

/** Boolean expression to filter rows from the table "bookmark_task_answers". All fields are combined with a logical 'AND'. */
export interface BookmarkTaskAnswersBoolExp {
  _and?: InputMaybe<Array<BookmarkTaskAnswersBoolExp>>;
  _not?: InputMaybe<BookmarkTaskAnswersBoolExp>;
  _or?: InputMaybe<Array<BookmarkTaskAnswersBoolExp>>;
  answer?: InputMaybe<StringComparisonExp>;
  asnwered_by_user_id?: InputMaybe<UuidComparisonExp>;
  bookmark_task?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_completed?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_task_answers" */
export enum BookmarkTaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskAnswersPkey = 'bookmark_task_answers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface BookmarkTaskAnswersDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface BookmarkTaskAnswersDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface BookmarkTaskAnswersDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "bookmark_task_answers" */
export interface BookmarkTaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  asnwered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_task?: InputMaybe<BookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface BookmarkTaskAnswersMaxFields {
  __typename?: 'bookmark_task_answers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  asnwered_by_user_id?: Maybe<Scalars['uuid']['output']>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "bookmark_task_answers" */
export interface BookmarkTaskAnswersMaxOrderBy {
  answer?: InputMaybe<OrderBy>;
  asnwered_by_user_id?: InputMaybe<OrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface BookmarkTaskAnswersMinFields {
  __typename?: 'bookmark_task_answers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  asnwered_by_user_id?: Maybe<Scalars['uuid']['output']>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "bookmark_task_answers" */
export interface BookmarkTaskAnswersMinOrderBy {
  answer?: InputMaybe<OrderBy>;
  asnwered_by_user_id?: InputMaybe<OrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "bookmark_task_answers" */
export interface BookmarkTaskAnswersMutationResponse {
  __typename?: 'bookmark_task_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkTaskAnswers>;
}

/** on_conflict condition type for table "bookmark_task_answers" */
export interface BookmarkTaskAnswersOnConflict {
  constraint: BookmarkTaskAnswersConstraint;
  update_columns?: Array<BookmarkTaskAnswersUpdateColumn>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}

/** Ordering options when selecting data from "bookmark_task_answers". */
export interface BookmarkTaskAnswersOrderBy {
  answer?: InputMaybe<OrderBy>;
  asnwered_by_user_id?: InputMaybe<OrderBy>;
  bookmark_task?: InputMaybe<BookmarkTasksOrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_completed?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_task_answers */
export interface BookmarkTaskAnswersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface BookmarkTaskAnswersPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "bookmark_task_answers" */
export enum BookmarkTaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "bookmark_task_answers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "bookmark_task_answers" */
export enum BookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "bookmark_task_answers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "bookmark_task_answers" */
export enum BookmarkTaskAnswersSelectColumnBookmarkTaskAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "bookmark_task_answers" */
export interface BookmarkTaskAnswersSetInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  asnwered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "bookmark_task_answers" */
export interface BookmarkTaskAnswersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkTaskAnswersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkTaskAnswersStreamCursorValueInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  asnwered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "bookmark_task_answers" */
export enum BookmarkTaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AsnweredByUserId = 'asnwered_by_user_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface BookmarkTaskAnswersUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BookmarkTaskAnswersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<BookmarkTaskAnswersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<BookmarkTaskAnswersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<BookmarkTaskAnswersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BookmarkTaskAnswersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkTaskAnswersSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkTaskAnswersBoolExp;
}

/** keeps all the comments done around a bookmark task */
export interface BookmarkTaskComment {
  __typename?: 'bookmark_task_comment';
  /** An object relationship */
  bookmark_task: BookmarkTasks;
  bookmark_task_id: Scalars['uuid']['output'];
  by_user_id: Scalars['uuid']['output'];
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "bookmark_task_comment" */
export interface BookmarkTaskCommentAggregate {
  __typename?: 'bookmark_task_comment_aggregate';
  aggregate?: Maybe<BookmarkTaskCommentAggregateFields>;
  nodes: Array<BookmarkTaskComment>;
}

export interface BookmarkTaskCommentAggregateBoolExp {
  count?: InputMaybe<BookmarkTaskCommentAggregateBoolExpCount>;
}

export interface BookmarkTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTaskCommentBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "bookmark_task_comment" */
export interface BookmarkTaskCommentAggregateFields {
  __typename?: 'bookmark_task_comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkTaskCommentMaxFields>;
  min?: Maybe<BookmarkTaskCommentMinFields>;
}


/** aggregate fields of "bookmark_task_comment" */
export interface BookmarkTaskCommentAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "bookmark_task_comment" */
export interface BookmarkTaskCommentAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookmarkTaskCommentMaxOrderBy>;
  min?: InputMaybe<BookmarkTaskCommentMinOrderBy>;
}

/** input type for inserting array relation for remote table "bookmark_task_comment" */
export interface BookmarkTaskCommentArrRelInsertInput {
  data: Array<BookmarkTaskCommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarkTaskCommentOnConflict>;
}

/** Boolean expression to filter rows from the table "bookmark_task_comment". All fields are combined with a logical 'AND'. */
export interface BookmarkTaskCommentBoolExp {
  _and?: InputMaybe<Array<BookmarkTaskCommentBoolExp>>;
  _not?: InputMaybe<BookmarkTaskCommentBoolExp>;
  _or?: InputMaybe<Array<BookmarkTaskCommentBoolExp>>;
  bookmark_task?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<UuidComparisonExp>;
  by_user_id?: InputMaybe<UuidComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_task_comment" */
export enum BookmarkTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTaskCommentPkey = 'bookmark_task_comment_pkey'
}

/** input type for inserting data into table "bookmark_task_comment" */
export interface BookmarkTaskCommentInsertInput {
  bookmark_task?: InputMaybe<BookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface BookmarkTaskCommentMaxFields {
  __typename?: 'bookmark_task_comment_max_fields';
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "bookmark_task_comment" */
export interface BookmarkTaskCommentMaxOrderBy {
  bookmark_task_id?: InputMaybe<OrderBy>;
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface BookmarkTaskCommentMinFields {
  __typename?: 'bookmark_task_comment_min_fields';
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "bookmark_task_comment" */
export interface BookmarkTaskCommentMinOrderBy {
  bookmark_task_id?: InputMaybe<OrderBy>;
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "bookmark_task_comment" */
export interface BookmarkTaskCommentMutationResponse {
  __typename?: 'bookmark_task_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkTaskComment>;
}

/** on_conflict condition type for table "bookmark_task_comment" */
export interface BookmarkTaskCommentOnConflict {
  constraint: BookmarkTaskCommentConstraint;
  update_columns?: Array<BookmarkTaskCommentUpdateColumn>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}

/** Ordering options when selecting data from "bookmark_task_comment". */
export interface BookmarkTaskCommentOrderBy {
  bookmark_task?: InputMaybe<BookmarkTasksOrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_task_comment */
export interface BookmarkTaskCommentPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "bookmark_task_comment" */
export enum BookmarkTaskCommentSelectColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bookmark_task_comment" */
export interface BookmarkTaskCommentSetInput {
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "bookmark_task_comment" */
export interface BookmarkTaskCommentStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkTaskCommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkTaskCommentStreamCursorValueInput {
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "bookmark_task_comment" */
export enum BookmarkTaskCommentUpdateColumn {
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface BookmarkTaskCommentUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkTaskCommentSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkTaskCommentBoolExp;
}

/** enum table that contains measures */
export interface BookmarkTaskMeasures {
  __typename?: 'bookmark_task_measures';
  measure: Scalars['String']['output'];
}

/** aggregated selection of "bookmark_task_measures" */
export interface BookmarkTaskMeasuresAggregate {
  __typename?: 'bookmark_task_measures_aggregate';
  aggregate?: Maybe<BookmarkTaskMeasuresAggregateFields>;
  nodes: Array<BookmarkTaskMeasures>;
}

/** aggregate fields of "bookmark_task_measures" */
export interface BookmarkTaskMeasuresAggregateFields {
  __typename?: 'bookmark_task_measures_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkTaskMeasuresMaxFields>;
  min?: Maybe<BookmarkTaskMeasuresMinFields>;
}


/** aggregate fields of "bookmark_task_measures" */
export interface BookmarkTaskMeasuresAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkTaskMeasuresSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "bookmark_task_measures". All fields are combined with a logical 'AND'. */
export interface BookmarkTaskMeasuresBoolExp {
  _and?: InputMaybe<Array<BookmarkTaskMeasuresBoolExp>>;
  _not?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
  _or?: InputMaybe<Array<BookmarkTaskMeasuresBoolExp>>;
  measure?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_task_measures" */
export enum BookmarkTaskMeasuresConstraint {
  /** unique or primary key constraint on columns "measure" */
  BookmarkTaskMeasuresPkey = 'bookmark_task_measures_pkey'
}

/** input type for inserting data into table "bookmark_task_measures" */
export interface BookmarkTaskMeasuresInsertInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface BookmarkTaskMeasuresMaxFields {
  __typename?: 'bookmark_task_measures_max_fields';
  measure?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface BookmarkTaskMeasuresMinFields {
  __typename?: 'bookmark_task_measures_min_fields';
  measure?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "bookmark_task_measures" */
export interface BookmarkTaskMeasuresMutationResponse {
  __typename?: 'bookmark_task_measures_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkTaskMeasures>;
}

/** on_conflict condition type for table "bookmark_task_measures" */
export interface BookmarkTaskMeasuresOnConflict {
  constraint: BookmarkTaskMeasuresConstraint;
  update_columns?: Array<BookmarkTaskMeasuresUpdateColumn>;
  where?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
}

/** Ordering options when selecting data from "bookmark_task_measures". */
export interface BookmarkTaskMeasuresOrderBy {
  measure?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_task_measures */
export interface BookmarkTaskMeasuresPkColumnsInput {
  measure: Scalars['String']['input'];
}

/** select columns of table "bookmark_task_measures" */
export enum BookmarkTaskMeasuresSelectColumn {
  /** column name */
  Measure = 'measure'
}

/** input type for updating data in table "bookmark_task_measures" */
export interface BookmarkTaskMeasuresSetInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "bookmark_task_measures" */
export interface BookmarkTaskMeasuresStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkTaskMeasuresStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkTaskMeasuresStreamCursorValueInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "bookmark_task_measures" */
export enum BookmarkTaskMeasuresUpdateColumn {
  /** column name */
  Measure = 'measure'
}

export interface BookmarkTaskMeasuresUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkTaskMeasuresSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkTaskMeasuresBoolExp;
}

/** statuses of bookmarks */
export interface BookmarkTaskStatuses {
  __typename?: 'bookmark_task_statuses';
  status: Scalars['String']['output'];
}

/** aggregated selection of "bookmark_task_statuses" */
export interface BookmarkTaskStatusesAggregate {
  __typename?: 'bookmark_task_statuses_aggregate';
  aggregate?: Maybe<BookmarkTaskStatusesAggregateFields>;
  nodes: Array<BookmarkTaskStatuses>;
}

/** aggregate fields of "bookmark_task_statuses" */
export interface BookmarkTaskStatusesAggregateFields {
  __typename?: 'bookmark_task_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkTaskStatusesMaxFields>;
  min?: Maybe<BookmarkTaskStatusesMinFields>;
}


/** aggregate fields of "bookmark_task_statuses" */
export interface BookmarkTaskStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkTaskStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "bookmark_task_statuses". All fields are combined with a logical 'AND'. */
export interface BookmarkTaskStatusesBoolExp {
  _and?: InputMaybe<Array<BookmarkTaskStatusesBoolExp>>;
  _not?: InputMaybe<BookmarkTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<BookmarkTaskStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "bookmark_task_statuses" */
export enum BookmarkTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  BookmarkTaskStatusesPkey = 'bookmark_task_statuses_pkey'
}

export enum BookmarkTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

/** Boolean expression to compare columns of type "bookmark_task_statuses_enum". All fields are combined with logical 'AND'. */
export interface BookmarkTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<BookmarkTaskStatusesEnum>;
  _in?: InputMaybe<Array<BookmarkTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<BookmarkTaskStatusesEnum>;
  _nin?: InputMaybe<Array<BookmarkTaskStatusesEnum>>;
}

/** input type for inserting data into table "bookmark_task_statuses" */
export interface BookmarkTaskStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface BookmarkTaskStatusesMaxFields {
  __typename?: 'bookmark_task_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface BookmarkTaskStatusesMinFields {
  __typename?: 'bookmark_task_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "bookmark_task_statuses" */
export interface BookmarkTaskStatusesMutationResponse {
  __typename?: 'bookmark_task_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkTaskStatuses>;
}

/** on_conflict condition type for table "bookmark_task_statuses" */
export interface BookmarkTaskStatusesOnConflict {
  constraint: BookmarkTaskStatusesConstraint;
  update_columns?: Array<BookmarkTaskStatusesUpdateColumn>;
  where?: InputMaybe<BookmarkTaskStatusesBoolExp>;
}

/** Ordering options when selecting data from "bookmark_task_statuses". */
export interface BookmarkTaskStatusesOrderBy {
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: bookmark_task_statuses */
export interface BookmarkTaskStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "bookmark_task_statuses" */
export enum BookmarkTaskStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "bookmark_task_statuses" */
export interface BookmarkTaskStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "bookmark_task_statuses" */
export interface BookmarkTaskStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkTaskStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkTaskStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "bookmark_task_statuses" */
export enum BookmarkTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface BookmarkTaskStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkTaskStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkTaskStatusesBoolExp;
}

/** Tasks created for each bookmark */
export interface BookmarkTasks {
  __typename?: 'bookmark_tasks';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  bookmark: Bookmarks;
  bookmark_id: Scalars['Int']['output'];
  /** An array relationship */
  bookmark_task_activities: Array<BookmarkTaskActivity>;
  /** An aggregate relationship */
  bookmark_task_activities_aggregate: BookmarkTaskActivityAggregate;
  /** An array relationship */
  bookmark_task_answers: Array<BookmarkTaskAnswers>;
  /** An aggregate relationship */
  bookmark_task_answers_aggregate: BookmarkTaskAnswersAggregate;
  /** An array relationship */
  bookmark_task_comments: Array<BookmarkTaskComment>;
  /** An aggregate relationship */
  bookmark_task_comments_aggregate: BookmarkTaskCommentAggregate;
  /** An array relationship */
  bookmark_task_gnpie_members: Array<BookmarkGnpieMembers>;
  /** An aggregate relationship */
  bookmark_task_gnpie_members_aggregate: BookmarkGnpieMembersAggregate;
  /** An object relationship */
  bookmark_task_measure?: Maybe<PkieTypeMeasure>;
  budget_allocated_at_quarter?: Maybe<Scalars['String']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Int']['output']>;
  budget_needed?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  chapter_coordinator?: Maybe<Users>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chief_gnpie_secretariat?: Maybe<Users>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline: Scalars['date']['output'];
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  gnpie_member?: Maybe<Users>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_secretariat?: Maybe<Users>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  is_completed?: Maybe<Scalars['Boolean']['output']>;
  measure?: Maybe<PkieTypeMeasureEnum>;
  /** An object relationship */
  negotiator?: Maybe<Users>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  specific_measure?: Maybe<Scalars['String']['output']>;
  status: BookmarkTaskStatusesEnum;
  text: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  updated_by_user?: Maybe<Users>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  yearly_quarter?: Maybe<YearlyQuarters>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskActivitiesArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskActivityOrderBy>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskActivitiesAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskActivityOrderBy>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskAnswersOrderBy>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskAnswersOrderBy>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskCommentsArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskCommentOrderBy>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskCommentOrderBy>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkGnpieMembersOrderBy>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksBookmarkTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkGnpieMembersOrderBy>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


/** Tasks created for each bookmark */
export interface BookmarkTasksDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "bookmark_tasks" */
export interface BookmarkTasksAggregate {
  __typename?: 'bookmark_tasks_aggregate';
  aggregate?: Maybe<BookmarkTasksAggregateFields>;
  nodes: Array<BookmarkTasks>;
}

export interface BookmarkTasksAggregateBoolExp {
  bool_and?: InputMaybe<BookmarkTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<BookmarkTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<BookmarkTasksAggregateBoolExpCount>;
}

export interface BookmarkTasksAggregateBoolExpBoolAnd {
  arguments: BookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTasksBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface BookmarkTasksAggregateBoolExpBoolOr {
  arguments: BookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTasksBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface BookmarkTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarkTasksBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "bookmark_tasks" */
export interface BookmarkTasksAggregateFields {
  __typename?: 'bookmark_tasks_aggregate_fields';
  avg?: Maybe<BookmarkTasksAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarkTasksMaxFields>;
  min?: Maybe<BookmarkTasksMinFields>;
  stddev?: Maybe<BookmarkTasksStddevFields>;
  stddev_pop?: Maybe<BookmarkTasksStddevPopFields>;
  stddev_samp?: Maybe<BookmarkTasksStddevSampFields>;
  sum?: Maybe<BookmarkTasksSumFields>;
  var_pop?: Maybe<BookmarkTasksVarPopFields>;
  var_samp?: Maybe<BookmarkTasksVarSampFields>;
  variance?: Maybe<BookmarkTasksVarianceFields>;
}


/** aggregate fields of "bookmark_tasks" */
export interface BookmarkTasksAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "bookmark_tasks" */
export interface BookmarkTasksAggregateOrderBy {
  avg?: InputMaybe<BookmarkTasksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookmarkTasksMaxOrderBy>;
  min?: InputMaybe<BookmarkTasksMinOrderBy>;
  stddev?: InputMaybe<BookmarkTasksStddevOrderBy>;
  stddev_pop?: InputMaybe<BookmarkTasksStddevPopOrderBy>;
  stddev_samp?: InputMaybe<BookmarkTasksStddevSampOrderBy>;
  sum?: InputMaybe<BookmarkTasksSumOrderBy>;
  var_pop?: InputMaybe<BookmarkTasksVarPopOrderBy>;
  var_samp?: InputMaybe<BookmarkTasksVarSampOrderBy>;
  variance?: InputMaybe<BookmarkTasksVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface BookmarkTasksAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "bookmark_tasks" */
export interface BookmarkTasksArrRelInsertInput {
  data: Array<BookmarkTasksInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarkTasksOnConflict>;
}

/** aggregate avg on columns */
export interface BookmarkTasksAvgFields {
  __typename?: 'bookmark_tasks_avg_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "bookmark_tasks" */
export interface BookmarkTasksAvgOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "bookmark_tasks". All fields are combined with a logical 'AND'. */
export interface BookmarkTasksBoolExp {
  _and?: InputMaybe<Array<BookmarkTasksBoolExp>>;
  _not?: InputMaybe<BookmarkTasksBoolExp>;
  _or?: InputMaybe<Array<BookmarkTasksBoolExp>>;
  assignee_id?: InputMaybe<UuidComparisonExp>;
  bookmark?: InputMaybe<BookmarksBoolExp>;
  bookmark_id?: InputMaybe<IntComparisonExp>;
  bookmark_task_activities?: InputMaybe<BookmarkTaskActivityBoolExp>;
  bookmark_task_activities_aggregate?: InputMaybe<BookmarkTaskActivityAggregateBoolExp>;
  bookmark_task_answers?: InputMaybe<BookmarkTaskAnswersBoolExp>;
  bookmark_task_answers_aggregate?: InputMaybe<BookmarkTaskAnswersAggregateBoolExp>;
  bookmark_task_comments?: InputMaybe<BookmarkTaskCommentBoolExp>;
  bookmark_task_comments_aggregate?: InputMaybe<BookmarkTaskCommentAggregateBoolExp>;
  bookmark_task_gnpie_members?: InputMaybe<BookmarkGnpieMembersBoolExp>;
  bookmark_task_gnpie_members_aggregate?: InputMaybe<BookmarkGnpieMembersAggregateBoolExp>;
  bookmark_task_measure?: InputMaybe<PkieTypeMeasureBoolExp>;
  budget_allocated_at_quarter?: InputMaybe<StringComparisonExp>;
  budget_allocated_at_year?: InputMaybe<IntComparisonExp>;
  budget_needed?: InputMaybe<NumericComparisonExp>;
  chapter_coordinator?: InputMaybe<UsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<UuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  completition_date?: InputMaybe<DateComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_completed?: InputMaybe<BooleanComparisonExp>;
  measure?: InputMaybe<PkieTypeMeasureEnumComparisonExp>;
  negotiator?: InputMaybe<UsersBoolExp>;
  negotiator_id?: InputMaybe<UuidComparisonExp>;
  specific_measure?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<BookmarkTaskStatusesEnumComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<UsersBoolExp>;
  updated_by_user_id?: InputMaybe<UuidComparisonExp>;
  yearly_quarter?: InputMaybe<YearlyQuartersBoolExp>;
}

/** unique or primary key constraints on table "bookmark_tasks" */
export enum BookmarkTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarkTasksPkey = 'bookmark_tasks_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface BookmarkTasksDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface BookmarkTasksDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface BookmarkTasksDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "bookmark_tasks" */
export interface BookmarkTasksIncInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "bookmark_tasks" */
export interface BookmarkTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark?: InputMaybe<BookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task_activities?: InputMaybe<BookmarkTaskActivityArrRelInsertInput>;
  bookmark_task_answers?: InputMaybe<BookmarkTaskAnswersArrRelInsertInput>;
  bookmark_task_comments?: InputMaybe<BookmarkTaskCommentArrRelInsertInput>;
  bookmark_task_gnpie_members?: InputMaybe<BookmarkGnpieMembersArrRelInsertInput>;
  bookmark_task_measure?: InputMaybe<PkieTypeMeasureObjRelInsertInput>;
  budget_allocated_at_quarter?: InputMaybe<Scalars['String']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
  chapter_coordinator?: InputMaybe<UsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<PkieTypeMeasureEnum>;
  negotiator?: InputMaybe<UsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  specific_measure?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookmarkTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<UsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  yearly_quarter?: InputMaybe<YearlyQuartersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface BookmarkTasksMaxFields {
  __typename?: 'bookmark_tasks_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  budget_allocated_at_quarter?: Maybe<Scalars['String']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Int']['output']>;
  budget_needed?: Maybe<Scalars['numeric']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  specific_measure?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "bookmark_tasks" */
export interface BookmarkTasksMaxOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_quarter?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  specific_measure?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface BookmarkTasksMinFields {
  __typename?: 'bookmark_tasks_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  budget_allocated_at_quarter?: Maybe<Scalars['String']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Int']['output']>;
  budget_needed?: Maybe<Scalars['numeric']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  specific_measure?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "bookmark_tasks" */
export interface BookmarkTasksMinOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_quarter?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  specific_measure?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "bookmark_tasks" */
export interface BookmarkTasksMutationResponse {
  __typename?: 'bookmark_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BookmarkTasks>;
}

/** input type for inserting object relation for remote table "bookmark_tasks" */
export interface BookmarkTasksObjRelInsertInput {
  data: BookmarkTasksInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarkTasksOnConflict>;
}

/** on_conflict condition type for table "bookmark_tasks" */
export interface BookmarkTasksOnConflict {
  constraint: BookmarkTasksConstraint;
  update_columns?: Array<BookmarkTasksUpdateColumn>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}

/** Ordering options when selecting data from "bookmark_tasks". */
export interface BookmarkTasksOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  bookmark?: InputMaybe<BookmarksOrderBy>;
  bookmark_id?: InputMaybe<OrderBy>;
  bookmark_task_activities_aggregate?: InputMaybe<BookmarkTaskActivityAggregateOrderBy>;
  bookmark_task_answers_aggregate?: InputMaybe<BookmarkTaskAnswersAggregateOrderBy>;
  bookmark_task_comments_aggregate?: InputMaybe<BookmarkTaskCommentAggregateOrderBy>;
  bookmark_task_gnpie_members_aggregate?: InputMaybe<BookmarkGnpieMembersAggregateOrderBy>;
  bookmark_task_measure?: InputMaybe<PkieTypeMeasureOrderBy>;
  budget_allocated_at_quarter?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
  chapter_coordinator?: InputMaybe<UsersOrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_completed?: InputMaybe<OrderBy>;
  measure?: InputMaybe<OrderBy>;
  negotiator?: InputMaybe<UsersOrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  specific_measure?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user?: InputMaybe<UsersOrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
  yearly_quarter?: InputMaybe<YearlyQuartersOrderBy>;
}

/** primary key columns input for table: bookmark_tasks */
export interface BookmarkTasksPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface BookmarkTasksPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "bookmark_tasks" */
export enum BookmarkTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

/** select "bookmark_tasks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "bookmark_tasks" */
export enum BookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "bookmark_tasks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "bookmark_tasks" */
export enum BookmarkTasksSelectColumnBookmarkTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "bookmark_tasks" */
export interface BookmarkTasksSetInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  budget_allocated_at_quarter?: InputMaybe<Scalars['String']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<PkieTypeMeasureEnum>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  specific_measure?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookmarkTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface BookmarkTasksStddevFields {
  __typename?: 'bookmark_tasks_stddev_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "bookmark_tasks" */
export interface BookmarkTasksStddevOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface BookmarkTasksStddevPopFields {
  __typename?: 'bookmark_tasks_stddev_pop_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "bookmark_tasks" */
export interface BookmarkTasksStddevPopOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface BookmarkTasksStddevSampFields {
  __typename?: 'bookmark_tasks_stddev_samp_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "bookmark_tasks" */
export interface BookmarkTasksStddevSampOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "bookmark_tasks" */
export interface BookmarkTasksStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarkTasksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarkTasksStreamCursorValueInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  budget_allocated_at_quarter?: InputMaybe<Scalars['String']['input']>;
  budget_allocated_at_year?: InputMaybe<Scalars['Int']['input']>;
  budget_needed?: InputMaybe<Scalars['numeric']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<PkieTypeMeasureEnum>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  specific_measure?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookmarkTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface BookmarkTasksSumFields {
  __typename?: 'bookmark_tasks_sum_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Int']['output']>;
  budget_needed?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "bookmark_tasks" */
export interface BookmarkTasksSumOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** update columns of table "bookmark_tasks" */
export enum BookmarkTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BudgetAllocatedAtQuarter = 'budget_allocated_at_quarter',
  /** column name */
  BudgetAllocatedAtYear = 'budget_allocated_at_year',
  /** column name */
  BudgetNeeded = 'budget_needed',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Measure = 'measure',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  SpecificMeasure = 'specific_measure',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface BookmarkTasksUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BookmarkTasksAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<BookmarkTasksDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<BookmarkTasksDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<BookmarkTasksDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BookmarkTasksIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BookmarkTasksPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarkTasksSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarkTasksBoolExp;
}

/** aggregate var_pop on columns */
export interface BookmarkTasksVarPopFields {
  __typename?: 'bookmark_tasks_var_pop_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "bookmark_tasks" */
export interface BookmarkTasksVarPopOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface BookmarkTasksVarSampFields {
  __typename?: 'bookmark_tasks_var_samp_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "bookmark_tasks" */
export interface BookmarkTasksVarSampOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface BookmarkTasksVarianceFields {
  __typename?: 'bookmark_tasks_variance_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  budget_allocated_at_year?: Maybe<Scalars['Float']['output']>;
  budget_needed?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "bookmark_tasks" */
export interface BookmarkTasksVarianceOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  budget_allocated_at_year?: InputMaybe<OrderBy>;
  budget_needed?: InputMaybe<OrderBy>;
}

/** bookmarks */
export interface Bookmarks {
  __typename?: 'bookmarks';
  assigned_to_user_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  assignee?: Maybe<Users>;
  /** An array relationship */
  bookmark_tasks: Array<BookmarkTasks>;
  /** An aggregate relationship */
  bookmark_tasks_aggregate: BookmarkTasksAggregate;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  code_id: Scalars['String']['output'];
  /** A computed field, executes function "code" */
  code_text?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline: Scalars['date']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  index_id: Scalars['Int']['output'];
  is_completed?: Maybe<Scalars['Boolean']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  measure_bookmarks: Array<PkieMeasureBookmark>;
  /** An aggregate relationship */
  measure_bookmarks_aggregate: PkieMeasureBookmarkAggregate;
  /** An object relationship */
  recommendation: Recommendations;
  recommendation_id: Scalars['uuid']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  status?: Maybe<BookmarkStatusEnum>;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
}


/** bookmarks */
export interface BookmarksBookmarkTasksArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


/** bookmarks */
export interface BookmarksBookmarkTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


/** bookmarks */
export interface BookmarksMeasureBookmarksArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


/** bookmarks */
export interface BookmarksMeasureBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}

/** aggregated selection of "bookmarks" */
export interface BookmarksAggregate {
  __typename?: 'bookmarks_aggregate';
  aggregate?: Maybe<BookmarksAggregateFields>;
  nodes: Array<Bookmarks>;
}

export interface BookmarksAggregateBoolExp {
  bool_and?: InputMaybe<BookmarksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<BookmarksAggregateBoolExpBoolOr>;
  count?: InputMaybe<BookmarksAggregateBoolExpCount>;
}

export interface BookmarksAggregateBoolExpBoolAnd {
  arguments: BookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarksBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface BookmarksAggregateBoolExpBoolOr {
  arguments: BookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarksBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface BookmarksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<BookmarksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BookmarksBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "bookmarks" */
export interface BookmarksAggregateFields {
  __typename?: 'bookmarks_aggregate_fields';
  avg?: Maybe<BookmarksAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BookmarksMaxFields>;
  min?: Maybe<BookmarksMinFields>;
  stddev?: Maybe<BookmarksStddevFields>;
  stddev_pop?: Maybe<BookmarksStddevPopFields>;
  stddev_samp?: Maybe<BookmarksStddevSampFields>;
  sum?: Maybe<BookmarksSumFields>;
  var_pop?: Maybe<BookmarksVarPopFields>;
  var_samp?: Maybe<BookmarksVarSampFields>;
  variance?: Maybe<BookmarksVarianceFields>;
}


/** aggregate fields of "bookmarks" */
export interface BookmarksAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<BookmarksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "bookmarks" */
export interface BookmarksAggregateOrderBy {
  avg?: InputMaybe<BookmarksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<BookmarksMaxOrderBy>;
  min?: InputMaybe<BookmarksMinOrderBy>;
  stddev?: InputMaybe<BookmarksStddevOrderBy>;
  stddev_pop?: InputMaybe<BookmarksStddevPopOrderBy>;
  stddev_samp?: InputMaybe<BookmarksStddevSampOrderBy>;
  sum?: InputMaybe<BookmarksSumOrderBy>;
  var_pop?: InputMaybe<BookmarksVarPopOrderBy>;
  var_samp?: InputMaybe<BookmarksVarSampOrderBy>;
  variance?: InputMaybe<BookmarksVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "bookmarks" */
export interface BookmarksArrRelInsertInput {
  data: Array<BookmarksInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarksOnConflict>;
}

/** aggregate avg on columns */
export interface BookmarksAvgFields {
  __typename?: 'bookmarks_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "bookmarks" */
export interface BookmarksAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "bookmarks". All fields are combined with a logical 'AND'. */
export interface BookmarksBoolExp {
  _and?: InputMaybe<Array<BookmarksBoolExp>>;
  _not?: InputMaybe<BookmarksBoolExp>;
  _or?: InputMaybe<Array<BookmarksBoolExp>>;
  assigned_to_user_id?: InputMaybe<UuidComparisonExp>;
  assignee?: InputMaybe<UsersBoolExp>;
  bookmark_tasks?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<BookmarkTasksAggregateBoolExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  code_id?: InputMaybe<StringComparisonExp>;
  code_text?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  index_id?: InputMaybe<IntComparisonExp>;
  is_completed?: InputMaybe<BooleanComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  measure_bookmarks?: InputMaybe<PkieMeasureBookmarkBoolExp>;
  measure_bookmarks_aggregate?: InputMaybe<PkieMeasureBookmarkAggregateBoolExp>;
  recommendation?: InputMaybe<RecommendationsBoolExp>;
  recommendation_id?: InputMaybe<UuidComparisonExp>;
  reference?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<BookmarkStatusEnumComparisonExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
}

/** unique or primary key constraints on table "bookmarks" */
export enum BookmarksConstraint {
  /** unique or primary key constraint on columns "id" */
  BookmarksPkey = 'bookmarks_pkey'
}

/** input type for incrementing numeric columns in table "bookmarks" */
export interface BookmarksIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  index_id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "bookmarks" */
export interface BookmarksInsertInput {
  assigned_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  assignee?: InputMaybe<UsersObjRelInsertInput>;
  bookmark_tasks?: InputMaybe<BookmarkTasksArrRelInsertInput>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  code_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  index_id?: InputMaybe<Scalars['Int']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure_bookmarks?: InputMaybe<PkieMeasureBookmarkArrRelInsertInput>;
  recommendation?: InputMaybe<RecommendationsObjRelInsertInput>;
  recommendation_id?: InputMaybe<Scalars['uuid']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookmarkStatusEnum>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface BookmarksMaxFields {
  __typename?: 'bookmarks_max_fields';
  assigned_to_user_id?: Maybe<Scalars['uuid']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  code_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "code" */
  code_text?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  index_id?: Maybe<Scalars['Int']['output']>;
  recommendation_id?: Maybe<Scalars['uuid']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "bookmarks" */
export interface BookmarksMaxOrderBy {
  assigned_to_user_id?: InputMaybe<OrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  code_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  recommendation_id?: InputMaybe<OrderBy>;
  reference?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface BookmarksMinFields {
  __typename?: 'bookmarks_min_fields';
  assigned_to_user_id?: Maybe<Scalars['uuid']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  code_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "code" */
  code_text?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  index_id?: Maybe<Scalars['Int']['output']>;
  recommendation_id?: Maybe<Scalars['uuid']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "bookmarks" */
export interface BookmarksMinOrderBy {
  assigned_to_user_id?: InputMaybe<OrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  code_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  recommendation_id?: InputMaybe<OrderBy>;
  reference?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "bookmarks" */
export interface BookmarksMutationResponse {
  __typename?: 'bookmarks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Bookmarks>;
}

/** input type for inserting object relation for remote table "bookmarks" */
export interface BookmarksObjRelInsertInput {
  data: BookmarksInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<BookmarksOnConflict>;
}

/** on_conflict condition type for table "bookmarks" */
export interface BookmarksOnConflict {
  constraint: BookmarksConstraint;
  update_columns?: Array<BookmarksUpdateColumn>;
  where?: InputMaybe<BookmarksBoolExp>;
}

/** Ordering options when selecting data from "bookmarks". */
export interface BookmarksOrderBy {
  assigned_to_user_id?: InputMaybe<OrderBy>;
  assignee?: InputMaybe<UsersOrderBy>;
  bookmark_tasks_aggregate?: InputMaybe<BookmarkTasksAggregateOrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  code_id?: InputMaybe<OrderBy>;
  code_text?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  is_completed?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  measure_bookmarks_aggregate?: InputMaybe<PkieMeasureBookmarkAggregateOrderBy>;
  recommendation?: InputMaybe<RecommendationsOrderBy>;
  recommendation_id?: InputMaybe<OrderBy>;
  reference?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
}

/** primary key columns input for table: bookmarks */
export interface BookmarksPkColumnsInput {
  id: Scalars['Int']['input'];
}

/** select columns of table "bookmarks" */
export enum BookmarksSelectColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "bookmarks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "bookmarks" */
export enum BookmarksSelectColumnBookmarksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "bookmarks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "bookmarks" */
export enum BookmarksSelectColumnBookmarksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "bookmarks" */
export interface BookmarksSetInput {
  assigned_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  code_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  index_id?: InputMaybe<Scalars['Int']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  recommendation_id?: InputMaybe<Scalars['uuid']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookmarkStatusEnum>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface BookmarksStddevFields {
  __typename?: 'bookmarks_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "bookmarks" */
export interface BookmarksStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface BookmarksStddevPopFields {
  __typename?: 'bookmarks_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "bookmarks" */
export interface BookmarksStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface BookmarksStddevSampFields {
  __typename?: 'bookmarks_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "bookmarks" */
export interface BookmarksStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "bookmarks" */
export interface BookmarksStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: BookmarksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface BookmarksStreamCursorValueInput {
  assigned_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  code_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  index_id?: InputMaybe<Scalars['Int']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  recommendation_id?: InputMaybe<Scalars['uuid']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookmarkStatusEnum>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface BookmarksSumFields {
  __typename?: 'bookmarks_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  index_id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "bookmarks" */
export interface BookmarksSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** update columns of table "bookmarks" */
export enum BookmarksUpdateColumn {
  /** column name */
  AssignedToUserId = 'assigned_to_user_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CodeId = 'code_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  IndexId = 'index_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  RecommendationId = 'recommendation_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface BookmarksUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BookmarksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BookmarksSetInput>;
  /** filter the rows which have to be updated */
  where: BookmarksBoolExp;
}

/** aggregate var_pop on columns */
export interface BookmarksVarPopFields {
  __typename?: 'bookmarks_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "bookmarks" */
export interface BookmarksVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface BookmarksVarSampFields {
  __typename?: 'bookmarks_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "bookmarks" */
export interface BookmarksVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface BookmarksVarianceFields {
  __typename?: 'bookmarks_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "bookmarks" */
export interface BookmarksVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export interface ByteaComparisonExp {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
}

/** calendarTasksOfChiefSecretariatNative Query Arguments */
export interface CalendarTasksOfChiefSecretariatArguments {
  chapter_id: Scalars['Int']['input'];
  user_id: Scalars['uuid']['input'];
}

/** calendarTasksOfCoordinatorNative Query Arguments */
export interface CalendarTasksOfCoordinatorArguments {
  chapter: Scalars['numeric']['input'];
  user_id: Scalars['uuid']['input'];
}

/** calendarTasksOfHorizontalCoordinatorNative Query Arguments */
export interface CalendarTasksOfHorizontalCoordinatorArguments {
  cluster_id: Scalars['Int']['input'];
  user_id: Scalars['uuid']['input'];
}

/** calendarTasksOfMemberNative Query Arguments */
export interface CalendarTasksOfMemberArguments {
  chapter_id: Scalars['Int']['input'];
  /** id of user to filter */
  user_id: Scalars['uuid']['input'];
}

/** calendarTasksOfNegotiatorNative Query Arguments */
export interface CalendarTasksOfNegotiatorArguments {
  chapter_id: Scalars['Int']['input'];
  /** id of user to filter */
  user_id: Scalars['uuid']['input'];
}

/** calendarTasksOfPMONative Query Arguments */
export interface CalendarTasksOfPmoArguments {
  /** id of user to filter */
  user_id: Scalars['uuid']['input'];
}

/** calendarTasksOfSecretariatNative Query Arguments */
export interface CalendarTasksOfSecretariatArguments {
  chapter_id: Scalars['Int']['input'];
  /** id of user to filter */
  user_id: Scalars['uuid']['input'];
}

/** keeps all project beneficiaries */
export interface CapacBeneficiaries {
  __typename?: 'capac_beneficiaries';
  created_at: Scalars['timestamptz']['output'];
  created_by_user_Id: Scalars['uuid']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  function?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  institution_id?: Maybe<Scalars['uuid']['output']>;
  number: Scalars['String']['output'];
  /** An array relationship */
  project_beneficiaries: Array<CapacProjectBeneficiaries>;
  /** An aggregate relationship */
  project_beneficiaries_aggregate: CapacProjectBeneficiariesAggregate;
  surname: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
}


/** keeps all project beneficiaries */
export interface CapacBeneficiariesProjectBeneficiariesArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


/** keeps all project beneficiaries */
export interface CapacBeneficiariesProjectBeneficiariesAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}

/** aggregated selection of "capac.beneficiaries" */
export interface CapacBeneficiariesAggregate {
  __typename?: 'capac_beneficiaries_aggregate';
  aggregate?: Maybe<CapacBeneficiariesAggregateFields>;
  nodes: Array<CapacBeneficiaries>;
}

/** aggregate fields of "capac.beneficiaries" */
export interface CapacBeneficiariesAggregateFields {
  __typename?: 'capac_beneficiaries_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacBeneficiariesMaxFields>;
  min?: Maybe<CapacBeneficiariesMinFields>;
}


/** aggregate fields of "capac.beneficiaries" */
export interface CapacBeneficiariesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacBeneficiariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "capac.beneficiaries". All fields are combined with a logical 'AND'. */
export interface CapacBeneficiariesBoolExp {
  _and?: InputMaybe<Array<CapacBeneficiariesBoolExp>>;
  _not?: InputMaybe<CapacBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<CapacBeneficiariesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_Id?: InputMaybe<UuidComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  first_name?: InputMaybe<StringComparisonExp>;
  function?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  institution_id?: InputMaybe<UuidComparisonExp>;
  number?: InputMaybe<StringComparisonExp>;
  project_beneficiaries?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<CapacProjectBeneficiariesAggregateBoolExp>;
  surname?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.beneficiaries" */
export enum CapacBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  BeneficiariesPkey = 'beneficiaries_pkey'
}

/** input type for inserting data into table "capac.beneficiaries" */
export interface CapacBeneficiariesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_Id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<CapacProjectBeneficiariesArrRelInsertInput>;
  surname?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacBeneficiariesMaxFields {
  __typename?: 'capac_beneficiaries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_Id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface CapacBeneficiariesMinFields {
  __typename?: 'capac_beneficiaries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_Id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "capac.beneficiaries" */
export interface CapacBeneficiariesMutationResponse {
  __typename?: 'capac_beneficiaries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacBeneficiaries>;
}

/** input type for inserting object relation for remote table "capac.beneficiaries" */
export interface CapacBeneficiariesObjRelInsertInput {
  data: CapacBeneficiariesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacBeneficiariesOnConflict>;
}

/** on_conflict condition type for table "capac.beneficiaries" */
export interface CapacBeneficiariesOnConflict {
  constraint: CapacBeneficiariesConstraint;
  update_columns?: Array<CapacBeneficiariesUpdateColumn>;
  where?: InputMaybe<CapacBeneficiariesBoolExp>;
}

/** Ordering options when selecting data from "capac.beneficiaries". */
export interface CapacBeneficiariesOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_Id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  first_name?: InputMaybe<OrderBy>;
  function?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  project_beneficiaries_aggregate?: InputMaybe<CapacProjectBeneficiariesAggregateOrderBy>;
  surname?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.beneficiaries */
export interface CapacBeneficiariesPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.beneficiaries" */
export enum CapacBeneficiariesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_Id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Function = 'function',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  Number = 'number',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.beneficiaries" */
export interface CapacBeneficiariesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_Id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_beneficiaries" */
export interface CapacBeneficiariesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacBeneficiariesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacBeneficiariesStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_Id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.beneficiaries" */
export enum CapacBeneficiariesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_Id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Function = 'function',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  Number = 'number',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacBeneficiariesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacBeneficiariesSetInput>;
  /** filter the rows which have to be updated */
  where: CapacBeneficiariesBoolExp;
}

/** maintains many to many relations and specifics */
export interface CapacChapterDonator {
  __typename?: 'capac_chapter_donator';
  /** An object relationship */
  chapter: Chapters;
  chapter_id: Scalars['Int']['output'];
  /** An object relationship */
  donator: CapacDonators;
  donator_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
}

/** aggregated selection of "capac.chapter_donator" */
export interface CapacChapterDonatorAggregate {
  __typename?: 'capac_chapter_donator_aggregate';
  aggregate?: Maybe<CapacChapterDonatorAggregateFields>;
  nodes: Array<CapacChapterDonator>;
}

export interface CapacChapterDonatorAggregateBoolExp {
  count?: InputMaybe<CapacChapterDonatorAggregateBoolExpCount>;
}

export interface CapacChapterDonatorAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacChapterDonatorBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.chapter_donator" */
export interface CapacChapterDonatorAggregateFields {
  __typename?: 'capac_chapter_donator_aggregate_fields';
  avg?: Maybe<CapacChapterDonatorAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CapacChapterDonatorMaxFields>;
  min?: Maybe<CapacChapterDonatorMinFields>;
  stddev?: Maybe<CapacChapterDonatorStddevFields>;
  stddev_pop?: Maybe<CapacChapterDonatorStddevPopFields>;
  stddev_samp?: Maybe<CapacChapterDonatorStddevSampFields>;
  sum?: Maybe<CapacChapterDonatorSumFields>;
  var_pop?: Maybe<CapacChapterDonatorVarPopFields>;
  var_samp?: Maybe<CapacChapterDonatorVarSampFields>;
  variance?: Maybe<CapacChapterDonatorVarianceFields>;
}


/** aggregate fields of "capac.chapter_donator" */
export interface CapacChapterDonatorAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.chapter_donator" */
export interface CapacChapterDonatorAggregateOrderBy {
  avg?: InputMaybe<CapacChapterDonatorAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacChapterDonatorMaxOrderBy>;
  min?: InputMaybe<CapacChapterDonatorMinOrderBy>;
  stddev?: InputMaybe<CapacChapterDonatorStddevOrderBy>;
  stddev_pop?: InputMaybe<CapacChapterDonatorStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CapacChapterDonatorStddevSampOrderBy>;
  sum?: InputMaybe<CapacChapterDonatorSumOrderBy>;
  var_pop?: InputMaybe<CapacChapterDonatorVarPopOrderBy>;
  var_samp?: InputMaybe<CapacChapterDonatorVarSampOrderBy>;
  variance?: InputMaybe<CapacChapterDonatorVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "capac.chapter_donator" */
export interface CapacChapterDonatorArrRelInsertInput {
  data: Array<CapacChapterDonatorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacChapterDonatorOnConflict>;
}

/** aggregate avg on columns */
export interface CapacChapterDonatorAvgFields {
  __typename?: 'capac_chapter_donator_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "capac.chapter_donator". All fields are combined with a logical 'AND'. */
export interface CapacChapterDonatorBoolExp {
  _and?: InputMaybe<Array<CapacChapterDonatorBoolExp>>;
  _not?: InputMaybe<CapacChapterDonatorBoolExp>;
  _or?: InputMaybe<Array<CapacChapterDonatorBoolExp>>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  donator?: InputMaybe<CapacDonatorsBoolExp>;
  donator_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "capac.chapter_donator" */
export enum CapacChapterDonatorConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterDonatorPkey = 'chapter_donator_pkey'
}

/** input type for incrementing numeric columns in table "capac.chapter_donator" */
export interface CapacChapterDonatorIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "capac.chapter_donator" */
export interface CapacChapterDonatorInsertInput {
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  donator?: InputMaybe<CapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface CapacChapterDonatorMaxFields {
  __typename?: 'capac_chapter_donator_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  donator_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  donator_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacChapterDonatorMinFields {
  __typename?: 'capac_chapter_donator_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  donator_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  donator_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.chapter_donator" */
export interface CapacChapterDonatorMutationResponse {
  __typename?: 'capac_chapter_donator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacChapterDonator>;
}

/** on_conflict condition type for table "capac.chapter_donator" */
export interface CapacChapterDonatorOnConflict {
  constraint: CapacChapterDonatorConstraint;
  update_columns?: Array<CapacChapterDonatorUpdateColumn>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}

/** Ordering options when selecting data from "capac.chapter_donator". */
export interface CapacChapterDonatorOrderBy {
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  donator?: InputMaybe<CapacDonatorsOrderBy>;
  donator_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.chapter_donator */
export interface CapacChapterDonatorPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.chapter_donator" */
export enum CapacChapterDonatorSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "capac.chapter_donator" */
export interface CapacChapterDonatorSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface CapacChapterDonatorStddevFields {
  __typename?: 'capac_chapter_donator_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface CapacChapterDonatorStddevPopFields {
  __typename?: 'capac_chapter_donator_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface CapacChapterDonatorStddevSampFields {
  __typename?: 'capac_chapter_donator_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "capac_chapter_donator" */
export interface CapacChapterDonatorStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacChapterDonatorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacChapterDonatorStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface CapacChapterDonatorSumFields {
  __typename?: 'capac_chapter_donator_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** update columns of table "capac.chapter_donator" */
export enum CapacChapterDonatorUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  Id = 'id'
}

export interface CapacChapterDonatorUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CapacChapterDonatorIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacChapterDonatorSetInput>;
  /** filter the rows which have to be updated */
  where: CapacChapterDonatorBoolExp;
}

/** aggregate var_pop on columns */
export interface CapacChapterDonatorVarPopFields {
  __typename?: 'capac_chapter_donator_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface CapacChapterDonatorVarSampFields {
  __typename?: 'capac_chapter_donator_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface CapacChapterDonatorVarianceFields {
  __typename?: 'capac_chapter_donator_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "capac.chapter_donator" */
export interface CapacChapterDonatorVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** list of donators */
export interface CapacDonators {
  __typename?: 'capac_donators';
  address?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  chapter_donators: Array<CapacChapterDonator>;
  /** An aggregate relationship */
  chapter_donators_aggregate: CapacChapterDonatorAggregate;
  contact_email: Scalars['String']['output'];
  contact_function?: Maybe<Scalars['String']['output']>;
  contact_person: Scalars['String']['output'];
  contact_phone: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  integration_steps_supported?: Maybe<Array<Scalars['numeric']['output']>>;
  is_deleted: Scalars['Boolean']['output'];
  organisation: Scalars['String']['output'];
  planned_new_support?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  projects: Array<CapacProjects>;
  /** An aggregate relationship */
  projects_aggregate: CapacProjectsAggregate;
  suggestions?: Maybe<Scalars['String']['output']>;
  supports_accelerated_integration?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}


/** list of donators */
export interface CapacDonatorsChapterDonatorsArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


/** list of donators */
export interface CapacDonatorsChapterDonatorsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


/** list of donators */
export interface CapacDonatorsProjectsArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


/** list of donators */
export interface CapacDonatorsProjectsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}

/** aggregated selection of "capac.donators" */
export interface CapacDonatorsAggregate {
  __typename?: 'capac_donators_aggregate';
  aggregate?: Maybe<CapacDonatorsAggregateFields>;
  nodes: Array<CapacDonators>;
}

/** aggregate fields of "capac.donators" */
export interface CapacDonatorsAggregateFields {
  __typename?: 'capac_donators_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacDonatorsMaxFields>;
  min?: Maybe<CapacDonatorsMinFields>;
}


/** aggregate fields of "capac.donators" */
export interface CapacDonatorsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacDonatorsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "capac.donators". All fields are combined with a logical 'AND'. */
export interface CapacDonatorsBoolExp {
  _and?: InputMaybe<Array<CapacDonatorsBoolExp>>;
  _not?: InputMaybe<CapacDonatorsBoolExp>;
  _or?: InputMaybe<Array<CapacDonatorsBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  chapter_donators?: InputMaybe<CapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<CapacChapterDonatorAggregateBoolExp>;
  contact_email?: InputMaybe<StringComparisonExp>;
  contact_function?: InputMaybe<StringComparisonExp>;
  contact_person?: InputMaybe<StringComparisonExp>;
  contact_phone?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  integration_steps_supported?: InputMaybe<NumericArrayComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  organisation?: InputMaybe<StringComparisonExp>;
  planned_new_support?: InputMaybe<StringComparisonExp>;
  projects?: InputMaybe<CapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<CapacProjectsAggregateBoolExp>;
  suggestions?: InputMaybe<StringComparisonExp>;
  supports_accelerated_integration?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.donators" */
export enum CapacDonatorsConstraint {
  /** unique or primary key constraint on columns "id" */
  DonatorsPkey = 'donators_pkey'
}

/** input type for inserting data into table "capac.donators" */
export interface CapacDonatorsInsertInput {
  address?: InputMaybe<Scalars['String']['input']>;
  chapter_donators?: InputMaybe<CapacChapterDonatorArrRelInsertInput>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_function?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps_supported?: InputMaybe<Array<Scalars['numeric']['input']>>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<CapacProjectsArrRelInsertInput>;
  suggestions?: InputMaybe<Scalars['String']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacDonatorsMaxFields {
  __typename?: 'capac_donators_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_function?: Maybe<Scalars['String']['output']>;
  contact_person?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_steps_supported?: Maybe<Array<Scalars['numeric']['output']>>;
  organisation?: Maybe<Scalars['String']['output']>;
  planned_new_support?: Maybe<Scalars['String']['output']>;
  suggestions?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface CapacDonatorsMinFields {
  __typename?: 'capac_donators_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_function?: Maybe<Scalars['String']['output']>;
  contact_person?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_steps_supported?: Maybe<Array<Scalars['numeric']['output']>>;
  organisation?: Maybe<Scalars['String']['output']>;
  planned_new_support?: Maybe<Scalars['String']['output']>;
  suggestions?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "capac.donators" */
export interface CapacDonatorsMutationResponse {
  __typename?: 'capac_donators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacDonators>;
}

/** input type for inserting object relation for remote table "capac.donators" */
export interface CapacDonatorsObjRelInsertInput {
  data: CapacDonatorsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacDonatorsOnConflict>;
}

/** on_conflict condition type for table "capac.donators" */
export interface CapacDonatorsOnConflict {
  constraint: CapacDonatorsConstraint;
  update_columns?: Array<CapacDonatorsUpdateColumn>;
  where?: InputMaybe<CapacDonatorsBoolExp>;
}

/** Ordering options when selecting data from "capac.donators". */
export interface CapacDonatorsOrderBy {
  address?: InputMaybe<OrderBy>;
  chapter_donators_aggregate?: InputMaybe<CapacChapterDonatorAggregateOrderBy>;
  contact_email?: InputMaybe<OrderBy>;
  contact_function?: InputMaybe<OrderBy>;
  contact_person?: InputMaybe<OrderBy>;
  contact_phone?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integration_steps_supported?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  organisation?: InputMaybe<OrderBy>;
  planned_new_support?: InputMaybe<OrderBy>;
  projects_aggregate?: InputMaybe<CapacProjectsAggregateOrderBy>;
  suggestions?: InputMaybe<OrderBy>;
  supports_accelerated_integration?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.donators */
export interface CapacDonatorsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.donators" */
export enum CapacDonatorsSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFunction = 'contact_function',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactPhone = 'contact_phone',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationStepsSupported = 'integration_steps_supported',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  Suggestions = 'suggestions',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.donators" */
export interface CapacDonatorsSetInput {
  address?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_function?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps_supported?: InputMaybe<Array<Scalars['numeric']['input']>>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  suggestions?: InputMaybe<Scalars['String']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_donators" */
export interface CapacDonatorsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacDonatorsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacDonatorsStreamCursorValueInput {
  address?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_function?: InputMaybe<Scalars['String']['input']>;
  contact_person?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps_supported?: InputMaybe<Array<Scalars['numeric']['input']>>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  suggestions?: InputMaybe<Scalars['String']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.donators" */
export enum CapacDonatorsUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFunction = 'contact_function',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactPhone = 'contact_phone',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationStepsSupported = 'integration_steps_supported',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  Suggestions = 'suggestions',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacDonatorsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacDonatorsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacDonatorsBoolExp;
}

/** list of experts */
export interface CapacExperts {
  __typename?: 'capac_experts';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  email: Scalars['String']['output'];
  expertise?: Maybe<Scalars['String']['output']>;
  full_name: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  trainings_experts: Array<CapacTrainingsExperts>;
  /** An aggregate relationship */
  trainings_experts_aggregate: CapacTrainingsExpertsAggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}


/** list of experts */
export interface CapacExpertsTrainingsExpertsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


/** list of experts */
export interface CapacExpertsTrainingsExpertsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}

/** aggregated selection of "capac.experts" */
export interface CapacExpertsAggregate {
  __typename?: 'capac_experts_aggregate';
  aggregate?: Maybe<CapacExpertsAggregateFields>;
  nodes: Array<CapacExperts>;
}

/** aggregate fields of "capac.experts" */
export interface CapacExpertsAggregateFields {
  __typename?: 'capac_experts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacExpertsMaxFields>;
  min?: Maybe<CapacExpertsMinFields>;
}


/** aggregate fields of "capac.experts" */
export interface CapacExpertsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacExpertsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "capac.experts". All fields are combined with a logical 'AND'. */
export interface CapacExpertsBoolExp {
  _and?: InputMaybe<Array<CapacExpertsBoolExp>>;
  _not?: InputMaybe<CapacExpertsBoolExp>;
  _or?: InputMaybe<Array<CapacExpertsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  expertise?: InputMaybe<StringComparisonExp>;
  full_name?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<StringComparisonExp>;
  trainings_experts?: InputMaybe<CapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<CapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.experts" */
export enum CapacExpertsConstraint {
  /** unique or primary key constraint on columns "full_name" */
  ExpertsFullNameKey = 'experts_full_name_key',
  /** unique or primary key constraint on columns "id" */
  ExpertsPkey = 'experts_pkey'
}

/** input type for inserting data into table "capac.experts" */
export interface CapacExpertsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  trainings_experts?: InputMaybe<CapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacExpertsMaxFields {
  __typename?: 'capac_experts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expertise?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface CapacExpertsMinFields {
  __typename?: 'capac_experts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expertise?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "capac.experts" */
export interface CapacExpertsMutationResponse {
  __typename?: 'capac_experts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacExperts>;
}

/** input type for inserting object relation for remote table "capac.experts" */
export interface CapacExpertsObjRelInsertInput {
  data: CapacExpertsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacExpertsOnConflict>;
}

/** on_conflict condition type for table "capac.experts" */
export interface CapacExpertsOnConflict {
  constraint: CapacExpertsConstraint;
  update_columns?: Array<CapacExpertsUpdateColumn>;
  where?: InputMaybe<CapacExpertsBoolExp>;
}

/** Ordering options when selecting data from "capac.experts". */
export interface CapacExpertsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  expertise?: InputMaybe<OrderBy>;
  full_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  trainings_experts_aggregate?: InputMaybe<CapacTrainingsExpertsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.experts */
export interface CapacExpertsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.experts" */
export enum CapacExpertsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expertise = 'expertise',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.experts" */
export interface CapacExpertsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_experts" */
export interface CapacExpertsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacExpertsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacExpertsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.experts" */
export enum CapacExpertsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Email = 'email',
  /** column name */
  Expertise = 'expertise',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacExpertsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacExpertsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacExpertsBoolExp;
}

/** enum containing all the support processes from donators for specific chapters */
export interface CapacIntegrationSteps {
  __typename?: 'capac_integration_steps';
  /** An array relationship */
  children: Array<CapacIntegrationSteps>;
  /** An aggregate relationship */
  children_aggregate: CapacIntegrationStepsAggregate;
  id: Scalars['numeric']['output'];
  /** An object relationship */
  integration_step?: Maybe<CapacIntegrationSteps>;
  parent_id?: Maybe<Scalars['numeric']['output']>;
  process: Scalars['String']['output'];
}


/** enum containing all the support processes from donators for specific chapters */
export interface CapacIntegrationStepsChildrenArgs {
  distinct_on?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacIntegrationStepsOrderBy>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}


/** enum containing all the support processes from donators for specific chapters */
export interface CapacIntegrationStepsChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacIntegrationStepsOrderBy>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}

/** aggregated selection of "capac.integration_steps" */
export interface CapacIntegrationStepsAggregate {
  __typename?: 'capac_integration_steps_aggregate';
  aggregate?: Maybe<CapacIntegrationStepsAggregateFields>;
  nodes: Array<CapacIntegrationSteps>;
}

export interface CapacIntegrationStepsAggregateBoolExp {
  count?: InputMaybe<CapacIntegrationStepsAggregateBoolExpCount>;
}

export interface CapacIntegrationStepsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacIntegrationStepsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.integration_steps" */
export interface CapacIntegrationStepsAggregateFields {
  __typename?: 'capac_integration_steps_aggregate_fields';
  avg?: Maybe<CapacIntegrationStepsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CapacIntegrationStepsMaxFields>;
  min?: Maybe<CapacIntegrationStepsMinFields>;
  stddev?: Maybe<CapacIntegrationStepsStddevFields>;
  stddev_pop?: Maybe<CapacIntegrationStepsStddevPopFields>;
  stddev_samp?: Maybe<CapacIntegrationStepsStddevSampFields>;
  sum?: Maybe<CapacIntegrationStepsSumFields>;
  var_pop?: Maybe<CapacIntegrationStepsVarPopFields>;
  var_samp?: Maybe<CapacIntegrationStepsVarSampFields>;
  variance?: Maybe<CapacIntegrationStepsVarianceFields>;
}


/** aggregate fields of "capac.integration_steps" */
export interface CapacIntegrationStepsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.integration_steps" */
export interface CapacIntegrationStepsAggregateOrderBy {
  avg?: InputMaybe<CapacIntegrationStepsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacIntegrationStepsMaxOrderBy>;
  min?: InputMaybe<CapacIntegrationStepsMinOrderBy>;
  stddev?: InputMaybe<CapacIntegrationStepsStddevOrderBy>;
  stddev_pop?: InputMaybe<CapacIntegrationStepsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CapacIntegrationStepsStddevSampOrderBy>;
  sum?: InputMaybe<CapacIntegrationStepsSumOrderBy>;
  var_pop?: InputMaybe<CapacIntegrationStepsVarPopOrderBy>;
  var_samp?: InputMaybe<CapacIntegrationStepsVarSampOrderBy>;
  variance?: InputMaybe<CapacIntegrationStepsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "capac.integration_steps" */
export interface CapacIntegrationStepsArrRelInsertInput {
  data: Array<CapacIntegrationStepsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacIntegrationStepsOnConflict>;
}

/** aggregate avg on columns */
export interface CapacIntegrationStepsAvgFields {
  __typename?: 'capac_integration_steps_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsAvgOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "capac.integration_steps". All fields are combined with a logical 'AND'. */
export interface CapacIntegrationStepsBoolExp {
  _and?: InputMaybe<Array<CapacIntegrationStepsBoolExp>>;
  _not?: InputMaybe<CapacIntegrationStepsBoolExp>;
  _or?: InputMaybe<Array<CapacIntegrationStepsBoolExp>>;
  children?: InputMaybe<CapacIntegrationStepsBoolExp>;
  children_aggregate?: InputMaybe<CapacIntegrationStepsAggregateBoolExp>;
  id?: InputMaybe<NumericComparisonExp>;
  integration_step?: InputMaybe<CapacIntegrationStepsBoolExp>;
  parent_id?: InputMaybe<NumericComparisonExp>;
  process?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "capac.integration_steps" */
export enum CapacIntegrationStepsConstraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationStepsIdKey = 'integration_steps_id_key',
  /** unique or primary key constraint on columns "id" */
  IntegrationStepsPkey = 'integration_steps_pkey'
}

/** input type for incrementing numeric columns in table "capac.integration_steps" */
export interface CapacIntegrationStepsIncInput {
  id?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "capac.integration_steps" */
export interface CapacIntegrationStepsInsertInput {
  children?: InputMaybe<CapacIntegrationStepsArrRelInsertInput>;
  id?: InputMaybe<Scalars['numeric']['input']>;
  integration_step?: InputMaybe<CapacIntegrationStepsObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['numeric']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface CapacIntegrationStepsMaxFields {
  __typename?: 'capac_integration_steps_max_fields';
  id?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['numeric']['output']>;
  process?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsMaxOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  process?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacIntegrationStepsMinFields {
  __typename?: 'capac_integration_steps_min_fields';
  id?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['numeric']['output']>;
  process?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsMinOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  process?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.integration_steps" */
export interface CapacIntegrationStepsMutationResponse {
  __typename?: 'capac_integration_steps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacIntegrationSteps>;
}

/** input type for inserting object relation for remote table "capac.integration_steps" */
export interface CapacIntegrationStepsObjRelInsertInput {
  data: CapacIntegrationStepsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacIntegrationStepsOnConflict>;
}

/** on_conflict condition type for table "capac.integration_steps" */
export interface CapacIntegrationStepsOnConflict {
  constraint: CapacIntegrationStepsConstraint;
  update_columns?: Array<CapacIntegrationStepsUpdateColumn>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}

/** Ordering options when selecting data from "capac.integration_steps". */
export interface CapacIntegrationStepsOrderBy {
  children_aggregate?: InputMaybe<CapacIntegrationStepsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  integration_step?: InputMaybe<CapacIntegrationStepsOrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  process?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.integration_steps */
export interface CapacIntegrationStepsPkColumnsInput {
  id: Scalars['numeric']['input'];
}

/** select columns of table "capac.integration_steps" */
export enum CapacIntegrationStepsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Process = 'process'
}

/** input type for updating data in table "capac.integration_steps" */
export interface CapacIntegrationStepsSetInput {
  id?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['numeric']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface CapacIntegrationStepsStddevFields {
  __typename?: 'capac_integration_steps_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsStddevOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface CapacIntegrationStepsStddevPopFields {
  __typename?: 'capac_integration_steps_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsStddevPopOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface CapacIntegrationStepsStddevSampFields {
  __typename?: 'capac_integration_steps_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsStddevSampOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "capac_integration_steps" */
export interface CapacIntegrationStepsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacIntegrationStepsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacIntegrationStepsStreamCursorValueInput {
  id?: InputMaybe<Scalars['numeric']['input']>;
  parent_id?: InputMaybe<Scalars['numeric']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface CapacIntegrationStepsSumFields {
  __typename?: 'capac_integration_steps_sum_fields';
  id?: Maybe<Scalars['numeric']['output']>;
  parent_id?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsSumOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** update columns of table "capac.integration_steps" */
export enum CapacIntegrationStepsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Process = 'process'
}

export interface CapacIntegrationStepsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CapacIntegrationStepsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacIntegrationStepsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacIntegrationStepsBoolExp;
}

/** aggregate var_pop on columns */
export interface CapacIntegrationStepsVarPopFields {
  __typename?: 'capac_integration_steps_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsVarPopOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface CapacIntegrationStepsVarSampFields {
  __typename?: 'capac_integration_steps_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsVarSampOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface CapacIntegrationStepsVarianceFields {
  __typename?: 'capac_integration_steps_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "capac.integration_steps" */
export interface CapacIntegrationStepsVarianceOrderBy {
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** list of priority lists */
export interface CapacPriorityLists {
  __typename?: 'capac_priority_lists';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  end_date: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  start_date: Scalars['date']['output'];
  title?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  training_needs: Array<CapacTrainingNeeds>;
  /** An aggregate relationship */
  training_needs_aggregate: CapacTrainingNeedsAggregate;
  updated_at: Scalars['timestamptz']['output'];
}


/** list of priority lists */
export interface CapacPriorityListsTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** list of priority lists */
export interface CapacPriorityListsTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}

/** aggregated selection of "capac.priority_lists" */
export interface CapacPriorityListsAggregate {
  __typename?: 'capac_priority_lists_aggregate';
  aggregate?: Maybe<CapacPriorityListsAggregateFields>;
  nodes: Array<CapacPriorityLists>;
}

/** aggregate fields of "capac.priority_lists" */
export interface CapacPriorityListsAggregateFields {
  __typename?: 'capac_priority_lists_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacPriorityListsMaxFields>;
  min?: Maybe<CapacPriorityListsMinFields>;
}


/** aggregate fields of "capac.priority_lists" */
export interface CapacPriorityListsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacPriorityListsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "capac.priority_lists". All fields are combined with a logical 'AND'. */
export interface CapacPriorityListsBoolExp {
  _and?: InputMaybe<Array<CapacPriorityListsBoolExp>>;
  _not?: InputMaybe<CapacPriorityListsBoolExp>;
  _or?: InputMaybe<Array<CapacPriorityListsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  end_date?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  start_date?: InputMaybe<DateComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  training_needs?: InputMaybe<CapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.priority_lists" */
export enum CapacPriorityListsConstraint {
  /** unique or primary key constraint on columns "id" */
  PriorityListsPkey = 'priority_lists_pkey'
}

/** input type for inserting data into table "capac.priority_lists" */
export interface CapacPriorityListsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<CapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacPriorityListsMaxFields {
  __typename?: 'capac_priority_lists_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface CapacPriorityListsMinFields {
  __typename?: 'capac_priority_lists_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "capac.priority_lists" */
export interface CapacPriorityListsMutationResponse {
  __typename?: 'capac_priority_lists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacPriorityLists>;
}

/** input type for inserting object relation for remote table "capac.priority_lists" */
export interface CapacPriorityListsObjRelInsertInput {
  data: CapacPriorityListsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacPriorityListsOnConflict>;
}

/** on_conflict condition type for table "capac.priority_lists" */
export interface CapacPriorityListsOnConflict {
  constraint: CapacPriorityListsConstraint;
  update_columns?: Array<CapacPriorityListsUpdateColumn>;
  where?: InputMaybe<CapacPriorityListsBoolExp>;
}

/** Ordering options when selecting data from "capac.priority_lists". */
export interface CapacPriorityListsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.priority_lists */
export interface CapacPriorityListsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.priority_lists" */
export enum CapacPriorityListsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.priority_lists" */
export interface CapacPriorityListsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_priority_lists" */
export interface CapacPriorityListsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacPriorityListsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacPriorityListsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.priority_lists" */
export enum CapacPriorityListsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacPriorityListsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacPriorityListsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacPriorityListsBoolExp;
}

/** contains beneficiaries for projects and vice versa */
export interface CapacProjectBeneficiaries {
  __typename?: 'capac_project_beneficiaries';
  /** An object relationship */
  beneficiary: CapacBeneficiaries;
  beneficiary_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  project: CapacProjects;
  project_id: Scalars['uuid']['output'];
}

/** aggregated selection of "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesAggregate {
  __typename?: 'capac_project_beneficiaries_aggregate';
  aggregate?: Maybe<CapacProjectBeneficiariesAggregateFields>;
  nodes: Array<CapacProjectBeneficiaries>;
}

export interface CapacProjectBeneficiariesAggregateBoolExp {
  count?: InputMaybe<CapacProjectBeneficiariesAggregateBoolExpCount>;
}

export interface CapacProjectBeneficiariesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesAggregateFields {
  __typename?: 'capac_project_beneficiaries_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacProjectBeneficiariesMaxFields>;
  min?: Maybe<CapacProjectBeneficiariesMinFields>;
}


/** aggregate fields of "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacProjectBeneficiariesMaxOrderBy>;
  min?: InputMaybe<CapacProjectBeneficiariesMinOrderBy>;
}

/** input type for inserting array relation for remote table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesArrRelInsertInput {
  data: Array<CapacProjectBeneficiariesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacProjectBeneficiariesOnConflict>;
}

/** Boolean expression to filter rows from the table "capac.project_beneficiaries". All fields are combined with a logical 'AND'. */
export interface CapacProjectBeneficiariesBoolExp {
  _and?: InputMaybe<Array<CapacProjectBeneficiariesBoolExp>>;
  _not?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
  _or?: InputMaybe<Array<CapacProjectBeneficiariesBoolExp>>;
  beneficiary?: InputMaybe<CapacBeneficiariesBoolExp>;
  beneficiary_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  project?: InputMaybe<CapacProjectsBoolExp>;
  project_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "capac.project_beneficiaries" */
export enum CapacProjectBeneficiariesConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectBeneficiariesPkey = 'project_beneficiaries_pkey'
}

/** input type for inserting data into table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesInsertInput {
  beneficiary?: InputMaybe<CapacBeneficiariesObjRelInsertInput>;
  beneficiary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  project?: InputMaybe<CapacProjectsObjRelInsertInput>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface CapacProjectBeneficiariesMaxFields {
  __typename?: 'capac_project_beneficiaries_max_fields';
  beneficiary_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesMaxOrderBy {
  beneficiary_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  project_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacProjectBeneficiariesMinFields {
  __typename?: 'capac_project_beneficiaries_min_fields';
  beneficiary_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  project_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesMinOrderBy {
  beneficiary_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  project_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesMutationResponse {
  __typename?: 'capac_project_beneficiaries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacProjectBeneficiaries>;
}

/** on_conflict condition type for table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesOnConflict {
  constraint: CapacProjectBeneficiariesConstraint;
  update_columns?: Array<CapacProjectBeneficiariesUpdateColumn>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}

/** Ordering options when selecting data from "capac.project_beneficiaries". */
export interface CapacProjectBeneficiariesOrderBy {
  beneficiary?: InputMaybe<CapacBeneficiariesOrderBy>;
  beneficiary_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  project?: InputMaybe<CapacProjectsOrderBy>;
  project_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.project_beneficiaries */
export interface CapacProjectBeneficiariesPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.project_beneficiaries" */
export enum CapacProjectBeneficiariesSelectColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "capac.project_beneficiaries" */
export interface CapacProjectBeneficiariesSetInput {
  beneficiary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "capac_project_beneficiaries" */
export interface CapacProjectBeneficiariesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacProjectBeneficiariesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacProjectBeneficiariesStreamCursorValueInput {
  beneficiary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  project_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "capac.project_beneficiaries" */
export enum CapacProjectBeneficiariesUpdateColumn {
  /** column name */
  BeneficiaryId = 'beneficiary_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export interface CapacProjectBeneficiariesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacProjectBeneficiariesSetInput>;
  /** filter the rows which have to be updated */
  where: CapacProjectBeneficiariesBoolExp;
}

/** list of projects from eu */
export interface CapacProjects {
  __typename?: 'capac_projects';
  activities?: Maybe<Scalars['String']['output']>;
  beneficiary?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  disbursement?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  donator?: Maybe<CapacDonators>;
  donator_id?: Maybe<Scalars['uuid']['output']>;
  end_date: Scalars['date']['output'];
  expected_results?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_deleted: Scalars['Boolean']['output'];
  last_disbusement_date?: Maybe<Scalars['date']['output']>;
  other_suggestions?: Maybe<Scalars['String']['output']>;
  outputs_delivered?: Maybe<Scalars['String']['output']>;
  planned_new_support?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  project_beneficiaries: Array<CapacProjectBeneficiaries>;
  /** An aggregate relationship */
  project_beneficiaries_aggregate: CapacProjectBeneficiariesAggregate;
  start_date: Scalars['date']['output'];
  supports_accelerated_integration?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  value?: Maybe<Scalars['numeric']['output']>;
}


/** list of projects from eu */
export interface CapacProjectsProjectBeneficiariesArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


/** list of projects from eu */
export interface CapacProjectsProjectBeneficiariesAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}

/** aggregated selection of "capac.projects" */
export interface CapacProjectsAggregate {
  __typename?: 'capac_projects_aggregate';
  aggregate?: Maybe<CapacProjectsAggregateFields>;
  nodes: Array<CapacProjects>;
}

export interface CapacProjectsAggregateBoolExp {
  bool_and?: InputMaybe<CapacProjectsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CapacProjectsAggregateBoolExpBoolOr>;
  count?: InputMaybe<CapacProjectsAggregateBoolExpCount>;
}

export interface CapacProjectsAggregateBoolExpBoolAnd {
  arguments: CapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacProjectsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface CapacProjectsAggregateBoolExpBoolOr {
  arguments: CapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacProjectsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface CapacProjectsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacProjectsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.projects" */
export interface CapacProjectsAggregateFields {
  __typename?: 'capac_projects_aggregate_fields';
  avg?: Maybe<CapacProjectsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CapacProjectsMaxFields>;
  min?: Maybe<CapacProjectsMinFields>;
  stddev?: Maybe<CapacProjectsStddevFields>;
  stddev_pop?: Maybe<CapacProjectsStddevPopFields>;
  stddev_samp?: Maybe<CapacProjectsStddevSampFields>;
  sum?: Maybe<CapacProjectsSumFields>;
  var_pop?: Maybe<CapacProjectsVarPopFields>;
  var_samp?: Maybe<CapacProjectsVarSampFields>;
  variance?: Maybe<CapacProjectsVarianceFields>;
}


/** aggregate fields of "capac.projects" */
export interface CapacProjectsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.projects" */
export interface CapacProjectsAggregateOrderBy {
  avg?: InputMaybe<CapacProjectsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacProjectsMaxOrderBy>;
  min?: InputMaybe<CapacProjectsMinOrderBy>;
  stddev?: InputMaybe<CapacProjectsStddevOrderBy>;
  stddev_pop?: InputMaybe<CapacProjectsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CapacProjectsStddevSampOrderBy>;
  sum?: InputMaybe<CapacProjectsSumOrderBy>;
  var_pop?: InputMaybe<CapacProjectsVarPopOrderBy>;
  var_samp?: InputMaybe<CapacProjectsVarSampOrderBy>;
  variance?: InputMaybe<CapacProjectsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "capac.projects" */
export interface CapacProjectsArrRelInsertInput {
  data: Array<CapacProjectsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacProjectsOnConflict>;
}

/** aggregate avg on columns */
export interface CapacProjectsAvgFields {
  __typename?: 'capac_projects_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "capac.projects" */
export interface CapacProjectsAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "capac.projects". All fields are combined with a logical 'AND'. */
export interface CapacProjectsBoolExp {
  _and?: InputMaybe<Array<CapacProjectsBoolExp>>;
  _not?: InputMaybe<CapacProjectsBoolExp>;
  _or?: InputMaybe<Array<CapacProjectsBoolExp>>;
  activities?: InputMaybe<StringComparisonExp>;
  beneficiary?: InputMaybe<StringComparisonExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  disbursement?: InputMaybe<NumericComparisonExp>;
  donator?: InputMaybe<CapacDonatorsBoolExp>;
  donator_id?: InputMaybe<UuidComparisonExp>;
  end_date?: InputMaybe<DateComparisonExp>;
  expected_results?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  last_disbusement_date?: InputMaybe<DateComparisonExp>;
  other_suggestions?: InputMaybe<StringComparisonExp>;
  outputs_delivered?: InputMaybe<StringComparisonExp>;
  planned_new_support?: InputMaybe<StringComparisonExp>;
  project_beneficiaries?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
  project_beneficiaries_aggregate?: InputMaybe<CapacProjectBeneficiariesAggregateBoolExp>;
  start_date?: InputMaybe<DateComparisonExp>;
  supports_accelerated_integration?: InputMaybe<BooleanComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
}

/** unique or primary key constraints on table "capac.projects" */
export enum CapacProjectsConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

/** input type for incrementing numeric columns in table "capac.projects" */
export interface CapacProjectsIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "capac.projects" */
export interface CapacProjectsInsertInput {
  activities?: InputMaybe<Scalars['String']['input']>;
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  donator?: InputMaybe<CapacDonatorsObjRelInsertInput>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  expected_results?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  last_disbusement_date?: InputMaybe<Scalars['date']['input']>;
  other_suggestions?: InputMaybe<Scalars['String']['input']>;
  outputs_delivered?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  project_beneficiaries?: InputMaybe<CapacProjectBeneficiariesArrRelInsertInput>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

/** aggregate max on columns */
export interface CapacProjectsMaxFields {
  __typename?: 'capac_projects_max_fields';
  activities?: Maybe<Scalars['String']['output']>;
  beneficiary?: Maybe<Scalars['String']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  disbursement?: Maybe<Scalars['numeric']['output']>;
  donator_id?: Maybe<Scalars['uuid']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  expected_results?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_disbusement_date?: Maybe<Scalars['date']['output']>;
  other_suggestions?: Maybe<Scalars['String']['output']>;
  outputs_delivered?: Maybe<Scalars['String']['output']>;
  planned_new_support?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
}

/** order by max() on columns of table "capac.projects" */
export interface CapacProjectsMaxOrderBy {
  activities?: InputMaybe<OrderBy>;
  beneficiary?: InputMaybe<OrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  donator_id?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  expected_results?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  last_disbusement_date?: InputMaybe<OrderBy>;
  other_suggestions?: InputMaybe<OrderBy>;
  outputs_delivered?: InputMaybe<OrderBy>;
  planned_new_support?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacProjectsMinFields {
  __typename?: 'capac_projects_min_fields';
  activities?: Maybe<Scalars['String']['output']>;
  beneficiary?: Maybe<Scalars['String']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  disbursement?: Maybe<Scalars['numeric']['output']>;
  donator_id?: Maybe<Scalars['uuid']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  expected_results?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_disbusement_date?: Maybe<Scalars['date']['output']>;
  other_suggestions?: Maybe<Scalars['String']['output']>;
  outputs_delivered?: Maybe<Scalars['String']['output']>;
  planned_new_support?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
}

/** order by min() on columns of table "capac.projects" */
export interface CapacProjectsMinOrderBy {
  activities?: InputMaybe<OrderBy>;
  beneficiary?: InputMaybe<OrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  donator_id?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  expected_results?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  last_disbusement_date?: InputMaybe<OrderBy>;
  other_suggestions?: InputMaybe<OrderBy>;
  outputs_delivered?: InputMaybe<OrderBy>;
  planned_new_support?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.projects" */
export interface CapacProjectsMutationResponse {
  __typename?: 'capac_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacProjects>;
}

/** input type for inserting object relation for remote table "capac.projects" */
export interface CapacProjectsObjRelInsertInput {
  data: CapacProjectsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacProjectsOnConflict>;
}

/** on_conflict condition type for table "capac.projects" */
export interface CapacProjectsOnConflict {
  constraint: CapacProjectsConstraint;
  update_columns?: Array<CapacProjectsUpdateColumn>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}

/** Ordering options when selecting data from "capac.projects". */
export interface CapacProjectsOrderBy {
  activities?: InputMaybe<OrderBy>;
  beneficiary?: InputMaybe<OrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  donator?: InputMaybe<CapacDonatorsOrderBy>;
  donator_id?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  expected_results?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  last_disbusement_date?: InputMaybe<OrderBy>;
  other_suggestions?: InputMaybe<OrderBy>;
  outputs_delivered?: InputMaybe<OrderBy>;
  planned_new_support?: InputMaybe<OrderBy>;
  project_beneficiaries_aggregate?: InputMaybe<CapacProjectBeneficiariesAggregateOrderBy>;
  start_date?: InputMaybe<OrderBy>;
  supports_accelerated_integration?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.projects */
export interface CapacProjectsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.projects" */
export enum CapacProjectsSelectColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** select "capac_projects_aggregate_bool_exp_bool_and_arguments_columns" columns of table "capac.projects" */
export enum CapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

/** select "capac_projects_aggregate_bool_exp_bool_or_arguments_columns" columns of table "capac.projects" */
export enum CapacProjectsSelectColumnCapacProjectsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration'
}

/** input type for updating data in table "capac.projects" */
export interface CapacProjectsSetInput {
  activities?: InputMaybe<Scalars['String']['input']>;
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  expected_results?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  last_disbusement_date?: InputMaybe<Scalars['date']['input']>;
  other_suggestions?: InputMaybe<Scalars['String']['input']>;
  outputs_delivered?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

/** aggregate stddev on columns */
export interface CapacProjectsStddevFields {
  __typename?: 'capac_projects_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "capac.projects" */
export interface CapacProjectsStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface CapacProjectsStddevPopFields {
  __typename?: 'capac_projects_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "capac.projects" */
export interface CapacProjectsStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface CapacProjectsStddevSampFields {
  __typename?: 'capac_projects_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "capac.projects" */
export interface CapacProjectsStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "capac_projects" */
export interface CapacProjectsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacProjectsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacProjectsStreamCursorValueInput {
  activities?: InputMaybe<Scalars['String']['input']>;
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  disbursement?: InputMaybe<Scalars['numeric']['input']>;
  donator_id?: InputMaybe<Scalars['uuid']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  expected_results?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  last_disbusement_date?: InputMaybe<Scalars['date']['input']>;
  other_suggestions?: InputMaybe<Scalars['String']['input']>;
  outputs_delivered?: InputMaybe<Scalars['String']['input']>;
  planned_new_support?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  supports_accelerated_integration?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
}

/** aggregate sum on columns */
export interface CapacProjectsSumFields {
  __typename?: 'capac_projects_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  disbursement?: Maybe<Scalars['numeric']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "capac.projects" */
export interface CapacProjectsSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** update columns of table "capac.projects" */
export enum CapacProjectsUpdateColumn {
  /** column name */
  Activities = 'activities',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Disbursement = 'disbursement',
  /** column name */
  DonatorId = 'donator_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExpectedResults = 'expected_results',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LastDisbusementDate = 'last_disbusement_date',
  /** column name */
  OtherSuggestions = 'other_suggestions',
  /** column name */
  OutputsDelivered = 'outputs_delivered',
  /** column name */
  PlannedNewSupport = 'planned_new_support',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  SupportsAcceleratedIntegration = 'supports_accelerated_integration',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export interface CapacProjectsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CapacProjectsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacProjectsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacProjectsBoolExp;
}

/** aggregate var_pop on columns */
export interface CapacProjectsVarPopFields {
  __typename?: 'capac_projects_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "capac.projects" */
export interface CapacProjectsVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface CapacProjectsVarSampFields {
  __typename?: 'capac_projects_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "capac.projects" */
export interface CapacProjectsVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface CapacProjectsVarianceFields {
  __typename?: 'capac_projects_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  disbursement?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "capac.projects" */
export interface CapacProjectsVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  disbursement?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
}

/** list of taiex capacities */
export interface CapacTaiex {
  __typename?: 'capac_taiex';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  title: Scalars['String']['output'];
  /** An array relationship */
  training_needs: Array<CapacTrainingNeeds>;
  /** An aggregate relationship */
  training_needs_aggregate: CapacTrainingNeedsAggregate;
  updated_at: Scalars['timestamptz']['output'];
}


/** list of taiex capacities */
export interface CapacTaiexTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** list of taiex capacities */
export interface CapacTaiexTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}

/** aggregated selection of "capac.taiex" */
export interface CapacTaiexAggregate {
  __typename?: 'capac_taiex_aggregate';
  aggregate?: Maybe<CapacTaiexAggregateFields>;
  nodes: Array<CapacTaiex>;
}

/** aggregate fields of "capac.taiex" */
export interface CapacTaiexAggregateFields {
  __typename?: 'capac_taiex_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTaiexMaxFields>;
  min?: Maybe<CapacTaiexMinFields>;
}


/** aggregate fields of "capac.taiex" */
export interface CapacTaiexAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTaiexSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "capac.taiex". All fields are combined with a logical 'AND'. */
export interface CapacTaiexBoolExp {
  _and?: InputMaybe<Array<CapacTaiexBoolExp>>;
  _not?: InputMaybe<CapacTaiexBoolExp>;
  _or?: InputMaybe<Array<CapacTaiexBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  training_needs?: InputMaybe<CapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.taiex" */
export enum CapacTaiexConstraint {
  /** unique or primary key constraint on columns "id" */
  TaiexPkey = 'taiex_pkey'
}

/** input type for inserting data into table "capac.taiex" */
export interface CapacTaiexInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<CapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacTaiexMaxFields {
  __typename?: 'capac_taiex_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface CapacTaiexMinFields {
  __typename?: 'capac_taiex_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "capac.taiex" */
export interface CapacTaiexMutationResponse {
  __typename?: 'capac_taiex_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTaiex>;
}

/** input type for inserting object relation for remote table "capac.taiex" */
export interface CapacTaiexObjRelInsertInput {
  data: CapacTaiexInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTaiexOnConflict>;
}

/** on_conflict condition type for table "capac.taiex" */
export interface CapacTaiexOnConflict {
  constraint: CapacTaiexConstraint;
  update_columns?: Array<CapacTaiexUpdateColumn>;
  where?: InputMaybe<CapacTaiexBoolExp>;
}

/** Ordering options when selecting data from "capac.taiex". */
export interface CapacTaiexOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.taiex */
export interface CapacTaiexPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.taiex" */
export enum CapacTaiexSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.taiex" */
export interface CapacTaiexSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_taiex" */
export interface CapacTaiexStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTaiexStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTaiexStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.taiex" */
export enum CapacTaiexUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacTaiexUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTaiexSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTaiexBoolExp;
}

/** list of training needs */
export interface CapacTrainingNeeds {
  __typename?: 'capac_training_needs';
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  integration_steps?: Maybe<Scalars['jsonb']['output']>;
  is_approved?: Maybe<Scalars['Boolean']['output']>;
  need: Scalars['String']['output'];
  /** An object relationship */
  priority_list?: Maybe<CapacPriorityLists>;
  priority_list_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  taiex?: Maybe<CapacTaiex>;
  taiex_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  training?: Maybe<CapacTrainings>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  twinning?: Maybe<CapacTwinning>;
  twinning_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
}


/** list of training needs */
export interface CapacTrainingNeedsIntegrationStepsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "capac.training_needs" */
export interface CapacTrainingNeedsAggregate {
  __typename?: 'capac_training_needs_aggregate';
  aggregate?: Maybe<CapacTrainingNeedsAggregateFields>;
  nodes: Array<CapacTrainingNeeds>;
}

export interface CapacTrainingNeedsAggregateBoolExp {
  bool_and?: InputMaybe<CapacTrainingNeedsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CapacTrainingNeedsAggregateBoolExpBoolOr>;
  count?: InputMaybe<CapacTrainingNeedsAggregateBoolExpCount>;
}

export interface CapacTrainingNeedsAggregateBoolExpBoolAnd {
  arguments: CapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingNeedsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface CapacTrainingNeedsAggregateBoolExpBoolOr {
  arguments: CapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingNeedsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface CapacTrainingNeedsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingNeedsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.training_needs" */
export interface CapacTrainingNeedsAggregateFields {
  __typename?: 'capac_training_needs_aggregate_fields';
  avg?: Maybe<CapacTrainingNeedsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTrainingNeedsMaxFields>;
  min?: Maybe<CapacTrainingNeedsMinFields>;
  stddev?: Maybe<CapacTrainingNeedsStddevFields>;
  stddev_pop?: Maybe<CapacTrainingNeedsStddevPopFields>;
  stddev_samp?: Maybe<CapacTrainingNeedsStddevSampFields>;
  sum?: Maybe<CapacTrainingNeedsSumFields>;
  var_pop?: Maybe<CapacTrainingNeedsVarPopFields>;
  var_samp?: Maybe<CapacTrainingNeedsVarSampFields>;
  variance?: Maybe<CapacTrainingNeedsVarianceFields>;
}


/** aggregate fields of "capac.training_needs" */
export interface CapacTrainingNeedsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.training_needs" */
export interface CapacTrainingNeedsAggregateOrderBy {
  avg?: InputMaybe<CapacTrainingNeedsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacTrainingNeedsMaxOrderBy>;
  min?: InputMaybe<CapacTrainingNeedsMinOrderBy>;
  stddev?: InputMaybe<CapacTrainingNeedsStddevOrderBy>;
  stddev_pop?: InputMaybe<CapacTrainingNeedsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CapacTrainingNeedsStddevSampOrderBy>;
  sum?: InputMaybe<CapacTrainingNeedsSumOrderBy>;
  var_pop?: InputMaybe<CapacTrainingNeedsVarPopOrderBy>;
  var_samp?: InputMaybe<CapacTrainingNeedsVarSampOrderBy>;
  variance?: InputMaybe<CapacTrainingNeedsVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface CapacTrainingNeedsAppendInput {
  integration_steps?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "capac.training_needs" */
export interface CapacTrainingNeedsArrRelInsertInput {
  data: Array<CapacTrainingNeedsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTrainingNeedsOnConflict>;
}

/** aggregate avg on columns */
export interface CapacTrainingNeedsAvgFields {
  __typename?: 'capac_training_needs_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "capac.training_needs". All fields are combined with a logical 'AND'. */
export interface CapacTrainingNeedsBoolExp {
  _and?: InputMaybe<Array<CapacTrainingNeedsBoolExp>>;
  _not?: InputMaybe<CapacTrainingNeedsBoolExp>;
  _or?: InputMaybe<Array<CapacTrainingNeedsBoolExp>>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  integration_steps?: InputMaybe<JsonbComparisonExp>;
  is_approved?: InputMaybe<BooleanComparisonExp>;
  need?: InputMaybe<StringComparisonExp>;
  priority_list?: InputMaybe<CapacPriorityListsBoolExp>;
  priority_list_id?: InputMaybe<UuidComparisonExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
  taiex?: InputMaybe<CapacTaiexBoolExp>;
  taiex_id?: InputMaybe<UuidComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
  training?: InputMaybe<CapacTrainingsBoolExp>;
  training_id?: InputMaybe<UuidComparisonExp>;
  twinning?: InputMaybe<CapacTwinningBoolExp>;
  twinning_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.training_needs" */
export enum CapacTrainingNeedsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingNeedsPkey = 'training_needs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface CapacTrainingNeedsDeleteAtPathInput {
  integration_steps?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface CapacTrainingNeedsDeleteElemInput {
  integration_steps?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface CapacTrainingNeedsDeleteKeyInput {
  integration_steps?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "capac.training_needs" */
export interface CapacTrainingNeedsIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "capac.training_needs" */
export interface CapacTrainingNeedsInsertInput {
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps?: InputMaybe<Scalars['jsonb']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  need?: InputMaybe<Scalars['String']['input']>;
  priority_list?: InputMaybe<CapacPriorityListsObjRelInsertInput>;
  priority_list_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  taiex?: InputMaybe<CapacTaiexObjRelInsertInput>;
  taiex_id?: InputMaybe<Scalars['uuid']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  training?: InputMaybe<CapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  twinning?: InputMaybe<CapacTwinningObjRelInsertInput>;
  twinning_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacTrainingNeedsMaxFields {
  __typename?: 'capac_training_needs_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  need?: Maybe<Scalars['String']['output']>;
  priority_list_id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  taiex_id?: Maybe<Scalars['uuid']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  twinning_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  need?: InputMaybe<OrderBy>;
  priority_list_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  taiex_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
  twinning_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacTrainingNeedsMinFields {
  __typename?: 'capac_training_needs_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  need?: Maybe<Scalars['String']['output']>;
  priority_list_id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  taiex_id?: Maybe<Scalars['uuid']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  twinning_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  need?: InputMaybe<OrderBy>;
  priority_list_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  taiex_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
  twinning_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.training_needs" */
export interface CapacTrainingNeedsMutationResponse {
  __typename?: 'capac_training_needs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTrainingNeeds>;
}

/** on_conflict condition type for table "capac.training_needs" */
export interface CapacTrainingNeedsOnConflict {
  constraint: CapacTrainingNeedsConstraint;
  update_columns?: Array<CapacTrainingNeedsUpdateColumn>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}

/** Ordering options when selecting data from "capac.training_needs". */
export interface CapacTrainingNeedsOrderBy {
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integration_steps?: InputMaybe<OrderBy>;
  is_approved?: InputMaybe<OrderBy>;
  need?: InputMaybe<OrderBy>;
  priority_list?: InputMaybe<CapacPriorityListsOrderBy>;
  priority_list_id?: InputMaybe<OrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  taiex?: InputMaybe<CapacTaiexOrderBy>;
  taiex_id?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  training?: InputMaybe<CapacTrainingsOrderBy>;
  training_id?: InputMaybe<OrderBy>;
  twinning?: InputMaybe<CapacTwinningOrderBy>;
  twinning_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.training_needs */
export interface CapacTrainingNeedsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface CapacTrainingNeedsPrependInput {
  integration_steps?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "capac.training_needs" */
export enum CapacTrainingNeedsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationSteps = 'integration_steps',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "capac_training_needs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "capac.training_needs" */
export enum CapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

/** select "capac_training_needs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "capac.training_needs" */
export enum CapacTrainingNeedsSelectColumnCapacTrainingNeedsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsApproved = 'is_approved'
}

/** input type for updating data in table "capac.training_needs" */
export interface CapacTrainingNeedsSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps?: InputMaybe<Scalars['jsonb']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  need?: InputMaybe<Scalars['String']['input']>;
  priority_list_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  taiex_id?: InputMaybe<Scalars['uuid']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  twinning_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface CapacTrainingNeedsStddevFields {
  __typename?: 'capac_training_needs_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface CapacTrainingNeedsStddevPopFields {
  __typename?: 'capac_training_needs_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface CapacTrainingNeedsStddevSampFields {
  __typename?: 'capac_training_needs_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "capac_training_needs" */
export interface CapacTrainingNeedsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTrainingNeedsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTrainingNeedsStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_steps?: InputMaybe<Scalars['jsonb']['input']>;
  is_approved?: InputMaybe<Scalars['Boolean']['input']>;
  need?: InputMaybe<Scalars['String']['input']>;
  priority_list_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  taiex_id?: InputMaybe<Scalars['uuid']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  twinning_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface CapacTrainingNeedsSumFields {
  __typename?: 'capac_training_needs_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** update columns of table "capac.training_needs" */
export enum CapacTrainingNeedsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationSteps = 'integration_steps',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  Need = 'need',
  /** column name */
  PriorityListId = 'priority_list_id',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TaiexId = 'taiex_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  TwinningId = 'twinning_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacTrainingNeedsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CapacTrainingNeedsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CapacTrainingNeedsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CapacTrainingNeedsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CapacTrainingNeedsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CapacTrainingNeedsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CapacTrainingNeedsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTrainingNeedsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTrainingNeedsBoolExp;
}

/** aggregate var_pop on columns */
export interface CapacTrainingNeedsVarPopFields {
  __typename?: 'capac_training_needs_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface CapacTrainingNeedsVarSampFields {
  __typename?: 'capac_training_needs_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface CapacTrainingNeedsVarianceFields {
  __typename?: 'capac_training_needs_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "capac.training_needs" */
export interface CapacTrainingNeedsVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** handles what participants are part of a training */
export interface CapacTrainingParticipants {
  __typename?: 'capac_training_participants';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  training: CapacTrainings;
  training_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "capac.training_participants" */
export interface CapacTrainingParticipantsAggregate {
  __typename?: 'capac_training_participants_aggregate';
  aggregate?: Maybe<CapacTrainingParticipantsAggregateFields>;
  nodes: Array<CapacTrainingParticipants>;
}

export interface CapacTrainingParticipantsAggregateBoolExp {
  count?: InputMaybe<CapacTrainingParticipantsAggregateBoolExpCount>;
}

export interface CapacTrainingParticipantsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingParticipantsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.training_participants" */
export interface CapacTrainingParticipantsAggregateFields {
  __typename?: 'capac_training_participants_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTrainingParticipantsMaxFields>;
  min?: Maybe<CapacTrainingParticipantsMinFields>;
}


/** aggregate fields of "capac.training_participants" */
export interface CapacTrainingParticipantsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.training_participants" */
export interface CapacTrainingParticipantsAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacTrainingParticipantsMaxOrderBy>;
  min?: InputMaybe<CapacTrainingParticipantsMinOrderBy>;
}

/** input type for inserting array relation for remote table "capac.training_participants" */
export interface CapacTrainingParticipantsArrRelInsertInput {
  data: Array<CapacTrainingParticipantsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTrainingParticipantsOnConflict>;
}

/** Boolean expression to filter rows from the table "capac.training_participants". All fields are combined with a logical 'AND'. */
export interface CapacTrainingParticipantsBoolExp {
  _and?: InputMaybe<Array<CapacTrainingParticipantsBoolExp>>;
  _not?: InputMaybe<CapacTrainingParticipantsBoolExp>;
  _or?: InputMaybe<Array<CapacTrainingParticipantsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  training?: InputMaybe<CapacTrainingsBoolExp>;
  training_id?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "capac.training_participants" */
export enum CapacTrainingParticipantsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey = 'training_sessions_pkey'
}

/** input type for inserting data into table "capac.training_participants" */
export interface CapacTrainingParticipantsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<CapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface CapacTrainingParticipantsMaxFields {
  __typename?: 'capac_training_participants_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "capac.training_participants" */
export interface CapacTrainingParticipantsMaxOrderBy {
  id?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacTrainingParticipantsMinFields {
  __typename?: 'capac_training_participants_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "capac.training_participants" */
export interface CapacTrainingParticipantsMinOrderBy {
  id?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.training_participants" */
export interface CapacTrainingParticipantsMutationResponse {
  __typename?: 'capac_training_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTrainingParticipants>;
}

/** on_conflict condition type for table "capac.training_participants" */
export interface CapacTrainingParticipantsOnConflict {
  constraint: CapacTrainingParticipantsConstraint;
  update_columns?: Array<CapacTrainingParticipantsUpdateColumn>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}

/** Ordering options when selecting data from "capac.training_participants". */
export interface CapacTrainingParticipantsOrderBy {
  id?: InputMaybe<OrderBy>;
  training?: InputMaybe<CapacTrainingsOrderBy>;
  training_id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.training_participants */
export interface CapacTrainingParticipantsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.training_participants" */
export enum CapacTrainingParticipantsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "capac.training_participants" */
export interface CapacTrainingParticipantsSetInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "capac_training_participants" */
export interface CapacTrainingParticipantsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTrainingParticipantsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTrainingParticipantsStreamCursorValueInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "capac.training_participants" */
export enum CapacTrainingParticipantsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UserId = 'user_id'
}

export interface CapacTrainingParticipantsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTrainingParticipantsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTrainingParticipantsBoolExp;
}

/** keeps all the sessions for a given training */
export interface CapacTrainingSessions {
  __typename?: 'capac_training_sessions';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  duration_mins: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  location: Scalars['String']['output'];
  participant_ids?: Maybe<Array<Scalars['uuid']['output']>>;
  starting_datetime: Scalars['timestamptz']['output'];
  /** An object relationship */
  training: CapacTrainings;
  training_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "capac.training_sessions" */
export interface CapacTrainingSessionsAggregate {
  __typename?: 'capac_training_sessions_aggregate';
  aggregate?: Maybe<CapacTrainingSessionsAggregateFields>;
  nodes: Array<CapacTrainingSessions>;
}

export interface CapacTrainingSessionsAggregateBoolExp {
  count?: InputMaybe<CapacTrainingSessionsAggregateBoolExpCount>;
}

export interface CapacTrainingSessionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingSessionsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.training_sessions" */
export interface CapacTrainingSessionsAggregateFields {
  __typename?: 'capac_training_sessions_aggregate_fields';
  avg?: Maybe<CapacTrainingSessionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTrainingSessionsMaxFields>;
  min?: Maybe<CapacTrainingSessionsMinFields>;
  stddev?: Maybe<CapacTrainingSessionsStddevFields>;
  stddev_pop?: Maybe<CapacTrainingSessionsStddevPopFields>;
  stddev_samp?: Maybe<CapacTrainingSessionsStddevSampFields>;
  sum?: Maybe<CapacTrainingSessionsSumFields>;
  var_pop?: Maybe<CapacTrainingSessionsVarPopFields>;
  var_samp?: Maybe<CapacTrainingSessionsVarSampFields>;
  variance?: Maybe<CapacTrainingSessionsVarianceFields>;
}


/** aggregate fields of "capac.training_sessions" */
export interface CapacTrainingSessionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.training_sessions" */
export interface CapacTrainingSessionsAggregateOrderBy {
  avg?: InputMaybe<CapacTrainingSessionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacTrainingSessionsMaxOrderBy>;
  min?: InputMaybe<CapacTrainingSessionsMinOrderBy>;
  stddev?: InputMaybe<CapacTrainingSessionsStddevOrderBy>;
  stddev_pop?: InputMaybe<CapacTrainingSessionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CapacTrainingSessionsStddevSampOrderBy>;
  sum?: InputMaybe<CapacTrainingSessionsSumOrderBy>;
  var_pop?: InputMaybe<CapacTrainingSessionsVarPopOrderBy>;
  var_samp?: InputMaybe<CapacTrainingSessionsVarSampOrderBy>;
  variance?: InputMaybe<CapacTrainingSessionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "capac.training_sessions" */
export interface CapacTrainingSessionsArrRelInsertInput {
  data: Array<CapacTrainingSessionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTrainingSessionsOnConflict>;
}

/** aggregate avg on columns */
export interface CapacTrainingSessionsAvgFields {
  __typename?: 'capac_training_sessions_avg_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsAvgOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "capac.training_sessions". All fields are combined with a logical 'AND'. */
export interface CapacTrainingSessionsBoolExp {
  _and?: InputMaybe<Array<CapacTrainingSessionsBoolExp>>;
  _not?: InputMaybe<CapacTrainingSessionsBoolExp>;
  _or?: InputMaybe<Array<CapacTrainingSessionsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  duration_mins?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  participant_ids?: InputMaybe<UuidArrayComparisonExp>;
  starting_datetime?: InputMaybe<TimestamptzComparisonExp>;
  training?: InputMaybe<CapacTrainingsBoolExp>;
  training_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.training_sessions" */
export enum CapacTrainingSessionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingSessionsPkey1 = 'training_sessions_pkey1'
}

/** input type for incrementing numeric columns in table "capac.training_sessions" */
export interface CapacTrainingSessionsIncInput {
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "capac.training_sessions" */
export interface CapacTrainingSessionsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  participant_ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  starting_datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  training?: InputMaybe<CapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacTrainingSessionsMaxFields {
  __typename?: 'capac_training_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  duration_mins?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  participant_ids?: Maybe<Array<Scalars['uuid']['output']>>;
  starting_datetime?: Maybe<Scalars['timestamptz']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  duration_mins?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  participant_ids?: InputMaybe<OrderBy>;
  starting_datetime?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacTrainingSessionsMinFields {
  __typename?: 'capac_training_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  duration_mins?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  participant_ids?: Maybe<Array<Scalars['uuid']['output']>>;
  starting_datetime?: Maybe<Scalars['timestamptz']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  duration_mins?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  participant_ids?: InputMaybe<OrderBy>;
  starting_datetime?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.training_sessions" */
export interface CapacTrainingSessionsMutationResponse {
  __typename?: 'capac_training_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTrainingSessions>;
}

/** on_conflict condition type for table "capac.training_sessions" */
export interface CapacTrainingSessionsOnConflict {
  constraint: CapacTrainingSessionsConstraint;
  update_columns?: Array<CapacTrainingSessionsUpdateColumn>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}

/** Ordering options when selecting data from "capac.training_sessions". */
export interface CapacTrainingSessionsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  duration_mins?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  participant_ids?: InputMaybe<OrderBy>;
  starting_datetime?: InputMaybe<OrderBy>;
  training?: InputMaybe<CapacTrainingsOrderBy>;
  training_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.training_sessions */
export interface CapacTrainingSessionsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.training_sessions" */
export enum CapacTrainingSessionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.training_sessions" */
export interface CapacTrainingSessionsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  participant_ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  starting_datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface CapacTrainingSessionsStddevFields {
  __typename?: 'capac_training_sessions_stddev_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsStddevOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface CapacTrainingSessionsStddevPopFields {
  __typename?: 'capac_training_sessions_stddev_pop_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsStddevPopOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface CapacTrainingSessionsStddevSampFields {
  __typename?: 'capac_training_sessions_stddev_samp_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsStddevSampOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "capac_training_sessions" */
export interface CapacTrainingSessionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTrainingSessionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTrainingSessionsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  duration_mins?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  participant_ids?: InputMaybe<Array<Scalars['uuid']['input']>>;
  starting_datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface CapacTrainingSessionsSumFields {
  __typename?: 'capac_training_sessions_sum_fields';
  duration_mins?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsSumOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** update columns of table "capac.training_sessions" */
export enum CapacTrainingSessionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DurationMins = 'duration_mins',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  ParticipantIds = 'participant_ids',
  /** column name */
  StartingDatetime = 'starting_datetime',
  /** column name */
  TrainingId = 'training_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacTrainingSessionsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CapacTrainingSessionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTrainingSessionsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTrainingSessionsBoolExp;
}

/** aggregate var_pop on columns */
export interface CapacTrainingSessionsVarPopFields {
  __typename?: 'capac_training_sessions_var_pop_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsVarPopOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface CapacTrainingSessionsVarSampFields {
  __typename?: 'capac_training_sessions_var_samp_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsVarSampOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface CapacTrainingSessionsVarianceFields {
  __typename?: 'capac_training_sessions_variance_fields';
  duration_mins?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "capac.training_sessions" */
export interface CapacTrainingSessionsVarianceOrderBy {
  duration_mins?: InputMaybe<OrderBy>;
}

/** list of trainings */
export interface CapacTrainings {
  __typename?: 'capac_trainings';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  location: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trainers: Scalars['String']['output'];
  /** An array relationship */
  training_needs: Array<CapacTrainingNeeds>;
  /** An aggregate relationship */
  training_needs_aggregate: CapacTrainingNeedsAggregate;
  /** An array relationship */
  training_participants: Array<CapacTrainingParticipants>;
  /** An aggregate relationship */
  training_participants_aggregate: CapacTrainingParticipantsAggregate;
  /** An array relationship */
  training_sessions: Array<CapacTrainingSessions>;
  /** An aggregate relationship */
  training_sessions_aggregate: CapacTrainingSessionsAggregate;
  /** An array relationship */
  trainings_experts: Array<CapacTrainingsExperts>;
  /** An aggregate relationship */
  trainings_experts_aggregate: CapacTrainingsExpertsAggregate;
  updated_at: Scalars['timestamptz']['output'];
}


/** list of trainings */
export interface CapacTrainingsTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingParticipantsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingParticipantsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingSessionsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingSessionsOrderBy>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingSessionsOrderBy>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingsExpertsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


/** list of trainings */
export interface CapacTrainingsTrainingsExpertsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}

/** aggregated selection of "capac.trainings" */
export interface CapacTrainingsAggregate {
  __typename?: 'capac_trainings_aggregate';
  aggregate?: Maybe<CapacTrainingsAggregateFields>;
  nodes: Array<CapacTrainings>;
}

export interface CapacTrainingsAggregateBoolExp {
  count?: InputMaybe<CapacTrainingsAggregateBoolExpCount>;
}

export interface CapacTrainingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.trainings" */
export interface CapacTrainingsAggregateFields {
  __typename?: 'capac_trainings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTrainingsMaxFields>;
  min?: Maybe<CapacTrainingsMinFields>;
}


/** aggregate fields of "capac.trainings" */
export interface CapacTrainingsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.trainings" */
export interface CapacTrainingsAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacTrainingsMaxOrderBy>;
  min?: InputMaybe<CapacTrainingsMinOrderBy>;
}

/** input type for inserting array relation for remote table "capac.trainings" */
export interface CapacTrainingsArrRelInsertInput {
  data: Array<CapacTrainingsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTrainingsOnConflict>;
}

/** Boolean expression to filter rows from the table "capac.trainings". All fields are combined with a logical 'AND'. */
export interface CapacTrainingsBoolExp {
  _and?: InputMaybe<Array<CapacTrainingsBoolExp>>;
  _not?: InputMaybe<CapacTrainingsBoolExp>;
  _or?: InputMaybe<Array<CapacTrainingsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  trainers?: InputMaybe<StringComparisonExp>;
  training_needs?: InputMaybe<CapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateBoolExp>;
  training_participants?: InputMaybe<CapacTrainingParticipantsBoolExp>;
  training_participants_aggregate?: InputMaybe<CapacTrainingParticipantsAggregateBoolExp>;
  training_sessions?: InputMaybe<CapacTrainingSessionsBoolExp>;
  training_sessions_aggregate?: InputMaybe<CapacTrainingSessionsAggregateBoolExp>;
  trainings_experts?: InputMaybe<CapacTrainingsExpertsBoolExp>;
  trainings_experts_aggregate?: InputMaybe<CapacTrainingsExpertsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.trainings" */
export enum CapacTrainingsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsPkey = 'trainings_pkey'
}

/** many to many table */
export interface CapacTrainingsExperts {
  __typename?: 'capac_trainings_experts';
  /** An object relationship */
  expert: CapacExperts;
  expert_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  training: CapacTrainings;
  training_id: Scalars['uuid']['output'];
}

/** aggregated selection of "capac.trainings_experts" */
export interface CapacTrainingsExpertsAggregate {
  __typename?: 'capac_trainings_experts_aggregate';
  aggregate?: Maybe<CapacTrainingsExpertsAggregateFields>;
  nodes: Array<CapacTrainingsExperts>;
}

export interface CapacTrainingsExpertsAggregateBoolExp {
  count?: InputMaybe<CapacTrainingsExpertsAggregateBoolExpCount>;
}

export interface CapacTrainingsExpertsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CapacTrainingsExpertsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "capac.trainings_experts" */
export interface CapacTrainingsExpertsAggregateFields {
  __typename?: 'capac_trainings_experts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTrainingsExpertsMaxFields>;
  min?: Maybe<CapacTrainingsExpertsMinFields>;
}


/** aggregate fields of "capac.trainings_experts" */
export interface CapacTrainingsExpertsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "capac.trainings_experts" */
export interface CapacTrainingsExpertsAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CapacTrainingsExpertsMaxOrderBy>;
  min?: InputMaybe<CapacTrainingsExpertsMinOrderBy>;
}

/** input type for inserting array relation for remote table "capac.trainings_experts" */
export interface CapacTrainingsExpertsArrRelInsertInput {
  data: Array<CapacTrainingsExpertsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTrainingsExpertsOnConflict>;
}

/** Boolean expression to filter rows from the table "capac.trainings_experts". All fields are combined with a logical 'AND'. */
export interface CapacTrainingsExpertsBoolExp {
  _and?: InputMaybe<Array<CapacTrainingsExpertsBoolExp>>;
  _not?: InputMaybe<CapacTrainingsExpertsBoolExp>;
  _or?: InputMaybe<Array<CapacTrainingsExpertsBoolExp>>;
  expert?: InputMaybe<CapacExpertsBoolExp>;
  expert_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  training?: InputMaybe<CapacTrainingsBoolExp>;
  training_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "capac.trainings_experts" */
export enum CapacTrainingsExpertsConstraint {
  /** unique or primary key constraint on columns "id" */
  TrainingsExpertsPkey = 'trainings_experts_pkey'
}

/** input type for inserting data into table "capac.trainings_experts" */
export interface CapacTrainingsExpertsInsertInput {
  expert?: InputMaybe<CapacExpertsObjRelInsertInput>;
  expert_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  training?: InputMaybe<CapacTrainingsObjRelInsertInput>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface CapacTrainingsExpertsMaxFields {
  __typename?: 'capac_trainings_experts_max_fields';
  expert_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "capac.trainings_experts" */
export interface CapacTrainingsExpertsMaxOrderBy {
  expert_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacTrainingsExpertsMinFields {
  __typename?: 'capac_trainings_experts_min_fields';
  expert_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  training_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "capac.trainings_experts" */
export interface CapacTrainingsExpertsMinOrderBy {
  expert_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  training_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.trainings_experts" */
export interface CapacTrainingsExpertsMutationResponse {
  __typename?: 'capac_trainings_experts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTrainingsExperts>;
}

/** on_conflict condition type for table "capac.trainings_experts" */
export interface CapacTrainingsExpertsOnConflict {
  constraint: CapacTrainingsExpertsConstraint;
  update_columns?: Array<CapacTrainingsExpertsUpdateColumn>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}

/** Ordering options when selecting data from "capac.trainings_experts". */
export interface CapacTrainingsExpertsOrderBy {
  expert?: InputMaybe<CapacExpertsOrderBy>;
  expert_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  training?: InputMaybe<CapacTrainingsOrderBy>;
  training_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.trainings_experts */
export interface CapacTrainingsExpertsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.trainings_experts" */
export enum CapacTrainingsExpertsSelectColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

/** input type for updating data in table "capac.trainings_experts" */
export interface CapacTrainingsExpertsSetInput {
  expert_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "capac_trainings_experts" */
export interface CapacTrainingsExpertsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTrainingsExpertsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTrainingsExpertsStreamCursorValueInput {
  expert_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  training_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "capac.trainings_experts" */
export enum CapacTrainingsExpertsUpdateColumn {
  /** column name */
  ExpertId = 'expert_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrainingId = 'training_id'
}

export interface CapacTrainingsExpertsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTrainingsExpertsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTrainingsExpertsBoolExp;
}

/** input type for inserting data into table "capac.trainings" */
export interface CapacTrainingsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainers?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<CapacTrainingNeedsArrRelInsertInput>;
  training_participants?: InputMaybe<CapacTrainingParticipantsArrRelInsertInput>;
  training_sessions?: InputMaybe<CapacTrainingSessionsArrRelInsertInput>;
  trainings_experts?: InputMaybe<CapacTrainingsExpertsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacTrainingsMaxFields {
  __typename?: 'capac_trainings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trainers?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "capac.trainings" */
export interface CapacTrainingsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  trainers?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface CapacTrainingsMinFields {
  __typename?: 'capac_trainings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trainers?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "capac.trainings" */
export interface CapacTrainingsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  trainers?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "capac.trainings" */
export interface CapacTrainingsMutationResponse {
  __typename?: 'capac_trainings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTrainings>;
}

/** input type for inserting object relation for remote table "capac.trainings" */
export interface CapacTrainingsObjRelInsertInput {
  data: CapacTrainingsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTrainingsOnConflict>;
}

/** on_conflict condition type for table "capac.trainings" */
export interface CapacTrainingsOnConflict {
  constraint: CapacTrainingsConstraint;
  update_columns?: Array<CapacTrainingsUpdateColumn>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}

/** Ordering options when selecting data from "capac.trainings". */
export interface CapacTrainingsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  trainers?: InputMaybe<OrderBy>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateOrderBy>;
  training_participants_aggregate?: InputMaybe<CapacTrainingParticipantsAggregateOrderBy>;
  training_sessions_aggregate?: InputMaybe<CapacTrainingSessionsAggregateOrderBy>;
  trainings_experts_aggregate?: InputMaybe<CapacTrainingsExpertsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.trainings */
export interface CapacTrainingsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.trainings" */
export enum CapacTrainingsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.trainings" */
export interface CapacTrainingsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainers?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_trainings" */
export interface CapacTrainingsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTrainingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTrainingsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trainers?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.trainings" */
export enum CapacTrainingsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Title = 'title',
  /** column name */
  Trainers = 'trainers',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacTrainingsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTrainingsSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTrainingsBoolExp;
}

/** list of twinning capacities */
export interface CapacTwinning {
  __typename?: 'capac_twinning';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  title: Scalars['String']['output'];
  /** An array relationship */
  training_needs: Array<CapacTrainingNeeds>;
  /** An aggregate relationship */
  training_needs_aggregate: CapacTrainingNeedsAggregate;
  updated_at: Scalars['timestamptz']['output'];
}


/** list of twinning capacities */
export interface CapacTwinningTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** list of twinning capacities */
export interface CapacTwinningTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}

/** aggregated selection of "capac.twinning" */
export interface CapacTwinningAggregate {
  __typename?: 'capac_twinning_aggregate';
  aggregate?: Maybe<CapacTwinningAggregateFields>;
  nodes: Array<CapacTwinning>;
}

/** aggregate fields of "capac.twinning" */
export interface CapacTwinningAggregateFields {
  __typename?: 'capac_twinning_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CapacTwinningMaxFields>;
  min?: Maybe<CapacTwinningMinFields>;
}


/** aggregate fields of "capac.twinning" */
export interface CapacTwinningAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<CapacTwinningSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "capac.twinning". All fields are combined with a logical 'AND'. */
export interface CapacTwinningBoolExp {
  _and?: InputMaybe<Array<CapacTwinningBoolExp>>;
  _not?: InputMaybe<CapacTwinningBoolExp>;
  _or?: InputMaybe<Array<CapacTwinningBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  training_needs?: InputMaybe<CapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "capac.twinning" */
export enum CapacTwinningConstraint {
  /** unique or primary key constraint on columns "id" */
  TwinningPkey = 'twinning_pkey'
}

/** input type for inserting data into table "capac.twinning" */
export interface CapacTwinningInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<CapacTrainingNeedsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface CapacTwinningMaxFields {
  __typename?: 'capac_twinning_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface CapacTwinningMinFields {
  __typename?: 'capac_twinning_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "capac.twinning" */
export interface CapacTwinningMutationResponse {
  __typename?: 'capac_twinning_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CapacTwinning>;
}

/** input type for inserting object relation for remote table "capac.twinning" */
export interface CapacTwinningObjRelInsertInput {
  data: CapacTwinningInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CapacTwinningOnConflict>;
}

/** on_conflict condition type for table "capac.twinning" */
export interface CapacTwinningOnConflict {
  constraint: CapacTwinningConstraint;
  update_columns?: Array<CapacTwinningUpdateColumn>;
  where?: InputMaybe<CapacTwinningBoolExp>;
}

/** Ordering options when selecting data from "capac.twinning". */
export interface CapacTwinningOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: capac.twinning */
export interface CapacTwinningPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "capac.twinning" */
export enum CapacTwinningSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "capac.twinning" */
export interface CapacTwinningSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "capac_twinning" */
export interface CapacTwinningStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CapacTwinningStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CapacTwinningStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "capac.twinning" */
export enum CapacTwinningUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface CapacTwinningUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CapacTwinningSetInput>;
  /** filter the rows which have to be updated */
  where: CapacTwinningBoolExp;
}

/** columns and relationships of "chapter_lead_institutions" */
export interface ChapterLeadInstitutions {
  __typename?: 'chapter_lead_institutions';
  chapter_id: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  institution: Institutions;
  institution_id: Scalars['uuid']['output'];
}

/** aggregated selection of "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsAggregate {
  __typename?: 'chapter_lead_institutions_aggregate';
  aggregate?: Maybe<ChapterLeadInstitutionsAggregateFields>;
  nodes: Array<ChapterLeadInstitutions>;
}

export interface ChapterLeadInstitutionsAggregateBoolExp {
  count?: InputMaybe<ChapterLeadInstitutionsAggregateBoolExpCount>;
}

export interface ChapterLeadInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsAggregateFields {
  __typename?: 'chapter_lead_institutions_aggregate_fields';
  avg?: Maybe<ChapterLeadInstitutionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ChapterLeadInstitutionsMaxFields>;
  min?: Maybe<ChapterLeadInstitutionsMinFields>;
  stddev?: Maybe<ChapterLeadInstitutionsStddevFields>;
  stddev_pop?: Maybe<ChapterLeadInstitutionsStddevPopFields>;
  stddev_samp?: Maybe<ChapterLeadInstitutionsStddevSampFields>;
  sum?: Maybe<ChapterLeadInstitutionsSumFields>;
  var_pop?: Maybe<ChapterLeadInstitutionsVarPopFields>;
  var_samp?: Maybe<ChapterLeadInstitutionsVarSampFields>;
  variance?: Maybe<ChapterLeadInstitutionsVarianceFields>;
}


/** aggregate fields of "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsAggregateOrderBy {
  avg?: InputMaybe<ChapterLeadInstitutionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChapterLeadInstitutionsMaxOrderBy>;
  min?: InputMaybe<ChapterLeadInstitutionsMinOrderBy>;
  stddev?: InputMaybe<ChapterLeadInstitutionsStddevOrderBy>;
  stddev_pop?: InputMaybe<ChapterLeadInstitutionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChapterLeadInstitutionsStddevSampOrderBy>;
  sum?: InputMaybe<ChapterLeadInstitutionsSumOrderBy>;
  var_pop?: InputMaybe<ChapterLeadInstitutionsVarPopOrderBy>;
  var_samp?: InputMaybe<ChapterLeadInstitutionsVarSampOrderBy>;
  variance?: InputMaybe<ChapterLeadInstitutionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsArrRelInsertInput {
  data: Array<ChapterLeadInstitutionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChapterLeadInstitutionsOnConflict>;
}

/** aggregate avg on columns */
export interface ChapterLeadInstitutionsAvgFields {
  __typename?: 'chapter_lead_institutions_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "chapter_lead_institutions". All fields are combined with a logical 'AND'. */
export interface ChapterLeadInstitutionsBoolExp {
  _and?: InputMaybe<Array<ChapterLeadInstitutionsBoolExp>>;
  _not?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
  _or?: InputMaybe<Array<ChapterLeadInstitutionsBoolExp>>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  institution?: InputMaybe<InstitutionsBoolExp>;
  institution_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "chapter_lead_institutions" */
export enum ChapterLeadInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChapterLeadInstitutionsPkey = 'chapter_lead_institutions_pkey'
}

/** input type for incrementing numeric columns in table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsInsertInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface ChapterLeadInstitutionsMaxFields {
  __typename?: 'chapter_lead_institutions_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ChapterLeadInstitutionsMinFields {
  __typename?: 'chapter_lead_institutions_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsMutationResponse {
  __typename?: 'chapter_lead_institutions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChapterLeadInstitutions>;
}

/** on_conflict condition type for table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsOnConflict {
  constraint: ChapterLeadInstitutionsConstraint;
  update_columns?: Array<ChapterLeadInstitutionsUpdateColumn>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}

/** Ordering options when selecting data from "chapter_lead_institutions". */
export interface ChapterLeadInstitutionsOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution?: InputMaybe<InstitutionsOrderBy>;
  institution_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: chapter_lead_institutions */
export interface ChapterLeadInstitutionsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "chapter_lead_institutions" */
export enum ChapterLeadInstitutionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

/** input type for updating data in table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface ChapterLeadInstitutionsStddevFields {
  __typename?: 'chapter_lead_institutions_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ChapterLeadInstitutionsStddevPopFields {
  __typename?: 'chapter_lead_institutions_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ChapterLeadInstitutionsStddevSampFields {
  __typename?: 'chapter_lead_institutions_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ChapterLeadInstitutionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ChapterLeadInstitutionsStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface ChapterLeadInstitutionsSumFields {
  __typename?: 'chapter_lead_institutions_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** update columns of table "chapter_lead_institutions" */
export enum ChapterLeadInstitutionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface ChapterLeadInstitutionsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChapterLeadInstitutionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChapterLeadInstitutionsSetInput>;
  /** filter the rows which have to be updated */
  where: ChapterLeadInstitutionsBoolExp;
}

/** aggregate var_pop on columns */
export interface ChapterLeadInstitutionsVarPopFields {
  __typename?: 'chapter_lead_institutions_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface ChapterLeadInstitutionsVarSampFields {
  __typename?: 'chapter_lead_institutions_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface ChapterLeadInstitutionsVarianceFields {
  __typename?: 'chapter_lead_institutions_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "chapter_lead_institutions" */
export interface ChapterLeadInstitutionsVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** contains eu chapters */
export interface Chapters {
  __typename?: 'chapters';
  /** An array relationship */
  acquis_chapters: Array<PkieAcquisChapter>;
  /** An aggregate relationship */
  acquis_chapters_aggregate: PkieAcquisChapterAggregate;
  /** An array relationship */
  bookmarks: Array<Bookmarks>;
  /** An aggregate relationship */
  bookmarks_aggregate: BookmarksAggregate;
  /** An array relationship */
  chapter_donators: Array<CapacChapterDonator>;
  /** An aggregate relationship */
  chapter_donators_aggregate: CapacChapterDonatorAggregate;
  /** An array relationship */
  chapter_lead_institutions: Array<ChapterLeadInstitutions>;
  /** An aggregate relationship */
  chapter_lead_institutions_aggregate: ChapterLeadInstitutionsAggregate;
  chapter_number: Scalars['String']['output'];
  chapter_title: Scalars['String']['output'];
  chapter_title_en?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  cluster?: Maybe<Clusters>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: DocumentsAggregate;
  /** An array relationship */
  gnpies: Array<Gnpie>;
  /** An aggregate relationship */
  gnpies_aggregate: GnpieAggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  integration_phase?: Maybe<IntegrationPhasesEnum>;
  new_id: Scalars['uuid']['output'];
  phase?: Maybe<IntegrationPhasesEnumEnum>;
  /** An array relationship */
  projects: Array<CapacProjects>;
  /** An aggregate relationship */
  projects_aggregate: CapacProjectsAggregate;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: QuestionsAggregate;
  /** An array relationship */
  report_chapters: Array<ReportChapters>;
  /** An aggregate relationship */
  report_chapters_aggregate: ReportChaptersAggregate;
  /** An array relationship */
  sub_chapters: Array<SubChapters>;
  /** An aggregate relationship */
  sub_chapters_aggregate: SubChaptersAggregate;
  /** An array relationship */
  topics: Array<Topics>;
  /** An aggregate relationship */
  topics_aggregate: TopicsAggregate;
  /** An array relationship */
  training_needs: Array<CapacTrainingNeeds>;
  /** An aggregate relationship */
  training_needs_aggregate: CapacTrainingNeedsAggregate;
  /** An array relationship */
  work_plan_tasks: Array<PkieWorkPlanTask>;
  /** An aggregate relationship */
  work_plan_tasks_aggregate: PkieWorkPlanTaskAggregate;
}


/** contains eu chapters */
export interface ChaptersAcquisChaptersArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


/** contains eu chapters */
export interface ChaptersAcquisChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


/** contains eu chapters */
export interface ChaptersBookmarksArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** contains eu chapters */
export interface ChaptersBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** contains eu chapters */
export interface ChaptersChapterDonatorsArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


/** contains eu chapters */
export interface ChaptersChapterDonatorsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


/** contains eu chapters */
export interface ChaptersChapterLeadInstitutionsArgs {
  distinct_on?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersChapterLeadInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersDocumentsArgs {
  distinct_on?: InputMaybe<Array<DocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentsOrderBy>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<DocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentsOrderBy>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersGnpiesArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** contains eu chapters */
export interface ChaptersGnpiesAggregateArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** contains eu chapters */
export interface ChaptersProjectsArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersProjectsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersQuestionsArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersReportChaptersArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


/** contains eu chapters */
export interface ChaptersReportChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


/** contains eu chapters */
export interface ChaptersSubChaptersArgs {
  distinct_on?: InputMaybe<Array<SubChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChaptersOrderBy>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


/** contains eu chapters */
export interface ChaptersSubChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<SubChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChaptersOrderBy>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


/** contains eu chapters */
export interface ChaptersTopicsArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersTopicsAggregateArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** contains eu chapters */
export interface ChaptersWorkPlanTasksArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


/** contains eu chapters */
export interface ChaptersWorkPlanTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}

/** aggregated selection of "chapters" */
export interface ChaptersAggregate {
  __typename?: 'chapters_aggregate';
  aggregate?: Maybe<ChaptersAggregateFields>;
  nodes: Array<Chapters>;
}

export interface ChaptersAggregateBoolExp {
  count?: InputMaybe<ChaptersAggregateBoolExpCount>;
}

export interface ChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ChaptersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "chapters" */
export interface ChaptersAggregateFields {
  __typename?: 'chapters_aggregate_fields';
  avg?: Maybe<ChaptersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ChaptersMaxFields>;
  min?: Maybe<ChaptersMinFields>;
  stddev?: Maybe<ChaptersStddevFields>;
  stddev_pop?: Maybe<ChaptersStddevPopFields>;
  stddev_samp?: Maybe<ChaptersStddevSampFields>;
  sum?: Maybe<ChaptersSumFields>;
  var_pop?: Maybe<ChaptersVarPopFields>;
  var_samp?: Maybe<ChaptersVarSampFields>;
  variance?: Maybe<ChaptersVarianceFields>;
}


/** aggregate fields of "chapters" */
export interface ChaptersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "chapters" */
export interface ChaptersAggregateOrderBy {
  avg?: InputMaybe<ChaptersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChaptersMaxOrderBy>;
  min?: InputMaybe<ChaptersMinOrderBy>;
  stddev?: InputMaybe<ChaptersStddevOrderBy>;
  stddev_pop?: InputMaybe<ChaptersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChaptersStddevSampOrderBy>;
  sum?: InputMaybe<ChaptersSumOrderBy>;
  var_pop?: InputMaybe<ChaptersVarPopOrderBy>;
  var_samp?: InputMaybe<ChaptersVarSampOrderBy>;
  variance?: InputMaybe<ChaptersVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "chapters" */
export interface ChaptersArrRelInsertInput {
  data: Array<ChaptersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChaptersOnConflict>;
}

/** aggregate avg on columns */
export interface ChaptersAvgFields {
  __typename?: 'chapters_avg_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "chapters" */
export interface ChaptersAvgOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "chapters". All fields are combined with a logical 'AND'. */
export interface ChaptersBoolExp {
  _and?: InputMaybe<Array<ChaptersBoolExp>>;
  _not?: InputMaybe<ChaptersBoolExp>;
  _or?: InputMaybe<Array<ChaptersBoolExp>>;
  acquis_chapters?: InputMaybe<PkieAcquisChapterBoolExp>;
  acquis_chapters_aggregate?: InputMaybe<PkieAcquisChapterAggregateBoolExp>;
  bookmarks?: InputMaybe<BookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateBoolExp>;
  chapter_donators?: InputMaybe<CapacChapterDonatorBoolExp>;
  chapter_donators_aggregate?: InputMaybe<CapacChapterDonatorAggregateBoolExp>;
  chapter_lead_institutions?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
  chapter_lead_institutions_aggregate?: InputMaybe<ChapterLeadInstitutionsAggregateBoolExp>;
  chapter_number?: InputMaybe<StringComparisonExp>;
  chapter_title?: InputMaybe<StringComparisonExp>;
  chapter_title_en?: InputMaybe<StringComparisonExp>;
  cluster?: InputMaybe<ClustersBoolExp>;
  cluster_id?: InputMaybe<IntComparisonExp>;
  documents?: InputMaybe<DocumentsBoolExp>;
  documents_aggregate?: InputMaybe<DocumentsAggregateBoolExp>;
  gnpies?: InputMaybe<GnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumBoolExp>;
  new_id?: InputMaybe<UuidComparisonExp>;
  phase?: InputMaybe<IntegrationPhasesEnumEnumComparisonExp>;
  projects?: InputMaybe<CapacProjectsBoolExp>;
  projects_aggregate?: InputMaybe<CapacProjectsAggregateBoolExp>;
  questions?: InputMaybe<QuestionsBoolExp>;
  questions_aggregate?: InputMaybe<QuestionsAggregateBoolExp>;
  report_chapters?: InputMaybe<ReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<ReportChaptersAggregateBoolExp>;
  sub_chapters?: InputMaybe<SubChaptersBoolExp>;
  sub_chapters_aggregate?: InputMaybe<SubChaptersAggregateBoolExp>;
  topics?: InputMaybe<TopicsBoolExp>;
  topics_aggregate?: InputMaybe<TopicsAggregateBoolExp>;
  training_needs?: InputMaybe<CapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateBoolExp>;
  work_plan_tasks?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<PkieWorkPlanTaskAggregateBoolExp>;
}

/** unique or primary key constraints on table "chapters" */
export enum ChaptersConstraint {
  /** unique or primary key constraint on columns "chapter_number" */
  ChaptersChapterNumberKey = 'chapters_chapter_number_key',
  /** unique or primary key constraint on columns "chapter_title_en" */
  ChaptersChapterTitleEnKey = 'chapters_chapter_title_en_key',
  /** unique or primary key constraint on columns "chapter_title" */
  ChaptersChapterTitleKey = 'chapters_chapter_title_key',
  /** unique or primary key constraint on columns "new_id" */
  ChaptersNewIdKey = 'chapters_new_id_key',
  /** unique or primary key constraint on columns "id" */
  ChaptersPkey = 'chapters_pkey'
}

/** input type for incrementing numeric columns in table "chapters" */
export interface ChaptersIncInput {
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "chapters" */
export interface ChaptersInsertInput {
  acquis_chapters?: InputMaybe<PkieAcquisChapterArrRelInsertInput>;
  bookmarks?: InputMaybe<BookmarksArrRelInsertInput>;
  chapter_donators?: InputMaybe<CapacChapterDonatorArrRelInsertInput>;
  chapter_lead_institutions?: InputMaybe<ChapterLeadInstitutionsArrRelInsertInput>;
  chapter_number?: InputMaybe<Scalars['String']['input']>;
  chapter_title?: InputMaybe<Scalars['String']['input']>;
  chapter_title_en?: InputMaybe<Scalars['String']['input']>;
  cluster?: InputMaybe<ClustersObjRelInsertInput>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  documents?: InputMaybe<DocumentsArrRelInsertInput>;
  gnpies?: InputMaybe<GnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumObjRelInsertInput>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  projects?: InputMaybe<CapacProjectsArrRelInsertInput>;
  questions?: InputMaybe<QuestionsArrRelInsertInput>;
  report_chapters?: InputMaybe<ReportChaptersArrRelInsertInput>;
  sub_chapters?: InputMaybe<SubChaptersArrRelInsertInput>;
  topics?: InputMaybe<TopicsArrRelInsertInput>;
  training_needs?: InputMaybe<CapacTrainingNeedsArrRelInsertInput>;
  work_plan_tasks?: InputMaybe<PkieWorkPlanTaskArrRelInsertInput>;
}

/** aggregate max on columns */
export interface ChaptersMaxFields {
  __typename?: 'chapters_max_fields';
  chapter_number?: Maybe<Scalars['String']['output']>;
  chapter_title?: Maybe<Scalars['String']['output']>;
  chapter_title_en?: Maybe<Scalars['String']['output']>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "chapters" */
export interface ChaptersMaxOrderBy {
  chapter_number?: InputMaybe<OrderBy>;
  chapter_title?: InputMaybe<OrderBy>;
  chapter_title_en?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  new_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ChaptersMinFields {
  __typename?: 'chapters_min_fields';
  chapter_number?: Maybe<Scalars['String']['output']>;
  chapter_title?: Maybe<Scalars['String']['output']>;
  chapter_title_en?: Maybe<Scalars['String']['output']>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  new_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "chapters" */
export interface ChaptersMinOrderBy {
  chapter_number?: InputMaybe<OrderBy>;
  chapter_title?: InputMaybe<OrderBy>;
  chapter_title_en?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  new_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "chapters" */
export interface ChaptersMutationResponse {
  __typename?: 'chapters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chapters>;
}

/** input type for inserting object relation for remote table "chapters" */
export interface ChaptersObjRelInsertInput {
  data: ChaptersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChaptersOnConflict>;
}

/** on_conflict condition type for table "chapters" */
export interface ChaptersOnConflict {
  constraint: ChaptersConstraint;
  update_columns?: Array<ChaptersUpdateColumn>;
  where?: InputMaybe<ChaptersBoolExp>;
}

/** Ordering options when selecting data from "chapters". */
export interface ChaptersOrderBy {
  acquis_chapters_aggregate?: InputMaybe<PkieAcquisChapterAggregateOrderBy>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateOrderBy>;
  chapter_donators_aggregate?: InputMaybe<CapacChapterDonatorAggregateOrderBy>;
  chapter_lead_institutions_aggregate?: InputMaybe<ChapterLeadInstitutionsAggregateOrderBy>;
  chapter_number?: InputMaybe<OrderBy>;
  chapter_title?: InputMaybe<OrderBy>;
  chapter_title_en?: InputMaybe<OrderBy>;
  cluster?: InputMaybe<ClustersOrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  documents_aggregate?: InputMaybe<DocumentsAggregateOrderBy>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumOrderBy>;
  new_id?: InputMaybe<OrderBy>;
  phase?: InputMaybe<OrderBy>;
  projects_aggregate?: InputMaybe<CapacProjectsAggregateOrderBy>;
  questions_aggregate?: InputMaybe<QuestionsAggregateOrderBy>;
  report_chapters_aggregate?: InputMaybe<ReportChaptersAggregateOrderBy>;
  sub_chapters_aggregate?: InputMaybe<SubChaptersAggregateOrderBy>;
  topics_aggregate?: InputMaybe<TopicsAggregateOrderBy>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateOrderBy>;
  work_plan_tasks_aggregate?: InputMaybe<PkieWorkPlanTaskAggregateOrderBy>;
}

/** primary key columns input for table: chapters */
export interface ChaptersPkColumnsInput {
  id: Scalars['Int']['input'];
}

/** select columns of table "chapters" */
export enum ChaptersSelectColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ChapterTitleEn = 'chapter_title_en',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Phase = 'phase'
}

/** input type for updating data in table "chapters" */
export interface ChaptersSetInput {
  chapter_number?: InputMaybe<Scalars['String']['input']>;
  chapter_title?: InputMaybe<Scalars['String']['input']>;
  chapter_title_en?: InputMaybe<Scalars['String']['input']>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
}

/** aggregate stddev on columns */
export interface ChaptersStddevFields {
  __typename?: 'chapters_stddev_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "chapters" */
export interface ChaptersStddevOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ChaptersStddevPopFields {
  __typename?: 'chapters_stddev_pop_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "chapters" */
export interface ChaptersStddevPopOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ChaptersStddevSampFields {
  __typename?: 'chapters_stddev_samp_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "chapters" */
export interface ChaptersStddevSampOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "chapters" */
export interface ChaptersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ChaptersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ChaptersStreamCursorValueInput {
  chapter_number?: InputMaybe<Scalars['String']['input']>;
  chapter_title?: InputMaybe<Scalars['String']['input']>;
  chapter_title_en?: InputMaybe<Scalars['String']['input']>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  new_id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
}

/** aggregate sum on columns */
export interface ChaptersSumFields {
  __typename?: 'chapters_sum_fields';
  cluster_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "chapters" */
export interface ChaptersSumOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** update columns of table "chapters" */
export enum ChaptersUpdateColumn {
  /** column name */
  ChapterNumber = 'chapter_number',
  /** column name */
  ChapterTitle = 'chapter_title',
  /** column name */
  ChapterTitleEn = 'chapter_title_en',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewId = 'new_id',
  /** column name */
  Phase = 'phase'
}

export interface ChaptersUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChaptersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChaptersSetInput>;
  /** filter the rows which have to be updated */
  where: ChaptersBoolExp;
}

/** aggregate var_pop on columns */
export interface ChaptersVarPopFields {
  __typename?: 'chapters_var_pop_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "chapters" */
export interface ChaptersVarPopOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface ChaptersVarSampFields {
  __typename?: 'chapters_var_samp_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "chapters" */
export interface ChaptersVarSampOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface ChaptersVarianceFields {
  __typename?: 'chapters_variance_fields';
  cluster_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "chapters" */
export interface ChaptersVarianceOrderBy {
  cluster_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export interface CitextComparisonExp {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
}

/** cluster of chapters */
export interface Clusters {
  __typename?: 'clusters';
  /** An array relationship */
  chapters: Array<Chapters>;
  /** An aggregate relationship */
  chapters_aggregate: ChaptersAggregate;
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  title_en?: Maybe<Scalars['String']['output']>;
}


/** cluster of chapters */
export interface ClustersChaptersArgs {
  distinct_on?: InputMaybe<Array<ChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChaptersOrderBy>>;
  where?: InputMaybe<ChaptersBoolExp>;
}


/** cluster of chapters */
export interface ClustersChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChaptersOrderBy>>;
  where?: InputMaybe<ChaptersBoolExp>;
}

/** aggregated selection of "clusters" */
export interface ClustersAggregate {
  __typename?: 'clusters_aggregate';
  aggregate?: Maybe<ClustersAggregateFields>;
  nodes: Array<Clusters>;
}

/** aggregate fields of "clusters" */
export interface ClustersAggregateFields {
  __typename?: 'clusters_aggregate_fields';
  avg?: Maybe<ClustersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ClustersMaxFields>;
  min?: Maybe<ClustersMinFields>;
  stddev?: Maybe<ClustersStddevFields>;
  stddev_pop?: Maybe<ClustersStddevPopFields>;
  stddev_samp?: Maybe<ClustersStddevSampFields>;
  sum?: Maybe<ClustersSumFields>;
  var_pop?: Maybe<ClustersVarPopFields>;
  var_samp?: Maybe<ClustersVarSampFields>;
  variance?: Maybe<ClustersVarianceFields>;
}


/** aggregate fields of "clusters" */
export interface ClustersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ClustersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface ClustersAvgFields {
  __typename?: 'clusters_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "clusters". All fields are combined with a logical 'AND'. */
export interface ClustersBoolExp {
  _and?: InputMaybe<Array<ClustersBoolExp>>;
  _not?: InputMaybe<ClustersBoolExp>;
  _or?: InputMaybe<Array<ClustersBoolExp>>;
  chapters?: InputMaybe<ChaptersBoolExp>;
  chapters_aggregate?: InputMaybe<ChaptersAggregateBoolExp>;
  color?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  title_en?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "clusters" */
export enum ClustersConstraint {
  /** unique or primary key constraint on columns "id" */
  ClustersPkey = 'clusters_pkey',
  /** unique or primary key constraint on columns "title_en" */
  ClustersTitleEnKey = 'clusters_title_en_key',
  /** unique or primary key constraint on columns "title" */
  ClustersTitleKey = 'clusters_title_key'
}

/** input type for incrementing numeric columns in table "clusters" */
export interface ClustersIncInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "clusters" */
export interface ClustersInsertInput {
  chapters?: InputMaybe<ChaptersArrRelInsertInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface ClustersMaxFields {
  __typename?: 'clusters_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface ClustersMinFields {
  __typename?: 'clusters_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "clusters" */
export interface ClustersMutationResponse {
  __typename?: 'clusters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Clusters>;
}

/** input type for inserting object relation for remote table "clusters" */
export interface ClustersObjRelInsertInput {
  data: ClustersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ClustersOnConflict>;
}

/** on_conflict condition type for table "clusters" */
export interface ClustersOnConflict {
  constraint: ClustersConstraint;
  update_columns?: Array<ClustersUpdateColumn>;
  where?: InputMaybe<ClustersBoolExp>;
}

/** Ordering options when selecting data from "clusters". */
export interface ClustersOrderBy {
  chapters_aggregate?: InputMaybe<ChaptersAggregateOrderBy>;
  color?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: clusters */
export interface ClustersPkColumnsInput {
  id: Scalars['Int']['input'];
}

/** select columns of table "clusters" */
export enum ClustersSelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

/** input type for updating data in table "clusters" */
export interface ClustersSetInput {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface ClustersStddevFields {
  __typename?: 'clusters_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface ClustersStddevPopFields {
  __typename?: 'clusters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface ClustersStddevSampFields {
  __typename?: 'clusters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "clusters" */
export interface ClustersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ClustersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ClustersStreamCursorValueInput {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface ClustersSumFields {
  __typename?: 'clusters_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "clusters" */
export enum ClustersUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export interface ClustersUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClustersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClustersSetInput>;
  /** filter the rows which have to be updated */
  where: ClustersBoolExp;
}

/** aggregate var_pop on columns */
export interface ClustersVarPopFields {
  __typename?: 'clusters_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface ClustersVarSampFields {
  __typename?: 'clusters_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface ClustersVarianceFields {
  __typename?: 'clusters_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface CompleteSignitureInput {
  chapterId: Scalars['Int']['input'];
  code: Scalars['String']['input'];
  progressReportId: Scalars['uuid']['input'];
}

export interface CompleteSignitureOutput {
  __typename?: 'completeSignitureOutput';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
}

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export interface DashboardAggregateEntity {
  __typename?: 'dashboardAggregateEntity';
  count?: Maybe<Scalars['bigint']['output']>;
}

/** Boolean expression to filter rows from the logical model for "dashboardAggregateEntity". All fields are combined with a logical 'AND'. */
export interface DashboardAggregateEntityBoolExpBoolExp {
  _and?: InputMaybe<Array<DashboardAggregateEntityBoolExpBoolExp>>;
  _not?: InputMaybe<DashboardAggregateEntityBoolExpBoolExp>;
  _or?: InputMaybe<Array<DashboardAggregateEntityBoolExpBoolExp>>;
  count?: InputMaybe<BigintComparisonExp>;
}

export enum DashboardAggregateEntityEnumName {
  /** column name */
  Count = 'count'
}

/** Ordering options when selecting data from "dashboardAggregateEntity". */
export interface DashboardAggregateEntityOrderBy {
  count?: InputMaybe<OrderBy>;
}

/** dashboardStatisticsAggregateNative Query Arguments */
export interface DashboardStatisticsAggregateArguments {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  role_type?: InputMaybe<Scalars['String']['input']>;
  taskCategory?: InputMaybe<Scalars['String']['input']>;
  taskNameSearch?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface DashboardStatisticsEntity {
  __typename?: 'dashboardStatisticsEntity';
  deadline?: Maybe<Scalars['date']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  task_name?: Maybe<Scalars['String']['output']>;
  task_status?: Maybe<Scalars['String']['output']>;
  task_type?: Maybe<Scalars['String']['output']>;
}

/** Boolean expression to filter rows from the logical model for "dashboardStatisticsEntity". All fields are combined with a logical 'AND'. */
export interface DashboardStatisticsEntityBoolExpBoolExp {
  _and?: InputMaybe<Array<DashboardStatisticsEntityBoolExpBoolExp>>;
  _not?: InputMaybe<DashboardStatisticsEntityBoolExpBoolExp>;
  _or?: InputMaybe<Array<DashboardStatisticsEntityBoolExpBoolExp>>;
  deadline?: InputMaybe<DateComparisonExp>;
  parent_id?: InputMaybe<StringComparisonExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  task_name?: InputMaybe<StringComparisonExp>;
  task_status?: InputMaybe<StringComparisonExp>;
  task_type?: InputMaybe<StringComparisonExp>;
}

export enum DashboardStatisticsEntityEnumName {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TaskName = 'task_name',
  /** column name */
  TaskStatus = 'task_status',
  /** column name */
  TaskType = 'task_type'
}

/** Ordering options when selecting data from "dashboardStatisticsEntity". */
export interface DashboardStatisticsEntityOrderBy {
  deadline?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  task_name?: InputMaybe<OrderBy>;
  task_status?: InputMaybe<OrderBy>;
  task_type?: InputMaybe<OrderBy>;
}

/** dashboardStatisticsNative Query Arguments */
export interface DashboardStatisticsArguments {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  role_type?: InputMaybe<Scalars['String']['input']>;
  taskCategory?: InputMaybe<Scalars['String']['input']>;
  taskNameSearch?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface DateComparisonExp {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
}

/** The folders of the system */
export interface DmsFolder {
  __typename?: 'dms_folder';
  change?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  children: Array<DmsFolder>;
  /** An aggregate relationship */
  children_aggregate: DmsFolderAggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  parent?: Maybe<DmsFolder>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  path: Scalars['String']['output'];
  type?: Maybe<DmsFolderTypeEnum>;
  updated_at: Scalars['timestamptz']['output'];
  view?: Maybe<Scalars['jsonb']['output']>;
}


/** The folders of the system */
export interface DmsFolderChangeArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** The folders of the system */
export interface DmsFolderChildrenArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


/** The folders of the system */
export interface DmsFolderChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


/** The folders of the system */
export interface DmsFolderViewArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "dms.folder" */
export interface DmsFolderAggregate {
  __typename?: 'dms_folder_aggregate';
  aggregate?: Maybe<DmsFolderAggregateFields>;
  nodes: Array<DmsFolder>;
}

export interface DmsFolderAggregateBoolExp {
  count?: InputMaybe<DmsFolderAggregateBoolExpCount>;
}

export interface DmsFolderAggregateBoolExpCount {
  arguments?: InputMaybe<Array<DmsFolderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DmsFolderBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "dms.folder" */
export interface DmsFolderAggregateFields {
  __typename?: 'dms_folder_aggregate_fields';
  avg?: Maybe<DmsFolderAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DmsFolderMaxFields>;
  min?: Maybe<DmsFolderMinFields>;
  stddev?: Maybe<DmsFolderStddevFields>;
  stddev_pop?: Maybe<DmsFolderStddevPopFields>;
  stddev_samp?: Maybe<DmsFolderStddevSampFields>;
  sum?: Maybe<DmsFolderSumFields>;
  var_pop?: Maybe<DmsFolderVarPopFields>;
  var_samp?: Maybe<DmsFolderVarSampFields>;
  variance?: Maybe<DmsFolderVarianceFields>;
}


/** aggregate fields of "dms.folder" */
export interface DmsFolderAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<DmsFolderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "dms.folder" */
export interface DmsFolderAggregateOrderBy {
  avg?: InputMaybe<DmsFolderAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DmsFolderMaxOrderBy>;
  min?: InputMaybe<DmsFolderMinOrderBy>;
  stddev?: InputMaybe<DmsFolderStddevOrderBy>;
  stddev_pop?: InputMaybe<DmsFolderStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DmsFolderStddevSampOrderBy>;
  sum?: InputMaybe<DmsFolderSumOrderBy>;
  var_pop?: InputMaybe<DmsFolderVarPopOrderBy>;
  var_samp?: InputMaybe<DmsFolderVarSampOrderBy>;
  variance?: InputMaybe<DmsFolderVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface DmsFolderAppendInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "dms.folder" */
export interface DmsFolderArrRelInsertInput {
  data: Array<DmsFolderInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DmsFolderOnConflict>;
}

/** aggregate avg on columns */
export interface DmsFolderAvgFields {
  __typename?: 'dms_folder_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "dms.folder" */
export interface DmsFolderAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "dms.folder". All fields are combined with a logical 'AND'. */
export interface DmsFolderBoolExp {
  _and?: InputMaybe<Array<DmsFolderBoolExp>>;
  _not?: InputMaybe<DmsFolderBoolExp>;
  _or?: InputMaybe<Array<DmsFolderBoolExp>>;
  change?: InputMaybe<JsonbComparisonExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  children?: InputMaybe<DmsFolderBoolExp>;
  children_aggregate?: InputMaybe<DmsFolderAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  feature?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  owner?: InputMaybe<IntComparisonExp>;
  parent?: InputMaybe<DmsFolderBoolExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  path?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<DmsFolderTypeEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  view?: InputMaybe<JsonbComparisonExp>;
}

/** unique or primary key constraints on table "dms.folder" */
export enum DmsFolderConstraint {
  /** unique or primary key constraint on columns "id" */
  FolderPkey = 'folder_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DmsFolderDeleteAtPathInput {
  change?: InputMaybe<Array<Scalars['String']['input']>>;
  view?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DmsFolderDeleteElemInput {
  change?: InputMaybe<Scalars['Int']['input']>;
  view?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DmsFolderDeleteKeyInput {
  change?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "dms.folder" */
export interface DmsFolderIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  owner?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "dms.folder" */
export interface DmsFolderInsertInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  children?: InputMaybe<DmsFolderArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  feature?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<DmsFolderObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DmsFolderTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate max on columns */
export interface DmsFolderMaxFields {
  __typename?: 'dms_folder_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "dms.folder" */
export interface DmsFolderMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  feature?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  path?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface DmsFolderMinFields {
  __typename?: 'dms_folder_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "dms.folder" */
export interface DmsFolderMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  feature?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  path?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "dms.folder" */
export interface DmsFolderMutationResponse {
  __typename?: 'dms_folder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DmsFolder>;
}

/** input type for inserting object relation for remote table "dms.folder" */
export interface DmsFolderObjRelInsertInput {
  data: DmsFolderInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DmsFolderOnConflict>;
}

/** on_conflict condition type for table "dms.folder" */
export interface DmsFolderOnConflict {
  constraint: DmsFolderConstraint;
  update_columns?: Array<DmsFolderUpdateColumn>;
  where?: InputMaybe<DmsFolderBoolExp>;
}

/** Ordering options when selecting data from "dms.folder". */
export interface DmsFolderOrderBy {
  change?: InputMaybe<OrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  children_aggregate?: InputMaybe<DmsFolderAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  feature?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
  parent?: InputMaybe<DmsFolderOrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  path?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  view?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: dms.folder */
export interface DmsFolderPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DmsFolderPrependInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "dms.folder" */
export enum DmsFolderSelectColumn {
  /** column name */
  Change = 'change',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  View = 'view'
}

/** input type for updating data in table "dms.folder" */
export interface DmsFolderSetInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  feature?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DmsFolderTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate stddev on columns */
export interface DmsFolderStddevFields {
  __typename?: 'dms_folder_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "dms.folder" */
export interface DmsFolderStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface DmsFolderStddevPopFields {
  __typename?: 'dms_folder_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "dms.folder" */
export interface DmsFolderStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface DmsFolderStddevSampFields {
  __typename?: 'dms_folder_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "dms.folder" */
export interface DmsFolderStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "dms_folder" */
export interface DmsFolderStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: DmsFolderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface DmsFolderStreamCursorValueInput {
  change?: InputMaybe<Scalars['jsonb']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  feature?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DmsFolderTypeEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate sum on columns */
export interface DmsFolderSumFields {
  __typename?: 'dms_folder_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "dms.folder" */
export interface DmsFolderSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** Can be created by the system or the user */
export interface DmsFolderType {
  __typename?: 'dms_folder_type';
  type: Scalars['String']['output'];
}

/** aggregated selection of "dms.folder_type" */
export interface DmsFolderTypeAggregate {
  __typename?: 'dms_folder_type_aggregate';
  aggregate?: Maybe<DmsFolderTypeAggregateFields>;
  nodes: Array<DmsFolderType>;
}

/** aggregate fields of "dms.folder_type" */
export interface DmsFolderTypeAggregateFields {
  __typename?: 'dms_folder_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DmsFolderTypeMaxFields>;
  min?: Maybe<DmsFolderTypeMinFields>;
}


/** aggregate fields of "dms.folder_type" */
export interface DmsFolderTypeAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<DmsFolderTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "dms.folder_type". All fields are combined with a logical 'AND'. */
export interface DmsFolderTypeBoolExp {
  _and?: InputMaybe<Array<DmsFolderTypeBoolExp>>;
  _not?: InputMaybe<DmsFolderTypeBoolExp>;
  _or?: InputMaybe<Array<DmsFolderTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "dms.folder_type" */
export enum DmsFolderTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  FolderTypePkey = 'folder_type_pkey'
}

export enum DmsFolderTypeEnum {
  System = 'SYSTEM',
  User = 'USER'
}

/** Boolean expression to compare columns of type "dms_folder_type_enum". All fields are combined with logical 'AND'. */
export interface DmsFolderTypeEnumComparisonExp {
  _eq?: InputMaybe<DmsFolderTypeEnum>;
  _in?: InputMaybe<Array<DmsFolderTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<DmsFolderTypeEnum>;
  _nin?: InputMaybe<Array<DmsFolderTypeEnum>>;
}

/** input type for inserting data into table "dms.folder_type" */
export interface DmsFolderTypeInsertInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface DmsFolderTypeMaxFields {
  __typename?: 'dms_folder_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface DmsFolderTypeMinFields {
  __typename?: 'dms_folder_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "dms.folder_type" */
export interface DmsFolderTypeMutationResponse {
  __typename?: 'dms_folder_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DmsFolderType>;
}

/** on_conflict condition type for table "dms.folder_type" */
export interface DmsFolderTypeOnConflict {
  constraint: DmsFolderTypeConstraint;
  update_columns?: Array<DmsFolderTypeUpdateColumn>;
  where?: InputMaybe<DmsFolderTypeBoolExp>;
}

/** Ordering options when selecting data from "dms.folder_type". */
export interface DmsFolderTypeOrderBy {
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: dms.folder_type */
export interface DmsFolderTypePkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "dms.folder_type" */
export enum DmsFolderTypeSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "dms.folder_type" */
export interface DmsFolderTypeSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "dms_folder_type" */
export interface DmsFolderTypeStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: DmsFolderTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface DmsFolderTypeStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "dms.folder_type" */
export enum DmsFolderTypeUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface DmsFolderTypeUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DmsFolderTypeSetInput>;
  /** filter the rows which have to be updated */
  where: DmsFolderTypeBoolExp;
}

/** update columns of table "dms.folder" */
export enum DmsFolderUpdateColumn {
  /** column name */
  Change = 'change',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  View = 'view'
}

export interface DmsFolderUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DmsFolderAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DmsFolderDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DmsFolderDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DmsFolderDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DmsFolderIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DmsFolderPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DmsFolderSetInput>;
  /** filter the rows which have to be updated */
  where: DmsFolderBoolExp;
}

/** aggregate var_pop on columns */
export interface DmsFolderVarPopFields {
  __typename?: 'dms_folder_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "dms.folder" */
export interface DmsFolderVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface DmsFolderVarSampFields {
  __typename?: 'dms_folder_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "dms.folder" */
export interface DmsFolderVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface DmsFolderVarianceFields {
  __typename?: 'dms_folder_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "dms.folder" */
export interface DmsFolderVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  owner?: InputMaybe<OrderBy>;
}

/** parent of the generic task */
export interface Documents {
  __typename?: 'documents';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  deadline: Scalars['date']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  document_type?: Maybe<TaskTypesEnum>;
  id: Scalars['uuid']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  status: TaskStatusesEnum;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: TaskAggregate;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
}


/** parent of the generic task */
export interface DocumentsDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** parent of the generic task */
export interface DocumentsTasksArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** parent of the generic task */
export interface DocumentsTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}

/** aggregated selection of "documents" */
export interface DocumentsAggregate {
  __typename?: 'documents_aggregate';
  aggregate?: Maybe<DocumentsAggregateFields>;
  nodes: Array<Documents>;
}

export interface DocumentsAggregateBoolExp {
  count?: InputMaybe<DocumentsAggregateBoolExpCount>;
}

export interface DocumentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<DocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DocumentsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "documents" */
export interface DocumentsAggregateFields {
  __typename?: 'documents_aggregate_fields';
  avg?: Maybe<DocumentsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentsMaxFields>;
  min?: Maybe<DocumentsMinFields>;
  stddev?: Maybe<DocumentsStddevFields>;
  stddev_pop?: Maybe<DocumentsStddevPopFields>;
  stddev_samp?: Maybe<DocumentsStddevSampFields>;
  sum?: Maybe<DocumentsSumFields>;
  var_pop?: Maybe<DocumentsVarPopFields>;
  var_samp?: Maybe<DocumentsVarSampFields>;
  variance?: Maybe<DocumentsVarianceFields>;
}


/** aggregate fields of "documents" */
export interface DocumentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<DocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "documents" */
export interface DocumentsAggregateOrderBy {
  avg?: InputMaybe<DocumentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DocumentsMaxOrderBy>;
  min?: InputMaybe<DocumentsMinOrderBy>;
  stddev?: InputMaybe<DocumentsStddevOrderBy>;
  stddev_pop?: InputMaybe<DocumentsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DocumentsStddevSampOrderBy>;
  sum?: InputMaybe<DocumentsSumOrderBy>;
  var_pop?: InputMaybe<DocumentsVarPopOrderBy>;
  var_samp?: InputMaybe<DocumentsVarSampOrderBy>;
  variance?: InputMaybe<DocumentsVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface DocumentsAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "documents" */
export interface DocumentsArrRelInsertInput {
  data: Array<DocumentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentsOnConflict>;
}

/** aggregate avg on columns */
export interface DocumentsAvgFields {
  __typename?: 'documents_avg_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "documents" */
export interface DocumentsAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export interface DocumentsBoolExp {
  _and?: InputMaybe<Array<DocumentsBoolExp>>;
  _not?: InputMaybe<DocumentsBoolExp>;
  _or?: InputMaybe<Array<DocumentsBoolExp>>;
  c_total_document_definition_tasks?: InputMaybe<BigintComparisonExp>;
  c_total_document_definition_tasks_done?: InputMaybe<BigintComparisonExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  document_type?: InputMaybe<TaskTypesEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  phase?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<TaskStatusesEnumComparisonExp>;
  tasks?: InputMaybe<TaskBoolExp>;
  tasks_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "documents" */
export enum DocumentsConstraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DocumentsDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DocumentsDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DocumentsDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "documents" */
export interface DocumentsIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "documents" */
export interface DocumentsInsertInput {
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_type?: InputMaybe<TaskTypesEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatusesEnum>;
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface DocumentsMaxFields {
  __typename?: 'documents_max_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "documents" */
export interface DocumentsMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phase?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface DocumentsMinFields {
  __typename?: 'documents_min_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "documents" */
export interface DocumentsMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phase?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "documents" */
export interface DocumentsMutationResponse {
  __typename?: 'documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Documents>;
}

/** input type for inserting object relation for remote table "documents" */
export interface DocumentsObjRelInsertInput {
  data: DocumentsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentsOnConflict>;
}

/** on_conflict condition type for table "documents" */
export interface DocumentsOnConflict {
  constraint: DocumentsConstraint;
  update_columns?: Array<DocumentsUpdateColumn>;
  where?: InputMaybe<DocumentsBoolExp>;
}

/** Ordering options when selecting data from "documents". */
export interface DocumentsOrderBy {
  c_total_document_definition_tasks?: InputMaybe<OrderBy>;
  c_total_document_definition_tasks_done?: InputMaybe<OrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  document_type?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phase?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: documents */
export interface DocumentsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DocumentsPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "documents" */
export enum DocumentsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "documents" */
export interface DocumentsSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_type?: InputMaybe<TaskTypesEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface DocumentsStddevFields {
  __typename?: 'documents_stddev_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "documents" */
export interface DocumentsStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface DocumentsStddevPopFields {
  __typename?: 'documents_stddev_pop_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "documents" */
export interface DocumentsStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface DocumentsStddevSampFields {
  __typename?: 'documents_stddev_samp_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "documents" */
export interface DocumentsStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "documents" */
export interface DocumentsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: DocumentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface DocumentsStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_type?: InputMaybe<TaskTypesEnum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface DocumentsSumFields {
  __typename?: 'documents_sum_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "documents" */
export interface DocumentsSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** update columns of table "documents" */
export enum DocumentsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface DocumentsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentsSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentsBoolExp;
}

/** aggregate var_pop on columns */
export interface DocumentsVarPopFields {
  __typename?: 'documents_var_pop_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "documents" */
export interface DocumentsVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface DocumentsVarSampFields {
  __typename?: 'documents_var_samp_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "documents" */
export interface DocumentsVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface DocumentsVarianceFields {
  __typename?: 'documents_variance_fields';
  /** A computed field, executes function "c_total_document_definition_tasks" */
  c_total_document_definition_tasks?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "c_total_document_definition_tasks_done" */
  c_total_document_definition_tasks_done?: Maybe<Scalars['bigint']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "documents" */
export interface DocumentsVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
}

/** columns and relationships of "error_logs" */
export interface ErrorLogs {
  __typename?: 'error_logs';
  created_at: Scalars['timestamptz']['output'];
  endpoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  ip: Scalars['String']['output'];
  payload: Scalars['String']['output'];
  response?: Maybe<Scalars['String']['output']>;
  solved?: Maybe<Scalars['Boolean']['output']>;
  solved_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  solved_by_user?: Maybe<Users>;
  solved_date?: Maybe<Scalars['timestamptz']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "error_logs" */
export interface ErrorLogsAggregate {
  __typename?: 'error_logs_aggregate';
  aggregate?: Maybe<ErrorLogsAggregateFields>;
  nodes: Array<ErrorLogs>;
}

/** aggregate fields of "error_logs" */
export interface ErrorLogsAggregateFields {
  __typename?: 'error_logs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ErrorLogsMaxFields>;
  min?: Maybe<ErrorLogsMinFields>;
}


/** aggregate fields of "error_logs" */
export interface ErrorLogsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ErrorLogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "error_logs". All fields are combined with a logical 'AND'. */
export interface ErrorLogsBoolExp {
  _and?: InputMaybe<Array<ErrorLogsBoolExp>>;
  _not?: InputMaybe<ErrorLogsBoolExp>;
  _or?: InputMaybe<Array<ErrorLogsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  endpoint?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ip?: InputMaybe<StringComparisonExp>;
  payload?: InputMaybe<StringComparisonExp>;
  response?: InputMaybe<StringComparisonExp>;
  solved?: InputMaybe<BooleanComparisonExp>;
  solved_by?: InputMaybe<UuidComparisonExp>;
  solved_by_user?: InputMaybe<UsersBoolExp>;
  solved_date?: InputMaybe<TimestamptzComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "error_logs" */
export enum ErrorLogsConstraint {
  /** unique or primary key constraint on columns "id" */
  ErrorLogsPkey = 'error_logs_pkey'
}

/** input type for inserting data into table "error_logs" */
export interface ErrorLogsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  solved?: InputMaybe<Scalars['Boolean']['input']>;
  solved_by?: InputMaybe<Scalars['uuid']['input']>;
  solved_by_user?: InputMaybe<UsersObjRelInsertInput>;
  solved_date?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface ErrorLogsMaxFields {
  __typename?: 'error_logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  solved_by?: Maybe<Scalars['uuid']['output']>;
  solved_date?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregate min on columns */
export interface ErrorLogsMinFields {
  __typename?: 'error_logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  solved_by?: Maybe<Scalars['uuid']['output']>;
  solved_date?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** response of any mutation on the table "error_logs" */
export interface ErrorLogsMutationResponse {
  __typename?: 'error_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ErrorLogs>;
}

/** on_conflict condition type for table "error_logs" */
export interface ErrorLogsOnConflict {
  constraint: ErrorLogsConstraint;
  update_columns?: Array<ErrorLogsUpdateColumn>;
  where?: InputMaybe<ErrorLogsBoolExp>;
}

/** Ordering options when selecting data from "error_logs". */
export interface ErrorLogsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  endpoint?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ip?: InputMaybe<OrderBy>;
  payload?: InputMaybe<OrderBy>;
  response?: InputMaybe<OrderBy>;
  solved?: InputMaybe<OrderBy>;
  solved_by?: InputMaybe<OrderBy>;
  solved_by_user?: InputMaybe<UsersOrderBy>;
  solved_date?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: error_logs */
export interface ErrorLogsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "error_logs" */
export enum ErrorLogsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Payload = 'payload',
  /** column name */
  Response = 'response',
  /** column name */
  Solved = 'solved',
  /** column name */
  SolvedBy = 'solved_by',
  /** column name */
  SolvedDate = 'solved_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "error_logs" */
export interface ErrorLogsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  solved?: InputMaybe<Scalars['Boolean']['input']>;
  solved_by?: InputMaybe<Scalars['uuid']['input']>;
  solved_date?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "error_logs" */
export interface ErrorLogsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ErrorLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ErrorLogsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  solved?: InputMaybe<Scalars['Boolean']['input']>;
  solved_by?: InputMaybe<Scalars['uuid']['input']>;
  solved_date?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "error_logs" */
export enum ErrorLogsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Payload = 'payload',
  /** column name */
  Response = 'response',
  /** column name */
  Solved = 'solved',
  /** column name */
  SolvedBy = 'solved_by',
  /** column name */
  SolvedDate = 'solved_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export interface ErrorLogsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ErrorLogsSetInput>;
  /** filter the rows which have to be updated */
  where: ErrorLogsBoolExp;
}

/** columns and relationships of "event_notes" */
export interface EventNotes {
  __typename?: 'event_notes';
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  note: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
}

/** aggregated selection of "event_notes" */
export interface EventNotesAggregate {
  __typename?: 'event_notes_aggregate';
  aggregate?: Maybe<EventNotesAggregateFields>;
  nodes: Array<EventNotes>;
}

export interface EventNotesAggregateBoolExp {
  count?: InputMaybe<EventNotesAggregateBoolExpCount>;
}

export interface EventNotesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<EventNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EventNotesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "event_notes" */
export interface EventNotesAggregateFields {
  __typename?: 'event_notes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<EventNotesMaxFields>;
  min?: Maybe<EventNotesMinFields>;
}


/** aggregate fields of "event_notes" */
export interface EventNotesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<EventNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "event_notes" */
export interface EventNotesAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventNotesMaxOrderBy>;
  min?: InputMaybe<EventNotesMinOrderBy>;
}

/** input type for inserting array relation for remote table "event_notes" */
export interface EventNotesArrRelInsertInput {
  data: Array<EventNotesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventNotesOnConflict>;
}

/** Boolean expression to filter rows from the table "event_notes". All fields are combined with a logical 'AND'. */
export interface EventNotesBoolExp {
  _and?: InputMaybe<Array<EventNotesBoolExp>>;
  _not?: InputMaybe<EventNotesBoolExp>;
  _or?: InputMaybe<Array<EventNotesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  event?: InputMaybe<EventsBoolExp>;
  event_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
}

/** unique or primary key constraints on table "event_notes" */
export enum EventNotesConstraint {
  /** unique or primary key constraint on columns "id" */
  EventNotesPkey = 'event_notes_pkey'
}

/** input type for inserting data into table "event_notes" */
export interface EventNotesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  event?: InputMaybe<EventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface EventNotesMaxFields {
  __typename?: 'event_notes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "event_notes" */
export interface EventNotesMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface EventNotesMinFields {
  __typename?: 'event_notes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "event_notes" */
export interface EventNotesMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "event_notes" */
export interface EventNotesMutationResponse {
  __typename?: 'event_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventNotes>;
}

/** on_conflict condition type for table "event_notes" */
export interface EventNotesOnConflict {
  constraint: EventNotesConstraint;
  update_columns?: Array<EventNotesUpdateColumn>;
  where?: InputMaybe<EventNotesBoolExp>;
}

/** Ordering options when selecting data from "event_notes". */
export interface EventNotesOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventsOrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
}

/** primary key columns input for table: event_notes */
export interface EventNotesPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "event_notes" */
export enum EventNotesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "event_notes" */
export interface EventNotesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "event_notes" */
export interface EventNotesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EventNotesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EventNotesStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "event_notes" */
export enum EventNotesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface EventNotesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventNotesSetInput>;
  /** filter the rows which have to be updated */
  where: EventNotesBoolExp;
}

/** columns and relationships of "event_typology" */
export interface EventTypology {
  __typename?: 'event_typology';
  typology: Scalars['String']['output'];
}

/** aggregated selection of "event_typology" */
export interface EventTypologyAggregate {
  __typename?: 'event_typology_aggregate';
  aggregate?: Maybe<EventTypologyAggregateFields>;
  nodes: Array<EventTypology>;
}

/** aggregate fields of "event_typology" */
export interface EventTypologyAggregateFields {
  __typename?: 'event_typology_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<EventTypologyMaxFields>;
  min?: Maybe<EventTypologyMinFields>;
}


/** aggregate fields of "event_typology" */
export interface EventTypologyAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<EventTypologySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "event_typology". All fields are combined with a logical 'AND'. */
export interface EventTypologyBoolExp {
  _and?: InputMaybe<Array<EventTypologyBoolExp>>;
  _not?: InputMaybe<EventTypologyBoolExp>;
  _or?: InputMaybe<Array<EventTypologyBoolExp>>;
  typology?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "event_typology" */
export enum EventTypologyConstraint {
  /** unique or primary key constraint on columns "typology" */
  EventTypologyPkey = 'event_typology_pkey'
}

export enum EventTypologyEnum {
  GrupetNderinstitucionalePuneIntegrim = 'GRUPET_NDERINSTITUCIONALE_PUNE_INTEGRIM',
  GrupiNegociator = 'GRUPI_NEGOCIATOR',
  PlatformaPartneritetIntegrimiEvropian = 'PLATFORMA_PARTNERITET_INTEGRIMI_EVROPIAN',
  RrjetiIntegrimitEvropian = 'RRJETI_INTEGRIMIT_EVROPIAN',
  Tjeter = 'TJETER'
}

/** Boolean expression to compare columns of type "event_typology_enum". All fields are combined with logical 'AND'. */
export interface EventTypologyEnumComparisonExp {
  _eq?: InputMaybe<EventTypologyEnum>;
  _in?: InputMaybe<Array<EventTypologyEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<EventTypologyEnum>;
  _nin?: InputMaybe<Array<EventTypologyEnum>>;
}

/** input type for inserting data into table "event_typology" */
export interface EventTypologyInsertInput {
  typology?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface EventTypologyMaxFields {
  __typename?: 'event_typology_max_fields';
  typology?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface EventTypologyMinFields {
  __typename?: 'event_typology_min_fields';
  typology?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "event_typology" */
export interface EventTypologyMutationResponse {
  __typename?: 'event_typology_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventTypology>;
}

/** on_conflict condition type for table "event_typology" */
export interface EventTypologyOnConflict {
  constraint: EventTypologyConstraint;
  update_columns?: Array<EventTypologyUpdateColumn>;
  where?: InputMaybe<EventTypologyBoolExp>;
}

/** Ordering options when selecting data from "event_typology". */
export interface EventTypologyOrderBy {
  typology?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: event_typology */
export interface EventTypologyPkColumnsInput {
  typology: Scalars['String']['input'];
}

/** select columns of table "event_typology" */
export enum EventTypologySelectColumn {
  /** column name */
  Typology = 'typology'
}

/** input type for updating data in table "event_typology" */
export interface EventTypologySetInput {
  typology?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "event_typology" */
export interface EventTypologyStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EventTypologyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EventTypologyStreamCursorValueInput {
  typology?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "event_typology" */
export enum EventTypologyUpdateColumn {
  /** column name */
  Typology = 'typology'
}

export interface EventTypologyUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventTypologySetInput>;
  /** filter the rows which have to be updated */
  where: EventTypologyBoolExp;
}

/** columns and relationships of "event_users" */
export interface EventUsers {
  __typename?: 'event_users';
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "event_users" */
export interface EventUsersAggregate {
  __typename?: 'event_users_aggregate';
  aggregate?: Maybe<EventUsersAggregateFields>;
  nodes: Array<EventUsers>;
}

export interface EventUsersAggregateBoolExp {
  count?: InputMaybe<EventUsersAggregateBoolExpCount>;
}

export interface EventUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<EventUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EventUsersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "event_users" */
export interface EventUsersAggregateFields {
  __typename?: 'event_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<EventUsersMaxFields>;
  min?: Maybe<EventUsersMinFields>;
}


/** aggregate fields of "event_users" */
export interface EventUsersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<EventUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "event_users" */
export interface EventUsersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EventUsersMaxOrderBy>;
  min?: InputMaybe<EventUsersMinOrderBy>;
}

/** input type for inserting array relation for remote table "event_users" */
export interface EventUsersArrRelInsertInput {
  data: Array<EventUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventUsersOnConflict>;
}

/** Boolean expression to filter rows from the table "event_users". All fields are combined with a logical 'AND'. */
export interface EventUsersBoolExp {
  _and?: InputMaybe<Array<EventUsersBoolExp>>;
  _not?: InputMaybe<EventUsersBoolExp>;
  _or?: InputMaybe<Array<EventUsersBoolExp>>;
  event?: InputMaybe<EventsBoolExp>;
  event_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "event_users" */
export enum EventUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  EventUsersPkey = 'event_users_pkey'
}

/** input type for inserting data into table "event_users" */
export interface EventUsersInsertInput {
  event?: InputMaybe<EventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface EventUsersMaxFields {
  __typename?: 'event_users_max_fields';
  event_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "event_users" */
export interface EventUsersMaxOrderBy {
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface EventUsersMinFields {
  __typename?: 'event_users_min_fields';
  event_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "event_users" */
export interface EventUsersMinOrderBy {
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "event_users" */
export interface EventUsersMutationResponse {
  __typename?: 'event_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EventUsers>;
}

/** on_conflict condition type for table "event_users" */
export interface EventUsersOnConflict {
  constraint: EventUsersConstraint;
  update_columns?: Array<EventUsersUpdateColumn>;
  where?: InputMaybe<EventUsersBoolExp>;
}

/** Ordering options when selecting data from "event_users". */
export interface EventUsersOrderBy {
  event?: InputMaybe<EventsOrderBy>;
  event_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: event_users */
export interface EventUsersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "event_users" */
export enum EventUsersSelectColumn {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "event_users" */
export interface EventUsersSetInput {
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "event_users" */
export interface EventUsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EventUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EventUsersStreamCursorValueInput {
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "event_users" */
export enum EventUsersUpdateColumn {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export interface EventUsersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventUsersSetInput>;
  /** filter the rows which have to be updated */
  where: EventUsersBoolExp;
}

/** columns and relationships of "events" */
export interface Events {
  __typename?: 'events';
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  event_date?: Maybe<Scalars['timestamptz']['output']>;
  event_date_deleted?: Maybe<Scalars['time']['output']>;
  /** An array relationship */
  event_notes: Array<EventNotes>;
  /** An aggregate relationship */
  event_notes_aggregate: EventNotesAggregate;
  /** An array relationship */
  event_users: Array<EventUsers>;
  /** An aggregate relationship */
  event_users_aggregate: EventUsersAggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: TaskAggregate;
  title: Scalars['String']['output'];
  typology?: Maybe<EventTypologyEnum>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
}


/** columns and relationships of "events" */
export interface EventsDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "events" */
export interface EventsEventNotesArgs {
  distinct_on?: InputMaybe<Array<EventNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventNotesOrderBy>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


/** columns and relationships of "events" */
export interface EventsEventNotesAggregateArgs {
  distinct_on?: InputMaybe<Array<EventNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventNotesOrderBy>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


/** columns and relationships of "events" */
export interface EventsEventUsersArgs {
  distinct_on?: InputMaybe<Array<EventUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventUsersOrderBy>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


/** columns and relationships of "events" */
export interface EventsEventUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<EventUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventUsersOrderBy>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


/** columns and relationships of "events" */
export interface EventsTasksArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** columns and relationships of "events" */
export interface EventsTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}

/** aggregated selection of "events" */
export interface EventsAggregate {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<EventsAggregateFields>;
  nodes: Array<Events>;
}

/** aggregate fields of "events" */
export interface EventsAggregateFields {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<EventsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EventsMaxFields>;
  min?: Maybe<EventsMinFields>;
  stddev?: Maybe<EventsStddevFields>;
  stddev_pop?: Maybe<EventsStddevPopFields>;
  stddev_samp?: Maybe<EventsStddevSampFields>;
  sum?: Maybe<EventsSumFields>;
  var_pop?: Maybe<EventsVarPopFields>;
  var_samp?: Maybe<EventsVarSampFields>;
  variance?: Maybe<EventsVarianceFields>;
}


/** aggregate fields of "events" */
export interface EventsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<EventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface EventsAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate avg on columns */
export interface EventsAvgFields {
  __typename?: 'events_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export interface EventsBoolExp {
  _and?: InputMaybe<Array<EventsBoolExp>>;
  _not?: InputMaybe<EventsBoolExp>;
  _or?: InputMaybe<Array<EventsBoolExp>>;
  content?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  end_date?: InputMaybe<TimestamptzComparisonExp>;
  event_date?: InputMaybe<TimestamptzComparisonExp>;
  event_date_deleted?: InputMaybe<TimeComparisonExp>;
  event_notes?: InputMaybe<EventNotesBoolExp>;
  event_notes_aggregate?: InputMaybe<EventNotesAggregateBoolExp>;
  event_users?: InputMaybe<EventUsersBoolExp>;
  event_users_aggregate?: InputMaybe<EventUsersAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  tasks?: InputMaybe<TaskBoolExp>;
  tasks_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  typology?: InputMaybe<EventTypologyEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
}

/** unique or primary key constraints on table "events" */
export enum EventsConstraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface EventsDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface EventsDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface EventsDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "events" */
export interface EventsIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "events" */
export interface EventsInsertInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date_deleted?: InputMaybe<Scalars['time']['input']>;
  event_notes?: InputMaybe<EventNotesArrRelInsertInput>;
  event_users?: InputMaybe<EventUsersArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  typology?: InputMaybe<EventTypologyEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface EventsMaxFields {
  __typename?: 'events_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  event_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface EventsMinFields {
  __typename?: 'events_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  event_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "events" */
export interface EventsMutationResponse {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
}

/** input type for inserting object relation for remote table "events" */
export interface EventsObjRelInsertInput {
  data: EventsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EventsOnConflict>;
}

/** on_conflict condition type for table "events" */
export interface EventsOnConflict {
  constraint: EventsConstraint;
  update_columns?: Array<EventsUpdateColumn>;
  where?: InputMaybe<EventsBoolExp>;
}

/** Ordering options when selecting data from "events". */
export interface EventsOrderBy {
  content?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  event_date?: InputMaybe<OrderBy>;
  event_date_deleted?: InputMaybe<OrderBy>;
  event_notes_aggregate?: InputMaybe<EventNotesAggregateOrderBy>;
  event_users_aggregate?: InputMaybe<EventUsersAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  typology?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
}

/** primary key columns input for table: events */
export interface EventsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface EventsPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "events" */
export enum EventsSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventDateDeleted = 'event_date_deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Typology = 'typology',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "events" */
export interface EventsSetInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date_deleted?: InputMaybe<Scalars['time']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typology?: InputMaybe<EventTypologyEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface EventsStddevFields {
  __typename?: 'events_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface EventsStddevPopFields {
  __typename?: 'events_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface EventsStddevSampFields {
  __typename?: 'events_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "events" */
export interface EventsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EventsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EventsStreamCursorValueInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_date_deleted?: InputMaybe<Scalars['time']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typology?: InputMaybe<EventTypologyEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface EventsSumFields {
  __typename?: 'events_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "events" */
export enum EventsUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventDateDeleted = 'event_date_deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Typology = 'typology',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface EventsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EventsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EventsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EventsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EventsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EventsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventsSetInput>;
  /** filter the rows which have to be updated */
  where: EventsBoolExp;
}

/** aggregate var_pop on columns */
export interface EventsVarPopFields {
  __typename?: 'events_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface EventsVarSampFields {
  __typename?: 'events_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface EventsVarianceFields {
  __typename?: 'events_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

export interface FolderPathModel {
  __typename?: 'folderPathModel';
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
}

/** Boolean expression to filter rows from the logical model for "folderPathModel". All fields are combined with a logical 'AND'. */
export interface FolderPathModelBoolExpBoolExp {
  _and?: InputMaybe<Array<FolderPathModelBoolExpBoolExp>>;
  _not?: InputMaybe<FolderPathModelBoolExpBoolExp>;
  _or?: InputMaybe<Array<FolderPathModelBoolExpBoolExp>>;
  dms_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
}

export enum FolderPathModelEnumName {
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** Ordering options when selecting data from "folderPathModel". */
export interface FolderPathModelOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
}

/** getFolderPathNative Query Arguments */
export interface GetFolderPathArguments {
  folder_id: Scalars['uuid']['input'];
}

/** Chapter groups */
export interface Gnpie {
  __typename?: 'gnpie';
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  cluster?: Maybe<Clusters>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  created_by_role?: Maybe<AuthRolesEnum>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  is_main_negotiator?: Maybe<Scalars['Boolean']['output']>;
  role: AuthRolesEnum;
  /** An object relationship */
  roleByRole: AuthRoles;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
}

/** gnpieUsersByChapterNative Query Arguments */
export interface GnpieUsersByChapterArguments {
  chapter_id: Scalars['Int']['input'];
}

/** aggregated selection of "gnpie" */
export interface GnpieAggregate {
  __typename?: 'gnpie_aggregate';
  aggregate?: Maybe<GnpieAggregateFields>;
  nodes: Array<Gnpie>;
}

export interface GnpieAggregateBoolExp {
  bool_and?: InputMaybe<GnpieAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<GnpieAggregateBoolExpBoolOr>;
  count?: InputMaybe<GnpieAggregateBoolExpCount>;
}

export interface GnpieAggregateBoolExpBoolAnd {
  arguments: GnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GnpieBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface GnpieAggregateBoolExpBoolOr {
  arguments: GnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GnpieBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface GnpieAggregateBoolExpCount {
  arguments?: InputMaybe<Array<GnpieSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GnpieBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "gnpie" */
export interface GnpieAggregateFields {
  __typename?: 'gnpie_aggregate_fields';
  avg?: Maybe<GnpieAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GnpieMaxFields>;
  min?: Maybe<GnpieMinFields>;
  stddev?: Maybe<GnpieStddevFields>;
  stddev_pop?: Maybe<GnpieStddevPopFields>;
  stddev_samp?: Maybe<GnpieStddevSampFields>;
  sum?: Maybe<GnpieSumFields>;
  var_pop?: Maybe<GnpieVarPopFields>;
  var_samp?: Maybe<GnpieVarSampFields>;
  variance?: Maybe<GnpieVarianceFields>;
}


/** aggregate fields of "gnpie" */
export interface GnpieAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<GnpieSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "gnpie" */
export interface GnpieAggregateOrderBy {
  avg?: InputMaybe<GnpieAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GnpieMaxOrderBy>;
  min?: InputMaybe<GnpieMinOrderBy>;
  stddev?: InputMaybe<GnpieStddevOrderBy>;
  stddev_pop?: InputMaybe<GnpieStddevPopOrderBy>;
  stddev_samp?: InputMaybe<GnpieStddevSampOrderBy>;
  sum?: InputMaybe<GnpieSumOrderBy>;
  var_pop?: InputMaybe<GnpieVarPopOrderBy>;
  var_samp?: InputMaybe<GnpieVarSampOrderBy>;
  variance?: InputMaybe<GnpieVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "gnpie" */
export interface GnpieArrRelInsertInput {
  data: Array<GnpieInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<GnpieOnConflict>;
}

/** aggregate avg on columns */
export interface GnpieAvgFields {
  __typename?: 'gnpie_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "gnpie" */
export interface GnpieAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "gnpie". All fields are combined with a logical 'AND'. */
export interface GnpieBoolExp {
  _and?: InputMaybe<Array<GnpieBoolExp>>;
  _not?: InputMaybe<GnpieBoolExp>;
  _or?: InputMaybe<Array<GnpieBoolExp>>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  cluster?: InputMaybe<ClustersBoolExp>;
  cluster_id?: InputMaybe<IntComparisonExp>;
  created_by_role?: InputMaybe<AuthRolesEnumComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_main_negotiator?: InputMaybe<BooleanComparisonExp>;
  role?: InputMaybe<AuthRolesEnumComparisonExp>;
  roleByRole?: InputMaybe<AuthRolesBoolExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "gnpie" */
export enum GnpieConstraint {
  /** unique or primary key constraint on columns "id" */
  GnpiePkey = 'gnpie_pkey'
}

/** input type for incrementing numeric columns in table "gnpie" */
export interface GnpieIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "gnpie" */
export interface GnpieInsertInput {
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  cluster?: InputMaybe<ClustersObjRelInsertInput>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_role?: InputMaybe<AuthRolesEnum>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_main_negotiator?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<AuthRolesEnum>;
  roleByRole?: InputMaybe<AuthRolesObjRelInsertInput>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface GnpieMaxFields {
  __typename?: 'gnpie_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "gnpie" */
export interface GnpieMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface GnpieMinFields {
  __typename?: 'gnpie_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "gnpie" */
export interface GnpieMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "gnpie" */
export interface GnpieMutationResponse {
  __typename?: 'gnpie_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Gnpie>;
}

/** on_conflict condition type for table "gnpie" */
export interface GnpieOnConflict {
  constraint: GnpieConstraint;
  update_columns?: Array<GnpieUpdateColumn>;
  where?: InputMaybe<GnpieBoolExp>;
}

/** Ordering options when selecting data from "gnpie". */
export interface GnpieOrderBy {
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  cluster?: InputMaybe<ClustersOrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  created_by_role?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_main_negotiator?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  roleByRole?: InputMaybe<AuthRolesOrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: gnpie */
export interface GnpiePkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "gnpie" */
export enum GnpieSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedByRole = 'created_by_role',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

/** select "gnpie_aggregate_bool_exp_bool_and_arguments_columns" columns of table "gnpie" */
export enum GnpieSelectColumnGnpieAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

/** select "gnpie_aggregate_bool_exp_bool_or_arguments_columns" columns of table "gnpie" */
export enum GnpieSelectColumnGnpieAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsMainNegotiator = 'is_main_negotiator'
}

/** input type for updating data in table "gnpie" */
export interface GnpieSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_role?: InputMaybe<AuthRolesEnum>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_main_negotiator?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<AuthRolesEnum>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface GnpieStddevFields {
  __typename?: 'gnpie_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "gnpie" */
export interface GnpieStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface GnpieStddevPopFields {
  __typename?: 'gnpie_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "gnpie" */
export interface GnpieStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface GnpieStddevSampFields {
  __typename?: 'gnpie_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "gnpie" */
export interface GnpieStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "gnpie" */
export interface GnpieStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: GnpieStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface GnpieStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_role?: InputMaybe<AuthRolesEnum>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_main_negotiator?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<AuthRolesEnum>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface GnpieSumFields {
  __typename?: 'gnpie_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  cluster_id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "gnpie" */
export interface GnpieSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** update columns of table "gnpie" */
export enum GnpieUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedByRole = 'created_by_role',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Role = 'role',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UserId = 'user_id'
}

export interface GnpieUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GnpieIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GnpieSetInput>;
  /** filter the rows which have to be updated */
  where: GnpieBoolExp;
}

export interface GnpieUser {
  __typename?: 'gnpie_user';
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_main_negotiator?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Scalars['json']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
}


export interface GnpieUserRolesArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** Boolean expression to filter rows from the logical model for "gnpie_user". All fields are combined with a logical 'AND'. */
export interface GnpieUserBoolExpBoolExp {
  _and?: InputMaybe<Array<GnpieUserBoolExpBoolExp>>;
  _not?: InputMaybe<GnpieUserBoolExpBoolExp>;
  _or?: InputMaybe<Array<GnpieUserBoolExpBoolExp>>;
  display_name?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_main_negotiator?: InputMaybe<BooleanComparisonExp>;
  roles?: InputMaybe<JsonComparisonExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
}

export enum GnpieUserEnumName {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsMainNegotiator = 'is_main_negotiator',
  /** column name */
  Roles = 'roles',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

/** Ordering options when selecting data from "gnpie_user". */
export interface GnpieUserOrderBy {
  display_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_main_negotiator?: InputMaybe<OrderBy>;
  roles?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_pop on columns */
export interface GnpieVarPopFields {
  __typename?: 'gnpie_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "gnpie" */
export interface GnpieVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface GnpieVarSampFields {
  __typename?: 'gnpie_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "gnpie" */
export interface GnpieVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface GnpieVarianceFields {
  __typename?: 'gnpie_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "gnpie" */
export interface GnpieVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  cluster_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** contains list of institutions */
export interface Institutions {
  __typename?: 'institutions';
  /** An array relationship */
  acquis_institutions: Array<PkieAcquisInstitutions>;
  /** An aggregate relationship */
  acquis_institutions_aggregate: PkieAcquisInstitutionsAggregate;
  /** An array relationship */
  child_institutions: Array<Institutions>;
  /** An aggregate relationship */
  child_institutions_aggregate: InstitutionsAggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  parent_institution?: Maybe<Institutions>;
  qbz_id?: Maybe<Scalars['Int']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TypeInstitutionsEnum>;
  /** An object relationship */
  type_institution?: Maybe<TypeInstitutions>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
}


/** contains list of institutions */
export interface InstitutionsAcquisInstitutionsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsAcquisInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsChildInstitutionsArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsChildInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsUsersArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}


/** contains list of institutions */
export interface InstitutionsUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}

/** aggregated selection of "institutions" */
export interface InstitutionsAggregate {
  __typename?: 'institutions_aggregate';
  aggregate?: Maybe<InstitutionsAggregateFields>;
  nodes: Array<Institutions>;
}

export interface InstitutionsAggregateBoolExp {
  bool_and?: InputMaybe<InstitutionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InstitutionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<InstitutionsAggregateBoolExpCount>;
}

export interface InstitutionsAggregateBoolExpBoolAnd {
  arguments: InstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InstitutionsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface InstitutionsAggregateBoolExpBoolOr {
  arguments: InstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InstitutionsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface InstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<InstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InstitutionsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "institutions" */
export interface InstitutionsAggregateFields {
  __typename?: 'institutions_aggregate_fields';
  avg?: Maybe<InstitutionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<InstitutionsMaxFields>;
  min?: Maybe<InstitutionsMinFields>;
  stddev?: Maybe<InstitutionsStddevFields>;
  stddev_pop?: Maybe<InstitutionsStddevPopFields>;
  stddev_samp?: Maybe<InstitutionsStddevSampFields>;
  sum?: Maybe<InstitutionsSumFields>;
  var_pop?: Maybe<InstitutionsVarPopFields>;
  var_samp?: Maybe<InstitutionsVarSampFields>;
  variance?: Maybe<InstitutionsVarianceFields>;
}


/** aggregate fields of "institutions" */
export interface InstitutionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<InstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "institutions" */
export interface InstitutionsAggregateOrderBy {
  avg?: InputMaybe<InstitutionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InstitutionsMaxOrderBy>;
  min?: InputMaybe<InstitutionsMinOrderBy>;
  stddev?: InputMaybe<InstitutionsStddevOrderBy>;
  stddev_pop?: InputMaybe<InstitutionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InstitutionsStddevSampOrderBy>;
  sum?: InputMaybe<InstitutionsSumOrderBy>;
  var_pop?: InputMaybe<InstitutionsVarPopOrderBy>;
  var_samp?: InputMaybe<InstitutionsVarSampOrderBy>;
  variance?: InputMaybe<InstitutionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "institutions" */
export interface InstitutionsArrRelInsertInput {
  data: Array<InstitutionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<InstitutionsOnConflict>;
}

/** aggregate avg on columns */
export interface InstitutionsAvgFields {
  __typename?: 'institutions_avg_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "institutions" */
export interface InstitutionsAvgOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "institutions". All fields are combined with a logical 'AND'. */
export interface InstitutionsBoolExp {
  _and?: InputMaybe<Array<InstitutionsBoolExp>>;
  _not?: InputMaybe<InstitutionsBoolExp>;
  _or?: InputMaybe<Array<InstitutionsBoolExp>>;
  acquis_institutions?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<PkieAcquisInstitutionsAggregateBoolExp>;
  child_institutions?: InputMaybe<InstitutionsBoolExp>;
  child_institutions_aggregate?: InputMaybe<InstitutionsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  parent_institution?: InputMaybe<InstitutionsBoolExp>;
  qbz_id?: InputMaybe<IntComparisonExp>;
  short_name?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<TypeInstitutionsEnumComparisonExp>;
  type_institution?: InputMaybe<TypeInstitutionsBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  users?: InputMaybe<UsersBoolExp>;
  users_aggregate?: InputMaybe<UsersAggregateBoolExp>;
}

/** unique or primary key constraints on table "institutions" */
export enum InstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  InstitutionsPkey = 'institutions_pkey'
}

/** input type for incrementing numeric columns in table "institutions" */
export interface InstitutionsIncInput {
  qbz_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "institutions" */
export interface InstitutionsInsertInput {
  acquis_institutions?: InputMaybe<PkieAcquisInstitutionsArrRelInsertInput>;
  child_institutions?: InputMaybe<InstitutionsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_institution?: InputMaybe<InstitutionsObjRelInsertInput>;
  qbz_id?: InputMaybe<Scalars['Int']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TypeInstitutionsEnum>;
  type_institution?: InputMaybe<TypeInstitutionsObjRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<UsersArrRelInsertInput>;
}

/** aggregate max on columns */
export interface InstitutionsMaxFields {
  __typename?: 'institutions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  qbz_id?: Maybe<Scalars['Int']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "institutions" */
export interface InstitutionsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  qbz_id?: InputMaybe<OrderBy>;
  short_name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface InstitutionsMinFields {
  __typename?: 'institutions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  qbz_id?: Maybe<Scalars['Int']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "institutions" */
export interface InstitutionsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  qbz_id?: InputMaybe<OrderBy>;
  short_name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "institutions" */
export interface InstitutionsMutationResponse {
  __typename?: 'institutions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Institutions>;
}

/** input type for inserting object relation for remote table "institutions" */
export interface InstitutionsObjRelInsertInput {
  data: InstitutionsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<InstitutionsOnConflict>;
}

/** on_conflict condition type for table "institutions" */
export interface InstitutionsOnConflict {
  constraint: InstitutionsConstraint;
  update_columns?: Array<InstitutionsUpdateColumn>;
  where?: InputMaybe<InstitutionsBoolExp>;
}

/** Ordering options when selecting data from "institutions". */
export interface InstitutionsOrderBy {
  acquis_institutions_aggregate?: InputMaybe<PkieAcquisInstitutionsAggregateOrderBy>;
  child_institutions_aggregate?: InputMaybe<InstitutionsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  parent_institution?: InputMaybe<InstitutionsOrderBy>;
  qbz_id?: InputMaybe<OrderBy>;
  short_name?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  type_institution?: InputMaybe<TypeInstitutionsOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  users_aggregate?: InputMaybe<UsersAggregateOrderBy>;
}

/** primary key columns input for table: institutions */
export interface InstitutionsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "institutions" */
export enum InstitutionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "institutions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "institutions" */
export enum InstitutionsSelectColumnInstitutionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "institutions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "institutions" */
export enum InstitutionsSelectColumnInstitutionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "institutions" */
export interface InstitutionsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  qbz_id?: InputMaybe<Scalars['Int']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TypeInstitutionsEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface InstitutionsStddevFields {
  __typename?: 'institutions_stddev_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "institutions" */
export interface InstitutionsStddevOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface InstitutionsStddevPopFields {
  __typename?: 'institutions_stddev_pop_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "institutions" */
export interface InstitutionsStddevPopOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface InstitutionsStddevSampFields {
  __typename?: 'institutions_stddev_samp_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "institutions" */
export interface InstitutionsStddevSampOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "institutions" */
export interface InstitutionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: InstitutionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface InstitutionsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  qbz_id?: InputMaybe<Scalars['Int']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TypeInstitutionsEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface InstitutionsSumFields {
  __typename?: 'institutions_sum_fields';
  qbz_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "institutions" */
export interface InstitutionsSumOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** update columns of table "institutions" */
export enum InstitutionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface InstitutionsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InstitutionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InstitutionsSetInput>;
  /** filter the rows which have to be updated */
  where: InstitutionsBoolExp;
}

/** aggregate var_pop on columns */
export interface InstitutionsVarPopFields {
  __typename?: 'institutions_var_pop_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "institutions" */
export interface InstitutionsVarPopOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface InstitutionsVarSampFields {
  __typename?: 'institutions_var_samp_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "institutions" */
export interface InstitutionsVarSampOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface InstitutionsVarianceFields {
  __typename?: 'institutions_variance_fields';
  qbz_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "institutions" */
export interface InstitutionsVarianceOrderBy {
  qbz_id?: InputMaybe<OrderBy>;
}

export interface IntegrationPhaseProgres {
  __typename?: 'integrationPhaseProgres';
  progres?: Maybe<Scalars['numeric']['output']>;
}

/** integrationPhaseProgresNative Query Arguments */
export interface IntegrationPhaseProgresArguments {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** Boolean expression to filter rows from the logical model for "integrationPhaseProgres". All fields are combined with a logical 'AND'. */
export interface IntegrationPhaseProgresBoolExpBoolExp {
  _and?: InputMaybe<Array<IntegrationPhaseProgresBoolExpBoolExp>>;
  _not?: InputMaybe<IntegrationPhaseProgresBoolExpBoolExp>;
  _or?: InputMaybe<Array<IntegrationPhaseProgresBoolExpBoolExp>>;
  progres?: InputMaybe<NumericComparisonExp>;
}

export enum IntegrationPhaseProgresEnumName {
  /** column name */
  Progres = 'progres'
}

/** Ordering options when selecting data from "integrationPhaseProgres". */
export interface IntegrationPhaseProgresOrderBy {
  progres?: InputMaybe<OrderBy>;
}

/** columns and relationships of "integration_phases" */
export interface IntegrationPhases {
  __typename?: 'integration_phases';
  dms_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  phase: IntegrationPhasesEnumEnum;
}

/** aggregated selection of "integration_phases" */
export interface IntegrationPhasesAggregate {
  __typename?: 'integration_phases_aggregate';
  aggregate?: Maybe<IntegrationPhasesAggregateFields>;
  nodes: Array<IntegrationPhases>;
}

/** aggregate fields of "integration_phases" */
export interface IntegrationPhasesAggregateFields {
  __typename?: 'integration_phases_aggregate_fields';
  avg?: Maybe<IntegrationPhasesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IntegrationPhasesMaxFields>;
  min?: Maybe<IntegrationPhasesMinFields>;
  stddev?: Maybe<IntegrationPhasesStddevFields>;
  stddev_pop?: Maybe<IntegrationPhasesStddevPopFields>;
  stddev_samp?: Maybe<IntegrationPhasesStddevSampFields>;
  sum?: Maybe<IntegrationPhasesSumFields>;
  var_pop?: Maybe<IntegrationPhasesVarPopFields>;
  var_samp?: Maybe<IntegrationPhasesVarSampFields>;
  variance?: Maybe<IntegrationPhasesVarianceFields>;
}


/** aggregate fields of "integration_phases" */
export interface IntegrationPhasesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IntegrationPhasesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface IntegrationPhasesAvgFields {
  __typename?: 'integration_phases_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "integration_phases". All fields are combined with a logical 'AND'. */
export interface IntegrationPhasesBoolExp {
  _and?: InputMaybe<Array<IntegrationPhasesBoolExp>>;
  _not?: InputMaybe<IntegrationPhasesBoolExp>;
  _or?: InputMaybe<Array<IntegrationPhasesBoolExp>>;
  dms_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  phase?: InputMaybe<IntegrationPhasesEnumEnumComparisonExp>;
}

/** unique or primary key constraints on table "integration_phases" */
export enum IntegrationPhasesConstraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationPhasesPkey1 = 'integration_phases_pkey1'
}

/** phases a chapter goes through */
export interface IntegrationPhasesEnum {
  __typename?: 'integration_phases_enum';
  phase: Scalars['String']['output'];
}

/** aggregated selection of "integration_phases_enum" */
export interface IntegrationPhasesEnumAggregate {
  __typename?: 'integration_phases_enum_aggregate';
  aggregate?: Maybe<IntegrationPhasesEnumAggregateFields>;
  nodes: Array<IntegrationPhasesEnum>;
}

/** aggregate fields of "integration_phases_enum" */
export interface IntegrationPhasesEnumAggregateFields {
  __typename?: 'integration_phases_enum_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IntegrationPhasesEnumMaxFields>;
  min?: Maybe<IntegrationPhasesEnumMinFields>;
}


/** aggregate fields of "integration_phases_enum" */
export interface IntegrationPhasesEnumAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IntegrationPhasesEnumSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "integration_phases_enum". All fields are combined with a logical 'AND'. */
export interface IntegrationPhasesEnumBoolExp {
  _and?: InputMaybe<Array<IntegrationPhasesEnumBoolExp>>;
  _not?: InputMaybe<IntegrationPhasesEnumBoolExp>;
  _or?: InputMaybe<Array<IntegrationPhasesEnumBoolExp>>;
  phase?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "integration_phases_enum" */
export enum IntegrationPhasesEnumConstraint {
  /** unique or primary key constraint on columns "phase" */
  IntegrationPhasesPkey = 'integration_phases_pkey'
}

export enum IntegrationPhasesEnumEnum {
  ClosingBenchmarks = 'CLOSING_BENCHMARKS',
  IntermediarySteps = 'INTERMEDIARY_STEPS',
  Membership = 'MEMBERSHIP',
  OpeningBenchmarcs = 'OPENING_BENCHMARCS',
  OpeningNegotiation = 'OPENING_NEGOTIATION',
  PositionNegotiation = 'POSITION_NEGOTIATION',
  Screening = 'SCREENING'
}

/** Boolean expression to compare columns of type "integration_phases_enum_enum". All fields are combined with logical 'AND'. */
export interface IntegrationPhasesEnumEnumComparisonExp {
  _eq?: InputMaybe<IntegrationPhasesEnumEnum>;
  _in?: InputMaybe<Array<IntegrationPhasesEnumEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IntegrationPhasesEnumEnum>;
  _nin?: InputMaybe<Array<IntegrationPhasesEnumEnum>>;
}

/** input type for inserting data into table "integration_phases_enum" */
export interface IntegrationPhasesEnumInsertInput {
  phase?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface IntegrationPhasesEnumMaxFields {
  __typename?: 'integration_phases_enum_max_fields';
  phase?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface IntegrationPhasesEnumMinFields {
  __typename?: 'integration_phases_enum_min_fields';
  phase?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "integration_phases_enum" */
export interface IntegrationPhasesEnumMutationResponse {
  __typename?: 'integration_phases_enum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IntegrationPhasesEnum>;
}

/** input type for inserting object relation for remote table "integration_phases_enum" */
export interface IntegrationPhasesEnumObjRelInsertInput {
  data: IntegrationPhasesEnumInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IntegrationPhasesEnumOnConflict>;
}

/** on_conflict condition type for table "integration_phases_enum" */
export interface IntegrationPhasesEnumOnConflict {
  constraint: IntegrationPhasesEnumConstraint;
  update_columns?: Array<IntegrationPhasesEnumUpdateColumn>;
  where?: InputMaybe<IntegrationPhasesEnumBoolExp>;
}

/** Ordering options when selecting data from "integration_phases_enum". */
export interface IntegrationPhasesEnumOrderBy {
  phase?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: integration_phases_enum */
export interface IntegrationPhasesEnumPkColumnsInput {
  phase: Scalars['String']['input'];
}

/** select columns of table "integration_phases_enum" */
export enum IntegrationPhasesEnumSelectColumn {
  /** column name */
  Phase = 'phase'
}

/** input type for updating data in table "integration_phases_enum" */
export interface IntegrationPhasesEnumSetInput {
  phase?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "integration_phases_enum" */
export interface IntegrationPhasesEnumStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IntegrationPhasesEnumStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IntegrationPhasesEnumStreamCursorValueInput {
  phase?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "integration_phases_enum" */
export enum IntegrationPhasesEnumUpdateColumn {
  /** column name */
  Phase = 'phase'
}

export interface IntegrationPhasesEnumUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IntegrationPhasesEnumSetInput>;
  /** filter the rows which have to be updated */
  where: IntegrationPhasesEnumBoolExp;
}

/** input type for incrementing numeric columns in table "integration_phases" */
export interface IntegrationPhasesIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "integration_phases" */
export interface IntegrationPhasesInsertInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
}

/** aggregate max on columns */
export interface IntegrationPhasesMaxFields {
  __typename?: 'integration_phases_max_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregate min on columns */
export interface IntegrationPhasesMinFields {
  __typename?: 'integration_phases_min_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
}

/** response of any mutation on the table "integration_phases" */
export interface IntegrationPhasesMutationResponse {
  __typename?: 'integration_phases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IntegrationPhases>;
}

/** on_conflict condition type for table "integration_phases" */
export interface IntegrationPhasesOnConflict {
  constraint: IntegrationPhasesConstraint;
  update_columns?: Array<IntegrationPhasesUpdateColumn>;
  where?: InputMaybe<IntegrationPhasesBoolExp>;
}

/** Ordering options when selecting data from "integration_phases". */
export interface IntegrationPhasesOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  phase?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: integration_phases */
export interface IntegrationPhasesPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "integration_phases" */
export enum IntegrationPhasesSelectColumn {
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase'
}

/** input type for updating data in table "integration_phases" */
export interface IntegrationPhasesSetInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
}

/** aggregate stddev on columns */
export interface IntegrationPhasesStddevFields {
  __typename?: 'integration_phases_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface IntegrationPhasesStddevPopFields {
  __typename?: 'integration_phases_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface IntegrationPhasesStddevSampFields {
  __typename?: 'integration_phases_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "integration_phases" */
export interface IntegrationPhasesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IntegrationPhasesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IntegrationPhasesStreamCursorValueInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
}

/** aggregate sum on columns */
export interface IntegrationPhasesSumFields {
  __typename?: 'integration_phases_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "integration_phases" */
export enum IntegrationPhasesUpdateColumn {
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  Phase = 'phase'
}

export interface IntegrationPhasesUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IntegrationPhasesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IntegrationPhasesSetInput>;
  /** filter the rows which have to be updated */
  where: IntegrationPhasesBoolExp;
}

/** aggregate var_pop on columns */
export interface IntegrationPhasesVarPopFields {
  __typename?: 'integration_phases_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface IntegrationPhasesVarSampFields {
  __typename?: 'integration_phases_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface IntegrationPhasesVarianceFields {
  __typename?: 'integration_phases_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export interface JsonComparisonExp {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
}

export interface JsonbCastExp {
  String?: InputMaybe<StringComparisonExp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface JsonbComparisonExp {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

/** enum of statuses for a meeting attendance of a user */
export interface MeetingAttendance {
  __typename?: 'meeting_attendance';
  attendance: Scalars['String']['output'];
}

/** aggregated selection of "meeting_attendance" */
export interface MeetingAttendanceAggregate {
  __typename?: 'meeting_attendance_aggregate';
  aggregate?: Maybe<MeetingAttendanceAggregateFields>;
  nodes: Array<MeetingAttendance>;
}

/** aggregate fields of "meeting_attendance" */
export interface MeetingAttendanceAggregateFields {
  __typename?: 'meeting_attendance_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<MeetingAttendanceMaxFields>;
  min?: Maybe<MeetingAttendanceMinFields>;
}


/** aggregate fields of "meeting_attendance" */
export interface MeetingAttendanceAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<MeetingAttendanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "meeting_attendance". All fields are combined with a logical 'AND'. */
export interface MeetingAttendanceBoolExp {
  _and?: InputMaybe<Array<MeetingAttendanceBoolExp>>;
  _not?: InputMaybe<MeetingAttendanceBoolExp>;
  _or?: InputMaybe<Array<MeetingAttendanceBoolExp>>;
  attendance?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "meeting_attendance" */
export enum MeetingAttendanceConstraint {
  /** unique or primary key constraint on columns "attendance" */
  MeetingAttendancePkey = 'meeting_attendance_pkey'
}

export enum MeetingAttendanceEnum {
  Accepted = 'ACCEPTED',
  NoAnswer = 'NO_ANSWER',
  Refused = 'REFUSED'
}

/** Boolean expression to compare columns of type "meeting_attendance_enum". All fields are combined with logical 'AND'. */
export interface MeetingAttendanceEnumComparisonExp {
  _eq?: InputMaybe<MeetingAttendanceEnum>;
  _in?: InputMaybe<Array<MeetingAttendanceEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<MeetingAttendanceEnum>;
  _nin?: InputMaybe<Array<MeetingAttendanceEnum>>;
}

/** input type for inserting data into table "meeting_attendance" */
export interface MeetingAttendanceInsertInput {
  attendance?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface MeetingAttendanceMaxFields {
  __typename?: 'meeting_attendance_max_fields';
  attendance?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface MeetingAttendanceMinFields {
  __typename?: 'meeting_attendance_min_fields';
  attendance?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "meeting_attendance" */
export interface MeetingAttendanceMutationResponse {
  __typename?: 'meeting_attendance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MeetingAttendance>;
}

/** on_conflict condition type for table "meeting_attendance" */
export interface MeetingAttendanceOnConflict {
  constraint: MeetingAttendanceConstraint;
  update_columns?: Array<MeetingAttendanceUpdateColumn>;
  where?: InputMaybe<MeetingAttendanceBoolExp>;
}

/** Ordering options when selecting data from "meeting_attendance". */
export interface MeetingAttendanceOrderBy {
  attendance?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: meeting_attendance */
export interface MeetingAttendancePkColumnsInput {
  attendance: Scalars['String']['input'];
}

/** select columns of table "meeting_attendance" */
export enum MeetingAttendanceSelectColumn {
  /** column name */
  Attendance = 'attendance'
}

/** input type for updating data in table "meeting_attendance" */
export interface MeetingAttendanceSetInput {
  attendance?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "meeting_attendance" */
export interface MeetingAttendanceStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MeetingAttendanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MeetingAttendanceStreamCursorValueInput {
  attendance?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "meeting_attendance" */
export enum MeetingAttendanceUpdateColumn {
  /** column name */
  Attendance = 'attendance'
}

export interface MeetingAttendanceUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MeetingAttendanceSetInput>;
  /** filter the rows which have to be updated */
  where: MeetingAttendanceBoolExp;
}

/** keeps notes from meetings */
export interface MeetingNotes {
  __typename?: 'meeting_notes';
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  meeting: Meetings;
  meeting_id: Scalars['uuid']['output'];
  note: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
}

/** aggregated selection of "meeting_notes" */
export interface MeetingNotesAggregate {
  __typename?: 'meeting_notes_aggregate';
  aggregate?: Maybe<MeetingNotesAggregateFields>;
  nodes: Array<MeetingNotes>;
}

export interface MeetingNotesAggregateBoolExp {
  count?: InputMaybe<MeetingNotesAggregateBoolExpCount>;
}

export interface MeetingNotesAggregateBoolExpCount {
  arguments?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeetingNotesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "meeting_notes" */
export interface MeetingNotesAggregateFields {
  __typename?: 'meeting_notes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<MeetingNotesMaxFields>;
  min?: Maybe<MeetingNotesMinFields>;
}


/** aggregate fields of "meeting_notes" */
export interface MeetingNotesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "meeting_notes" */
export interface MeetingNotesAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MeetingNotesMaxOrderBy>;
  min?: InputMaybe<MeetingNotesMinOrderBy>;
}

/** input type for inserting array relation for remote table "meeting_notes" */
export interface MeetingNotesArrRelInsertInput {
  data: Array<MeetingNotesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MeetingNotesOnConflict>;
}

/** Boolean expression to filter rows from the table "meeting_notes". All fields are combined with a logical 'AND'. */
export interface MeetingNotesBoolExp {
  _and?: InputMaybe<Array<MeetingNotesBoolExp>>;
  _not?: InputMaybe<MeetingNotesBoolExp>;
  _or?: InputMaybe<Array<MeetingNotesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  meeting?: InputMaybe<MeetingsBoolExp>;
  meeting_id?: InputMaybe<UuidComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
}

/** unique or primary key constraints on table "meeting_notes" */
export enum MeetingNotesConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingNotesPkey = 'meeting_notes_pkey'
}

/** input type for inserting data into table "meeting_notes" */
export interface MeetingNotesInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<MeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface MeetingNotesMaxFields {
  __typename?: 'meeting_notes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "meeting_notes" */
export interface MeetingNotesMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface MeetingNotesMinFields {
  __typename?: 'meeting_notes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "meeting_notes" */
export interface MeetingNotesMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "meeting_notes" */
export interface MeetingNotesMutationResponse {
  __typename?: 'meeting_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MeetingNotes>;
}

/** on_conflict condition type for table "meeting_notes" */
export interface MeetingNotesOnConflict {
  constraint: MeetingNotesConstraint;
  update_columns?: Array<MeetingNotesUpdateColumn>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}

/** Ordering options when selecting data from "meeting_notes". */
export interface MeetingNotesOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meeting?: InputMaybe<MeetingsOrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
}

/** primary key columns input for table: meeting_notes */
export interface MeetingNotesPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "meeting_notes" */
export enum MeetingNotesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "meeting_notes" */
export interface MeetingNotesSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "meeting_notes" */
export interface MeetingNotesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MeetingNotesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MeetingNotesStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "meeting_notes" */
export enum MeetingNotesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface MeetingNotesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MeetingNotesSetInput>;
  /** filter the rows which have to be updated */
  where: MeetingNotesBoolExp;
}

/** keeps many to many relationships between users and the meetings */
export interface MeetingUsers {
  __typename?: 'meeting_users';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  meeting: Meetings;
  meeting_attendence?: Maybe<MeetingAttendanceEnum>;
  meeting_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "meeting_users" */
export interface MeetingUsersAggregate {
  __typename?: 'meeting_users_aggregate';
  aggregate?: Maybe<MeetingUsersAggregateFields>;
  nodes: Array<MeetingUsers>;
}

export interface MeetingUsersAggregateBoolExp {
  count?: InputMaybe<MeetingUsersAggregateBoolExpCount>;
}

export interface MeetingUsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeetingUsersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "meeting_users" */
export interface MeetingUsersAggregateFields {
  __typename?: 'meeting_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<MeetingUsersMaxFields>;
  min?: Maybe<MeetingUsersMinFields>;
}


/** aggregate fields of "meeting_users" */
export interface MeetingUsersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "meeting_users" */
export interface MeetingUsersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MeetingUsersMaxOrderBy>;
  min?: InputMaybe<MeetingUsersMinOrderBy>;
}

/** input type for inserting array relation for remote table "meeting_users" */
export interface MeetingUsersArrRelInsertInput {
  data: Array<MeetingUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MeetingUsersOnConflict>;
}

/** Boolean expression to filter rows from the table "meeting_users". All fields are combined with a logical 'AND'. */
export interface MeetingUsersBoolExp {
  _and?: InputMaybe<Array<MeetingUsersBoolExp>>;
  _not?: InputMaybe<MeetingUsersBoolExp>;
  _or?: InputMaybe<Array<MeetingUsersBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  meeting?: InputMaybe<MeetingsBoolExp>;
  meeting_attendence?: InputMaybe<MeetingAttendanceEnumComparisonExp>;
  meeting_id?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "meeting_users" */
export enum MeetingUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingUsersPkey = 'meeting_users_pkey'
}

/** input type for inserting data into table "meeting_users" */
export interface MeetingUsersInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<MeetingsObjRelInsertInput>;
  meeting_attendence?: InputMaybe<MeetingAttendanceEnum>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface MeetingUsersMaxFields {
  __typename?: 'meeting_users_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "meeting_users" */
export interface MeetingUsersMaxOrderBy {
  id?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface MeetingUsersMinFields {
  __typename?: 'meeting_users_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "meeting_users" */
export interface MeetingUsersMinOrderBy {
  id?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "meeting_users" */
export interface MeetingUsersMutationResponse {
  __typename?: 'meeting_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MeetingUsers>;
}

/** on_conflict condition type for table "meeting_users" */
export interface MeetingUsersOnConflict {
  constraint: MeetingUsersConstraint;
  update_columns?: Array<MeetingUsersUpdateColumn>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}

/** Ordering options when selecting data from "meeting_users". */
export interface MeetingUsersOrderBy {
  id?: InputMaybe<OrderBy>;
  meeting?: InputMaybe<MeetingsOrderBy>;
  meeting_attendence?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: meeting_users */
export interface MeetingUsersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "meeting_users" */
export enum MeetingUsersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingAttendence = 'meeting_attendence',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "meeting_users" */
export interface MeetingUsersSetInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_attendence?: InputMaybe<MeetingAttendanceEnum>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "meeting_users" */
export interface MeetingUsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MeetingUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MeetingUsersStreamCursorValueInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_attendence?: InputMaybe<MeetingAttendanceEnum>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "meeting_users" */
export enum MeetingUsersUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MeetingAttendence = 'meeting_attendence',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  UserId = 'user_id'
}

export interface MeetingUsersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MeetingUsersSetInput>;
  /** filter the rows which have to be updated */
  where: MeetingUsersBoolExp;
}

/** meetings in calendar */
export interface Meetings {
  __typename?: 'meetings';
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  meeting_date: Scalars['timestamptz']['output'];
  /** An array relationship */
  meeting_notes: Array<MeetingNotes>;
  /** An aggregate relationship */
  meeting_notes_aggregate: MeetingNotesAggregate;
  /** An array relationship */
  meeting_users: Array<MeetingUsers>;
  /** An aggregate relationship */
  meeting_users_aggregate: MeetingUsersAggregate;
  minutes_of_meeting?: Maybe<Scalars['String']['output']>;
  notified?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: TaskAggregate;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
}


/** meetings in calendar */
export interface MeetingsDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** meetings in calendar */
export interface MeetingsMeetingNotesArgs {
  distinct_on?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingNotesOrderBy>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


/** meetings in calendar */
export interface MeetingsMeetingNotesAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingNotesOrderBy>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


/** meetings in calendar */
export interface MeetingsMeetingUsersArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


/** meetings in calendar */
export interface MeetingsMeetingUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


/** meetings in calendar */
export interface MeetingsTasksArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** meetings in calendar */
export interface MeetingsTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}

/** aggregated selection of "meetings" */
export interface MeetingsAggregate {
  __typename?: 'meetings_aggregate';
  aggregate?: Maybe<MeetingsAggregateFields>;
  nodes: Array<Meetings>;
}

export interface MeetingsAggregateBoolExp {
  bool_and?: InputMaybe<MeetingsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<MeetingsAggregateBoolExpBoolOr>;
  count?: InputMaybe<MeetingsAggregateBoolExpCount>;
}

export interface MeetingsAggregateBoolExpBoolAnd {
  arguments: MeetingsSelectColumnMeetingsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeetingsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface MeetingsAggregateBoolExpBoolOr {
  arguments: MeetingsSelectColumnMeetingsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeetingsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface MeetingsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<MeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeetingsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "meetings" */
export interface MeetingsAggregateFields {
  __typename?: 'meetings_aggregate_fields';
  avg?: Maybe<MeetingsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MeetingsMaxFields>;
  min?: Maybe<MeetingsMinFields>;
  stddev?: Maybe<MeetingsStddevFields>;
  stddev_pop?: Maybe<MeetingsStddevPopFields>;
  stddev_samp?: Maybe<MeetingsStddevSampFields>;
  sum?: Maybe<MeetingsSumFields>;
  var_pop?: Maybe<MeetingsVarPopFields>;
  var_samp?: Maybe<MeetingsVarSampFields>;
  variance?: Maybe<MeetingsVarianceFields>;
}


/** aggregate fields of "meetings" */
export interface MeetingsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<MeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "meetings" */
export interface MeetingsAggregateOrderBy {
  avg?: InputMaybe<MeetingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MeetingsMaxOrderBy>;
  min?: InputMaybe<MeetingsMinOrderBy>;
  stddev?: InputMaybe<MeetingsStddevOrderBy>;
  stddev_pop?: InputMaybe<MeetingsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MeetingsStddevSampOrderBy>;
  sum?: InputMaybe<MeetingsSumOrderBy>;
  var_pop?: InputMaybe<MeetingsVarPopOrderBy>;
  var_samp?: InputMaybe<MeetingsVarSampOrderBy>;
  variance?: InputMaybe<MeetingsVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface MeetingsAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "meetings" */
export interface MeetingsArrRelInsertInput {
  data: Array<MeetingsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MeetingsOnConflict>;
}

/** aggregate avg on columns */
export interface MeetingsAvgFields {
  __typename?: 'meetings_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "meetings" */
export interface MeetingsAvgOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "meetings". All fields are combined with a logical 'AND'. */
export interface MeetingsBoolExp {
  _and?: InputMaybe<Array<MeetingsBoolExp>>;
  _not?: InputMaybe<MeetingsBoolExp>;
  _or?: InputMaybe<Array<MeetingsBoolExp>>;
  content?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  end_date?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  meeting_date?: InputMaybe<TimestamptzComparisonExp>;
  meeting_notes?: InputMaybe<MeetingNotesBoolExp>;
  meeting_notes_aggregate?: InputMaybe<MeetingNotesAggregateBoolExp>;
  meeting_users?: InputMaybe<MeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<MeetingUsersAggregateBoolExp>;
  minutes_of_meeting?: InputMaybe<StringComparisonExp>;
  notified?: InputMaybe<BooleanComparisonExp>;
  tasks?: InputMaybe<TaskBoolExp>;
  tasks_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
}

/** unique or primary key constraints on table "meetings" */
export enum MeetingsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeetingsPkey = 'meetings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface MeetingsDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface MeetingsDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface MeetingsDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "meetings" */
export interface MeetingsIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "meetings" */
export interface MeetingsInsertInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_date?: InputMaybe<Scalars['timestamptz']['input']>;
  meeting_notes?: InputMaybe<MeetingNotesArrRelInsertInput>;
  meeting_users?: InputMaybe<MeetingUsersArrRelInsertInput>;
  minutes_of_meeting?: InputMaybe<Scalars['String']['input']>;
  notified?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface MeetingsMaxFields {
  __typename?: 'meetings_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  meeting_date?: Maybe<Scalars['timestamptz']['output']>;
  minutes_of_meeting?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "meetings" */
export interface MeetingsMaxOrderBy {
  content?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meeting_date?: InputMaybe<OrderBy>;
  minutes_of_meeting?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface MeetingsMinFields {
  __typename?: 'meetings_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  meeting_date?: Maybe<Scalars['timestamptz']['output']>;
  minutes_of_meeting?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "meetings" */
export interface MeetingsMinOrderBy {
  content?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meeting_date?: InputMaybe<OrderBy>;
  minutes_of_meeting?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "meetings" */
export interface MeetingsMutationResponse {
  __typename?: 'meetings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Meetings>;
}

/** input type for inserting object relation for remote table "meetings" */
export interface MeetingsObjRelInsertInput {
  data: MeetingsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MeetingsOnConflict>;
}

/** on_conflict condition type for table "meetings" */
export interface MeetingsOnConflict {
  constraint: MeetingsConstraint;
  update_columns?: Array<MeetingsUpdateColumn>;
  where?: InputMaybe<MeetingsBoolExp>;
}

/** Ordering options when selecting data from "meetings". */
export interface MeetingsOrderBy {
  content?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meeting_date?: InputMaybe<OrderBy>;
  meeting_notes_aggregate?: InputMaybe<MeetingNotesAggregateOrderBy>;
  meeting_users_aggregate?: InputMaybe<MeetingUsersAggregateOrderBy>;
  minutes_of_meeting?: InputMaybe<OrderBy>;
  notified?: InputMaybe<OrderBy>;
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
}

/** primary key columns input for table: meetings */
export interface MeetingsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface MeetingsPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "meetings" */
export enum MeetingsSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Notified = 'notified',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "meetings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "meetings" */
export enum MeetingsSelectColumnMeetingsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Notified = 'notified'
}

/** select "meetings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "meetings" */
export enum MeetingsSelectColumnMeetingsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Notified = 'notified'
}

/** input type for updating data in table "meetings" */
export interface MeetingsSetInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_date?: InputMaybe<Scalars['timestamptz']['input']>;
  minutes_of_meeting?: InputMaybe<Scalars['String']['input']>;
  notified?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface MeetingsStddevFields {
  __typename?: 'meetings_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "meetings" */
export interface MeetingsStddevOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface MeetingsStddevPopFields {
  __typename?: 'meetings_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "meetings" */
export interface MeetingsStddevPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface MeetingsStddevSampFields {
  __typename?: 'meetings_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "meetings" */
export interface MeetingsStddevSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "meetings" */
export interface MeetingsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MeetingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MeetingsStreamCursorValueInput {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_date?: InputMaybe<Scalars['timestamptz']['input']>;
  minutes_of_meeting?: InputMaybe<Scalars['String']['input']>;
  notified?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface MeetingsSumFields {
  __typename?: 'meetings_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "meetings" */
export interface MeetingsSumOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** update columns of table "meetings" */
export enum MeetingsUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingDate = 'meeting_date',
  /** column name */
  MinutesOfMeeting = 'minutes_of_meeting',
  /** column name */
  Notified = 'notified',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface MeetingsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MeetingsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<MeetingsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<MeetingsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<MeetingsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MeetingsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MeetingsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MeetingsSetInput>;
  /** filter the rows which have to be updated */
  where: MeetingsBoolExp;
}

/** aggregate var_pop on columns */
export interface MeetingsVarPopFields {
  __typename?: 'meetings_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "meetings" */
export interface MeetingsVarPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface MeetingsVarSampFields {
  __typename?: 'meetings_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "meetings" */
export interface MeetingsVarSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface MeetingsVarianceFields {
  __typename?: 'meetings_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "meetings" */
export interface MeetingsVarianceOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

export interface MemberCalendar {
  __typename?: 'member_calendar';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  deadline: Scalars['timestamp']['output'];
  end_date?: Maybe<Scalars['timestamp']['output']>;
  grand_parent_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  parent_id: Scalars['String']['output'];
  task_name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  user_id: Scalars['uuid']['output'];
}

/** Boolean expression to filter rows from the logical model for "member_calendar". All fields are combined with a logical 'AND'. */
export interface MemberCalendarBoolExpBoolExp {
  _and?: InputMaybe<Array<MemberCalendarBoolExpBoolExp>>;
  _not?: InputMaybe<MemberCalendarBoolExpBoolExp>;
  _or?: InputMaybe<Array<MemberCalendarBoolExpBoolExp>>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  deadline?: InputMaybe<TimestampComparisonExp>;
  end_date?: InputMaybe<TimestampComparisonExp>;
  grand_parent_id?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  parent_id?: InputMaybe<StringComparisonExp>;
  task_name?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
}

export enum MemberCalendarEnumName {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GrandParentId = 'grand_parent_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TaskName = 'task_name',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** Ordering options when selecting data from "member_calendar". */
export interface MemberCalendarOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  grand_parent_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  task_name?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** mutation root */
export interface MutationRoot {
  __typename?: 'mutation_root';
  /** on update if status is changing to done, waiting for pmo or waiting for chapter coordinator, change all document tasks to that new status as well */
  UpdateDocumentByPk?: Maybe<UpdateDocumentByPkOutput>;
  /** on update if status is changing to done, waiting for pmo or waiting for chapter coordinator, change all report tasks to that new status as well */
  UpdateReportChaptersByPK?: Maybe<UpdateReportChaptersByPkOutput>;
  /** Complete document signature of a report and change the chapter status after negotiator signs */
  completeReportSigniture?: Maybe<CompleteSignitureOutput>;
  /** delete single row from the table: "auth.providers" */
  deleteAuthProvider?: Maybe<AuthProviders>;
  /** delete single row from the table: "auth.provider_requests" */
  deleteAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** delete data from the table: "auth.provider_requests" */
  deleteAuthProviderRequests?: Maybe<AuthProviderRequestsMutationResponse>;
  /** delete data from the table: "auth.providers" */
  deleteAuthProviders?: Maybe<AuthProvidersMutationResponse>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** delete single row from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** delete data from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypesMutationResponse>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** delete single row from the table: "auth.roles" */
  deleteAuthRole?: Maybe<AuthRoles>;
  /** delete data from the table: "auth.roles" */
  deleteAuthRoles?: Maybe<AuthRolesMutationResponse>;
  /** delete single row from the table: "auth.user_providers" */
  deleteAuthUserProvider?: Maybe<AuthUserProviders>;
  /** delete data from the table: "auth.user_providers" */
  deleteAuthUserProviders?: Maybe<AuthUserProvidersMutationResponse>;
  /** delete single row from the table: "auth.user_roles" */
  deleteAuthUserRole?: Maybe<AuthUserRoles>;
  /** delete data from the table: "auth.user_roles" */
  deleteAuthUserRoles?: Maybe<AuthUserRolesMutationResponse>;
  /** delete single row from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** delete data from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeysMutationResponse>;
  /** delete single row from the table: "auth.users" */
  deleteUser?: Maybe<Users>;
  /** delete data from the table: "auth.users" */
  deleteUsers?: Maybe<UsersMutationResponse>;
  /** delete data from the table: "auth.modules" */
  delete_auth_modules?: Maybe<AuthModulesMutationResponse>;
  /** delete single row from the table: "auth.modules" */
  delete_auth_modules_by_pk?: Maybe<AuthModules>;
  /** delete data from the table: "bookmark_gnpie_members" */
  delete_bookmark_gnpie_members?: Maybe<BookmarkGnpieMembersMutationResponse>;
  /** delete single row from the table: "bookmark_gnpie_members" */
  delete_bookmark_gnpie_members_by_pk?: Maybe<BookmarkGnpieMembers>;
  /** delete data from the table: "bookmark_status" */
  delete_bookmark_status?: Maybe<BookmarkStatusMutationResponse>;
  /** delete single row from the table: "bookmark_status" */
  delete_bookmark_status_by_pk?: Maybe<BookmarkStatus>;
  /** delete data from the table: "bookmark_task_activity" */
  delete_bookmark_task_activity?: Maybe<BookmarkTaskActivityMutationResponse>;
  /** delete single row from the table: "bookmark_task_activity" */
  delete_bookmark_task_activity_by_pk?: Maybe<BookmarkTaskActivity>;
  /** delete data from the table: "bookmark_task_answers" */
  delete_bookmark_task_answers?: Maybe<BookmarkTaskAnswersMutationResponse>;
  /** delete single row from the table: "bookmark_task_answers" */
  delete_bookmark_task_answers_by_pk?: Maybe<BookmarkTaskAnswers>;
  /** delete data from the table: "bookmark_task_comment" */
  delete_bookmark_task_comment?: Maybe<BookmarkTaskCommentMutationResponse>;
  /** delete single row from the table: "bookmark_task_comment" */
  delete_bookmark_task_comment_by_pk?: Maybe<BookmarkTaskComment>;
  /** delete data from the table: "bookmark_task_measures" */
  delete_bookmark_task_measures?: Maybe<BookmarkTaskMeasuresMutationResponse>;
  /** delete single row from the table: "bookmark_task_measures" */
  delete_bookmark_task_measures_by_pk?: Maybe<BookmarkTaskMeasures>;
  /** delete data from the table: "bookmark_task_statuses" */
  delete_bookmark_task_statuses?: Maybe<BookmarkTaskStatusesMutationResponse>;
  /** delete single row from the table: "bookmark_task_statuses" */
  delete_bookmark_task_statuses_by_pk?: Maybe<BookmarkTaskStatuses>;
  /** delete data from the table: "bookmark_tasks" */
  delete_bookmark_tasks?: Maybe<BookmarkTasksMutationResponse>;
  /** delete single row from the table: "bookmark_tasks" */
  delete_bookmark_tasks_by_pk?: Maybe<BookmarkTasks>;
  /** delete data from the table: "bookmarks" */
  delete_bookmarks?: Maybe<BookmarksMutationResponse>;
  /** delete single row from the table: "bookmarks" */
  delete_bookmarks_by_pk?: Maybe<Bookmarks>;
  /** delete data from the table: "capac.beneficiaries" */
  delete_capac_beneficiaries?: Maybe<CapacBeneficiariesMutationResponse>;
  /** delete single row from the table: "capac.beneficiaries" */
  delete_capac_beneficiaries_by_pk?: Maybe<CapacBeneficiaries>;
  /** delete data from the table: "capac.chapter_donator" */
  delete_capac_chapter_donator?: Maybe<CapacChapterDonatorMutationResponse>;
  /** delete single row from the table: "capac.chapter_donator" */
  delete_capac_chapter_donator_by_pk?: Maybe<CapacChapterDonator>;
  /** delete data from the table: "capac.donators" */
  delete_capac_donators?: Maybe<CapacDonatorsMutationResponse>;
  /** delete single row from the table: "capac.donators" */
  delete_capac_donators_by_pk?: Maybe<CapacDonators>;
  /** delete data from the table: "capac.experts" */
  delete_capac_experts?: Maybe<CapacExpertsMutationResponse>;
  /** delete single row from the table: "capac.experts" */
  delete_capac_experts_by_pk?: Maybe<CapacExperts>;
  /** delete data from the table: "capac.integration_steps" */
  delete_capac_integration_steps?: Maybe<CapacIntegrationStepsMutationResponse>;
  /** delete single row from the table: "capac.integration_steps" */
  delete_capac_integration_steps_by_pk?: Maybe<CapacIntegrationSteps>;
  /** delete data from the table: "capac.priority_lists" */
  delete_capac_priority_lists?: Maybe<CapacPriorityListsMutationResponse>;
  /** delete single row from the table: "capac.priority_lists" */
  delete_capac_priority_lists_by_pk?: Maybe<CapacPriorityLists>;
  /** delete data from the table: "capac.project_beneficiaries" */
  delete_capac_project_beneficiaries?: Maybe<CapacProjectBeneficiariesMutationResponse>;
  /** delete single row from the table: "capac.project_beneficiaries" */
  delete_capac_project_beneficiaries_by_pk?: Maybe<CapacProjectBeneficiaries>;
  /** delete data from the table: "capac.projects" */
  delete_capac_projects?: Maybe<CapacProjectsMutationResponse>;
  /** delete single row from the table: "capac.projects" */
  delete_capac_projects_by_pk?: Maybe<CapacProjects>;
  /** delete data from the table: "capac.taiex" */
  delete_capac_taiex?: Maybe<CapacTaiexMutationResponse>;
  /** delete single row from the table: "capac.taiex" */
  delete_capac_taiex_by_pk?: Maybe<CapacTaiex>;
  /** delete data from the table: "capac.training_needs" */
  delete_capac_training_needs?: Maybe<CapacTrainingNeedsMutationResponse>;
  /** delete single row from the table: "capac.training_needs" */
  delete_capac_training_needs_by_pk?: Maybe<CapacTrainingNeeds>;
  /** delete data from the table: "capac.training_participants" */
  delete_capac_training_participants?: Maybe<CapacTrainingParticipantsMutationResponse>;
  /** delete single row from the table: "capac.training_participants" */
  delete_capac_training_participants_by_pk?: Maybe<CapacTrainingParticipants>;
  /** delete data from the table: "capac.training_sessions" */
  delete_capac_training_sessions?: Maybe<CapacTrainingSessionsMutationResponse>;
  /** delete single row from the table: "capac.training_sessions" */
  delete_capac_training_sessions_by_pk?: Maybe<CapacTrainingSessions>;
  /** delete data from the table: "capac.trainings" */
  delete_capac_trainings?: Maybe<CapacTrainingsMutationResponse>;
  /** delete single row from the table: "capac.trainings" */
  delete_capac_trainings_by_pk?: Maybe<CapacTrainings>;
  /** delete data from the table: "capac.trainings_experts" */
  delete_capac_trainings_experts?: Maybe<CapacTrainingsExpertsMutationResponse>;
  /** delete single row from the table: "capac.trainings_experts" */
  delete_capac_trainings_experts_by_pk?: Maybe<CapacTrainingsExperts>;
  /** delete data from the table: "capac.twinning" */
  delete_capac_twinning?: Maybe<CapacTwinningMutationResponse>;
  /** delete single row from the table: "capac.twinning" */
  delete_capac_twinning_by_pk?: Maybe<CapacTwinning>;
  /** delete data from the table: "chapter_lead_institutions" */
  delete_chapter_lead_institutions?: Maybe<ChapterLeadInstitutionsMutationResponse>;
  /** delete single row from the table: "chapter_lead_institutions" */
  delete_chapter_lead_institutions_by_pk?: Maybe<ChapterLeadInstitutions>;
  /** delete data from the table: "chapters" */
  delete_chapters?: Maybe<ChaptersMutationResponse>;
  /** delete single row from the table: "chapters" */
  delete_chapters_by_pk?: Maybe<Chapters>;
  /** delete data from the table: "clusters" */
  delete_clusters?: Maybe<ClustersMutationResponse>;
  /** delete single row from the table: "clusters" */
  delete_clusters_by_pk?: Maybe<Clusters>;
  /** delete data from the table: "dms.folder" */
  delete_dms_folder?: Maybe<DmsFolderMutationResponse>;
  /** delete single row from the table: "dms.folder" */
  delete_dms_folder_by_pk?: Maybe<DmsFolder>;
  /** delete data from the table: "dms.folder_type" */
  delete_dms_folder_type?: Maybe<DmsFolderTypeMutationResponse>;
  /** delete single row from the table: "dms.folder_type" */
  delete_dms_folder_type_by_pk?: Maybe<DmsFolderType>;
  /** delete data from the table: "documents" */
  delete_documents?: Maybe<DocumentsMutationResponse>;
  /** delete single row from the table: "documents" */
  delete_documents_by_pk?: Maybe<Documents>;
  /** delete data from the table: "error_logs" */
  delete_error_logs?: Maybe<ErrorLogsMutationResponse>;
  /** delete single row from the table: "error_logs" */
  delete_error_logs_by_pk?: Maybe<ErrorLogs>;
  /** delete data from the table: "event_notes" */
  delete_event_notes?: Maybe<EventNotesMutationResponse>;
  /** delete single row from the table: "event_notes" */
  delete_event_notes_by_pk?: Maybe<EventNotes>;
  /** delete data from the table: "event_typology" */
  delete_event_typology?: Maybe<EventTypologyMutationResponse>;
  /** delete single row from the table: "event_typology" */
  delete_event_typology_by_pk?: Maybe<EventTypology>;
  /** delete data from the table: "event_users" */
  delete_event_users?: Maybe<EventUsersMutationResponse>;
  /** delete single row from the table: "event_users" */
  delete_event_users_by_pk?: Maybe<EventUsers>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<EventsMutationResponse>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "gnpie" */
  delete_gnpie?: Maybe<GnpieMutationResponse>;
  /** delete single row from the table: "gnpie" */
  delete_gnpie_by_pk?: Maybe<Gnpie>;
  /** delete data from the table: "institutions" */
  delete_institutions?: Maybe<InstitutionsMutationResponse>;
  /** delete single row from the table: "institutions" */
  delete_institutions_by_pk?: Maybe<Institutions>;
  /** delete data from the table: "integration_phases" */
  delete_integration_phases?: Maybe<IntegrationPhasesMutationResponse>;
  /** delete single row from the table: "integration_phases" */
  delete_integration_phases_by_pk?: Maybe<IntegrationPhases>;
  /** delete data from the table: "integration_phases_enum" */
  delete_integration_phases_enum?: Maybe<IntegrationPhasesEnumMutationResponse>;
  /** delete single row from the table: "integration_phases_enum" */
  delete_integration_phases_enum_by_pk?: Maybe<IntegrationPhasesEnum>;
  /** delete data from the table: "meeting_attendance" */
  delete_meeting_attendance?: Maybe<MeetingAttendanceMutationResponse>;
  /** delete single row from the table: "meeting_attendance" */
  delete_meeting_attendance_by_pk?: Maybe<MeetingAttendance>;
  /** delete data from the table: "meeting_notes" */
  delete_meeting_notes?: Maybe<MeetingNotesMutationResponse>;
  /** delete single row from the table: "meeting_notes" */
  delete_meeting_notes_by_pk?: Maybe<MeetingNotes>;
  /** delete data from the table: "meeting_users" */
  delete_meeting_users?: Maybe<MeetingUsersMutationResponse>;
  /** delete single row from the table: "meeting_users" */
  delete_meeting_users_by_pk?: Maybe<MeetingUsers>;
  /** delete data from the table: "meetings" */
  delete_meetings?: Maybe<MeetingsMutationResponse>;
  /** delete single row from the table: "meetings" */
  delete_meetings_by_pk?: Maybe<Meetings>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<NotificationMutationResponse>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "notification_origin" */
  delete_notification_origin?: Maybe<NotificationOriginMutationResponse>;
  /** delete single row from the table: "notification_origin" */
  delete_notification_origin_by_pk?: Maybe<NotificationOrigin>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<NotificationTypeMutationResponse>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<NotificationType>;
  /** delete data from the table: "pkie.acquis" */
  delete_pkie_acquis?: Maybe<PkieAcquisMutationResponse>;
  /** delete single row from the table: "pkie.acquis" */
  delete_pkie_acquis_by_pk?: Maybe<PkieAcquis>;
  /** delete data from the table: "pkie.acquis_chapter" */
  delete_pkie_acquis_chapter?: Maybe<PkieAcquisChapterMutationResponse>;
  /** delete single row from the table: "pkie.acquis_chapter" */
  delete_pkie_acquis_chapter_by_pk?: Maybe<PkieAcquisChapter>;
  /** delete data from the table: "pkie.acquis_institutions" */
  delete_pkie_acquis_institutions?: Maybe<PkieAcquisInstitutionsMutationResponse>;
  /** delete single row from the table: "pkie.acquis_institutions" */
  delete_pkie_acquis_institutions_by_pk?: Maybe<PkieAcquisInstitutions>;
  /** delete data from the table: "pkie.acquis_national_acts" */
  delete_pkie_acquis_national_acts?: Maybe<PkieAcquisNationalActsMutationResponse>;
  /** delete single row from the table: "pkie.acquis_national_acts" */
  delete_pkie_acquis_national_acts_by_pk?: Maybe<PkieAcquisNationalActs>;
  /** delete data from the table: "pkie.acquis_type" */
  delete_pkie_acquis_type?: Maybe<PkieAcquisTypeMutationResponse>;
  /** delete single row from the table: "pkie.acquis_type" */
  delete_pkie_acquis_type_by_pk?: Maybe<PkieAcquisType>;
  /** delete data from the table: "pkie.acquis_work_plan_task" */
  delete_pkie_acquis_work_plan_task?: Maybe<PkieAcquisWorkPlanTaskMutationResponse>;
  /** delete single row from the table: "pkie.acquis_work_plan_task" */
  delete_pkie_acquis_work_plan_task_by_pk?: Maybe<PkieAcquisWorkPlanTask>;
  /** delete data from the table: "pkie.act_type" */
  delete_pkie_act_type?: Maybe<PkieActTypeMutationResponse>;
  /** delete single row from the table: "pkie.act_type" */
  delete_pkie_act_type_by_pk?: Maybe<PkieActType>;
  /** delete data from the table: "pkie.level_of_alignment" */
  delete_pkie_level_of_alignment?: Maybe<PkieLevelOfAlignmentMutationResponse>;
  /** delete single row from the table: "pkie.level_of_alignment" */
  delete_pkie_level_of_alignment_by_pk?: Maybe<PkieLevelOfAlignment>;
  /** delete data from the table: "pkie.measure" */
  delete_pkie_measure?: Maybe<PkieMeasureMutationResponse>;
  /** delete data from the table: "pkie.measure_activity" */
  delete_pkie_measure_activity?: Maybe<PkieMeasureActivityMutationResponse>;
  /** delete single row from the table: "pkie.measure_activity" */
  delete_pkie_measure_activity_by_pk?: Maybe<PkieMeasureActivity>;
  /** delete data from the table: "pkie.measure_answers" */
  delete_pkie_measure_answers?: Maybe<PkieMeasureAnswersMutationResponse>;
  /** delete single row from the table: "pkie.measure_answers" */
  delete_pkie_measure_answers_by_pk?: Maybe<PkieMeasureAnswers>;
  /** delete data from the table: "pkie.measure_bookmark" */
  delete_pkie_measure_bookmark?: Maybe<PkieMeasureBookmarkMutationResponse>;
  /** delete single row from the table: "pkie.measure_bookmark" */
  delete_pkie_measure_bookmark_by_pk?: Maybe<PkieMeasureBookmark>;
  /** delete data from the table: "pkie.measure_budgets" */
  delete_pkie_measure_budgets?: Maybe<PkieMeasureBudgetsMutationResponse>;
  /** delete single row from the table: "pkie.measure_budgets" */
  delete_pkie_measure_budgets_by_pk?: Maybe<PkieMeasureBudgets>;
  /** delete single row from the table: "pkie.measure" */
  delete_pkie_measure_by_pk?: Maybe<PkieMeasure>;
  /** delete data from the table: "pkie.measure_comment" */
  delete_pkie_measure_comment?: Maybe<PkieMeasureCommentMutationResponse>;
  /** delete single row from the table: "pkie.measure_comment" */
  delete_pkie_measure_comment_by_pk?: Maybe<PkieMeasureComment>;
  /** delete data from the table: "pkie.measure_gnpie_members" */
  delete_pkie_measure_gnpie_members?: Maybe<PkieMeasureGnpieMembersMutationResponse>;
  /** delete single row from the table: "pkie.measure_gnpie_members" */
  delete_pkie_measure_gnpie_members_by_pk?: Maybe<PkieMeasureGnpieMembers>;
  /** delete data from the table: "pkie.measure_roadmap" */
  delete_pkie_measure_roadmap?: Maybe<PkieMeasureRoadmapMutationResponse>;
  /** delete single row from the table: "pkie.measure_roadmap" */
  delete_pkie_measure_roadmap_by_pk?: Maybe<PkieMeasureRoadmap>;
  /** delete data from the table: "pkie.measure_status" */
  delete_pkie_measure_status?: Maybe<PkieMeasureStatusMutationResponse>;
  /** delete single row from the table: "pkie.measure_status" */
  delete_pkie_measure_status_by_pk?: Maybe<PkieMeasureStatus>;
  /** delete data from the table: "pkie.national_acts" */
  delete_pkie_national_acts?: Maybe<PkieNationalActsMutationResponse>;
  /** delete single row from the table: "pkie.national_acts" */
  delete_pkie_national_acts_by_pk?: Maybe<PkieNationalActs>;
  /** delete data from the table: "pkie.proces_pune_iaga" */
  delete_pkie_proces_pune_iaga?: Maybe<PkieProcesPuneIagaMutationResponse>;
  /** delete single row from the table: "pkie.proces_pune_iaga" */
  delete_pkie_proces_pune_iaga_by_pk?: Maybe<PkieProcesPuneIaga>;
  /** delete data from the table: "pkie.proces_pune_lga" */
  delete_pkie_proces_pune_lga?: Maybe<PkieProcesPuneLgaMutationResponse>;
  /** delete single row from the table: "pkie.proces_pune_lga" */
  delete_pkie_proces_pune_lga_by_pk?: Maybe<PkieProcesPuneLga>;
  /** delete data from the table: "pkie.source_of_funding" */
  delete_pkie_source_of_funding?: Maybe<PkieSourceOfFundingMutationResponse>;
  /** delete single row from the table: "pkie.source_of_funding" */
  delete_pkie_source_of_funding_by_pk?: Maybe<PkieSourceOfFunding>;
  /** delete data from the table: "pkie.type_alignment_act" */
  delete_pkie_type_alignment_act?: Maybe<PkieTypeAlignmentActMutationResponse>;
  /** delete single row from the table: "pkie.type_alignment_act" */
  delete_pkie_type_alignment_act_by_pk?: Maybe<PkieTypeAlignmentAct>;
  /** delete data from the table: "pkie.type_measure" */
  delete_pkie_type_measure?: Maybe<PkieTypeMeasureMutationResponse>;
  /** delete data from the table: "pkie.type_measure_budget" */
  delete_pkie_type_measure_budget?: Maybe<PkieTypeMeasureBudgetMutationResponse>;
  /** delete single row from the table: "pkie.type_measure_budget" */
  delete_pkie_type_measure_budget_by_pk?: Maybe<PkieTypeMeasureBudget>;
  /** delete single row from the table: "pkie.type_measure" */
  delete_pkie_type_measure_by_pk?: Maybe<PkieTypeMeasure>;
  /** delete data from the table: "pkie.work_plan_comment" */
  delete_pkie_work_plan_comment?: Maybe<PkieWorkPlanCommentMutationResponse>;
  /** delete single row from the table: "pkie.work_plan_comment" */
  delete_pkie_work_plan_comment_by_pk?: Maybe<PkieWorkPlanComment>;
  /** delete data from the table: "pkie.work_plan_statuses" */
  delete_pkie_work_plan_statuses?: Maybe<PkieWorkPlanStatusesMutationResponse>;
  /** delete single row from the table: "pkie.work_plan_statuses" */
  delete_pkie_work_plan_statuses_by_pk?: Maybe<PkieWorkPlanStatuses>;
  /** delete data from the table: "pkie.work_plan_task" */
  delete_pkie_work_plan_task?: Maybe<PkieWorkPlanTaskMutationResponse>;
  /** delete data from the table: "pkie.work_plan_task_activity" */
  delete_pkie_work_plan_task_activity?: Maybe<PkieWorkPlanTaskActivityMutationResponse>;
  /** delete single row from the table: "pkie.work_plan_task_activity" */
  delete_pkie_work_plan_task_activity_by_pk?: Maybe<PkieWorkPlanTaskActivity>;
  /** delete single row from the table: "pkie.work_plan_task" */
  delete_pkie_work_plan_task_by_pk?: Maybe<PkieWorkPlanTask>;
  /** delete data from the table: "pkie.work_plan_task_statuses" */
  delete_pkie_work_plan_task_statuses?: Maybe<PkieWorkPlanTaskStatusesMutationResponse>;
  /** delete single row from the table: "pkie.work_plan_task_statuses" */
  delete_pkie_work_plan_task_statuses_by_pk?: Maybe<PkieWorkPlanTaskStatuses>;
  /** delete data from the table: "pkie.work_plans" */
  delete_pkie_work_plans?: Maybe<PkieWorkPlansMutationResponse>;
  /** delete single row from the table: "pkie.work_plans" */
  delete_pkie_work_plans_by_pk?: Maybe<PkieWorkPlans>;
  /** delete data from the table: "question_types" */
  delete_question_types?: Maybe<QuestionTypesMutationResponse>;
  /** delete single row from the table: "question_types" */
  delete_question_types_by_pk?: Maybe<QuestionTypes>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<QuestionsMutationResponse>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "recommendations" */
  delete_recommendations?: Maybe<RecommendationsMutationResponse>;
  /** delete single row from the table: "recommendations" */
  delete_recommendations_by_pk?: Maybe<Recommendations>;
  /** delete data from the table: "report_answers" */
  delete_report_answers?: Maybe<ReportAnswersMutationResponse>;
  /** delete single row from the table: "report_answers" */
  delete_report_answers_by_pk?: Maybe<ReportAnswers>;
  /** delete data from the table: "report_chapter_statuses" */
  delete_report_chapter_statuses?: Maybe<ReportChapterStatusesMutationResponse>;
  /** delete single row from the table: "report_chapter_statuses" */
  delete_report_chapter_statuses_by_pk?: Maybe<ReportChapterStatuses>;
  /** delete data from the table: "report_chapters" */
  delete_report_chapters?: Maybe<ReportChaptersMutationResponse>;
  /** delete single row from the table: "report_chapters" */
  delete_report_chapters_by_pk?: Maybe<ReportChapters>;
  /** delete data from the table: "report_statuses" */
  delete_report_statuses?: Maybe<ReportStatusesMutationResponse>;
  /** delete single row from the table: "report_statuses" */
  delete_report_statuses_by_pk?: Maybe<ReportStatuses>;
  /** delete data from the table: "report_task_activity" */
  delete_report_task_activity?: Maybe<ReportTaskActivityMutationResponse>;
  /** delete single row from the table: "report_task_activity" */
  delete_report_task_activity_by_pk?: Maybe<ReportTaskActivity>;
  /** delete data from the table: "report_task_comment" */
  delete_report_task_comment?: Maybe<ReportTaskCommentMutationResponse>;
  /** delete single row from the table: "report_task_comment" */
  delete_report_task_comment_by_pk?: Maybe<ReportTaskComment>;
  /** delete data from the table: "report_task_gnpie_members" */
  delete_report_task_gnpie_members?: Maybe<ReportTaskGnpieMembersMutationResponse>;
  /** delete single row from the table: "report_task_gnpie_members" */
  delete_report_task_gnpie_members_by_pk?: Maybe<ReportTaskGnpieMembers>;
  /** delete data from the table: "report_task_statuses" */
  delete_report_task_statuses?: Maybe<ReportTaskStatusesMutationResponse>;
  /** delete single row from the table: "report_task_statuses" */
  delete_report_task_statuses_by_pk?: Maybe<ReportTaskStatuses>;
  /** delete data from the table: "report_tasks" */
  delete_report_tasks?: Maybe<ReportTasksMutationResponse>;
  /** delete single row from the table: "report_tasks" */
  delete_report_tasks_by_pk?: Maybe<ReportTasks>;
  /** delete data from the table: "reports" */
  delete_reports?: Maybe<ReportsMutationResponse>;
  /** delete single row from the table: "reports" */
  delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "roadmap_measure_type" */
  delete_roadmap_measure_type?: Maybe<RoadmapMeasureTypeMutationResponse>;
  /** delete single row from the table: "roadmap_measure_type" */
  delete_roadmap_measure_type_by_pk?: Maybe<RoadmapMeasureType>;
  /** delete data from the table: "signed_documents" */
  delete_signed_documents?: Maybe<SignedDocumentsMutationResponse>;
  /** delete single row from the table: "signed_documents" */
  delete_signed_documents_by_pk?: Maybe<SignedDocuments>;
  /** delete data from the table: "sub_chapter_lead_institutions" */
  delete_sub_chapter_lead_institutions?: Maybe<SubChapterLeadInstitutionsMutationResponse>;
  /** delete single row from the table: "sub_chapter_lead_institutions" */
  delete_sub_chapter_lead_institutions_by_pk?: Maybe<SubChapterLeadInstitutions>;
  /** delete data from the table: "sub_chapters" */
  delete_sub_chapters?: Maybe<SubChaptersMutationResponse>;
  /** delete single row from the table: "sub_chapters" */
  delete_sub_chapters_by_pk?: Maybe<SubChapters>;
  /** delete data from the table: "task" */
  delete_task?: Maybe<TaskMutationResponse>;
  /** delete data from the table: "task_activity" */
  delete_task_activity?: Maybe<TaskActivityMutationResponse>;
  /** delete single row from the table: "task_activity" */
  delete_task_activity_by_pk?: Maybe<TaskActivity>;
  /** delete data from the table: "task_answers" */
  delete_task_answers?: Maybe<TaskAnswersMutationResponse>;
  /** delete single row from the table: "task_answers" */
  delete_task_answers_by_pk?: Maybe<TaskAnswers>;
  /** delete single row from the table: "task" */
  delete_task_by_pk?: Maybe<Task>;
  /** delete data from the table: "task_comments" */
  delete_task_comments?: Maybe<TaskCommentsMutationResponse>;
  /** delete single row from the table: "task_comments" */
  delete_task_comments_by_pk?: Maybe<TaskComments>;
  /** delete data from the table: "task_gnpie_members" */
  delete_task_gnpie_members?: Maybe<TaskGnpieMembersMutationResponse>;
  /** delete single row from the table: "task_gnpie_members" */
  delete_task_gnpie_members_by_pk?: Maybe<TaskGnpieMembers>;
  /** delete data from the table: "task_statuses" */
  delete_task_statuses?: Maybe<TaskStatusesMutationResponse>;
  /** delete single row from the table: "task_statuses" */
  delete_task_statuses_by_pk?: Maybe<TaskStatuses>;
  /** delete data from the table: "task_types" */
  delete_task_types?: Maybe<TaskTypesMutationResponse>;
  /** delete single row from the table: "task_types" */
  delete_task_types_by_pk?: Maybe<TaskTypes>;
  /** delete data from the table: "topics" */
  delete_topics?: Maybe<TopicsMutationResponse>;
  /** delete single row from the table: "topics" */
  delete_topics_by_pk?: Maybe<Topics>;
  /** delete data from the table: "type_institutions" */
  delete_type_institutions?: Maybe<TypeInstitutionsMutationResponse>;
  /** delete single row from the table: "type_institutions" */
  delete_type_institutions_by_pk?: Maybe<TypeInstitutions>;
  /** delete data from the table: "yearly_quarters" */
  delete_yearly_quarters?: Maybe<YearlyQuartersMutationResponse>;
  /** delete single row from the table: "yearly_quarters" */
  delete_yearly_quarters_by_pk?: Maybe<YearlyQuarters>;
  insertAcquis?: Maybe<InsertAcquisOutput>;
  /** This is the action that will take care of creating all the tasks of work plan for each chapter */
  insertAcquisWorkPlan?: Maybe<CreateAcquisWorkPlanOutput>;
  /** insert a single row into the table: "auth.providers" */
  insertAuthProvider?: Maybe<AuthProviders>;
  /** insert a single row into the table: "auth.provider_requests" */
  insertAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** insert data into the table: "auth.provider_requests" */
  insertAuthProviderRequests?: Maybe<AuthProviderRequestsMutationResponse>;
  /** insert data into the table: "auth.providers" */
  insertAuthProviders?: Maybe<AuthProvidersMutationResponse>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insertAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** insert a single row into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** insert data into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypesMutationResponse>;
  /** insert data into the table: "auth.refresh_tokens" */
  insertAuthRefreshTokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** insert a single row into the table: "auth.roles" */
  insertAuthRole?: Maybe<AuthRoles>;
  /** insert data into the table: "auth.roles" */
  insertAuthRoles?: Maybe<AuthRolesMutationResponse>;
  /** insert a single row into the table: "auth.user_providers" */
  insertAuthUserProvider?: Maybe<AuthUserProviders>;
  /** insert data into the table: "auth.user_providers" */
  insertAuthUserProviders?: Maybe<AuthUserProvidersMutationResponse>;
  /** insert a single row into the table: "auth.user_roles" */
  insertAuthUserRole?: Maybe<AuthUserRoles>;
  /** insert data into the table: "auth.user_roles" */
  insertAuthUserRoles?: Maybe<AuthUserRolesMutationResponse>;
  /** insert a single row into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** insert data into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeysMutationResponse>;
  /** This is the action to create documents */
  insertDocument?: Maybe<InsertDocumentOutput>;
  /** create new folder */
  insertFolder?: Maybe<InsertFolderOutput>;
  /** This is the action that will take care of creating all the tasks of a progress report once it creates it */
  insertProgressReport?: Maybe<InsertProgressReportOutput>;
  /** insert a single row into the table: "auth.users" */
  insertUser?: Maybe<Users>;
  /** insert data into the table: "auth.users" */
  insertUsers?: Maybe<UsersMutationResponse>;
  /** insert data into the table: "auth.modules" */
  insert_auth_modules?: Maybe<AuthModulesMutationResponse>;
  /** insert a single row into the table: "auth.modules" */
  insert_auth_modules_one?: Maybe<AuthModules>;
  /** insert data into the table: "bookmark_gnpie_members" */
  insert_bookmark_gnpie_members?: Maybe<BookmarkGnpieMembersMutationResponse>;
  /** insert a single row into the table: "bookmark_gnpie_members" */
  insert_bookmark_gnpie_members_one?: Maybe<BookmarkGnpieMembers>;
  /** insert data into the table: "bookmark_status" */
  insert_bookmark_status?: Maybe<BookmarkStatusMutationResponse>;
  /** insert a single row into the table: "bookmark_status" */
  insert_bookmark_status_one?: Maybe<BookmarkStatus>;
  /** insert data into the table: "bookmark_task_activity" */
  insert_bookmark_task_activity?: Maybe<BookmarkTaskActivityMutationResponse>;
  /** insert a single row into the table: "bookmark_task_activity" */
  insert_bookmark_task_activity_one?: Maybe<BookmarkTaskActivity>;
  /** insert data into the table: "bookmark_task_answers" */
  insert_bookmark_task_answers?: Maybe<BookmarkTaskAnswersMutationResponse>;
  /** insert a single row into the table: "bookmark_task_answers" */
  insert_bookmark_task_answers_one?: Maybe<BookmarkTaskAnswers>;
  /** insert data into the table: "bookmark_task_comment" */
  insert_bookmark_task_comment?: Maybe<BookmarkTaskCommentMutationResponse>;
  /** insert a single row into the table: "bookmark_task_comment" */
  insert_bookmark_task_comment_one?: Maybe<BookmarkTaskComment>;
  /** insert data into the table: "bookmark_task_measures" */
  insert_bookmark_task_measures?: Maybe<BookmarkTaskMeasuresMutationResponse>;
  /** insert a single row into the table: "bookmark_task_measures" */
  insert_bookmark_task_measures_one?: Maybe<BookmarkTaskMeasures>;
  /** insert data into the table: "bookmark_task_statuses" */
  insert_bookmark_task_statuses?: Maybe<BookmarkTaskStatusesMutationResponse>;
  /** insert a single row into the table: "bookmark_task_statuses" */
  insert_bookmark_task_statuses_one?: Maybe<BookmarkTaskStatuses>;
  /** insert data into the table: "bookmark_tasks" */
  insert_bookmark_tasks?: Maybe<BookmarkTasksMutationResponse>;
  /** insert a single row into the table: "bookmark_tasks" */
  insert_bookmark_tasks_one?: Maybe<BookmarkTasks>;
  /** insert data into the table: "bookmarks" */
  insert_bookmarks?: Maybe<BookmarksMutationResponse>;
  /** insert a single row into the table: "bookmarks" */
  insert_bookmarks_one?: Maybe<Bookmarks>;
  /** insert data into the table: "capac.beneficiaries" */
  insert_capac_beneficiaries?: Maybe<CapacBeneficiariesMutationResponse>;
  /** insert a single row into the table: "capac.beneficiaries" */
  insert_capac_beneficiaries_one?: Maybe<CapacBeneficiaries>;
  /** insert data into the table: "capac.chapter_donator" */
  insert_capac_chapter_donator?: Maybe<CapacChapterDonatorMutationResponse>;
  /** insert a single row into the table: "capac.chapter_donator" */
  insert_capac_chapter_donator_one?: Maybe<CapacChapterDonator>;
  /** insert data into the table: "capac.donators" */
  insert_capac_donators?: Maybe<CapacDonatorsMutationResponse>;
  /** insert a single row into the table: "capac.donators" */
  insert_capac_donators_one?: Maybe<CapacDonators>;
  /** insert data into the table: "capac.experts" */
  insert_capac_experts?: Maybe<CapacExpertsMutationResponse>;
  /** insert a single row into the table: "capac.experts" */
  insert_capac_experts_one?: Maybe<CapacExperts>;
  /** insert data into the table: "capac.integration_steps" */
  insert_capac_integration_steps?: Maybe<CapacIntegrationStepsMutationResponse>;
  /** insert a single row into the table: "capac.integration_steps" */
  insert_capac_integration_steps_one?: Maybe<CapacIntegrationSteps>;
  /** insert data into the table: "capac.priority_lists" */
  insert_capac_priority_lists?: Maybe<CapacPriorityListsMutationResponse>;
  /** insert a single row into the table: "capac.priority_lists" */
  insert_capac_priority_lists_one?: Maybe<CapacPriorityLists>;
  /** insert data into the table: "capac.project_beneficiaries" */
  insert_capac_project_beneficiaries?: Maybe<CapacProjectBeneficiariesMutationResponse>;
  /** insert a single row into the table: "capac.project_beneficiaries" */
  insert_capac_project_beneficiaries_one?: Maybe<CapacProjectBeneficiaries>;
  /** insert data into the table: "capac.projects" */
  insert_capac_projects?: Maybe<CapacProjectsMutationResponse>;
  /** insert a single row into the table: "capac.projects" */
  insert_capac_projects_one?: Maybe<CapacProjects>;
  /** insert data into the table: "capac.taiex" */
  insert_capac_taiex?: Maybe<CapacTaiexMutationResponse>;
  /** insert a single row into the table: "capac.taiex" */
  insert_capac_taiex_one?: Maybe<CapacTaiex>;
  /** insert data into the table: "capac.training_needs" */
  insert_capac_training_needs?: Maybe<CapacTrainingNeedsMutationResponse>;
  /** insert a single row into the table: "capac.training_needs" */
  insert_capac_training_needs_one?: Maybe<CapacTrainingNeeds>;
  /** insert data into the table: "capac.training_participants" */
  insert_capac_training_participants?: Maybe<CapacTrainingParticipantsMutationResponse>;
  /** insert a single row into the table: "capac.training_participants" */
  insert_capac_training_participants_one?: Maybe<CapacTrainingParticipants>;
  /** insert data into the table: "capac.training_sessions" */
  insert_capac_training_sessions?: Maybe<CapacTrainingSessionsMutationResponse>;
  /** insert a single row into the table: "capac.training_sessions" */
  insert_capac_training_sessions_one?: Maybe<CapacTrainingSessions>;
  /** insert data into the table: "capac.trainings" */
  insert_capac_trainings?: Maybe<CapacTrainingsMutationResponse>;
  /** insert data into the table: "capac.trainings_experts" */
  insert_capac_trainings_experts?: Maybe<CapacTrainingsExpertsMutationResponse>;
  /** insert a single row into the table: "capac.trainings_experts" */
  insert_capac_trainings_experts_one?: Maybe<CapacTrainingsExperts>;
  /** insert a single row into the table: "capac.trainings" */
  insert_capac_trainings_one?: Maybe<CapacTrainings>;
  /** insert data into the table: "capac.twinning" */
  insert_capac_twinning?: Maybe<CapacTwinningMutationResponse>;
  /** insert a single row into the table: "capac.twinning" */
  insert_capac_twinning_one?: Maybe<CapacTwinning>;
  /** insert data into the table: "chapter_lead_institutions" */
  insert_chapter_lead_institutions?: Maybe<ChapterLeadInstitutionsMutationResponse>;
  /** insert a single row into the table: "chapter_lead_institutions" */
  insert_chapter_lead_institutions_one?: Maybe<ChapterLeadInstitutions>;
  /** insert data into the table: "chapters" */
  insert_chapters?: Maybe<ChaptersMutationResponse>;
  /** insert a single row into the table: "chapters" */
  insert_chapters_one?: Maybe<Chapters>;
  /** insert data into the table: "clusters" */
  insert_clusters?: Maybe<ClustersMutationResponse>;
  /** insert a single row into the table: "clusters" */
  insert_clusters_one?: Maybe<Clusters>;
  /** insert data into the table: "dms.folder" */
  insert_dms_folder?: Maybe<DmsFolderMutationResponse>;
  /** insert a single row into the table: "dms.folder" */
  insert_dms_folder_one?: Maybe<DmsFolder>;
  /** insert data into the table: "dms.folder_type" */
  insert_dms_folder_type?: Maybe<DmsFolderTypeMutationResponse>;
  /** insert a single row into the table: "dms.folder_type" */
  insert_dms_folder_type_one?: Maybe<DmsFolderType>;
  /** insert data into the table: "documents" */
  insert_documents?: Maybe<DocumentsMutationResponse>;
  /** insert a single row into the table: "documents" */
  insert_documents_one?: Maybe<Documents>;
  /** insert data into the table: "error_logs" */
  insert_error_logs?: Maybe<ErrorLogsMutationResponse>;
  /** insert a single row into the table: "error_logs" */
  insert_error_logs_one?: Maybe<ErrorLogs>;
  /** insert data into the table: "event_notes" */
  insert_event_notes?: Maybe<EventNotesMutationResponse>;
  /** insert a single row into the table: "event_notes" */
  insert_event_notes_one?: Maybe<EventNotes>;
  /** insert data into the table: "event_typology" */
  insert_event_typology?: Maybe<EventTypologyMutationResponse>;
  /** insert a single row into the table: "event_typology" */
  insert_event_typology_one?: Maybe<EventTypology>;
  /** insert data into the table: "event_users" */
  insert_event_users?: Maybe<EventUsersMutationResponse>;
  /** insert a single row into the table: "event_users" */
  insert_event_users_one?: Maybe<EventUsers>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<EventsMutationResponse>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "gnpie" */
  insert_gnpie?: Maybe<GnpieMutationResponse>;
  /** insert a single row into the table: "gnpie" */
  insert_gnpie_one?: Maybe<Gnpie>;
  /** insert data into the table: "institutions" */
  insert_institutions?: Maybe<InstitutionsMutationResponse>;
  /** insert a single row into the table: "institutions" */
  insert_institutions_one?: Maybe<Institutions>;
  /** insert data into the table: "integration_phases" */
  insert_integration_phases?: Maybe<IntegrationPhasesMutationResponse>;
  /** insert data into the table: "integration_phases_enum" */
  insert_integration_phases_enum?: Maybe<IntegrationPhasesEnumMutationResponse>;
  /** insert a single row into the table: "integration_phases_enum" */
  insert_integration_phases_enum_one?: Maybe<IntegrationPhasesEnum>;
  /** insert a single row into the table: "integration_phases" */
  insert_integration_phases_one?: Maybe<IntegrationPhases>;
  /** insert data into the table: "meeting_attendance" */
  insert_meeting_attendance?: Maybe<MeetingAttendanceMutationResponse>;
  /** insert a single row into the table: "meeting_attendance" */
  insert_meeting_attendance_one?: Maybe<MeetingAttendance>;
  /** insert data into the table: "meeting_notes" */
  insert_meeting_notes?: Maybe<MeetingNotesMutationResponse>;
  /** insert a single row into the table: "meeting_notes" */
  insert_meeting_notes_one?: Maybe<MeetingNotes>;
  /** insert data into the table: "meeting_users" */
  insert_meeting_users?: Maybe<MeetingUsersMutationResponse>;
  /** insert a single row into the table: "meeting_users" */
  insert_meeting_users_one?: Maybe<MeetingUsers>;
  /** insert data into the table: "meetings" */
  insert_meetings?: Maybe<MeetingsMutationResponse>;
  /** insert a single row into the table: "meetings" */
  insert_meetings_one?: Maybe<Meetings>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<NotificationMutationResponse>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "notification_origin" */
  insert_notification_origin?: Maybe<NotificationOriginMutationResponse>;
  /** insert a single row into the table: "notification_origin" */
  insert_notification_origin_one?: Maybe<NotificationOrigin>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<NotificationTypeMutationResponse>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<NotificationType>;
  /** insert data into the table: "pkie.acquis" */
  insert_pkie_acquis?: Maybe<PkieAcquisMutationResponse>;
  /** insert data into the table: "pkie.acquis_chapter" */
  insert_pkie_acquis_chapter?: Maybe<PkieAcquisChapterMutationResponse>;
  /** insert a single row into the table: "pkie.acquis_chapter" */
  insert_pkie_acquis_chapter_one?: Maybe<PkieAcquisChapter>;
  /** insert data into the table: "pkie.acquis_institutions" */
  insert_pkie_acquis_institutions?: Maybe<PkieAcquisInstitutionsMutationResponse>;
  /** insert a single row into the table: "pkie.acquis_institutions" */
  insert_pkie_acquis_institutions_one?: Maybe<PkieAcquisInstitutions>;
  /** insert data into the table: "pkie.acquis_national_acts" */
  insert_pkie_acquis_national_acts?: Maybe<PkieAcquisNationalActsMutationResponse>;
  /** insert a single row into the table: "pkie.acquis_national_acts" */
  insert_pkie_acquis_national_acts_one?: Maybe<PkieAcquisNationalActs>;
  /** insert a single row into the table: "pkie.acquis" */
  insert_pkie_acquis_one?: Maybe<PkieAcquis>;
  /** insert data into the table: "pkie.acquis_type" */
  insert_pkie_acquis_type?: Maybe<PkieAcquisTypeMutationResponse>;
  /** insert a single row into the table: "pkie.acquis_type" */
  insert_pkie_acquis_type_one?: Maybe<PkieAcquisType>;
  /** insert data into the table: "pkie.acquis_work_plan_task" */
  insert_pkie_acquis_work_plan_task?: Maybe<PkieAcquisWorkPlanTaskMutationResponse>;
  /** insert a single row into the table: "pkie.acquis_work_plan_task" */
  insert_pkie_acquis_work_plan_task_one?: Maybe<PkieAcquisWorkPlanTask>;
  /** insert data into the table: "pkie.act_type" */
  insert_pkie_act_type?: Maybe<PkieActTypeMutationResponse>;
  /** insert a single row into the table: "pkie.act_type" */
  insert_pkie_act_type_one?: Maybe<PkieActType>;
  /** insert data into the table: "pkie.level_of_alignment" */
  insert_pkie_level_of_alignment?: Maybe<PkieLevelOfAlignmentMutationResponse>;
  /** insert a single row into the table: "pkie.level_of_alignment" */
  insert_pkie_level_of_alignment_one?: Maybe<PkieLevelOfAlignment>;
  /** insert data into the table: "pkie.measure" */
  insert_pkie_measure?: Maybe<PkieMeasureMutationResponse>;
  /** insert data into the table: "pkie.measure_activity" */
  insert_pkie_measure_activity?: Maybe<PkieMeasureActivityMutationResponse>;
  /** insert a single row into the table: "pkie.measure_activity" */
  insert_pkie_measure_activity_one?: Maybe<PkieMeasureActivity>;
  /** insert data into the table: "pkie.measure_answers" */
  insert_pkie_measure_answers?: Maybe<PkieMeasureAnswersMutationResponse>;
  /** insert a single row into the table: "pkie.measure_answers" */
  insert_pkie_measure_answers_one?: Maybe<PkieMeasureAnswers>;
  /** insert data into the table: "pkie.measure_bookmark" */
  insert_pkie_measure_bookmark?: Maybe<PkieMeasureBookmarkMutationResponse>;
  /** insert a single row into the table: "pkie.measure_bookmark" */
  insert_pkie_measure_bookmark_one?: Maybe<PkieMeasureBookmark>;
  /** insert data into the table: "pkie.measure_budgets" */
  insert_pkie_measure_budgets?: Maybe<PkieMeasureBudgetsMutationResponse>;
  /** insert a single row into the table: "pkie.measure_budgets" */
  insert_pkie_measure_budgets_one?: Maybe<PkieMeasureBudgets>;
  /** insert data into the table: "pkie.measure_comment" */
  insert_pkie_measure_comment?: Maybe<PkieMeasureCommentMutationResponse>;
  /** insert a single row into the table: "pkie.measure_comment" */
  insert_pkie_measure_comment_one?: Maybe<PkieMeasureComment>;
  /** insert data into the table: "pkie.measure_gnpie_members" */
  insert_pkie_measure_gnpie_members?: Maybe<PkieMeasureGnpieMembersMutationResponse>;
  /** insert a single row into the table: "pkie.measure_gnpie_members" */
  insert_pkie_measure_gnpie_members_one?: Maybe<PkieMeasureGnpieMembers>;
  /** insert a single row into the table: "pkie.measure" */
  insert_pkie_measure_one?: Maybe<PkieMeasure>;
  /** insert data into the table: "pkie.measure_roadmap" */
  insert_pkie_measure_roadmap?: Maybe<PkieMeasureRoadmapMutationResponse>;
  /** insert a single row into the table: "pkie.measure_roadmap" */
  insert_pkie_measure_roadmap_one?: Maybe<PkieMeasureRoadmap>;
  /** insert data into the table: "pkie.measure_status" */
  insert_pkie_measure_status?: Maybe<PkieMeasureStatusMutationResponse>;
  /** insert a single row into the table: "pkie.measure_status" */
  insert_pkie_measure_status_one?: Maybe<PkieMeasureStatus>;
  /** insert data into the table: "pkie.national_acts" */
  insert_pkie_national_acts?: Maybe<PkieNationalActsMutationResponse>;
  /** insert a single row into the table: "pkie.national_acts" */
  insert_pkie_national_acts_one?: Maybe<PkieNationalActs>;
  /** insert data into the table: "pkie.proces_pune_iaga" */
  insert_pkie_proces_pune_iaga?: Maybe<PkieProcesPuneIagaMutationResponse>;
  /** insert a single row into the table: "pkie.proces_pune_iaga" */
  insert_pkie_proces_pune_iaga_one?: Maybe<PkieProcesPuneIaga>;
  /** insert data into the table: "pkie.proces_pune_lga" */
  insert_pkie_proces_pune_lga?: Maybe<PkieProcesPuneLgaMutationResponse>;
  /** insert a single row into the table: "pkie.proces_pune_lga" */
  insert_pkie_proces_pune_lga_one?: Maybe<PkieProcesPuneLga>;
  /** insert data into the table: "pkie.source_of_funding" */
  insert_pkie_source_of_funding?: Maybe<PkieSourceOfFundingMutationResponse>;
  /** insert a single row into the table: "pkie.source_of_funding" */
  insert_pkie_source_of_funding_one?: Maybe<PkieSourceOfFunding>;
  /** insert data into the table: "pkie.type_alignment_act" */
  insert_pkie_type_alignment_act?: Maybe<PkieTypeAlignmentActMutationResponse>;
  /** insert a single row into the table: "pkie.type_alignment_act" */
  insert_pkie_type_alignment_act_one?: Maybe<PkieTypeAlignmentAct>;
  /** insert data into the table: "pkie.type_measure" */
  insert_pkie_type_measure?: Maybe<PkieTypeMeasureMutationResponse>;
  /** insert data into the table: "pkie.type_measure_budget" */
  insert_pkie_type_measure_budget?: Maybe<PkieTypeMeasureBudgetMutationResponse>;
  /** insert a single row into the table: "pkie.type_measure_budget" */
  insert_pkie_type_measure_budget_one?: Maybe<PkieTypeMeasureBudget>;
  /** insert a single row into the table: "pkie.type_measure" */
  insert_pkie_type_measure_one?: Maybe<PkieTypeMeasure>;
  /** insert data into the table: "pkie.work_plan_comment" */
  insert_pkie_work_plan_comment?: Maybe<PkieWorkPlanCommentMutationResponse>;
  /** insert a single row into the table: "pkie.work_plan_comment" */
  insert_pkie_work_plan_comment_one?: Maybe<PkieWorkPlanComment>;
  /** insert data into the table: "pkie.work_plan_statuses" */
  insert_pkie_work_plan_statuses?: Maybe<PkieWorkPlanStatusesMutationResponse>;
  /** insert a single row into the table: "pkie.work_plan_statuses" */
  insert_pkie_work_plan_statuses_one?: Maybe<PkieWorkPlanStatuses>;
  /** insert data into the table: "pkie.work_plan_task" */
  insert_pkie_work_plan_task?: Maybe<PkieWorkPlanTaskMutationResponse>;
  /** insert data into the table: "pkie.work_plan_task_activity" */
  insert_pkie_work_plan_task_activity?: Maybe<PkieWorkPlanTaskActivityMutationResponse>;
  /** insert a single row into the table: "pkie.work_plan_task_activity" */
  insert_pkie_work_plan_task_activity_one?: Maybe<PkieWorkPlanTaskActivity>;
  /** insert a single row into the table: "pkie.work_plan_task" */
  insert_pkie_work_plan_task_one?: Maybe<PkieWorkPlanTask>;
  /** insert data into the table: "pkie.work_plan_task_statuses" */
  insert_pkie_work_plan_task_statuses?: Maybe<PkieWorkPlanTaskStatusesMutationResponse>;
  /** insert a single row into the table: "pkie.work_plan_task_statuses" */
  insert_pkie_work_plan_task_statuses_one?: Maybe<PkieWorkPlanTaskStatuses>;
  /** insert data into the table: "pkie.work_plans" */
  insert_pkie_work_plans?: Maybe<PkieWorkPlansMutationResponse>;
  /** insert a single row into the table: "pkie.work_plans" */
  insert_pkie_work_plans_one?: Maybe<PkieWorkPlans>;
  /** insert data into the table: "question_types" */
  insert_question_types?: Maybe<QuestionTypesMutationResponse>;
  /** insert a single row into the table: "question_types" */
  insert_question_types_one?: Maybe<QuestionTypes>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<QuestionsMutationResponse>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "recommendations" */
  insert_recommendations?: Maybe<RecommendationsMutationResponse>;
  /** insert a single row into the table: "recommendations" */
  insert_recommendations_one?: Maybe<Recommendations>;
  /** insert data into the table: "report_answers" */
  insert_report_answers?: Maybe<ReportAnswersMutationResponse>;
  /** insert a single row into the table: "report_answers" */
  insert_report_answers_one?: Maybe<ReportAnswers>;
  /** insert data into the table: "report_chapter_statuses" */
  insert_report_chapter_statuses?: Maybe<ReportChapterStatusesMutationResponse>;
  /** insert a single row into the table: "report_chapter_statuses" */
  insert_report_chapter_statuses_one?: Maybe<ReportChapterStatuses>;
  /** insert data into the table: "report_chapters" */
  insert_report_chapters?: Maybe<ReportChaptersMutationResponse>;
  /** insert a single row into the table: "report_chapters" */
  insert_report_chapters_one?: Maybe<ReportChapters>;
  /** insert data into the table: "report_statuses" */
  insert_report_statuses?: Maybe<ReportStatusesMutationResponse>;
  /** insert a single row into the table: "report_statuses" */
  insert_report_statuses_one?: Maybe<ReportStatuses>;
  /** insert data into the table: "report_task_activity" */
  insert_report_task_activity?: Maybe<ReportTaskActivityMutationResponse>;
  /** insert a single row into the table: "report_task_activity" */
  insert_report_task_activity_one?: Maybe<ReportTaskActivity>;
  /** insert data into the table: "report_task_comment" */
  insert_report_task_comment?: Maybe<ReportTaskCommentMutationResponse>;
  /** insert a single row into the table: "report_task_comment" */
  insert_report_task_comment_one?: Maybe<ReportTaskComment>;
  /** insert data into the table: "report_task_gnpie_members" */
  insert_report_task_gnpie_members?: Maybe<ReportTaskGnpieMembersMutationResponse>;
  /** insert a single row into the table: "report_task_gnpie_members" */
  insert_report_task_gnpie_members_one?: Maybe<ReportTaskGnpieMembers>;
  /** insert data into the table: "report_task_statuses" */
  insert_report_task_statuses?: Maybe<ReportTaskStatusesMutationResponse>;
  /** insert a single row into the table: "report_task_statuses" */
  insert_report_task_statuses_one?: Maybe<ReportTaskStatuses>;
  /** insert data into the table: "report_tasks" */
  insert_report_tasks?: Maybe<ReportTasksMutationResponse>;
  /** insert a single row into the table: "report_tasks" */
  insert_report_tasks_one?: Maybe<ReportTasks>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<ReportsMutationResponse>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "roadmap_measure_type" */
  insert_roadmap_measure_type?: Maybe<RoadmapMeasureTypeMutationResponse>;
  /** insert a single row into the table: "roadmap_measure_type" */
  insert_roadmap_measure_type_one?: Maybe<RoadmapMeasureType>;
  /** insert data into the table: "signed_documents" */
  insert_signed_documents?: Maybe<SignedDocumentsMutationResponse>;
  /** insert a single row into the table: "signed_documents" */
  insert_signed_documents_one?: Maybe<SignedDocuments>;
  /** insert data into the table: "sub_chapter_lead_institutions" */
  insert_sub_chapter_lead_institutions?: Maybe<SubChapterLeadInstitutionsMutationResponse>;
  /** insert a single row into the table: "sub_chapter_lead_institutions" */
  insert_sub_chapter_lead_institutions_one?: Maybe<SubChapterLeadInstitutions>;
  /** insert data into the table: "sub_chapters" */
  insert_sub_chapters?: Maybe<SubChaptersMutationResponse>;
  /** insert a single row into the table: "sub_chapters" */
  insert_sub_chapters_one?: Maybe<SubChapters>;
  /** insert data into the table: "task" */
  insert_task?: Maybe<TaskMutationResponse>;
  /** insert data into the table: "task_activity" */
  insert_task_activity?: Maybe<TaskActivityMutationResponse>;
  /** insert a single row into the table: "task_activity" */
  insert_task_activity_one?: Maybe<TaskActivity>;
  /** insert data into the table: "task_answers" */
  insert_task_answers?: Maybe<TaskAnswersMutationResponse>;
  /** insert a single row into the table: "task_answers" */
  insert_task_answers_one?: Maybe<TaskAnswers>;
  /** insert data into the table: "task_comments" */
  insert_task_comments?: Maybe<TaskCommentsMutationResponse>;
  /** insert a single row into the table: "task_comments" */
  insert_task_comments_one?: Maybe<TaskComments>;
  /** insert data into the table: "task_gnpie_members" */
  insert_task_gnpie_members?: Maybe<TaskGnpieMembersMutationResponse>;
  /** insert a single row into the table: "task_gnpie_members" */
  insert_task_gnpie_members_one?: Maybe<TaskGnpieMembers>;
  /** insert a single row into the table: "task" */
  insert_task_one?: Maybe<Task>;
  /** insert data into the table: "task_statuses" */
  insert_task_statuses?: Maybe<TaskStatusesMutationResponse>;
  /** insert a single row into the table: "task_statuses" */
  insert_task_statuses_one?: Maybe<TaskStatuses>;
  /** insert data into the table: "task_types" */
  insert_task_types?: Maybe<TaskTypesMutationResponse>;
  /** insert a single row into the table: "task_types" */
  insert_task_types_one?: Maybe<TaskTypes>;
  /** insert data into the table: "topics" */
  insert_topics?: Maybe<TopicsMutationResponse>;
  /** insert a single row into the table: "topics" */
  insert_topics_one?: Maybe<Topics>;
  /** insert data into the table: "type_institutions" */
  insert_type_institutions?: Maybe<TypeInstitutionsMutationResponse>;
  /** insert a single row into the table: "type_institutions" */
  insert_type_institutions_one?: Maybe<TypeInstitutions>;
  /** insert data into the table: "yearly_quarters" */
  insert_yearly_quarters?: Maybe<YearlyQuartersMutationResponse>;
  /** insert a single row into the table: "yearly_quarters" */
  insert_yearly_quarters_one?: Maybe<YearlyQuarters>;
  /** update single row of the table: "auth.providers" */
  updateAuthProvider?: Maybe<AuthProviders>;
  /** update single row of the table: "auth.provider_requests" */
  updateAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** update data of the table: "auth.provider_requests" */
  updateAuthProviderRequests?: Maybe<AuthProviderRequestsMutationResponse>;
  /** update data of the table: "auth.providers" */
  updateAuthProviders?: Maybe<AuthProvidersMutationResponse>;
  /** update single row of the table: "auth.refresh_tokens" */
  updateAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** update single row of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** update data of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypesMutationResponse>;
  /** update data of the table: "auth.refresh_tokens" */
  updateAuthRefreshTokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** update single row of the table: "auth.roles" */
  updateAuthRole?: Maybe<AuthRoles>;
  /** update data of the table: "auth.roles" */
  updateAuthRoles?: Maybe<AuthRolesMutationResponse>;
  /** update single row of the table: "auth.user_providers" */
  updateAuthUserProvider?: Maybe<AuthUserProviders>;
  /** update data of the table: "auth.user_providers" */
  updateAuthUserProviders?: Maybe<AuthUserProvidersMutationResponse>;
  /** update single row of the table: "auth.user_roles" */
  updateAuthUserRole?: Maybe<AuthUserRoles>;
  /** update data of the table: "auth.user_roles" */
  updateAuthUserRoles?: Maybe<AuthUserRolesMutationResponse>;
  /** update single row of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** update data of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeysMutationResponse>;
  /** updateFolder */
  updateFolder?: Maybe<UpdateFolderOutput>;
  /** update single row of the table: "auth.users" */
  updateUser?: Maybe<Users>;
  /** update data of the table: "auth.users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update multiples rows of table: "auth.provider_requests" */
  update_authProviderRequests_many?: Maybe<Array<Maybe<AuthProviderRequestsMutationResponse>>>;
  /** update multiples rows of table: "auth.providers" */
  update_authProviders_many?: Maybe<Array<Maybe<AuthProvidersMutationResponse>>>;
  /** update multiples rows of table: "auth.refresh_token_types" */
  update_authRefreshTokenTypes_many?: Maybe<Array<Maybe<AuthRefreshTokenTypesMutationResponse>>>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_authRefreshTokens_many?: Maybe<Array<Maybe<AuthRefreshTokensMutationResponse>>>;
  /** update multiples rows of table: "auth.roles" */
  update_authRoles_many?: Maybe<Array<Maybe<AuthRolesMutationResponse>>>;
  /** update multiples rows of table: "auth.user_providers" */
  update_authUserProviders_many?: Maybe<Array<Maybe<AuthUserProvidersMutationResponse>>>;
  /** update multiples rows of table: "auth.user_roles" */
  update_authUserRoles_many?: Maybe<Array<Maybe<AuthUserRolesMutationResponse>>>;
  /** update multiples rows of table: "auth.user_security_keys" */
  update_authUserSecurityKeys_many?: Maybe<Array<Maybe<AuthUserSecurityKeysMutationResponse>>>;
  /** update data of the table: "auth.modules" */
  update_auth_modules?: Maybe<AuthModulesMutationResponse>;
  /** update single row of the table: "auth.modules" */
  update_auth_modules_by_pk?: Maybe<AuthModules>;
  /** update multiples rows of table: "auth.modules" */
  update_auth_modules_many?: Maybe<Array<Maybe<AuthModulesMutationResponse>>>;
  /** update data of the table: "bookmark_gnpie_members" */
  update_bookmark_gnpie_members?: Maybe<BookmarkGnpieMembersMutationResponse>;
  /** update single row of the table: "bookmark_gnpie_members" */
  update_bookmark_gnpie_members_by_pk?: Maybe<BookmarkGnpieMembers>;
  /** update multiples rows of table: "bookmark_gnpie_members" */
  update_bookmark_gnpie_members_many?: Maybe<Array<Maybe<BookmarkGnpieMembersMutationResponse>>>;
  /** update data of the table: "bookmark_status" */
  update_bookmark_status?: Maybe<BookmarkStatusMutationResponse>;
  /** update single row of the table: "bookmark_status" */
  update_bookmark_status_by_pk?: Maybe<BookmarkStatus>;
  /** update multiples rows of table: "bookmark_status" */
  update_bookmark_status_many?: Maybe<Array<Maybe<BookmarkStatusMutationResponse>>>;
  /** update data of the table: "bookmark_task_activity" */
  update_bookmark_task_activity?: Maybe<BookmarkTaskActivityMutationResponse>;
  /** update single row of the table: "bookmark_task_activity" */
  update_bookmark_task_activity_by_pk?: Maybe<BookmarkTaskActivity>;
  /** update multiples rows of table: "bookmark_task_activity" */
  update_bookmark_task_activity_many?: Maybe<Array<Maybe<BookmarkTaskActivityMutationResponse>>>;
  /** update data of the table: "bookmark_task_answers" */
  update_bookmark_task_answers?: Maybe<BookmarkTaskAnswersMutationResponse>;
  /** update single row of the table: "bookmark_task_answers" */
  update_bookmark_task_answers_by_pk?: Maybe<BookmarkTaskAnswers>;
  /** update multiples rows of table: "bookmark_task_answers" */
  update_bookmark_task_answers_many?: Maybe<Array<Maybe<BookmarkTaskAnswersMutationResponse>>>;
  /** update data of the table: "bookmark_task_comment" */
  update_bookmark_task_comment?: Maybe<BookmarkTaskCommentMutationResponse>;
  /** update single row of the table: "bookmark_task_comment" */
  update_bookmark_task_comment_by_pk?: Maybe<BookmarkTaskComment>;
  /** update multiples rows of table: "bookmark_task_comment" */
  update_bookmark_task_comment_many?: Maybe<Array<Maybe<BookmarkTaskCommentMutationResponse>>>;
  /** update data of the table: "bookmark_task_measures" */
  update_bookmark_task_measures?: Maybe<BookmarkTaskMeasuresMutationResponse>;
  /** update single row of the table: "bookmark_task_measures" */
  update_bookmark_task_measures_by_pk?: Maybe<BookmarkTaskMeasures>;
  /** update multiples rows of table: "bookmark_task_measures" */
  update_bookmark_task_measures_many?: Maybe<Array<Maybe<BookmarkTaskMeasuresMutationResponse>>>;
  /** update data of the table: "bookmark_task_statuses" */
  update_bookmark_task_statuses?: Maybe<BookmarkTaskStatusesMutationResponse>;
  /** update single row of the table: "bookmark_task_statuses" */
  update_bookmark_task_statuses_by_pk?: Maybe<BookmarkTaskStatuses>;
  /** update multiples rows of table: "bookmark_task_statuses" */
  update_bookmark_task_statuses_many?: Maybe<Array<Maybe<BookmarkTaskStatusesMutationResponse>>>;
  /** update data of the table: "bookmark_tasks" */
  update_bookmark_tasks?: Maybe<BookmarkTasksMutationResponse>;
  /** update single row of the table: "bookmark_tasks" */
  update_bookmark_tasks_by_pk?: Maybe<BookmarkTasks>;
  /** update multiples rows of table: "bookmark_tasks" */
  update_bookmark_tasks_many?: Maybe<Array<Maybe<BookmarkTasksMutationResponse>>>;
  /** update data of the table: "bookmarks" */
  update_bookmarks?: Maybe<BookmarksMutationResponse>;
  /** update single row of the table: "bookmarks" */
  update_bookmarks_by_pk?: Maybe<Bookmarks>;
  /** update multiples rows of table: "bookmarks" */
  update_bookmarks_many?: Maybe<Array<Maybe<BookmarksMutationResponse>>>;
  /** update data of the table: "capac.beneficiaries" */
  update_capac_beneficiaries?: Maybe<CapacBeneficiariesMutationResponse>;
  /** update single row of the table: "capac.beneficiaries" */
  update_capac_beneficiaries_by_pk?: Maybe<CapacBeneficiaries>;
  /** update multiples rows of table: "capac.beneficiaries" */
  update_capac_beneficiaries_many?: Maybe<Array<Maybe<CapacBeneficiariesMutationResponse>>>;
  /** update data of the table: "capac.chapter_donator" */
  update_capac_chapter_donator?: Maybe<CapacChapterDonatorMutationResponse>;
  /** update single row of the table: "capac.chapter_donator" */
  update_capac_chapter_donator_by_pk?: Maybe<CapacChapterDonator>;
  /** update multiples rows of table: "capac.chapter_donator" */
  update_capac_chapter_donator_many?: Maybe<Array<Maybe<CapacChapterDonatorMutationResponse>>>;
  /** update data of the table: "capac.donators" */
  update_capac_donators?: Maybe<CapacDonatorsMutationResponse>;
  /** update single row of the table: "capac.donators" */
  update_capac_donators_by_pk?: Maybe<CapacDonators>;
  /** update multiples rows of table: "capac.donators" */
  update_capac_donators_many?: Maybe<Array<Maybe<CapacDonatorsMutationResponse>>>;
  /** update data of the table: "capac.experts" */
  update_capac_experts?: Maybe<CapacExpertsMutationResponse>;
  /** update single row of the table: "capac.experts" */
  update_capac_experts_by_pk?: Maybe<CapacExperts>;
  /** update multiples rows of table: "capac.experts" */
  update_capac_experts_many?: Maybe<Array<Maybe<CapacExpertsMutationResponse>>>;
  /** update data of the table: "capac.integration_steps" */
  update_capac_integration_steps?: Maybe<CapacIntegrationStepsMutationResponse>;
  /** update single row of the table: "capac.integration_steps" */
  update_capac_integration_steps_by_pk?: Maybe<CapacIntegrationSteps>;
  /** update multiples rows of table: "capac.integration_steps" */
  update_capac_integration_steps_many?: Maybe<Array<Maybe<CapacIntegrationStepsMutationResponse>>>;
  /** update data of the table: "capac.priority_lists" */
  update_capac_priority_lists?: Maybe<CapacPriorityListsMutationResponse>;
  /** update single row of the table: "capac.priority_lists" */
  update_capac_priority_lists_by_pk?: Maybe<CapacPriorityLists>;
  /** update multiples rows of table: "capac.priority_lists" */
  update_capac_priority_lists_many?: Maybe<Array<Maybe<CapacPriorityListsMutationResponse>>>;
  /** update data of the table: "capac.project_beneficiaries" */
  update_capac_project_beneficiaries?: Maybe<CapacProjectBeneficiariesMutationResponse>;
  /** update single row of the table: "capac.project_beneficiaries" */
  update_capac_project_beneficiaries_by_pk?: Maybe<CapacProjectBeneficiaries>;
  /** update multiples rows of table: "capac.project_beneficiaries" */
  update_capac_project_beneficiaries_many?: Maybe<Array<Maybe<CapacProjectBeneficiariesMutationResponse>>>;
  /** update data of the table: "capac.projects" */
  update_capac_projects?: Maybe<CapacProjectsMutationResponse>;
  /** update single row of the table: "capac.projects" */
  update_capac_projects_by_pk?: Maybe<CapacProjects>;
  /** update multiples rows of table: "capac.projects" */
  update_capac_projects_many?: Maybe<Array<Maybe<CapacProjectsMutationResponse>>>;
  /** update data of the table: "capac.taiex" */
  update_capac_taiex?: Maybe<CapacTaiexMutationResponse>;
  /** update single row of the table: "capac.taiex" */
  update_capac_taiex_by_pk?: Maybe<CapacTaiex>;
  /** update multiples rows of table: "capac.taiex" */
  update_capac_taiex_many?: Maybe<Array<Maybe<CapacTaiexMutationResponse>>>;
  /** update data of the table: "capac.training_needs" */
  update_capac_training_needs?: Maybe<CapacTrainingNeedsMutationResponse>;
  /** update single row of the table: "capac.training_needs" */
  update_capac_training_needs_by_pk?: Maybe<CapacTrainingNeeds>;
  /** update multiples rows of table: "capac.training_needs" */
  update_capac_training_needs_many?: Maybe<Array<Maybe<CapacTrainingNeedsMutationResponse>>>;
  /** update data of the table: "capac.training_participants" */
  update_capac_training_participants?: Maybe<CapacTrainingParticipantsMutationResponse>;
  /** update single row of the table: "capac.training_participants" */
  update_capac_training_participants_by_pk?: Maybe<CapacTrainingParticipants>;
  /** update multiples rows of table: "capac.training_participants" */
  update_capac_training_participants_many?: Maybe<Array<Maybe<CapacTrainingParticipantsMutationResponse>>>;
  /** update data of the table: "capac.training_sessions" */
  update_capac_training_sessions?: Maybe<CapacTrainingSessionsMutationResponse>;
  /** update single row of the table: "capac.training_sessions" */
  update_capac_training_sessions_by_pk?: Maybe<CapacTrainingSessions>;
  /** update multiples rows of table: "capac.training_sessions" */
  update_capac_training_sessions_many?: Maybe<Array<Maybe<CapacTrainingSessionsMutationResponse>>>;
  /** update data of the table: "capac.trainings" */
  update_capac_trainings?: Maybe<CapacTrainingsMutationResponse>;
  /** update single row of the table: "capac.trainings" */
  update_capac_trainings_by_pk?: Maybe<CapacTrainings>;
  /** update data of the table: "capac.trainings_experts" */
  update_capac_trainings_experts?: Maybe<CapacTrainingsExpertsMutationResponse>;
  /** update single row of the table: "capac.trainings_experts" */
  update_capac_trainings_experts_by_pk?: Maybe<CapacTrainingsExperts>;
  /** update multiples rows of table: "capac.trainings_experts" */
  update_capac_trainings_experts_many?: Maybe<Array<Maybe<CapacTrainingsExpertsMutationResponse>>>;
  /** update multiples rows of table: "capac.trainings" */
  update_capac_trainings_many?: Maybe<Array<Maybe<CapacTrainingsMutationResponse>>>;
  /** update data of the table: "capac.twinning" */
  update_capac_twinning?: Maybe<CapacTwinningMutationResponse>;
  /** update single row of the table: "capac.twinning" */
  update_capac_twinning_by_pk?: Maybe<CapacTwinning>;
  /** update multiples rows of table: "capac.twinning" */
  update_capac_twinning_many?: Maybe<Array<Maybe<CapacTwinningMutationResponse>>>;
  /** update data of the table: "chapter_lead_institutions" */
  update_chapter_lead_institutions?: Maybe<ChapterLeadInstitutionsMutationResponse>;
  /** update single row of the table: "chapter_lead_institutions" */
  update_chapter_lead_institutions_by_pk?: Maybe<ChapterLeadInstitutions>;
  /** update multiples rows of table: "chapter_lead_institutions" */
  update_chapter_lead_institutions_many?: Maybe<Array<Maybe<ChapterLeadInstitutionsMutationResponse>>>;
  /** update data of the table: "chapters" */
  update_chapters?: Maybe<ChaptersMutationResponse>;
  /** update single row of the table: "chapters" */
  update_chapters_by_pk?: Maybe<Chapters>;
  /** update multiples rows of table: "chapters" */
  update_chapters_many?: Maybe<Array<Maybe<ChaptersMutationResponse>>>;
  /** update data of the table: "clusters" */
  update_clusters?: Maybe<ClustersMutationResponse>;
  /** update single row of the table: "clusters" */
  update_clusters_by_pk?: Maybe<Clusters>;
  /** update multiples rows of table: "clusters" */
  update_clusters_many?: Maybe<Array<Maybe<ClustersMutationResponse>>>;
  /** update data of the table: "dms.folder" */
  update_dms_folder?: Maybe<DmsFolderMutationResponse>;
  /** update single row of the table: "dms.folder" */
  update_dms_folder_by_pk?: Maybe<DmsFolder>;
  /** update multiples rows of table: "dms.folder" */
  update_dms_folder_many?: Maybe<Array<Maybe<DmsFolderMutationResponse>>>;
  /** update data of the table: "dms.folder_type" */
  update_dms_folder_type?: Maybe<DmsFolderTypeMutationResponse>;
  /** update single row of the table: "dms.folder_type" */
  update_dms_folder_type_by_pk?: Maybe<DmsFolderType>;
  /** update multiples rows of table: "dms.folder_type" */
  update_dms_folder_type_many?: Maybe<Array<Maybe<DmsFolderTypeMutationResponse>>>;
  /** update data of the table: "documents" */
  update_documents?: Maybe<DocumentsMutationResponse>;
  /** update single row of the table: "documents" */
  update_documents_by_pk?: Maybe<Documents>;
  /** update multiples rows of table: "documents" */
  update_documents_many?: Maybe<Array<Maybe<DocumentsMutationResponse>>>;
  /** update data of the table: "error_logs" */
  update_error_logs?: Maybe<ErrorLogsMutationResponse>;
  /** update single row of the table: "error_logs" */
  update_error_logs_by_pk?: Maybe<ErrorLogs>;
  /** update multiples rows of table: "error_logs" */
  update_error_logs_many?: Maybe<Array<Maybe<ErrorLogsMutationResponse>>>;
  /** update data of the table: "event_notes" */
  update_event_notes?: Maybe<EventNotesMutationResponse>;
  /** update single row of the table: "event_notes" */
  update_event_notes_by_pk?: Maybe<EventNotes>;
  /** update multiples rows of table: "event_notes" */
  update_event_notes_many?: Maybe<Array<Maybe<EventNotesMutationResponse>>>;
  /** update data of the table: "event_typology" */
  update_event_typology?: Maybe<EventTypologyMutationResponse>;
  /** update single row of the table: "event_typology" */
  update_event_typology_by_pk?: Maybe<EventTypology>;
  /** update multiples rows of table: "event_typology" */
  update_event_typology_many?: Maybe<Array<Maybe<EventTypologyMutationResponse>>>;
  /** update data of the table: "event_users" */
  update_event_users?: Maybe<EventUsersMutationResponse>;
  /** update single row of the table: "event_users" */
  update_event_users_by_pk?: Maybe<EventUsers>;
  /** update multiples rows of table: "event_users" */
  update_event_users_many?: Maybe<Array<Maybe<EventUsersMutationResponse>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<EventsMutationResponse>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<EventsMutationResponse>>>;
  /** update data of the table: "gnpie" */
  update_gnpie?: Maybe<GnpieMutationResponse>;
  /** update single row of the table: "gnpie" */
  update_gnpie_by_pk?: Maybe<Gnpie>;
  /** update multiples rows of table: "gnpie" */
  update_gnpie_many?: Maybe<Array<Maybe<GnpieMutationResponse>>>;
  /** update data of the table: "institutions" */
  update_institutions?: Maybe<InstitutionsMutationResponse>;
  /** update single row of the table: "institutions" */
  update_institutions_by_pk?: Maybe<Institutions>;
  /** update multiples rows of table: "institutions" */
  update_institutions_many?: Maybe<Array<Maybe<InstitutionsMutationResponse>>>;
  /** update data of the table: "integration_phases" */
  update_integration_phases?: Maybe<IntegrationPhasesMutationResponse>;
  /** update single row of the table: "integration_phases" */
  update_integration_phases_by_pk?: Maybe<IntegrationPhases>;
  /** update data of the table: "integration_phases_enum" */
  update_integration_phases_enum?: Maybe<IntegrationPhasesEnumMutationResponse>;
  /** update single row of the table: "integration_phases_enum" */
  update_integration_phases_enum_by_pk?: Maybe<IntegrationPhasesEnum>;
  /** update multiples rows of table: "integration_phases_enum" */
  update_integration_phases_enum_many?: Maybe<Array<Maybe<IntegrationPhasesEnumMutationResponse>>>;
  /** update multiples rows of table: "integration_phases" */
  update_integration_phases_many?: Maybe<Array<Maybe<IntegrationPhasesMutationResponse>>>;
  /** update data of the table: "meeting_attendance" */
  update_meeting_attendance?: Maybe<MeetingAttendanceMutationResponse>;
  /** update single row of the table: "meeting_attendance" */
  update_meeting_attendance_by_pk?: Maybe<MeetingAttendance>;
  /** update multiples rows of table: "meeting_attendance" */
  update_meeting_attendance_many?: Maybe<Array<Maybe<MeetingAttendanceMutationResponse>>>;
  /** update data of the table: "meeting_notes" */
  update_meeting_notes?: Maybe<MeetingNotesMutationResponse>;
  /** update single row of the table: "meeting_notes" */
  update_meeting_notes_by_pk?: Maybe<MeetingNotes>;
  /** update multiples rows of table: "meeting_notes" */
  update_meeting_notes_many?: Maybe<Array<Maybe<MeetingNotesMutationResponse>>>;
  /** update data of the table: "meeting_users" */
  update_meeting_users?: Maybe<MeetingUsersMutationResponse>;
  /** update single row of the table: "meeting_users" */
  update_meeting_users_by_pk?: Maybe<MeetingUsers>;
  /** update multiples rows of table: "meeting_users" */
  update_meeting_users_many?: Maybe<Array<Maybe<MeetingUsersMutationResponse>>>;
  /** update data of the table: "meetings" */
  update_meetings?: Maybe<MeetingsMutationResponse>;
  /** update single row of the table: "meetings" */
  update_meetings_by_pk?: Maybe<Meetings>;
  /** update multiples rows of table: "meetings" */
  update_meetings_many?: Maybe<Array<Maybe<MeetingsMutationResponse>>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<NotificationMutationResponse>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<NotificationMutationResponse>>>;
  /** update data of the table: "notification_origin" */
  update_notification_origin?: Maybe<NotificationOriginMutationResponse>;
  /** update single row of the table: "notification_origin" */
  update_notification_origin_by_pk?: Maybe<NotificationOrigin>;
  /** update multiples rows of table: "notification_origin" */
  update_notification_origin_many?: Maybe<Array<Maybe<NotificationOriginMutationResponse>>>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<NotificationTypeMutationResponse>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<NotificationType>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<Array<Maybe<NotificationTypeMutationResponse>>>;
  /** update data of the table: "pkie.acquis" */
  update_pkie_acquis?: Maybe<PkieAcquisMutationResponse>;
  /** update single row of the table: "pkie.acquis" */
  update_pkie_acquis_by_pk?: Maybe<PkieAcquis>;
  /** update data of the table: "pkie.acquis_chapter" */
  update_pkie_acquis_chapter?: Maybe<PkieAcquisChapterMutationResponse>;
  /** update single row of the table: "pkie.acquis_chapter" */
  update_pkie_acquis_chapter_by_pk?: Maybe<PkieAcquisChapter>;
  /** update multiples rows of table: "pkie.acquis_chapter" */
  update_pkie_acquis_chapter_many?: Maybe<Array<Maybe<PkieAcquisChapterMutationResponse>>>;
  /** update data of the table: "pkie.acquis_institutions" */
  update_pkie_acquis_institutions?: Maybe<PkieAcquisInstitutionsMutationResponse>;
  /** update single row of the table: "pkie.acquis_institutions" */
  update_pkie_acquis_institutions_by_pk?: Maybe<PkieAcquisInstitutions>;
  /** update multiples rows of table: "pkie.acquis_institutions" */
  update_pkie_acquis_institutions_many?: Maybe<Array<Maybe<PkieAcquisInstitutionsMutationResponse>>>;
  /** update multiples rows of table: "pkie.acquis" */
  update_pkie_acquis_many?: Maybe<Array<Maybe<PkieAcquisMutationResponse>>>;
  /** update data of the table: "pkie.acquis_national_acts" */
  update_pkie_acquis_national_acts?: Maybe<PkieAcquisNationalActsMutationResponse>;
  /** update single row of the table: "pkie.acquis_national_acts" */
  update_pkie_acquis_national_acts_by_pk?: Maybe<PkieAcquisNationalActs>;
  /** update multiples rows of table: "pkie.acquis_national_acts" */
  update_pkie_acquis_national_acts_many?: Maybe<Array<Maybe<PkieAcquisNationalActsMutationResponse>>>;
  /** update data of the table: "pkie.acquis_type" */
  update_pkie_acquis_type?: Maybe<PkieAcquisTypeMutationResponse>;
  /** update single row of the table: "pkie.acquis_type" */
  update_pkie_acquis_type_by_pk?: Maybe<PkieAcquisType>;
  /** update multiples rows of table: "pkie.acquis_type" */
  update_pkie_acquis_type_many?: Maybe<Array<Maybe<PkieAcquisTypeMutationResponse>>>;
  /** update data of the table: "pkie.acquis_work_plan_task" */
  update_pkie_acquis_work_plan_task?: Maybe<PkieAcquisWorkPlanTaskMutationResponse>;
  /** update single row of the table: "pkie.acquis_work_plan_task" */
  update_pkie_acquis_work_plan_task_by_pk?: Maybe<PkieAcquisWorkPlanTask>;
  /** update multiples rows of table: "pkie.acquis_work_plan_task" */
  update_pkie_acquis_work_plan_task_many?: Maybe<Array<Maybe<PkieAcquisWorkPlanTaskMutationResponse>>>;
  /** update data of the table: "pkie.act_type" */
  update_pkie_act_type?: Maybe<PkieActTypeMutationResponse>;
  /** update single row of the table: "pkie.act_type" */
  update_pkie_act_type_by_pk?: Maybe<PkieActType>;
  /** update multiples rows of table: "pkie.act_type" */
  update_pkie_act_type_many?: Maybe<Array<Maybe<PkieActTypeMutationResponse>>>;
  /** update data of the table: "pkie.level_of_alignment" */
  update_pkie_level_of_alignment?: Maybe<PkieLevelOfAlignmentMutationResponse>;
  /** update single row of the table: "pkie.level_of_alignment" */
  update_pkie_level_of_alignment_by_pk?: Maybe<PkieLevelOfAlignment>;
  /** update multiples rows of table: "pkie.level_of_alignment" */
  update_pkie_level_of_alignment_many?: Maybe<Array<Maybe<PkieLevelOfAlignmentMutationResponse>>>;
  /** update data of the table: "pkie.measure" */
  update_pkie_measure?: Maybe<PkieMeasureMutationResponse>;
  /** update data of the table: "pkie.measure_activity" */
  update_pkie_measure_activity?: Maybe<PkieMeasureActivityMutationResponse>;
  /** update single row of the table: "pkie.measure_activity" */
  update_pkie_measure_activity_by_pk?: Maybe<PkieMeasureActivity>;
  /** update multiples rows of table: "pkie.measure_activity" */
  update_pkie_measure_activity_many?: Maybe<Array<Maybe<PkieMeasureActivityMutationResponse>>>;
  /** update data of the table: "pkie.measure_answers" */
  update_pkie_measure_answers?: Maybe<PkieMeasureAnswersMutationResponse>;
  /** update single row of the table: "pkie.measure_answers" */
  update_pkie_measure_answers_by_pk?: Maybe<PkieMeasureAnswers>;
  /** update multiples rows of table: "pkie.measure_answers" */
  update_pkie_measure_answers_many?: Maybe<Array<Maybe<PkieMeasureAnswersMutationResponse>>>;
  /** update data of the table: "pkie.measure_bookmark" */
  update_pkie_measure_bookmark?: Maybe<PkieMeasureBookmarkMutationResponse>;
  /** update single row of the table: "pkie.measure_bookmark" */
  update_pkie_measure_bookmark_by_pk?: Maybe<PkieMeasureBookmark>;
  /** update multiples rows of table: "pkie.measure_bookmark" */
  update_pkie_measure_bookmark_many?: Maybe<Array<Maybe<PkieMeasureBookmarkMutationResponse>>>;
  /** update data of the table: "pkie.measure_budgets" */
  update_pkie_measure_budgets?: Maybe<PkieMeasureBudgetsMutationResponse>;
  /** update single row of the table: "pkie.measure_budgets" */
  update_pkie_measure_budgets_by_pk?: Maybe<PkieMeasureBudgets>;
  /** update multiples rows of table: "pkie.measure_budgets" */
  update_pkie_measure_budgets_many?: Maybe<Array<Maybe<PkieMeasureBudgetsMutationResponse>>>;
  /** update single row of the table: "pkie.measure" */
  update_pkie_measure_by_pk?: Maybe<PkieMeasure>;
  /** update data of the table: "pkie.measure_comment" */
  update_pkie_measure_comment?: Maybe<PkieMeasureCommentMutationResponse>;
  /** update single row of the table: "pkie.measure_comment" */
  update_pkie_measure_comment_by_pk?: Maybe<PkieMeasureComment>;
  /** update multiples rows of table: "pkie.measure_comment" */
  update_pkie_measure_comment_many?: Maybe<Array<Maybe<PkieMeasureCommentMutationResponse>>>;
  /** update data of the table: "pkie.measure_gnpie_members" */
  update_pkie_measure_gnpie_members?: Maybe<PkieMeasureGnpieMembersMutationResponse>;
  /** update single row of the table: "pkie.measure_gnpie_members" */
  update_pkie_measure_gnpie_members_by_pk?: Maybe<PkieMeasureGnpieMembers>;
  /** update multiples rows of table: "pkie.measure_gnpie_members" */
  update_pkie_measure_gnpie_members_many?: Maybe<Array<Maybe<PkieMeasureGnpieMembersMutationResponse>>>;
  /** update multiples rows of table: "pkie.measure" */
  update_pkie_measure_many?: Maybe<Array<Maybe<PkieMeasureMutationResponse>>>;
  /** update data of the table: "pkie.measure_roadmap" */
  update_pkie_measure_roadmap?: Maybe<PkieMeasureRoadmapMutationResponse>;
  /** update single row of the table: "pkie.measure_roadmap" */
  update_pkie_measure_roadmap_by_pk?: Maybe<PkieMeasureRoadmap>;
  /** update multiples rows of table: "pkie.measure_roadmap" */
  update_pkie_measure_roadmap_many?: Maybe<Array<Maybe<PkieMeasureRoadmapMutationResponse>>>;
  /** update data of the table: "pkie.measure_status" */
  update_pkie_measure_status?: Maybe<PkieMeasureStatusMutationResponse>;
  /** update single row of the table: "pkie.measure_status" */
  update_pkie_measure_status_by_pk?: Maybe<PkieMeasureStatus>;
  /** update multiples rows of table: "pkie.measure_status" */
  update_pkie_measure_status_many?: Maybe<Array<Maybe<PkieMeasureStatusMutationResponse>>>;
  /** update data of the table: "pkie.national_acts" */
  update_pkie_national_acts?: Maybe<PkieNationalActsMutationResponse>;
  /** update single row of the table: "pkie.national_acts" */
  update_pkie_national_acts_by_pk?: Maybe<PkieNationalActs>;
  /** update multiples rows of table: "pkie.national_acts" */
  update_pkie_national_acts_many?: Maybe<Array<Maybe<PkieNationalActsMutationResponse>>>;
  /** update data of the table: "pkie.proces_pune_iaga" */
  update_pkie_proces_pune_iaga?: Maybe<PkieProcesPuneIagaMutationResponse>;
  /** update single row of the table: "pkie.proces_pune_iaga" */
  update_pkie_proces_pune_iaga_by_pk?: Maybe<PkieProcesPuneIaga>;
  /** update multiples rows of table: "pkie.proces_pune_iaga" */
  update_pkie_proces_pune_iaga_many?: Maybe<Array<Maybe<PkieProcesPuneIagaMutationResponse>>>;
  /** update data of the table: "pkie.proces_pune_lga" */
  update_pkie_proces_pune_lga?: Maybe<PkieProcesPuneLgaMutationResponse>;
  /** update single row of the table: "pkie.proces_pune_lga" */
  update_pkie_proces_pune_lga_by_pk?: Maybe<PkieProcesPuneLga>;
  /** update multiples rows of table: "pkie.proces_pune_lga" */
  update_pkie_proces_pune_lga_many?: Maybe<Array<Maybe<PkieProcesPuneLgaMutationResponse>>>;
  /** update data of the table: "pkie.source_of_funding" */
  update_pkie_source_of_funding?: Maybe<PkieSourceOfFundingMutationResponse>;
  /** update single row of the table: "pkie.source_of_funding" */
  update_pkie_source_of_funding_by_pk?: Maybe<PkieSourceOfFunding>;
  /** update multiples rows of table: "pkie.source_of_funding" */
  update_pkie_source_of_funding_many?: Maybe<Array<Maybe<PkieSourceOfFundingMutationResponse>>>;
  /** update data of the table: "pkie.type_alignment_act" */
  update_pkie_type_alignment_act?: Maybe<PkieTypeAlignmentActMutationResponse>;
  /** update single row of the table: "pkie.type_alignment_act" */
  update_pkie_type_alignment_act_by_pk?: Maybe<PkieTypeAlignmentAct>;
  /** update multiples rows of table: "pkie.type_alignment_act" */
  update_pkie_type_alignment_act_many?: Maybe<Array<Maybe<PkieTypeAlignmentActMutationResponse>>>;
  /** update data of the table: "pkie.type_measure" */
  update_pkie_type_measure?: Maybe<PkieTypeMeasureMutationResponse>;
  /** update data of the table: "pkie.type_measure_budget" */
  update_pkie_type_measure_budget?: Maybe<PkieTypeMeasureBudgetMutationResponse>;
  /** update single row of the table: "pkie.type_measure_budget" */
  update_pkie_type_measure_budget_by_pk?: Maybe<PkieTypeMeasureBudget>;
  /** update multiples rows of table: "pkie.type_measure_budget" */
  update_pkie_type_measure_budget_many?: Maybe<Array<Maybe<PkieTypeMeasureBudgetMutationResponse>>>;
  /** update single row of the table: "pkie.type_measure" */
  update_pkie_type_measure_by_pk?: Maybe<PkieTypeMeasure>;
  /** update multiples rows of table: "pkie.type_measure" */
  update_pkie_type_measure_many?: Maybe<Array<Maybe<PkieTypeMeasureMutationResponse>>>;
  /** update data of the table: "pkie.work_plan_comment" */
  update_pkie_work_plan_comment?: Maybe<PkieWorkPlanCommentMutationResponse>;
  /** update single row of the table: "pkie.work_plan_comment" */
  update_pkie_work_plan_comment_by_pk?: Maybe<PkieWorkPlanComment>;
  /** update multiples rows of table: "pkie.work_plan_comment" */
  update_pkie_work_plan_comment_many?: Maybe<Array<Maybe<PkieWorkPlanCommentMutationResponse>>>;
  /** update data of the table: "pkie.work_plan_statuses" */
  update_pkie_work_plan_statuses?: Maybe<PkieWorkPlanStatusesMutationResponse>;
  /** update single row of the table: "pkie.work_plan_statuses" */
  update_pkie_work_plan_statuses_by_pk?: Maybe<PkieWorkPlanStatuses>;
  /** update multiples rows of table: "pkie.work_plan_statuses" */
  update_pkie_work_plan_statuses_many?: Maybe<Array<Maybe<PkieWorkPlanStatusesMutationResponse>>>;
  /** update data of the table: "pkie.work_plan_task" */
  update_pkie_work_plan_task?: Maybe<PkieWorkPlanTaskMutationResponse>;
  /** update data of the table: "pkie.work_plan_task_activity" */
  update_pkie_work_plan_task_activity?: Maybe<PkieWorkPlanTaskActivityMutationResponse>;
  /** update single row of the table: "pkie.work_plan_task_activity" */
  update_pkie_work_plan_task_activity_by_pk?: Maybe<PkieWorkPlanTaskActivity>;
  /** update multiples rows of table: "pkie.work_plan_task_activity" */
  update_pkie_work_plan_task_activity_many?: Maybe<Array<Maybe<PkieWorkPlanTaskActivityMutationResponse>>>;
  /** update single row of the table: "pkie.work_plan_task" */
  update_pkie_work_plan_task_by_pk?: Maybe<PkieWorkPlanTask>;
  /** update multiples rows of table: "pkie.work_plan_task" */
  update_pkie_work_plan_task_many?: Maybe<Array<Maybe<PkieWorkPlanTaskMutationResponse>>>;
  /** update data of the table: "pkie.work_plan_task_statuses" */
  update_pkie_work_plan_task_statuses?: Maybe<PkieWorkPlanTaskStatusesMutationResponse>;
  /** update single row of the table: "pkie.work_plan_task_statuses" */
  update_pkie_work_plan_task_statuses_by_pk?: Maybe<PkieWorkPlanTaskStatuses>;
  /** update multiples rows of table: "pkie.work_plan_task_statuses" */
  update_pkie_work_plan_task_statuses_many?: Maybe<Array<Maybe<PkieWorkPlanTaskStatusesMutationResponse>>>;
  /** update data of the table: "pkie.work_plans" */
  update_pkie_work_plans?: Maybe<PkieWorkPlansMutationResponse>;
  /** update single row of the table: "pkie.work_plans" */
  update_pkie_work_plans_by_pk?: Maybe<PkieWorkPlans>;
  /** update multiples rows of table: "pkie.work_plans" */
  update_pkie_work_plans_many?: Maybe<Array<Maybe<PkieWorkPlansMutationResponse>>>;
  /** update data of the table: "question_types" */
  update_question_types?: Maybe<QuestionTypesMutationResponse>;
  /** update single row of the table: "question_types" */
  update_question_types_by_pk?: Maybe<QuestionTypes>;
  /** update multiples rows of table: "question_types" */
  update_question_types_many?: Maybe<Array<Maybe<QuestionTypesMutationResponse>>>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<QuestionsMutationResponse>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<QuestionsMutationResponse>>>;
  /** update data of the table: "recommendations" */
  update_recommendations?: Maybe<RecommendationsMutationResponse>;
  /** update single row of the table: "recommendations" */
  update_recommendations_by_pk?: Maybe<Recommendations>;
  /** update multiples rows of table: "recommendations" */
  update_recommendations_many?: Maybe<Array<Maybe<RecommendationsMutationResponse>>>;
  /** update data of the table: "report_answers" */
  update_report_answers?: Maybe<ReportAnswersMutationResponse>;
  /** update single row of the table: "report_answers" */
  update_report_answers_by_pk?: Maybe<ReportAnswers>;
  /** update multiples rows of table: "report_answers" */
  update_report_answers_many?: Maybe<Array<Maybe<ReportAnswersMutationResponse>>>;
  /** update data of the table: "report_chapter_statuses" */
  update_report_chapter_statuses?: Maybe<ReportChapterStatusesMutationResponse>;
  /** update single row of the table: "report_chapter_statuses" */
  update_report_chapter_statuses_by_pk?: Maybe<ReportChapterStatuses>;
  /** update multiples rows of table: "report_chapter_statuses" */
  update_report_chapter_statuses_many?: Maybe<Array<Maybe<ReportChapterStatusesMutationResponse>>>;
  /** update data of the table: "report_chapters" */
  update_report_chapters?: Maybe<ReportChaptersMutationResponse>;
  /** update single row of the table: "report_chapters" */
  update_report_chapters_by_pk?: Maybe<ReportChapters>;
  /** update multiples rows of table: "report_chapters" */
  update_report_chapters_many?: Maybe<Array<Maybe<ReportChaptersMutationResponse>>>;
  /** update data of the table: "report_statuses" */
  update_report_statuses?: Maybe<ReportStatusesMutationResponse>;
  /** update single row of the table: "report_statuses" */
  update_report_statuses_by_pk?: Maybe<ReportStatuses>;
  /** update multiples rows of table: "report_statuses" */
  update_report_statuses_many?: Maybe<Array<Maybe<ReportStatusesMutationResponse>>>;
  /** update data of the table: "report_task_activity" */
  update_report_task_activity?: Maybe<ReportTaskActivityMutationResponse>;
  /** update single row of the table: "report_task_activity" */
  update_report_task_activity_by_pk?: Maybe<ReportTaskActivity>;
  /** update multiples rows of table: "report_task_activity" */
  update_report_task_activity_many?: Maybe<Array<Maybe<ReportTaskActivityMutationResponse>>>;
  /** update data of the table: "report_task_comment" */
  update_report_task_comment?: Maybe<ReportTaskCommentMutationResponse>;
  /** update single row of the table: "report_task_comment" */
  update_report_task_comment_by_pk?: Maybe<ReportTaskComment>;
  /** update multiples rows of table: "report_task_comment" */
  update_report_task_comment_many?: Maybe<Array<Maybe<ReportTaskCommentMutationResponse>>>;
  /** update data of the table: "report_task_gnpie_members" */
  update_report_task_gnpie_members?: Maybe<ReportTaskGnpieMembersMutationResponse>;
  /** update single row of the table: "report_task_gnpie_members" */
  update_report_task_gnpie_members_by_pk?: Maybe<ReportTaskGnpieMembers>;
  /** update multiples rows of table: "report_task_gnpie_members" */
  update_report_task_gnpie_members_many?: Maybe<Array<Maybe<ReportTaskGnpieMembersMutationResponse>>>;
  /** update data of the table: "report_task_statuses" */
  update_report_task_statuses?: Maybe<ReportTaskStatusesMutationResponse>;
  /** update single row of the table: "report_task_statuses" */
  update_report_task_statuses_by_pk?: Maybe<ReportTaskStatuses>;
  /** update multiples rows of table: "report_task_statuses" */
  update_report_task_statuses_many?: Maybe<Array<Maybe<ReportTaskStatusesMutationResponse>>>;
  /** update data of the table: "report_tasks" */
  update_report_tasks?: Maybe<ReportTasksMutationResponse>;
  /** update single row of the table: "report_tasks" */
  update_report_tasks_by_pk?: Maybe<ReportTasks>;
  /** update multiples rows of table: "report_tasks" */
  update_report_tasks_many?: Maybe<Array<Maybe<ReportTasksMutationResponse>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<ReportsMutationResponse>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<ReportsMutationResponse>>>;
  /** update data of the table: "roadmap_measure_type" */
  update_roadmap_measure_type?: Maybe<RoadmapMeasureTypeMutationResponse>;
  /** update single row of the table: "roadmap_measure_type" */
  update_roadmap_measure_type_by_pk?: Maybe<RoadmapMeasureType>;
  /** update multiples rows of table: "roadmap_measure_type" */
  update_roadmap_measure_type_many?: Maybe<Array<Maybe<RoadmapMeasureTypeMutationResponse>>>;
  /** update data of the table: "signed_documents" */
  update_signed_documents?: Maybe<SignedDocumentsMutationResponse>;
  /** update single row of the table: "signed_documents" */
  update_signed_documents_by_pk?: Maybe<SignedDocuments>;
  /** update multiples rows of table: "signed_documents" */
  update_signed_documents_many?: Maybe<Array<Maybe<SignedDocumentsMutationResponse>>>;
  /** update data of the table: "sub_chapter_lead_institutions" */
  update_sub_chapter_lead_institutions?: Maybe<SubChapterLeadInstitutionsMutationResponse>;
  /** update single row of the table: "sub_chapter_lead_institutions" */
  update_sub_chapter_lead_institutions_by_pk?: Maybe<SubChapterLeadInstitutions>;
  /** update multiples rows of table: "sub_chapter_lead_institutions" */
  update_sub_chapter_lead_institutions_many?: Maybe<Array<Maybe<SubChapterLeadInstitutionsMutationResponse>>>;
  /** update data of the table: "sub_chapters" */
  update_sub_chapters?: Maybe<SubChaptersMutationResponse>;
  /** update single row of the table: "sub_chapters" */
  update_sub_chapters_by_pk?: Maybe<SubChapters>;
  /** update multiples rows of table: "sub_chapters" */
  update_sub_chapters_many?: Maybe<Array<Maybe<SubChaptersMutationResponse>>>;
  /** update data of the table: "task" */
  update_task?: Maybe<TaskMutationResponse>;
  /** update data of the table: "task_activity" */
  update_task_activity?: Maybe<TaskActivityMutationResponse>;
  /** update single row of the table: "task_activity" */
  update_task_activity_by_pk?: Maybe<TaskActivity>;
  /** update multiples rows of table: "task_activity" */
  update_task_activity_many?: Maybe<Array<Maybe<TaskActivityMutationResponse>>>;
  /** update data of the table: "task_answers" */
  update_task_answers?: Maybe<TaskAnswersMutationResponse>;
  /** update single row of the table: "task_answers" */
  update_task_answers_by_pk?: Maybe<TaskAnswers>;
  /** update multiples rows of table: "task_answers" */
  update_task_answers_many?: Maybe<Array<Maybe<TaskAnswersMutationResponse>>>;
  /** update single row of the table: "task" */
  update_task_by_pk?: Maybe<Task>;
  /** update data of the table: "task_comments" */
  update_task_comments?: Maybe<TaskCommentsMutationResponse>;
  /** update single row of the table: "task_comments" */
  update_task_comments_by_pk?: Maybe<TaskComments>;
  /** update multiples rows of table: "task_comments" */
  update_task_comments_many?: Maybe<Array<Maybe<TaskCommentsMutationResponse>>>;
  /** update data of the table: "task_gnpie_members" */
  update_task_gnpie_members?: Maybe<TaskGnpieMembersMutationResponse>;
  /** update single row of the table: "task_gnpie_members" */
  update_task_gnpie_members_by_pk?: Maybe<TaskGnpieMembers>;
  /** update multiples rows of table: "task_gnpie_members" */
  update_task_gnpie_members_many?: Maybe<Array<Maybe<TaskGnpieMembersMutationResponse>>>;
  /** update multiples rows of table: "task" */
  update_task_many?: Maybe<Array<Maybe<TaskMutationResponse>>>;
  /** update data of the table: "task_statuses" */
  update_task_statuses?: Maybe<TaskStatusesMutationResponse>;
  /** update single row of the table: "task_statuses" */
  update_task_statuses_by_pk?: Maybe<TaskStatuses>;
  /** update multiples rows of table: "task_statuses" */
  update_task_statuses_many?: Maybe<Array<Maybe<TaskStatusesMutationResponse>>>;
  /** update data of the table: "task_types" */
  update_task_types?: Maybe<TaskTypesMutationResponse>;
  /** update single row of the table: "task_types" */
  update_task_types_by_pk?: Maybe<TaskTypes>;
  /** update multiples rows of table: "task_types" */
  update_task_types_many?: Maybe<Array<Maybe<TaskTypesMutationResponse>>>;
  /** update data of the table: "topics" */
  update_topics?: Maybe<TopicsMutationResponse>;
  /** update single row of the table: "topics" */
  update_topics_by_pk?: Maybe<Topics>;
  /** update multiples rows of table: "topics" */
  update_topics_many?: Maybe<Array<Maybe<TopicsMutationResponse>>>;
  /** update data of the table: "type_institutions" */
  update_type_institutions?: Maybe<TypeInstitutionsMutationResponse>;
  /** update single row of the table: "type_institutions" */
  update_type_institutions_by_pk?: Maybe<TypeInstitutions>;
  /** update multiples rows of table: "type_institutions" */
  update_type_institutions_many?: Maybe<Array<Maybe<TypeInstitutionsMutationResponse>>>;
  /** update multiples rows of table: "auth.users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
  /** update data of the table: "yearly_quarters" */
  update_yearly_quarters?: Maybe<YearlyQuartersMutationResponse>;
  /** update single row of the table: "yearly_quarters" */
  update_yearly_quarters_by_pk?: Maybe<YearlyQuarters>;
  /** update multiples rows of table: "yearly_quarters" */
  update_yearly_quarters_many?: Maybe<Array<Maybe<YearlyQuartersMutationResponse>>>;
}


/** mutation root */
export interface MutationRootUpdateDocumentByPkArgs {
  pk_columns: UpdateDocumentByPkDocumentsPkColumnsInput;
  set?: InputMaybe<UpdateDocumentByPkDocumentsSetInput>;
}


/** mutation root */
export interface MutationRootUpdateReportChaptersByPkArgs {
  pk_columns: UpdateReportChaptersByPkReportChaptersPkColumnsInput;
  set?: InputMaybe<UpdateReportChaptersByPkReportChaptersSetInput>;
}


/** mutation root */
export interface MutationRootCompleteReportSignitureArgs {
  obj: CompleteSignitureInput;
}


/** mutation root */
export interface MutationRootDeleteAuthProviderArgs {
  id: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthProviderRequestArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthProviderRequestsArgs {
  where: AuthProviderRequestsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthProvidersArgs {
  where: AuthProvidersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthRefreshTokenArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthRefreshTokenTypeArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthRefreshTokenTypesArgs {
  where: AuthRefreshTokenTypesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthRefreshTokensArgs {
  where: AuthRefreshTokensBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthRoleArgs {
  role: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthRolesArgs {
  where: AuthRolesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthUserProviderArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthUserProvidersArgs {
  where: AuthUserProvidersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthUserRoleArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthUserRolesArgs {
  where: AuthUserRolesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthUserSecurityKeyArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteAuthUserSecurityKeysArgs {
  where: AuthUserSecurityKeysBoolExp;
}


/** mutation root */
export interface MutationRootDeleteUserArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteUsersArgs {
  where: UsersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthModulesArgs {
  where: AuthModulesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAuthModulesByPkArgs {
  module: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkGnpieMembersArgs {
  where: BookmarkGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkStatusArgs {
  where: BookmarkStatusBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkStatusByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskActivityArgs {
  where: BookmarkTaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskAnswersArgs {
  where: BookmarkTaskAnswersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskCommentArgs {
  where: BookmarkTaskCommentBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskMeasuresArgs {
  where: BookmarkTaskMeasuresBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskMeasuresByPkArgs {
  measure: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskStatusesArgs {
  where: BookmarkTaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarkTasksArgs {
  where: BookmarkTasksBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarkTasksByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteBookmarksArgs {
  where: BookmarksBoolExp;
}


/** mutation root */
export interface MutationRootDeleteBookmarksByPkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacBeneficiariesArgs {
  where: CapacBeneficiariesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacBeneficiariesByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacChapterDonatorArgs {
  where: CapacChapterDonatorBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacChapterDonatorByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacDonatorsArgs {
  where: CapacDonatorsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacDonatorsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacExpertsArgs {
  where: CapacExpertsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacExpertsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacIntegrationStepsArgs {
  where: CapacIntegrationStepsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacIntegrationStepsByPkArgs {
  id: Scalars['numeric']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacPriorityListsArgs {
  where: CapacPriorityListsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacPriorityListsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacProjectBeneficiariesArgs {
  where: CapacProjectBeneficiariesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacProjectBeneficiariesByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacProjectsArgs {
  where: CapacProjectsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacProjectsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTaiexArgs {
  where: CapacTaiexBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTaiexByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingNeedsArgs {
  where: CapacTrainingNeedsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingNeedsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingParticipantsArgs {
  where: CapacTrainingParticipantsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingParticipantsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingSessionsArgs {
  where: CapacTrainingSessionsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingSessionsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingsArgs {
  where: CapacTrainingsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingsExpertsArgs {
  where: CapacTrainingsExpertsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTrainingsExpertsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteCapacTwinningArgs {
  where: CapacTwinningBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCapacTwinningByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteChapterLeadInstitutionsArgs {
  where: ChapterLeadInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteChapterLeadInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteChaptersArgs {
  where: ChaptersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteChaptersByPkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface MutationRootDeleteClustersArgs {
  where: ClustersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteClustersByPkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface MutationRootDeleteDmsFolderArgs {
  where: DmsFolderBoolExp;
}


/** mutation root */
export interface MutationRootDeleteDmsFolderByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteDmsFolderTypeArgs {
  where: DmsFolderTypeBoolExp;
}


/** mutation root */
export interface MutationRootDeleteDmsFolderTypeByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteDocumentsArgs {
  where: DocumentsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteDocumentsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteErrorLogsArgs {
  where: ErrorLogsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteErrorLogsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteEventNotesArgs {
  where: EventNotesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteEventNotesByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteEventTypologyArgs {
  where: EventTypologyBoolExp;
}


/** mutation root */
export interface MutationRootDeleteEventTypologyByPkArgs {
  typology: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteEventUsersArgs {
  where: EventUsersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteEventUsersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteEventsArgs {
  where: EventsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteEventsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteGnpieArgs {
  where: GnpieBoolExp;
}


/** mutation root */
export interface MutationRootDeleteGnpieByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteInstitutionsArgs {
  where: InstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteIntegrationPhasesArgs {
  where: IntegrationPhasesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteIntegrationPhasesByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteIntegrationPhasesEnumArgs {
  where: IntegrationPhasesEnumBoolExp;
}


/** mutation root */
export interface MutationRootDeleteIntegrationPhasesEnumByPkArgs {
  phase: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteMeetingAttendanceArgs {
  where: MeetingAttendanceBoolExp;
}


/** mutation root */
export interface MutationRootDeleteMeetingAttendanceByPkArgs {
  attendance: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteMeetingNotesArgs {
  where: MeetingNotesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteMeetingNotesByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteMeetingUsersArgs {
  where: MeetingUsersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteMeetingUsersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteMeetingsArgs {
  where: MeetingsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteMeetingsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteNotificationArgs {
  where: NotificationBoolExp;
}


/** mutation root */
export interface MutationRootDeleteNotificationByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteNotificationOriginArgs {
  where: NotificationOriginBoolExp;
}


/** mutation root */
export interface MutationRootDeleteNotificationOriginByPkArgs {
  origin: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteNotificationTypeArgs {
  where: NotificationTypeBoolExp;
}


/** mutation root */
export interface MutationRootDeleteNotificationTypeByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieAcquisArgs {
  where: PkieAcquisBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieAcquisByPkArgs {
  celex: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieAcquisChapterArgs {
  where: PkieAcquisChapterBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieAcquisChapterByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieAcquisInstitutionsArgs {
  where: PkieAcquisInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieAcquisInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieAcquisNationalActsArgs {
  where: PkieAcquisNationalActsBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieAcquisNationalActsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieAcquisTypeArgs {
  where: PkieAcquisTypeBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieAcquisTypeByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieAcquisWorkPlanTaskArgs {
  where: PkieAcquisWorkPlanTaskBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieAcquisWorkPlanTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieActTypeArgs {
  where: PkieActTypeBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieActTypeByPkArgs {
  code: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieLevelOfAlignmentArgs {
  where: PkieLevelOfAlignmentBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieLevelOfAlignmentByPkArgs {
  level: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureArgs {
  where: PkieMeasureBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureActivityArgs {
  where: PkieMeasureActivityBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureAnswersArgs {
  where: PkieMeasureAnswersBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureBookmarkArgs {
  where: PkieMeasureBookmarkBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureBookmarkByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureBudgetsArgs {
  where: PkieMeasureBudgetsBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureBudgetsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureCommentArgs {
  where: PkieMeasureCommentBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureGnpieMembersArgs {
  where: PkieMeasureGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureRoadmapArgs {
  where: PkieMeasureRoadmapBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureRoadmapByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieMeasureStatusArgs {
  where: PkieMeasureStatusBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieMeasureStatusByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieNationalActsArgs {
  where: PkieNationalActsBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieNationalActsByPkArgs {
  eli: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieProcesPuneIagaArgs {
  where: PkieProcesPuneIagaBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieProcesPuneIagaByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieProcesPuneLgaArgs {
  where: PkieProcesPuneLgaBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieProcesPuneLgaByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieSourceOfFundingArgs {
  where: PkieSourceOfFundingBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieSourceOfFundingByPkArgs {
  source: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieTypeAlignmentActArgs {
  where: PkieTypeAlignmentActBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieTypeAlignmentActByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieTypeMeasureArgs {
  where: PkieTypeMeasureBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieTypeMeasureBudgetArgs {
  where: PkieTypeMeasureBudgetBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieTypeMeasureBudgetByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieTypeMeasureByPkArgs {
  measure: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanCommentArgs {
  where: PkieWorkPlanCommentBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanStatusesArgs {
  where: PkieWorkPlanStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanTaskArgs {
  where: PkieWorkPlanTaskBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanTaskActivityArgs {
  where: PkieWorkPlanTaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanTaskStatusesArgs {
  where: PkieWorkPlanTaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlanTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlansArgs {
  where: PkieWorkPlansBoolExp;
}


/** mutation root */
export interface MutationRootDeletePkieWorkPlansByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteQuestionTypesArgs {
  where: QuestionTypesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteQuestionTypesByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteQuestionsArgs {
  where: QuestionsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteQuestionsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteRecommendationsArgs {
  where: RecommendationsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteRecommendationsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportAnswersArgs {
  where: ReportAnswersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportChapterStatusesArgs {
  where: ReportChapterStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportChapterStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportChaptersArgs {
  where: ReportChaptersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportChaptersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportStatusesArgs {
  where: ReportStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportTaskActivityArgs {
  where: ReportTaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportTaskCommentArgs {
  where: ReportTaskCommentBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportTaskCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportTaskGnpieMembersArgs {
  where: ReportTaskGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportTaskGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportTaskStatusesArgs {
  where: ReportTaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportTasksArgs {
  where: ReportTasksBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportTasksByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteReportsArgs {
  where: ReportsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteReportsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteRoadmapMeasureTypeArgs {
  where: RoadmapMeasureTypeBoolExp;
}


/** mutation root */
export interface MutationRootDeleteRoadmapMeasureTypeByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteSignedDocumentsArgs {
  where: SignedDocumentsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteSignedDocumentsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteSubChapterLeadInstitutionsArgs {
  where: SubChapterLeadInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteSubChapterLeadInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteSubChaptersArgs {
  where: SubChaptersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteSubChaptersByPkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskArgs {
  where: TaskBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskActivityArgs {
  where: TaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskAnswersArgs {
  where: TaskAnswersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskCommentsArgs {
  where: TaskCommentsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskCommentsByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskGnpieMembersArgs {
  where: TaskGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskStatusesArgs {
  where: TaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteTaskTypesArgs {
  where: TaskTypesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTaskTypesByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteTopicsArgs {
  where: TopicsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTopicsByPkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface MutationRootDeleteTypeInstitutionsArgs {
  where: TypeInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteTypeInstitutionsByPkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootDeleteYearlyQuartersArgs {
  where: YearlyQuartersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteYearlyQuartersByPkArgs {
  quarter: Scalars['String']['input'];
}


/** mutation root */
export interface MutationRootInsertAcquisArgs {
  input: InsertAcquisPkieAcquisInsertInput;
}


/** mutation root */
export interface MutationRootInsertAcquisWorkPlanArgs {
  obj: CreateAcquisWorkPlanPkieWorkPlansInsertInput;
}


/** mutation root */
export interface MutationRootInsertAuthProviderArgs {
  object: AuthProvidersInsertInput;
  on_conflict?: InputMaybe<AuthProvidersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthProviderRequestArgs {
  object: AuthProviderRequestsInsertInput;
  on_conflict?: InputMaybe<AuthProviderRequestsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthProviderRequestsArgs {
  objects: Array<AuthProviderRequestsInsertInput>;
  on_conflict?: InputMaybe<AuthProviderRequestsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthProvidersArgs {
  objects: Array<AuthProvidersInsertInput>;
  on_conflict?: InputMaybe<AuthProvidersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthRefreshTokenArgs {
  object: AuthRefreshTokensInsertInput;
  on_conflict?: InputMaybe<AuthRefreshTokensOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthRefreshTokenTypeArgs {
  object: AuthRefreshTokenTypesInsertInput;
  on_conflict?: InputMaybe<AuthRefreshTokenTypesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthRefreshTokenTypesArgs {
  objects: Array<AuthRefreshTokenTypesInsertInput>;
  on_conflict?: InputMaybe<AuthRefreshTokenTypesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthRefreshTokensArgs {
  objects: Array<AuthRefreshTokensInsertInput>;
  on_conflict?: InputMaybe<AuthRefreshTokensOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthRoleArgs {
  object: AuthRolesInsertInput;
  on_conflict?: InputMaybe<AuthRolesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthRolesArgs {
  objects: Array<AuthRolesInsertInput>;
  on_conflict?: InputMaybe<AuthRolesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthUserProviderArgs {
  object: AuthUserProvidersInsertInput;
  on_conflict?: InputMaybe<AuthUserProvidersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthUserProvidersArgs {
  objects: Array<AuthUserProvidersInsertInput>;
  on_conflict?: InputMaybe<AuthUserProvidersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthUserRoleArgs {
  object: AuthUserRolesInsertInput;
  on_conflict?: InputMaybe<AuthUserRolesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthUserRolesArgs {
  objects: Array<AuthUserRolesInsertInput>;
  on_conflict?: InputMaybe<AuthUserRolesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthUserSecurityKeyArgs {
  object: AuthUserSecurityKeysInsertInput;
  on_conflict?: InputMaybe<AuthUserSecurityKeysOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthUserSecurityKeysArgs {
  objects: Array<AuthUserSecurityKeysInsertInput>;
  on_conflict?: InputMaybe<AuthUserSecurityKeysOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDocumentArgs {
  obj: InsertDocumentDocumentsInsertInput;
}


/** mutation root */
export interface MutationRootInsertFolderArgs {
  obj: InsertFolderDmsFolderInsertInput;
}


/** mutation root */
export interface MutationRootInsertProgressReportArgs {
  report: InsertProgressReportReportsInsertInput;
}


/** mutation root */
export interface MutationRootInsertUserArgs {
  object: UsersInsertInput;
  on_conflict?: InputMaybe<UsersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertUsersArgs {
  objects: Array<UsersInsertInput>;
  on_conflict?: InputMaybe<UsersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthModulesArgs {
  objects: Array<AuthModulesInsertInput>;
  on_conflict?: InputMaybe<AuthModulesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAuthModulesOneArgs {
  object: AuthModulesInsertInput;
  on_conflict?: InputMaybe<AuthModulesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkGnpieMembersArgs {
  objects: Array<BookmarkGnpieMembersInsertInput>;
  on_conflict?: InputMaybe<BookmarkGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkGnpieMembersOneArgs {
  object: BookmarkGnpieMembersInsertInput;
  on_conflict?: InputMaybe<BookmarkGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkStatusArgs {
  objects: Array<BookmarkStatusInsertInput>;
  on_conflict?: InputMaybe<BookmarkStatusOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkStatusOneArgs {
  object: BookmarkStatusInsertInput;
  on_conflict?: InputMaybe<BookmarkStatusOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskActivityArgs {
  objects: Array<BookmarkTaskActivityInsertInput>;
  on_conflict?: InputMaybe<BookmarkTaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskActivityOneArgs {
  object: BookmarkTaskActivityInsertInput;
  on_conflict?: InputMaybe<BookmarkTaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskAnswersArgs {
  objects: Array<BookmarkTaskAnswersInsertInput>;
  on_conflict?: InputMaybe<BookmarkTaskAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskAnswersOneArgs {
  object: BookmarkTaskAnswersInsertInput;
  on_conflict?: InputMaybe<BookmarkTaskAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskCommentArgs {
  objects: Array<BookmarkTaskCommentInsertInput>;
  on_conflict?: InputMaybe<BookmarkTaskCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskCommentOneArgs {
  object: BookmarkTaskCommentInsertInput;
  on_conflict?: InputMaybe<BookmarkTaskCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskMeasuresArgs {
  objects: Array<BookmarkTaskMeasuresInsertInput>;
  on_conflict?: InputMaybe<BookmarkTaskMeasuresOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskMeasuresOneArgs {
  object: BookmarkTaskMeasuresInsertInput;
  on_conflict?: InputMaybe<BookmarkTaskMeasuresOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskStatusesArgs {
  objects: Array<BookmarkTaskStatusesInsertInput>;
  on_conflict?: InputMaybe<BookmarkTaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTaskStatusesOneArgs {
  object: BookmarkTaskStatusesInsertInput;
  on_conflict?: InputMaybe<BookmarkTaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTasksArgs {
  objects: Array<BookmarkTasksInsertInput>;
  on_conflict?: InputMaybe<BookmarkTasksOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarkTasksOneArgs {
  object: BookmarkTasksInsertInput;
  on_conflict?: InputMaybe<BookmarkTasksOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarksArgs {
  objects: Array<BookmarksInsertInput>;
  on_conflict?: InputMaybe<BookmarksOnConflict>;
}


/** mutation root */
export interface MutationRootInsertBookmarksOneArgs {
  object: BookmarksInsertInput;
  on_conflict?: InputMaybe<BookmarksOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacBeneficiariesArgs {
  objects: Array<CapacBeneficiariesInsertInput>;
  on_conflict?: InputMaybe<CapacBeneficiariesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacBeneficiariesOneArgs {
  object: CapacBeneficiariesInsertInput;
  on_conflict?: InputMaybe<CapacBeneficiariesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacChapterDonatorArgs {
  objects: Array<CapacChapterDonatorInsertInput>;
  on_conflict?: InputMaybe<CapacChapterDonatorOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacChapterDonatorOneArgs {
  object: CapacChapterDonatorInsertInput;
  on_conflict?: InputMaybe<CapacChapterDonatorOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacDonatorsArgs {
  objects: Array<CapacDonatorsInsertInput>;
  on_conflict?: InputMaybe<CapacDonatorsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacDonatorsOneArgs {
  object: CapacDonatorsInsertInput;
  on_conflict?: InputMaybe<CapacDonatorsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacExpertsArgs {
  objects: Array<CapacExpertsInsertInput>;
  on_conflict?: InputMaybe<CapacExpertsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacExpertsOneArgs {
  object: CapacExpertsInsertInput;
  on_conflict?: InputMaybe<CapacExpertsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacIntegrationStepsArgs {
  objects: Array<CapacIntegrationStepsInsertInput>;
  on_conflict?: InputMaybe<CapacIntegrationStepsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacIntegrationStepsOneArgs {
  object: CapacIntegrationStepsInsertInput;
  on_conflict?: InputMaybe<CapacIntegrationStepsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacPriorityListsArgs {
  objects: Array<CapacPriorityListsInsertInput>;
  on_conflict?: InputMaybe<CapacPriorityListsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacPriorityListsOneArgs {
  object: CapacPriorityListsInsertInput;
  on_conflict?: InputMaybe<CapacPriorityListsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacProjectBeneficiariesArgs {
  objects: Array<CapacProjectBeneficiariesInsertInput>;
  on_conflict?: InputMaybe<CapacProjectBeneficiariesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacProjectBeneficiariesOneArgs {
  object: CapacProjectBeneficiariesInsertInput;
  on_conflict?: InputMaybe<CapacProjectBeneficiariesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacProjectsArgs {
  objects: Array<CapacProjectsInsertInput>;
  on_conflict?: InputMaybe<CapacProjectsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacProjectsOneArgs {
  object: CapacProjectsInsertInput;
  on_conflict?: InputMaybe<CapacProjectsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTaiexArgs {
  objects: Array<CapacTaiexInsertInput>;
  on_conflict?: InputMaybe<CapacTaiexOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTaiexOneArgs {
  object: CapacTaiexInsertInput;
  on_conflict?: InputMaybe<CapacTaiexOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingNeedsArgs {
  objects: Array<CapacTrainingNeedsInsertInput>;
  on_conflict?: InputMaybe<CapacTrainingNeedsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingNeedsOneArgs {
  object: CapacTrainingNeedsInsertInput;
  on_conflict?: InputMaybe<CapacTrainingNeedsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingParticipantsArgs {
  objects: Array<CapacTrainingParticipantsInsertInput>;
  on_conflict?: InputMaybe<CapacTrainingParticipantsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingParticipantsOneArgs {
  object: CapacTrainingParticipantsInsertInput;
  on_conflict?: InputMaybe<CapacTrainingParticipantsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingSessionsArgs {
  objects: Array<CapacTrainingSessionsInsertInput>;
  on_conflict?: InputMaybe<CapacTrainingSessionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingSessionsOneArgs {
  object: CapacTrainingSessionsInsertInput;
  on_conflict?: InputMaybe<CapacTrainingSessionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingsArgs {
  objects: Array<CapacTrainingsInsertInput>;
  on_conflict?: InputMaybe<CapacTrainingsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingsExpertsArgs {
  objects: Array<CapacTrainingsExpertsInsertInput>;
  on_conflict?: InputMaybe<CapacTrainingsExpertsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingsExpertsOneArgs {
  object: CapacTrainingsExpertsInsertInput;
  on_conflict?: InputMaybe<CapacTrainingsExpertsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTrainingsOneArgs {
  object: CapacTrainingsInsertInput;
  on_conflict?: InputMaybe<CapacTrainingsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTwinningArgs {
  objects: Array<CapacTwinningInsertInput>;
  on_conflict?: InputMaybe<CapacTwinningOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCapacTwinningOneArgs {
  object: CapacTwinningInsertInput;
  on_conflict?: InputMaybe<CapacTwinningOnConflict>;
}


/** mutation root */
export interface MutationRootInsertChapterLeadInstitutionsArgs {
  objects: Array<ChapterLeadInstitutionsInsertInput>;
  on_conflict?: InputMaybe<ChapterLeadInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertChapterLeadInstitutionsOneArgs {
  object: ChapterLeadInstitutionsInsertInput;
  on_conflict?: InputMaybe<ChapterLeadInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertChaptersArgs {
  objects: Array<ChaptersInsertInput>;
  on_conflict?: InputMaybe<ChaptersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertChaptersOneArgs {
  object: ChaptersInsertInput;
  on_conflict?: InputMaybe<ChaptersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertClustersArgs {
  objects: Array<ClustersInsertInput>;
  on_conflict?: InputMaybe<ClustersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertClustersOneArgs {
  object: ClustersInsertInput;
  on_conflict?: InputMaybe<ClustersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDmsFolderArgs {
  objects: Array<DmsFolderInsertInput>;
  on_conflict?: InputMaybe<DmsFolderOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDmsFolderOneArgs {
  object: DmsFolderInsertInput;
  on_conflict?: InputMaybe<DmsFolderOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDmsFolderTypeArgs {
  objects: Array<DmsFolderTypeInsertInput>;
  on_conflict?: InputMaybe<DmsFolderTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDmsFolderTypeOneArgs {
  object: DmsFolderTypeInsertInput;
  on_conflict?: InputMaybe<DmsFolderTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDocumentsArgs {
  objects: Array<DocumentsInsertInput>;
  on_conflict?: InputMaybe<DocumentsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertDocumentsOneArgs {
  object: DocumentsInsertInput;
  on_conflict?: InputMaybe<DocumentsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertErrorLogsArgs {
  objects: Array<ErrorLogsInsertInput>;
  on_conflict?: InputMaybe<ErrorLogsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertErrorLogsOneArgs {
  object: ErrorLogsInsertInput;
  on_conflict?: InputMaybe<ErrorLogsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventNotesArgs {
  objects: Array<EventNotesInsertInput>;
  on_conflict?: InputMaybe<EventNotesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventNotesOneArgs {
  object: EventNotesInsertInput;
  on_conflict?: InputMaybe<EventNotesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventTypologyArgs {
  objects: Array<EventTypologyInsertInput>;
  on_conflict?: InputMaybe<EventTypologyOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventTypologyOneArgs {
  object: EventTypologyInsertInput;
  on_conflict?: InputMaybe<EventTypologyOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventUsersArgs {
  objects: Array<EventUsersInsertInput>;
  on_conflict?: InputMaybe<EventUsersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventUsersOneArgs {
  object: EventUsersInsertInput;
  on_conflict?: InputMaybe<EventUsersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventsArgs {
  objects: Array<EventsInsertInput>;
  on_conflict?: InputMaybe<EventsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventsOneArgs {
  object: EventsInsertInput;
  on_conflict?: InputMaybe<EventsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGnpieArgs {
  objects: Array<GnpieInsertInput>;
  on_conflict?: InputMaybe<GnpieOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGnpieOneArgs {
  object: GnpieInsertInput;
  on_conflict?: InputMaybe<GnpieOnConflict>;
}


/** mutation root */
export interface MutationRootInsertInstitutionsArgs {
  objects: Array<InstitutionsInsertInput>;
  on_conflict?: InputMaybe<InstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertInstitutionsOneArgs {
  object: InstitutionsInsertInput;
  on_conflict?: InputMaybe<InstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertIntegrationPhasesArgs {
  objects: Array<IntegrationPhasesInsertInput>;
  on_conflict?: InputMaybe<IntegrationPhasesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertIntegrationPhasesEnumArgs {
  objects: Array<IntegrationPhasesEnumInsertInput>;
  on_conflict?: InputMaybe<IntegrationPhasesEnumOnConflict>;
}


/** mutation root */
export interface MutationRootInsertIntegrationPhasesEnumOneArgs {
  object: IntegrationPhasesEnumInsertInput;
  on_conflict?: InputMaybe<IntegrationPhasesEnumOnConflict>;
}


/** mutation root */
export interface MutationRootInsertIntegrationPhasesOneArgs {
  object: IntegrationPhasesInsertInput;
  on_conflict?: InputMaybe<IntegrationPhasesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingAttendanceArgs {
  objects: Array<MeetingAttendanceInsertInput>;
  on_conflict?: InputMaybe<MeetingAttendanceOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingAttendanceOneArgs {
  object: MeetingAttendanceInsertInput;
  on_conflict?: InputMaybe<MeetingAttendanceOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingNotesArgs {
  objects: Array<MeetingNotesInsertInput>;
  on_conflict?: InputMaybe<MeetingNotesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingNotesOneArgs {
  object: MeetingNotesInsertInput;
  on_conflict?: InputMaybe<MeetingNotesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingUsersArgs {
  objects: Array<MeetingUsersInsertInput>;
  on_conflict?: InputMaybe<MeetingUsersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingUsersOneArgs {
  object: MeetingUsersInsertInput;
  on_conflict?: InputMaybe<MeetingUsersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingsArgs {
  objects: Array<MeetingsInsertInput>;
  on_conflict?: InputMaybe<MeetingsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertMeetingsOneArgs {
  object: MeetingsInsertInput;
  on_conflict?: InputMaybe<MeetingsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertNotificationArgs {
  objects: Array<NotificationInsertInput>;
  on_conflict?: InputMaybe<NotificationOnConflict>;
}


/** mutation root */
export interface MutationRootInsertNotificationOneArgs {
  object: NotificationInsertInput;
  on_conflict?: InputMaybe<NotificationOnConflict>;
}


/** mutation root */
export interface MutationRootInsertNotificationOriginArgs {
  objects: Array<NotificationOriginInsertInput>;
  on_conflict?: InputMaybe<NotificationOriginOnConflict>;
}


/** mutation root */
export interface MutationRootInsertNotificationOriginOneArgs {
  object: NotificationOriginInsertInput;
  on_conflict?: InputMaybe<NotificationOriginOnConflict>;
}


/** mutation root */
export interface MutationRootInsertNotificationTypeArgs {
  objects: Array<NotificationTypeInsertInput>;
  on_conflict?: InputMaybe<NotificationTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertNotificationTypeOneArgs {
  object: NotificationTypeInsertInput;
  on_conflict?: InputMaybe<NotificationTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisArgs {
  objects: Array<PkieAcquisInsertInput>;
  on_conflict?: InputMaybe<PkieAcquisOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisChapterArgs {
  objects: Array<PkieAcquisChapterInsertInput>;
  on_conflict?: InputMaybe<PkieAcquisChapterOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisChapterOneArgs {
  object: PkieAcquisChapterInsertInput;
  on_conflict?: InputMaybe<PkieAcquisChapterOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisInstitutionsArgs {
  objects: Array<PkieAcquisInstitutionsInsertInput>;
  on_conflict?: InputMaybe<PkieAcquisInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisInstitutionsOneArgs {
  object: PkieAcquisInstitutionsInsertInput;
  on_conflict?: InputMaybe<PkieAcquisInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisNationalActsArgs {
  objects: Array<PkieAcquisNationalActsInsertInput>;
  on_conflict?: InputMaybe<PkieAcquisNationalActsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisNationalActsOneArgs {
  object: PkieAcquisNationalActsInsertInput;
  on_conflict?: InputMaybe<PkieAcquisNationalActsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisOneArgs {
  object: PkieAcquisInsertInput;
  on_conflict?: InputMaybe<PkieAcquisOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisTypeArgs {
  objects: Array<PkieAcquisTypeInsertInput>;
  on_conflict?: InputMaybe<PkieAcquisTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisTypeOneArgs {
  object: PkieAcquisTypeInsertInput;
  on_conflict?: InputMaybe<PkieAcquisTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisWorkPlanTaskArgs {
  objects: Array<PkieAcquisWorkPlanTaskInsertInput>;
  on_conflict?: InputMaybe<PkieAcquisWorkPlanTaskOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieAcquisWorkPlanTaskOneArgs {
  object: PkieAcquisWorkPlanTaskInsertInput;
  on_conflict?: InputMaybe<PkieAcquisWorkPlanTaskOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieActTypeArgs {
  objects: Array<PkieActTypeInsertInput>;
  on_conflict?: InputMaybe<PkieActTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieActTypeOneArgs {
  object: PkieActTypeInsertInput;
  on_conflict?: InputMaybe<PkieActTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieLevelOfAlignmentArgs {
  objects: Array<PkieLevelOfAlignmentInsertInput>;
  on_conflict?: InputMaybe<PkieLevelOfAlignmentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieLevelOfAlignmentOneArgs {
  object: PkieLevelOfAlignmentInsertInput;
  on_conflict?: InputMaybe<PkieLevelOfAlignmentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureArgs {
  objects: Array<PkieMeasureInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureActivityArgs {
  objects: Array<PkieMeasureActivityInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureActivityOneArgs {
  object: PkieMeasureActivityInsertInput;
  on_conflict?: InputMaybe<PkieMeasureActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureAnswersArgs {
  objects: Array<PkieMeasureAnswersInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureAnswersOneArgs {
  object: PkieMeasureAnswersInsertInput;
  on_conflict?: InputMaybe<PkieMeasureAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureBookmarkArgs {
  objects: Array<PkieMeasureBookmarkInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureBookmarkOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureBookmarkOneArgs {
  object: PkieMeasureBookmarkInsertInput;
  on_conflict?: InputMaybe<PkieMeasureBookmarkOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureBudgetsArgs {
  objects: Array<PkieMeasureBudgetsInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureBudgetsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureBudgetsOneArgs {
  object: PkieMeasureBudgetsInsertInput;
  on_conflict?: InputMaybe<PkieMeasureBudgetsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureCommentArgs {
  objects: Array<PkieMeasureCommentInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureCommentOneArgs {
  object: PkieMeasureCommentInsertInput;
  on_conflict?: InputMaybe<PkieMeasureCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureGnpieMembersArgs {
  objects: Array<PkieMeasureGnpieMembersInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureGnpieMembersOneArgs {
  object: PkieMeasureGnpieMembersInsertInput;
  on_conflict?: InputMaybe<PkieMeasureGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureOneArgs {
  object: PkieMeasureInsertInput;
  on_conflict?: InputMaybe<PkieMeasureOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureRoadmapArgs {
  objects: Array<PkieMeasureRoadmapInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureRoadmapOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureRoadmapOneArgs {
  object: PkieMeasureRoadmapInsertInput;
  on_conflict?: InputMaybe<PkieMeasureRoadmapOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureStatusArgs {
  objects: Array<PkieMeasureStatusInsertInput>;
  on_conflict?: InputMaybe<PkieMeasureStatusOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieMeasureStatusOneArgs {
  object: PkieMeasureStatusInsertInput;
  on_conflict?: InputMaybe<PkieMeasureStatusOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieNationalActsArgs {
  objects: Array<PkieNationalActsInsertInput>;
  on_conflict?: InputMaybe<PkieNationalActsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieNationalActsOneArgs {
  object: PkieNationalActsInsertInput;
  on_conflict?: InputMaybe<PkieNationalActsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieProcesPuneIagaArgs {
  objects: Array<PkieProcesPuneIagaInsertInput>;
  on_conflict?: InputMaybe<PkieProcesPuneIagaOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieProcesPuneIagaOneArgs {
  object: PkieProcesPuneIagaInsertInput;
  on_conflict?: InputMaybe<PkieProcesPuneIagaOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieProcesPuneLgaArgs {
  objects: Array<PkieProcesPuneLgaInsertInput>;
  on_conflict?: InputMaybe<PkieProcesPuneLgaOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieProcesPuneLgaOneArgs {
  object: PkieProcesPuneLgaInsertInput;
  on_conflict?: InputMaybe<PkieProcesPuneLgaOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieSourceOfFundingArgs {
  objects: Array<PkieSourceOfFundingInsertInput>;
  on_conflict?: InputMaybe<PkieSourceOfFundingOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieSourceOfFundingOneArgs {
  object: PkieSourceOfFundingInsertInput;
  on_conflict?: InputMaybe<PkieSourceOfFundingOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieTypeAlignmentActArgs {
  objects: Array<PkieTypeAlignmentActInsertInput>;
  on_conflict?: InputMaybe<PkieTypeAlignmentActOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieTypeAlignmentActOneArgs {
  object: PkieTypeAlignmentActInsertInput;
  on_conflict?: InputMaybe<PkieTypeAlignmentActOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieTypeMeasureArgs {
  objects: Array<PkieTypeMeasureInsertInput>;
  on_conflict?: InputMaybe<PkieTypeMeasureOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieTypeMeasureBudgetArgs {
  objects: Array<PkieTypeMeasureBudgetInsertInput>;
  on_conflict?: InputMaybe<PkieTypeMeasureBudgetOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieTypeMeasureBudgetOneArgs {
  object: PkieTypeMeasureBudgetInsertInput;
  on_conflict?: InputMaybe<PkieTypeMeasureBudgetOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieTypeMeasureOneArgs {
  object: PkieTypeMeasureInsertInput;
  on_conflict?: InputMaybe<PkieTypeMeasureOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanCommentArgs {
  objects: Array<PkieWorkPlanCommentInsertInput>;
  on_conflict?: InputMaybe<PkieWorkPlanCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanCommentOneArgs {
  object: PkieWorkPlanCommentInsertInput;
  on_conflict?: InputMaybe<PkieWorkPlanCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanStatusesArgs {
  objects: Array<PkieWorkPlanStatusesInsertInput>;
  on_conflict?: InputMaybe<PkieWorkPlanStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanStatusesOneArgs {
  object: PkieWorkPlanStatusesInsertInput;
  on_conflict?: InputMaybe<PkieWorkPlanStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanTaskArgs {
  objects: Array<PkieWorkPlanTaskInsertInput>;
  on_conflict?: InputMaybe<PkieWorkPlanTaskOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanTaskActivityArgs {
  objects: Array<PkieWorkPlanTaskActivityInsertInput>;
  on_conflict?: InputMaybe<PkieWorkPlanTaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanTaskActivityOneArgs {
  object: PkieWorkPlanTaskActivityInsertInput;
  on_conflict?: InputMaybe<PkieWorkPlanTaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanTaskOneArgs {
  object: PkieWorkPlanTaskInsertInput;
  on_conflict?: InputMaybe<PkieWorkPlanTaskOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanTaskStatusesArgs {
  objects: Array<PkieWorkPlanTaskStatusesInsertInput>;
  on_conflict?: InputMaybe<PkieWorkPlanTaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlanTaskStatusesOneArgs {
  object: PkieWorkPlanTaskStatusesInsertInput;
  on_conflict?: InputMaybe<PkieWorkPlanTaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlansArgs {
  objects: Array<PkieWorkPlansInsertInput>;
  on_conflict?: InputMaybe<PkieWorkPlansOnConflict>;
}


/** mutation root */
export interface MutationRootInsertPkieWorkPlansOneArgs {
  object: PkieWorkPlansInsertInput;
  on_conflict?: InputMaybe<PkieWorkPlansOnConflict>;
}


/** mutation root */
export interface MutationRootInsertQuestionTypesArgs {
  objects: Array<QuestionTypesInsertInput>;
  on_conflict?: InputMaybe<QuestionTypesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertQuestionTypesOneArgs {
  object: QuestionTypesInsertInput;
  on_conflict?: InputMaybe<QuestionTypesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertQuestionsArgs {
  objects: Array<QuestionsInsertInput>;
  on_conflict?: InputMaybe<QuestionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertQuestionsOneArgs {
  object: QuestionsInsertInput;
  on_conflict?: InputMaybe<QuestionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertRecommendationsArgs {
  objects: Array<RecommendationsInsertInput>;
  on_conflict?: InputMaybe<RecommendationsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertRecommendationsOneArgs {
  object: RecommendationsInsertInput;
  on_conflict?: InputMaybe<RecommendationsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportAnswersArgs {
  objects: Array<ReportAnswersInsertInput>;
  on_conflict?: InputMaybe<ReportAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportAnswersOneArgs {
  object: ReportAnswersInsertInput;
  on_conflict?: InputMaybe<ReportAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportChapterStatusesArgs {
  objects: Array<ReportChapterStatusesInsertInput>;
  on_conflict?: InputMaybe<ReportChapterStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportChapterStatusesOneArgs {
  object: ReportChapterStatusesInsertInput;
  on_conflict?: InputMaybe<ReportChapterStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportChaptersArgs {
  objects: Array<ReportChaptersInsertInput>;
  on_conflict?: InputMaybe<ReportChaptersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportChaptersOneArgs {
  object: ReportChaptersInsertInput;
  on_conflict?: InputMaybe<ReportChaptersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportStatusesArgs {
  objects: Array<ReportStatusesInsertInput>;
  on_conflict?: InputMaybe<ReportStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportStatusesOneArgs {
  object: ReportStatusesInsertInput;
  on_conflict?: InputMaybe<ReportStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskActivityArgs {
  objects: Array<ReportTaskActivityInsertInput>;
  on_conflict?: InputMaybe<ReportTaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskActivityOneArgs {
  object: ReportTaskActivityInsertInput;
  on_conflict?: InputMaybe<ReportTaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskCommentArgs {
  objects: Array<ReportTaskCommentInsertInput>;
  on_conflict?: InputMaybe<ReportTaskCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskCommentOneArgs {
  object: ReportTaskCommentInsertInput;
  on_conflict?: InputMaybe<ReportTaskCommentOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskGnpieMembersArgs {
  objects: Array<ReportTaskGnpieMembersInsertInput>;
  on_conflict?: InputMaybe<ReportTaskGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskGnpieMembersOneArgs {
  object: ReportTaskGnpieMembersInsertInput;
  on_conflict?: InputMaybe<ReportTaskGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskStatusesArgs {
  objects: Array<ReportTaskStatusesInsertInput>;
  on_conflict?: InputMaybe<ReportTaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTaskStatusesOneArgs {
  object: ReportTaskStatusesInsertInput;
  on_conflict?: InputMaybe<ReportTaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTasksArgs {
  objects: Array<ReportTasksInsertInput>;
  on_conflict?: InputMaybe<ReportTasksOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportTasksOneArgs {
  object: ReportTasksInsertInput;
  on_conflict?: InputMaybe<ReportTasksOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportsArgs {
  objects: Array<ReportsInsertInput>;
  on_conflict?: InputMaybe<ReportsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertReportsOneArgs {
  object: ReportsInsertInput;
  on_conflict?: InputMaybe<ReportsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertRoadmapMeasureTypeArgs {
  objects: Array<RoadmapMeasureTypeInsertInput>;
  on_conflict?: InputMaybe<RoadmapMeasureTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertRoadmapMeasureTypeOneArgs {
  object: RoadmapMeasureTypeInsertInput;
  on_conflict?: InputMaybe<RoadmapMeasureTypeOnConflict>;
}


/** mutation root */
export interface MutationRootInsertSignedDocumentsArgs {
  objects: Array<SignedDocumentsInsertInput>;
  on_conflict?: InputMaybe<SignedDocumentsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertSignedDocumentsOneArgs {
  object: SignedDocumentsInsertInput;
  on_conflict?: InputMaybe<SignedDocumentsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertSubChapterLeadInstitutionsArgs {
  objects: Array<SubChapterLeadInstitutionsInsertInput>;
  on_conflict?: InputMaybe<SubChapterLeadInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertSubChapterLeadInstitutionsOneArgs {
  object: SubChapterLeadInstitutionsInsertInput;
  on_conflict?: InputMaybe<SubChapterLeadInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertSubChaptersArgs {
  objects: Array<SubChaptersInsertInput>;
  on_conflict?: InputMaybe<SubChaptersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertSubChaptersOneArgs {
  object: SubChaptersInsertInput;
  on_conflict?: InputMaybe<SubChaptersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskArgs {
  objects: Array<TaskInsertInput>;
  on_conflict?: InputMaybe<TaskOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskActivityArgs {
  objects: Array<TaskActivityInsertInput>;
  on_conflict?: InputMaybe<TaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskActivityOneArgs {
  object: TaskActivityInsertInput;
  on_conflict?: InputMaybe<TaskActivityOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskAnswersArgs {
  objects: Array<TaskAnswersInsertInput>;
  on_conflict?: InputMaybe<TaskAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskAnswersOneArgs {
  object: TaskAnswersInsertInput;
  on_conflict?: InputMaybe<TaskAnswersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskCommentsArgs {
  objects: Array<TaskCommentsInsertInput>;
  on_conflict?: InputMaybe<TaskCommentsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskCommentsOneArgs {
  object: TaskCommentsInsertInput;
  on_conflict?: InputMaybe<TaskCommentsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskGnpieMembersArgs {
  objects: Array<TaskGnpieMembersInsertInput>;
  on_conflict?: InputMaybe<TaskGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskGnpieMembersOneArgs {
  object: TaskGnpieMembersInsertInput;
  on_conflict?: InputMaybe<TaskGnpieMembersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskOneArgs {
  object: TaskInsertInput;
  on_conflict?: InputMaybe<TaskOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskStatusesArgs {
  objects: Array<TaskStatusesInsertInput>;
  on_conflict?: InputMaybe<TaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskStatusesOneArgs {
  object: TaskStatusesInsertInput;
  on_conflict?: InputMaybe<TaskStatusesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskTypesArgs {
  objects: Array<TaskTypesInsertInput>;
  on_conflict?: InputMaybe<TaskTypesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTaskTypesOneArgs {
  object: TaskTypesInsertInput;
  on_conflict?: InputMaybe<TaskTypesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTopicsArgs {
  objects: Array<TopicsInsertInput>;
  on_conflict?: InputMaybe<TopicsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTopicsOneArgs {
  object: TopicsInsertInput;
  on_conflict?: InputMaybe<TopicsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTypeInstitutionsArgs {
  objects: Array<TypeInstitutionsInsertInput>;
  on_conflict?: InputMaybe<TypeInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertTypeInstitutionsOneArgs {
  object: TypeInstitutionsInsertInput;
  on_conflict?: InputMaybe<TypeInstitutionsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertYearlyQuartersArgs {
  objects: Array<YearlyQuartersInsertInput>;
  on_conflict?: InputMaybe<YearlyQuartersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertYearlyQuartersOneArgs {
  object: YearlyQuartersInsertInput;
  on_conflict?: InputMaybe<YearlyQuartersOnConflict>;
}


/** mutation root */
export interface MutationRootUpdateAuthProviderArgs {
  _set?: InputMaybe<AuthProvidersSetInput>;
  pk_columns: AuthProvidersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthProviderRequestArgs {
  _append?: InputMaybe<AuthProviderRequestsAppendInput>;
  _delete_at_path?: InputMaybe<AuthProviderRequestsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthProviderRequestsDeleteElemInput>;
  _delete_key?: InputMaybe<AuthProviderRequestsDeleteKeyInput>;
  _prepend?: InputMaybe<AuthProviderRequestsPrependInput>;
  _set?: InputMaybe<AuthProviderRequestsSetInput>;
  pk_columns: AuthProviderRequestsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthProviderRequestsArgs {
  _append?: InputMaybe<AuthProviderRequestsAppendInput>;
  _delete_at_path?: InputMaybe<AuthProviderRequestsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthProviderRequestsDeleteElemInput>;
  _delete_key?: InputMaybe<AuthProviderRequestsDeleteKeyInput>;
  _prepend?: InputMaybe<AuthProviderRequestsPrependInput>;
  _set?: InputMaybe<AuthProviderRequestsSetInput>;
  where: AuthProviderRequestsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthProvidersArgs {
  _set?: InputMaybe<AuthProvidersSetInput>;
  where: AuthProvidersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthRefreshTokenArgs {
  _append?: InputMaybe<AuthRefreshTokensAppendInput>;
  _delete_at_path?: InputMaybe<AuthRefreshTokensDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthRefreshTokensDeleteElemInput>;
  _delete_key?: InputMaybe<AuthRefreshTokensDeleteKeyInput>;
  _prepend?: InputMaybe<AuthRefreshTokensPrependInput>;
  _set?: InputMaybe<AuthRefreshTokensSetInput>;
  pk_columns: AuthRefreshTokensPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthRefreshTokenTypeArgs {
  _set?: InputMaybe<AuthRefreshTokenTypesSetInput>;
  pk_columns: AuthRefreshTokenTypesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthRefreshTokenTypesArgs {
  _set?: InputMaybe<AuthRefreshTokenTypesSetInput>;
  where: AuthRefreshTokenTypesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthRefreshTokensArgs {
  _append?: InputMaybe<AuthRefreshTokensAppendInput>;
  _delete_at_path?: InputMaybe<AuthRefreshTokensDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthRefreshTokensDeleteElemInput>;
  _delete_key?: InputMaybe<AuthRefreshTokensDeleteKeyInput>;
  _prepend?: InputMaybe<AuthRefreshTokensPrependInput>;
  _set?: InputMaybe<AuthRefreshTokensSetInput>;
  where: AuthRefreshTokensBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthRoleArgs {
  _set?: InputMaybe<AuthRolesSetInput>;
  pk_columns: AuthRolesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthRolesArgs {
  _set?: InputMaybe<AuthRolesSetInput>;
  where: AuthRolesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthUserProviderArgs {
  _set?: InputMaybe<AuthUserProvidersSetInput>;
  pk_columns: AuthUserProvidersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthUserProvidersArgs {
  _set?: InputMaybe<AuthUserProvidersSetInput>;
  where: AuthUserProvidersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthUserRoleArgs {
  _set?: InputMaybe<AuthUserRolesSetInput>;
  pk_columns: AuthUserRolesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthUserRolesArgs {
  _set?: InputMaybe<AuthUserRolesSetInput>;
  where: AuthUserRolesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthUserSecurityKeyArgs {
  _inc?: InputMaybe<AuthUserSecurityKeysIncInput>;
  _set?: InputMaybe<AuthUserSecurityKeysSetInput>;
  pk_columns: AuthUserSecurityKeysPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthUserSecurityKeysArgs {
  _inc?: InputMaybe<AuthUserSecurityKeysIncInput>;
  _set?: InputMaybe<AuthUserSecurityKeysSetInput>;
  where: AuthUserSecurityKeysBoolExp;
}


/** mutation root */
export interface MutationRootUpdateFolderArgs {
  pk: UpdateFolderDmsFolderPkColumnsInput;
  set?: InputMaybe<UpdateFolderDmsFolderSetInput>;
}


/** mutation root */
export interface MutationRootUpdateUserArgs {
  _append?: InputMaybe<UsersAppendInput>;
  _delete_at_path?: InputMaybe<UsersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UsersDeleteElemInput>;
  _delete_key?: InputMaybe<UsersDeleteKeyInput>;
  _inc?: InputMaybe<UsersIncInput>;
  _prepend?: InputMaybe<UsersPrependInput>;
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateUsersArgs {
  _append?: InputMaybe<UsersAppendInput>;
  _delete_at_path?: InputMaybe<UsersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UsersDeleteElemInput>;
  _delete_key?: InputMaybe<UsersDeleteKeyInput>;
  _inc?: InputMaybe<UsersIncInput>;
  _prepend?: InputMaybe<UsersPrependInput>;
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthProviderRequestsManyArgs {
  updates: Array<AuthProviderRequestsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthProvidersManyArgs {
  updates: Array<AuthProvidersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthRefreshTokenTypesManyArgs {
  updates: Array<AuthRefreshTokenTypesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthRefreshTokensManyArgs {
  updates: Array<AuthRefreshTokensUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthRolesManyArgs {
  updates: Array<AuthRolesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthUserProvidersManyArgs {
  updates: Array<AuthUserProvidersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthUserRolesManyArgs {
  updates: Array<AuthUserRolesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthUserSecurityKeysManyArgs {
  updates: Array<AuthUserSecurityKeysUpdates>;
}


/** mutation root */
export interface MutationRootUpdateAuthModulesArgs {
  _set?: InputMaybe<AuthModulesSetInput>;
  where: AuthModulesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAuthModulesByPkArgs {
  _set?: InputMaybe<AuthModulesSetInput>;
  pk_columns: AuthModulesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateAuthModulesManyArgs {
  updates: Array<AuthModulesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkGnpieMembersArgs {
  _set?: InputMaybe<BookmarkGnpieMembersSetInput>;
  where: BookmarkGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkGnpieMembersByPkArgs {
  _set?: InputMaybe<BookmarkGnpieMembersSetInput>;
  pk_columns: BookmarkGnpieMembersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkGnpieMembersManyArgs {
  updates: Array<BookmarkGnpieMembersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkStatusArgs {
  _set?: InputMaybe<BookmarkStatusSetInput>;
  where: BookmarkStatusBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkStatusByPkArgs {
  _set?: InputMaybe<BookmarkStatusSetInput>;
  pk_columns: BookmarkStatusPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkStatusManyArgs {
  updates: Array<BookmarkStatusUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskActivityArgs {
  _set?: InputMaybe<BookmarkTaskActivitySetInput>;
  where: BookmarkTaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskActivityByPkArgs {
  _set?: InputMaybe<BookmarkTaskActivitySetInput>;
  pk_columns: BookmarkTaskActivityPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskActivityManyArgs {
  updates: Array<BookmarkTaskActivityUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskAnswersArgs {
  _append?: InputMaybe<BookmarkTaskAnswersAppendInput>;
  _delete_at_path?: InputMaybe<BookmarkTaskAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<BookmarkTaskAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<BookmarkTaskAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<BookmarkTaskAnswersPrependInput>;
  _set?: InputMaybe<BookmarkTaskAnswersSetInput>;
  where: BookmarkTaskAnswersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskAnswersByPkArgs {
  _append?: InputMaybe<BookmarkTaskAnswersAppendInput>;
  _delete_at_path?: InputMaybe<BookmarkTaskAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<BookmarkTaskAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<BookmarkTaskAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<BookmarkTaskAnswersPrependInput>;
  _set?: InputMaybe<BookmarkTaskAnswersSetInput>;
  pk_columns: BookmarkTaskAnswersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskAnswersManyArgs {
  updates: Array<BookmarkTaskAnswersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskCommentArgs {
  _set?: InputMaybe<BookmarkTaskCommentSetInput>;
  where: BookmarkTaskCommentBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskCommentByPkArgs {
  _set?: InputMaybe<BookmarkTaskCommentSetInput>;
  pk_columns: BookmarkTaskCommentPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskCommentManyArgs {
  updates: Array<BookmarkTaskCommentUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskMeasuresArgs {
  _set?: InputMaybe<BookmarkTaskMeasuresSetInput>;
  where: BookmarkTaskMeasuresBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskMeasuresByPkArgs {
  _set?: InputMaybe<BookmarkTaskMeasuresSetInput>;
  pk_columns: BookmarkTaskMeasuresPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskMeasuresManyArgs {
  updates: Array<BookmarkTaskMeasuresUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskStatusesArgs {
  _set?: InputMaybe<BookmarkTaskStatusesSetInput>;
  where: BookmarkTaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskStatusesByPkArgs {
  _set?: InputMaybe<BookmarkTaskStatusesSetInput>;
  pk_columns: BookmarkTaskStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTaskStatusesManyArgs {
  updates: Array<BookmarkTaskStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTasksArgs {
  _append?: InputMaybe<BookmarkTasksAppendInput>;
  _delete_at_path?: InputMaybe<BookmarkTasksDeleteAtPathInput>;
  _delete_elem?: InputMaybe<BookmarkTasksDeleteElemInput>;
  _delete_key?: InputMaybe<BookmarkTasksDeleteKeyInput>;
  _inc?: InputMaybe<BookmarkTasksIncInput>;
  _prepend?: InputMaybe<BookmarkTasksPrependInput>;
  _set?: InputMaybe<BookmarkTasksSetInput>;
  where: BookmarkTasksBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTasksByPkArgs {
  _append?: InputMaybe<BookmarkTasksAppendInput>;
  _delete_at_path?: InputMaybe<BookmarkTasksDeleteAtPathInput>;
  _delete_elem?: InputMaybe<BookmarkTasksDeleteElemInput>;
  _delete_key?: InputMaybe<BookmarkTasksDeleteKeyInput>;
  _inc?: InputMaybe<BookmarkTasksIncInput>;
  _prepend?: InputMaybe<BookmarkTasksPrependInput>;
  _set?: InputMaybe<BookmarkTasksSetInput>;
  pk_columns: BookmarkTasksPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarkTasksManyArgs {
  updates: Array<BookmarkTasksUpdates>;
}


/** mutation root */
export interface MutationRootUpdateBookmarksArgs {
  _inc?: InputMaybe<BookmarksIncInput>;
  _set?: InputMaybe<BookmarksSetInput>;
  where: BookmarksBoolExp;
}


/** mutation root */
export interface MutationRootUpdateBookmarksByPkArgs {
  _inc?: InputMaybe<BookmarksIncInput>;
  _set?: InputMaybe<BookmarksSetInput>;
  pk_columns: BookmarksPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateBookmarksManyArgs {
  updates: Array<BookmarksUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacBeneficiariesArgs {
  _set?: InputMaybe<CapacBeneficiariesSetInput>;
  where: CapacBeneficiariesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacBeneficiariesByPkArgs {
  _set?: InputMaybe<CapacBeneficiariesSetInput>;
  pk_columns: CapacBeneficiariesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacBeneficiariesManyArgs {
  updates: Array<CapacBeneficiariesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacChapterDonatorArgs {
  _inc?: InputMaybe<CapacChapterDonatorIncInput>;
  _set?: InputMaybe<CapacChapterDonatorSetInput>;
  where: CapacChapterDonatorBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacChapterDonatorByPkArgs {
  _inc?: InputMaybe<CapacChapterDonatorIncInput>;
  _set?: InputMaybe<CapacChapterDonatorSetInput>;
  pk_columns: CapacChapterDonatorPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacChapterDonatorManyArgs {
  updates: Array<CapacChapterDonatorUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacDonatorsArgs {
  _set?: InputMaybe<CapacDonatorsSetInput>;
  where: CapacDonatorsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacDonatorsByPkArgs {
  _set?: InputMaybe<CapacDonatorsSetInput>;
  pk_columns: CapacDonatorsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacDonatorsManyArgs {
  updates: Array<CapacDonatorsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacExpertsArgs {
  _set?: InputMaybe<CapacExpertsSetInput>;
  where: CapacExpertsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacExpertsByPkArgs {
  _set?: InputMaybe<CapacExpertsSetInput>;
  pk_columns: CapacExpertsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacExpertsManyArgs {
  updates: Array<CapacExpertsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacIntegrationStepsArgs {
  _inc?: InputMaybe<CapacIntegrationStepsIncInput>;
  _set?: InputMaybe<CapacIntegrationStepsSetInput>;
  where: CapacIntegrationStepsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacIntegrationStepsByPkArgs {
  _inc?: InputMaybe<CapacIntegrationStepsIncInput>;
  _set?: InputMaybe<CapacIntegrationStepsSetInput>;
  pk_columns: CapacIntegrationStepsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacIntegrationStepsManyArgs {
  updates: Array<CapacIntegrationStepsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacPriorityListsArgs {
  _set?: InputMaybe<CapacPriorityListsSetInput>;
  where: CapacPriorityListsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacPriorityListsByPkArgs {
  _set?: InputMaybe<CapacPriorityListsSetInput>;
  pk_columns: CapacPriorityListsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacPriorityListsManyArgs {
  updates: Array<CapacPriorityListsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacProjectBeneficiariesArgs {
  _set?: InputMaybe<CapacProjectBeneficiariesSetInput>;
  where: CapacProjectBeneficiariesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacProjectBeneficiariesByPkArgs {
  _set?: InputMaybe<CapacProjectBeneficiariesSetInput>;
  pk_columns: CapacProjectBeneficiariesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacProjectBeneficiariesManyArgs {
  updates: Array<CapacProjectBeneficiariesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacProjectsArgs {
  _inc?: InputMaybe<CapacProjectsIncInput>;
  _set?: InputMaybe<CapacProjectsSetInput>;
  where: CapacProjectsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacProjectsByPkArgs {
  _inc?: InputMaybe<CapacProjectsIncInput>;
  _set?: InputMaybe<CapacProjectsSetInput>;
  pk_columns: CapacProjectsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacProjectsManyArgs {
  updates: Array<CapacProjectsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTaiexArgs {
  _set?: InputMaybe<CapacTaiexSetInput>;
  where: CapacTaiexBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTaiexByPkArgs {
  _set?: InputMaybe<CapacTaiexSetInput>;
  pk_columns: CapacTaiexPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTaiexManyArgs {
  updates: Array<CapacTaiexUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingNeedsArgs {
  _append?: InputMaybe<CapacTrainingNeedsAppendInput>;
  _delete_at_path?: InputMaybe<CapacTrainingNeedsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CapacTrainingNeedsDeleteElemInput>;
  _delete_key?: InputMaybe<CapacTrainingNeedsDeleteKeyInput>;
  _inc?: InputMaybe<CapacTrainingNeedsIncInput>;
  _prepend?: InputMaybe<CapacTrainingNeedsPrependInput>;
  _set?: InputMaybe<CapacTrainingNeedsSetInput>;
  where: CapacTrainingNeedsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingNeedsByPkArgs {
  _append?: InputMaybe<CapacTrainingNeedsAppendInput>;
  _delete_at_path?: InputMaybe<CapacTrainingNeedsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CapacTrainingNeedsDeleteElemInput>;
  _delete_key?: InputMaybe<CapacTrainingNeedsDeleteKeyInput>;
  _inc?: InputMaybe<CapacTrainingNeedsIncInput>;
  _prepend?: InputMaybe<CapacTrainingNeedsPrependInput>;
  _set?: InputMaybe<CapacTrainingNeedsSetInput>;
  pk_columns: CapacTrainingNeedsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingNeedsManyArgs {
  updates: Array<CapacTrainingNeedsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingParticipantsArgs {
  _set?: InputMaybe<CapacTrainingParticipantsSetInput>;
  where: CapacTrainingParticipantsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingParticipantsByPkArgs {
  _set?: InputMaybe<CapacTrainingParticipantsSetInput>;
  pk_columns: CapacTrainingParticipantsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingParticipantsManyArgs {
  updates: Array<CapacTrainingParticipantsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingSessionsArgs {
  _inc?: InputMaybe<CapacTrainingSessionsIncInput>;
  _set?: InputMaybe<CapacTrainingSessionsSetInput>;
  where: CapacTrainingSessionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingSessionsByPkArgs {
  _inc?: InputMaybe<CapacTrainingSessionsIncInput>;
  _set?: InputMaybe<CapacTrainingSessionsSetInput>;
  pk_columns: CapacTrainingSessionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingSessionsManyArgs {
  updates: Array<CapacTrainingSessionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingsArgs {
  _set?: InputMaybe<CapacTrainingsSetInput>;
  where: CapacTrainingsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingsByPkArgs {
  _set?: InputMaybe<CapacTrainingsSetInput>;
  pk_columns: CapacTrainingsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingsExpertsArgs {
  _set?: InputMaybe<CapacTrainingsExpertsSetInput>;
  where: CapacTrainingsExpertsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingsExpertsByPkArgs {
  _set?: InputMaybe<CapacTrainingsExpertsSetInput>;
  pk_columns: CapacTrainingsExpertsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingsExpertsManyArgs {
  updates: Array<CapacTrainingsExpertsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTrainingsManyArgs {
  updates: Array<CapacTrainingsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateCapacTwinningArgs {
  _set?: InputMaybe<CapacTwinningSetInput>;
  where: CapacTwinningBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCapacTwinningByPkArgs {
  _set?: InputMaybe<CapacTwinningSetInput>;
  pk_columns: CapacTwinningPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCapacTwinningManyArgs {
  updates: Array<CapacTwinningUpdates>;
}


/** mutation root */
export interface MutationRootUpdateChapterLeadInstitutionsArgs {
  _inc?: InputMaybe<ChapterLeadInstitutionsIncInput>;
  _set?: InputMaybe<ChapterLeadInstitutionsSetInput>;
  where: ChapterLeadInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateChapterLeadInstitutionsByPkArgs {
  _inc?: InputMaybe<ChapterLeadInstitutionsIncInput>;
  _set?: InputMaybe<ChapterLeadInstitutionsSetInput>;
  pk_columns: ChapterLeadInstitutionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateChapterLeadInstitutionsManyArgs {
  updates: Array<ChapterLeadInstitutionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateChaptersArgs {
  _inc?: InputMaybe<ChaptersIncInput>;
  _set?: InputMaybe<ChaptersSetInput>;
  where: ChaptersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateChaptersByPkArgs {
  _inc?: InputMaybe<ChaptersIncInput>;
  _set?: InputMaybe<ChaptersSetInput>;
  pk_columns: ChaptersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateChaptersManyArgs {
  updates: Array<ChaptersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateClustersArgs {
  _inc?: InputMaybe<ClustersIncInput>;
  _set?: InputMaybe<ClustersSetInput>;
  where: ClustersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateClustersByPkArgs {
  _inc?: InputMaybe<ClustersIncInput>;
  _set?: InputMaybe<ClustersSetInput>;
  pk_columns: ClustersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateClustersManyArgs {
  updates: Array<ClustersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateDmsFolderArgs {
  _append?: InputMaybe<DmsFolderAppendInput>;
  _delete_at_path?: InputMaybe<DmsFolderDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DmsFolderDeleteElemInput>;
  _delete_key?: InputMaybe<DmsFolderDeleteKeyInput>;
  _inc?: InputMaybe<DmsFolderIncInput>;
  _prepend?: InputMaybe<DmsFolderPrependInput>;
  _set?: InputMaybe<DmsFolderSetInput>;
  where: DmsFolderBoolExp;
}


/** mutation root */
export interface MutationRootUpdateDmsFolderByPkArgs {
  _append?: InputMaybe<DmsFolderAppendInput>;
  _delete_at_path?: InputMaybe<DmsFolderDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DmsFolderDeleteElemInput>;
  _delete_key?: InputMaybe<DmsFolderDeleteKeyInput>;
  _inc?: InputMaybe<DmsFolderIncInput>;
  _prepend?: InputMaybe<DmsFolderPrependInput>;
  _set?: InputMaybe<DmsFolderSetInput>;
  pk_columns: DmsFolderPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateDmsFolderManyArgs {
  updates: Array<DmsFolderUpdates>;
}


/** mutation root */
export interface MutationRootUpdateDmsFolderTypeArgs {
  _set?: InputMaybe<DmsFolderTypeSetInput>;
  where: DmsFolderTypeBoolExp;
}


/** mutation root */
export interface MutationRootUpdateDmsFolderTypeByPkArgs {
  _set?: InputMaybe<DmsFolderTypeSetInput>;
  pk_columns: DmsFolderTypePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateDmsFolderTypeManyArgs {
  updates: Array<DmsFolderTypeUpdates>;
}


/** mutation root */
export interface MutationRootUpdateDocumentsArgs {
  _append?: InputMaybe<DocumentsAppendInput>;
  _delete_at_path?: InputMaybe<DocumentsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentsDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentsDeleteKeyInput>;
  _inc?: InputMaybe<DocumentsIncInput>;
  _prepend?: InputMaybe<DocumentsPrependInput>;
  _set?: InputMaybe<DocumentsSetInput>;
  where: DocumentsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateDocumentsByPkArgs {
  _append?: InputMaybe<DocumentsAppendInput>;
  _delete_at_path?: InputMaybe<DocumentsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentsDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentsDeleteKeyInput>;
  _inc?: InputMaybe<DocumentsIncInput>;
  _prepend?: InputMaybe<DocumentsPrependInput>;
  _set?: InputMaybe<DocumentsSetInput>;
  pk_columns: DocumentsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateDocumentsManyArgs {
  updates: Array<DocumentsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateErrorLogsArgs {
  _set?: InputMaybe<ErrorLogsSetInput>;
  where: ErrorLogsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateErrorLogsByPkArgs {
  _set?: InputMaybe<ErrorLogsSetInput>;
  pk_columns: ErrorLogsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateErrorLogsManyArgs {
  updates: Array<ErrorLogsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateEventNotesArgs {
  _set?: InputMaybe<EventNotesSetInput>;
  where: EventNotesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateEventNotesByPkArgs {
  _set?: InputMaybe<EventNotesSetInput>;
  pk_columns: EventNotesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateEventNotesManyArgs {
  updates: Array<EventNotesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateEventTypologyArgs {
  _set?: InputMaybe<EventTypologySetInput>;
  where: EventTypologyBoolExp;
}


/** mutation root */
export interface MutationRootUpdateEventTypologyByPkArgs {
  _set?: InputMaybe<EventTypologySetInput>;
  pk_columns: EventTypologyPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateEventTypologyManyArgs {
  updates: Array<EventTypologyUpdates>;
}


/** mutation root */
export interface MutationRootUpdateEventUsersArgs {
  _set?: InputMaybe<EventUsersSetInput>;
  where: EventUsersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateEventUsersByPkArgs {
  _set?: InputMaybe<EventUsersSetInput>;
  pk_columns: EventUsersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateEventUsersManyArgs {
  updates: Array<EventUsersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateEventsArgs {
  _append?: InputMaybe<EventsAppendInput>;
  _delete_at_path?: InputMaybe<EventsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventsDeleteElemInput>;
  _delete_key?: InputMaybe<EventsDeleteKeyInput>;
  _inc?: InputMaybe<EventsIncInput>;
  _prepend?: InputMaybe<EventsPrependInput>;
  _set?: InputMaybe<EventsSetInput>;
  where: EventsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateEventsByPkArgs {
  _append?: InputMaybe<EventsAppendInput>;
  _delete_at_path?: InputMaybe<EventsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EventsDeleteElemInput>;
  _delete_key?: InputMaybe<EventsDeleteKeyInput>;
  _inc?: InputMaybe<EventsIncInput>;
  _prepend?: InputMaybe<EventsPrependInput>;
  _set?: InputMaybe<EventsSetInput>;
  pk_columns: EventsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateEventsManyArgs {
  updates: Array<EventsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateGnpieArgs {
  _inc?: InputMaybe<GnpieIncInput>;
  _set?: InputMaybe<GnpieSetInput>;
  where: GnpieBoolExp;
}


/** mutation root */
export interface MutationRootUpdateGnpieByPkArgs {
  _inc?: InputMaybe<GnpieIncInput>;
  _set?: InputMaybe<GnpieSetInput>;
  pk_columns: GnpiePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateGnpieManyArgs {
  updates: Array<GnpieUpdates>;
}


/** mutation root */
export interface MutationRootUpdateInstitutionsArgs {
  _inc?: InputMaybe<InstitutionsIncInput>;
  _set?: InputMaybe<InstitutionsSetInput>;
  where: InstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateInstitutionsByPkArgs {
  _inc?: InputMaybe<InstitutionsIncInput>;
  _set?: InputMaybe<InstitutionsSetInput>;
  pk_columns: InstitutionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateInstitutionsManyArgs {
  updates: Array<InstitutionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateIntegrationPhasesArgs {
  _inc?: InputMaybe<IntegrationPhasesIncInput>;
  _set?: InputMaybe<IntegrationPhasesSetInput>;
  where: IntegrationPhasesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateIntegrationPhasesByPkArgs {
  _inc?: InputMaybe<IntegrationPhasesIncInput>;
  _set?: InputMaybe<IntegrationPhasesSetInput>;
  pk_columns: IntegrationPhasesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateIntegrationPhasesEnumArgs {
  _set?: InputMaybe<IntegrationPhasesEnumSetInput>;
  where: IntegrationPhasesEnumBoolExp;
}


/** mutation root */
export interface MutationRootUpdateIntegrationPhasesEnumByPkArgs {
  _set?: InputMaybe<IntegrationPhasesEnumSetInput>;
  pk_columns: IntegrationPhasesEnumPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateIntegrationPhasesEnumManyArgs {
  updates: Array<IntegrationPhasesEnumUpdates>;
}


/** mutation root */
export interface MutationRootUpdateIntegrationPhasesManyArgs {
  updates: Array<IntegrationPhasesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateMeetingAttendanceArgs {
  _set?: InputMaybe<MeetingAttendanceSetInput>;
  where: MeetingAttendanceBoolExp;
}


/** mutation root */
export interface MutationRootUpdateMeetingAttendanceByPkArgs {
  _set?: InputMaybe<MeetingAttendanceSetInput>;
  pk_columns: MeetingAttendancePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateMeetingAttendanceManyArgs {
  updates: Array<MeetingAttendanceUpdates>;
}


/** mutation root */
export interface MutationRootUpdateMeetingNotesArgs {
  _set?: InputMaybe<MeetingNotesSetInput>;
  where: MeetingNotesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateMeetingNotesByPkArgs {
  _set?: InputMaybe<MeetingNotesSetInput>;
  pk_columns: MeetingNotesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateMeetingNotesManyArgs {
  updates: Array<MeetingNotesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateMeetingUsersArgs {
  _set?: InputMaybe<MeetingUsersSetInput>;
  where: MeetingUsersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateMeetingUsersByPkArgs {
  _set?: InputMaybe<MeetingUsersSetInput>;
  pk_columns: MeetingUsersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateMeetingUsersManyArgs {
  updates: Array<MeetingUsersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateMeetingsArgs {
  _append?: InputMaybe<MeetingsAppendInput>;
  _delete_at_path?: InputMaybe<MeetingsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MeetingsDeleteElemInput>;
  _delete_key?: InputMaybe<MeetingsDeleteKeyInput>;
  _inc?: InputMaybe<MeetingsIncInput>;
  _prepend?: InputMaybe<MeetingsPrependInput>;
  _set?: InputMaybe<MeetingsSetInput>;
  where: MeetingsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateMeetingsByPkArgs {
  _append?: InputMaybe<MeetingsAppendInput>;
  _delete_at_path?: InputMaybe<MeetingsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MeetingsDeleteElemInput>;
  _delete_key?: InputMaybe<MeetingsDeleteKeyInput>;
  _inc?: InputMaybe<MeetingsIncInput>;
  _prepend?: InputMaybe<MeetingsPrependInput>;
  _set?: InputMaybe<MeetingsSetInput>;
  pk_columns: MeetingsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateMeetingsManyArgs {
  updates: Array<MeetingsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateNotificationArgs {
  _inc?: InputMaybe<NotificationIncInput>;
  _set?: InputMaybe<NotificationSetInput>;
  where: NotificationBoolExp;
}


/** mutation root */
export interface MutationRootUpdateNotificationByPkArgs {
  _inc?: InputMaybe<NotificationIncInput>;
  _set?: InputMaybe<NotificationSetInput>;
  pk_columns: NotificationPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateNotificationManyArgs {
  updates: Array<NotificationUpdates>;
}


/** mutation root */
export interface MutationRootUpdateNotificationOriginArgs {
  _set?: InputMaybe<NotificationOriginSetInput>;
  where: NotificationOriginBoolExp;
}


/** mutation root */
export interface MutationRootUpdateNotificationOriginByPkArgs {
  _set?: InputMaybe<NotificationOriginSetInput>;
  pk_columns: NotificationOriginPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateNotificationOriginManyArgs {
  updates: Array<NotificationOriginUpdates>;
}


/** mutation root */
export interface MutationRootUpdateNotificationTypeArgs {
  _set?: InputMaybe<NotificationTypeSetInput>;
  where: NotificationTypeBoolExp;
}


/** mutation root */
export interface MutationRootUpdateNotificationTypeByPkArgs {
  _set?: InputMaybe<NotificationTypeSetInput>;
  pk_columns: NotificationTypePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateNotificationTypeManyArgs {
  updates: Array<NotificationTypeUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisArgs {
  _inc?: InputMaybe<PkieAcquisIncInput>;
  _set?: InputMaybe<PkieAcquisSetInput>;
  where: PkieAcquisBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisByPkArgs {
  _inc?: InputMaybe<PkieAcquisIncInput>;
  _set?: InputMaybe<PkieAcquisSetInput>;
  pk_columns: PkieAcquisPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisChapterArgs {
  _inc?: InputMaybe<PkieAcquisChapterIncInput>;
  _set?: InputMaybe<PkieAcquisChapterSetInput>;
  where: PkieAcquisChapterBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisChapterByPkArgs {
  _inc?: InputMaybe<PkieAcquisChapterIncInput>;
  _set?: InputMaybe<PkieAcquisChapterSetInput>;
  pk_columns: PkieAcquisChapterPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisChapterManyArgs {
  updates: Array<PkieAcquisChapterUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisInstitutionsArgs {
  _set?: InputMaybe<PkieAcquisInstitutionsSetInput>;
  where: PkieAcquisInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisInstitutionsByPkArgs {
  _set?: InputMaybe<PkieAcquisInstitutionsSetInput>;
  pk_columns: PkieAcquisInstitutionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisInstitutionsManyArgs {
  updates: Array<PkieAcquisInstitutionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisManyArgs {
  updates: Array<PkieAcquisUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisNationalActsArgs {
  _set?: InputMaybe<PkieAcquisNationalActsSetInput>;
  where: PkieAcquisNationalActsBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisNationalActsByPkArgs {
  _set?: InputMaybe<PkieAcquisNationalActsSetInput>;
  pk_columns: PkieAcquisNationalActsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisNationalActsManyArgs {
  updates: Array<PkieAcquisNationalActsUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisTypeArgs {
  _set?: InputMaybe<PkieAcquisTypeSetInput>;
  where: PkieAcquisTypeBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisTypeByPkArgs {
  _set?: InputMaybe<PkieAcquisTypeSetInput>;
  pk_columns: PkieAcquisTypePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisTypeManyArgs {
  updates: Array<PkieAcquisTypeUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisWorkPlanTaskArgs {
  _set?: InputMaybe<PkieAcquisWorkPlanTaskSetInput>;
  where: PkieAcquisWorkPlanTaskBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisWorkPlanTaskByPkArgs {
  _set?: InputMaybe<PkieAcquisWorkPlanTaskSetInput>;
  pk_columns: PkieAcquisWorkPlanTaskPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieAcquisWorkPlanTaskManyArgs {
  updates: Array<PkieAcquisWorkPlanTaskUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieActTypeArgs {
  _set?: InputMaybe<PkieActTypeSetInput>;
  where: PkieActTypeBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieActTypeByPkArgs {
  _set?: InputMaybe<PkieActTypeSetInput>;
  pk_columns: PkieActTypePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieActTypeManyArgs {
  updates: Array<PkieActTypeUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieLevelOfAlignmentArgs {
  _set?: InputMaybe<PkieLevelOfAlignmentSetInput>;
  where: PkieLevelOfAlignmentBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieLevelOfAlignmentByPkArgs {
  _set?: InputMaybe<PkieLevelOfAlignmentSetInput>;
  pk_columns: PkieLevelOfAlignmentPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieLevelOfAlignmentManyArgs {
  updates: Array<PkieLevelOfAlignmentUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureArgs {
  _append?: InputMaybe<PkieMeasureAppendInput>;
  _delete_at_path?: InputMaybe<PkieMeasureDeleteAtPathInput>;
  _delete_elem?: InputMaybe<PkieMeasureDeleteElemInput>;
  _delete_key?: InputMaybe<PkieMeasureDeleteKeyInput>;
  _inc?: InputMaybe<PkieMeasureIncInput>;
  _prepend?: InputMaybe<PkieMeasurePrependInput>;
  _set?: InputMaybe<PkieMeasureSetInput>;
  where: PkieMeasureBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureActivityArgs {
  _set?: InputMaybe<PkieMeasureActivitySetInput>;
  where: PkieMeasureActivityBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureActivityByPkArgs {
  _set?: InputMaybe<PkieMeasureActivitySetInput>;
  pk_columns: PkieMeasureActivityPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureActivityManyArgs {
  updates: Array<PkieMeasureActivityUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureAnswersArgs {
  _append?: InputMaybe<PkieMeasureAnswersAppendInput>;
  _delete_at_path?: InputMaybe<PkieMeasureAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<PkieMeasureAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<PkieMeasureAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<PkieMeasureAnswersPrependInput>;
  _set?: InputMaybe<PkieMeasureAnswersSetInput>;
  where: PkieMeasureAnswersBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureAnswersByPkArgs {
  _append?: InputMaybe<PkieMeasureAnswersAppendInput>;
  _delete_at_path?: InputMaybe<PkieMeasureAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<PkieMeasureAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<PkieMeasureAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<PkieMeasureAnswersPrependInput>;
  _set?: InputMaybe<PkieMeasureAnswersSetInput>;
  pk_columns: PkieMeasureAnswersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureAnswersManyArgs {
  updates: Array<PkieMeasureAnswersUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureBookmarkArgs {
  _inc?: InputMaybe<PkieMeasureBookmarkIncInput>;
  _set?: InputMaybe<PkieMeasureBookmarkSetInput>;
  where: PkieMeasureBookmarkBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureBookmarkByPkArgs {
  _inc?: InputMaybe<PkieMeasureBookmarkIncInput>;
  _set?: InputMaybe<PkieMeasureBookmarkSetInput>;
  pk_columns: PkieMeasureBookmarkPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureBookmarkManyArgs {
  updates: Array<PkieMeasureBookmarkUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureBudgetsArgs {
  _inc?: InputMaybe<PkieMeasureBudgetsIncInput>;
  _set?: InputMaybe<PkieMeasureBudgetsSetInput>;
  where: PkieMeasureBudgetsBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureBudgetsByPkArgs {
  _inc?: InputMaybe<PkieMeasureBudgetsIncInput>;
  _set?: InputMaybe<PkieMeasureBudgetsSetInput>;
  pk_columns: PkieMeasureBudgetsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureBudgetsManyArgs {
  updates: Array<PkieMeasureBudgetsUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureByPkArgs {
  _append?: InputMaybe<PkieMeasureAppendInput>;
  _delete_at_path?: InputMaybe<PkieMeasureDeleteAtPathInput>;
  _delete_elem?: InputMaybe<PkieMeasureDeleteElemInput>;
  _delete_key?: InputMaybe<PkieMeasureDeleteKeyInput>;
  _inc?: InputMaybe<PkieMeasureIncInput>;
  _prepend?: InputMaybe<PkieMeasurePrependInput>;
  _set?: InputMaybe<PkieMeasureSetInput>;
  pk_columns: PkieMeasurePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureCommentArgs {
  _set?: InputMaybe<PkieMeasureCommentSetInput>;
  where: PkieMeasureCommentBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureCommentByPkArgs {
  _set?: InputMaybe<PkieMeasureCommentSetInput>;
  pk_columns: PkieMeasureCommentPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureCommentManyArgs {
  updates: Array<PkieMeasureCommentUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureGnpieMembersArgs {
  _set?: InputMaybe<PkieMeasureGnpieMembersSetInput>;
  where: PkieMeasureGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureGnpieMembersByPkArgs {
  _set?: InputMaybe<PkieMeasureGnpieMembersSetInput>;
  pk_columns: PkieMeasureGnpieMembersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureGnpieMembersManyArgs {
  updates: Array<PkieMeasureGnpieMembersUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureManyArgs {
  updates: Array<PkieMeasureUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureRoadmapArgs {
  _set?: InputMaybe<PkieMeasureRoadmapSetInput>;
  where: PkieMeasureRoadmapBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureRoadmapByPkArgs {
  _set?: InputMaybe<PkieMeasureRoadmapSetInput>;
  pk_columns: PkieMeasureRoadmapPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureRoadmapManyArgs {
  updates: Array<PkieMeasureRoadmapUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureStatusArgs {
  _set?: InputMaybe<PkieMeasureStatusSetInput>;
  where: PkieMeasureStatusBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureStatusByPkArgs {
  _set?: InputMaybe<PkieMeasureStatusSetInput>;
  pk_columns: PkieMeasureStatusPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieMeasureStatusManyArgs {
  updates: Array<PkieMeasureStatusUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieNationalActsArgs {
  _inc?: InputMaybe<PkieNationalActsIncInput>;
  _set?: InputMaybe<PkieNationalActsSetInput>;
  where: PkieNationalActsBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieNationalActsByPkArgs {
  _inc?: InputMaybe<PkieNationalActsIncInput>;
  _set?: InputMaybe<PkieNationalActsSetInput>;
  pk_columns: PkieNationalActsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieNationalActsManyArgs {
  updates: Array<PkieNationalActsUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieProcesPuneIagaArgs {
  _set?: InputMaybe<PkieProcesPuneIagaSetInput>;
  where: PkieProcesPuneIagaBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieProcesPuneIagaByPkArgs {
  _set?: InputMaybe<PkieProcesPuneIagaSetInput>;
  pk_columns: PkieProcesPuneIagaPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieProcesPuneIagaManyArgs {
  updates: Array<PkieProcesPuneIagaUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieProcesPuneLgaArgs {
  _set?: InputMaybe<PkieProcesPuneLgaSetInput>;
  where: PkieProcesPuneLgaBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieProcesPuneLgaByPkArgs {
  _set?: InputMaybe<PkieProcesPuneLgaSetInput>;
  pk_columns: PkieProcesPuneLgaPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieProcesPuneLgaManyArgs {
  updates: Array<PkieProcesPuneLgaUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieSourceOfFundingArgs {
  _set?: InputMaybe<PkieSourceOfFundingSetInput>;
  where: PkieSourceOfFundingBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieSourceOfFundingByPkArgs {
  _set?: InputMaybe<PkieSourceOfFundingSetInput>;
  pk_columns: PkieSourceOfFundingPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieSourceOfFundingManyArgs {
  updates: Array<PkieSourceOfFundingUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeAlignmentActArgs {
  _set?: InputMaybe<PkieTypeAlignmentActSetInput>;
  where: PkieTypeAlignmentActBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeAlignmentActByPkArgs {
  _set?: InputMaybe<PkieTypeAlignmentActSetInput>;
  pk_columns: PkieTypeAlignmentActPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeAlignmentActManyArgs {
  updates: Array<PkieTypeAlignmentActUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeMeasureArgs {
  _set?: InputMaybe<PkieTypeMeasureSetInput>;
  where: PkieTypeMeasureBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeMeasureBudgetArgs {
  _set?: InputMaybe<PkieTypeMeasureBudgetSetInput>;
  where: PkieTypeMeasureBudgetBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeMeasureBudgetByPkArgs {
  _set?: InputMaybe<PkieTypeMeasureBudgetSetInput>;
  pk_columns: PkieTypeMeasureBudgetPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeMeasureBudgetManyArgs {
  updates: Array<PkieTypeMeasureBudgetUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeMeasureByPkArgs {
  _set?: InputMaybe<PkieTypeMeasureSetInput>;
  pk_columns: PkieTypeMeasurePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieTypeMeasureManyArgs {
  updates: Array<PkieTypeMeasureUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanCommentArgs {
  _set?: InputMaybe<PkieWorkPlanCommentSetInput>;
  where: PkieWorkPlanCommentBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanCommentByPkArgs {
  _set?: InputMaybe<PkieWorkPlanCommentSetInput>;
  pk_columns: PkieWorkPlanCommentPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanCommentManyArgs {
  updates: Array<PkieWorkPlanCommentUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanStatusesArgs {
  _set?: InputMaybe<PkieWorkPlanStatusesSetInput>;
  where: PkieWorkPlanStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanStatusesByPkArgs {
  _set?: InputMaybe<PkieWorkPlanStatusesSetInput>;
  pk_columns: PkieWorkPlanStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanStatusesManyArgs {
  updates: Array<PkieWorkPlanStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskArgs {
  _inc?: InputMaybe<PkieWorkPlanTaskIncInput>;
  _set?: InputMaybe<PkieWorkPlanTaskSetInput>;
  where: PkieWorkPlanTaskBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskActivityArgs {
  _set?: InputMaybe<PkieWorkPlanTaskActivitySetInput>;
  where: PkieWorkPlanTaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskActivityByPkArgs {
  _set?: InputMaybe<PkieWorkPlanTaskActivitySetInput>;
  pk_columns: PkieWorkPlanTaskActivityPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskActivityManyArgs {
  updates: Array<PkieWorkPlanTaskActivityUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskByPkArgs {
  _inc?: InputMaybe<PkieWorkPlanTaskIncInput>;
  _set?: InputMaybe<PkieWorkPlanTaskSetInput>;
  pk_columns: PkieWorkPlanTaskPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskManyArgs {
  updates: Array<PkieWorkPlanTaskUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskStatusesArgs {
  _set?: InputMaybe<PkieWorkPlanTaskStatusesSetInput>;
  where: PkieWorkPlanTaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskStatusesByPkArgs {
  _set?: InputMaybe<PkieWorkPlanTaskStatusesSetInput>;
  pk_columns: PkieWorkPlanTaskStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlanTaskStatusesManyArgs {
  updates: Array<PkieWorkPlanTaskStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlansArgs {
  _inc?: InputMaybe<PkieWorkPlansIncInput>;
  _set?: InputMaybe<PkieWorkPlansSetInput>;
  where: PkieWorkPlansBoolExp;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlansByPkArgs {
  _inc?: InputMaybe<PkieWorkPlansIncInput>;
  _set?: InputMaybe<PkieWorkPlansSetInput>;
  pk_columns: PkieWorkPlansPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdatePkieWorkPlansManyArgs {
  updates: Array<PkieWorkPlansUpdates>;
}


/** mutation root */
export interface MutationRootUpdateQuestionTypesArgs {
  _set?: InputMaybe<QuestionTypesSetInput>;
  where: QuestionTypesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateQuestionTypesByPkArgs {
  _set?: InputMaybe<QuestionTypesSetInput>;
  pk_columns: QuestionTypesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateQuestionTypesManyArgs {
  updates: Array<QuestionTypesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateQuestionsArgs {
  _inc?: InputMaybe<QuestionsIncInput>;
  _set?: InputMaybe<QuestionsSetInput>;
  where: QuestionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateQuestionsByPkArgs {
  _inc?: InputMaybe<QuestionsIncInput>;
  _set?: InputMaybe<QuestionsSetInput>;
  pk_columns: QuestionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateQuestionsManyArgs {
  updates: Array<QuestionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateRecommendationsArgs {
  _inc?: InputMaybe<RecommendationsIncInput>;
  _set?: InputMaybe<RecommendationsSetInput>;
  where: RecommendationsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateRecommendationsByPkArgs {
  _inc?: InputMaybe<RecommendationsIncInput>;
  _set?: InputMaybe<RecommendationsSetInput>;
  pk_columns: RecommendationsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateRecommendationsManyArgs {
  updates: Array<RecommendationsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportAnswersArgs {
  _append?: InputMaybe<ReportAnswersAppendInput>;
  _delete_at_path?: InputMaybe<ReportAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<ReportAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<ReportAnswersPrependInput>;
  _set?: InputMaybe<ReportAnswersSetInput>;
  where: ReportAnswersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportAnswersByPkArgs {
  _append?: InputMaybe<ReportAnswersAppendInput>;
  _delete_at_path?: InputMaybe<ReportAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<ReportAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<ReportAnswersPrependInput>;
  _set?: InputMaybe<ReportAnswersSetInput>;
  pk_columns: ReportAnswersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportAnswersManyArgs {
  updates: Array<ReportAnswersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportChapterStatusesArgs {
  _set?: InputMaybe<ReportChapterStatusesSetInput>;
  where: ReportChapterStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportChapterStatusesByPkArgs {
  _set?: InputMaybe<ReportChapterStatusesSetInput>;
  pk_columns: ReportChapterStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportChapterStatusesManyArgs {
  updates: Array<ReportChapterStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportChaptersArgs {
  _inc?: InputMaybe<ReportChaptersIncInput>;
  _set?: InputMaybe<ReportChaptersSetInput>;
  where: ReportChaptersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportChaptersByPkArgs {
  _inc?: InputMaybe<ReportChaptersIncInput>;
  _set?: InputMaybe<ReportChaptersSetInput>;
  pk_columns: ReportChaptersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportChaptersManyArgs {
  updates: Array<ReportChaptersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportStatusesArgs {
  _set?: InputMaybe<ReportStatusesSetInput>;
  where: ReportStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportStatusesByPkArgs {
  _set?: InputMaybe<ReportStatusesSetInput>;
  pk_columns: ReportStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportStatusesManyArgs {
  updates: Array<ReportStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportTaskActivityArgs {
  _set?: InputMaybe<ReportTaskActivitySetInput>;
  where: ReportTaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportTaskActivityByPkArgs {
  _set?: InputMaybe<ReportTaskActivitySetInput>;
  pk_columns: ReportTaskActivityPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportTaskActivityManyArgs {
  updates: Array<ReportTaskActivityUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportTaskCommentArgs {
  _set?: InputMaybe<ReportTaskCommentSetInput>;
  where: ReportTaskCommentBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportTaskCommentByPkArgs {
  _set?: InputMaybe<ReportTaskCommentSetInput>;
  pk_columns: ReportTaskCommentPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportTaskCommentManyArgs {
  updates: Array<ReportTaskCommentUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportTaskGnpieMembersArgs {
  _set?: InputMaybe<ReportTaskGnpieMembersSetInput>;
  where: ReportTaskGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportTaskGnpieMembersByPkArgs {
  _set?: InputMaybe<ReportTaskGnpieMembersSetInput>;
  pk_columns: ReportTaskGnpieMembersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportTaskGnpieMembersManyArgs {
  updates: Array<ReportTaskGnpieMembersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportTaskStatusesArgs {
  _set?: InputMaybe<ReportTaskStatusesSetInput>;
  where: ReportTaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportTaskStatusesByPkArgs {
  _set?: InputMaybe<ReportTaskStatusesSetInput>;
  pk_columns: ReportTaskStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportTaskStatusesManyArgs {
  updates: Array<ReportTaskStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportTasksArgs {
  _append?: InputMaybe<ReportTasksAppendInput>;
  _delete_at_path?: InputMaybe<ReportTasksDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportTasksDeleteElemInput>;
  _delete_key?: InputMaybe<ReportTasksDeleteKeyInput>;
  _prepend?: InputMaybe<ReportTasksPrependInput>;
  _set?: InputMaybe<ReportTasksSetInput>;
  where: ReportTasksBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportTasksByPkArgs {
  _append?: InputMaybe<ReportTasksAppendInput>;
  _delete_at_path?: InputMaybe<ReportTasksDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ReportTasksDeleteElemInput>;
  _delete_key?: InputMaybe<ReportTasksDeleteKeyInput>;
  _prepend?: InputMaybe<ReportTasksPrependInput>;
  _set?: InputMaybe<ReportTasksSetInput>;
  pk_columns: ReportTasksPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportTasksManyArgs {
  updates: Array<ReportTasksUpdates>;
}


/** mutation root */
export interface MutationRootUpdateReportsArgs {
  _inc?: InputMaybe<ReportsIncInput>;
  _set?: InputMaybe<ReportsSetInput>;
  where: ReportsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateReportsByPkArgs {
  _inc?: InputMaybe<ReportsIncInput>;
  _set?: InputMaybe<ReportsSetInput>;
  pk_columns: ReportsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateReportsManyArgs {
  updates: Array<ReportsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateRoadmapMeasureTypeArgs {
  _set?: InputMaybe<RoadmapMeasureTypeSetInput>;
  where: RoadmapMeasureTypeBoolExp;
}


/** mutation root */
export interface MutationRootUpdateRoadmapMeasureTypeByPkArgs {
  _set?: InputMaybe<RoadmapMeasureTypeSetInput>;
  pk_columns: RoadmapMeasureTypePkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateRoadmapMeasureTypeManyArgs {
  updates: Array<RoadmapMeasureTypeUpdates>;
}


/** mutation root */
export interface MutationRootUpdateSignedDocumentsArgs {
  _inc?: InputMaybe<SignedDocumentsIncInput>;
  _set?: InputMaybe<SignedDocumentsSetInput>;
  where: SignedDocumentsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateSignedDocumentsByPkArgs {
  _inc?: InputMaybe<SignedDocumentsIncInput>;
  _set?: InputMaybe<SignedDocumentsSetInput>;
  pk_columns: SignedDocumentsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateSignedDocumentsManyArgs {
  updates: Array<SignedDocumentsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateSubChapterLeadInstitutionsArgs {
  _inc?: InputMaybe<SubChapterLeadInstitutionsIncInput>;
  _set?: InputMaybe<SubChapterLeadInstitutionsSetInput>;
  where: SubChapterLeadInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateSubChapterLeadInstitutionsByPkArgs {
  _inc?: InputMaybe<SubChapterLeadInstitutionsIncInput>;
  _set?: InputMaybe<SubChapterLeadInstitutionsSetInput>;
  pk_columns: SubChapterLeadInstitutionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateSubChapterLeadInstitutionsManyArgs {
  updates: Array<SubChapterLeadInstitutionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateSubChaptersArgs {
  _inc?: InputMaybe<SubChaptersIncInput>;
  _set?: InputMaybe<SubChaptersSetInput>;
  where: SubChaptersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateSubChaptersByPkArgs {
  _inc?: InputMaybe<SubChaptersIncInput>;
  _set?: InputMaybe<SubChaptersSetInput>;
  pk_columns: SubChaptersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateSubChaptersManyArgs {
  updates: Array<SubChaptersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskArgs {
  _append?: InputMaybe<TaskAppendInput>;
  _delete_at_path?: InputMaybe<TaskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaskDeleteElemInput>;
  _delete_key?: InputMaybe<TaskDeleteKeyInput>;
  _inc?: InputMaybe<TaskIncInput>;
  _prepend?: InputMaybe<TaskPrependInput>;
  _set?: InputMaybe<TaskSetInput>;
  where: TaskBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskActivityArgs {
  _set?: InputMaybe<TaskActivitySetInput>;
  where: TaskActivityBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskActivityByPkArgs {
  _set?: InputMaybe<TaskActivitySetInput>;
  pk_columns: TaskActivityPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskActivityManyArgs {
  updates: Array<TaskActivityUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskAnswersArgs {
  _append?: InputMaybe<TaskAnswersAppendInput>;
  _delete_at_path?: InputMaybe<TaskAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaskAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<TaskAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<TaskAnswersPrependInput>;
  _set?: InputMaybe<TaskAnswersSetInput>;
  where: TaskAnswersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskAnswersByPkArgs {
  _append?: InputMaybe<TaskAnswersAppendInput>;
  _delete_at_path?: InputMaybe<TaskAnswersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaskAnswersDeleteElemInput>;
  _delete_key?: InputMaybe<TaskAnswersDeleteKeyInput>;
  _prepend?: InputMaybe<TaskAnswersPrependInput>;
  _set?: InputMaybe<TaskAnswersSetInput>;
  pk_columns: TaskAnswersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskAnswersManyArgs {
  updates: Array<TaskAnswersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskByPkArgs {
  _append?: InputMaybe<TaskAppendInput>;
  _delete_at_path?: InputMaybe<TaskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaskDeleteElemInput>;
  _delete_key?: InputMaybe<TaskDeleteKeyInput>;
  _inc?: InputMaybe<TaskIncInput>;
  _prepend?: InputMaybe<TaskPrependInput>;
  _set?: InputMaybe<TaskSetInput>;
  pk_columns: TaskPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskCommentsArgs {
  _set?: InputMaybe<TaskCommentsSetInput>;
  where: TaskCommentsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskCommentsByPkArgs {
  _set?: InputMaybe<TaskCommentsSetInput>;
  pk_columns: TaskCommentsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskCommentsManyArgs {
  updates: Array<TaskCommentsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskGnpieMembersArgs {
  _set?: InputMaybe<TaskGnpieMembersSetInput>;
  where: TaskGnpieMembersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskGnpieMembersByPkArgs {
  _set?: InputMaybe<TaskGnpieMembersSetInput>;
  pk_columns: TaskGnpieMembersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskGnpieMembersManyArgs {
  updates: Array<TaskGnpieMembersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskManyArgs {
  updates: Array<TaskUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskStatusesArgs {
  _set?: InputMaybe<TaskStatusesSetInput>;
  where: TaskStatusesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskStatusesByPkArgs {
  _set?: InputMaybe<TaskStatusesSetInput>;
  pk_columns: TaskStatusesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskStatusesManyArgs {
  updates: Array<TaskStatusesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTaskTypesArgs {
  _set?: InputMaybe<TaskTypesSetInput>;
  where: TaskTypesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTaskTypesByPkArgs {
  _set?: InputMaybe<TaskTypesSetInput>;
  pk_columns: TaskTypesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTaskTypesManyArgs {
  updates: Array<TaskTypesUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTopicsArgs {
  _inc?: InputMaybe<TopicsIncInput>;
  _set?: InputMaybe<TopicsSetInput>;
  where: TopicsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTopicsByPkArgs {
  _inc?: InputMaybe<TopicsIncInput>;
  _set?: InputMaybe<TopicsSetInput>;
  pk_columns: TopicsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTopicsManyArgs {
  updates: Array<TopicsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateTypeInstitutionsArgs {
  _set?: InputMaybe<TypeInstitutionsSetInput>;
  where: TypeInstitutionsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateTypeInstitutionsByPkArgs {
  _set?: InputMaybe<TypeInstitutionsSetInput>;
  pk_columns: TypeInstitutionsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateTypeInstitutionsManyArgs {
  updates: Array<TypeInstitutionsUpdates>;
}


/** mutation root */
export interface MutationRootUpdateUsersManyArgs {
  updates: Array<UsersUpdates>;
}


/** mutation root */
export interface MutationRootUpdateYearlyQuartersArgs {
  _set?: InputMaybe<YearlyQuartersSetInput>;
  where: YearlyQuartersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateYearlyQuartersByPkArgs {
  _set?: InputMaybe<YearlyQuartersSetInput>;
  pk_columns: YearlyQuartersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateYearlyQuartersManyArgs {
  updates: Array<YearlyQuartersUpdates>;
}

export interface NegotiatorCalendar {
  __typename?: 'negotiator_calendar';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  deadline?: Maybe<Scalars['timestamp']['output']>;
  end_date?: Maybe<Scalars['timestamp']['output']>;
  grand_parent_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parent_id: Scalars['String']['output'];
  task_name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
}

/** Boolean expression to filter rows from the logical model for "negotiator_calendar". All fields are combined with a logical 'AND'. */
export interface NegotiatorCalendarBoolExpBoolExp {
  _and?: InputMaybe<Array<NegotiatorCalendarBoolExpBoolExp>>;
  _not?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
  _or?: InputMaybe<Array<NegotiatorCalendarBoolExpBoolExp>>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  deadline?: InputMaybe<TimestampComparisonExp>;
  end_date?: InputMaybe<TimestampComparisonExp>;
  grand_parent_id?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  parent_id?: InputMaybe<StringComparisonExp>;
  task_name?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  user_id?: InputMaybe<StringComparisonExp>;
}

export enum NegotiatorCalendarEnumName {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GrandParentId = 'grand_parent_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TaskName = 'task_name',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** Ordering options when selecting data from "negotiator_calendar". */
export interface NegotiatorCalendarOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  grand_parent_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  task_name?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
}

/** columns and relationships of "notification" */
export interface Notification {
  __typename?: 'notification';
  /** An object relationship */
  bookmark?: Maybe<Bookmarks>;
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  bookmark_task?: Maybe<BookmarkTasks>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  content: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  document?: Maybe<Documents>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  folder_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure?: Maybe<PkieMeasure>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  meeting?: Maybe<Meetings>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  meeting_task?: Maybe<Task>;
  meeting_task_id?: Maybe<Scalars['uuid']['output']>;
  notification_origin?: Maybe<NotificationOriginEnum>;
  notification_type: NotificationTypeEnum;
  read?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  report?: Maybe<Reports>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  report_task?: Maybe<ReportTasks>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<Task>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  work_plan_task?: Maybe<PkieWorkPlanTask>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregated selection of "notification" */
export interface NotificationAggregate {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<NotificationAggregateFields>;
  nodes: Array<Notification>;
}

/** aggregate fields of "notification" */
export interface NotificationAggregateFields {
  __typename?: 'notification_aggregate_fields';
  avg?: Maybe<NotificationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<NotificationMaxFields>;
  min?: Maybe<NotificationMinFields>;
  stddev?: Maybe<NotificationStddevFields>;
  stddev_pop?: Maybe<NotificationStddevPopFields>;
  stddev_samp?: Maybe<NotificationStddevSampFields>;
  sum?: Maybe<NotificationSumFields>;
  var_pop?: Maybe<NotificationVarPopFields>;
  var_samp?: Maybe<NotificationVarSampFields>;
  variance?: Maybe<NotificationVarianceFields>;
}


/** aggregate fields of "notification" */
export interface NotificationAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<NotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface NotificationAvgFields {
  __typename?: 'notification_avg_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export interface NotificationBoolExp {
  _and?: InputMaybe<Array<NotificationBoolExp>>;
  _not?: InputMaybe<NotificationBoolExp>;
  _or?: InputMaybe<Array<NotificationBoolExp>>;
  bookmark?: InputMaybe<BookmarksBoolExp>;
  bookmark_id?: InputMaybe<IntComparisonExp>;
  bookmark_task?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_task_id?: InputMaybe<UuidComparisonExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  content?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  document?: InputMaybe<DocumentsBoolExp>;
  document_id?: InputMaybe<UuidComparisonExp>;
  folder_id?: InputMaybe<UuidComparisonExp>;
  gnpie_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  meeting?: InputMaybe<MeetingsBoolExp>;
  meeting_id?: InputMaybe<UuidComparisonExp>;
  meeting_task?: InputMaybe<TaskBoolExp>;
  meeting_task_id?: InputMaybe<UuidComparisonExp>;
  notification_origin?: InputMaybe<NotificationOriginEnumComparisonExp>;
  notification_type?: InputMaybe<NotificationTypeEnumComparisonExp>;
  read?: InputMaybe<BooleanComparisonExp>;
  report?: InputMaybe<ReportsBoolExp>;
  report_id?: InputMaybe<UuidComparisonExp>;
  report_task?: InputMaybe<ReportTasksBoolExp>;
  report_task_id?: InputMaybe<UuidComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
  work_plan_id?: InputMaybe<UuidComparisonExp>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "notification" */
export enum NotificationConstraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey'
}

/** input type for incrementing numeric columns in table "notification" */
export interface NotificationIncInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "notification" */
export interface NotificationInsertInput {
  bookmark?: InputMaybe<BookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task?: InputMaybe<BookmarkTasksObjRelInsertInput>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document?: InputMaybe<DocumentsObjRelInsertInput>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  folder_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  meeting?: InputMaybe<MeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_task?: InputMaybe<TaskObjRelInsertInput>;
  meeting_task_id?: InputMaybe<Scalars['uuid']['input']>;
  notification_origin?: InputMaybe<NotificationOriginEnum>;
  notification_type?: InputMaybe<NotificationTypeEnum>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  report?: InputMaybe<ReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<ReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface NotificationMaxFields {
  __typename?: 'notification_max_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  folder_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  meeting_task_id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregate min on columns */
export interface NotificationMinFields {
  __typename?: 'notification_min_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  bookmark_task_id?: Maybe<Scalars['uuid']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  folder_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  meeting_task_id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** response of any mutation on the table "notification" */
export interface NotificationMutationResponse {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
}

/** on_conflict condition type for table "notification" */
export interface NotificationOnConflict {
  constraint: NotificationConstraint;
  update_columns?: Array<NotificationUpdateColumn>;
  where?: InputMaybe<NotificationBoolExp>;
}

/** Ordering options when selecting data from "notification". */
export interface NotificationOrderBy {
  bookmark?: InputMaybe<BookmarksOrderBy>;
  bookmark_id?: InputMaybe<OrderBy>;
  bookmark_task?: InputMaybe<BookmarkTasksOrderBy>;
  bookmark_task_id?: InputMaybe<OrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  document?: InputMaybe<DocumentsOrderBy>;
  document_id?: InputMaybe<OrderBy>;
  folder_id?: InputMaybe<OrderBy>;
  gnpie_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  meeting?: InputMaybe<MeetingsOrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  meeting_task?: InputMaybe<TaskOrderBy>;
  meeting_task_id?: InputMaybe<OrderBy>;
  notification_origin?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  read?: InputMaybe<OrderBy>;
  report?: InputMaybe<ReportsOrderBy>;
  report_id?: InputMaybe<OrderBy>;
  report_task?: InputMaybe<ReportTasksOrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  task_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  user_id?: InputMaybe<OrderBy>;
  work_plan_id?: InputMaybe<OrderBy>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskOrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** columns and relationships of "notification_origin" */
export interface NotificationOrigin {
  __typename?: 'notification_origin';
  origin: Scalars['String']['output'];
}

/** aggregated selection of "notification_origin" */
export interface NotificationOriginAggregate {
  __typename?: 'notification_origin_aggregate';
  aggregate?: Maybe<NotificationOriginAggregateFields>;
  nodes: Array<NotificationOrigin>;
}

/** aggregate fields of "notification_origin" */
export interface NotificationOriginAggregateFields {
  __typename?: 'notification_origin_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<NotificationOriginMaxFields>;
  min?: Maybe<NotificationOriginMinFields>;
}


/** aggregate fields of "notification_origin" */
export interface NotificationOriginAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<NotificationOriginSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "notification_origin". All fields are combined with a logical 'AND'. */
export interface NotificationOriginBoolExp {
  _and?: InputMaybe<Array<NotificationOriginBoolExp>>;
  _not?: InputMaybe<NotificationOriginBoolExp>;
  _or?: InputMaybe<Array<NotificationOriginBoolExp>>;
  origin?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "notification_origin" */
export enum NotificationOriginConstraint {
  /** unique or primary key constraint on columns "origin" */
  NotificationOriginPkey = 'notification_origin_pkey'
}

export enum NotificationOriginEnum {
  Acquis = 'acquis',
  BookmarkTasks = 'bookmark_tasks',
  Bookmarks = 'bookmarks',
  Folders = 'folders',
  Integration = 'integration',
  IntegrationTaskChild = 'integration_task_child',
  IntegrationTaskParent = 'integration_task_parent',
  Measure = 'measure',
  MeetingTask = 'meeting_task',
  Meetings = 'meetings',
  ReportTasks = 'report_tasks',
  Reports = 'reports',
  Roadmap = 'roadmap',
  RoadmapTaskChild = 'roadmap_task_child',
  RoadmapTaskParent = 'roadmap_task_parent',
  WorkPlanTask = 'work_plan_task',
  WorkPlans = 'work_plans'
}

/** Boolean expression to compare columns of type "notification_origin_enum". All fields are combined with logical 'AND'. */
export interface NotificationOriginEnumComparisonExp {
  _eq?: InputMaybe<NotificationOriginEnum>;
  _in?: InputMaybe<Array<NotificationOriginEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<NotificationOriginEnum>;
  _nin?: InputMaybe<Array<NotificationOriginEnum>>;
}

/** input type for inserting data into table "notification_origin" */
export interface NotificationOriginInsertInput {
  origin?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface NotificationOriginMaxFields {
  __typename?: 'notification_origin_max_fields';
  origin?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface NotificationOriginMinFields {
  __typename?: 'notification_origin_min_fields';
  origin?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "notification_origin" */
export interface NotificationOriginMutationResponse {
  __typename?: 'notification_origin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<NotificationOrigin>;
}

/** on_conflict condition type for table "notification_origin" */
export interface NotificationOriginOnConflict {
  constraint: NotificationOriginConstraint;
  update_columns?: Array<NotificationOriginUpdateColumn>;
  where?: InputMaybe<NotificationOriginBoolExp>;
}

/** Ordering options when selecting data from "notification_origin". */
export interface NotificationOriginOrderBy {
  origin?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: notification_origin */
export interface NotificationOriginPkColumnsInput {
  origin: Scalars['String']['input'];
}

/** select columns of table "notification_origin" */
export enum NotificationOriginSelectColumn {
  /** column name */
  Origin = 'origin'
}

/** input type for updating data in table "notification_origin" */
export interface NotificationOriginSetInput {
  origin?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "notification_origin" */
export interface NotificationOriginStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: NotificationOriginStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface NotificationOriginStreamCursorValueInput {
  origin?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "notification_origin" */
export enum NotificationOriginUpdateColumn {
  /** column name */
  Origin = 'origin'
}

export interface NotificationOriginUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NotificationOriginSetInput>;
  /** filter the rows which have to be updated */
  where: NotificationOriginBoolExp;
}

/** primary key columns input for table: notification */
export interface NotificationPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "notification" */
export enum NotificationSelectColumn {
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  GnpieUserId = 'gnpie_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  MeetingTaskId = 'meeting_task_id',
  /** column name */
  NotificationOrigin = 'notification_origin',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  Read = 'read',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkPlanId = 'work_plan_id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

/** input type for updating data in table "notification" */
export interface NotificationSetInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  folder_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_task_id?: InputMaybe<Scalars['uuid']['input']>;
  notification_origin?: InputMaybe<NotificationOriginEnum>;
  notification_type?: InputMaybe<NotificationTypeEnum>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface NotificationStddevFields {
  __typename?: 'notification_stddev_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface NotificationStddevPopFields {
  __typename?: 'notification_stddev_pop_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface NotificationStddevSampFields {
  __typename?: 'notification_stddev_samp_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "notification" */
export interface NotificationStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: NotificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface NotificationStreamCursorValueInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  bookmark_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  folder_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  meeting_task_id?: InputMaybe<Scalars['uuid']['input']>;
  notification_origin?: InputMaybe<NotificationOriginEnum>;
  notification_type?: InputMaybe<NotificationTypeEnum>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface NotificationSumFields {
  __typename?: 'notification_sum_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** columns and relationships of "notification_type" */
export interface NotificationType {
  __typename?: 'notification_type';
  type: Scalars['String']['output'];
}

/** aggregated selection of "notification_type" */
export interface NotificationTypeAggregate {
  __typename?: 'notification_type_aggregate';
  aggregate?: Maybe<NotificationTypeAggregateFields>;
  nodes: Array<NotificationType>;
}

/** aggregate fields of "notification_type" */
export interface NotificationTypeAggregateFields {
  __typename?: 'notification_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<NotificationTypeMaxFields>;
  min?: Maybe<NotificationTypeMinFields>;
}


/** aggregate fields of "notification_type" */
export interface NotificationTypeAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<NotificationTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export interface NotificationTypeBoolExp {
  _and?: InputMaybe<Array<NotificationTypeBoolExp>>;
  _not?: InputMaybe<NotificationTypeBoolExp>;
  _or?: InputMaybe<Array<NotificationTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "notification_type" */
export enum NotificationTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  NotificationTypePkey = 'notification_type_pkey'
}

export enum NotificationTypeEnum {
  AcquieAdded = 'ACQUIE_ADDED',
  Assignment = 'ASSIGNMENT',
  AssignmentRemoved = 'ASSIGNMENT_REMOVED',
  ChapterStatus = 'CHAPTER_STATUS',
  CommentAdded = 'COMMENT_ADDED',
  DocumentAdded = 'DOCUMENT_ADDED',
  ErrorLog = 'ERROR_LOG',
  FolderPermission = 'FOLDER_PERMISSION',
  MeasureFiledsUpdate = 'MEASURE_FILEDS_UPDATE',
  MeetingAssignment = 'MEETING_ASSIGNMENT',
  MeetingReminder = 'MEETING_REMINDER',
  MeetingTaskAssignment = 'MEETING__TASK_ASSIGNMENT',
  ReportingDeadlineChange = 'REPORTING_DEADLINE_CHANGE',
  StatusChange = 'STATUS_CHANGE',
  TaskAnswered = 'TASK_ANSWERED',
  TaskReminder = 'TASK_REMINDER'
}

/** Boolean expression to compare columns of type "notification_type_enum". All fields are combined with logical 'AND'. */
export interface NotificationTypeEnumComparisonExp {
  _eq?: InputMaybe<NotificationTypeEnum>;
  _in?: InputMaybe<Array<NotificationTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<NotificationTypeEnum>;
  _nin?: InputMaybe<Array<NotificationTypeEnum>>;
}

/** input type for inserting data into table "notification_type" */
export interface NotificationTypeInsertInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface NotificationTypeMaxFields {
  __typename?: 'notification_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface NotificationTypeMinFields {
  __typename?: 'notification_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "notification_type" */
export interface NotificationTypeMutationResponse {
  __typename?: 'notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<NotificationType>;
}

/** on_conflict condition type for table "notification_type" */
export interface NotificationTypeOnConflict {
  constraint: NotificationTypeConstraint;
  update_columns?: Array<NotificationTypeUpdateColumn>;
  where?: InputMaybe<NotificationTypeBoolExp>;
}

/** Ordering options when selecting data from "notification_type". */
export interface NotificationTypeOrderBy {
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: notification_type */
export interface NotificationTypePkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "notification_type" */
export enum NotificationTypeSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "notification_type" */
export interface NotificationTypeSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "notification_type" */
export interface NotificationTypeStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: NotificationTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface NotificationTypeStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "notification_type" */
export enum NotificationTypeUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface NotificationTypeUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NotificationTypeSetInput>;
  /** filter the rows which have to be updated */
  where: NotificationTypeBoolExp;
}

/** update columns of table "notification" */
export enum NotificationUpdateColumn {
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  BookmarkTaskId = 'bookmark_task_id',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  GnpieUserId = 'gnpie_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  MeetingTaskId = 'meeting_task_id',
  /** column name */
  NotificationOrigin = 'notification_origin',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  Read = 'read',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkPlanId = 'work_plan_id',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface NotificationUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NotificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NotificationSetInput>;
  /** filter the rows which have to be updated */
  where: NotificationBoolExp;
}

/** aggregate var_pop on columns */
export interface NotificationVarPopFields {
  __typename?: 'notification_var_pop_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface NotificationVarSampFields {
  __typename?: 'notification_var_samp_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface NotificationVarianceFields {
  __typename?: 'notification_variance_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** nrOfInstitutionsPerChapterNative Query Arguments */
export interface NrOfInstitutionsPerChapterArguments {
  chapter_id: Scalars['Int']['input'];
}

/** nrOfUsersPerInstitutionNative Query Arguments */
export interface NrOfUsersPerInstitutionArguments {
  chapter_id: Scalars['Int']['input'];
}

export interface NrOfInstitutionsPerChapter {
  __typename?: 'nr_of_institutions_per_chapter';
  count?: Maybe<Scalars['bigint']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** Boolean expression to filter rows from the logical model for "nr_of_institutions_per_chapter". All fields are combined with a logical 'AND'. */
export interface NrOfInstitutionsPerChapterBoolExpBoolExp {
  _and?: InputMaybe<Array<NrOfInstitutionsPerChapterBoolExpBoolExp>>;
  _not?: InputMaybe<NrOfInstitutionsPerChapterBoolExpBoolExp>;
  _or?: InputMaybe<Array<NrOfInstitutionsPerChapterBoolExpBoolExp>>;
  count?: InputMaybe<BigintComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
}

export enum NrOfInstitutionsPerChapterEnumName {
  /** column name */
  Count = 'count',
  /** column name */
  Type = 'type'
}

/** Ordering options when selecting data from "nr_of_institutions_per_chapter". */
export interface NrOfInstitutionsPerChapterOrderBy {
  count?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface NumericArrayComparisonExp {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _eq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _gte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _lte?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _neq?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['numeric']['input']>>>;
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface NumericComparisonExp {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
}

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Contains all acquis legal acts from eu  */
export interface PkieAcquis {
  __typename?: 'pkie_acquis';
  /** An array relationship */
  acquis_chapters: Array<PkieAcquisChapter>;
  /** An aggregate relationship */
  acquis_chapters_aggregate: PkieAcquisChapterAggregate;
  /** An array relationship */
  acquis_institutions: Array<PkieAcquisInstitutions>;
  /** An aggregate relationship */
  acquis_institutions_aggregate: PkieAcquisInstitutionsAggregate;
  /** An array relationship */
  acquis_national_acts: Array<PkieAcquisNationalActs>;
  /** An aggregate relationship */
  acquis_national_acts_aggregate: PkieAcquisNationalActsAggregate;
  author?: Maybe<Scalars['String']['output']>;
  celex: Scalars['String']['output'];
  current_consolidated_version?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['date']['output']>;
  date_of_effect?: Maybe<Scalars['String']['output']>;
  date_of_end_of_validity?: Maybe<Scalars['String']['output']>;
  date_of_publication?: Maybe<Scalars['String']['output']>;
  date_of_transposition?: Maybe<Scalars['String']['output']>;
  directory_code?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  eurovoc_descriptor?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Scalars['String']['output']>;
  in_force_indicator?: Maybe<Scalars['Boolean']['output']>;
  index_nr: Scalars['Int']['output'];
  is_aligned?: Maybe<Scalars['Boolean']['output']>;
  latest_conslolidated_version?: Maybe<Scalars['String']['output']>;
  latest_modification_notice?: Maybe<Scalars['String']['output']>;
  level_of_alignment?: Maybe<PkieLevelOfAlignmentEnum>;
  /** An object relationship */
  level_of_alignment_status?: Maybe<PkieLevelOfAlignment>;
  /** An array relationship */
  proces_pune_iagas: Array<PkieProcesPuneIaga>;
  /** An aggregate relationship */
  proces_pune_iagas_aggregate: PkieProcesPuneIagaAggregate;
  /** An array relationship */
  proces_pune_lgas: Array<PkieProcesPuneLga>;
  /** An aggregate relationship */
  proces_pune_lgas_aggregate: PkieProcesPuneLgaAggregate;
  publication_reference?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  subject_matter?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  to_be_transposed?: Maybe<Scalars['Boolean']['output']>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: Maybe<Scalars['date']['output']>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: Maybe<Scalars['date']['output']>;
  type?: Maybe<PkieAcquisTypeEnum>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisAcquisChaptersArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisAcquisChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisAcquisInstitutionsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisAcquisInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisAcquisNationalActsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisAcquisNationalActsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisProcesPuneIagasArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneIagaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisProcesPuneIagasAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneIagaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisProcesPuneLgasArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneLgaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}


/** Contains all acquis legal acts from eu  */
export interface PkieAcquisProcesPuneLgasAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneLgaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}

/** aggregated selection of "pkie.acquis" */
export interface PkieAcquisAggregate {
  __typename?: 'pkie_acquis_aggregate';
  aggregate?: Maybe<PkieAcquisAggregateFields>;
  nodes: Array<PkieAcquis>;
}

export interface PkieAcquisAggregateBoolExp {
  bool_and?: InputMaybe<PkieAcquisAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PkieAcquisAggregateBoolExpBoolOr>;
  count?: InputMaybe<PkieAcquisAggregateBoolExpCount>;
}

export interface PkieAcquisAggregateBoolExpBoolAnd {
  arguments: PkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieAcquisAggregateBoolExpBoolOr {
  arguments: PkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieAcquisAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.acquis" */
export interface PkieAcquisAggregateFields {
  __typename?: 'pkie_acquis_aggregate_fields';
  avg?: Maybe<PkieAcquisAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieAcquisMaxFields>;
  min?: Maybe<PkieAcquisMinFields>;
  stddev?: Maybe<PkieAcquisStddevFields>;
  stddev_pop?: Maybe<PkieAcquisStddevPopFields>;
  stddev_samp?: Maybe<PkieAcquisStddevSampFields>;
  sum?: Maybe<PkieAcquisSumFields>;
  var_pop?: Maybe<PkieAcquisVarPopFields>;
  var_samp?: Maybe<PkieAcquisVarSampFields>;
  variance?: Maybe<PkieAcquisVarianceFields>;
}


/** aggregate fields of "pkie.acquis" */
export interface PkieAcquisAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.acquis" */
export interface PkieAcquisAggregateOrderBy {
  avg?: InputMaybe<PkieAcquisAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieAcquisMaxOrderBy>;
  min?: InputMaybe<PkieAcquisMinOrderBy>;
  stddev?: InputMaybe<PkieAcquisStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieAcquisStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieAcquisStddevSampOrderBy>;
  sum?: InputMaybe<PkieAcquisSumOrderBy>;
  var_pop?: InputMaybe<PkieAcquisVarPopOrderBy>;
  var_samp?: InputMaybe<PkieAcquisVarSampOrderBy>;
  variance?: InputMaybe<PkieAcquisVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.acquis" */
export interface PkieAcquisArrRelInsertInput {
  data: Array<PkieAcquisInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisOnConflict>;
}

/** aggregate avg on columns */
export interface PkieAcquisAvgFields {
  __typename?: 'pkie_acquis_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.acquis" */
export interface PkieAcquisAvgOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.acquis". All fields are combined with a logical 'AND'. */
export interface PkieAcquisBoolExp {
  _and?: InputMaybe<Array<PkieAcquisBoolExp>>;
  _not?: InputMaybe<PkieAcquisBoolExp>;
  _or?: InputMaybe<Array<PkieAcquisBoolExp>>;
  acquis_chapters?: InputMaybe<PkieAcquisChapterBoolExp>;
  acquis_chapters_aggregate?: InputMaybe<PkieAcquisChapterAggregateBoolExp>;
  acquis_institutions?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
  acquis_institutions_aggregate?: InputMaybe<PkieAcquisInstitutionsAggregateBoolExp>;
  acquis_national_acts?: InputMaybe<PkieAcquisNationalActsBoolExp>;
  acquis_national_acts_aggregate?: InputMaybe<PkieAcquisNationalActsAggregateBoolExp>;
  author?: InputMaybe<StringComparisonExp>;
  celex?: InputMaybe<StringComparisonExp>;
  current_consolidated_version?: InputMaybe<StringComparisonExp>;
  date_created?: InputMaybe<DateComparisonExp>;
  date_of_effect?: InputMaybe<StringComparisonExp>;
  date_of_end_of_validity?: InputMaybe<StringComparisonExp>;
  date_of_publication?: InputMaybe<StringComparisonExp>;
  date_of_transposition?: InputMaybe<StringComparisonExp>;
  directory_code?: InputMaybe<StringComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  eli?: InputMaybe<StringComparisonExp>;
  eurovoc_descriptor?: InputMaybe<StringComparisonExp>;
  form?: InputMaybe<StringComparisonExp>;
  in_force_indicator?: InputMaybe<BooleanComparisonExp>;
  index_nr?: InputMaybe<IntComparisonExp>;
  is_aligned?: InputMaybe<BooleanComparisonExp>;
  latest_conslolidated_version?: InputMaybe<StringComparisonExp>;
  latest_modification_notice?: InputMaybe<StringComparisonExp>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
  proces_pune_iagas?: InputMaybe<PkieProcesPuneIagaBoolExp>;
  proces_pune_iagas_aggregate?: InputMaybe<PkieProcesPuneIagaAggregateBoolExp>;
  proces_pune_lgas?: InputMaybe<PkieProcesPuneLgaBoolExp>;
  proces_pune_lgas_aggregate?: InputMaybe<PkieProcesPuneLgaAggregateBoolExp>;
  publication_reference?: InputMaybe<StringComparisonExp>;
  reference?: InputMaybe<StringComparisonExp>;
  subject_matter?: InputMaybe<StringComparisonExp>;
  subtitle?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  to_be_transposed?: InputMaybe<BooleanComparisonExp>;
  to_be_transposed_general_date?: InputMaybe<DateComparisonExp>;
  to_be_transposed_work_plan_task_date?: InputMaybe<DateComparisonExp>;
  type?: InputMaybe<PkieAcquisTypeEnumComparisonExp>;
}

/** handle many to many relationships with acquis and chapter */
export interface PkieAcquisChapter {
  __typename?: 'pkie_acquis_chapter';
  /** An object relationship */
  acqui: PkieAcquis;
  /** An array relationship */
  acquis_work_plan_tasks: Array<PkieAcquisWorkPlanTask>;
  /** An aggregate relationship */
  acquis_work_plan_tasks_aggregate: PkieAcquisWorkPlanTaskAggregate;
  celex: Scalars['String']['output'];
  /** An object relationship */
  chapter: Chapters;
  chapter_id: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}


/** handle many to many relationships with acquis and chapter */
export interface PkieAcquisChapterAcquisWorkPlanTasksArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


/** handle many to many relationships with acquis and chapter */
export interface PkieAcquisChapterAcquisWorkPlanTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


/** handle many to many relationships with acquis and chapter */
export interface PkieAcquisChapterMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** handle many to many relationships with acquis and chapter */
export interface PkieAcquisChapterMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "pkie.acquis_chapter" */
export interface PkieAcquisChapterAggregate {
  __typename?: 'pkie_acquis_chapter_aggregate';
  aggregate?: Maybe<PkieAcquisChapterAggregateFields>;
  nodes: Array<PkieAcquisChapter>;
}

export interface PkieAcquisChapterAggregateBoolExp {
  count?: InputMaybe<PkieAcquisChapterAggregateBoolExpCount>;
}

export interface PkieAcquisChapterAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisChapterBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.acquis_chapter" */
export interface PkieAcquisChapterAggregateFields {
  __typename?: 'pkie_acquis_chapter_aggregate_fields';
  avg?: Maybe<PkieAcquisChapterAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieAcquisChapterMaxFields>;
  min?: Maybe<PkieAcquisChapterMinFields>;
  stddev?: Maybe<PkieAcquisChapterStddevFields>;
  stddev_pop?: Maybe<PkieAcquisChapterStddevPopFields>;
  stddev_samp?: Maybe<PkieAcquisChapterStddevSampFields>;
  sum?: Maybe<PkieAcquisChapterSumFields>;
  var_pop?: Maybe<PkieAcquisChapterVarPopFields>;
  var_samp?: Maybe<PkieAcquisChapterVarSampFields>;
  variance?: Maybe<PkieAcquisChapterVarianceFields>;
}


/** aggregate fields of "pkie.acquis_chapter" */
export interface PkieAcquisChapterAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterAggregateOrderBy {
  avg?: InputMaybe<PkieAcquisChapterAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieAcquisChapterMaxOrderBy>;
  min?: InputMaybe<PkieAcquisChapterMinOrderBy>;
  stddev?: InputMaybe<PkieAcquisChapterStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieAcquisChapterStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieAcquisChapterStddevSampOrderBy>;
  sum?: InputMaybe<PkieAcquisChapterSumOrderBy>;
  var_pop?: InputMaybe<PkieAcquisChapterVarPopOrderBy>;
  var_samp?: InputMaybe<PkieAcquisChapterVarSampOrderBy>;
  variance?: InputMaybe<PkieAcquisChapterVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.acquis_chapter" */
export interface PkieAcquisChapterArrRelInsertInput {
  data: Array<PkieAcquisChapterInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisChapterOnConflict>;
}

/** aggregate avg on columns */
export interface PkieAcquisChapterAvgFields {
  __typename?: 'pkie_acquis_chapter_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.acquis_chapter". All fields are combined with a logical 'AND'. */
export interface PkieAcquisChapterBoolExp {
  _and?: InputMaybe<Array<PkieAcquisChapterBoolExp>>;
  _not?: InputMaybe<PkieAcquisChapterBoolExp>;
  _or?: InputMaybe<Array<PkieAcquisChapterBoolExp>>;
  acqui?: InputMaybe<PkieAcquisBoolExp>;
  acquis_work_plan_tasks?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  acquis_work_plan_tasks_aggregate?: InputMaybe<PkieAcquisWorkPlanTaskAggregateBoolExp>;
  celex?: InputMaybe<StringComparisonExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "pkie.acquis_chapter" */
export enum PkieAcquisChapterConstraint {
  /** unique or primary key constraint on columns "celex", "topic_id", "chapter_id", "sub_chapter_id" */
  AcquisChapterChapterIdCelexSubChapterIdTopicIdKey = 'acquis_chapter_chapter_id_celex_sub_chapter_id_topic_id_key',
  /** unique or primary key constraint on columns "id" */
  AcquisChapterPkey = 'acquis_chapter_pkey'
}

/** input type for incrementing numeric columns in table "pkie.acquis_chapter" */
export interface PkieAcquisChapterIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.acquis_chapter" */
export interface PkieAcquisChapterInsertInput {
  acqui?: InputMaybe<PkieAcquisObjRelInsertInput>;
  acquis_work_plan_tasks?: InputMaybe<PkieAcquisWorkPlanTaskArrRelInsertInput>;
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface PkieAcquisChapterMaxFields {
  __typename?: 'pkie_acquis_chapter_max_fields';
  celex?: Maybe<Scalars['String']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterMaxOrderBy {
  celex?: InputMaybe<OrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieAcquisChapterMinFields {
  __typename?: 'pkie_acquis_chapter_min_fields';
  celex?: Maybe<Scalars['String']['output']>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterMinOrderBy {
  celex?: InputMaybe<OrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.acquis_chapter" */
export interface PkieAcquisChapterMutationResponse {
  __typename?: 'pkie_acquis_chapter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieAcquisChapter>;
}

/** input type for inserting object relation for remote table "pkie.acquis_chapter" */
export interface PkieAcquisChapterObjRelInsertInput {
  data: PkieAcquisChapterInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisChapterOnConflict>;
}

/** on_conflict condition type for table "pkie.acquis_chapter" */
export interface PkieAcquisChapterOnConflict {
  constraint: PkieAcquisChapterConstraint;
  update_columns?: Array<PkieAcquisChapterUpdateColumn>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}

/** Ordering options when selecting data from "pkie.acquis_chapter". */
export interface PkieAcquisChapterOrderBy {
  acqui?: InputMaybe<PkieAcquisOrderBy>;
  acquis_work_plan_tasks_aggregate?: InputMaybe<PkieAcquisWorkPlanTaskAggregateOrderBy>;
  celex?: InputMaybe<OrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.acquis_chapter */
export interface PkieAcquisChapterPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.acquis_chapter" */
export enum PkieAcquisChapterSelectColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id'
}

/** input type for updating data in table "pkie.acquis_chapter" */
export interface PkieAcquisChapterSetInput {
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface PkieAcquisChapterStddevFields {
  __typename?: 'pkie_acquis_chapter_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieAcquisChapterStddevPopFields {
  __typename?: 'pkie_acquis_chapter_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieAcquisChapterStddevSampFields {
  __typename?: 'pkie_acquis_chapter_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_acquis_chapter" */
export interface PkieAcquisChapterStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieAcquisChapterStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieAcquisChapterStreamCursorValueInput {
  celex?: InputMaybe<Scalars['String']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface PkieAcquisChapterSumFields {
  __typename?: 'pkie_acquis_chapter_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** update columns of table "pkie.acquis_chapter" */
export enum PkieAcquisChapterUpdateColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  TopicId = 'topic_id'
}

export interface PkieAcquisChapterUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieAcquisChapterIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieAcquisChapterSetInput>;
  /** filter the rows which have to be updated */
  where: PkieAcquisChapterBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieAcquisChapterVarPopFields {
  __typename?: 'pkie_acquis_chapter_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieAcquisChapterVarSampFields {
  __typename?: 'pkie_acquis_chapter_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieAcquisChapterVarianceFields {
  __typename?: 'pkie_acquis_chapter_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.acquis_chapter" */
export interface PkieAcquisChapterVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** unique or primary key constraints on table "pkie.acquis" */
export enum PkieAcquisConstraint {
  /** unique or primary key constraint on columns "celex" */
  AcquisPkey = 'acquis_pkey'
}

/** input type for incrementing numeric columns in table "pkie.acquis" */
export interface PkieAcquisIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  index_nr?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.acquis" */
export interface PkieAcquisInsertInput {
  acquis_chapters?: InputMaybe<PkieAcquisChapterArrRelInsertInput>;
  acquis_institutions?: InputMaybe<PkieAcquisInstitutionsArrRelInsertInput>;
  acquis_national_acts?: InputMaybe<PkieAcquisNationalActsArrRelInsertInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  current_consolidated_version?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['date']['input']>;
  date_of_effect?: InputMaybe<Scalars['String']['input']>;
  date_of_end_of_validity?: InputMaybe<Scalars['String']['input']>;
  date_of_publication?: InputMaybe<Scalars['String']['input']>;
  date_of_transposition?: InputMaybe<Scalars['String']['input']>;
  directory_code?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  eurovoc_descriptor?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  in_force_indicator?: InputMaybe<Scalars['Boolean']['input']>;
  index_nr?: InputMaybe<Scalars['Int']['input']>;
  is_aligned?: InputMaybe<Scalars['Boolean']['input']>;
  latest_conslolidated_version?: InputMaybe<Scalars['String']['input']>;
  latest_modification_notice?: InputMaybe<Scalars['String']['input']>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<PkieLevelOfAlignmentObjRelInsertInput>;
  proces_pune_iagas?: InputMaybe<PkieProcesPuneIagaArrRelInsertInput>;
  proces_pune_lgas?: InputMaybe<PkieProcesPuneLgaArrRelInsertInput>;
  publication_reference?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  subject_matter?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to_be_transposed?: InputMaybe<Scalars['Boolean']['input']>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: InputMaybe<Scalars['date']['input']>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: InputMaybe<Scalars['date']['input']>;
  type?: InputMaybe<PkieAcquisTypeEnum>;
}

/** handling the info what institutions are contributing to what acqui */
export interface PkieAcquisInstitutions {
  __typename?: 'pkie_acquis_institutions';
  /** An object relationship */
  acqui: PkieAcquis;
  acqui_celex: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  institution: Institutions;
  institution_id: Scalars['uuid']['output'];
}

/** aggregated selection of "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsAggregate {
  __typename?: 'pkie_acquis_institutions_aggregate';
  aggregate?: Maybe<PkieAcquisInstitutionsAggregateFields>;
  nodes: Array<PkieAcquisInstitutions>;
}

export interface PkieAcquisInstitutionsAggregateBoolExp {
  count?: InputMaybe<PkieAcquisInstitutionsAggregateBoolExpCount>;
}

export interface PkieAcquisInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsAggregateFields {
  __typename?: 'pkie_acquis_institutions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieAcquisInstitutionsMaxFields>;
  min?: Maybe<PkieAcquisInstitutionsMinFields>;
}


/** aggregate fields of "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieAcquisInstitutionsMaxOrderBy>;
  min?: InputMaybe<PkieAcquisInstitutionsMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsArrRelInsertInput {
  data: Array<PkieAcquisInstitutionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisInstitutionsOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.acquis_institutions". All fields are combined with a logical 'AND'. */
export interface PkieAcquisInstitutionsBoolExp {
  _and?: InputMaybe<Array<PkieAcquisInstitutionsBoolExp>>;
  _not?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
  _or?: InputMaybe<Array<PkieAcquisInstitutionsBoolExp>>;
  acqui?: InputMaybe<PkieAcquisBoolExp>;
  acqui_celex?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  institution?: InputMaybe<InstitutionsBoolExp>;
  institution_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "pkie.acquis_institutions" */
export enum PkieAcquisInstitutionsConstraint {
  /** unique or primary key constraint on columns "acqui_celex", "institution_id" */
  AcquisInstitutionsInstitutionIdAcquiCelexKey = 'acquis_institutions_institution_id_acqui_celex_key',
  /** unique or primary key constraint on columns "id" */
  AcquisInstitutionsPkey = 'acquis_institutions_pkey'
}

/** input type for inserting data into table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsInsertInput {
  acqui?: InputMaybe<PkieAcquisObjRelInsertInput>;
  acqui_celex?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface PkieAcquisInstitutionsMaxFields {
  __typename?: 'pkie_acquis_institutions_max_fields';
  acqui_celex?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsMaxOrderBy {
  acqui_celex?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieAcquisInstitutionsMinFields {
  __typename?: 'pkie_acquis_institutions_min_fields';
  acqui_celex?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsMinOrderBy {
  acqui_celex?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsMutationResponse {
  __typename?: 'pkie_acquis_institutions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieAcquisInstitutions>;
}

/** on_conflict condition type for table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsOnConflict {
  constraint: PkieAcquisInstitutionsConstraint;
  update_columns?: Array<PkieAcquisInstitutionsUpdateColumn>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}

/** Ordering options when selecting data from "pkie.acquis_institutions". */
export interface PkieAcquisInstitutionsOrderBy {
  acqui?: InputMaybe<PkieAcquisOrderBy>;
  acqui_celex?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution?: InputMaybe<InstitutionsOrderBy>;
  institution_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.acquis_institutions */
export interface PkieAcquisInstitutionsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.acquis_institutions" */
export enum PkieAcquisInstitutionsSelectColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

/** input type for updating data in table "pkie.acquis_institutions" */
export interface PkieAcquisInstitutionsSetInput {
  acqui_celex?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "pkie_acquis_institutions" */
export interface PkieAcquisInstitutionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieAcquisInstitutionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieAcquisInstitutionsStreamCursorValueInput {
  acqui_celex?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "pkie.acquis_institutions" */
export enum PkieAcquisInstitutionsUpdateColumn {
  /** column name */
  AcquiCelex = 'acqui_celex',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id'
}

export interface PkieAcquisInstitutionsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieAcquisInstitutionsSetInput>;
  /** filter the rows which have to be updated */
  where: PkieAcquisInstitutionsBoolExp;
}

/** aggregate max on columns */
export interface PkieAcquisMaxFields {
  __typename?: 'pkie_acquis_max_fields';
  author?: Maybe<Scalars['String']['output']>;
  celex?: Maybe<Scalars['String']['output']>;
  current_consolidated_version?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['date']['output']>;
  date_of_effect?: Maybe<Scalars['String']['output']>;
  date_of_end_of_validity?: Maybe<Scalars['String']['output']>;
  date_of_publication?: Maybe<Scalars['String']['output']>;
  date_of_transposition?: Maybe<Scalars['String']['output']>;
  directory_code?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  eurovoc_descriptor?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Scalars['String']['output']>;
  index_nr?: Maybe<Scalars['Int']['output']>;
  latest_conslolidated_version?: Maybe<Scalars['String']['output']>;
  latest_modification_notice?: Maybe<Scalars['String']['output']>;
  publication_reference?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  subject_matter?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: Maybe<Scalars['date']['output']>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: Maybe<Scalars['date']['output']>;
}

/** order by max() on columns of table "pkie.acquis" */
export interface PkieAcquisMaxOrderBy {
  author?: InputMaybe<OrderBy>;
  celex?: InputMaybe<OrderBy>;
  current_consolidated_version?: InputMaybe<OrderBy>;
  date_created?: InputMaybe<OrderBy>;
  date_of_effect?: InputMaybe<OrderBy>;
  date_of_end_of_validity?: InputMaybe<OrderBy>;
  date_of_publication?: InputMaybe<OrderBy>;
  date_of_transposition?: InputMaybe<OrderBy>;
  directory_code?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  eurovoc_descriptor?: InputMaybe<OrderBy>;
  form?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
  latest_conslolidated_version?: InputMaybe<OrderBy>;
  latest_modification_notice?: InputMaybe<OrderBy>;
  publication_reference?: InputMaybe<OrderBy>;
  reference?: InputMaybe<OrderBy>;
  subject_matter?: InputMaybe<OrderBy>;
  subtitle?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: InputMaybe<OrderBy>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieAcquisMinFields {
  __typename?: 'pkie_acquis_min_fields';
  author?: Maybe<Scalars['String']['output']>;
  celex?: Maybe<Scalars['String']['output']>;
  current_consolidated_version?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['date']['output']>;
  date_of_effect?: Maybe<Scalars['String']['output']>;
  date_of_end_of_validity?: Maybe<Scalars['String']['output']>;
  date_of_publication?: Maybe<Scalars['String']['output']>;
  date_of_transposition?: Maybe<Scalars['String']['output']>;
  directory_code?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  eurovoc_descriptor?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Scalars['String']['output']>;
  index_nr?: Maybe<Scalars['Int']['output']>;
  latest_conslolidated_version?: Maybe<Scalars['String']['output']>;
  latest_modification_notice?: Maybe<Scalars['String']['output']>;
  publication_reference?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  subject_matter?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: Maybe<Scalars['date']['output']>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: Maybe<Scalars['date']['output']>;
}

/** order by min() on columns of table "pkie.acquis" */
export interface PkieAcquisMinOrderBy {
  author?: InputMaybe<OrderBy>;
  celex?: InputMaybe<OrderBy>;
  current_consolidated_version?: InputMaybe<OrderBy>;
  date_created?: InputMaybe<OrderBy>;
  date_of_effect?: InputMaybe<OrderBy>;
  date_of_end_of_validity?: InputMaybe<OrderBy>;
  date_of_publication?: InputMaybe<OrderBy>;
  date_of_transposition?: InputMaybe<OrderBy>;
  directory_code?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  eurovoc_descriptor?: InputMaybe<OrderBy>;
  form?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
  latest_conslolidated_version?: InputMaybe<OrderBy>;
  latest_modification_notice?: InputMaybe<OrderBy>;
  publication_reference?: InputMaybe<OrderBy>;
  reference?: InputMaybe<OrderBy>;
  subject_matter?: InputMaybe<OrderBy>;
  subtitle?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: InputMaybe<OrderBy>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.acquis" */
export interface PkieAcquisMutationResponse {
  __typename?: 'pkie_acquis_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieAcquis>;
}

/** Many to many table connecting the national acts used to transpose a given acquis */
export interface PkieAcquisNationalActs {
  __typename?: 'pkie_acquis_national_acts';
  /** An object relationship */
  acqui: PkieAcquis;
  acquis_celex: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  national_act: PkieNationalActs;
  national_act_eli: Scalars['String']['output'];
}

/** aggregated selection of "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsAggregate {
  __typename?: 'pkie_acquis_national_acts_aggregate';
  aggregate?: Maybe<PkieAcquisNationalActsAggregateFields>;
  nodes: Array<PkieAcquisNationalActs>;
}

export interface PkieAcquisNationalActsAggregateBoolExp {
  count?: InputMaybe<PkieAcquisNationalActsAggregateBoolExpCount>;
}

export interface PkieAcquisNationalActsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisNationalActsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsAggregateFields {
  __typename?: 'pkie_acquis_national_acts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieAcquisNationalActsMaxFields>;
  min?: Maybe<PkieAcquisNationalActsMinFields>;
}


/** aggregate fields of "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieAcquisNationalActsMaxOrderBy>;
  min?: InputMaybe<PkieAcquisNationalActsMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsArrRelInsertInput {
  data: Array<PkieAcquisNationalActsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisNationalActsOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.acquis_national_acts". All fields are combined with a logical 'AND'. */
export interface PkieAcquisNationalActsBoolExp {
  _and?: InputMaybe<Array<PkieAcquisNationalActsBoolExp>>;
  _not?: InputMaybe<PkieAcquisNationalActsBoolExp>;
  _or?: InputMaybe<Array<PkieAcquisNationalActsBoolExp>>;
  acqui?: InputMaybe<PkieAcquisBoolExp>;
  acquis_celex?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  national_act?: InputMaybe<PkieNationalActsBoolExp>;
  national_act_eli?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.acquis_national_acts" */
export enum PkieAcquisNationalActsConstraint {
  /** unique or primary key constraint on columns "national_act_eli", "acquis_celex" */
  AcquisNationalActsNationalActEliAcquisCelexKey = 'acquis_national_acts_national_act_eli_acquis_celex_key',
  /** unique or primary key constraint on columns "id" */
  AcquisNationalActsPkey = 'acquis_national_acts_pkey'
}

/** input type for inserting data into table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsInsertInput {
  acqui?: InputMaybe<PkieAcquisObjRelInsertInput>;
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  national_act?: InputMaybe<PkieNationalActsObjRelInsertInput>;
  national_act_eli?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieAcquisNationalActsMaxFields {
  __typename?: 'pkie_acquis_national_acts_max_fields';
  acquis_celex?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  national_act_eli?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsMaxOrderBy {
  acquis_celex?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  national_act_eli?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieAcquisNationalActsMinFields {
  __typename?: 'pkie_acquis_national_acts_min_fields';
  acquis_celex?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  national_act_eli?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsMinOrderBy {
  acquis_celex?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  national_act_eli?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsMutationResponse {
  __typename?: 'pkie_acquis_national_acts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieAcquisNationalActs>;
}

/** on_conflict condition type for table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsOnConflict {
  constraint: PkieAcquisNationalActsConstraint;
  update_columns?: Array<PkieAcquisNationalActsUpdateColumn>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}

/** Ordering options when selecting data from "pkie.acquis_national_acts". */
export interface PkieAcquisNationalActsOrderBy {
  acqui?: InputMaybe<PkieAcquisOrderBy>;
  acquis_celex?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  national_act?: InputMaybe<PkieNationalActsOrderBy>;
  national_act_eli?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.acquis_national_acts */
export interface PkieAcquisNationalActsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.acquis_national_acts" */
export enum PkieAcquisNationalActsSelectColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

/** input type for updating data in table "pkie.acquis_national_acts" */
export interface PkieAcquisNationalActsSetInput {
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  national_act_eli?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_acquis_national_acts" */
export interface PkieAcquisNationalActsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieAcquisNationalActsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieAcquisNationalActsStreamCursorValueInput {
  acquis_celex?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  national_act_eli?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.acquis_national_acts" */
export enum PkieAcquisNationalActsUpdateColumn {
  /** column name */
  AcquisCelex = 'acquis_celex',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  NationalActEli = 'national_act_eli'
}

export interface PkieAcquisNationalActsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieAcquisNationalActsSetInput>;
  /** filter the rows which have to be updated */
  where: PkieAcquisNationalActsBoolExp;
}

/** input type for inserting object relation for remote table "pkie.acquis" */
export interface PkieAcquisObjRelInsertInput {
  data: PkieAcquisInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisOnConflict>;
}

/** on_conflict condition type for table "pkie.acquis" */
export interface PkieAcquisOnConflict {
  constraint: PkieAcquisConstraint;
  update_columns?: Array<PkieAcquisUpdateColumn>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}

/** Ordering options when selecting data from "pkie.acquis". */
export interface PkieAcquisOrderBy {
  acquis_chapters_aggregate?: InputMaybe<PkieAcquisChapterAggregateOrderBy>;
  acquis_institutions_aggregate?: InputMaybe<PkieAcquisInstitutionsAggregateOrderBy>;
  acquis_national_acts_aggregate?: InputMaybe<PkieAcquisNationalActsAggregateOrderBy>;
  author?: InputMaybe<OrderBy>;
  celex?: InputMaybe<OrderBy>;
  current_consolidated_version?: InputMaybe<OrderBy>;
  date_created?: InputMaybe<OrderBy>;
  date_of_effect?: InputMaybe<OrderBy>;
  date_of_end_of_validity?: InputMaybe<OrderBy>;
  date_of_publication?: InputMaybe<OrderBy>;
  date_of_transposition?: InputMaybe<OrderBy>;
  directory_code?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  eurovoc_descriptor?: InputMaybe<OrderBy>;
  form?: InputMaybe<OrderBy>;
  in_force_indicator?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
  is_aligned?: InputMaybe<OrderBy>;
  latest_conslolidated_version?: InputMaybe<OrderBy>;
  latest_modification_notice?: InputMaybe<OrderBy>;
  level_of_alignment?: InputMaybe<OrderBy>;
  level_of_alignment_status?: InputMaybe<PkieLevelOfAlignmentOrderBy>;
  proces_pune_iagas_aggregate?: InputMaybe<PkieProcesPuneIagaAggregateOrderBy>;
  proces_pune_lgas_aggregate?: InputMaybe<PkieProcesPuneLgaAggregateOrderBy>;
  publication_reference?: InputMaybe<OrderBy>;
  reference?: InputMaybe<OrderBy>;
  subject_matter?: InputMaybe<OrderBy>;
  subtitle?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  to_be_transposed?: InputMaybe<OrderBy>;
  to_be_transposed_general_date?: InputMaybe<OrderBy>;
  to_be_transposed_work_plan_task_date?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.acquis */
export interface PkieAcquisPkColumnsInput {
  celex: Scalars['String']['input'];
}

/** select columns of table "pkie.acquis" */
export enum PkieAcquisSelectColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IndexNr = 'index_nr',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  Type = 'type'
}

/** select "pkie_acquis_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pkie.acquis" */
export enum PkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

/** select "pkie_acquis_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pkie.acquis" */
export enum PkieAcquisSelectColumnPkieAcquisAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  ToBeTransposed = 'to_be_transposed'
}

/** input type for updating data in table "pkie.acquis" */
export interface PkieAcquisSetInput {
  author?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  current_consolidated_version?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['date']['input']>;
  date_of_effect?: InputMaybe<Scalars['String']['input']>;
  date_of_end_of_validity?: InputMaybe<Scalars['String']['input']>;
  date_of_publication?: InputMaybe<Scalars['String']['input']>;
  date_of_transposition?: InputMaybe<Scalars['String']['input']>;
  directory_code?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  eurovoc_descriptor?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  in_force_indicator?: InputMaybe<Scalars['Boolean']['input']>;
  index_nr?: InputMaybe<Scalars['Int']['input']>;
  is_aligned?: InputMaybe<Scalars['Boolean']['input']>;
  latest_conslolidated_version?: InputMaybe<Scalars['String']['input']>;
  latest_modification_notice?: InputMaybe<Scalars['String']['input']>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnum>;
  publication_reference?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  subject_matter?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to_be_transposed?: InputMaybe<Scalars['Boolean']['input']>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: InputMaybe<Scalars['date']['input']>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: InputMaybe<Scalars['date']['input']>;
  type?: InputMaybe<PkieAcquisTypeEnum>;
}

/** aggregate stddev on columns */
export interface PkieAcquisStddevFields {
  __typename?: 'pkie_acquis_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.acquis" */
export interface PkieAcquisStddevOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieAcquisStddevPopFields {
  __typename?: 'pkie_acquis_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.acquis" */
export interface PkieAcquisStddevPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieAcquisStddevSampFields {
  __typename?: 'pkie_acquis_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.acquis" */
export interface PkieAcquisStddevSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_acquis" */
export interface PkieAcquisStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieAcquisStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieAcquisStreamCursorValueInput {
  author?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  current_consolidated_version?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['date']['input']>;
  date_of_effect?: InputMaybe<Scalars['String']['input']>;
  date_of_end_of_validity?: InputMaybe<Scalars['String']['input']>;
  date_of_publication?: InputMaybe<Scalars['String']['input']>;
  date_of_transposition?: InputMaybe<Scalars['String']['input']>;
  directory_code?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  eurovoc_descriptor?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  in_force_indicator?: InputMaybe<Scalars['Boolean']['input']>;
  index_nr?: InputMaybe<Scalars['Int']['input']>;
  is_aligned?: InputMaybe<Scalars['Boolean']['input']>;
  latest_conslolidated_version?: InputMaybe<Scalars['String']['input']>;
  latest_modification_notice?: InputMaybe<Scalars['String']['input']>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnum>;
  publication_reference?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  subject_matter?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to_be_transposed?: InputMaybe<Scalars['Boolean']['input']>;
  /** each acquis can have its deadline for when the Albanian government will be transposed */
  to_be_transposed_general_date?: InputMaybe<Scalars['date']['input']>;
  /** each acquis can have its specific deadline based on the work plan for when the Albanian government will be transposed */
  to_be_transposed_work_plan_task_date?: InputMaybe<Scalars['date']['input']>;
  type?: InputMaybe<PkieAcquisTypeEnum>;
}

/** aggregate sum on columns */
export interface PkieAcquisSumFields {
  __typename?: 'pkie_acquis_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
  index_nr?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.acquis" */
export interface PkieAcquisSumOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** type of acquis */
export interface PkieAcquisType {
  __typename?: 'pkie_acquis_type';
  type: Scalars['String']['output'];
}

/** aggregated selection of "pkie.acquis_type" */
export interface PkieAcquisTypeAggregate {
  __typename?: 'pkie_acquis_type_aggregate';
  aggregate?: Maybe<PkieAcquisTypeAggregateFields>;
  nodes: Array<PkieAcquisType>;
}

/** aggregate fields of "pkie.acquis_type" */
export interface PkieAcquisTypeAggregateFields {
  __typename?: 'pkie_acquis_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieAcquisTypeMaxFields>;
  min?: Maybe<PkieAcquisTypeMinFields>;
}


/** aggregate fields of "pkie.acquis_type" */
export interface PkieAcquisTypeAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieAcquisTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.acquis_type". All fields are combined with a logical 'AND'. */
export interface PkieAcquisTypeBoolExp {
  _and?: InputMaybe<Array<PkieAcquisTypeBoolExp>>;
  _not?: InputMaybe<PkieAcquisTypeBoolExp>;
  _or?: InputMaybe<Array<PkieAcquisTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.acquis_type" */
export enum PkieAcquisTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  AcquisTypePkey = 'acquis_type_pkey'
}

export enum PkieAcquisTypeEnum {
  Hard = 'HARD',
  Soft = 'SOFT'
}

/** Boolean expression to compare columns of type "pkie_acquis_type_enum". All fields are combined with logical 'AND'. */
export interface PkieAcquisTypeEnumComparisonExp {
  _eq?: InputMaybe<PkieAcquisTypeEnum>;
  _in?: InputMaybe<Array<PkieAcquisTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieAcquisTypeEnum>;
  _nin?: InputMaybe<Array<PkieAcquisTypeEnum>>;
}

/** input type for inserting data into table "pkie.acquis_type" */
export interface PkieAcquisTypeInsertInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieAcquisTypeMaxFields {
  __typename?: 'pkie_acquis_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieAcquisTypeMinFields {
  __typename?: 'pkie_acquis_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.acquis_type" */
export interface PkieAcquisTypeMutationResponse {
  __typename?: 'pkie_acquis_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieAcquisType>;
}

/** on_conflict condition type for table "pkie.acquis_type" */
export interface PkieAcquisTypeOnConflict {
  constraint: PkieAcquisTypeConstraint;
  update_columns?: Array<PkieAcquisTypeUpdateColumn>;
  where?: InputMaybe<PkieAcquisTypeBoolExp>;
}

/** Ordering options when selecting data from "pkie.acquis_type". */
export interface PkieAcquisTypeOrderBy {
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.acquis_type */
export interface PkieAcquisTypePkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "pkie.acquis_type" */
export enum PkieAcquisTypeSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "pkie.acquis_type" */
export interface PkieAcquisTypeSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_acquis_type" */
export interface PkieAcquisTypeStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieAcquisTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieAcquisTypeStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.acquis_type" */
export enum PkieAcquisTypeUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface PkieAcquisTypeUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieAcquisTypeSetInput>;
  /** filter the rows which have to be updated */
  where: PkieAcquisTypeBoolExp;
}

/** update columns of table "pkie.acquis" */
export enum PkieAcquisUpdateColumn {
  /** column name */
  Author = 'author',
  /** column name */
  Celex = 'celex',
  /** column name */
  CurrentConsolidatedVersion = 'current_consolidated_version',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateOfEffect = 'date_of_effect',
  /** column name */
  DateOfEndOfValidity = 'date_of_end_of_validity',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  DateOfTransposition = 'date_of_transposition',
  /** column name */
  DirectoryCode = 'directory_code',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  EurovocDescriptor = 'eurovoc_descriptor',
  /** column name */
  Form = 'form',
  /** column name */
  InForceIndicator = 'in_force_indicator',
  /** column name */
  IndexNr = 'index_nr',
  /** column name */
  IsAligned = 'is_aligned',
  /** column name */
  LatestConslolidatedVersion = 'latest_conslolidated_version',
  /** column name */
  LatestModificationNotice = 'latest_modification_notice',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  PublicationReference = 'publication_reference',
  /** column name */
  Reference = 'reference',
  /** column name */
  SubjectMatter = 'subject_matter',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeTransposed = 'to_be_transposed',
  /** column name */
  ToBeTransposedGeneralDate = 'to_be_transposed_general_date',
  /** column name */
  ToBeTransposedWorkPlanTaskDate = 'to_be_transposed_work_plan_task_date',
  /** column name */
  Type = 'type'
}

export interface PkieAcquisUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieAcquisIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieAcquisSetInput>;
  /** filter the rows which have to be updated */
  where: PkieAcquisBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieAcquisVarPopFields {
  __typename?: 'pkie_acquis_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.acquis" */
export interface PkieAcquisVarPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieAcquisVarSampFields {
  __typename?: 'pkie_acquis_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.acquis" */
export interface PkieAcquisVarSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieAcquisVarianceFields {
  __typename?: 'pkie_acquis_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  index_nr?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.acquis" */
export interface PkieAcquisVarianceOrderBy {
  dms_id?: InputMaybe<OrderBy>;
  index_nr?: InputMaybe<OrderBy>;
}

/** many to many table between acquis and work_plan_task */
export interface PkieAcquisWorkPlanTask {
  __typename?: 'pkie_acquis_work_plan_task';
  /** An object relationship */
  acquis_chapter?: Maybe<PkieAcquisChapter>;
  acquis_chapter_id?: Maybe<Scalars['uuid']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  planed?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  work_plan_task: PkieWorkPlanTask;
  work_plan_task_id: Scalars['uuid']['output'];
}


/** many to many table between acquis and work_plan_task */
export interface PkieAcquisWorkPlanTaskMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** many to many table between acquis and work_plan_task */
export interface PkieAcquisWorkPlanTaskMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskAggregate {
  __typename?: 'pkie_acquis_work_plan_task_aggregate';
  aggregate?: Maybe<PkieAcquisWorkPlanTaskAggregateFields>;
  nodes: Array<PkieAcquisWorkPlanTask>;
}

export interface PkieAcquisWorkPlanTaskAggregateBoolExp {
  bool_and?: InputMaybe<PkieAcquisWorkPlanTaskAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PkieAcquisWorkPlanTaskAggregateBoolExpBoolOr>;
  count?: InputMaybe<PkieAcquisWorkPlanTaskAggregateBoolExpCount>;
}

export interface PkieAcquisWorkPlanTaskAggregateBoolExpBoolAnd {
  arguments: PkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieAcquisWorkPlanTaskAggregateBoolExpBoolOr {
  arguments: PkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieAcquisWorkPlanTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskAggregateFields {
  __typename?: 'pkie_acquis_work_plan_task_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieAcquisWorkPlanTaskMaxFields>;
  min?: Maybe<PkieAcquisWorkPlanTaskMinFields>;
}


/** aggregate fields of "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieAcquisWorkPlanTaskMaxOrderBy>;
  min?: InputMaybe<PkieAcquisWorkPlanTaskMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskArrRelInsertInput {
  data: Array<PkieAcquisWorkPlanTaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisWorkPlanTaskOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.acquis_work_plan_task". All fields are combined with a logical 'AND'. */
export interface PkieAcquisWorkPlanTaskBoolExp {
  _and?: InputMaybe<Array<PkieAcquisWorkPlanTaskBoolExp>>;
  _not?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  _or?: InputMaybe<Array<PkieAcquisWorkPlanTaskBoolExp>>;
  acquis_chapter?: InputMaybe<PkieAcquisChapterBoolExp>;
  acquis_chapter_id?: InputMaybe<UuidComparisonExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  planed?: InputMaybe<BooleanComparisonExp>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "pkie.acquis_work_plan_task" */
export enum PkieAcquisWorkPlanTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  AcquisWorkPlanTaskPkey = 'acquis_work_plan_task_pkey',
  /** unique or primary key constraint on columns "work_plan_task_id", "acquis_chapter_id" */
  AcquisWorkPlanTaskWorkPlanTaskIdAcquisChapterIdKey = 'acquis_work_plan_task_work_plan_task_id_acquis_chapter_id_key'
}

/** input type for inserting data into table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskInsertInput {
  acquis_chapter?: InputMaybe<PkieAcquisChapterObjRelInsertInput>;
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  planed?: InputMaybe<Scalars['Boolean']['input']>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface PkieAcquisWorkPlanTaskMaxFields {
  __typename?: 'pkie_acquis_work_plan_task_max_fields';
  acquis_chapter_id?: Maybe<Scalars['uuid']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskMaxOrderBy {
  acquis_chapter_id?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieAcquisWorkPlanTaskMinFields {
  __typename?: 'pkie_acquis_work_plan_task_min_fields';
  acquis_chapter_id?: Maybe<Scalars['uuid']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskMinOrderBy {
  acquis_chapter_id?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskMutationResponse {
  __typename?: 'pkie_acquis_work_plan_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieAcquisWorkPlanTask>;
}

/** input type for inserting object relation for remote table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskObjRelInsertInput {
  data: PkieAcquisWorkPlanTaskInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieAcquisWorkPlanTaskOnConflict>;
}

/** on_conflict condition type for table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskOnConflict {
  constraint: PkieAcquisWorkPlanTaskConstraint;
  update_columns?: Array<PkieAcquisWorkPlanTaskUpdateColumn>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}

/** Ordering options when selecting data from "pkie.acquis_work_plan_task". */
export interface PkieAcquisWorkPlanTaskOrderBy {
  acquis_chapter?: InputMaybe<PkieAcquisChapterOrderBy>;
  acquis_chapter_id?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  planed?: InputMaybe<OrderBy>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskOrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.acquis_work_plan_task */
export interface PkieAcquisWorkPlanTaskPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.acquis_work_plan_task" */
export enum PkieAcquisWorkPlanTaskSelectColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Planed = 'planed',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

/** select "pkie_acquis_work_plan_task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pkie.acquis_work_plan_task" */
export enum PkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Planed = 'planed'
}

/** select "pkie_acquis_work_plan_task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pkie.acquis_work_plan_task" */
export enum PkieAcquisWorkPlanTaskSelectColumnPkieAcquisWorkPlanTaskAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Planed = 'planed'
}

/** input type for updating data in table "pkie.acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskSetInput {
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  planed?: InputMaybe<Scalars['Boolean']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "pkie_acquis_work_plan_task" */
export interface PkieAcquisWorkPlanTaskStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieAcquisWorkPlanTaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieAcquisWorkPlanTaskStreamCursorValueInput {
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  planed?: InputMaybe<Scalars['Boolean']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "pkie.acquis_work_plan_task" */
export enum PkieAcquisWorkPlanTaskUpdateColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Planed = 'planed',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface PkieAcquisWorkPlanTaskUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieAcquisWorkPlanTaskSetInput>;
  /** filter the rows which have to be updated */
  where: PkieAcquisWorkPlanTaskBoolExp;
}

/** columns and relationships of "pkie.act_type" */
export interface PkieActType {
  __typename?: 'pkie_act_type';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

/** aggregated selection of "pkie.act_type" */
export interface PkieActTypeAggregate {
  __typename?: 'pkie_act_type_aggregate';
  aggregate?: Maybe<PkieActTypeAggregateFields>;
  nodes: Array<PkieActType>;
}

/** aggregate fields of "pkie.act_type" */
export interface PkieActTypeAggregateFields {
  __typename?: 'pkie_act_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieActTypeMaxFields>;
  min?: Maybe<PkieActTypeMinFields>;
}


/** aggregate fields of "pkie.act_type" */
export interface PkieActTypeAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieActTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.act_type". All fields are combined with a logical 'AND'. */
export interface PkieActTypeBoolExp {
  _and?: InputMaybe<Array<PkieActTypeBoolExp>>;
  _not?: InputMaybe<PkieActTypeBoolExp>;
  _or?: InputMaybe<Array<PkieActTypeBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.act_type" */
export enum PkieActTypeConstraint {
  /** unique or primary key constraint on columns "code" */
  ActTypePkey = 'act_type_pkey'
}

export enum PkieActTypeEnum {
  /** Akt normativ */
  AktNormativ = 'akt_normativ',
  /** Autorizim */
  Autorizim = 'autorizim',
  /** Bibliografi */
  Bibliografi = 'bibliografi',
  /** Deklaratë */
  Deklarate = 'deklarate',
  /** Dekret */
  Dekret = 'dekret',
  /** Kërkesë */
  Kerkese = 'kerkese',
  /** Konventë */
  Konvente = 'konvente',
  /** Licensë */
  Licenca = 'licenca',
  /** Ligj */
  Ligj = 'ligj',
  /** Marrëveshje */
  Marreveshje = 'marreveshje',
  /** Memorandum */
  Memorandum = 'memorandum',
  /** Njoftim */
  Njoftim = 'njoftim',
  /** Rezolutë */
  Rezolute = 'rezolute',
  /** Rregullore */
  Rregullore = 'rregullore',
  /** Udhezim */
  Udhezim = 'udhezim',
  /** Urdhër */
  Urdher = 'urdher',
  /** Vendim */
  Vendim = 'vendim',
  /** Vendim i shkurtuar */
  VendimIShkurtuar = 'vendim_i_shkurtuar'
}

/** Boolean expression to compare columns of type "pkie_act_type_enum". All fields are combined with logical 'AND'. */
export interface PkieActTypeEnumComparisonExp {
  _eq?: InputMaybe<PkieActTypeEnum>;
  _in?: InputMaybe<Array<PkieActTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieActTypeEnum>;
  _nin?: InputMaybe<Array<PkieActTypeEnum>>;
}

/** input type for inserting data into table "pkie.act_type" */
export interface PkieActTypeInsertInput {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieActTypeMaxFields {
  __typename?: 'pkie_act_type_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieActTypeMinFields {
  __typename?: 'pkie_act_type_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.act_type" */
export interface PkieActTypeMutationResponse {
  __typename?: 'pkie_act_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieActType>;
}

/** input type for inserting object relation for remote table "pkie.act_type" */
export interface PkieActTypeObjRelInsertInput {
  data: PkieActTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieActTypeOnConflict>;
}

/** on_conflict condition type for table "pkie.act_type" */
export interface PkieActTypeOnConflict {
  constraint: PkieActTypeConstraint;
  update_columns?: Array<PkieActTypeUpdateColumn>;
  where?: InputMaybe<PkieActTypeBoolExp>;
}

/** Ordering options when selecting data from "pkie.act_type". */
export interface PkieActTypeOrderBy {
  code?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.act_type */
export interface PkieActTypePkColumnsInput {
  code: Scalars['String']['input'];
}

/** select columns of table "pkie.act_type" */
export enum PkieActTypeSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "pkie.act_type" */
export interface PkieActTypeSetInput {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_act_type" */
export interface PkieActTypeStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieActTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieActTypeStreamCursorValueInput {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.act_type" */
export enum PkieActTypeUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

export interface PkieActTypeUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieActTypeSetInput>;
  /** filter the rows which have to be updated */
  where: PkieActTypeBoolExp;
}

/** level of alignment for acquis */
export interface PkieLevelOfAlignment {
  __typename?: 'pkie_level_of_alignment';
  /** An array relationship */
  acquis: Array<PkieAcquis>;
  /** An aggregate relationship */
  acquis_aggregate: PkieAcquisAggregate;
  level: Scalars['String']['output'];
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
}


/** level of alignment for acquis */
export interface PkieLevelOfAlignmentAcquisArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisOrderBy>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


/** level of alignment for acquis */
export interface PkieLevelOfAlignmentAcquisAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisOrderBy>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


/** level of alignment for acquis */
export interface PkieLevelOfAlignmentMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** level of alignment for acquis */
export interface PkieLevelOfAlignmentMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentAggregate {
  __typename?: 'pkie_level_of_alignment_aggregate';
  aggregate?: Maybe<PkieLevelOfAlignmentAggregateFields>;
  nodes: Array<PkieLevelOfAlignment>;
}

/** aggregate fields of "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentAggregateFields {
  __typename?: 'pkie_level_of_alignment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieLevelOfAlignmentMaxFields>;
  min?: Maybe<PkieLevelOfAlignmentMinFields>;
}


/** aggregate fields of "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieLevelOfAlignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.level_of_alignment". All fields are combined with a logical 'AND'. */
export interface PkieLevelOfAlignmentBoolExp {
  _and?: InputMaybe<Array<PkieLevelOfAlignmentBoolExp>>;
  _not?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
  _or?: InputMaybe<Array<PkieLevelOfAlignmentBoolExp>>;
  acquis?: InputMaybe<PkieAcquisBoolExp>;
  acquis_aggregate?: InputMaybe<PkieAcquisAggregateBoolExp>;
  level?: InputMaybe<StringComparisonExp>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
}

/** unique or primary key constraints on table "pkie.level_of_alignment" */
export enum PkieLevelOfAlignmentConstraint {
  /** unique or primary key constraint on columns "level" */
  LevelOfAlignmentPkey = 'level_of_alignment_pkey'
}

export enum PkieLevelOfAlignmentEnum {
  ELarte = 'E_LARTE',
  EMire = 'E_MIRE',
  EModeruar = 'E_MODERUAR',
  EPlote = 'E_PLOTE',
  Fillestare = 'FILLESTARE',
  Na = 'NA'
}

/** Boolean expression to compare columns of type "pkie_level_of_alignment_enum". All fields are combined with logical 'AND'. */
export interface PkieLevelOfAlignmentEnumComparisonExp {
  _eq?: InputMaybe<PkieLevelOfAlignmentEnum>;
  _in?: InputMaybe<Array<PkieLevelOfAlignmentEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieLevelOfAlignmentEnum>;
  _nin?: InputMaybe<Array<PkieLevelOfAlignmentEnum>>;
}

/** input type for inserting data into table "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentInsertInput {
  acquis?: InputMaybe<PkieAcquisArrRelInsertInput>;
  level?: InputMaybe<Scalars['String']['input']>;
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
}

/** aggregate max on columns */
export interface PkieLevelOfAlignmentMaxFields {
  __typename?: 'pkie_level_of_alignment_max_fields';
  level?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieLevelOfAlignmentMinFields {
  __typename?: 'pkie_level_of_alignment_min_fields';
  level?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentMutationResponse {
  __typename?: 'pkie_level_of_alignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieLevelOfAlignment>;
}

/** input type for inserting object relation for remote table "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentObjRelInsertInput {
  data: PkieLevelOfAlignmentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieLevelOfAlignmentOnConflict>;
}

/** on_conflict condition type for table "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentOnConflict {
  constraint: PkieLevelOfAlignmentConstraint;
  update_columns?: Array<PkieLevelOfAlignmentUpdateColumn>;
  where?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
}

/** Ordering options when selecting data from "pkie.level_of_alignment". */
export interface PkieLevelOfAlignmentOrderBy {
  acquis_aggregate?: InputMaybe<PkieAcquisAggregateOrderBy>;
  level?: InputMaybe<OrderBy>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
}

/** primary key columns input for table: pkie.level_of_alignment */
export interface PkieLevelOfAlignmentPkColumnsInput {
  level: Scalars['String']['input'];
}

/** select columns of table "pkie.level_of_alignment" */
export enum PkieLevelOfAlignmentSelectColumn {
  /** column name */
  Level = 'level'
}

/** input type for updating data in table "pkie.level_of_alignment" */
export interface PkieLevelOfAlignmentSetInput {
  level?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_level_of_alignment" */
export interface PkieLevelOfAlignmentStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieLevelOfAlignmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieLevelOfAlignmentStreamCursorValueInput {
  level?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.level_of_alignment" */
export enum PkieLevelOfAlignmentUpdateColumn {
  /** column name */
  Level = 'level'
}

export interface PkieLevelOfAlignmentUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieLevelOfAlignmentSetInput>;
  /** filter the rows which have to be updated */
  where: PkieLevelOfAlignmentBoolExp;
}

/** measure created for transposing acquis */
export interface PkieMeasure {
  __typename?: 'pkie_measure';
  /** An object relationship */
  acquis_chapter?: Maybe<PkieAcquisChapter>;
  acquis_chapter_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  acquis_work_plan_task?: Maybe<PkieAcquisWorkPlanTask>;
  acquis_work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chapter_coordinator?: Maybe<Users>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chief_gnpie_secretariat?: Maybe<Users>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  crated_by_user_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  gnpie_member?: Maybe<Users>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_secretariat?: Maybe<Users>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  impact?: Maybe<Scalars['String']['output']>;
  is_completed?: Maybe<Scalars['Boolean']['output']>;
  level_of_alignment?: Maybe<PkieLevelOfAlignmentEnum>;
  /** An object relationship */
  level_of_alignment_status?: Maybe<PkieLevelOfAlignment>;
  /** An array relationship */
  measure_activities: Array<PkieMeasureActivity>;
  /** An aggregate relationship */
  measure_activities_aggregate: PkieMeasureActivityAggregate;
  /** An array relationship */
  measure_answers: Array<PkieMeasureAnswers>;
  /** An aggregate relationship */
  measure_answers_aggregate: PkieMeasureAnswersAggregate;
  /** An array relationship */
  measure_bookmarks: Array<PkieMeasureBookmark>;
  /** An aggregate relationship */
  measure_bookmarks_aggregate: PkieMeasureBookmarkAggregate;
  /** An array relationship */
  measure_budgets: Array<PkieMeasureBudgets>;
  /** An aggregate relationship */
  measure_budgets_aggregate: PkieMeasureBudgetsAggregate;
  /** An array relationship */
  measure_comments: Array<PkieMeasureComment>;
  /** An aggregate relationship */
  measure_comments_aggregate: PkieMeasureCommentAggregate;
  /** An array relationship */
  measure_gnpie_members: Array<PkieMeasureGnpieMembers>;
  /** An aggregate relationship */
  measure_gnpie_members_aggregate: PkieMeasureGnpieMembersAggregate;
  /** An array relationship */
  measure_roadmaps: Array<PkieMeasureRoadmap>;
  /** An aggregate relationship */
  measure_roadmaps_aggregate: PkieMeasureRoadmapAggregate;
  /** An object relationship */
  measure_status?: Maybe<PkieMeasureStatus>;
  /** An object relationship */
  national_act?: Maybe<PkieNationalActs>;
  /** An object relationship */
  negotiator?: Maybe<Users>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  proposing_institution?: Maybe<Institutions>;
  proposing_institution_id?: Maybe<Scalars['uuid']['output']>;
  quadrennial_scheduled_approval?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "measure_scheduled_approval_date" */
  scheduled_approval_date?: Maybe<Scalars['date']['output']>;
  status?: Maybe<PkieMeasureStatusEnum>;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  type: PkieTypeMeasureEnum;
  /** An object relationship */
  type_alignment_act?: Maybe<PkieTypeAlignmentAct>;
  type_of_alignment?: Maybe<PkieTypeAlignmentActEnum>;
  updated_at: Scalars['timestamptz']['output'];
  weight_transposition?: Maybe<Scalars['Int']['output']>;
  year_of_completition?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  yearly_quarter?: Maybe<YearlyQuarters>;
}


/** measure created for transposing acquis */
export interface PkieMeasureDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureActivitiesArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureActivityOrderBy>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureActivitiesAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureActivityOrderBy>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureAnswersArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureAnswersOrderBy>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureAnswersOrderBy>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureBookmarksArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureBudgetsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureBudgetsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureCommentsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureCommentOrderBy>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureCommentOrderBy>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureGnpieMembersOrderBy>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureGnpieMembersOrderBy>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureRoadmapsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


/** measure created for transposing acquis */
export interface PkieMeasureMeasureRoadmapsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}

/** keeps logs of measure's activity */
export interface PkieMeasureActivity {
  __typename?: 'pkie_measure_activity';
  activity: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure: PkieMeasure;
  measure_id: Scalars['uuid']['output'];
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "pkie.measure_activity" */
export interface PkieMeasureActivityAggregate {
  __typename?: 'pkie_measure_activity_aggregate';
  aggregate?: Maybe<PkieMeasureActivityAggregateFields>;
  nodes: Array<PkieMeasureActivity>;
}

export interface PkieMeasureActivityAggregateBoolExp {
  count?: InputMaybe<PkieMeasureActivityAggregateBoolExpCount>;
}

export interface PkieMeasureActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureActivityBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_activity" */
export interface PkieMeasureActivityAggregateFields {
  __typename?: 'pkie_measure_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureActivityMaxFields>;
  min?: Maybe<PkieMeasureActivityMinFields>;
}


/** aggregate fields of "pkie.measure_activity" */
export interface PkieMeasureActivityAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_activity" */
export interface PkieMeasureActivityAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureActivityMaxOrderBy>;
  min?: InputMaybe<PkieMeasureActivityMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.measure_activity" */
export interface PkieMeasureActivityArrRelInsertInput {
  data: Array<PkieMeasureActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureActivityOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.measure_activity". All fields are combined with a logical 'AND'. */
export interface PkieMeasureActivityBoolExp {
  _and?: InputMaybe<Array<PkieMeasureActivityBoolExp>>;
  _not?: InputMaybe<PkieMeasureActivityBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureActivityBoolExp>>;
  activity?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_activity" */
export enum PkieMeasureActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureActivityPkey = 'measure_activity_pkey'
}

/** input type for inserting data into table "pkie.measure_activity" */
export interface PkieMeasureActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureActivityMaxFields {
  __typename?: 'pkie_measure_activity_max_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "pkie.measure_activity" */
export interface PkieMeasureActivityMaxOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureActivityMinFields {
  __typename?: 'pkie_measure_activity_min_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "pkie.measure_activity" */
export interface PkieMeasureActivityMinOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_activity" */
export interface PkieMeasureActivityMutationResponse {
  __typename?: 'pkie_measure_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureActivity>;
}

/** on_conflict condition type for table "pkie.measure_activity" */
export interface PkieMeasureActivityOnConflict {
  constraint: PkieMeasureActivityConstraint;
  update_columns?: Array<PkieMeasureActivityUpdateColumn>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_activity". */
export interface PkieMeasureActivityOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_activity */
export interface PkieMeasureActivityPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.measure_activity" */
export enum PkieMeasureActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "pkie.measure_activity" */
export interface PkieMeasureActivitySetInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_measure_activity" */
export interface PkieMeasureActivityStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureActivityStreamCursorValueInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.measure_activity" */
export enum PkieMeasureActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status'
}

export interface PkieMeasureActivityUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureActivitySetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureActivityBoolExp;
}

/** aggregated selection of "pkie.measure" */
export interface PkieMeasureAggregate {
  __typename?: 'pkie_measure_aggregate';
  aggregate?: Maybe<PkieMeasureAggregateFields>;
  nodes: Array<PkieMeasure>;
}

export interface PkieMeasureAggregateBoolExp {
  bool_and?: InputMaybe<PkieMeasureAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PkieMeasureAggregateBoolExpBoolOr>;
  count?: InputMaybe<PkieMeasureAggregateBoolExpCount>;
}

export interface PkieMeasureAggregateBoolExpBoolAnd {
  arguments: PkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieMeasureAggregateBoolExpBoolOr {
  arguments: PkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieMeasureAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure" */
export interface PkieMeasureAggregateFields {
  __typename?: 'pkie_measure_aggregate_fields';
  avg?: Maybe<PkieMeasureAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureMaxFields>;
  min?: Maybe<PkieMeasureMinFields>;
  stddev?: Maybe<PkieMeasureStddevFields>;
  stddev_pop?: Maybe<PkieMeasureStddevPopFields>;
  stddev_samp?: Maybe<PkieMeasureStddevSampFields>;
  sum?: Maybe<PkieMeasureSumFields>;
  var_pop?: Maybe<PkieMeasureVarPopFields>;
  var_samp?: Maybe<PkieMeasureVarSampFields>;
  variance?: Maybe<PkieMeasureVarianceFields>;
}


/** aggregate fields of "pkie.measure" */
export interface PkieMeasureAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure" */
export interface PkieMeasureAggregateOrderBy {
  avg?: InputMaybe<PkieMeasureAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureMaxOrderBy>;
  min?: InputMaybe<PkieMeasureMinOrderBy>;
  stddev?: InputMaybe<PkieMeasureStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieMeasureStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieMeasureStddevSampOrderBy>;
  sum?: InputMaybe<PkieMeasureSumOrderBy>;
  var_pop?: InputMaybe<PkieMeasureVarPopOrderBy>;
  var_samp?: InputMaybe<PkieMeasureVarSampOrderBy>;
  variance?: InputMaybe<PkieMeasureVarianceOrderBy>;
}

/** contains answers for measures */
export interface PkieMeasureAnswers {
  __typename?: 'pkie_measure_answers';
  answer?: Maybe<Scalars['String']['output']>;
  answered_by_user_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure: PkieMeasure;
  measure_id: Scalars['uuid']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
}


/** contains answers for measures */
export interface PkieMeasureAnswersDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "pkie.measure_answers" */
export interface PkieMeasureAnswersAggregate {
  __typename?: 'pkie_measure_answers_aggregate';
  aggregate?: Maybe<PkieMeasureAnswersAggregateFields>;
  nodes: Array<PkieMeasureAnswers>;
}

export interface PkieMeasureAnswersAggregateBoolExp {
  count?: InputMaybe<PkieMeasureAnswersAggregateBoolExpCount>;
}

export interface PkieMeasureAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureAnswersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_answers" */
export interface PkieMeasureAnswersAggregateFields {
  __typename?: 'pkie_measure_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureAnswersMaxFields>;
  min?: Maybe<PkieMeasureAnswersMinFields>;
}


/** aggregate fields of "pkie.measure_answers" */
export interface PkieMeasureAnswersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_answers" */
export interface PkieMeasureAnswersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureAnswersMaxOrderBy>;
  min?: InputMaybe<PkieMeasureAnswersMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface PkieMeasureAnswersAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "pkie.measure_answers" */
export interface PkieMeasureAnswersArrRelInsertInput {
  data: Array<PkieMeasureAnswersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureAnswersOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.measure_answers". All fields are combined with a logical 'AND'. */
export interface PkieMeasureAnswersBoolExp {
  _and?: InputMaybe<Array<PkieMeasureAnswersBoolExp>>;
  _not?: InputMaybe<PkieMeasureAnswersBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureAnswersBoolExp>>;
  answer?: InputMaybe<StringComparisonExp>;
  answered_by_user_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_answers" */
export enum PkieMeasureAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureAnswersPkey = 'measure_answers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface PkieMeasureAnswersDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface PkieMeasureAnswersDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface PkieMeasureAnswersDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "pkie.measure_answers" */
export interface PkieMeasureAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureAnswersMaxFields {
  __typename?: 'pkie_measure_answers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answered_by_user_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "pkie.measure_answers" */
export interface PkieMeasureAnswersMaxOrderBy {
  answer?: InputMaybe<OrderBy>;
  answered_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureAnswersMinFields {
  __typename?: 'pkie_measure_answers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answered_by_user_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "pkie.measure_answers" */
export interface PkieMeasureAnswersMinOrderBy {
  answer?: InputMaybe<OrderBy>;
  answered_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_answers" */
export interface PkieMeasureAnswersMutationResponse {
  __typename?: 'pkie_measure_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureAnswers>;
}

/** on_conflict condition type for table "pkie.measure_answers" */
export interface PkieMeasureAnswersOnConflict {
  constraint: PkieMeasureAnswersConstraint;
  update_columns?: Array<PkieMeasureAnswersUpdateColumn>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_answers". */
export interface PkieMeasureAnswersOrderBy {
  answer?: InputMaybe<OrderBy>;
  answered_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_answers */
export interface PkieMeasureAnswersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface PkieMeasureAnswersPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "pkie.measure_answers" */
export enum PkieMeasureAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pkie.measure_answers" */
export interface PkieMeasureAnswersSetInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "pkie_measure_answers" */
export interface PkieMeasureAnswersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureAnswersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureAnswersStreamCursorValueInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "pkie.measure_answers" */
export enum PkieMeasureAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface PkieMeasureAnswersUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PkieMeasureAnswersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PkieMeasureAnswersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PkieMeasureAnswersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PkieMeasureAnswersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PkieMeasureAnswersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureAnswersSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureAnswersBoolExp;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface PkieMeasureAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "pkie.measure" */
export interface PkieMeasureArrRelInsertInput {
  data: Array<PkieMeasureInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureOnConflict>;
}

/** aggregate avg on columns */
export interface PkieMeasureAvgFields {
  __typename?: 'pkie_measure_avg_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.measure" */
export interface PkieMeasureAvgOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** many to many between measure and bookmark */
export interface PkieMeasureBookmark {
  __typename?: 'pkie_measure_bookmark';
  /** An object relationship */
  bookmark?: Maybe<Bookmarks>;
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure: PkieMeasure;
  measure_id: Scalars['uuid']['output'];
}

/** aggregated selection of "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkAggregate {
  __typename?: 'pkie_measure_bookmark_aggregate';
  aggregate?: Maybe<PkieMeasureBookmarkAggregateFields>;
  nodes: Array<PkieMeasureBookmark>;
}

export interface PkieMeasureBookmarkAggregateBoolExp {
  count?: InputMaybe<PkieMeasureBookmarkAggregateBoolExpCount>;
}

export interface PkieMeasureBookmarkAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBookmarkBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkAggregateFields {
  __typename?: 'pkie_measure_bookmark_aggregate_fields';
  avg?: Maybe<PkieMeasureBookmarkAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureBookmarkMaxFields>;
  min?: Maybe<PkieMeasureBookmarkMinFields>;
  stddev?: Maybe<PkieMeasureBookmarkStddevFields>;
  stddev_pop?: Maybe<PkieMeasureBookmarkStddevPopFields>;
  stddev_samp?: Maybe<PkieMeasureBookmarkStddevSampFields>;
  sum?: Maybe<PkieMeasureBookmarkSumFields>;
  var_pop?: Maybe<PkieMeasureBookmarkVarPopFields>;
  var_samp?: Maybe<PkieMeasureBookmarkVarSampFields>;
  variance?: Maybe<PkieMeasureBookmarkVarianceFields>;
}


/** aggregate fields of "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkAggregateOrderBy {
  avg?: InputMaybe<PkieMeasureBookmarkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureBookmarkMaxOrderBy>;
  min?: InputMaybe<PkieMeasureBookmarkMinOrderBy>;
  stddev?: InputMaybe<PkieMeasureBookmarkStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieMeasureBookmarkStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieMeasureBookmarkStddevSampOrderBy>;
  sum?: InputMaybe<PkieMeasureBookmarkSumOrderBy>;
  var_pop?: InputMaybe<PkieMeasureBookmarkVarPopOrderBy>;
  var_samp?: InputMaybe<PkieMeasureBookmarkVarSampOrderBy>;
  variance?: InputMaybe<PkieMeasureBookmarkVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkArrRelInsertInput {
  data: Array<PkieMeasureBookmarkInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureBookmarkOnConflict>;
}

/** aggregate avg on columns */
export interface PkieMeasureBookmarkAvgFields {
  __typename?: 'pkie_measure_bookmark_avg_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkAvgOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.measure_bookmark". All fields are combined with a logical 'AND'. */
export interface PkieMeasureBookmarkBoolExp {
  _and?: InputMaybe<Array<PkieMeasureBookmarkBoolExp>>;
  _not?: InputMaybe<PkieMeasureBookmarkBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureBookmarkBoolExp>>;
  bookmark?: InputMaybe<BookmarksBoolExp>;
  bookmark_id?: InputMaybe<IntComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_bookmark" */
export enum PkieMeasureBookmarkConstraint {
  /** unique or primary key constraint on columns "measure_id", "bookmark_id" */
  MeasureBookmarkMeasureIdBookmarkIdKey = 'measure_bookmark_measure_id_bookmark_id_key',
  /** unique or primary key constraint on columns "id" */
  MeasureBookmarkPkey = 'measure_bookmark_pkey'
}

/** input type for incrementing numeric columns in table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkIncInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkInsertInput {
  bookmark?: InputMaybe<BookmarksObjRelInsertInput>;
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureBookmarkMaxFields {
  __typename?: 'pkie_measure_bookmark_max_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkMaxOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureBookmarkMinFields {
  __typename?: 'pkie_measure_bookmark_min_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkMinOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkMutationResponse {
  __typename?: 'pkie_measure_bookmark_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureBookmark>;
}

/** on_conflict condition type for table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkOnConflict {
  constraint: PkieMeasureBookmarkConstraint;
  update_columns?: Array<PkieMeasureBookmarkUpdateColumn>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_bookmark". */
export interface PkieMeasureBookmarkOrderBy {
  bookmark?: InputMaybe<BookmarksOrderBy>;
  bookmark_id?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_bookmark */
export interface PkieMeasureBookmarkPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.measure_bookmark" */
export enum PkieMeasureBookmarkSelectColumn {
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

/** input type for updating data in table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkSetInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface PkieMeasureBookmarkStddevFields {
  __typename?: 'pkie_measure_bookmark_stddev_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkStddevOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieMeasureBookmarkStddevPopFields {
  __typename?: 'pkie_measure_bookmark_stddev_pop_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkStddevPopOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieMeasureBookmarkStddevSampFields {
  __typename?: 'pkie_measure_bookmark_stddev_samp_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkStddevSampOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_measure_bookmark" */
export interface PkieMeasureBookmarkStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureBookmarkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureBookmarkStreamCursorValueInput {
  bookmark_id?: InputMaybe<Scalars['Int']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface PkieMeasureBookmarkSumFields {
  __typename?: 'pkie_measure_bookmark_sum_fields';
  bookmark_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkSumOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** update columns of table "pkie.measure_bookmark" */
export enum PkieMeasureBookmarkUpdateColumn {
  /** column name */
  BookmarkId = 'bookmark_id',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id'
}

export interface PkieMeasureBookmarkUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieMeasureBookmarkIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureBookmarkSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureBookmarkBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieMeasureBookmarkVarPopFields {
  __typename?: 'pkie_measure_bookmark_var_pop_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkVarPopOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieMeasureBookmarkVarSampFields {
  __typename?: 'pkie_measure_bookmark_var_samp_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkVarSampOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieMeasureBookmarkVarianceFields {
  __typename?: 'pkie_measure_bookmark_variance_fields';
  bookmark_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.measure_bookmark" */
export interface PkieMeasureBookmarkVarianceOrderBy {
  bookmark_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.measure". All fields are combined with a logical 'AND'. */
export interface PkieMeasureBoolExp {
  _and?: InputMaybe<Array<PkieMeasureBoolExp>>;
  _not?: InputMaybe<PkieMeasureBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureBoolExp>>;
  acquis_chapter?: InputMaybe<PkieAcquisChapterBoolExp>;
  acquis_chapter_id?: InputMaybe<UuidComparisonExp>;
  acquis_work_plan_task?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  acquis_work_plan_task_id?: InputMaybe<UuidComparisonExp>;
  chapter_coordinator?: InputMaybe<UsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<UuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  crated_by_user_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  eli?: InputMaybe<StringComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impact?: InputMaybe<StringComparisonExp>;
  is_completed?: InputMaybe<BooleanComparisonExp>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnumComparisonExp>;
  level_of_alignment_status?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
  measure_activities?: InputMaybe<PkieMeasureActivityBoolExp>;
  measure_activities_aggregate?: InputMaybe<PkieMeasureActivityAggregateBoolExp>;
  measure_answers?: InputMaybe<PkieMeasureAnswersBoolExp>;
  measure_answers_aggregate?: InputMaybe<PkieMeasureAnswersAggregateBoolExp>;
  measure_bookmarks?: InputMaybe<PkieMeasureBookmarkBoolExp>;
  measure_bookmarks_aggregate?: InputMaybe<PkieMeasureBookmarkAggregateBoolExp>;
  measure_budgets?: InputMaybe<PkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<PkieMeasureBudgetsAggregateBoolExp>;
  measure_comments?: InputMaybe<PkieMeasureCommentBoolExp>;
  measure_comments_aggregate?: InputMaybe<PkieMeasureCommentAggregateBoolExp>;
  measure_gnpie_members?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
  measure_gnpie_members_aggregate?: InputMaybe<PkieMeasureGnpieMembersAggregateBoolExp>;
  measure_roadmaps?: InputMaybe<PkieMeasureRoadmapBoolExp>;
  measure_roadmaps_aggregate?: InputMaybe<PkieMeasureRoadmapAggregateBoolExp>;
  measure_status?: InputMaybe<PkieMeasureStatusBoolExp>;
  national_act?: InputMaybe<PkieNationalActsBoolExp>;
  negotiator?: InputMaybe<UsersBoolExp>;
  negotiator_id?: InputMaybe<UuidComparisonExp>;
  proposing_institution?: InputMaybe<InstitutionsBoolExp>;
  proposing_institution_id?: InputMaybe<UuidComparisonExp>;
  quadrennial_scheduled_approval?: InputMaybe<StringComparisonExp>;
  scheduled_approval_date?: InputMaybe<DateComparisonExp>;
  status?: InputMaybe<PkieMeasureStatusEnumComparisonExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<PkieTypeMeasureEnumComparisonExp>;
  type_alignment_act?: InputMaybe<PkieTypeAlignmentActBoolExp>;
  type_of_alignment?: InputMaybe<PkieTypeAlignmentActEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  weight_transposition?: InputMaybe<IntComparisonExp>;
  year_of_completition?: InputMaybe<IntComparisonExp>;
  yearly_quarter?: InputMaybe<YearlyQuartersBoolExp>;
}

/** budgets for measures */
export interface PkieMeasureBudgets {
  __typename?: 'pkie_measure_budgets';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  measure?: Maybe<PkieMeasure>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  source_of_funding?: Maybe<PkieSourceOfFundingEnum>;
  /** An object relationship */
  type_budget?: Maybe<PkieTypeMeasureBudget>;
  type_of_budget?: Maybe<PkieTypeMeasureBudgetEnum>;
  updated_at: Scalars['timestamptz']['output'];
  value?: Maybe<Scalars['numeric']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
}

/** aggregated selection of "pkie.measure_budgets" */
export interface PkieMeasureBudgetsAggregate {
  __typename?: 'pkie_measure_budgets_aggregate';
  aggregate?: Maybe<PkieMeasureBudgetsAggregateFields>;
  nodes: Array<PkieMeasureBudgets>;
}

export interface PkieMeasureBudgetsAggregateBoolExp {
  bool_and?: InputMaybe<PkieMeasureBudgetsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PkieMeasureBudgetsAggregateBoolExpBoolOr>;
  count?: InputMaybe<PkieMeasureBudgetsAggregateBoolExpCount>;
}

export interface PkieMeasureBudgetsAggregateBoolExpBoolAnd {
  arguments: PkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBudgetsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieMeasureBudgetsAggregateBoolExpBoolOr {
  arguments: PkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBudgetsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieMeasureBudgetsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureBudgetsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_budgets" */
export interface PkieMeasureBudgetsAggregateFields {
  __typename?: 'pkie_measure_budgets_aggregate_fields';
  avg?: Maybe<PkieMeasureBudgetsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureBudgetsMaxFields>;
  min?: Maybe<PkieMeasureBudgetsMinFields>;
  stddev?: Maybe<PkieMeasureBudgetsStddevFields>;
  stddev_pop?: Maybe<PkieMeasureBudgetsStddevPopFields>;
  stddev_samp?: Maybe<PkieMeasureBudgetsStddevSampFields>;
  sum?: Maybe<PkieMeasureBudgetsSumFields>;
  var_pop?: Maybe<PkieMeasureBudgetsVarPopFields>;
  var_samp?: Maybe<PkieMeasureBudgetsVarSampFields>;
  variance?: Maybe<PkieMeasureBudgetsVarianceFields>;
}


/** aggregate fields of "pkie.measure_budgets" */
export interface PkieMeasureBudgetsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsAggregateOrderBy {
  avg?: InputMaybe<PkieMeasureBudgetsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureBudgetsMaxOrderBy>;
  min?: InputMaybe<PkieMeasureBudgetsMinOrderBy>;
  stddev?: InputMaybe<PkieMeasureBudgetsStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieMeasureBudgetsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieMeasureBudgetsStddevSampOrderBy>;
  sum?: InputMaybe<PkieMeasureBudgetsSumOrderBy>;
  var_pop?: InputMaybe<PkieMeasureBudgetsVarPopOrderBy>;
  var_samp?: InputMaybe<PkieMeasureBudgetsVarSampOrderBy>;
  variance?: InputMaybe<PkieMeasureBudgetsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsArrRelInsertInput {
  data: Array<PkieMeasureBudgetsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureBudgetsOnConflict>;
}

/** aggregate avg on columns */
export interface PkieMeasureBudgetsAvgFields {
  __typename?: 'pkie_measure_budgets_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsAvgOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.measure_budgets". All fields are combined with a logical 'AND'. */
export interface PkieMeasureBudgetsBoolExp {
  _and?: InputMaybe<Array<PkieMeasureBudgetsBoolExp>>;
  _not?: InputMaybe<PkieMeasureBudgetsBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureBudgetsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  source_of_funding?: InputMaybe<PkieSourceOfFundingEnumComparisonExp>;
  type_budget?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
  type_of_budget?: InputMaybe<PkieTypeMeasureBudgetEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
  year?: InputMaybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_budgets" */
export enum PkieMeasureBudgetsConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureBudgetsPkey = 'measure_budgets_pkey'
}

/** input type for incrementing numeric columns in table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsIncInput {
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  source_of_funding?: InputMaybe<PkieSourceOfFundingEnum>;
  type_budget?: InputMaybe<PkieTypeMeasureBudgetObjRelInsertInput>;
  type_of_budget?: InputMaybe<PkieTypeMeasureBudgetEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureBudgetsMaxFields {
  __typename?: 'pkie_measure_budgets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureBudgetsMinFields {
  __typename?: 'pkie_measure_budgets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsMutationResponse {
  __typename?: 'pkie_measure_budgets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureBudgets>;
}

/** on_conflict condition type for table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsOnConflict {
  constraint: PkieMeasureBudgetsConstraint;
  update_columns?: Array<PkieMeasureBudgetsUpdateColumn>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_budgets". */
export interface PkieMeasureBudgetsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  source_of_funding?: InputMaybe<OrderBy>;
  type_budget?: InputMaybe<PkieTypeMeasureBudgetOrderBy>;
  type_of_budget?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_budgets */
export interface PkieMeasureBudgetsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.measure_budgets" */
export enum PkieMeasureBudgetsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

/** select "pkie_measure_budgets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pkie.measure_budgets" */
export enum PkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "pkie_measure_budgets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pkie.measure_budgets" */
export enum PkieMeasureBudgetsSelectColumnPkieMeasureBudgetsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  source_of_funding?: InputMaybe<PkieSourceOfFundingEnum>;
  type_of_budget?: InputMaybe<PkieTypeMeasureBudgetEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface PkieMeasureBudgetsStddevFields {
  __typename?: 'pkie_measure_budgets_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsStddevOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieMeasureBudgetsStddevPopFields {
  __typename?: 'pkie_measure_budgets_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsStddevPopOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieMeasureBudgetsStddevSampFields {
  __typename?: 'pkie_measure_budgets_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsStddevSampOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_measure_budgets" */
export interface PkieMeasureBudgetsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureBudgetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureBudgetsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  source_of_funding?: InputMaybe<PkieSourceOfFundingEnum>;
  type_of_budget?: InputMaybe<PkieTypeMeasureBudgetEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface PkieMeasureBudgetsSumFields {
  __typename?: 'pkie_measure_budgets_sum_fields';
  value?: Maybe<Scalars['numeric']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsSumOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** update columns of table "pkie.measure_budgets" */
export enum PkieMeasureBudgetsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  SourceOfFunding = 'source_of_funding',
  /** column name */
  TypeOfBudget = 'type_of_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Year = 'year'
}

export interface PkieMeasureBudgetsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieMeasureBudgetsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureBudgetsSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureBudgetsBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieMeasureBudgetsVarPopFields {
  __typename?: 'pkie_measure_budgets_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsVarPopOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieMeasureBudgetsVarSampFields {
  __typename?: 'pkie_measure_budgets_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsVarSampOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieMeasureBudgetsVarianceFields {
  __typename?: 'pkie_measure_budgets_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.measure_budgets" */
export interface PkieMeasureBudgetsVarianceOrderBy {
  value?: InputMaybe<OrderBy>;
  year?: InputMaybe<OrderBy>;
}

/** comments of measures */
export interface PkieMeasureComment {
  __typename?: 'pkie_measure_comment';
  by_user_id: Scalars['uuid']['output'];
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure: PkieMeasure;
  measure_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "pkie.measure_comment" */
export interface PkieMeasureCommentAggregate {
  __typename?: 'pkie_measure_comment_aggregate';
  aggregate?: Maybe<PkieMeasureCommentAggregateFields>;
  nodes: Array<PkieMeasureComment>;
}

export interface PkieMeasureCommentAggregateBoolExp {
  count?: InputMaybe<PkieMeasureCommentAggregateBoolExpCount>;
}

export interface PkieMeasureCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureCommentBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_comment" */
export interface PkieMeasureCommentAggregateFields {
  __typename?: 'pkie_measure_comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureCommentMaxFields>;
  min?: Maybe<PkieMeasureCommentMinFields>;
}


/** aggregate fields of "pkie.measure_comment" */
export interface PkieMeasureCommentAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_comment" */
export interface PkieMeasureCommentAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureCommentMaxOrderBy>;
  min?: InputMaybe<PkieMeasureCommentMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.measure_comment" */
export interface PkieMeasureCommentArrRelInsertInput {
  data: Array<PkieMeasureCommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureCommentOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.measure_comment". All fields are combined with a logical 'AND'. */
export interface PkieMeasureCommentBoolExp {
  _and?: InputMaybe<Array<PkieMeasureCommentBoolExp>>;
  _not?: InputMaybe<PkieMeasureCommentBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureCommentBoolExp>>;
  by_user_id?: InputMaybe<UuidComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_comment" */
export enum PkieMeasureCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureCommentPkey = 'measure_comment_pkey'
}

/** input type for inserting data into table "pkie.measure_comment" */
export interface PkieMeasureCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureCommentMaxFields {
  __typename?: 'pkie_measure_comment_max_fields';
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "pkie.measure_comment" */
export interface PkieMeasureCommentMaxOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureCommentMinFields {
  __typename?: 'pkie_measure_comment_min_fields';
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "pkie.measure_comment" */
export interface PkieMeasureCommentMinOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_comment" */
export interface PkieMeasureCommentMutationResponse {
  __typename?: 'pkie_measure_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureComment>;
}

/** on_conflict condition type for table "pkie.measure_comment" */
export interface PkieMeasureCommentOnConflict {
  constraint: PkieMeasureCommentConstraint;
  update_columns?: Array<PkieMeasureCommentUpdateColumn>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_comment". */
export interface PkieMeasureCommentOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_comment */
export interface PkieMeasureCommentPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.measure_comment" */
export enum PkieMeasureCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pkie.measure_comment" */
export interface PkieMeasureCommentSetInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "pkie_measure_comment" */
export interface PkieMeasureCommentStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureCommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureCommentStreamCursorValueInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "pkie.measure_comment" */
export enum PkieMeasureCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface PkieMeasureCommentUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureCommentSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureCommentBoolExp;
}

/** unique or primary key constraints on table "pkie.measure" */
export enum PkieMeasureConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasurePkey = 'measure_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface PkieMeasureDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface PkieMeasureDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface PkieMeasureDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** columns and relationships of "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembers {
  __typename?: 'pkie_measure_gnpie_members';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  gnpie_member: Users;
  gnpie_member_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure: PkieMeasure;
  measure_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersAggregate {
  __typename?: 'pkie_measure_gnpie_members_aggregate';
  aggregate?: Maybe<PkieMeasureGnpieMembersAggregateFields>;
  nodes: Array<PkieMeasureGnpieMembers>;
}

export interface PkieMeasureGnpieMembersAggregateBoolExp {
  count?: InputMaybe<PkieMeasureGnpieMembersAggregateBoolExpCount>;
}

export interface PkieMeasureGnpieMembersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersAggregateFields {
  __typename?: 'pkie_measure_gnpie_members_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureGnpieMembersMaxFields>;
  min?: Maybe<PkieMeasureGnpieMembersMinFields>;
}


/** aggregate fields of "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureGnpieMembersMaxOrderBy>;
  min?: InputMaybe<PkieMeasureGnpieMembersMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersArrRelInsertInput {
  data: Array<PkieMeasureGnpieMembersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureGnpieMembersOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.measure_gnpie_members". All fields are combined with a logical 'AND'. */
export interface PkieMeasureGnpieMembersBoolExp {
  _and?: InputMaybe<Array<PkieMeasureGnpieMembersBoolExp>>;
  _not?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureGnpieMembersBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_gnpie_members" */
export enum PkieMeasureGnpieMembersConstraint {
  /** unique or primary key constraint on columns "gnpie_member_id", "measure_id" */
  MeasureGnpieMembersMeasureIdGnpieMemberIdKey = 'measure_gnpie_members_measure_id_gnpie_member_id_key',
  /** unique or primary key constraint on columns "id" */
  MeasureGnpieMembersPkey = 'measure_gnpie_members_pkey'
}

/** input type for inserting data into table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureGnpieMembersMaxFields {
  __typename?: 'pkie_measure_gnpie_members_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureGnpieMembersMinFields {
  __typename?: 'pkie_measure_gnpie_members_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersMutationResponse {
  __typename?: 'pkie_measure_gnpie_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureGnpieMembers>;
}

/** on_conflict condition type for table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersOnConflict {
  constraint: PkieMeasureGnpieMembersConstraint;
  update_columns?: Array<PkieMeasureGnpieMembersUpdateColumn>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_gnpie_members". */
export interface PkieMeasureGnpieMembersOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_gnpie_members */
export interface PkieMeasureGnpieMembersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.measure_gnpie_members" */
export enum PkieMeasureGnpieMembersSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pkie.measure_gnpie_members" */
export interface PkieMeasureGnpieMembersSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "pkie_measure_gnpie_members" */
export interface PkieMeasureGnpieMembersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureGnpieMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureGnpieMembersStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "pkie.measure_gnpie_members" */
export enum PkieMeasureGnpieMembersUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface PkieMeasureGnpieMembersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureGnpieMembersSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureGnpieMembersBoolExp;
}

/** input type for incrementing numeric columns in table "pkie.measure" */
export interface PkieMeasureIncInput {
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.measure" */
export interface PkieMeasureInsertInput {
  acquis_chapter?: InputMaybe<PkieAcquisChapterObjRelInsertInput>;
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  acquis_work_plan_task?: InputMaybe<PkieAcquisWorkPlanTaskObjRelInsertInput>;
  acquis_work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<UsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  crated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Scalars['String']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnum>;
  level_of_alignment_status?: InputMaybe<PkieLevelOfAlignmentObjRelInsertInput>;
  measure_activities?: InputMaybe<PkieMeasureActivityArrRelInsertInput>;
  measure_answers?: InputMaybe<PkieMeasureAnswersArrRelInsertInput>;
  measure_bookmarks?: InputMaybe<PkieMeasureBookmarkArrRelInsertInput>;
  measure_budgets?: InputMaybe<PkieMeasureBudgetsArrRelInsertInput>;
  measure_comments?: InputMaybe<PkieMeasureCommentArrRelInsertInput>;
  measure_gnpie_members?: InputMaybe<PkieMeasureGnpieMembersArrRelInsertInput>;
  measure_roadmaps?: InputMaybe<PkieMeasureRoadmapArrRelInsertInput>;
  measure_status?: InputMaybe<PkieMeasureStatusObjRelInsertInput>;
  national_act?: InputMaybe<PkieNationalActsObjRelInsertInput>;
  negotiator?: InputMaybe<UsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  proposing_institution?: InputMaybe<InstitutionsObjRelInsertInput>;
  proposing_institution_id?: InputMaybe<Scalars['uuid']['input']>;
  quadrennial_scheduled_approval?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PkieMeasureStatusEnum>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<PkieTypeMeasureEnum>;
  type_alignment_act?: InputMaybe<PkieTypeAlignmentActObjRelInsertInput>;
  type_of_alignment?: InputMaybe<PkieTypeAlignmentActEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
  yearly_quarter?: InputMaybe<YearlyQuartersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface PkieMeasureMaxFields {
  __typename?: 'pkie_measure_max_fields';
  acquis_chapter_id?: Maybe<Scalars['uuid']['output']>;
  acquis_work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  crated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  impact?: Maybe<Scalars['String']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  proposing_institution_id?: Maybe<Scalars['uuid']['output']>;
  quadrennial_scheduled_approval?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "measure_scheduled_approval_date" */
  scheduled_approval_date?: Maybe<Scalars['date']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  weight_transposition?: Maybe<Scalars['Int']['output']>;
  year_of_completition?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "pkie.measure" */
export interface PkieMeasureMaxOrderBy {
  acquis_chapter_id?: InputMaybe<OrderBy>;
  acquis_work_plan_task_id?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  crated_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impact?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  proposing_institution_id?: InputMaybe<OrderBy>;
  quadrennial_scheduled_approval?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureMinFields {
  __typename?: 'pkie_measure_min_fields';
  acquis_chapter_id?: Maybe<Scalars['uuid']['output']>;
  acquis_work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  crated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  impact?: Maybe<Scalars['String']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  proposing_institution_id?: Maybe<Scalars['uuid']['output']>;
  quadrennial_scheduled_approval?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "measure_scheduled_approval_date" */
  scheduled_approval_date?: Maybe<Scalars['date']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  weight_transposition?: Maybe<Scalars['Int']['output']>;
  year_of_completition?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "pkie.measure" */
export interface PkieMeasureMinOrderBy {
  acquis_chapter_id?: InputMaybe<OrderBy>;
  acquis_work_plan_task_id?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  crated_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impact?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  proposing_institution_id?: InputMaybe<OrderBy>;
  quadrennial_scheduled_approval?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure" */
export interface PkieMeasureMutationResponse {
  __typename?: 'pkie_measure_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasure>;
}

/** input type for inserting object relation for remote table "pkie.measure" */
export interface PkieMeasureObjRelInsertInput {
  data: PkieMeasureInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureOnConflict>;
}

/** on_conflict condition type for table "pkie.measure" */
export interface PkieMeasureOnConflict {
  constraint: PkieMeasureConstraint;
  update_columns?: Array<PkieMeasureUpdateColumn>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure". */
export interface PkieMeasureOrderBy {
  acquis_chapter?: InputMaybe<PkieAcquisChapterOrderBy>;
  acquis_chapter_id?: InputMaybe<OrderBy>;
  acquis_work_plan_task?: InputMaybe<PkieAcquisWorkPlanTaskOrderBy>;
  acquis_work_plan_task_id?: InputMaybe<OrderBy>;
  chapter_coordinator?: InputMaybe<UsersOrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  crated_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impact?: InputMaybe<OrderBy>;
  is_completed?: InputMaybe<OrderBy>;
  level_of_alignment?: InputMaybe<OrderBy>;
  level_of_alignment_status?: InputMaybe<PkieLevelOfAlignmentOrderBy>;
  measure_activities_aggregate?: InputMaybe<PkieMeasureActivityAggregateOrderBy>;
  measure_answers_aggregate?: InputMaybe<PkieMeasureAnswersAggregateOrderBy>;
  measure_bookmarks_aggregate?: InputMaybe<PkieMeasureBookmarkAggregateOrderBy>;
  measure_budgets_aggregate?: InputMaybe<PkieMeasureBudgetsAggregateOrderBy>;
  measure_comments_aggregate?: InputMaybe<PkieMeasureCommentAggregateOrderBy>;
  measure_gnpie_members_aggregate?: InputMaybe<PkieMeasureGnpieMembersAggregateOrderBy>;
  measure_roadmaps_aggregate?: InputMaybe<PkieMeasureRoadmapAggregateOrderBy>;
  measure_status?: InputMaybe<PkieMeasureStatusOrderBy>;
  national_act?: InputMaybe<PkieNationalActsOrderBy>;
  negotiator?: InputMaybe<UsersOrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  proposing_institution?: InputMaybe<InstitutionsOrderBy>;
  proposing_institution_id?: InputMaybe<OrderBy>;
  quadrennial_scheduled_approval?: InputMaybe<OrderBy>;
  scheduled_approval_date?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  type_alignment_act?: InputMaybe<PkieTypeAlignmentActOrderBy>;
  type_of_alignment?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
  yearly_quarter?: InputMaybe<YearlyQuartersOrderBy>;
}

/** primary key columns input for table: pkie.measure */
export interface PkieMeasurePkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface PkieMeasurePrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** columns and relationships of "pkie.measure_roadmap" */
export interface PkieMeasureRoadmap {
  __typename?: 'pkie_measure_roadmap';
  created_at: Scalars['timestamp']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  measure: PkieMeasure;
  measure_id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapAggregate {
  __typename?: 'pkie_measure_roadmap_aggregate';
  aggregate?: Maybe<PkieMeasureRoadmapAggregateFields>;
  nodes: Array<PkieMeasureRoadmap>;
}

export interface PkieMeasureRoadmapAggregateBoolExp {
  count?: InputMaybe<PkieMeasureRoadmapAggregateBoolExpCount>;
}

export interface PkieMeasureRoadmapAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieMeasureRoadmapBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapAggregateFields {
  __typename?: 'pkie_measure_roadmap_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureRoadmapMaxFields>;
  min?: Maybe<PkieMeasureRoadmapMinFields>;
}


/** aggregate fields of "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieMeasureRoadmapMaxOrderBy>;
  min?: InputMaybe<PkieMeasureRoadmapMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapArrRelInsertInput {
  data: Array<PkieMeasureRoadmapInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureRoadmapOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.measure_roadmap". All fields are combined with a logical 'AND'. */
export interface PkieMeasureRoadmapBoolExp {
  _and?: InputMaybe<Array<PkieMeasureRoadmapBoolExp>>;
  _not?: InputMaybe<PkieMeasureRoadmapBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureRoadmapBoolExp>>;
  created_at?: InputMaybe<TimestampComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measure?: InputMaybe<PkieMeasureBoolExp>;
  measure_id?: InputMaybe<UuidComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_roadmap" */
export enum PkieMeasureRoadmapConstraint {
  /** unique or primary key constraint on columns "id" */
  MeasureRoadmapPkey = 'measure_roadmap_pkey',
  /** unique or primary key constraint on columns "task_id", "measure_id" */
  MeasureRoadmapTaskIdMeasureIdKey = 'measure_roadmap_task_id_measure_id_key'
}

/** input type for inserting data into table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapInsertInput {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure?: InputMaybe<PkieMeasureObjRelInsertInput>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureRoadmapMaxFields {
  __typename?: 'pkie_measure_roadmap_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieMeasureRoadmapMinFields {
  __typename?: 'pkie_measure_roadmap_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  measure_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapMutationResponse {
  __typename?: 'pkie_measure_roadmap_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureRoadmap>;
}

/** on_conflict condition type for table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapOnConflict {
  constraint: PkieMeasureRoadmapConstraint;
  update_columns?: Array<PkieMeasureRoadmapUpdateColumn>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_roadmap". */
export interface PkieMeasureRoadmapOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measure?: InputMaybe<PkieMeasureOrderBy>;
  measure_id?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_roadmap */
export interface PkieMeasureRoadmapPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.measure_roadmap" */
export enum PkieMeasureRoadmapSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pkie.measure_roadmap" */
export interface PkieMeasureRoadmapSetInput {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "pkie_measure_roadmap" */
export interface PkieMeasureRoadmapStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureRoadmapStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureRoadmapStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measure_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "pkie.measure_roadmap" */
export enum PkieMeasureRoadmapUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasureId = 'measure_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface PkieMeasureRoadmapUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureRoadmapSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureRoadmapBoolExp;
}

/** select columns of table "pkie.measure" */
export enum PkieMeasureSelectColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  AcquisWorkPlanTaskId = 'acquis_work_plan_task_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Eli = 'eli',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

/** select "pkie_measure_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pkie.measure" */
export enum PkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "pkie_measure_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pkie.measure" */
export enum PkieMeasureSelectColumnPkieMeasureAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "pkie.measure" */
export interface PkieMeasureSetInput {
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  acquis_work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  crated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Scalars['String']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnum>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  proposing_institution_id?: InputMaybe<Scalars['uuid']['input']>;
  quadrennial_scheduled_approval?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PkieMeasureStatusEnum>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<PkieTypeMeasureEnum>;
  type_of_alignment?: InputMaybe<PkieTypeAlignmentActEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
}

/** measure statuses */
export interface PkieMeasureStatus {
  __typename?: 'pkie_measure_status';
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  status: Scalars['String']['output'];
}


/** measure statuses */
export interface PkieMeasureStatusMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** measure statuses */
export interface PkieMeasureStatusMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "pkie.measure_status" */
export interface PkieMeasureStatusAggregate {
  __typename?: 'pkie_measure_status_aggregate';
  aggregate?: Maybe<PkieMeasureStatusAggregateFields>;
  nodes: Array<PkieMeasureStatus>;
}

/** aggregate fields of "pkie.measure_status" */
export interface PkieMeasureStatusAggregateFields {
  __typename?: 'pkie_measure_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieMeasureStatusMaxFields>;
  min?: Maybe<PkieMeasureStatusMinFields>;
}


/** aggregate fields of "pkie.measure_status" */
export interface PkieMeasureStatusAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieMeasureStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.measure_status". All fields are combined with a logical 'AND'. */
export interface PkieMeasureStatusBoolExp {
  _and?: InputMaybe<Array<PkieMeasureStatusBoolExp>>;
  _not?: InputMaybe<PkieMeasureStatusBoolExp>;
  _or?: InputMaybe<Array<PkieMeasureStatusBoolExp>>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.measure_status" */
export enum PkieMeasureStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  MeasureStatusPkey = 'measure_status_pkey'
}

export enum PkieMeasureStatusEnum {
  HartimKonceptiInstitucioni = 'HARTIM_KONCEPTI_INSTITUCIONI',
  HyrjaNeFuqi = 'HYRJA_NE_FUQI',
  MiratimiNeKm = 'MIRATIMI_NE_KM',
  MiratimiNgaMinistri = 'MIRATIMI_NGA_MINISTRI',
  MiratimNgaKuvendi = 'MIRATIM_NGA_KUVENDI',
  PergatitjaEAktitLigjorInstitucioni = 'PERGATITJA_E_AKTIT_LIGJOR_INSTITUCIONI',
  PerKonsultimMeInstitucionet = 'PER_KONSULTIM_ME_INSTITUCIONET',
  PerKonsultimPublik = 'PER_KONSULTIM_PUBLIK',
  ShqyrtimNgaKuvendi = 'SHQYRTIM_NGA_KUVENDI'
}

/** Boolean expression to compare columns of type "pkie_measure_status_enum". All fields are combined with logical 'AND'. */
export interface PkieMeasureStatusEnumComparisonExp {
  _eq?: InputMaybe<PkieMeasureStatusEnum>;
  _in?: InputMaybe<Array<PkieMeasureStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieMeasureStatusEnum>;
  _nin?: InputMaybe<Array<PkieMeasureStatusEnum>>;
}

/** input type for inserting data into table "pkie.measure_status" */
export interface PkieMeasureStatusInsertInput {
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieMeasureStatusMaxFields {
  __typename?: 'pkie_measure_status_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieMeasureStatusMinFields {
  __typename?: 'pkie_measure_status_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.measure_status" */
export interface PkieMeasureStatusMutationResponse {
  __typename?: 'pkie_measure_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieMeasureStatus>;
}

/** input type for inserting object relation for remote table "pkie.measure_status" */
export interface PkieMeasureStatusObjRelInsertInput {
  data: PkieMeasureStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieMeasureStatusOnConflict>;
}

/** on_conflict condition type for table "pkie.measure_status" */
export interface PkieMeasureStatusOnConflict {
  constraint: PkieMeasureStatusConstraint;
  update_columns?: Array<PkieMeasureStatusUpdateColumn>;
  where?: InputMaybe<PkieMeasureStatusBoolExp>;
}

/** Ordering options when selecting data from "pkie.measure_status". */
export interface PkieMeasureStatusOrderBy {
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.measure_status */
export interface PkieMeasureStatusPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "pkie.measure_status" */
export enum PkieMeasureStatusSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "pkie.measure_status" */
export interface PkieMeasureStatusSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_measure_status" */
export interface PkieMeasureStatusStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureStatusStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.measure_status" */
export enum PkieMeasureStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface PkieMeasureStatusUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureStatusSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureStatusBoolExp;
}

/** aggregate stddev on columns */
export interface PkieMeasureStddevFields {
  __typename?: 'pkie_measure_stddev_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.measure" */
export interface PkieMeasureStddevOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieMeasureStddevPopFields {
  __typename?: 'pkie_measure_stddev_pop_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.measure" */
export interface PkieMeasureStddevPopOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieMeasureStddevSampFields {
  __typename?: 'pkie_measure_stddev_samp_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.measure" */
export interface PkieMeasureStddevSampOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_measure" */
export interface PkieMeasureStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieMeasureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieMeasureStreamCursorValueInput {
  acquis_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  acquis_work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  crated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Scalars['String']['input']>;
  is_completed?: InputMaybe<Scalars['Boolean']['input']>;
  level_of_alignment?: InputMaybe<PkieLevelOfAlignmentEnum>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  proposing_institution_id?: InputMaybe<Scalars['uuid']['input']>;
  quadrennial_scheduled_approval?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PkieMeasureStatusEnum>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<PkieTypeMeasureEnum>;
  type_of_alignment?: InputMaybe<PkieTypeAlignmentActEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  weight_transposition?: InputMaybe<Scalars['Int']['input']>;
  year_of_completition?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface PkieMeasureSumFields {
  __typename?: 'pkie_measure_sum_fields';
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  weight_transposition?: Maybe<Scalars['Int']['output']>;
  year_of_completition?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.measure" */
export interface PkieMeasureSumOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** update columns of table "pkie.measure" */
export enum PkieMeasureUpdateColumn {
  /** column name */
  AcquisChapterId = 'acquis_chapter_id',
  /** column name */
  AcquisWorkPlanTaskId = 'acquis_work_plan_task_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CratedByUserId = 'crated_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Eli = 'eli',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LevelOfAlignment = 'level_of_alignment',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ProposingInstitutionId = 'proposing_institution_id',
  /** column name */
  QuadrennialScheduledApproval = 'quadrennial_scheduled_approval',
  /** column name */
  Status = 'status',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  TypeOfAlignment = 'type_of_alignment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightTransposition = 'weight_transposition',
  /** column name */
  YearOfCompletition = 'year_of_completition'
}

export interface PkieMeasureUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PkieMeasureAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PkieMeasureDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PkieMeasureDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PkieMeasureDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieMeasureIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PkieMeasurePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieMeasureSetInput>;
  /** filter the rows which have to be updated */
  where: PkieMeasureBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieMeasureVarPopFields {
  __typename?: 'pkie_measure_var_pop_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.measure" */
export interface PkieMeasureVarPopOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieMeasureVarSampFields {
  __typename?: 'pkie_measure_var_samp_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.measure" */
export interface PkieMeasureVarSampOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieMeasureVarianceFields {
  __typename?: 'pkie_measure_variance_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
  weight_transposition?: Maybe<Scalars['Float']['output']>;
  year_of_completition?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.measure" */
export interface PkieMeasureVarianceOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  weight_transposition?: InputMaybe<OrderBy>;
  year_of_completition?: InputMaybe<OrderBy>;
}

/** National acts coming from QBZ */
export interface PkieNationalActs {
  __typename?: 'pkie_national_acts';
  /** An array relationship */
  acquis_national_acts: Array<PkieAcquisNationalActs>;
  /** An aggregate relationship */
  acquis_national_acts_aggregate: PkieAcquisNationalActsAggregate;
  act_number?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  act_type?: Maybe<PkieActType>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli: Scalars['String']['output'];
  failed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  publish_date?: Maybe<Scalars['date']['output']>;
  qbz_id?: Maybe<Scalars['uuid']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<PkieActTypeEnum>;
}


/** National acts coming from QBZ */
export interface PkieNationalActsAcquisNationalActsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


/** National acts coming from QBZ */
export interface PkieNationalActsAcquisNationalActsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


/** National acts coming from QBZ */
export interface PkieNationalActsMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** National acts coming from QBZ */
export interface PkieNationalActsMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "pkie.national_acts" */
export interface PkieNationalActsAggregate {
  __typename?: 'pkie_national_acts_aggregate';
  aggregate?: Maybe<PkieNationalActsAggregateFields>;
  nodes: Array<PkieNationalActs>;
}

/** aggregate fields of "pkie.national_acts" */
export interface PkieNationalActsAggregateFields {
  __typename?: 'pkie_national_acts_aggregate_fields';
  avg?: Maybe<PkieNationalActsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieNationalActsMaxFields>;
  min?: Maybe<PkieNationalActsMinFields>;
  stddev?: Maybe<PkieNationalActsStddevFields>;
  stddev_pop?: Maybe<PkieNationalActsStddevPopFields>;
  stddev_samp?: Maybe<PkieNationalActsStddevSampFields>;
  sum?: Maybe<PkieNationalActsSumFields>;
  var_pop?: Maybe<PkieNationalActsVarPopFields>;
  var_samp?: Maybe<PkieNationalActsVarSampFields>;
  variance?: Maybe<PkieNationalActsVarianceFields>;
}


/** aggregate fields of "pkie.national_acts" */
export interface PkieNationalActsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieNationalActsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface PkieNationalActsAvgFields {
  __typename?: 'pkie_national_acts_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "pkie.national_acts". All fields are combined with a logical 'AND'. */
export interface PkieNationalActsBoolExp {
  _and?: InputMaybe<Array<PkieNationalActsBoolExp>>;
  _not?: InputMaybe<PkieNationalActsBoolExp>;
  _or?: InputMaybe<Array<PkieNationalActsBoolExp>>;
  acquis_national_acts?: InputMaybe<PkieAcquisNationalActsBoolExp>;
  acquis_national_acts_aggregate?: InputMaybe<PkieAcquisNationalActsAggregateBoolExp>;
  act_number?: InputMaybe<StringComparisonExp>;
  act_type?: InputMaybe<PkieActTypeBoolExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  eli?: InputMaybe<StringComparisonExp>;
  failed?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  publish_date?: InputMaybe<DateComparisonExp>;
  qbz_id?: InputMaybe<UuidComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<PkieActTypeEnumComparisonExp>;
}

/** unique or primary key constraints on table "pkie.national_acts" */
export enum PkieNationalActsConstraint {
  /** unique or primary key constraint on columns "id" */
  NationalActsIdKey = 'national_acts_id_key',
  /** unique or primary key constraint on columns "eli" */
  NationalActsPkey = 'national_acts_pkey'
}

/** input type for incrementing numeric columns in table "pkie.national_acts" */
export interface PkieNationalActsIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.national_acts" */
export interface PkieNationalActsInsertInput {
  acquis_national_acts?: InputMaybe<PkieAcquisNationalActsArrRelInsertInput>;
  act_number?: InputMaybe<Scalars['String']['input']>;
  act_type?: InputMaybe<PkieActTypeObjRelInsertInput>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  failed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  publish_date?: InputMaybe<Scalars['date']['input']>;
  qbz_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PkieActTypeEnum>;
}

/** aggregate max on columns */
export interface PkieNationalActsMaxFields {
  __typename?: 'pkie_national_acts_max_fields';
  act_number?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  publish_date?: Maybe<Scalars['date']['output']>;
  qbz_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieNationalActsMinFields {
  __typename?: 'pkie_national_acts_min_fields';
  act_number?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  eli?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  publish_date?: Maybe<Scalars['date']['output']>;
  qbz_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.national_acts" */
export interface PkieNationalActsMutationResponse {
  __typename?: 'pkie_national_acts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieNationalActs>;
}

/** input type for inserting object relation for remote table "pkie.national_acts" */
export interface PkieNationalActsObjRelInsertInput {
  data: PkieNationalActsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieNationalActsOnConflict>;
}

/** on_conflict condition type for table "pkie.national_acts" */
export interface PkieNationalActsOnConflict {
  constraint: PkieNationalActsConstraint;
  update_columns?: Array<PkieNationalActsUpdateColumn>;
  where?: InputMaybe<PkieNationalActsBoolExp>;
}

/** Ordering options when selecting data from "pkie.national_acts". */
export interface PkieNationalActsOrderBy {
  acquis_national_acts_aggregate?: InputMaybe<PkieAcquisNationalActsAggregateOrderBy>;
  act_number?: InputMaybe<OrderBy>;
  act_type?: InputMaybe<PkieActTypeOrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  eli?: InputMaybe<OrderBy>;
  failed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  publish_date?: InputMaybe<OrderBy>;
  qbz_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.national_acts */
export interface PkieNationalActsPkColumnsInput {
  eli: Scalars['String']['input'];
}

/** select columns of table "pkie.national_acts" */
export enum PkieNationalActsSelectColumn {
  /** column name */
  ActNumber = 'act_number',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  Failed = 'failed',
  /** column name */
  Id = 'id',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "pkie.national_acts" */
export interface PkieNationalActsSetInput {
  act_number?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  failed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  publish_date?: InputMaybe<Scalars['date']['input']>;
  qbz_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PkieActTypeEnum>;
}

/** aggregate stddev on columns */
export interface PkieNationalActsStddevFields {
  __typename?: 'pkie_national_acts_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface PkieNationalActsStddevPopFields {
  __typename?: 'pkie_national_acts_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface PkieNationalActsStddevSampFields {
  __typename?: 'pkie_national_acts_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "pkie_national_acts" */
export interface PkieNationalActsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieNationalActsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieNationalActsStreamCursorValueInput {
  act_number?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  eli?: InputMaybe<Scalars['String']['input']>;
  failed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  publish_date?: InputMaybe<Scalars['date']['input']>;
  qbz_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PkieActTypeEnum>;
}

/** aggregate sum on columns */
export interface PkieNationalActsSumFields {
  __typename?: 'pkie_national_acts_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "pkie.national_acts" */
export enum PkieNationalActsUpdateColumn {
  /** column name */
  ActNumber = 'act_number',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Eli = 'eli',
  /** column name */
  Failed = 'failed',
  /** column name */
  Id = 'id',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  QbzId = 'qbz_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

export interface PkieNationalActsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieNationalActsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieNationalActsSetInput>;
  /** filter the rows which have to be updated */
  where: PkieNationalActsBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieNationalActsVarPopFields {
  __typename?: 'pkie_national_acts_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface PkieNationalActsVarSampFields {
  __typename?: 'pkie_national_acts_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface PkieNationalActsVarianceFields {
  __typename?: 'pkie_national_acts_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIaga {
  __typename?: 'pkie_proces_pune_iaga';
  /** An object relationship */
  acqui: PkieAcquis;
  boshlleqet_e_struktures?: Maybe<Scalars['String']['output']>;
  celex: Scalars['String']['output'];
  elemente_te_struktures?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  ngritja_institucionale?: Maybe<Scalars['String']['output']>;
  pershtatje_struktura_administrative?: Maybe<Scalars['String']['output']>;
  struktura_administrative?: Maybe<Scalars['String']['output']>;
  synimi_aspekte_financiare?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaAggregate {
  __typename?: 'pkie_proces_pune_iaga_aggregate';
  aggregate?: Maybe<PkieProcesPuneIagaAggregateFields>;
  nodes: Array<PkieProcesPuneIaga>;
}

export interface PkieProcesPuneIagaAggregateBoolExp {
  count?: InputMaybe<PkieProcesPuneIagaAggregateBoolExpCount>;
}

export interface PkieProcesPuneIagaAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieProcesPuneIagaBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaAggregateFields {
  __typename?: 'pkie_proces_pune_iaga_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieProcesPuneIagaMaxFields>;
  min?: Maybe<PkieProcesPuneIagaMinFields>;
}


/** aggregate fields of "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieProcesPuneIagaMaxOrderBy>;
  min?: InputMaybe<PkieProcesPuneIagaMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaArrRelInsertInput {
  data: Array<PkieProcesPuneIagaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieProcesPuneIagaOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.proces_pune_iaga". All fields are combined with a logical 'AND'. */
export interface PkieProcesPuneIagaBoolExp {
  _and?: InputMaybe<Array<PkieProcesPuneIagaBoolExp>>;
  _not?: InputMaybe<PkieProcesPuneIagaBoolExp>;
  _or?: InputMaybe<Array<PkieProcesPuneIagaBoolExp>>;
  acqui?: InputMaybe<PkieAcquisBoolExp>;
  boshlleqet_e_struktures?: InputMaybe<StringComparisonExp>;
  celex?: InputMaybe<StringComparisonExp>;
  elemente_te_struktures?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ngritja_institucionale?: InputMaybe<StringComparisonExp>;
  pershtatje_struktura_administrative?: InputMaybe<StringComparisonExp>;
  struktura_administrative?: InputMaybe<StringComparisonExp>;
  synimi_aspekte_financiare?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.proces_pune_iaga" */
export enum PkieProcesPuneIagaConstraint {
  /** unique or primary key constraint on columns "id" */
  ProcesPuneIagaPkey = 'proces_pune_iaga_pkey'
}

/** input type for inserting data into table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaInsertInput {
  acqui?: InputMaybe<PkieAcquisObjRelInsertInput>;
  boshlleqet_e_struktures?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  elemente_te_struktures?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ngritja_institucionale?: InputMaybe<Scalars['String']['input']>;
  pershtatje_struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  synimi_aspekte_financiare?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieProcesPuneIagaMaxFields {
  __typename?: 'pkie_proces_pune_iaga_max_fields';
  boshlleqet_e_struktures?: Maybe<Scalars['String']['output']>;
  celex?: Maybe<Scalars['String']['output']>;
  elemente_te_struktures?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ngritja_institucionale?: Maybe<Scalars['String']['output']>;
  pershtatje_struktura_administrative?: Maybe<Scalars['String']['output']>;
  struktura_administrative?: Maybe<Scalars['String']['output']>;
  synimi_aspekte_financiare?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaMaxOrderBy {
  boshlleqet_e_struktures?: InputMaybe<OrderBy>;
  celex?: InputMaybe<OrderBy>;
  elemente_te_struktures?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ngritja_institucionale?: InputMaybe<OrderBy>;
  pershtatje_struktura_administrative?: InputMaybe<OrderBy>;
  struktura_administrative?: InputMaybe<OrderBy>;
  synimi_aspekte_financiare?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieProcesPuneIagaMinFields {
  __typename?: 'pkie_proces_pune_iaga_min_fields';
  boshlleqet_e_struktures?: Maybe<Scalars['String']['output']>;
  celex?: Maybe<Scalars['String']['output']>;
  elemente_te_struktures?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ngritja_institucionale?: Maybe<Scalars['String']['output']>;
  pershtatje_struktura_administrative?: Maybe<Scalars['String']['output']>;
  struktura_administrative?: Maybe<Scalars['String']['output']>;
  synimi_aspekte_financiare?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaMinOrderBy {
  boshlleqet_e_struktures?: InputMaybe<OrderBy>;
  celex?: InputMaybe<OrderBy>;
  elemente_te_struktures?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ngritja_institucionale?: InputMaybe<OrderBy>;
  pershtatje_struktura_administrative?: InputMaybe<OrderBy>;
  struktura_administrative?: InputMaybe<OrderBy>;
  synimi_aspekte_financiare?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaMutationResponse {
  __typename?: 'pkie_proces_pune_iaga_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieProcesPuneIaga>;
}

/** on_conflict condition type for table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaOnConflict {
  constraint: PkieProcesPuneIagaConstraint;
  update_columns?: Array<PkieProcesPuneIagaUpdateColumn>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}

/** Ordering options when selecting data from "pkie.proces_pune_iaga". */
export interface PkieProcesPuneIagaOrderBy {
  acqui?: InputMaybe<PkieAcquisOrderBy>;
  boshlleqet_e_struktures?: InputMaybe<OrderBy>;
  celex?: InputMaybe<OrderBy>;
  elemente_te_struktures?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ngritja_institucionale?: InputMaybe<OrderBy>;
  pershtatje_struktura_administrative?: InputMaybe<OrderBy>;
  struktura_administrative?: InputMaybe<OrderBy>;
  synimi_aspekte_financiare?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.proces_pune_iaga */
export interface PkieProcesPuneIagaPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.proces_pune_iaga" */
export enum PkieProcesPuneIagaSelectColumn {
  /** column name */
  BoshlleqetEStruktures = 'boshlleqet_e_struktures',
  /** column name */
  Celex = 'celex',
  /** column name */
  ElementeTeStruktures = 'elemente_te_struktures',
  /** column name */
  Id = 'id',
  /** column name */
  NgritjaInstitucionale = 'ngritja_institucionale',
  /** column name */
  PershtatjeStrukturaAdministrative = 'pershtatje_struktura_administrative',
  /** column name */
  StrukturaAdministrative = 'struktura_administrative',
  /** column name */
  SynimiAspekteFinanciare = 'synimi_aspekte_financiare'
}

/** input type for updating data in table "pkie.proces_pune_iaga" */
export interface PkieProcesPuneIagaSetInput {
  boshlleqet_e_struktures?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  elemente_te_struktures?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ngritja_institucionale?: InputMaybe<Scalars['String']['input']>;
  pershtatje_struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  synimi_aspekte_financiare?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_proces_pune_iaga" */
export interface PkieProcesPuneIagaStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieProcesPuneIagaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieProcesPuneIagaStreamCursorValueInput {
  boshlleqet_e_struktures?: InputMaybe<Scalars['String']['input']>;
  celex?: InputMaybe<Scalars['String']['input']>;
  elemente_te_struktures?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ngritja_institucionale?: InputMaybe<Scalars['String']['input']>;
  pershtatje_struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  struktura_administrative?: InputMaybe<Scalars['String']['input']>;
  synimi_aspekte_financiare?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.proces_pune_iaga" */
export enum PkieProcesPuneIagaUpdateColumn {
  /** column name */
  BoshlleqetEStruktures = 'boshlleqet_e_struktures',
  /** column name */
  Celex = 'celex',
  /** column name */
  ElementeTeStruktures = 'elemente_te_struktures',
  /** column name */
  Id = 'id',
  /** column name */
  NgritjaInstitucionale = 'ngritja_institucionale',
  /** column name */
  PershtatjeStrukturaAdministrative = 'pershtatje_struktura_administrative',
  /** column name */
  StrukturaAdministrative = 'struktura_administrative',
  /** column name */
  SynimiAspekteFinanciare = 'synimi_aspekte_financiare'
}

export interface PkieProcesPuneIagaUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieProcesPuneIagaSetInput>;
  /** filter the rows which have to be updated */
  where: PkieProcesPuneIagaBoolExp;
}

/** columns and relationships of "pkie.proces_pune_lga" */
export interface PkieProcesPuneLga {
  __typename?: 'pkie_proces_pune_lga';
  /** An object relationship */
  acqui: PkieAcquis;
  celex: Scalars['String']['output'];
  harmonizimi_hapesirat?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  transpozim_plane?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaAggregate {
  __typename?: 'pkie_proces_pune_lga_aggregate';
  aggregate?: Maybe<PkieProcesPuneLgaAggregateFields>;
  nodes: Array<PkieProcesPuneLga>;
}

export interface PkieProcesPuneLgaAggregateBoolExp {
  count?: InputMaybe<PkieProcesPuneLgaAggregateBoolExpCount>;
}

export interface PkieProcesPuneLgaAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieProcesPuneLgaBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaAggregateFields {
  __typename?: 'pkie_proces_pune_lga_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieProcesPuneLgaMaxFields>;
  min?: Maybe<PkieProcesPuneLgaMinFields>;
}


/** aggregate fields of "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieProcesPuneLgaMaxOrderBy>;
  min?: InputMaybe<PkieProcesPuneLgaMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaArrRelInsertInput {
  data: Array<PkieProcesPuneLgaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieProcesPuneLgaOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.proces_pune_lga". All fields are combined with a logical 'AND'. */
export interface PkieProcesPuneLgaBoolExp {
  _and?: InputMaybe<Array<PkieProcesPuneLgaBoolExp>>;
  _not?: InputMaybe<PkieProcesPuneLgaBoolExp>;
  _or?: InputMaybe<Array<PkieProcesPuneLgaBoolExp>>;
  acqui?: InputMaybe<PkieAcquisBoolExp>;
  celex?: InputMaybe<StringComparisonExp>;
  harmonizimi_hapesirat?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  transpozim_plane?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.proces_pune_lga" */
export enum PkieProcesPuneLgaConstraint {
  /** unique or primary key constraint on columns "id" */
  ProcesePuneLgaPkey = 'procese_pune_lga_pkey'
}

/** input type for inserting data into table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaInsertInput {
  acqui?: InputMaybe<PkieAcquisObjRelInsertInput>;
  celex?: InputMaybe<Scalars['String']['input']>;
  harmonizimi_hapesirat?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  transpozim_plane?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieProcesPuneLgaMaxFields {
  __typename?: 'pkie_proces_pune_lga_max_fields';
  celex?: Maybe<Scalars['String']['output']>;
  harmonizimi_hapesirat?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  transpozim_plane?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaMaxOrderBy {
  celex?: InputMaybe<OrderBy>;
  harmonizimi_hapesirat?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transpozim_plane?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieProcesPuneLgaMinFields {
  __typename?: 'pkie_proces_pune_lga_min_fields';
  celex?: Maybe<Scalars['String']['output']>;
  harmonizimi_hapesirat?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  transpozim_plane?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaMinOrderBy {
  celex?: InputMaybe<OrderBy>;
  harmonizimi_hapesirat?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transpozim_plane?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaMutationResponse {
  __typename?: 'pkie_proces_pune_lga_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieProcesPuneLga>;
}

/** on_conflict condition type for table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaOnConflict {
  constraint: PkieProcesPuneLgaConstraint;
  update_columns?: Array<PkieProcesPuneLgaUpdateColumn>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}

/** Ordering options when selecting data from "pkie.proces_pune_lga". */
export interface PkieProcesPuneLgaOrderBy {
  acqui?: InputMaybe<PkieAcquisOrderBy>;
  celex?: InputMaybe<OrderBy>;
  harmonizimi_hapesirat?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transpozim_plane?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.proces_pune_lga */
export interface PkieProcesPuneLgaPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.proces_pune_lga" */
export enum PkieProcesPuneLgaSelectColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  HarmonizimiHapesirat = 'harmonizimi_hapesirat',
  /** column name */
  Id = 'id',
  /** column name */
  TranspozimPlane = 'transpozim_plane'
}

/** input type for updating data in table "pkie.proces_pune_lga" */
export interface PkieProcesPuneLgaSetInput {
  celex?: InputMaybe<Scalars['String']['input']>;
  harmonizimi_hapesirat?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  transpozim_plane?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_proces_pune_lga" */
export interface PkieProcesPuneLgaStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieProcesPuneLgaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieProcesPuneLgaStreamCursorValueInput {
  celex?: InputMaybe<Scalars['String']['input']>;
  harmonizimi_hapesirat?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  transpozim_plane?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.proces_pune_lga" */
export enum PkieProcesPuneLgaUpdateColumn {
  /** column name */
  Celex = 'celex',
  /** column name */
  HarmonizimiHapesirat = 'harmonizimi_hapesirat',
  /** column name */
  Id = 'id',
  /** column name */
  TranspozimPlane = 'transpozim_plane'
}

export interface PkieProcesPuneLgaUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieProcesPuneLgaSetInput>;
  /** filter the rows which have to be updated */
  where: PkieProcesPuneLgaBoolExp;
}

/** columns and relationships of "pkie.source_of_funding" */
export interface PkieSourceOfFunding {
  __typename?: 'pkie_source_of_funding';
  source: Scalars['String']['output'];
}

/** aggregated selection of "pkie.source_of_funding" */
export interface PkieSourceOfFundingAggregate {
  __typename?: 'pkie_source_of_funding_aggregate';
  aggregate?: Maybe<PkieSourceOfFundingAggregateFields>;
  nodes: Array<PkieSourceOfFunding>;
}

/** aggregate fields of "pkie.source_of_funding" */
export interface PkieSourceOfFundingAggregateFields {
  __typename?: 'pkie_source_of_funding_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieSourceOfFundingMaxFields>;
  min?: Maybe<PkieSourceOfFundingMinFields>;
}


/** aggregate fields of "pkie.source_of_funding" */
export interface PkieSourceOfFundingAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieSourceOfFundingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.source_of_funding". All fields are combined with a logical 'AND'. */
export interface PkieSourceOfFundingBoolExp {
  _and?: InputMaybe<Array<PkieSourceOfFundingBoolExp>>;
  _not?: InputMaybe<PkieSourceOfFundingBoolExp>;
  _or?: InputMaybe<Array<PkieSourceOfFundingBoolExp>>;
  source?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.source_of_funding" */
export enum PkieSourceOfFundingConstraint {
  /** unique or primary key constraint on columns "source" */
  SourceOfFundingPkey = 'source_of_funding_pkey'
}

export enum PkieSourceOfFundingEnum {
  Donor = 'DONOR',
  StateBudget = 'STATE_BUDGET'
}

/** Boolean expression to compare columns of type "pkie_source_of_funding_enum". All fields are combined with logical 'AND'. */
export interface PkieSourceOfFundingEnumComparisonExp {
  _eq?: InputMaybe<PkieSourceOfFundingEnum>;
  _in?: InputMaybe<Array<PkieSourceOfFundingEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieSourceOfFundingEnum>;
  _nin?: InputMaybe<Array<PkieSourceOfFundingEnum>>;
}

/** input type for inserting data into table "pkie.source_of_funding" */
export interface PkieSourceOfFundingInsertInput {
  source?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieSourceOfFundingMaxFields {
  __typename?: 'pkie_source_of_funding_max_fields';
  source?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieSourceOfFundingMinFields {
  __typename?: 'pkie_source_of_funding_min_fields';
  source?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.source_of_funding" */
export interface PkieSourceOfFundingMutationResponse {
  __typename?: 'pkie_source_of_funding_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieSourceOfFunding>;
}

/** on_conflict condition type for table "pkie.source_of_funding" */
export interface PkieSourceOfFundingOnConflict {
  constraint: PkieSourceOfFundingConstraint;
  update_columns?: Array<PkieSourceOfFundingUpdateColumn>;
  where?: InputMaybe<PkieSourceOfFundingBoolExp>;
}

/** Ordering options when selecting data from "pkie.source_of_funding". */
export interface PkieSourceOfFundingOrderBy {
  source?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.source_of_funding */
export interface PkieSourceOfFundingPkColumnsInput {
  source: Scalars['String']['input'];
}

/** select columns of table "pkie.source_of_funding" */
export enum PkieSourceOfFundingSelectColumn {
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "pkie.source_of_funding" */
export interface PkieSourceOfFundingSetInput {
  source?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_source_of_funding" */
export interface PkieSourceOfFundingStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieSourceOfFundingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieSourceOfFundingStreamCursorValueInput {
  source?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.source_of_funding" */
export enum PkieSourceOfFundingUpdateColumn {
  /** column name */
  Source = 'source'
}

export interface PkieSourceOfFundingUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieSourceOfFundingSetInput>;
  /** filter the rows which have to be updated */
  where: PkieSourceOfFundingBoolExp;
}

/** contains types of alignment act */
export interface PkieTypeAlignmentAct {
  __typename?: 'pkie_type_alignment_act';
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  type: Scalars['String']['output'];
}


/** contains types of alignment act */
export interface PkieTypeAlignmentActMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** contains types of alignment act */
export interface PkieTypeAlignmentActMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActAggregate {
  __typename?: 'pkie_type_alignment_act_aggregate';
  aggregate?: Maybe<PkieTypeAlignmentActAggregateFields>;
  nodes: Array<PkieTypeAlignmentAct>;
}

/** aggregate fields of "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActAggregateFields {
  __typename?: 'pkie_type_alignment_act_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieTypeAlignmentActMaxFields>;
  min?: Maybe<PkieTypeAlignmentActMinFields>;
}


/** aggregate fields of "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieTypeAlignmentActSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.type_alignment_act". All fields are combined with a logical 'AND'. */
export interface PkieTypeAlignmentActBoolExp {
  _and?: InputMaybe<Array<PkieTypeAlignmentActBoolExp>>;
  _not?: InputMaybe<PkieTypeAlignmentActBoolExp>;
  _or?: InputMaybe<Array<PkieTypeAlignmentActBoolExp>>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.type_alignment_act" */
export enum PkieTypeAlignmentActConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeAlignmentActPkey = 'type_alignment_act_pkey'
}

export enum PkieTypeAlignmentActEnum {
  DokumentPolitikash = 'DOKUMENT_POLITIKASH',
  Ligj = 'LIGJ',
  Udhezim = 'UDHEZIM',
  Urdher = 'URDHER',
  Vkm = 'VKM'
}

/** Boolean expression to compare columns of type "pkie_type_alignment_act_enum". All fields are combined with logical 'AND'. */
export interface PkieTypeAlignmentActEnumComparisonExp {
  _eq?: InputMaybe<PkieTypeAlignmentActEnum>;
  _in?: InputMaybe<Array<PkieTypeAlignmentActEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieTypeAlignmentActEnum>;
  _nin?: InputMaybe<Array<PkieTypeAlignmentActEnum>>;
}

/** input type for inserting data into table "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActInsertInput {
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieTypeAlignmentActMaxFields {
  __typename?: 'pkie_type_alignment_act_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieTypeAlignmentActMinFields {
  __typename?: 'pkie_type_alignment_act_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActMutationResponse {
  __typename?: 'pkie_type_alignment_act_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieTypeAlignmentAct>;
}

/** input type for inserting object relation for remote table "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActObjRelInsertInput {
  data: PkieTypeAlignmentActInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieTypeAlignmentActOnConflict>;
}

/** on_conflict condition type for table "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActOnConflict {
  constraint: PkieTypeAlignmentActConstraint;
  update_columns?: Array<PkieTypeAlignmentActUpdateColumn>;
  where?: InputMaybe<PkieTypeAlignmentActBoolExp>;
}

/** Ordering options when selecting data from "pkie.type_alignment_act". */
export interface PkieTypeAlignmentActOrderBy {
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.type_alignment_act */
export interface PkieTypeAlignmentActPkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "pkie.type_alignment_act" */
export enum PkieTypeAlignmentActSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "pkie.type_alignment_act" */
export interface PkieTypeAlignmentActSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_type_alignment_act" */
export interface PkieTypeAlignmentActStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieTypeAlignmentActStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieTypeAlignmentActStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.type_alignment_act" */
export enum PkieTypeAlignmentActUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface PkieTypeAlignmentActUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieTypeAlignmentActSetInput>;
  /** filter the rows which have to be updated */
  where: PkieTypeAlignmentActBoolExp;
}

/** Contains types of measures to be taken for transposition */
export interface PkieTypeMeasure {
  __typename?: 'pkie_type_measure';
  measure: Scalars['String']['output'];
}

/** aggregated selection of "pkie.type_measure" */
export interface PkieTypeMeasureAggregate {
  __typename?: 'pkie_type_measure_aggregate';
  aggregate?: Maybe<PkieTypeMeasureAggregateFields>;
  nodes: Array<PkieTypeMeasure>;
}

/** aggregate fields of "pkie.type_measure" */
export interface PkieTypeMeasureAggregateFields {
  __typename?: 'pkie_type_measure_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieTypeMeasureMaxFields>;
  min?: Maybe<PkieTypeMeasureMinFields>;
}


/** aggregate fields of "pkie.type_measure" */
export interface PkieTypeMeasureAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieTypeMeasureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.type_measure". All fields are combined with a logical 'AND'. */
export interface PkieTypeMeasureBoolExp {
  _and?: InputMaybe<Array<PkieTypeMeasureBoolExp>>;
  _not?: InputMaybe<PkieTypeMeasureBoolExp>;
  _or?: InputMaybe<Array<PkieTypeMeasureBoolExp>>;
  measure?: InputMaybe<StringComparisonExp>;
}

/** types of budgets in measures  */
export interface PkieTypeMeasureBudget {
  __typename?: 'pkie_type_measure_budget';
  /** An array relationship */
  measure_budgets: Array<PkieMeasureBudgets>;
  /** An aggregate relationship */
  measure_budgets_aggregate: PkieMeasureBudgetsAggregate;
  type: Scalars['String']['output'];
}


/** types of budgets in measures  */
export interface PkieTypeMeasureBudgetMeasureBudgetsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


/** types of budgets in measures  */
export interface PkieTypeMeasureBudgetMeasureBudgetsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}

/** aggregated selection of "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetAggregate {
  __typename?: 'pkie_type_measure_budget_aggregate';
  aggregate?: Maybe<PkieTypeMeasureBudgetAggregateFields>;
  nodes: Array<PkieTypeMeasureBudget>;
}

/** aggregate fields of "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetAggregateFields {
  __typename?: 'pkie_type_measure_budget_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieTypeMeasureBudgetMaxFields>;
  min?: Maybe<PkieTypeMeasureBudgetMinFields>;
}


/** aggregate fields of "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieTypeMeasureBudgetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.type_measure_budget". All fields are combined with a logical 'AND'. */
export interface PkieTypeMeasureBudgetBoolExp {
  _and?: InputMaybe<Array<PkieTypeMeasureBudgetBoolExp>>;
  _not?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
  _or?: InputMaybe<Array<PkieTypeMeasureBudgetBoolExp>>;
  measure_budgets?: InputMaybe<PkieMeasureBudgetsBoolExp>;
  measure_budgets_aggregate?: InputMaybe<PkieMeasureBudgetsAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "pkie.type_measure_budget" */
export enum PkieTypeMeasureBudgetConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeMeasureBudgetPkey = 'type_measure_budget_pkey'
}

export enum PkieTypeMeasureBudgetEnum {
  Existing = 'EXISTING',
  Extra = 'EXTRA'
}

/** Boolean expression to compare columns of type "pkie_type_measure_budget_enum". All fields are combined with logical 'AND'. */
export interface PkieTypeMeasureBudgetEnumComparisonExp {
  _eq?: InputMaybe<PkieTypeMeasureBudgetEnum>;
  _in?: InputMaybe<Array<PkieTypeMeasureBudgetEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieTypeMeasureBudgetEnum>;
  _nin?: InputMaybe<Array<PkieTypeMeasureBudgetEnum>>;
}

/** input type for inserting data into table "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetInsertInput {
  measure_budgets?: InputMaybe<PkieMeasureBudgetsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieTypeMeasureBudgetMaxFields {
  __typename?: 'pkie_type_measure_budget_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieTypeMeasureBudgetMinFields {
  __typename?: 'pkie_type_measure_budget_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetMutationResponse {
  __typename?: 'pkie_type_measure_budget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieTypeMeasureBudget>;
}

/** input type for inserting object relation for remote table "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetObjRelInsertInput {
  data: PkieTypeMeasureBudgetInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieTypeMeasureBudgetOnConflict>;
}

/** on_conflict condition type for table "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetOnConflict {
  constraint: PkieTypeMeasureBudgetConstraint;
  update_columns?: Array<PkieTypeMeasureBudgetUpdateColumn>;
  where?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
}

/** Ordering options when selecting data from "pkie.type_measure_budget". */
export interface PkieTypeMeasureBudgetOrderBy {
  measure_budgets_aggregate?: InputMaybe<PkieMeasureBudgetsAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.type_measure_budget */
export interface PkieTypeMeasureBudgetPkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "pkie.type_measure_budget" */
export enum PkieTypeMeasureBudgetSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "pkie.type_measure_budget" */
export interface PkieTypeMeasureBudgetSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_type_measure_budget" */
export interface PkieTypeMeasureBudgetStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieTypeMeasureBudgetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieTypeMeasureBudgetStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.type_measure_budget" */
export enum PkieTypeMeasureBudgetUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface PkieTypeMeasureBudgetUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieTypeMeasureBudgetSetInput>;
  /** filter the rows which have to be updated */
  where: PkieTypeMeasureBudgetBoolExp;
}

/** unique or primary key constraints on table "pkie.type_measure" */
export enum PkieTypeMeasureConstraint {
  /** unique or primary key constraint on columns "measure" */
  TypeMeasurePkey = 'type_measure_pkey'
}

export enum PkieTypeMeasureEnum {
  Investments = 'INVESTMENTS',
  LegalPolicy = 'LEGAL_POLICY',
  INstitutionalAdministrative = 'iNSTITUTIONAL_ADMINISTRATIVE'
}

/** Boolean expression to compare columns of type "pkie_type_measure_enum". All fields are combined with logical 'AND'. */
export interface PkieTypeMeasureEnumComparisonExp {
  _eq?: InputMaybe<PkieTypeMeasureEnum>;
  _in?: InputMaybe<Array<PkieTypeMeasureEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieTypeMeasureEnum>;
  _nin?: InputMaybe<Array<PkieTypeMeasureEnum>>;
}

/** input type for inserting data into table "pkie.type_measure" */
export interface PkieTypeMeasureInsertInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PkieTypeMeasureMaxFields {
  __typename?: 'pkie_type_measure_max_fields';
  measure?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieTypeMeasureMinFields {
  __typename?: 'pkie_type_measure_min_fields';
  measure?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.type_measure" */
export interface PkieTypeMeasureMutationResponse {
  __typename?: 'pkie_type_measure_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieTypeMeasure>;
}

/** input type for inserting object relation for remote table "pkie.type_measure" */
export interface PkieTypeMeasureObjRelInsertInput {
  data: PkieTypeMeasureInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieTypeMeasureOnConflict>;
}

/** on_conflict condition type for table "pkie.type_measure" */
export interface PkieTypeMeasureOnConflict {
  constraint: PkieTypeMeasureConstraint;
  update_columns?: Array<PkieTypeMeasureUpdateColumn>;
  where?: InputMaybe<PkieTypeMeasureBoolExp>;
}

/** Ordering options when selecting data from "pkie.type_measure". */
export interface PkieTypeMeasureOrderBy {
  measure?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.type_measure */
export interface PkieTypeMeasurePkColumnsInput {
  measure: Scalars['String']['input'];
}

/** select columns of table "pkie.type_measure" */
export enum PkieTypeMeasureSelectColumn {
  /** column name */
  Measure = 'measure'
}

/** input type for updating data in table "pkie.type_measure" */
export interface PkieTypeMeasureSetInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_type_measure" */
export interface PkieTypeMeasureStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieTypeMeasureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieTypeMeasureStreamCursorValueInput {
  measure?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.type_measure" */
export enum PkieTypeMeasureUpdateColumn {
  /** column name */
  Measure = 'measure'
}

export interface PkieTypeMeasureUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieTypeMeasureSetInput>;
  /** filter the rows which have to be updated */
  where: PkieTypeMeasureBoolExp;
}

/** comments on work plan */
export interface PkieWorkPlanComment {
  __typename?: 'pkie_work_plan_comment';
  by_user_id: Scalars['uuid']['output'];
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  work_plan_task: PkieWorkPlanTask;
  work_plan_task_id: Scalars['uuid']['output'];
}

/** aggregated selection of "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentAggregate {
  __typename?: 'pkie_work_plan_comment_aggregate';
  aggregate?: Maybe<PkieWorkPlanCommentAggregateFields>;
  nodes: Array<PkieWorkPlanComment>;
}

export interface PkieWorkPlanCommentAggregateBoolExp {
  count?: InputMaybe<PkieWorkPlanCommentAggregateBoolExpCount>;
}

export interface PkieWorkPlanCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieWorkPlanCommentBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentAggregateFields {
  __typename?: 'pkie_work_plan_comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieWorkPlanCommentMaxFields>;
  min?: Maybe<PkieWorkPlanCommentMinFields>;
}


/** aggregate fields of "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieWorkPlanCommentMaxOrderBy>;
  min?: InputMaybe<PkieWorkPlanCommentMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentArrRelInsertInput {
  data: Array<PkieWorkPlanCommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlanCommentOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.work_plan_comment". All fields are combined with a logical 'AND'. */
export interface PkieWorkPlanCommentBoolExp {
  _and?: InputMaybe<Array<PkieWorkPlanCommentBoolExp>>;
  _not?: InputMaybe<PkieWorkPlanCommentBoolExp>;
  _or?: InputMaybe<Array<PkieWorkPlanCommentBoolExp>>;
  by_user_id?: InputMaybe<UuidComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "pkie.work_plan_comment" */
export enum PkieWorkPlanCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanCommentPkey = 'work_plan_comment_pkey'
}

/** input type for inserting data into table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface PkieWorkPlanCommentMaxFields {
  __typename?: 'pkie_work_plan_comment_max_fields';
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentMaxOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieWorkPlanCommentMinFields {
  __typename?: 'pkie_work_plan_comment_min_fields';
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentMinOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentMutationResponse {
  __typename?: 'pkie_work_plan_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieWorkPlanComment>;
}

/** on_conflict condition type for table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentOnConflict {
  constraint: PkieWorkPlanCommentConstraint;
  update_columns?: Array<PkieWorkPlanCommentUpdateColumn>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}

/** Ordering options when selecting data from "pkie.work_plan_comment". */
export interface PkieWorkPlanCommentOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskOrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.work_plan_comment */
export interface PkieWorkPlanCommentPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.work_plan_comment" */
export enum PkieWorkPlanCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

/** input type for updating data in table "pkie.work_plan_comment" */
export interface PkieWorkPlanCommentSetInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "pkie_work_plan_comment" */
export interface PkieWorkPlanCommentStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieWorkPlanCommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieWorkPlanCommentStreamCursorValueInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "pkie.work_plan_comment" */
export enum PkieWorkPlanCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface PkieWorkPlanCommentUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieWorkPlanCommentSetInput>;
  /** filter the rows which have to be updated */
  where: PkieWorkPlanCommentBoolExp;
}

/** contains statuses of work_plans */
export interface PkieWorkPlanStatuses {
  __typename?: 'pkie_work_plan_statuses';
  status: Scalars['String']['output'];
  /** An array relationship */
  work_plans: Array<PkieWorkPlans>;
  /** An aggregate relationship */
  work_plans_aggregate: PkieWorkPlansAggregate;
}


/** contains statuses of work_plans */
export interface PkieWorkPlanStatusesWorkPlansArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlansOrderBy>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}


/** contains statuses of work_plans */
export interface PkieWorkPlanStatusesWorkPlansAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlansOrderBy>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}

/** aggregated selection of "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesAggregate {
  __typename?: 'pkie_work_plan_statuses_aggregate';
  aggregate?: Maybe<PkieWorkPlanStatusesAggregateFields>;
  nodes: Array<PkieWorkPlanStatuses>;
}

/** aggregate fields of "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesAggregateFields {
  __typename?: 'pkie_work_plan_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieWorkPlanStatusesMaxFields>;
  min?: Maybe<PkieWorkPlanStatusesMinFields>;
}


/** aggregate fields of "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieWorkPlanStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.work_plan_statuses". All fields are combined with a logical 'AND'. */
export interface PkieWorkPlanStatusesBoolExp {
  _and?: InputMaybe<Array<PkieWorkPlanStatusesBoolExp>>;
  _not?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
  _or?: InputMaybe<Array<PkieWorkPlanStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
  work_plans?: InputMaybe<PkieWorkPlansBoolExp>;
  work_plans_aggregate?: InputMaybe<PkieWorkPlansAggregateBoolExp>;
}

/** unique or primary key constraints on table "pkie.work_plan_statuses" */
export enum PkieWorkPlanStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanStatusesPkey = 'work_plan_statuses_pkey'
}

export enum PkieWorkPlanStatusesEnum {
  Doing = 'DOING',
  Done = 'DONE',
  Draft = 'DRAFT'
}

/** Boolean expression to compare columns of type "pkie_work_plan_statuses_enum". All fields are combined with logical 'AND'. */
export interface PkieWorkPlanStatusesEnumComparisonExp {
  _eq?: InputMaybe<PkieWorkPlanStatusesEnum>;
  _in?: InputMaybe<Array<PkieWorkPlanStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieWorkPlanStatusesEnum>;
  _nin?: InputMaybe<Array<PkieWorkPlanStatusesEnum>>;
}

/** input type for inserting data into table "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plans?: InputMaybe<PkieWorkPlansArrRelInsertInput>;
}

/** aggregate max on columns */
export interface PkieWorkPlanStatusesMaxFields {
  __typename?: 'pkie_work_plan_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieWorkPlanStatusesMinFields {
  __typename?: 'pkie_work_plan_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesMutationResponse {
  __typename?: 'pkie_work_plan_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieWorkPlanStatuses>;
}

/** input type for inserting object relation for remote table "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesObjRelInsertInput {
  data: PkieWorkPlanStatusesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlanStatusesOnConflict>;
}

/** on_conflict condition type for table "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesOnConflict {
  constraint: PkieWorkPlanStatusesConstraint;
  update_columns?: Array<PkieWorkPlanStatusesUpdateColumn>;
  where?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
}

/** Ordering options when selecting data from "pkie.work_plan_statuses". */
export interface PkieWorkPlanStatusesOrderBy {
  status?: InputMaybe<OrderBy>;
  work_plans_aggregate?: InputMaybe<PkieWorkPlansAggregateOrderBy>;
}

/** primary key columns input for table: pkie.work_plan_statuses */
export interface PkieWorkPlanStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "pkie.work_plan_statuses" */
export enum PkieWorkPlanStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "pkie.work_plan_statuses" */
export interface PkieWorkPlanStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_work_plan_statuses" */
export interface PkieWorkPlanStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieWorkPlanStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieWorkPlanStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.work_plan_statuses" */
export enum PkieWorkPlanStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface PkieWorkPlanStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieWorkPlanStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: PkieWorkPlanStatusesBoolExp;
}

/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTask {
  __typename?: 'pkie_work_plan_task';
  /** An array relationship */
  acquis_work_plan_tasks: Array<PkieAcquisWorkPlanTask>;
  /** An aggregate relationship */
  acquis_work_plan_tasks_aggregate: PkieAcquisWorkPlanTaskAggregate;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  chief_gnpie_secretariat?: Maybe<Users>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  coordinator?: Maybe<Users>;
  coordinator_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deadline?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  gnpie_member?: Maybe<Users>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_secretariat?: Maybe<Users>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  negotiator?: Maybe<Users>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  reporter_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  signed_documents: Array<SignedDocuments>;
  /** An aggregate relationship */
  signed_documents_aggregate: SignedDocumentsAggregate;
  status: PkieWorkPlanTaskStatusesEnum;
  text?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  userByUpdatedByUserId?: Maybe<Users>;
  /** An object relationship */
  work_plan: PkieWorkPlans;
  /** An array relationship */
  work_plan_comments: Array<PkieWorkPlanComment>;
  /** An aggregate relationship */
  work_plan_comments_aggregate: PkieWorkPlanCommentAggregate;
  work_plan_id: Scalars['uuid']['output'];
  /** An array relationship */
  work_plan_task_activities: Array<PkieWorkPlanTaskActivity>;
  /** An aggregate relationship */
  work_plan_task_activities_aggregate: PkieWorkPlanTaskActivityAggregate;
  /** An array relationship */
  work_plan_task_activity: Array<PkieWorkPlanTaskActivity>;
  /** An aggregate relationship */
  work_plan_task_activity_aggregate: PkieWorkPlanTaskActivityAggregate;
  /** An object relationship */
  work_plan_task_status: PkieWorkPlanTaskStatuses;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskAcquisWorkPlanTasksArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskAcquisWorkPlanTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskSignedDocumentsArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskSignedDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskWorkPlanCommentsArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanCommentOrderBy>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskWorkPlanCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanCommentOrderBy>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskWorkPlanTaskActivitiesArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskWorkPlanTaskActivitiesAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskWorkPlanTaskActivityArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


/** a work_plan_task is chapter based and maintains the full definition of the work_plan from chapter perspective. It is automatically generated on creation of a work_plan */
export interface PkieWorkPlanTaskWorkPlanTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}

/** maintains lof for activity around work_plan_task  */
export interface PkieWorkPlanTaskActivity {
  __typename?: 'pkie_work_plan_task_activity';
  activity: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  status?: Maybe<PkieWorkPlanTaskStatusesEnum>;
  /** An object relationship */
  work_plan_task: PkieWorkPlanTask;
  work_plan_task_id: Scalars['uuid']['output'];
}

/** aggregated selection of "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityAggregate {
  __typename?: 'pkie_work_plan_task_activity_aggregate';
  aggregate?: Maybe<PkieWorkPlanTaskActivityAggregateFields>;
  nodes: Array<PkieWorkPlanTaskActivity>;
}

export interface PkieWorkPlanTaskActivityAggregateBoolExp {
  count?: InputMaybe<PkieWorkPlanTaskActivityAggregateBoolExpCount>;
}

export interface PkieWorkPlanTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityAggregateFields {
  __typename?: 'pkie_work_plan_task_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieWorkPlanTaskActivityMaxFields>;
  min?: Maybe<PkieWorkPlanTaskActivityMinFields>;
}


/** aggregate fields of "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieWorkPlanTaskActivityMaxOrderBy>;
  min?: InputMaybe<PkieWorkPlanTaskActivityMinOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityArrRelInsertInput {
  data: Array<PkieWorkPlanTaskActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlanTaskActivityOnConflict>;
}

/** Boolean expression to filter rows from the table "pkie.work_plan_task_activity". All fields are combined with a logical 'AND'. */
export interface PkieWorkPlanTaskActivityBoolExp {
  _and?: InputMaybe<Array<PkieWorkPlanTaskActivityBoolExp>>;
  _not?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
  _or?: InputMaybe<Array<PkieWorkPlanTaskActivityBoolExp>>;
  activity?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnumComparisonExp>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "pkie.work_plan_task_activity" */
export enum PkieWorkPlanTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskActivityPkey = 'work_plan_task_activity_pkey'
}

/** input type for inserting data into table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface PkieWorkPlanTaskActivityMaxFields {
  __typename?: 'pkie_work_plan_task_activity_max_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityMaxOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieWorkPlanTaskActivityMinFields {
  __typename?: 'pkie_work_plan_task_activity_min_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityMinOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityMutationResponse {
  __typename?: 'pkie_work_plan_task_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieWorkPlanTaskActivity>;
}

/** on_conflict condition type for table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityOnConflict {
  constraint: PkieWorkPlanTaskActivityConstraint;
  update_columns?: Array<PkieWorkPlanTaskActivityUpdateColumn>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}

/** Ordering options when selecting data from "pkie.work_plan_task_activity". */
export interface PkieWorkPlanTaskActivityOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskOrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: pkie.work_plan_task_activity */
export interface PkieWorkPlanTaskActivityPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.work_plan_task_activity" */
export enum PkieWorkPlanTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

/** input type for updating data in table "pkie.work_plan_task_activity" */
export interface PkieWorkPlanTaskActivitySetInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "pkie_work_plan_task_activity" */
export interface PkieWorkPlanTaskActivityStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieWorkPlanTaskActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieWorkPlanTaskActivityStreamCursorValueInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "pkie.work_plan_task_activity" */
export enum PkieWorkPlanTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface PkieWorkPlanTaskActivityUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieWorkPlanTaskActivitySetInput>;
  /** filter the rows which have to be updated */
  where: PkieWorkPlanTaskActivityBoolExp;
}

/** aggregated selection of "pkie.work_plan_task" */
export interface PkieWorkPlanTaskAggregate {
  __typename?: 'pkie_work_plan_task_aggregate';
  aggregate?: Maybe<PkieWorkPlanTaskAggregateFields>;
  nodes: Array<PkieWorkPlanTask>;
}

export interface PkieWorkPlanTaskAggregateBoolExp {
  count?: InputMaybe<PkieWorkPlanTaskAggregateBoolExpCount>;
}

export interface PkieWorkPlanTaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.work_plan_task" */
export interface PkieWorkPlanTaskAggregateFields {
  __typename?: 'pkie_work_plan_task_aggregate_fields';
  avg?: Maybe<PkieWorkPlanTaskAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieWorkPlanTaskMaxFields>;
  min?: Maybe<PkieWorkPlanTaskMinFields>;
  stddev?: Maybe<PkieWorkPlanTaskStddevFields>;
  stddev_pop?: Maybe<PkieWorkPlanTaskStddevPopFields>;
  stddev_samp?: Maybe<PkieWorkPlanTaskStddevSampFields>;
  sum?: Maybe<PkieWorkPlanTaskSumFields>;
  var_pop?: Maybe<PkieWorkPlanTaskVarPopFields>;
  var_samp?: Maybe<PkieWorkPlanTaskVarSampFields>;
  variance?: Maybe<PkieWorkPlanTaskVarianceFields>;
}


/** aggregate fields of "pkie.work_plan_task" */
export interface PkieWorkPlanTaskAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskAggregateOrderBy {
  avg?: InputMaybe<PkieWorkPlanTaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieWorkPlanTaskMaxOrderBy>;
  min?: InputMaybe<PkieWorkPlanTaskMinOrderBy>;
  stddev?: InputMaybe<PkieWorkPlanTaskStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieWorkPlanTaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieWorkPlanTaskStddevSampOrderBy>;
  sum?: InputMaybe<PkieWorkPlanTaskSumOrderBy>;
  var_pop?: InputMaybe<PkieWorkPlanTaskVarPopOrderBy>;
  var_samp?: InputMaybe<PkieWorkPlanTaskVarSampOrderBy>;
  variance?: InputMaybe<PkieWorkPlanTaskVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskArrRelInsertInput {
  data: Array<PkieWorkPlanTaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlanTaskOnConflict>;
}

/** aggregate avg on columns */
export interface PkieWorkPlanTaskAvgFields {
  __typename?: 'pkie_work_plan_task_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.work_plan_task". All fields are combined with a logical 'AND'. */
export interface PkieWorkPlanTaskBoolExp {
  _and?: InputMaybe<Array<PkieWorkPlanTaskBoolExp>>;
  _not?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  _or?: InputMaybe<Array<PkieWorkPlanTaskBoolExp>>;
  acquis_work_plan_tasks?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
  acquis_work_plan_tasks_aggregate?: InputMaybe<PkieAcquisWorkPlanTaskAggregateBoolExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  coordinator?: InputMaybe<UsersBoolExp>;
  coordinator_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  negotiator?: InputMaybe<UsersBoolExp>;
  negotiator_id?: InputMaybe<UuidComparisonExp>;
  reporter_id?: InputMaybe<UuidComparisonExp>;
  signed_documents?: InputMaybe<SignedDocumentsBoolExp>;
  signed_documents_aggregate?: InputMaybe<SignedDocumentsAggregateBoolExp>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnumComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  updated_by_user_id?: InputMaybe<UuidComparisonExp>;
  userByUpdatedByUserId?: InputMaybe<UsersBoolExp>;
  work_plan?: InputMaybe<PkieWorkPlansBoolExp>;
  work_plan_comments?: InputMaybe<PkieWorkPlanCommentBoolExp>;
  work_plan_comments_aggregate?: InputMaybe<PkieWorkPlanCommentAggregateBoolExp>;
  work_plan_id?: InputMaybe<UuidComparisonExp>;
  work_plan_task_activities?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
  work_plan_task_activities_aggregate?: InputMaybe<PkieWorkPlanTaskActivityAggregateBoolExp>;
  work_plan_task_activity?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
  work_plan_task_activity_aggregate?: InputMaybe<PkieWorkPlanTaskActivityAggregateBoolExp>;
  work_plan_task_status?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}

/** unique or primary key constraints on table "pkie.work_plan_task" */
export enum PkieWorkPlanTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanTaskPkey = 'work_plan_task_pkey'
}

/** input type for incrementing numeric columns in table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskInsertInput {
  acquis_work_plan_tasks?: InputMaybe<PkieAcquisWorkPlanTaskArrRelInsertInput>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  chief_gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  coordinator?: InputMaybe<UsersObjRelInsertInput>;
  coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator?: InputMaybe<UsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  signed_documents?: InputMaybe<SignedDocumentsArrRelInsertInput>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  userByUpdatedByUserId?: InputMaybe<UsersObjRelInsertInput>;
  work_plan?: InputMaybe<PkieWorkPlansObjRelInsertInput>;
  work_plan_comments?: InputMaybe<PkieWorkPlanCommentArrRelInsertInput>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_task_activities?: InputMaybe<PkieWorkPlanTaskActivityArrRelInsertInput>;
  work_plan_task_activity?: InputMaybe<PkieWorkPlanTaskActivityArrRelInsertInput>;
  work_plan_task_status?: InputMaybe<PkieWorkPlanTaskStatusesObjRelInsertInput>;
}

/** aggregate max on columns */
export interface PkieWorkPlanTaskMaxFields {
  __typename?: 'pkie_work_plan_task_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  coordinator_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  reporter_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  coordinator_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  reporter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
  work_plan_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieWorkPlanTaskMinFields {
  __typename?: 'pkie_work_plan_task_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  coordinator_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  reporter_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  work_plan_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  coordinator_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  reporter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
  work_plan_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskMutationResponse {
  __typename?: 'pkie_work_plan_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieWorkPlanTask>;
}

/** input type for inserting object relation for remote table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskObjRelInsertInput {
  data: PkieWorkPlanTaskInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlanTaskOnConflict>;
}

/** on_conflict condition type for table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskOnConflict {
  constraint: PkieWorkPlanTaskConstraint;
  update_columns?: Array<PkieWorkPlanTaskUpdateColumn>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}

/** Ordering options when selecting data from "pkie.work_plan_task". */
export interface PkieWorkPlanTaskOrderBy {
  acquis_work_plan_tasks_aggregate?: InputMaybe<PkieAcquisWorkPlanTaskAggregateOrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  coordinator?: InputMaybe<UsersOrderBy>;
  coordinator_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator?: InputMaybe<UsersOrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  reporter_id?: InputMaybe<OrderBy>;
  signed_documents_aggregate?: InputMaybe<SignedDocumentsAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
  userByUpdatedByUserId?: InputMaybe<UsersOrderBy>;
  work_plan?: InputMaybe<PkieWorkPlansOrderBy>;
  work_plan_comments_aggregate?: InputMaybe<PkieWorkPlanCommentAggregateOrderBy>;
  work_plan_id?: InputMaybe<OrderBy>;
  work_plan_task_activities_aggregate?: InputMaybe<PkieWorkPlanTaskActivityAggregateOrderBy>;
  work_plan_task_activity_aggregate?: InputMaybe<PkieWorkPlanTaskActivityAggregateOrderBy>;
  work_plan_task_status?: InputMaybe<PkieWorkPlanTaskStatusesOrderBy>;
}

/** primary key columns input for table: pkie.work_plan_task */
export interface PkieWorkPlanTaskPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.work_plan_task" */
export enum PkieWorkPlanTaskSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CoordinatorId = 'coordinator_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

/** input type for updating data in table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** statuses that a work plan task can have */
export interface PkieWorkPlanTaskStatuses {
  __typename?: 'pkie_work_plan_task_statuses';
  status: Scalars['String']['output'];
  /** An array relationship */
  work_plan_tasks: Array<PkieWorkPlanTask>;
  /** An aggregate relationship */
  work_plan_tasks_aggregate: PkieWorkPlanTaskAggregate;
}


/** statuses that a work plan task can have */
export interface PkieWorkPlanTaskStatusesWorkPlanTasksArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


/** statuses that a work plan task can have */
export interface PkieWorkPlanTaskStatusesWorkPlanTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}

/** aggregated selection of "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesAggregate {
  __typename?: 'pkie_work_plan_task_statuses_aggregate';
  aggregate?: Maybe<PkieWorkPlanTaskStatusesAggregateFields>;
  nodes: Array<PkieWorkPlanTaskStatuses>;
}

/** aggregate fields of "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesAggregateFields {
  __typename?: 'pkie_work_plan_task_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PkieWorkPlanTaskStatusesMaxFields>;
  min?: Maybe<PkieWorkPlanTaskStatusesMinFields>;
}


/** aggregate fields of "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieWorkPlanTaskStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "pkie.work_plan_task_statuses". All fields are combined with a logical 'AND'. */
export interface PkieWorkPlanTaskStatusesBoolExp {
  _and?: InputMaybe<Array<PkieWorkPlanTaskStatusesBoolExp>>;
  _not?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<PkieWorkPlanTaskStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
  work_plan_tasks?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<PkieWorkPlanTaskAggregateBoolExp>;
}

/** unique or primary key constraints on table "pkie.work_plan_task_statuses" */
export enum PkieWorkPlanTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WorkPlanTaskStatusesPkey = 'work_plan_task_statuses_pkey'
}

export enum PkieWorkPlanTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

/** Boolean expression to compare columns of type "pkie_work_plan_task_statuses_enum". All fields are combined with logical 'AND'. */
export interface PkieWorkPlanTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  _in?: InputMaybe<Array<PkieWorkPlanTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  _nin?: InputMaybe<Array<PkieWorkPlanTaskStatusesEnum>>;
}

/** input type for inserting data into table "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
  work_plan_tasks?: InputMaybe<PkieWorkPlanTaskArrRelInsertInput>;
}

/** aggregate max on columns */
export interface PkieWorkPlanTaskStatusesMaxFields {
  __typename?: 'pkie_work_plan_task_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PkieWorkPlanTaskStatusesMinFields {
  __typename?: 'pkie_work_plan_task_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesMutationResponse {
  __typename?: 'pkie_work_plan_task_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieWorkPlanTaskStatuses>;
}

/** input type for inserting object relation for remote table "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesObjRelInsertInput {
  data: PkieWorkPlanTaskStatusesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlanTaskStatusesOnConflict>;
}

/** on_conflict condition type for table "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesOnConflict {
  constraint: PkieWorkPlanTaskStatusesConstraint;
  update_columns?: Array<PkieWorkPlanTaskStatusesUpdateColumn>;
  where?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}

/** Ordering options when selecting data from "pkie.work_plan_task_statuses". */
export interface PkieWorkPlanTaskStatusesOrderBy {
  status?: InputMaybe<OrderBy>;
  work_plan_tasks_aggregate?: InputMaybe<PkieWorkPlanTaskAggregateOrderBy>;
}

/** primary key columns input for table: pkie.work_plan_task_statuses */
export interface PkieWorkPlanTaskStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "pkie.work_plan_task_statuses" */
export enum PkieWorkPlanTaskStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "pkie.work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "pkie_work_plan_task_statuses" */
export interface PkieWorkPlanTaskStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieWorkPlanTaskStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieWorkPlanTaskStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "pkie.work_plan_task_statuses" */
export enum PkieWorkPlanTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface PkieWorkPlanTaskStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieWorkPlanTaskStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: PkieWorkPlanTaskStatusesBoolExp;
}

/** aggregate stddev on columns */
export interface PkieWorkPlanTaskStddevFields {
  __typename?: 'pkie_work_plan_task_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieWorkPlanTaskStddevPopFields {
  __typename?: 'pkie_work_plan_task_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieWorkPlanTaskStddevSampFields {
  __typename?: 'pkie_work_plan_task_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_work_plan_task" */
export interface PkieWorkPlanTaskStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieWorkPlanTaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieWorkPlanTaskStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<PkieWorkPlanTaskStatusesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_plan_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface PkieWorkPlanTaskSumFields {
  __typename?: 'pkie_work_plan_task_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** update columns of table "pkie.work_plan_task" */
export enum PkieWorkPlanTaskUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CoordinatorId = 'coordinator_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
  /** column name */
  WorkPlanId = 'work_plan_id'
}

export interface PkieWorkPlanTaskUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieWorkPlanTaskIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieWorkPlanTaskSetInput>;
  /** filter the rows which have to be updated */
  where: PkieWorkPlanTaskBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieWorkPlanTaskVarPopFields {
  __typename?: 'pkie_work_plan_task_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieWorkPlanTaskVarSampFields {
  __typename?: 'pkie_work_plan_task_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieWorkPlanTaskVarianceFields {
  __typename?: 'pkie_work_plan_task_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.work_plan_task" */
export interface PkieWorkPlanTaskVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** National plan for acquis adaptions  */
export interface PkieWorkPlans {
  __typename?: 'pkie_work_plans';
  attachment_file_id?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  definition_deadline: Scalars['date']['output'];
  dms_id?: Maybe<Scalars['numeric']['output']>;
  end_year: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  start_year: Scalars['Int']['output'];
  status: PkieWorkPlanStatusesEnum;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  work_plan_status: PkieWorkPlanStatuses;
  /** An array relationship */
  work_plan_tasks: Array<PkieWorkPlanTask>;
  /** An aggregate relationship */
  work_plan_tasks_aggregate: PkieWorkPlanTaskAggregate;
}


/** National plan for acquis adaptions  */
export interface PkieWorkPlansWorkPlanTasksArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


/** National plan for acquis adaptions  */
export interface PkieWorkPlansWorkPlanTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}

/** aggregated selection of "pkie.work_plans" */
export interface PkieWorkPlansAggregate {
  __typename?: 'pkie_work_plans_aggregate';
  aggregate?: Maybe<PkieWorkPlansAggregateFields>;
  nodes: Array<PkieWorkPlans>;
}

export interface PkieWorkPlansAggregateBoolExp {
  bool_and?: InputMaybe<PkieWorkPlansAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PkieWorkPlansAggregateBoolExpBoolOr>;
  count?: InputMaybe<PkieWorkPlansAggregateBoolExpCount>;
}

export interface PkieWorkPlansAggregateBoolExpBoolAnd {
  arguments: PkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieWorkPlansBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieWorkPlansAggregateBoolExpBoolOr {
  arguments: PkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieWorkPlansBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface PkieWorkPlansAggregateBoolExpCount {
  arguments?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PkieWorkPlansBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "pkie.work_plans" */
export interface PkieWorkPlansAggregateFields {
  __typename?: 'pkie_work_plans_aggregate_fields';
  avg?: Maybe<PkieWorkPlansAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PkieWorkPlansMaxFields>;
  min?: Maybe<PkieWorkPlansMinFields>;
  stddev?: Maybe<PkieWorkPlansStddevFields>;
  stddev_pop?: Maybe<PkieWorkPlansStddevPopFields>;
  stddev_samp?: Maybe<PkieWorkPlansStddevSampFields>;
  sum?: Maybe<PkieWorkPlansSumFields>;
  var_pop?: Maybe<PkieWorkPlansVarPopFields>;
  var_samp?: Maybe<PkieWorkPlansVarSampFields>;
  variance?: Maybe<PkieWorkPlansVarianceFields>;
}


/** aggregate fields of "pkie.work_plans" */
export interface PkieWorkPlansAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "pkie.work_plans" */
export interface PkieWorkPlansAggregateOrderBy {
  avg?: InputMaybe<PkieWorkPlansAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PkieWorkPlansMaxOrderBy>;
  min?: InputMaybe<PkieWorkPlansMinOrderBy>;
  stddev?: InputMaybe<PkieWorkPlansStddevOrderBy>;
  stddev_pop?: InputMaybe<PkieWorkPlansStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PkieWorkPlansStddevSampOrderBy>;
  sum?: InputMaybe<PkieWorkPlansSumOrderBy>;
  var_pop?: InputMaybe<PkieWorkPlansVarPopOrderBy>;
  var_samp?: InputMaybe<PkieWorkPlansVarSampOrderBy>;
  variance?: InputMaybe<PkieWorkPlansVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "pkie.work_plans" */
export interface PkieWorkPlansArrRelInsertInput {
  data: Array<PkieWorkPlansInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlansOnConflict>;
}

/** aggregate avg on columns */
export interface PkieWorkPlansAvgFields {
  __typename?: 'pkie_work_plans_avg_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansAvgOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "pkie.work_plans". All fields are combined with a logical 'AND'. */
export interface PkieWorkPlansBoolExp {
  _and?: InputMaybe<Array<PkieWorkPlansBoolExp>>;
  _not?: InputMaybe<PkieWorkPlansBoolExp>;
  _or?: InputMaybe<Array<PkieWorkPlansBoolExp>>;
  attachment_file_id?: InputMaybe<NumericComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  definition_deadline?: InputMaybe<DateComparisonExp>;
  dms_id?: InputMaybe<NumericComparisonExp>;
  end_year?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  start_year?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<PkieWorkPlanStatusesEnumComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  work_plan_status?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
  work_plan_tasks?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_tasks_aggregate?: InputMaybe<PkieWorkPlanTaskAggregateBoolExp>;
}

/** unique or primary key constraints on table "pkie.work_plans" */
export enum PkieWorkPlansConstraint {
  /** unique or primary key constraint on columns "id" */
  WorkPlanPkey = 'work_plan_pkey'
}

/** input type for incrementing numeric columns in table "pkie.work_plans" */
export interface PkieWorkPlansIncInput {
  attachment_file_id?: InputMaybe<Scalars['numeric']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "pkie.work_plans" */
export interface PkieWorkPlansInsertInput {
  attachment_file_id?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PkieWorkPlanStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_status?: InputMaybe<PkieWorkPlanStatusesObjRelInsertInput>;
  work_plan_tasks?: InputMaybe<PkieWorkPlanTaskArrRelInsertInput>;
}

/** aggregate max on columns */
export interface PkieWorkPlansMaxFields {
  __typename?: 'pkie_work_plans_max_fields';
  attachment_file_id?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  definition_deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['numeric']['output']>;
  end_year?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  start_year?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansMaxOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  definition_deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface PkieWorkPlansMinFields {
  __typename?: 'pkie_work_plans_min_fields';
  attachment_file_id?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  definition_deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['numeric']['output']>;
  end_year?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  start_year?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansMinOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  definition_deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "pkie.work_plans" */
export interface PkieWorkPlansMutationResponse {
  __typename?: 'pkie_work_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PkieWorkPlans>;
}

/** input type for inserting object relation for remote table "pkie.work_plans" */
export interface PkieWorkPlansObjRelInsertInput {
  data: PkieWorkPlansInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PkieWorkPlansOnConflict>;
}

/** on_conflict condition type for table "pkie.work_plans" */
export interface PkieWorkPlansOnConflict {
  constraint: PkieWorkPlansConstraint;
  update_columns?: Array<PkieWorkPlansUpdateColumn>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}

/** Ordering options when selecting data from "pkie.work_plans". */
export interface PkieWorkPlansOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  definition_deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  work_plan_status?: InputMaybe<PkieWorkPlanStatusesOrderBy>;
  work_plan_tasks_aggregate?: InputMaybe<PkieWorkPlanTaskAggregateOrderBy>;
}

/** primary key columns input for table: pkie.work_plans */
export interface PkieWorkPlansPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "pkie.work_plans" */
export enum PkieWorkPlansSelectColumn {
  /** column name */
  AttachmentFileId = 'attachment_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "pkie_work_plans_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pkie.work_plans" */
export enum PkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "pkie_work_plans_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pkie.work_plans" */
export enum PkieWorkPlansSelectColumnPkieWorkPlansAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "pkie.work_plans" */
export interface PkieWorkPlansSetInput {
  attachment_file_id?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PkieWorkPlanStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface PkieWorkPlansStddevFields {
  __typename?: 'pkie_work_plans_stddev_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansStddevOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface PkieWorkPlansStddevPopFields {
  __typename?: 'pkie_work_plans_stddev_pop_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansStddevPopOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface PkieWorkPlansStddevSampFields {
  __typename?: 'pkie_work_plans_stddev_samp_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansStddevSampOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "pkie_work_plans" */
export interface PkieWorkPlansStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PkieWorkPlansStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PkieWorkPlansStreamCursorValueInput {
  attachment_file_id?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  definition_deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  end_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  start_year?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PkieWorkPlanStatusesEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface PkieWorkPlansSumFields {
  __typename?: 'pkie_work_plans_sum_fields';
  attachment_file_id?: Maybe<Scalars['numeric']['output']>;
  dms_id?: Maybe<Scalars['numeric']['output']>;
  end_year?: Maybe<Scalars['Int']['output']>;
  start_year?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansSumOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** update columns of table "pkie.work_plans" */
export enum PkieWorkPlansUpdateColumn {
  /** column name */
  AttachmentFileId = 'attachment_file_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DefinitionDeadline = 'definition_deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface PkieWorkPlansUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PkieWorkPlansIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PkieWorkPlansSetInput>;
  /** filter the rows which have to be updated */
  where: PkieWorkPlansBoolExp;
}

/** aggregate var_pop on columns */
export interface PkieWorkPlansVarPopFields {
  __typename?: 'pkie_work_plans_var_pop_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansVarPopOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface PkieWorkPlansVarSampFields {
  __typename?: 'pkie_work_plans_var_samp_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansVarSampOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface PkieWorkPlansVarianceFields {
  __typename?: 'pkie_work_plans_variance_fields';
  attachment_file_id?: Maybe<Scalars['Float']['output']>;
  dms_id?: Maybe<Scalars['Float']['output']>;
  end_year?: Maybe<Scalars['Float']['output']>;
  start_year?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "pkie.work_plans" */
export interface PkieWorkPlansVarianceOrderBy {
  attachment_file_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  end_year?: InputMaybe<OrderBy>;
  start_year?: InputMaybe<OrderBy>;
}

export interface PmoCalendar {
  __typename?: 'pmo_calendar';
  deadline: Scalars['timestamp']['output'];
  end_date?: Maybe<Scalars['timestamp']['output']>;
  grand_parent_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['String']['output']>;
  task_name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

/** Boolean expression to filter rows from the logical model for "pmo_calendar". All fields are combined with a logical 'AND'. */
export interface PmoCalendarBoolExpBoolExp {
  _and?: InputMaybe<Array<PmoCalendarBoolExpBoolExp>>;
  _not?: InputMaybe<PmoCalendarBoolExpBoolExp>;
  _or?: InputMaybe<Array<PmoCalendarBoolExpBoolExp>>;
  deadline?: InputMaybe<TimestampComparisonExp>;
  end_date?: InputMaybe<TimestampComparisonExp>;
  grand_parent_id?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  parent_id?: InputMaybe<StringComparisonExp>;
  task_name?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
}

export enum PmoCalendarEnumName {
  /** column name */
  Deadline = 'deadline',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GrandParentId = 'grand_parent_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TaskName = 'task_name',
  /** column name */
  Type = 'type'
}

/** Ordering options when selecting data from "pmo_calendar". */
export interface PmoCalendarOrderBy {
  deadline?: InputMaybe<OrderBy>;
  end_date?: InputMaybe<OrderBy>;
  grand_parent_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  task_name?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
}

export interface QueryRoot {
  __typename?: 'query_root';
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequestsAggregate;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProvidersAggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypesAggregate;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokensAggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRolesAggregate;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProvidersAggregate;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRolesAggregate;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeysAggregate;
  /** fetch data from the table: "auth.modules" */
  auth_modules: Array<AuthModules>;
  /** fetch aggregated fields from the table: "auth.modules" */
  auth_modules_aggregate: AuthModulesAggregate;
  /** fetch data from the table: "auth.modules" using primary key columns */
  auth_modules_by_pk?: Maybe<AuthModules>;
  /** fetch data from the table: "bookmark_gnpie_members" */
  bookmark_gnpie_members: Array<BookmarkGnpieMembers>;
  /** fetch aggregated fields from the table: "bookmark_gnpie_members" */
  bookmark_gnpie_members_aggregate: BookmarkGnpieMembersAggregate;
  /** fetch data from the table: "bookmark_gnpie_members" using primary key columns */
  bookmark_gnpie_members_by_pk?: Maybe<BookmarkGnpieMembers>;
  /** fetch data from the table: "bookmark_status" */
  bookmark_status: Array<BookmarkStatus>;
  /** fetch aggregated fields from the table: "bookmark_status" */
  bookmark_status_aggregate: BookmarkStatusAggregate;
  /** fetch data from the table: "bookmark_status" using primary key columns */
  bookmark_status_by_pk?: Maybe<BookmarkStatus>;
  /** fetch data from the table: "bookmark_task_activity" */
  bookmark_task_activity: Array<BookmarkTaskActivity>;
  /** fetch aggregated fields from the table: "bookmark_task_activity" */
  bookmark_task_activity_aggregate: BookmarkTaskActivityAggregate;
  /** fetch data from the table: "bookmark_task_activity" using primary key columns */
  bookmark_task_activity_by_pk?: Maybe<BookmarkTaskActivity>;
  /** An array relationship */
  bookmark_task_answers: Array<BookmarkTaskAnswers>;
  /** An aggregate relationship */
  bookmark_task_answers_aggregate: BookmarkTaskAnswersAggregate;
  /** fetch data from the table: "bookmark_task_answers" using primary key columns */
  bookmark_task_answers_by_pk?: Maybe<BookmarkTaskAnswers>;
  /** fetch data from the table: "bookmark_task_comment" */
  bookmark_task_comment: Array<BookmarkTaskComment>;
  /** fetch aggregated fields from the table: "bookmark_task_comment" */
  bookmark_task_comment_aggregate: BookmarkTaskCommentAggregate;
  /** fetch data from the table: "bookmark_task_comment" using primary key columns */
  bookmark_task_comment_by_pk?: Maybe<BookmarkTaskComment>;
  /** fetch data from the table: "bookmark_task_measures" */
  bookmark_task_measures: Array<BookmarkTaskMeasures>;
  /** fetch aggregated fields from the table: "bookmark_task_measures" */
  bookmark_task_measures_aggregate: BookmarkTaskMeasuresAggregate;
  /** fetch data from the table: "bookmark_task_measures" using primary key columns */
  bookmark_task_measures_by_pk?: Maybe<BookmarkTaskMeasures>;
  /** fetch data from the table: "bookmark_task_statuses" */
  bookmark_task_statuses: Array<BookmarkTaskStatuses>;
  /** fetch aggregated fields from the table: "bookmark_task_statuses" */
  bookmark_task_statuses_aggregate: BookmarkTaskStatusesAggregate;
  /** fetch data from the table: "bookmark_task_statuses" using primary key columns */
  bookmark_task_statuses_by_pk?: Maybe<BookmarkTaskStatuses>;
  /** An array relationship */
  bookmark_tasks: Array<BookmarkTasks>;
  /** An aggregate relationship */
  bookmark_tasks_aggregate: BookmarkTasksAggregate;
  /** fetch data from the table: "bookmark_tasks" using primary key columns */
  bookmark_tasks_by_pk?: Maybe<BookmarkTasks>;
  /** An array relationship */
  bookmarks: Array<Bookmarks>;
  /** An aggregate relationship */
  bookmarks_aggregate: BookmarksAggregate;
  /** fetch data from the table: "bookmarks" using primary key columns */
  bookmarks_by_pk?: Maybe<Bookmarks>;
  calendarTasksOfChiefSecretariat: Array<MemberCalendar>;
  calendarTasksOfCoordinator: Array<NegotiatorCalendar>;
  calendarTasksOfHorizontalCoordinator: Array<NegotiatorCalendar>;
  calendarTasksOfMember: Array<MemberCalendar>;
  calendarTasksOfNegotiator: Array<NegotiatorCalendar>;
  calendarTasksOfPMO: Array<PmoCalendar>;
  calendarTasksOfSecretariat: Array<MemberCalendar>;
  /** fetch data from the table: "capac.beneficiaries" */
  capac_beneficiaries: Array<CapacBeneficiaries>;
  /** fetch aggregated fields from the table: "capac.beneficiaries" */
  capac_beneficiaries_aggregate: CapacBeneficiariesAggregate;
  /** fetch data from the table: "capac.beneficiaries" using primary key columns */
  capac_beneficiaries_by_pk?: Maybe<CapacBeneficiaries>;
  /** fetch data from the table: "capac.chapter_donator" */
  capac_chapter_donator: Array<CapacChapterDonator>;
  /** fetch aggregated fields from the table: "capac.chapter_donator" */
  capac_chapter_donator_aggregate: CapacChapterDonatorAggregate;
  /** fetch data from the table: "capac.chapter_donator" using primary key columns */
  capac_chapter_donator_by_pk?: Maybe<CapacChapterDonator>;
  /** fetch data from the table: "capac.donators" */
  capac_donators: Array<CapacDonators>;
  /** fetch aggregated fields from the table: "capac.donators" */
  capac_donators_aggregate: CapacDonatorsAggregate;
  /** fetch data from the table: "capac.donators" using primary key columns */
  capac_donators_by_pk?: Maybe<CapacDonators>;
  /** fetch data from the table: "capac.experts" */
  capac_experts: Array<CapacExperts>;
  /** fetch aggregated fields from the table: "capac.experts" */
  capac_experts_aggregate: CapacExpertsAggregate;
  /** fetch data from the table: "capac.experts" using primary key columns */
  capac_experts_by_pk?: Maybe<CapacExperts>;
  /** fetch data from the table: "capac.integration_steps" */
  capac_integration_steps: Array<CapacIntegrationSteps>;
  /** fetch aggregated fields from the table: "capac.integration_steps" */
  capac_integration_steps_aggregate: CapacIntegrationStepsAggregate;
  /** fetch data from the table: "capac.integration_steps" using primary key columns */
  capac_integration_steps_by_pk?: Maybe<CapacIntegrationSteps>;
  /** fetch data from the table: "capac.priority_lists" */
  capac_priority_lists: Array<CapacPriorityLists>;
  /** fetch aggregated fields from the table: "capac.priority_lists" */
  capac_priority_lists_aggregate: CapacPriorityListsAggregate;
  /** fetch data from the table: "capac.priority_lists" using primary key columns */
  capac_priority_lists_by_pk?: Maybe<CapacPriorityLists>;
  /** fetch data from the table: "capac.project_beneficiaries" */
  capac_project_beneficiaries: Array<CapacProjectBeneficiaries>;
  /** fetch aggregated fields from the table: "capac.project_beneficiaries" */
  capac_project_beneficiaries_aggregate: CapacProjectBeneficiariesAggregate;
  /** fetch data from the table: "capac.project_beneficiaries" using primary key columns */
  capac_project_beneficiaries_by_pk?: Maybe<CapacProjectBeneficiaries>;
  /** fetch data from the table: "capac.projects" */
  capac_projects: Array<CapacProjects>;
  /** fetch aggregated fields from the table: "capac.projects" */
  capac_projects_aggregate: CapacProjectsAggregate;
  /** fetch data from the table: "capac.projects" using primary key columns */
  capac_projects_by_pk?: Maybe<CapacProjects>;
  /** fetch data from the table: "capac.taiex" */
  capac_taiex: Array<CapacTaiex>;
  /** fetch aggregated fields from the table: "capac.taiex" */
  capac_taiex_aggregate: CapacTaiexAggregate;
  /** fetch data from the table: "capac.taiex" using primary key columns */
  capac_taiex_by_pk?: Maybe<CapacTaiex>;
  /** fetch data from the table: "capac.training_needs" */
  capac_training_needs: Array<CapacTrainingNeeds>;
  /** fetch aggregated fields from the table: "capac.training_needs" */
  capac_training_needs_aggregate: CapacTrainingNeedsAggregate;
  /** fetch data from the table: "capac.training_needs" using primary key columns */
  capac_training_needs_by_pk?: Maybe<CapacTrainingNeeds>;
  /** fetch data from the table: "capac.training_participants" */
  capac_training_participants: Array<CapacTrainingParticipants>;
  /** fetch aggregated fields from the table: "capac.training_participants" */
  capac_training_participants_aggregate: CapacTrainingParticipantsAggregate;
  /** fetch data from the table: "capac.training_participants" using primary key columns */
  capac_training_participants_by_pk?: Maybe<CapacTrainingParticipants>;
  /** fetch data from the table: "capac.training_sessions" */
  capac_training_sessions: Array<CapacTrainingSessions>;
  /** fetch aggregated fields from the table: "capac.training_sessions" */
  capac_training_sessions_aggregate: CapacTrainingSessionsAggregate;
  /** fetch data from the table: "capac.training_sessions" using primary key columns */
  capac_training_sessions_by_pk?: Maybe<CapacTrainingSessions>;
  /** fetch data from the table: "capac.trainings" */
  capac_trainings: Array<CapacTrainings>;
  /** fetch aggregated fields from the table: "capac.trainings" */
  capac_trainings_aggregate: CapacTrainingsAggregate;
  /** fetch data from the table: "capac.trainings" using primary key columns */
  capac_trainings_by_pk?: Maybe<CapacTrainings>;
  /** fetch data from the table: "capac.trainings_experts" */
  capac_trainings_experts: Array<CapacTrainingsExperts>;
  /** fetch aggregated fields from the table: "capac.trainings_experts" */
  capac_trainings_experts_aggregate: CapacTrainingsExpertsAggregate;
  /** fetch data from the table: "capac.trainings_experts" using primary key columns */
  capac_trainings_experts_by_pk?: Maybe<CapacTrainingsExperts>;
  /** fetch data from the table: "capac.twinning" */
  capac_twinning: Array<CapacTwinning>;
  /** fetch aggregated fields from the table: "capac.twinning" */
  capac_twinning_aggregate: CapacTwinningAggregate;
  /** fetch data from the table: "capac.twinning" using primary key columns */
  capac_twinning_by_pk?: Maybe<CapacTwinning>;
  /** An array relationship */
  chapter_lead_institutions: Array<ChapterLeadInstitutions>;
  /** An aggregate relationship */
  chapter_lead_institutions_aggregate: ChapterLeadInstitutionsAggregate;
  /** fetch data from the table: "chapter_lead_institutions" using primary key columns */
  chapter_lead_institutions_by_pk?: Maybe<ChapterLeadInstitutions>;
  /** An array relationship */
  chapters: Array<Chapters>;
  /** An aggregate relationship */
  chapters_aggregate: ChaptersAggregate;
  /** fetch data from the table: "chapters" using primary key columns */
  chapters_by_pk?: Maybe<Chapters>;
  /** fetch data from the table: "clusters" */
  clusters: Array<Clusters>;
  /** fetch aggregated fields from the table: "clusters" */
  clusters_aggregate: ClustersAggregate;
  /** fetch data from the table: "clusters" using primary key columns */
  clusters_by_pk?: Maybe<Clusters>;
  dashboardStatistics: Array<DashboardStatisticsEntity>;
  dashboardStatisticsAggregate: Array<DashboardAggregateEntity>;
  /** fetch data from the table: "dms.folder" */
  dms_folder: Array<DmsFolder>;
  /** fetch aggregated fields from the table: "dms.folder" */
  dms_folder_aggregate: DmsFolderAggregate;
  /** fetch data from the table: "dms.folder" using primary key columns */
  dms_folder_by_pk?: Maybe<DmsFolder>;
  /** fetch data from the table: "dms.folder_type" */
  dms_folder_type: Array<DmsFolderType>;
  /** fetch aggregated fields from the table: "dms.folder_type" */
  dms_folder_type_aggregate: DmsFolderTypeAggregate;
  /** fetch data from the table: "dms.folder_type" using primary key columns */
  dms_folder_type_by_pk?: Maybe<DmsFolderType>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: DocumentsAggregate;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table: "error_logs" */
  error_logs: Array<ErrorLogs>;
  /** fetch aggregated fields from the table: "error_logs" */
  error_logs_aggregate: ErrorLogsAggregate;
  /** fetch data from the table: "error_logs" using primary key columns */
  error_logs_by_pk?: Maybe<ErrorLogs>;
  /** An array relationship */
  event_notes: Array<EventNotes>;
  /** An aggregate relationship */
  event_notes_aggregate: EventNotesAggregate;
  /** fetch data from the table: "event_notes" using primary key columns */
  event_notes_by_pk?: Maybe<EventNotes>;
  /** fetch data from the table: "event_typology" */
  event_typology: Array<EventTypology>;
  /** fetch aggregated fields from the table: "event_typology" */
  event_typology_aggregate: EventTypologyAggregate;
  /** fetch data from the table: "event_typology" using primary key columns */
  event_typology_by_pk?: Maybe<EventTypology>;
  /** An array relationship */
  event_users: Array<EventUsers>;
  /** An aggregate relationship */
  event_users_aggregate: EventUsersAggregate;
  /** fetch data from the table: "event_users" using primary key columns */
  event_users_by_pk?: Maybe<EventUsers>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: EventsAggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  getFolderPath: Array<FolderPathModel>;
  /** fetch data from the table: "gnpie" */
  gnpie: Array<Gnpie>;
  gnpieUsersByChapter: Array<GnpieUser>;
  /** fetch aggregated fields from the table: "gnpie" */
  gnpie_aggregate: GnpieAggregate;
  /** fetch data from the table: "gnpie" using primary key columns */
  gnpie_by_pk?: Maybe<Gnpie>;
  /** An array relationship */
  institutions: Array<Institutions>;
  /** An aggregate relationship */
  institutions_aggregate: InstitutionsAggregate;
  /** fetch data from the table: "institutions" using primary key columns */
  institutions_by_pk?: Maybe<Institutions>;
  integrationPhaseProgres: Array<IntegrationPhaseProgres>;
  /** fetch data from the table: "integration_phases" */
  integration_phases: Array<IntegrationPhases>;
  /** fetch aggregated fields from the table: "integration_phases" */
  integration_phases_aggregate: IntegrationPhasesAggregate;
  /** fetch data from the table: "integration_phases" using primary key columns */
  integration_phases_by_pk?: Maybe<IntegrationPhases>;
  /** fetch data from the table: "integration_phases_enum" */
  integration_phases_enum: Array<IntegrationPhasesEnum>;
  /** fetch aggregated fields from the table: "integration_phases_enum" */
  integration_phases_enum_aggregate: IntegrationPhasesEnumAggregate;
  /** fetch data from the table: "integration_phases_enum" using primary key columns */
  integration_phases_enum_by_pk?: Maybe<IntegrationPhasesEnum>;
  /** fetch data from the table: "meeting_attendance" */
  meeting_attendance: Array<MeetingAttendance>;
  /** fetch aggregated fields from the table: "meeting_attendance" */
  meeting_attendance_aggregate: MeetingAttendanceAggregate;
  /** fetch data from the table: "meeting_attendance" using primary key columns */
  meeting_attendance_by_pk?: Maybe<MeetingAttendance>;
  /** An array relationship */
  meeting_notes: Array<MeetingNotes>;
  /** An aggregate relationship */
  meeting_notes_aggregate: MeetingNotesAggregate;
  /** fetch data from the table: "meeting_notes" using primary key columns */
  meeting_notes_by_pk?: Maybe<MeetingNotes>;
  /** An array relationship */
  meeting_users: Array<MeetingUsers>;
  /** An aggregate relationship */
  meeting_users_aggregate: MeetingUsersAggregate;
  /** fetch data from the table: "meeting_users" using primary key columns */
  meeting_users_by_pk?: Maybe<MeetingUsers>;
  /** An array relationship */
  meetings: Array<Meetings>;
  /** An aggregate relationship */
  meetings_aggregate: MeetingsAggregate;
  /** fetch data from the table: "meetings" using primary key columns */
  meetings_by_pk?: Maybe<Meetings>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: NotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_origin" */
  notification_origin: Array<NotificationOrigin>;
  /** fetch aggregated fields from the table: "notification_origin" */
  notification_origin_aggregate: NotificationOriginAggregate;
  /** fetch data from the table: "notification_origin" using primary key columns */
  notification_origin_by_pk?: Maybe<NotificationOrigin>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<NotificationType>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: NotificationTypeAggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<NotificationType>;
  nrOfInstitutionsPerChapter: Array<NrOfInstitutionsPerChapter>;
  nrOfUsersPerInstitution: Array<NrOfInstitutionsPerChapter>;
  /** fetch data from the table: "pkie.acquis" */
  pkie_acquis: Array<PkieAcquis>;
  /** fetch aggregated fields from the table: "pkie.acquis" */
  pkie_acquis_aggregate: PkieAcquisAggregate;
  /** fetch data from the table: "pkie.acquis" using primary key columns */
  pkie_acquis_by_pk?: Maybe<PkieAcquis>;
  /** fetch data from the table: "pkie.acquis_chapter" */
  pkie_acquis_chapter: Array<PkieAcquisChapter>;
  /** fetch aggregated fields from the table: "pkie.acquis_chapter" */
  pkie_acquis_chapter_aggregate: PkieAcquisChapterAggregate;
  /** fetch data from the table: "pkie.acquis_chapter" using primary key columns */
  pkie_acquis_chapter_by_pk?: Maybe<PkieAcquisChapter>;
  /** fetch data from the table: "pkie.acquis_institutions" */
  pkie_acquis_institutions: Array<PkieAcquisInstitutions>;
  /** fetch aggregated fields from the table: "pkie.acquis_institutions" */
  pkie_acquis_institutions_aggregate: PkieAcquisInstitutionsAggregate;
  /** fetch data from the table: "pkie.acquis_institutions" using primary key columns */
  pkie_acquis_institutions_by_pk?: Maybe<PkieAcquisInstitutions>;
  /** fetch data from the table: "pkie.acquis_national_acts" */
  pkie_acquis_national_acts: Array<PkieAcquisNationalActs>;
  /** fetch aggregated fields from the table: "pkie.acquis_national_acts" */
  pkie_acquis_national_acts_aggregate: PkieAcquisNationalActsAggregate;
  /** fetch data from the table: "pkie.acquis_national_acts" using primary key columns */
  pkie_acquis_national_acts_by_pk?: Maybe<PkieAcquisNationalActs>;
  /** fetch data from the table: "pkie.acquis_type" */
  pkie_acquis_type: Array<PkieAcquisType>;
  /** fetch aggregated fields from the table: "pkie.acquis_type" */
  pkie_acquis_type_aggregate: PkieAcquisTypeAggregate;
  /** fetch data from the table: "pkie.acquis_type" using primary key columns */
  pkie_acquis_type_by_pk?: Maybe<PkieAcquisType>;
  /** fetch data from the table: "pkie.acquis_work_plan_task" */
  pkie_acquis_work_plan_task: Array<PkieAcquisWorkPlanTask>;
  /** fetch aggregated fields from the table: "pkie.acquis_work_plan_task" */
  pkie_acquis_work_plan_task_aggregate: PkieAcquisWorkPlanTaskAggregate;
  /** fetch data from the table: "pkie.acquis_work_plan_task" using primary key columns */
  pkie_acquis_work_plan_task_by_pk?: Maybe<PkieAcquisWorkPlanTask>;
  /** fetch data from the table: "pkie.act_type" */
  pkie_act_type: Array<PkieActType>;
  /** fetch aggregated fields from the table: "pkie.act_type" */
  pkie_act_type_aggregate: PkieActTypeAggregate;
  /** fetch data from the table: "pkie.act_type" using primary key columns */
  pkie_act_type_by_pk?: Maybe<PkieActType>;
  /** fetch data from the table: "pkie.level_of_alignment" */
  pkie_level_of_alignment: Array<PkieLevelOfAlignment>;
  /** fetch aggregated fields from the table: "pkie.level_of_alignment" */
  pkie_level_of_alignment_aggregate: PkieLevelOfAlignmentAggregate;
  /** fetch data from the table: "pkie.level_of_alignment" using primary key columns */
  pkie_level_of_alignment_by_pk?: Maybe<PkieLevelOfAlignment>;
  /** fetch data from the table: "pkie.measure" */
  pkie_measure: Array<PkieMeasure>;
  /** fetch data from the table: "pkie.measure_activity" */
  pkie_measure_activity: Array<PkieMeasureActivity>;
  /** fetch aggregated fields from the table: "pkie.measure_activity" */
  pkie_measure_activity_aggregate: PkieMeasureActivityAggregate;
  /** fetch data from the table: "pkie.measure_activity" using primary key columns */
  pkie_measure_activity_by_pk?: Maybe<PkieMeasureActivity>;
  /** fetch aggregated fields from the table: "pkie.measure" */
  pkie_measure_aggregate: PkieMeasureAggregate;
  /** fetch data from the table: "pkie.measure_answers" */
  pkie_measure_answers: Array<PkieMeasureAnswers>;
  /** fetch aggregated fields from the table: "pkie.measure_answers" */
  pkie_measure_answers_aggregate: PkieMeasureAnswersAggregate;
  /** fetch data from the table: "pkie.measure_answers" using primary key columns */
  pkie_measure_answers_by_pk?: Maybe<PkieMeasureAnswers>;
  /** fetch data from the table: "pkie.measure_bookmark" */
  pkie_measure_bookmark: Array<PkieMeasureBookmark>;
  /** fetch aggregated fields from the table: "pkie.measure_bookmark" */
  pkie_measure_bookmark_aggregate: PkieMeasureBookmarkAggregate;
  /** fetch data from the table: "pkie.measure_bookmark" using primary key columns */
  pkie_measure_bookmark_by_pk?: Maybe<PkieMeasureBookmark>;
  /** fetch data from the table: "pkie.measure_budgets" */
  pkie_measure_budgets: Array<PkieMeasureBudgets>;
  /** fetch aggregated fields from the table: "pkie.measure_budgets" */
  pkie_measure_budgets_aggregate: PkieMeasureBudgetsAggregate;
  /** fetch data from the table: "pkie.measure_budgets" using primary key columns */
  pkie_measure_budgets_by_pk?: Maybe<PkieMeasureBudgets>;
  /** fetch data from the table: "pkie.measure" using primary key columns */
  pkie_measure_by_pk?: Maybe<PkieMeasure>;
  /** fetch data from the table: "pkie.measure_comment" */
  pkie_measure_comment: Array<PkieMeasureComment>;
  /** fetch aggregated fields from the table: "pkie.measure_comment" */
  pkie_measure_comment_aggregate: PkieMeasureCommentAggregate;
  /** fetch data from the table: "pkie.measure_comment" using primary key columns */
  pkie_measure_comment_by_pk?: Maybe<PkieMeasureComment>;
  /** fetch data from the table: "pkie.measure_gnpie_members" */
  pkie_measure_gnpie_members: Array<PkieMeasureGnpieMembers>;
  /** fetch aggregated fields from the table: "pkie.measure_gnpie_members" */
  pkie_measure_gnpie_members_aggregate: PkieMeasureGnpieMembersAggregate;
  /** fetch data from the table: "pkie.measure_gnpie_members" using primary key columns */
  pkie_measure_gnpie_members_by_pk?: Maybe<PkieMeasureGnpieMembers>;
  /** fetch data from the table: "pkie.measure_roadmap" */
  pkie_measure_roadmap: Array<PkieMeasureRoadmap>;
  /** fetch aggregated fields from the table: "pkie.measure_roadmap" */
  pkie_measure_roadmap_aggregate: PkieMeasureRoadmapAggregate;
  /** fetch data from the table: "pkie.measure_roadmap" using primary key columns */
  pkie_measure_roadmap_by_pk?: Maybe<PkieMeasureRoadmap>;
  /** fetch data from the table: "pkie.measure_status" */
  pkie_measure_status: Array<PkieMeasureStatus>;
  /** fetch aggregated fields from the table: "pkie.measure_status" */
  pkie_measure_status_aggregate: PkieMeasureStatusAggregate;
  /** fetch data from the table: "pkie.measure_status" using primary key columns */
  pkie_measure_status_by_pk?: Maybe<PkieMeasureStatus>;
  /** fetch data from the table: "pkie.national_acts" */
  pkie_national_acts: Array<PkieNationalActs>;
  /** fetch aggregated fields from the table: "pkie.national_acts" */
  pkie_national_acts_aggregate: PkieNationalActsAggregate;
  /** fetch data from the table: "pkie.national_acts" using primary key columns */
  pkie_national_acts_by_pk?: Maybe<PkieNationalActs>;
  /** fetch data from the table: "pkie.proces_pune_iaga" */
  pkie_proces_pune_iaga: Array<PkieProcesPuneIaga>;
  /** fetch aggregated fields from the table: "pkie.proces_pune_iaga" */
  pkie_proces_pune_iaga_aggregate: PkieProcesPuneIagaAggregate;
  /** fetch data from the table: "pkie.proces_pune_iaga" using primary key columns */
  pkie_proces_pune_iaga_by_pk?: Maybe<PkieProcesPuneIaga>;
  /** fetch data from the table: "pkie.proces_pune_lga" */
  pkie_proces_pune_lga: Array<PkieProcesPuneLga>;
  /** fetch aggregated fields from the table: "pkie.proces_pune_lga" */
  pkie_proces_pune_lga_aggregate: PkieProcesPuneLgaAggregate;
  /** fetch data from the table: "pkie.proces_pune_lga" using primary key columns */
  pkie_proces_pune_lga_by_pk?: Maybe<PkieProcesPuneLga>;
  /** fetch data from the table: "pkie.source_of_funding" */
  pkie_source_of_funding: Array<PkieSourceOfFunding>;
  /** fetch aggregated fields from the table: "pkie.source_of_funding" */
  pkie_source_of_funding_aggregate: PkieSourceOfFundingAggregate;
  /** fetch data from the table: "pkie.source_of_funding" using primary key columns */
  pkie_source_of_funding_by_pk?: Maybe<PkieSourceOfFunding>;
  /** fetch data from the table: "pkie.type_alignment_act" */
  pkie_type_alignment_act: Array<PkieTypeAlignmentAct>;
  /** fetch aggregated fields from the table: "pkie.type_alignment_act" */
  pkie_type_alignment_act_aggregate: PkieTypeAlignmentActAggregate;
  /** fetch data from the table: "pkie.type_alignment_act" using primary key columns */
  pkie_type_alignment_act_by_pk?: Maybe<PkieTypeAlignmentAct>;
  /** fetch data from the table: "pkie.type_measure" */
  pkie_type_measure: Array<PkieTypeMeasure>;
  /** fetch aggregated fields from the table: "pkie.type_measure" */
  pkie_type_measure_aggregate: PkieTypeMeasureAggregate;
  /** fetch data from the table: "pkie.type_measure_budget" */
  pkie_type_measure_budget: Array<PkieTypeMeasureBudget>;
  /** fetch aggregated fields from the table: "pkie.type_measure_budget" */
  pkie_type_measure_budget_aggregate: PkieTypeMeasureBudgetAggregate;
  /** fetch data from the table: "pkie.type_measure_budget" using primary key columns */
  pkie_type_measure_budget_by_pk?: Maybe<PkieTypeMeasureBudget>;
  /** fetch data from the table: "pkie.type_measure" using primary key columns */
  pkie_type_measure_by_pk?: Maybe<PkieTypeMeasure>;
  /** fetch data from the table: "pkie.work_plan_comment" */
  pkie_work_plan_comment: Array<PkieWorkPlanComment>;
  /** fetch aggregated fields from the table: "pkie.work_plan_comment" */
  pkie_work_plan_comment_aggregate: PkieWorkPlanCommentAggregate;
  /** fetch data from the table: "pkie.work_plan_comment" using primary key columns */
  pkie_work_plan_comment_by_pk?: Maybe<PkieWorkPlanComment>;
  /** fetch data from the table: "pkie.work_plan_statuses" */
  pkie_work_plan_statuses: Array<PkieWorkPlanStatuses>;
  /** fetch aggregated fields from the table: "pkie.work_plan_statuses" */
  pkie_work_plan_statuses_aggregate: PkieWorkPlanStatusesAggregate;
  /** fetch data from the table: "pkie.work_plan_statuses" using primary key columns */
  pkie_work_plan_statuses_by_pk?: Maybe<PkieWorkPlanStatuses>;
  /** fetch data from the table: "pkie.work_plan_task" */
  pkie_work_plan_task: Array<PkieWorkPlanTask>;
  /** fetch data from the table: "pkie.work_plan_task_activity" */
  pkie_work_plan_task_activity: Array<PkieWorkPlanTaskActivity>;
  /** fetch aggregated fields from the table: "pkie.work_plan_task_activity" */
  pkie_work_plan_task_activity_aggregate: PkieWorkPlanTaskActivityAggregate;
  /** fetch data from the table: "pkie.work_plan_task_activity" using primary key columns */
  pkie_work_plan_task_activity_by_pk?: Maybe<PkieWorkPlanTaskActivity>;
  /** fetch aggregated fields from the table: "pkie.work_plan_task" */
  pkie_work_plan_task_aggregate: PkieWorkPlanTaskAggregate;
  /** fetch data from the table: "pkie.work_plan_task" using primary key columns */
  pkie_work_plan_task_by_pk?: Maybe<PkieWorkPlanTask>;
  /** fetch data from the table: "pkie.work_plan_task_statuses" */
  pkie_work_plan_task_statuses: Array<PkieWorkPlanTaskStatuses>;
  /** fetch aggregated fields from the table: "pkie.work_plan_task_statuses" */
  pkie_work_plan_task_statuses_aggregate: PkieWorkPlanTaskStatusesAggregate;
  /** fetch data from the table: "pkie.work_plan_task_statuses" using primary key columns */
  pkie_work_plan_task_statuses_by_pk?: Maybe<PkieWorkPlanTaskStatuses>;
  /** fetch data from the table: "pkie.work_plans" */
  pkie_work_plans: Array<PkieWorkPlans>;
  /** fetch aggregated fields from the table: "pkie.work_plans" */
  pkie_work_plans_aggregate: PkieWorkPlansAggregate;
  /** fetch data from the table: "pkie.work_plans" using primary key columns */
  pkie_work_plans_by_pk?: Maybe<PkieWorkPlans>;
  /** fetch data from the table: "question_types" */
  question_types: Array<QuestionTypes>;
  /** fetch aggregated fields from the table: "question_types" */
  question_types_aggregate: QuestionTypesAggregate;
  /** fetch data from the table: "question_types" using primary key columns */
  question_types_by_pk?: Maybe<QuestionTypes>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: QuestionsAggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: RecommendationsAggregate;
  /** fetch data from the table: "recommendations" using primary key columns */
  recommendations_by_pk?: Maybe<Recommendations>;
  /** An array relationship */
  report_answers: Array<ReportAnswers>;
  /** An aggregate relationship */
  report_answers_aggregate: ReportAnswersAggregate;
  /** fetch data from the table: "report_answers" using primary key columns */
  report_answers_by_pk?: Maybe<ReportAnswers>;
  /** fetch data from the table: "report_chapter_statuses" */
  report_chapter_statuses: Array<ReportChapterStatuses>;
  /** fetch aggregated fields from the table: "report_chapter_statuses" */
  report_chapter_statuses_aggregate: ReportChapterStatusesAggregate;
  /** fetch data from the table: "report_chapter_statuses" using primary key columns */
  report_chapter_statuses_by_pk?: Maybe<ReportChapterStatuses>;
  /** An array relationship */
  report_chapters: Array<ReportChapters>;
  /** An aggregate relationship */
  report_chapters_aggregate: ReportChaptersAggregate;
  /** fetch data from the table: "report_chapters" using primary key columns */
  report_chapters_by_pk?: Maybe<ReportChapters>;
  /** fetch data from the table: "report_statuses" */
  report_statuses: Array<ReportStatuses>;
  /** fetch aggregated fields from the table: "report_statuses" */
  report_statuses_aggregate: ReportStatusesAggregate;
  /** fetch data from the table: "report_statuses" using primary key columns */
  report_statuses_by_pk?: Maybe<ReportStatuses>;
  /** fetch data from the table: "report_task_activity" */
  report_task_activity: Array<ReportTaskActivity>;
  /** fetch aggregated fields from the table: "report_task_activity" */
  report_task_activity_aggregate: ReportTaskActivityAggregate;
  /** fetch data from the table: "report_task_activity" using primary key columns */
  report_task_activity_by_pk?: Maybe<ReportTaskActivity>;
  /** fetch data from the table: "report_task_comment" */
  report_task_comment: Array<ReportTaskComment>;
  /** fetch aggregated fields from the table: "report_task_comment" */
  report_task_comment_aggregate: ReportTaskCommentAggregate;
  /** fetch data from the table: "report_task_comment" using primary key columns */
  report_task_comment_by_pk?: Maybe<ReportTaskComment>;
  /** An array relationship */
  report_task_gnpie_members: Array<ReportTaskGnpieMembers>;
  /** An aggregate relationship */
  report_task_gnpie_members_aggregate: ReportTaskGnpieMembersAggregate;
  /** fetch data from the table: "report_task_gnpie_members" using primary key columns */
  report_task_gnpie_members_by_pk?: Maybe<ReportTaskGnpieMembers>;
  /** fetch data from the table: "report_task_statuses" */
  report_task_statuses: Array<ReportTaskStatuses>;
  /** fetch aggregated fields from the table: "report_task_statuses" */
  report_task_statuses_aggregate: ReportTaskStatusesAggregate;
  /** fetch data from the table: "report_task_statuses" using primary key columns */
  report_task_statuses_by_pk?: Maybe<ReportTaskStatuses>;
  /** An array relationship */
  report_tasks: Array<ReportTasks>;
  /** An aggregate relationship */
  report_tasks_aggregate: ReportTasksAggregate;
  /** fetch data from the table: "report_tasks" using primary key columns */
  report_tasks_by_pk?: Maybe<ReportTasks>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: ReportsAggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table: "roadmap_measure_type" */
  roadmap_measure_type: Array<RoadmapMeasureType>;
  /** fetch aggregated fields from the table: "roadmap_measure_type" */
  roadmap_measure_type_aggregate: RoadmapMeasureTypeAggregate;
  /** fetch data from the table: "roadmap_measure_type" using primary key columns */
  roadmap_measure_type_by_pk?: Maybe<RoadmapMeasureType>;
  /** An array relationship */
  signed_documents: Array<SignedDocuments>;
  /** An aggregate relationship */
  signed_documents_aggregate: SignedDocumentsAggregate;
  /** fetch data from the table: "signed_documents" using primary key columns */
  signed_documents_by_pk?: Maybe<SignedDocuments>;
  /** An array relationship */
  sub_chapter_lead_institutions: Array<SubChapterLeadInstitutions>;
  /** An aggregate relationship */
  sub_chapter_lead_institutions_aggregate: SubChapterLeadInstitutionsAggregate;
  /** fetch data from the table: "sub_chapter_lead_institutions" using primary key columns */
  sub_chapter_lead_institutions_by_pk?: Maybe<SubChapterLeadInstitutions>;
  /** An array relationship */
  sub_chapters: Array<SubChapters>;
  /** An aggregate relationship */
  sub_chapters_aggregate: SubChaptersAggregate;
  /** fetch data from the table: "sub_chapters" using primary key columns */
  sub_chapters_by_pk?: Maybe<SubChapters>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch data from the table: "task_activity" */
  task_activity: Array<TaskActivity>;
  /** fetch aggregated fields from the table: "task_activity" */
  task_activity_aggregate: TaskActivityAggregate;
  /** fetch data from the table: "task_activity" using primary key columns */
  task_activity_by_pk?: Maybe<TaskActivity>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: TaskAggregate;
  /** An array relationship */
  task_answers: Array<TaskAnswers>;
  /** An aggregate relationship */
  task_answers_aggregate: TaskAnswersAggregate;
  /** fetch data from the table: "task_answers" using primary key columns */
  task_answers_by_pk?: Maybe<TaskAnswers>;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** An array relationship */
  task_comments: Array<TaskComments>;
  /** An aggregate relationship */
  task_comments_aggregate: TaskCommentsAggregate;
  /** fetch data from the table: "task_comments" using primary key columns */
  task_comments_by_pk?: Maybe<TaskComments>;
  /** An array relationship */
  task_gnpie_members: Array<TaskGnpieMembers>;
  /** An aggregate relationship */
  task_gnpie_members_aggregate: TaskGnpieMembersAggregate;
  /** fetch data from the table: "task_gnpie_members" using primary key columns */
  task_gnpie_members_by_pk?: Maybe<TaskGnpieMembers>;
  /** fetch data from the table: "task_statuses" */
  task_statuses: Array<TaskStatuses>;
  /** fetch aggregated fields from the table: "task_statuses" */
  task_statuses_aggregate: TaskStatusesAggregate;
  /** fetch data from the table: "task_statuses" using primary key columns */
  task_statuses_by_pk?: Maybe<TaskStatuses>;
  /** fetch data from the table: "task_types" */
  task_types: Array<TaskTypes>;
  /** fetch aggregated fields from the table: "task_types" */
  task_types_aggregate: TaskTypesAggregate;
  /** fetch data from the table: "task_types" using primary key columns */
  task_types_by_pk?: Maybe<TaskTypes>;
  /** An array relationship */
  topics: Array<Topics>;
  /** An aggregate relationship */
  topics_aggregate: TopicsAggregate;
  /** fetch data from the table: "topics" using primary key columns */
  topics_by_pk?: Maybe<Topics>;
  /** fetch data from the table: "type_institutions" */
  type_institutions: Array<TypeInstitutions>;
  /** fetch aggregated fields from the table: "type_institutions" */
  type_institutions_aggregate: TypeInstitutionsAggregate;
  /** fetch data from the table: "type_institutions" using primary key columns */
  type_institutions_by_pk?: Maybe<TypeInstitutions>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "yearly_quarters" */
  yearly_quarters: Array<YearlyQuarters>;
  /** fetch aggregated fields from the table: "yearly_quarters" */
  yearly_quarters_aggregate: YearlyQuartersAggregate;
  /** fetch data from the table: "yearly_quarters" using primary key columns */
  yearly_quarters_by_pk?: Maybe<YearlyQuarters>;
}


export interface QueryRootAuthProviderArgs {
  id: Scalars['String']['input'];
}


export interface QueryRootAuthProviderRequestArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootAuthProviderRequestsArgs {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
}


export interface QueryRootAuthProviderRequestsAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
}


export interface QueryRootAuthProvidersArgs {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
}


export interface QueryRootAuthProvidersAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
}


export interface QueryRootAuthRefreshTokenArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootAuthRefreshTokenTypeArgs {
  value: Scalars['String']['input'];
}


export interface QueryRootAuthRefreshTokenTypesArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
}


export interface QueryRootAuthRefreshTokenTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
}


export interface QueryRootAuthRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


export interface QueryRootAuthRefreshTokensAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


export interface QueryRootAuthRoleArgs {
  role: Scalars['String']['input'];
}


export interface QueryRootAuthRolesArgs {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
}


export interface QueryRootAuthRolesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
}


export interface QueryRootAuthUserProviderArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootAuthUserProvidersArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


export interface QueryRootAuthUserProvidersAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


export interface QueryRootAuthUserRoleArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootAuthUserRolesArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


export interface QueryRootAuthUserRolesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


export interface QueryRootAuthUserSecurityKeyArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootAuthUserSecurityKeysArgs {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


export interface QueryRootAuthUserSecurityKeysAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


export interface QueryRootAuthModulesArgs {
  distinct_on?: InputMaybe<Array<AuthModulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthModulesOrderBy>>;
  where?: InputMaybe<AuthModulesBoolExp>;
}


export interface QueryRootAuthModulesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthModulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthModulesOrderBy>>;
  where?: InputMaybe<AuthModulesBoolExp>;
}


export interface QueryRootAuthModulesByPkArgs {
  module: Scalars['String']['input'];
}


export interface QueryRootBookmarkGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkGnpieMembersOrderBy>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


export interface QueryRootBookmarkGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkGnpieMembersOrderBy>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


export interface QueryRootBookmarkGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootBookmarkStatusArgs {
  distinct_on?: InputMaybe<Array<BookmarkStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkStatusOrderBy>>;
  where?: InputMaybe<BookmarkStatusBoolExp>;
}


export interface QueryRootBookmarkStatusAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkStatusOrderBy>>;
  where?: InputMaybe<BookmarkStatusBoolExp>;
}


export interface QueryRootBookmarkStatusByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootBookmarkTaskActivityArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskActivityOrderBy>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


export interface QueryRootBookmarkTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskActivityOrderBy>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


export interface QueryRootBookmarkTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootBookmarkTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskAnswersOrderBy>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


export interface QueryRootBookmarkTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskAnswersOrderBy>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


export interface QueryRootBookmarkTaskAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootBookmarkTaskCommentArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskCommentOrderBy>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


export interface QueryRootBookmarkTaskCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskCommentOrderBy>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


export interface QueryRootBookmarkTaskCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootBookmarkTaskMeasuresArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskMeasuresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskMeasuresOrderBy>>;
  where?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
}


export interface QueryRootBookmarkTaskMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskMeasuresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskMeasuresOrderBy>>;
  where?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
}


export interface QueryRootBookmarkTaskMeasuresByPkArgs {
  measure: Scalars['String']['input'];
}


export interface QueryRootBookmarkTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskStatusesOrderBy>>;
  where?: InputMaybe<BookmarkTaskStatusesBoolExp>;
}


export interface QueryRootBookmarkTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskStatusesOrderBy>>;
  where?: InputMaybe<BookmarkTaskStatusesBoolExp>;
}


export interface QueryRootBookmarkTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootBookmarkTasksArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


export interface QueryRootBookmarkTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


export interface QueryRootBookmarkTasksByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootBookmarksArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


export interface QueryRootBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


export interface QueryRootBookmarksByPkArgs {
  id: Scalars['Int']['input'];
}


export interface QueryRootCalendarTasksOfChiefSecretariatArgs {
  args: CalendarTasksOfChiefSecretariatArguments;
  distinct_on?: InputMaybe<Array<MemberCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCalendarOrderBy>>;
  where?: InputMaybe<MemberCalendarBoolExpBoolExp>;
}


export interface QueryRootCalendarTasksOfCoordinatorArgs {
  args: CalendarTasksOfCoordinatorArguments;
  distinct_on?: InputMaybe<Array<NegotiatorCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NegotiatorCalendarOrderBy>>;
  where?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
}


export interface QueryRootCalendarTasksOfHorizontalCoordinatorArgs {
  args: CalendarTasksOfHorizontalCoordinatorArguments;
  distinct_on?: InputMaybe<Array<NegotiatorCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NegotiatorCalendarOrderBy>>;
  where?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
}


export interface QueryRootCalendarTasksOfMemberArgs {
  args: CalendarTasksOfMemberArguments;
  distinct_on?: InputMaybe<Array<MemberCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCalendarOrderBy>>;
  where?: InputMaybe<MemberCalendarBoolExpBoolExp>;
}


export interface QueryRootCalendarTasksOfNegotiatorArgs {
  args: CalendarTasksOfNegotiatorArguments;
  distinct_on?: InputMaybe<Array<NegotiatorCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NegotiatorCalendarOrderBy>>;
  where?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
}


export interface QueryRootCalendarTasksOfPmoArgs {
  args: CalendarTasksOfPmoArguments;
  distinct_on?: InputMaybe<Array<PmoCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PmoCalendarOrderBy>>;
  where?: InputMaybe<PmoCalendarBoolExpBoolExp>;
}


export interface QueryRootCalendarTasksOfSecretariatArgs {
  args: CalendarTasksOfSecretariatArguments;
  distinct_on?: InputMaybe<Array<MemberCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCalendarOrderBy>>;
  where?: InputMaybe<MemberCalendarBoolExpBoolExp>;
}


export interface QueryRootCapacBeneficiariesArgs {
  distinct_on?: InputMaybe<Array<CapacBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacBeneficiariesBoolExp>;
}


export interface QueryRootCapacBeneficiariesAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacBeneficiariesBoolExp>;
}


export interface QueryRootCapacBeneficiariesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacChapterDonatorArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


export interface QueryRootCapacChapterDonatorAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


export interface QueryRootCapacChapterDonatorByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacDonatorsArgs {
  distinct_on?: InputMaybe<Array<CapacDonatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacDonatorsOrderBy>>;
  where?: InputMaybe<CapacDonatorsBoolExp>;
}


export interface QueryRootCapacDonatorsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacDonatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacDonatorsOrderBy>>;
  where?: InputMaybe<CapacDonatorsBoolExp>;
}


export interface QueryRootCapacDonatorsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacExpertsArgs {
  distinct_on?: InputMaybe<Array<CapacExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacExpertsOrderBy>>;
  where?: InputMaybe<CapacExpertsBoolExp>;
}


export interface QueryRootCapacExpertsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacExpertsOrderBy>>;
  where?: InputMaybe<CapacExpertsBoolExp>;
}


export interface QueryRootCapacExpertsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacIntegrationStepsArgs {
  distinct_on?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacIntegrationStepsOrderBy>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}


export interface QueryRootCapacIntegrationStepsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacIntegrationStepsOrderBy>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}


export interface QueryRootCapacIntegrationStepsByPkArgs {
  id: Scalars['numeric']['input'];
}


export interface QueryRootCapacPriorityListsArgs {
  distinct_on?: InputMaybe<Array<CapacPriorityListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacPriorityListsOrderBy>>;
  where?: InputMaybe<CapacPriorityListsBoolExp>;
}


export interface QueryRootCapacPriorityListsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacPriorityListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacPriorityListsOrderBy>>;
  where?: InputMaybe<CapacPriorityListsBoolExp>;
}


export interface QueryRootCapacPriorityListsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacProjectBeneficiariesArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


export interface QueryRootCapacProjectBeneficiariesAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


export interface QueryRootCapacProjectBeneficiariesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacProjectsArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


export interface QueryRootCapacProjectsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


export interface QueryRootCapacProjectsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTaiexArgs {
  distinct_on?: InputMaybe<Array<CapacTaiexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTaiexOrderBy>>;
  where?: InputMaybe<CapacTaiexBoolExp>;
}


export interface QueryRootCapacTaiexAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTaiexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTaiexOrderBy>>;
  where?: InputMaybe<CapacTaiexBoolExp>;
}


export interface QueryRootCapacTaiexByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


export interface QueryRootCapacTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


export interface QueryRootCapacTrainingNeedsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTrainingParticipantsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


export interface QueryRootCapacTrainingParticipantsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


export interface QueryRootCapacTrainingParticipantsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTrainingSessionsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingSessionsOrderBy>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


export interface QueryRootCapacTrainingSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingSessionsOrderBy>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


export interface QueryRootCapacTrainingSessionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTrainingsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsOrderBy>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


export interface QueryRootCapacTrainingsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsOrderBy>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


export interface QueryRootCapacTrainingsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTrainingsExpertsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


export interface QueryRootCapacTrainingsExpertsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


export interface QueryRootCapacTrainingsExpertsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootCapacTwinningArgs {
  distinct_on?: InputMaybe<Array<CapacTwinningSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTwinningOrderBy>>;
  where?: InputMaybe<CapacTwinningBoolExp>;
}


export interface QueryRootCapacTwinningAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTwinningSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTwinningOrderBy>>;
  where?: InputMaybe<CapacTwinningBoolExp>;
}


export interface QueryRootCapacTwinningByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootChapterLeadInstitutionsArgs {
  distinct_on?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


export interface QueryRootChapterLeadInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


export interface QueryRootChapterLeadInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootChaptersArgs {
  distinct_on?: InputMaybe<Array<ChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChaptersOrderBy>>;
  where?: InputMaybe<ChaptersBoolExp>;
}


export interface QueryRootChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChaptersOrderBy>>;
  where?: InputMaybe<ChaptersBoolExp>;
}


export interface QueryRootChaptersByPkArgs {
  id: Scalars['Int']['input'];
}


export interface QueryRootClustersArgs {
  distinct_on?: InputMaybe<Array<ClustersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClustersOrderBy>>;
  where?: InputMaybe<ClustersBoolExp>;
}


export interface QueryRootClustersAggregateArgs {
  distinct_on?: InputMaybe<Array<ClustersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClustersOrderBy>>;
  where?: InputMaybe<ClustersBoolExp>;
}


export interface QueryRootClustersByPkArgs {
  id: Scalars['Int']['input'];
}


export interface QueryRootDashboardStatisticsArgs {
  args: DashboardStatisticsArguments;
  distinct_on?: InputMaybe<Array<DashboardStatisticsEntityEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardStatisticsEntityOrderBy>>;
  where?: InputMaybe<DashboardStatisticsEntityBoolExpBoolExp>;
}


export interface QueryRootDashboardStatisticsAggregateArgs {
  args: DashboardStatisticsAggregateArguments;
  distinct_on?: InputMaybe<Array<DashboardAggregateEntityEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardAggregateEntityOrderBy>>;
  where?: InputMaybe<DashboardAggregateEntityBoolExpBoolExp>;
}


export interface QueryRootDmsFolderArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface QueryRootDmsFolderAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface QueryRootDmsFolderByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootDmsFolderTypeArgs {
  distinct_on?: InputMaybe<Array<DmsFolderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderTypeOrderBy>>;
  where?: InputMaybe<DmsFolderTypeBoolExp>;
}


export interface QueryRootDmsFolderTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderTypeOrderBy>>;
  where?: InputMaybe<DmsFolderTypeBoolExp>;
}


export interface QueryRootDmsFolderTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootDocumentsArgs {
  distinct_on?: InputMaybe<Array<DocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentsOrderBy>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


export interface QueryRootDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<DocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentsOrderBy>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


export interface QueryRootDocumentsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootErrorLogsArgs {
  distinct_on?: InputMaybe<Array<ErrorLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ErrorLogsOrderBy>>;
  where?: InputMaybe<ErrorLogsBoolExp>;
}


export interface QueryRootErrorLogsAggregateArgs {
  distinct_on?: InputMaybe<Array<ErrorLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ErrorLogsOrderBy>>;
  where?: InputMaybe<ErrorLogsBoolExp>;
}


export interface QueryRootErrorLogsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootEventNotesArgs {
  distinct_on?: InputMaybe<Array<EventNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventNotesOrderBy>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


export interface QueryRootEventNotesAggregateArgs {
  distinct_on?: InputMaybe<Array<EventNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventNotesOrderBy>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


export interface QueryRootEventNotesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootEventTypologyArgs {
  distinct_on?: InputMaybe<Array<EventTypologySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTypologyOrderBy>>;
  where?: InputMaybe<EventTypologyBoolExp>;
}


export interface QueryRootEventTypologyAggregateArgs {
  distinct_on?: InputMaybe<Array<EventTypologySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTypologyOrderBy>>;
  where?: InputMaybe<EventTypologyBoolExp>;
}


export interface QueryRootEventTypologyByPkArgs {
  typology: Scalars['String']['input'];
}


export interface QueryRootEventUsersArgs {
  distinct_on?: InputMaybe<Array<EventUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventUsersOrderBy>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


export interface QueryRootEventUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<EventUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventUsersOrderBy>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


export interface QueryRootEventUsersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootEventsArgs {
  distinct_on?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
}


export interface QueryRootEventsAggregateArgs {
  distinct_on?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
}


export interface QueryRootEventsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootGetFolderPathArgs {
  args: GetFolderPathArguments;
  distinct_on?: InputMaybe<Array<FolderPathModelEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FolderPathModelOrderBy>>;
  where?: InputMaybe<FolderPathModelBoolExpBoolExp>;
}


export interface QueryRootGnpieArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


export interface QueryRootGnpieUsersByChapterArgs {
  args: GnpieUsersByChapterArguments;
  distinct_on?: InputMaybe<Array<GnpieUserEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieUserOrderBy>>;
  where?: InputMaybe<GnpieUserBoolExpBoolExp>;
}


export interface QueryRootGnpieAggregateArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


export interface QueryRootGnpieByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootInstitutionsArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


export interface QueryRootInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


export interface QueryRootInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootIntegrationPhaseProgresArgs {
  args: IntegrationPhaseProgresArguments;
  distinct_on?: InputMaybe<Array<IntegrationPhaseProgresEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhaseProgresOrderBy>>;
  where?: InputMaybe<IntegrationPhaseProgresBoolExpBoolExp>;
}


export interface QueryRootIntegrationPhasesArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesOrderBy>>;
  where?: InputMaybe<IntegrationPhasesBoolExp>;
}


export interface QueryRootIntegrationPhasesAggregateArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesOrderBy>>;
  where?: InputMaybe<IntegrationPhasesBoolExp>;
}


export interface QueryRootIntegrationPhasesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootIntegrationPhasesEnumArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesEnumOrderBy>>;
  where?: InputMaybe<IntegrationPhasesEnumBoolExp>;
}


export interface QueryRootIntegrationPhasesEnumAggregateArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesEnumOrderBy>>;
  where?: InputMaybe<IntegrationPhasesEnumBoolExp>;
}


export interface QueryRootIntegrationPhasesEnumByPkArgs {
  phase: Scalars['String']['input'];
}


export interface QueryRootMeetingAttendanceArgs {
  distinct_on?: InputMaybe<Array<MeetingAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingAttendanceOrderBy>>;
  where?: InputMaybe<MeetingAttendanceBoolExp>;
}


export interface QueryRootMeetingAttendanceAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingAttendanceOrderBy>>;
  where?: InputMaybe<MeetingAttendanceBoolExp>;
}


export interface QueryRootMeetingAttendanceByPkArgs {
  attendance: Scalars['String']['input'];
}


export interface QueryRootMeetingNotesArgs {
  distinct_on?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingNotesOrderBy>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


export interface QueryRootMeetingNotesAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingNotesOrderBy>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


export interface QueryRootMeetingNotesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootMeetingUsersArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


export interface QueryRootMeetingUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


export interface QueryRootMeetingUsersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootMeetingsArgs {
  distinct_on?: InputMaybe<Array<MeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingsOrderBy>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


export interface QueryRootMeetingsAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingsOrderBy>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


export interface QueryRootMeetingsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootNotificationArgs {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
}


export interface QueryRootNotificationAggregateArgs {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
}


export interface QueryRootNotificationByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootNotificationOriginArgs {
  distinct_on?: InputMaybe<Array<NotificationOriginSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOriginOrderBy>>;
  where?: InputMaybe<NotificationOriginBoolExp>;
}


export interface QueryRootNotificationOriginAggregateArgs {
  distinct_on?: InputMaybe<Array<NotificationOriginSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOriginOrderBy>>;
  where?: InputMaybe<NotificationOriginBoolExp>;
}


export interface QueryRootNotificationOriginByPkArgs {
  origin: Scalars['String']['input'];
}


export interface QueryRootNotificationTypeArgs {
  distinct_on?: InputMaybe<Array<NotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationTypeOrderBy>>;
  where?: InputMaybe<NotificationTypeBoolExp>;
}


export interface QueryRootNotificationTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<NotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationTypeOrderBy>>;
  where?: InputMaybe<NotificationTypeBoolExp>;
}


export interface QueryRootNotificationTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootNrOfInstitutionsPerChapterArgs {
  args: NrOfInstitutionsPerChapterArguments;
  distinct_on?: InputMaybe<Array<NrOfInstitutionsPerChapterEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NrOfInstitutionsPerChapterOrderBy>>;
  where?: InputMaybe<NrOfInstitutionsPerChapterBoolExpBoolExp>;
}


export interface QueryRootNrOfUsersPerInstitutionArgs {
  args: NrOfUsersPerInstitutionArguments;
  distinct_on?: InputMaybe<Array<NrOfInstitutionsPerChapterEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NrOfInstitutionsPerChapterOrderBy>>;
  where?: InputMaybe<NrOfInstitutionsPerChapterBoolExpBoolExp>;
}


export interface QueryRootPkieAcquisArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisOrderBy>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


export interface QueryRootPkieAcquisAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisOrderBy>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


export interface QueryRootPkieAcquisByPkArgs {
  celex: Scalars['String']['input'];
}


export interface QueryRootPkieAcquisChapterArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


export interface QueryRootPkieAcquisChapterAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


export interface QueryRootPkieAcquisChapterByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieAcquisInstitutionsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


export interface QueryRootPkieAcquisInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


export interface QueryRootPkieAcquisInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieAcquisNationalActsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


export interface QueryRootPkieAcquisNationalActsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


export interface QueryRootPkieAcquisNationalActsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieAcquisTypeArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisTypeOrderBy>>;
  where?: InputMaybe<PkieAcquisTypeBoolExp>;
}


export interface QueryRootPkieAcquisTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisTypeOrderBy>>;
  where?: InputMaybe<PkieAcquisTypeBoolExp>;
}


export interface QueryRootPkieAcquisTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootPkieAcquisWorkPlanTaskArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


export interface QueryRootPkieAcquisWorkPlanTaskAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


export interface QueryRootPkieAcquisWorkPlanTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieActTypeArgs {
  distinct_on?: InputMaybe<Array<PkieActTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieActTypeOrderBy>>;
  where?: InputMaybe<PkieActTypeBoolExp>;
}


export interface QueryRootPkieActTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieActTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieActTypeOrderBy>>;
  where?: InputMaybe<PkieActTypeBoolExp>;
}


export interface QueryRootPkieActTypeByPkArgs {
  code: Scalars['String']['input'];
}


export interface QueryRootPkieLevelOfAlignmentArgs {
  distinct_on?: InputMaybe<Array<PkieLevelOfAlignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieLevelOfAlignmentOrderBy>>;
  where?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
}


export interface QueryRootPkieLevelOfAlignmentAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieLevelOfAlignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieLevelOfAlignmentOrderBy>>;
  where?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
}


export interface QueryRootPkieLevelOfAlignmentByPkArgs {
  level: Scalars['String']['input'];
}


export interface QueryRootPkieMeasureArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


export interface QueryRootPkieMeasureActivityArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureActivityOrderBy>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


export interface QueryRootPkieMeasureActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureActivityOrderBy>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


export interface QueryRootPkieMeasureActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


export interface QueryRootPkieMeasureAnswersArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureAnswersOrderBy>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


export interface QueryRootPkieMeasureAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureAnswersOrderBy>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


export interface QueryRootPkieMeasureAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureBookmarkArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


export interface QueryRootPkieMeasureBookmarkAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


export interface QueryRootPkieMeasureBookmarkByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureBudgetsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


export interface QueryRootPkieMeasureBudgetsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


export interface QueryRootPkieMeasureBudgetsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureCommentArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureCommentOrderBy>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


export interface QueryRootPkieMeasureCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureCommentOrderBy>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


export interface QueryRootPkieMeasureCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureGnpieMembersOrderBy>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


export interface QueryRootPkieMeasureGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureGnpieMembersOrderBy>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


export interface QueryRootPkieMeasureGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureRoadmapArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


export interface QueryRootPkieMeasureRoadmapAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


export interface QueryRootPkieMeasureRoadmapByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieMeasureStatusArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureStatusOrderBy>>;
  where?: InputMaybe<PkieMeasureStatusBoolExp>;
}


export interface QueryRootPkieMeasureStatusAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureStatusOrderBy>>;
  where?: InputMaybe<PkieMeasureStatusBoolExp>;
}


export interface QueryRootPkieMeasureStatusByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootPkieNationalActsArgs {
  distinct_on?: InputMaybe<Array<PkieNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieNationalActsOrderBy>>;
  where?: InputMaybe<PkieNationalActsBoolExp>;
}


export interface QueryRootPkieNationalActsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieNationalActsOrderBy>>;
  where?: InputMaybe<PkieNationalActsBoolExp>;
}


export interface QueryRootPkieNationalActsByPkArgs {
  eli: Scalars['String']['input'];
}


export interface QueryRootPkieProcesPuneIagaArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneIagaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


export interface QueryRootPkieProcesPuneIagaAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneIagaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


export interface QueryRootPkieProcesPuneIagaByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieProcesPuneLgaArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneLgaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}


export interface QueryRootPkieProcesPuneLgaAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneLgaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}


export interface QueryRootPkieProcesPuneLgaByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieSourceOfFundingArgs {
  distinct_on?: InputMaybe<Array<PkieSourceOfFundingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieSourceOfFundingOrderBy>>;
  where?: InputMaybe<PkieSourceOfFundingBoolExp>;
}


export interface QueryRootPkieSourceOfFundingAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieSourceOfFundingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieSourceOfFundingOrderBy>>;
  where?: InputMaybe<PkieSourceOfFundingBoolExp>;
}


export interface QueryRootPkieSourceOfFundingByPkArgs {
  source: Scalars['String']['input'];
}


export interface QueryRootPkieTypeAlignmentActArgs {
  distinct_on?: InputMaybe<Array<PkieTypeAlignmentActSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeAlignmentActOrderBy>>;
  where?: InputMaybe<PkieTypeAlignmentActBoolExp>;
}


export interface QueryRootPkieTypeAlignmentActAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieTypeAlignmentActSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeAlignmentActOrderBy>>;
  where?: InputMaybe<PkieTypeAlignmentActBoolExp>;
}


export interface QueryRootPkieTypeAlignmentActByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootPkieTypeMeasureArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBoolExp>;
}


export interface QueryRootPkieTypeMeasureAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBoolExp>;
}


export interface QueryRootPkieTypeMeasureBudgetArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureBudgetOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
}


export interface QueryRootPkieTypeMeasureBudgetAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureBudgetOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
}


export interface QueryRootPkieTypeMeasureBudgetByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootPkieTypeMeasureByPkArgs {
  measure: Scalars['String']['input'];
}


export interface QueryRootPkieWorkPlanCommentArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanCommentOrderBy>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


export interface QueryRootPkieWorkPlanCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanCommentOrderBy>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


export interface QueryRootPkieWorkPlanCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieWorkPlanStatusesArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
}


export interface QueryRootPkieWorkPlanStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
}


export interface QueryRootPkieWorkPlanStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootPkieWorkPlanTaskArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


export interface QueryRootPkieWorkPlanTaskActivityArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


export interface QueryRootPkieWorkPlanTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


export interface QueryRootPkieWorkPlanTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieWorkPlanTaskAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


export interface QueryRootPkieWorkPlanTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootPkieWorkPlanTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}


export interface QueryRootPkieWorkPlanTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}


export interface QueryRootPkieWorkPlanTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootPkieWorkPlansArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlansOrderBy>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}


export interface QueryRootPkieWorkPlansAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlansOrderBy>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}


export interface QueryRootPkieWorkPlansByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootQuestionTypesArgs {
  distinct_on?: InputMaybe<Array<QuestionTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypesOrderBy>>;
  where?: InputMaybe<QuestionTypesBoolExp>;
}


export interface QueryRootQuestionTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<QuestionTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypesOrderBy>>;
  where?: InputMaybe<QuestionTypesBoolExp>;
}


export interface QueryRootQuestionTypesByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootQuestionsArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


export interface QueryRootQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


export interface QueryRootQuestionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootRecommendationsArgs {
  distinct_on?: InputMaybe<Array<RecommendationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RecommendationsOrderBy>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


export interface QueryRootRecommendationsAggregateArgs {
  distinct_on?: InputMaybe<Array<RecommendationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RecommendationsOrderBy>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


export interface QueryRootRecommendationsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportAnswersArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


export interface QueryRootReportAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


export interface QueryRootReportAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportChapterStatusesArgs {
  distinct_on?: InputMaybe<Array<ReportChapterStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChapterStatusesOrderBy>>;
  where?: InputMaybe<ReportChapterStatusesBoolExp>;
}


export interface QueryRootReportChapterStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChapterStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChapterStatusesOrderBy>>;
  where?: InputMaybe<ReportChapterStatusesBoolExp>;
}


export interface QueryRootReportChapterStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootReportChaptersArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


export interface QueryRootReportChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


export interface QueryRootReportChaptersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportStatusesArgs {
  distinct_on?: InputMaybe<Array<ReportStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportStatusesOrderBy>>;
  where?: InputMaybe<ReportStatusesBoolExp>;
}


export interface QueryRootReportStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportStatusesOrderBy>>;
  where?: InputMaybe<ReportStatusesBoolExp>;
}


export interface QueryRootReportStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootReportTaskActivityArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


export interface QueryRootReportTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


export interface QueryRootReportTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportTaskCommentArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


export interface QueryRootReportTaskCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


export interface QueryRootReportTaskCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskGnpieMembersOrderBy>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}


export interface QueryRootReportTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskGnpieMembersOrderBy>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}


export interface QueryRootReportTaskGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<ReportTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskStatusesOrderBy>>;
  where?: InputMaybe<ReportTaskStatusesBoolExp>;
}


export interface QueryRootReportTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskStatusesOrderBy>>;
  where?: InputMaybe<ReportTaskStatusesBoolExp>;
}


export interface QueryRootReportTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootReportTasksArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


export interface QueryRootReportTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


export interface QueryRootReportTasksByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootReportsArgs {
  distinct_on?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
}


export interface QueryRootReportsAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
}


export interface QueryRootReportsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootRoadmapMeasureTypeArgs {
  distinct_on?: InputMaybe<Array<RoadmapMeasureTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoadmapMeasureTypeOrderBy>>;
  where?: InputMaybe<RoadmapMeasureTypeBoolExp>;
}


export interface QueryRootRoadmapMeasureTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<RoadmapMeasureTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoadmapMeasureTypeOrderBy>>;
  where?: InputMaybe<RoadmapMeasureTypeBoolExp>;
}


export interface QueryRootRoadmapMeasureTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootSignedDocumentsArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


export interface QueryRootSignedDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


export interface QueryRootSignedDocumentsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootSubChapterLeadInstitutionsArgs {
  distinct_on?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


export interface QueryRootSubChapterLeadInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


export interface QueryRootSubChapterLeadInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootSubChaptersArgs {
  distinct_on?: InputMaybe<Array<SubChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChaptersOrderBy>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


export interface QueryRootSubChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<SubChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChaptersOrderBy>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


export interface QueryRootSubChaptersByPkArgs {
  id: Scalars['Int']['input'];
}


export interface QueryRootTaskArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


export interface QueryRootTaskActivityArgs {
  distinct_on?: InputMaybe<Array<TaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskActivityOrderBy>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


export interface QueryRootTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskActivityOrderBy>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


export interface QueryRootTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootTaskAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


export interface QueryRootTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskAnswersOrderBy>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


export interface QueryRootTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskAnswersOrderBy>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


export interface QueryRootTaskAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootTaskCommentsArgs {
  distinct_on?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskCommentsOrderBy>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


export interface QueryRootTaskCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskCommentsOrderBy>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


export interface QueryRootTaskCommentsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskGnpieMembersOrderBy>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}


export interface QueryRootTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskGnpieMembersOrderBy>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}


export interface QueryRootTaskGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<TaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskStatusesOrderBy>>;
  where?: InputMaybe<TaskStatusesBoolExp>;
}


export interface QueryRootTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskStatusesOrderBy>>;
  where?: InputMaybe<TaskStatusesBoolExp>;
}


export interface QueryRootTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface QueryRootTaskTypesArgs {
  distinct_on?: InputMaybe<Array<TaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskTypesOrderBy>>;
  where?: InputMaybe<TaskTypesBoolExp>;
}


export interface QueryRootTaskTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskTypesOrderBy>>;
  where?: InputMaybe<TaskTypesBoolExp>;
}


export interface QueryRootTaskTypesByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootTopicsArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


export interface QueryRootTopicsAggregateArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


export interface QueryRootTopicsByPkArgs {
  id: Scalars['Int']['input'];
}


export interface QueryRootTypeInstitutionsArgs {
  distinct_on?: InputMaybe<Array<TypeInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TypeInstitutionsOrderBy>>;
  where?: InputMaybe<TypeInstitutionsBoolExp>;
}


export interface QueryRootTypeInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<TypeInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TypeInstitutionsOrderBy>>;
  where?: InputMaybe<TypeInstitutionsBoolExp>;
}


export interface QueryRootTypeInstitutionsByPkArgs {
  type: Scalars['String']['input'];
}


export interface QueryRootUserArgs {
  id: Scalars['uuid']['input'];
}


export interface QueryRootUsersArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}


export interface QueryRootUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}


export interface QueryRootYearlyQuartersArgs {
  distinct_on?: InputMaybe<Array<YearlyQuartersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<YearlyQuartersOrderBy>>;
  where?: InputMaybe<YearlyQuartersBoolExp>;
}


export interface QueryRootYearlyQuartersAggregateArgs {
  distinct_on?: InputMaybe<Array<YearlyQuartersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<YearlyQuartersOrderBy>>;
  where?: InputMaybe<YearlyQuartersBoolExp>;
}


export interface QueryRootYearlyQuartersByPkArgs {
  quarter: Scalars['String']['input'];
}

/** enum types of questions */
export interface QuestionTypes {
  __typename?: 'question_types';
  type: Scalars['String']['output'];
}

/** aggregated selection of "question_types" */
export interface QuestionTypesAggregate {
  __typename?: 'question_types_aggregate';
  aggregate?: Maybe<QuestionTypesAggregateFields>;
  nodes: Array<QuestionTypes>;
}

/** aggregate fields of "question_types" */
export interface QuestionTypesAggregateFields {
  __typename?: 'question_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionTypesMaxFields>;
  min?: Maybe<QuestionTypesMinFields>;
}


/** aggregate fields of "question_types" */
export interface QuestionTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<QuestionTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "question_types". All fields are combined with a logical 'AND'. */
export interface QuestionTypesBoolExp {
  _and?: InputMaybe<Array<QuestionTypesBoolExp>>;
  _not?: InputMaybe<QuestionTypesBoolExp>;
  _or?: InputMaybe<Array<QuestionTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "question_types" */
export enum QuestionTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  QuestionTypesPkey = 'question_types_pkey'
}

export enum QuestionTypesEnum {
  Integration = 'INTEGRATION',
  Report = 'REPORT'
}

/** Boolean expression to compare columns of type "question_types_enum". All fields are combined with logical 'AND'. */
export interface QuestionTypesEnumComparisonExp {
  _eq?: InputMaybe<QuestionTypesEnum>;
  _in?: InputMaybe<Array<QuestionTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<QuestionTypesEnum>;
  _nin?: InputMaybe<Array<QuestionTypesEnum>>;
}

/** input type for inserting data into table "question_types" */
export interface QuestionTypesInsertInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface QuestionTypesMaxFields {
  __typename?: 'question_types_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface QuestionTypesMinFields {
  __typename?: 'question_types_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "question_types" */
export interface QuestionTypesMutationResponse {
  __typename?: 'question_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionTypes>;
}

/** on_conflict condition type for table "question_types" */
export interface QuestionTypesOnConflict {
  constraint: QuestionTypesConstraint;
  update_columns?: Array<QuestionTypesUpdateColumn>;
  where?: InputMaybe<QuestionTypesBoolExp>;
}

/** Ordering options when selecting data from "question_types". */
export interface QuestionTypesOrderBy {
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: question_types */
export interface QuestionTypesPkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "question_types" */
export enum QuestionTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "question_types" */
export interface QuestionTypesSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "question_types" */
export interface QuestionTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: QuestionTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface QuestionTypesStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "question_types" */
export enum QuestionTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface QuestionTypesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionTypesSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionTypesBoolExp;
}

/** contains questions for Progres Report */
export interface Questions {
  __typename?: 'questions';
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  report_answers: Array<ReportAnswers>;
  /** An aggregate relationship */
  report_answers_aggregate: ReportAnswersAggregate;
  /** An array relationship */
  report_tasks: Array<ReportTasks>;
  /** An aggregate relationship */
  report_tasks_aggregate: ReportTasksAggregate;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: TaskAggregate;
  text: Scalars['String']['output'];
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  type: QuestionTypesEnum;
  updated_at: Scalars['timestamptz']['output'];
}


/** contains questions for Progres Report */
export interface QuestionsReportAnswersArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** contains questions for Progres Report */
export interface QuestionsReportAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** contains questions for Progres Report */
export interface QuestionsReportTasksArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


/** contains questions for Progres Report */
export interface QuestionsReportTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


/** contains questions for Progres Report */
export interface QuestionsTasksArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** contains questions for Progres Report */
export interface QuestionsTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}

/** aggregated selection of "questions" */
export interface QuestionsAggregate {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<QuestionsAggregateFields>;
  nodes: Array<Questions>;
}

export interface QuestionsAggregateBoolExp {
  bool_and?: InputMaybe<QuestionsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<QuestionsAggregateBoolExpBoolOr>;
  count?: InputMaybe<QuestionsAggregateBoolExpCount>;
}

export interface QuestionsAggregateBoolExpBoolAnd {
  arguments: QuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface QuestionsAggregateBoolExpBoolOr {
  arguments: QuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface QuestionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<QuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "questions" */
export interface QuestionsAggregateFields {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<QuestionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionsMaxFields>;
  min?: Maybe<QuestionsMinFields>;
  stddev?: Maybe<QuestionsStddevFields>;
  stddev_pop?: Maybe<QuestionsStddevPopFields>;
  stddev_samp?: Maybe<QuestionsStddevSampFields>;
  sum?: Maybe<QuestionsSumFields>;
  var_pop?: Maybe<QuestionsVarPopFields>;
  var_samp?: Maybe<QuestionsVarSampFields>;
  variance?: Maybe<QuestionsVarianceFields>;
}


/** aggregate fields of "questions" */
export interface QuestionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<QuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "questions" */
export interface QuestionsAggregateOrderBy {
  avg?: InputMaybe<QuestionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionsMaxOrderBy>;
  min?: InputMaybe<QuestionsMinOrderBy>;
  stddev?: InputMaybe<QuestionsStddevOrderBy>;
  stddev_pop?: InputMaybe<QuestionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<QuestionsStddevSampOrderBy>;
  sum?: InputMaybe<QuestionsSumOrderBy>;
  var_pop?: InputMaybe<QuestionsVarPopOrderBy>;
  var_samp?: InputMaybe<QuestionsVarSampOrderBy>;
  variance?: InputMaybe<QuestionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "questions" */
export interface QuestionsArrRelInsertInput {
  data: Array<QuestionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionsOnConflict>;
}

/** aggregate avg on columns */
export interface QuestionsAvgFields {
  __typename?: 'questions_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "questions" */
export interface QuestionsAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export interface QuestionsBoolExp {
  _and?: InputMaybe<Array<QuestionsBoolExp>>;
  _not?: InputMaybe<QuestionsBoolExp>;
  _or?: InputMaybe<Array<QuestionsBoolExp>>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  report_answers?: InputMaybe<ReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<ReportAnswersAggregateBoolExp>;
  report_tasks?: InputMaybe<ReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<ReportTasksAggregateBoolExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
  tasks?: InputMaybe<TaskBoolExp>;
  tasks_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<QuestionTypesEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "questions" */
export enum QuestionsConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'Questions_pkey'
}

/** input type for incrementing numeric columns in table "questions" */
export interface QuestionsIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "questions" */
export interface QuestionsInsertInput {
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  report_answers?: InputMaybe<ReportAnswersArrRelInsertInput>;
  report_tasks?: InputMaybe<ReportTasksArrRelInsertInput>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<QuestionTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface QuestionsMaxFields {
  __typename?: 'questions_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "questions" */
export interface QuestionsMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface QuestionsMinFields {
  __typename?: 'questions_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "questions" */
export interface QuestionsMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "questions" */
export interface QuestionsMutationResponse {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
}

/** input type for inserting object relation for remote table "questions" */
export interface QuestionsObjRelInsertInput {
  data: QuestionsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionsOnConflict>;
}

/** on_conflict condition type for table "questions" */
export interface QuestionsOnConflict {
  constraint: QuestionsConstraint;
  update_columns?: Array<QuestionsUpdateColumn>;
  where?: InputMaybe<QuestionsBoolExp>;
}

/** Ordering options when selecting data from "questions". */
export interface QuestionsOrderBy {
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  report_answers_aggregate?: InputMaybe<ReportAnswersAggregateOrderBy>;
  report_tasks_aggregate?: InputMaybe<ReportTasksAggregateOrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  text?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: questions */
export interface QuestionsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "questions" */
export enum QuestionsSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "questions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questions" */
export enum QuestionsSelectColumnQuestionsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** select "questions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questions" */
export enum QuestionsSelectColumnQuestionsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "questions" */
export interface QuestionsSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<QuestionTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface QuestionsStddevFields {
  __typename?: 'questions_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "questions" */
export interface QuestionsStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface QuestionsStddevPopFields {
  __typename?: 'questions_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "questions" */
export interface QuestionsStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface QuestionsStddevSampFields {
  __typename?: 'questions_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "questions" */
export interface QuestionsStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "questions" */
export interface QuestionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: QuestionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface QuestionsStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<QuestionTypesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface QuestionsSumFields {
  __typename?: 'questions_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "questions" */
export interface QuestionsSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** update columns of table "questions" */
export enum QuestionsUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  SubChapterId = 'sub_chapter_id',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface QuestionsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QuestionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionsSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionsBoolExp;
}

/** aggregate var_pop on columns */
export interface QuestionsVarPopFields {
  __typename?: 'questions_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "questions" */
export interface QuestionsVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface QuestionsVarSampFields {
  __typename?: 'questions_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "questions" */
export interface QuestionsVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface QuestionsVarianceFields {
  __typename?: 'questions_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "questions" */
export interface QuestionsVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Table hosting recommandatios for progress reports form EU */
export interface Recommendations {
  __typename?: 'recommendations';
  /** An array relationship */
  bookmarks: Array<Bookmarks>;
  /** An aggregate relationship */
  bookmarks_aggregate: BookmarksAggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  report: Reports;
  report_id: Scalars['uuid']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
}


/** Table hosting recommandatios for progress reports form EU */
export interface RecommendationsBookmarksArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** Table hosting recommandatios for progress reports form EU */
export interface RecommendationsBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}

/** aggregated selection of "recommendations" */
export interface RecommendationsAggregate {
  __typename?: 'recommendations_aggregate';
  aggregate?: Maybe<RecommendationsAggregateFields>;
  nodes: Array<Recommendations>;
}

export interface RecommendationsAggregateBoolExp {
  count?: InputMaybe<RecommendationsAggregateBoolExpCount>;
}

export interface RecommendationsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<RecommendationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<RecommendationsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "recommendations" */
export interface RecommendationsAggregateFields {
  __typename?: 'recommendations_aggregate_fields';
  avg?: Maybe<RecommendationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RecommendationsMaxFields>;
  min?: Maybe<RecommendationsMinFields>;
  stddev?: Maybe<RecommendationsStddevFields>;
  stddev_pop?: Maybe<RecommendationsStddevPopFields>;
  stddev_samp?: Maybe<RecommendationsStddevSampFields>;
  sum?: Maybe<RecommendationsSumFields>;
  var_pop?: Maybe<RecommendationsVarPopFields>;
  var_samp?: Maybe<RecommendationsVarSampFields>;
  variance?: Maybe<RecommendationsVarianceFields>;
}


/** aggregate fields of "recommendations" */
export interface RecommendationsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<RecommendationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "recommendations" */
export interface RecommendationsAggregateOrderBy {
  avg?: InputMaybe<RecommendationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RecommendationsMaxOrderBy>;
  min?: InputMaybe<RecommendationsMinOrderBy>;
  stddev?: InputMaybe<RecommendationsStddevOrderBy>;
  stddev_pop?: InputMaybe<RecommendationsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RecommendationsStddevSampOrderBy>;
  sum?: InputMaybe<RecommendationsSumOrderBy>;
  var_pop?: InputMaybe<RecommendationsVarPopOrderBy>;
  var_samp?: InputMaybe<RecommendationsVarSampOrderBy>;
  variance?: InputMaybe<RecommendationsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "recommendations" */
export interface RecommendationsArrRelInsertInput {
  data: Array<RecommendationsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RecommendationsOnConflict>;
}

/** aggregate avg on columns */
export interface RecommendationsAvgFields {
  __typename?: 'recommendations_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "recommendations" */
export interface RecommendationsAvgOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "recommendations". All fields are combined with a logical 'AND'. */
export interface RecommendationsBoolExp {
  _and?: InputMaybe<Array<RecommendationsBoolExp>>;
  _not?: InputMaybe<RecommendationsBoolExp>;
  _or?: InputMaybe<Array<RecommendationsBoolExp>>;
  bookmarks?: InputMaybe<BookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  report?: InputMaybe<ReportsBoolExp>;
  report_id?: InputMaybe<UuidComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "recommendations" */
export enum RecommendationsConstraint {
  /** unique or primary key constraint on columns "id" */
  RecommendationsPkey = 'recommendations_pkey'
}

/** input type for incrementing numeric columns in table "recommendations" */
export interface RecommendationsIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "recommendations" */
export interface RecommendationsInsertInput {
  bookmarks?: InputMaybe<BookmarksArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<ReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface RecommendationsMaxFields {
  __typename?: 'recommendations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "recommendations" */
export interface RecommendationsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface RecommendationsMinFields {
  __typename?: 'recommendations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "recommendations" */
export interface RecommendationsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "recommendations" */
export interface RecommendationsMutationResponse {
  __typename?: 'recommendations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommendations>;
}

/** input type for inserting object relation for remote table "recommendations" */
export interface RecommendationsObjRelInsertInput {
  data: RecommendationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RecommendationsOnConflict>;
}

/** on_conflict condition type for table "recommendations" */
export interface RecommendationsOnConflict {
  constraint: RecommendationsConstraint;
  update_columns?: Array<RecommendationsUpdateColumn>;
  where?: InputMaybe<RecommendationsBoolExp>;
}

/** Ordering options when selecting data from "recommendations". */
export interface RecommendationsOrderBy {
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report?: InputMaybe<ReportsOrderBy>;
  report_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: recommendations */
export interface RecommendationsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "recommendations" */
export enum RecommendationsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recommendations" */
export interface RecommendationsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface RecommendationsStddevFields {
  __typename?: 'recommendations_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "recommendations" */
export interface RecommendationsStddevOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface RecommendationsStddevPopFields {
  __typename?: 'recommendations_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "recommendations" */
export interface RecommendationsStddevPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface RecommendationsStddevSampFields {
  __typename?: 'recommendations_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "recommendations" */
export interface RecommendationsStddevSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "recommendations" */
export interface RecommendationsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: RecommendationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface RecommendationsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface RecommendationsSumFields {
  __typename?: 'recommendations_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "recommendations" */
export interface RecommendationsSumOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** update columns of table "recommendations" */
export enum RecommendationsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface RecommendationsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RecommendationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecommendationsSetInput>;
  /** filter the rows which have to be updated */
  where: RecommendationsBoolExp;
}

/** aggregate var_pop on columns */
export interface RecommendationsVarPopFields {
  __typename?: 'recommendations_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "recommendations" */
export interface RecommendationsVarPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface RecommendationsVarSampFields {
  __typename?: 'recommendations_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "recommendations" */
export interface RecommendationsVarSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface RecommendationsVarianceFields {
  __typename?: 'recommendations_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "recommendations" */
export interface RecommendationsVarianceOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** answers submitted for particular reports */
export interface ReportAnswers {
  __typename?: 'report_answers';
  answer?: Maybe<Scalars['String']['output']>;
  answered_by_user_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  final_answer: Array<ReportTasks>;
  /** An aggregate relationship */
  final_answer_aggregate: ReportTasksAggregate;
  has_update?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  is_final_answer?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid']['output'];
  /** An object relationship */
  report: Reports;
  report_id: Scalars['uuid']['output'];
  /** An object relationship */
  report_task: ReportTasks;
  report_task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
}


/** answers submitted for particular reports */
export interface ReportAnswersDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** answers submitted for particular reports */
export interface ReportAnswersFinalAnswerArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


/** answers submitted for particular reports */
export interface ReportAnswersFinalAnswerAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}

/** aggregated selection of "report_answers" */
export interface ReportAnswersAggregate {
  __typename?: 'report_answers_aggregate';
  aggregate?: Maybe<ReportAnswersAggregateFields>;
  nodes: Array<ReportAnswers>;
}

export interface ReportAnswersAggregateBoolExp {
  bool_and?: InputMaybe<ReportAnswersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ReportAnswersAggregateBoolExpBoolOr>;
  count?: InputMaybe<ReportAnswersAggregateBoolExpCount>;
}

export interface ReportAnswersAggregateBoolExpBoolAnd {
  arguments: ReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportAnswersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface ReportAnswersAggregateBoolExpBoolOr {
  arguments: ReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportAnswersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface ReportAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportAnswersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "report_answers" */
export interface ReportAnswersAggregateFields {
  __typename?: 'report_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportAnswersMaxFields>;
  min?: Maybe<ReportAnswersMinFields>;
}


/** aggregate fields of "report_answers" */
export interface ReportAnswersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "report_answers" */
export interface ReportAnswersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportAnswersMaxOrderBy>;
  min?: InputMaybe<ReportAnswersMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface ReportAnswersAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "report_answers" */
export interface ReportAnswersArrRelInsertInput {
  data: Array<ReportAnswersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportAnswersOnConflict>;
}

/** Boolean expression to filter rows from the table "report_answers". All fields are combined with a logical 'AND'. */
export interface ReportAnswersBoolExp {
  _and?: InputMaybe<Array<ReportAnswersBoolExp>>;
  _not?: InputMaybe<ReportAnswersBoolExp>;
  _or?: InputMaybe<Array<ReportAnswersBoolExp>>;
  answer?: InputMaybe<StringComparisonExp>;
  answered_by_user_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  final_answer?: InputMaybe<ReportTasksBoolExp>;
  final_answer_aggregate?: InputMaybe<ReportTasksAggregateBoolExp>;
  has_update?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_final_answer?: InputMaybe<BooleanComparisonExp>;
  question?: InputMaybe<QuestionsBoolExp>;
  question_id?: InputMaybe<UuidComparisonExp>;
  report?: InputMaybe<ReportsBoolExp>;
  report_id?: InputMaybe<UuidComparisonExp>;
  report_task?: InputMaybe<ReportTasksBoolExp>;
  report_task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
}

/** unique or primary key constraints on table "report_answers" */
export enum ReportAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportAnswersPkey = 'report_answers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ReportAnswersDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ReportAnswersDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ReportAnswersDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "report_answers" */
export interface ReportAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  final_answer?: InputMaybe<ReportTasksArrRelInsertInput>;
  has_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_final_answer?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<QuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<ReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<ReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface ReportAnswersMaxFields {
  __typename?: 'report_answers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answered_by_user_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "report_answers" */
export interface ReportAnswersMaxOrderBy {
  answer?: InputMaybe<OrderBy>;
  answered_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  question_id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportAnswersMinFields {
  __typename?: 'report_answers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answered_by_user_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "report_answers" */
export interface ReportAnswersMinOrderBy {
  answer?: InputMaybe<OrderBy>;
  answered_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  question_id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "report_answers" */
export interface ReportAnswersMutationResponse {
  __typename?: 'report_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportAnswers>;
}

/** input type for inserting object relation for remote table "report_answers" */
export interface ReportAnswersObjRelInsertInput {
  data: ReportAnswersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportAnswersOnConflict>;
}

/** on_conflict condition type for table "report_answers" */
export interface ReportAnswersOnConflict {
  constraint: ReportAnswersConstraint;
  update_columns?: Array<ReportAnswersUpdateColumn>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}

/** Ordering options when selecting data from "report_answers". */
export interface ReportAnswersOrderBy {
  answer?: InputMaybe<OrderBy>;
  answered_by_user_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  final_answer_aggregate?: InputMaybe<ReportTasksAggregateOrderBy>;
  has_update?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_final_answer?: InputMaybe<OrderBy>;
  question?: InputMaybe<QuestionsOrderBy>;
  question_id?: InputMaybe<OrderBy>;
  report?: InputMaybe<ReportsOrderBy>;
  report_id?: InputMaybe<OrderBy>;
  report_task?: InputMaybe<ReportTasksOrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
}

/** primary key columns input for table: report_answers */
export interface ReportAnswersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ReportAnswersPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "report_answers" */
export enum ReportAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  IsFinalAnswer = 'is_final_answer',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "report_answers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "report_answers" */
export enum ReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  IsFinalAnswer = 'is_final_answer'
}

/** select "report_answers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "report_answers" */
export enum ReportAnswersSelectColumnReportAnswersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  IsFinalAnswer = 'is_final_answer'
}

/** input type for updating data in table "report_answers" */
export interface ReportAnswersSetInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  has_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_final_answer?: InputMaybe<Scalars['Boolean']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "report_answers" */
export interface ReportAnswersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportAnswersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportAnswersStreamCursorValueInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  answered_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  has_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_final_answer?: InputMaybe<Scalars['Boolean']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "report_answers" */
export enum ReportAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByUserId = 'answered_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  HasUpdate = 'has_update',
  /** column name */
  Id = 'id',
  /** column name */
  IsFinalAnswer = 'is_final_answer',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface ReportAnswersUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ReportAnswersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ReportAnswersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ReportAnswersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ReportAnswersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ReportAnswersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportAnswersSetInput>;
  /** filter the rows which have to be updated */
  where: ReportAnswersBoolExp;
}

/** keeps possible statuses for a given chapter in a given report */
export interface ReportChapterStatuses {
  __typename?: 'report_chapter_statuses';
  /** An array relationship */
  report_chapters: Array<ReportChapters>;
  /** An aggregate relationship */
  report_chapters_aggregate: ReportChaptersAggregate;
  status: Scalars['String']['output'];
}


/** keeps possible statuses for a given chapter in a given report */
export interface ReportChapterStatusesReportChaptersArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


/** keeps possible statuses for a given chapter in a given report */
export interface ReportChapterStatusesReportChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}

/** aggregated selection of "report_chapter_statuses" */
export interface ReportChapterStatusesAggregate {
  __typename?: 'report_chapter_statuses_aggregate';
  aggregate?: Maybe<ReportChapterStatusesAggregateFields>;
  nodes: Array<ReportChapterStatuses>;
}

/** aggregate fields of "report_chapter_statuses" */
export interface ReportChapterStatusesAggregateFields {
  __typename?: 'report_chapter_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportChapterStatusesMaxFields>;
  min?: Maybe<ReportChapterStatusesMinFields>;
}


/** aggregate fields of "report_chapter_statuses" */
export interface ReportChapterStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportChapterStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "report_chapter_statuses". All fields are combined with a logical 'AND'. */
export interface ReportChapterStatusesBoolExp {
  _and?: InputMaybe<Array<ReportChapterStatusesBoolExp>>;
  _not?: InputMaybe<ReportChapterStatusesBoolExp>;
  _or?: InputMaybe<Array<ReportChapterStatusesBoolExp>>;
  report_chapters?: InputMaybe<ReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<ReportChaptersAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "report_chapter_statuses" */
export enum ReportChapterStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportChapterStatusesPkey = 'report_chapter_statuses_pkey'
}

/** input type for inserting data into table "report_chapter_statuses" */
export interface ReportChapterStatusesInsertInput {
  report_chapters?: InputMaybe<ReportChaptersArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface ReportChapterStatusesMaxFields {
  __typename?: 'report_chapter_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface ReportChapterStatusesMinFields {
  __typename?: 'report_chapter_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "report_chapter_statuses" */
export interface ReportChapterStatusesMutationResponse {
  __typename?: 'report_chapter_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportChapterStatuses>;
}

/** input type for inserting object relation for remote table "report_chapter_statuses" */
export interface ReportChapterStatusesObjRelInsertInput {
  data: ReportChapterStatusesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportChapterStatusesOnConflict>;
}

/** on_conflict condition type for table "report_chapter_statuses" */
export interface ReportChapterStatusesOnConflict {
  constraint: ReportChapterStatusesConstraint;
  update_columns?: Array<ReportChapterStatusesUpdateColumn>;
  where?: InputMaybe<ReportChapterStatusesBoolExp>;
}

/** Ordering options when selecting data from "report_chapter_statuses". */
export interface ReportChapterStatusesOrderBy {
  report_chapters_aggregate?: InputMaybe<ReportChaptersAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_chapter_statuses */
export interface ReportChapterStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "report_chapter_statuses" */
export enum ReportChapterStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "report_chapter_statuses" */
export interface ReportChapterStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "report_chapter_statuses" */
export interface ReportChapterStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportChapterStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportChapterStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "report_chapter_statuses" */
export enum ReportChapterStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface ReportChapterStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportChapterStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: ReportChapterStatusesBoolExp;
}

/** keeps overall report status for a given chapter */
export interface ReportChapters {
  __typename?: 'report_chapters';
  /** An object relationship */
  chapter: Chapters;
  chapter_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  report: Reports;
  /** An object relationship */
  report_chapter_status: ReportChapterStatuses;
  report_id: Scalars['uuid']['output'];
  /** An array relationship */
  signed_documents: Array<SignedDocuments>;
  /** An aggregate relationship */
  signed_documents_aggregate: SignedDocumentsAggregate;
  status: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
}


/** keeps overall report status for a given chapter */
export interface ReportChaptersSignedDocumentsArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


/** keeps overall report status for a given chapter */
export interface ReportChaptersSignedDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}

/** aggregated selection of "report_chapters" */
export interface ReportChaptersAggregate {
  __typename?: 'report_chapters_aggregate';
  aggregate?: Maybe<ReportChaptersAggregateFields>;
  nodes: Array<ReportChapters>;
}

export interface ReportChaptersAggregateBoolExp {
  count?: InputMaybe<ReportChaptersAggregateBoolExpCount>;
}

export interface ReportChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportChaptersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "report_chapters" */
export interface ReportChaptersAggregateFields {
  __typename?: 'report_chapters_aggregate_fields';
  avg?: Maybe<ReportChaptersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ReportChaptersMaxFields>;
  min?: Maybe<ReportChaptersMinFields>;
  stddev?: Maybe<ReportChaptersStddevFields>;
  stddev_pop?: Maybe<ReportChaptersStddevPopFields>;
  stddev_samp?: Maybe<ReportChaptersStddevSampFields>;
  sum?: Maybe<ReportChaptersSumFields>;
  var_pop?: Maybe<ReportChaptersVarPopFields>;
  var_samp?: Maybe<ReportChaptersVarSampFields>;
  variance?: Maybe<ReportChaptersVarianceFields>;
}


/** aggregate fields of "report_chapters" */
export interface ReportChaptersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "report_chapters" */
export interface ReportChaptersAggregateOrderBy {
  avg?: InputMaybe<ReportChaptersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportChaptersMaxOrderBy>;
  min?: InputMaybe<ReportChaptersMinOrderBy>;
  stddev?: InputMaybe<ReportChaptersStddevOrderBy>;
  stddev_pop?: InputMaybe<ReportChaptersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReportChaptersStddevSampOrderBy>;
  sum?: InputMaybe<ReportChaptersSumOrderBy>;
  var_pop?: InputMaybe<ReportChaptersVarPopOrderBy>;
  var_samp?: InputMaybe<ReportChaptersVarSampOrderBy>;
  variance?: InputMaybe<ReportChaptersVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "report_chapters" */
export interface ReportChaptersArrRelInsertInput {
  data: Array<ReportChaptersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportChaptersOnConflict>;
}

/** aggregate avg on columns */
export interface ReportChaptersAvgFields {
  __typename?: 'report_chapters_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "report_chapters" */
export interface ReportChaptersAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "report_chapters". All fields are combined with a logical 'AND'. */
export interface ReportChaptersBoolExp {
  _and?: InputMaybe<Array<ReportChaptersBoolExp>>;
  _not?: InputMaybe<ReportChaptersBoolExp>;
  _or?: InputMaybe<Array<ReportChaptersBoolExp>>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  report?: InputMaybe<ReportsBoolExp>;
  report_chapter_status?: InputMaybe<ReportChapterStatusesBoolExp>;
  report_id?: InputMaybe<UuidComparisonExp>;
  signed_documents?: InputMaybe<SignedDocumentsBoolExp>;
  signed_documents_aggregate?: InputMaybe<SignedDocumentsAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "report_chapters" */
export enum ReportChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportChaptersPkey = 'report_chapters_pkey'
}

/** input type for incrementing numeric columns in table "report_chapters" */
export interface ReportChaptersIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "report_chapters" */
export interface ReportChaptersInsertInput {
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<ReportsObjRelInsertInput>;
  report_chapter_status?: InputMaybe<ReportChapterStatusesObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  signed_documents?: InputMaybe<SignedDocumentsArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface ReportChaptersMaxFields {
  __typename?: 'report_chapters_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "report_chapters" */
export interface ReportChaptersMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportChaptersMinFields {
  __typename?: 'report_chapters_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "report_chapters" */
export interface ReportChaptersMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "report_chapters" */
export interface ReportChaptersMutationResponse {
  __typename?: 'report_chapters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportChapters>;
}

/** input type for inserting object relation for remote table "report_chapters" */
export interface ReportChaptersObjRelInsertInput {
  data: ReportChaptersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportChaptersOnConflict>;
}

/** on_conflict condition type for table "report_chapters" */
export interface ReportChaptersOnConflict {
  constraint: ReportChaptersConstraint;
  update_columns?: Array<ReportChaptersUpdateColumn>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}

/** Ordering options when selecting data from "report_chapters". */
export interface ReportChaptersOrderBy {
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report?: InputMaybe<ReportsOrderBy>;
  report_chapter_status?: InputMaybe<ReportChapterStatusesOrderBy>;
  report_id?: InputMaybe<OrderBy>;
  signed_documents_aggregate?: InputMaybe<SignedDocumentsAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_chapters */
export interface ReportChaptersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "report_chapters" */
export enum ReportChaptersSelectColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "report_chapters" */
export interface ReportChaptersSetInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface ReportChaptersStddevFields {
  __typename?: 'report_chapters_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "report_chapters" */
export interface ReportChaptersStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ReportChaptersStddevPopFields {
  __typename?: 'report_chapters_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "report_chapters" */
export interface ReportChaptersStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ReportChaptersStddevSampFields {
  __typename?: 'report_chapters_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "report_chapters" */
export interface ReportChaptersStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "report_chapters" */
export interface ReportChaptersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportChaptersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportChaptersStreamCursorValueInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface ReportChaptersSumFields {
  __typename?: 'report_chapters_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "report_chapters" */
export interface ReportChaptersSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** update columns of table "report_chapters" */
export enum ReportChaptersUpdateColumn {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface ReportChaptersUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportChaptersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportChaptersSetInput>;
  /** filter the rows which have to be updated */
  where: ReportChaptersBoolExp;
}

/** aggregate var_pop on columns */
export interface ReportChaptersVarPopFields {
  __typename?: 'report_chapters_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "report_chapters" */
export interface ReportChaptersVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface ReportChaptersVarSampFields {
  __typename?: 'report_chapters_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "report_chapters" */
export interface ReportChaptersVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface ReportChaptersVarianceFields {
  __typename?: 'report_chapters_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "report_chapters" */
export interface ReportChaptersVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
}

/** statuses for reports */
export interface ReportStatuses {
  __typename?: 'report_statuses';
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: ReportsAggregate;
  status: Scalars['String']['output'];
}


/** statuses for reports */
export interface ReportStatusesReportsArgs {
  distinct_on?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
}


/** statuses for reports */
export interface ReportStatusesReportsAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
}

/** aggregated selection of "report_statuses" */
export interface ReportStatusesAggregate {
  __typename?: 'report_statuses_aggregate';
  aggregate?: Maybe<ReportStatusesAggregateFields>;
  nodes: Array<ReportStatuses>;
}

/** aggregate fields of "report_statuses" */
export interface ReportStatusesAggregateFields {
  __typename?: 'report_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportStatusesMaxFields>;
  min?: Maybe<ReportStatusesMinFields>;
}


/** aggregate fields of "report_statuses" */
export interface ReportStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "report_statuses". All fields are combined with a logical 'AND'. */
export interface ReportStatusesBoolExp {
  _and?: InputMaybe<Array<ReportStatusesBoolExp>>;
  _not?: InputMaybe<ReportStatusesBoolExp>;
  _or?: InputMaybe<Array<ReportStatusesBoolExp>>;
  reports?: InputMaybe<ReportsBoolExp>;
  reports_aggregate?: InputMaybe<ReportsAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "report_statuses" */
export enum ReportStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportStatusesPkey = 'report_statuses_pkey'
}

export enum ReportStatusesEnum {
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING'
}

/** Boolean expression to compare columns of type "report_statuses_enum". All fields are combined with logical 'AND'. */
export interface ReportStatusesEnumComparisonExp {
  _eq?: InputMaybe<ReportStatusesEnum>;
  _in?: InputMaybe<Array<ReportStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ReportStatusesEnum>;
  _nin?: InputMaybe<Array<ReportStatusesEnum>>;
}

/** input type for inserting data into table "report_statuses" */
export interface ReportStatusesInsertInput {
  reports?: InputMaybe<ReportsArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface ReportStatusesMaxFields {
  __typename?: 'report_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface ReportStatusesMinFields {
  __typename?: 'report_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "report_statuses" */
export interface ReportStatusesMutationResponse {
  __typename?: 'report_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportStatuses>;
}

/** input type for inserting object relation for remote table "report_statuses" */
export interface ReportStatusesObjRelInsertInput {
  data: ReportStatusesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportStatusesOnConflict>;
}

/** on_conflict condition type for table "report_statuses" */
export interface ReportStatusesOnConflict {
  constraint: ReportStatusesConstraint;
  update_columns?: Array<ReportStatusesUpdateColumn>;
  where?: InputMaybe<ReportStatusesBoolExp>;
}

/** Ordering options when selecting data from "report_statuses". */
export interface ReportStatusesOrderBy {
  reports_aggregate?: InputMaybe<ReportsAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_statuses */
export interface ReportStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "report_statuses" */
export enum ReportStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "report_statuses" */
export interface ReportStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "report_statuses" */
export interface ReportStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "report_statuses" */
export enum ReportStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface ReportStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: ReportStatusesBoolExp;
}

/** keeps all the logs of the activity around a task report, will be inserted automatically */
export interface ReportTaskActivity {
  __typename?: 'report_task_activity';
  activity: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  report_task?: Maybe<ReportTasks>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<ReportTaskStatusesEnum>;
}

/** aggregated selection of "report_task_activity" */
export interface ReportTaskActivityAggregate {
  __typename?: 'report_task_activity_aggregate';
  aggregate?: Maybe<ReportTaskActivityAggregateFields>;
  nodes: Array<ReportTaskActivity>;
}

export interface ReportTaskActivityAggregateBoolExp {
  count?: InputMaybe<ReportTaskActivityAggregateBoolExpCount>;
}

export interface ReportTaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportTaskActivityBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "report_task_activity" */
export interface ReportTaskActivityAggregateFields {
  __typename?: 'report_task_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportTaskActivityMaxFields>;
  min?: Maybe<ReportTaskActivityMinFields>;
}


/** aggregate fields of "report_task_activity" */
export interface ReportTaskActivityAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "report_task_activity" */
export interface ReportTaskActivityAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportTaskActivityMaxOrderBy>;
  min?: InputMaybe<ReportTaskActivityMinOrderBy>;
}

/** input type for inserting array relation for remote table "report_task_activity" */
export interface ReportTaskActivityArrRelInsertInput {
  data: Array<ReportTaskActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportTaskActivityOnConflict>;
}

/** Boolean expression to filter rows from the table "report_task_activity". All fields are combined with a logical 'AND'. */
export interface ReportTaskActivityBoolExp {
  _and?: InputMaybe<Array<ReportTaskActivityBoolExp>>;
  _not?: InputMaybe<ReportTaskActivityBoolExp>;
  _or?: InputMaybe<Array<ReportTaskActivityBoolExp>>;
  activity?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  report_task?: InputMaybe<ReportTasksBoolExp>;
  report_task_id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<ReportTaskStatusesEnumComparisonExp>;
}

/** unique or primary key constraints on table "report_task_activity" */
export enum ReportTaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskActivityPkey = 'report_task_activity_pkey'
}

/** input type for inserting data into table "report_task_activity" */
export interface ReportTaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<ReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<ReportTaskStatusesEnum>;
}

/** aggregate max on columns */
export interface ReportTaskActivityMaxFields {
  __typename?: 'report_task_activity_max_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "report_task_activity" */
export interface ReportTaskActivityMaxOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportTaskActivityMinFields {
  __typename?: 'report_task_activity_min_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "report_task_activity" */
export interface ReportTaskActivityMinOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "report_task_activity" */
export interface ReportTaskActivityMutationResponse {
  __typename?: 'report_task_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportTaskActivity>;
}

/** on_conflict condition type for table "report_task_activity" */
export interface ReportTaskActivityOnConflict {
  constraint: ReportTaskActivityConstraint;
  update_columns?: Array<ReportTaskActivityUpdateColumn>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}

/** Ordering options when selecting data from "report_task_activity". */
export interface ReportTaskActivityOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task?: InputMaybe<ReportTasksOrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_task_activity */
export interface ReportTaskActivityPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "report_task_activity" */
export enum ReportTaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "report_task_activity" */
export interface ReportTaskActivitySetInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<ReportTaskStatusesEnum>;
}

/** Streaming cursor of the table "report_task_activity" */
export interface ReportTaskActivityStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportTaskActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportTaskActivityStreamCursorValueInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<ReportTaskStatusesEnum>;
}

/** update columns of table "report_task_activity" */
export enum ReportTaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  Status = 'status'
}

export interface ReportTaskActivityUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportTaskActivitySetInput>;
  /** filter the rows which have to be updated */
  where: ReportTaskActivityBoolExp;
}

/** keeps all the comments done around a report task */
export interface ReportTaskComment {
  __typename?: 'report_task_comment';
  by_user_id: Scalars['uuid']['output'];
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  report_task: ReportTasks;
  report_task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "report_task_comment" */
export interface ReportTaskCommentAggregate {
  __typename?: 'report_task_comment_aggregate';
  aggregate?: Maybe<ReportTaskCommentAggregateFields>;
  nodes: Array<ReportTaskComment>;
}

export interface ReportTaskCommentAggregateBoolExp {
  count?: InputMaybe<ReportTaskCommentAggregateBoolExpCount>;
}

export interface ReportTaskCommentAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportTaskCommentBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "report_task_comment" */
export interface ReportTaskCommentAggregateFields {
  __typename?: 'report_task_comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportTaskCommentMaxFields>;
  min?: Maybe<ReportTaskCommentMinFields>;
}


/** aggregate fields of "report_task_comment" */
export interface ReportTaskCommentAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "report_task_comment" */
export interface ReportTaskCommentAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportTaskCommentMaxOrderBy>;
  min?: InputMaybe<ReportTaskCommentMinOrderBy>;
}

/** input type for inserting array relation for remote table "report_task_comment" */
export interface ReportTaskCommentArrRelInsertInput {
  data: Array<ReportTaskCommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportTaskCommentOnConflict>;
}

/** Boolean expression to filter rows from the table "report_task_comment". All fields are combined with a logical 'AND'. */
export interface ReportTaskCommentBoolExp {
  _and?: InputMaybe<Array<ReportTaskCommentBoolExp>>;
  _not?: InputMaybe<ReportTaskCommentBoolExp>;
  _or?: InputMaybe<Array<ReportTaskCommentBoolExp>>;
  by_user_id?: InputMaybe<UuidComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  report_task?: InputMaybe<ReportTasksBoolExp>;
  report_task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "report_task_comment" */
export enum ReportTaskCommentConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskCommentPkey = 'report_task_comment_pkey'
}

/** input type for inserting data into table "report_task_comment" */
export interface ReportTaskCommentInsertInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<ReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface ReportTaskCommentMaxFields {
  __typename?: 'report_task_comment_max_fields';
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "report_task_comment" */
export interface ReportTaskCommentMaxOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportTaskCommentMinFields {
  __typename?: 'report_task_comment_min_fields';
  by_user_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "report_task_comment" */
export interface ReportTaskCommentMinOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "report_task_comment" */
export interface ReportTaskCommentMutationResponse {
  __typename?: 'report_task_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportTaskComment>;
}

/** on_conflict condition type for table "report_task_comment" */
export interface ReportTaskCommentOnConflict {
  constraint: ReportTaskCommentConstraint;
  update_columns?: Array<ReportTaskCommentUpdateColumn>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}

/** Ordering options when selecting data from "report_task_comment". */
export interface ReportTaskCommentOrderBy {
  by_user_id?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task?: InputMaybe<ReportTasksOrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_task_comment */
export interface ReportTaskCommentPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "report_task_comment" */
export enum ReportTaskCommentSelectColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "report_task_comment" */
export interface ReportTaskCommentSetInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "report_task_comment" */
export interface ReportTaskCommentStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportTaskCommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportTaskCommentStreamCursorValueInput {
  by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "report_task_comment" */
export enum ReportTaskCommentUpdateColumn {
  /** column name */
  ByUserId = 'by_user_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface ReportTaskCommentUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportTaskCommentSetInput>;
  /** filter the rows which have to be updated */
  where: ReportTaskCommentBoolExp;
}

/** columns and relationships of "report_task_gnpie_members" */
export interface ReportTaskGnpieMembers {
  __typename?: 'report_task_gnpie_members';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  gnpie_member: Users;
  gnpie_member_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  report_task: ReportTasks;
  report_task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersAggregate {
  __typename?: 'report_task_gnpie_members_aggregate';
  aggregate?: Maybe<ReportTaskGnpieMembersAggregateFields>;
  nodes: Array<ReportTaskGnpieMembers>;
}

export interface ReportTaskGnpieMembersAggregateBoolExp {
  count?: InputMaybe<ReportTaskGnpieMembersAggregateBoolExpCount>;
}

export interface ReportTaskGnpieMembersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersAggregateFields {
  __typename?: 'report_task_gnpie_members_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportTaskGnpieMembersMaxFields>;
  min?: Maybe<ReportTaskGnpieMembersMinFields>;
}


/** aggregate fields of "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportTaskGnpieMembersMaxOrderBy>;
  min?: InputMaybe<ReportTaskGnpieMembersMinOrderBy>;
}

/** input type for inserting array relation for remote table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersArrRelInsertInput {
  data: Array<ReportTaskGnpieMembersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportTaskGnpieMembersOnConflict>;
}

/** Boolean expression to filter rows from the table "report_task_gnpie_members". All fields are combined with a logical 'AND'. */
export interface ReportTaskGnpieMembersBoolExp {
  _and?: InputMaybe<Array<ReportTaskGnpieMembersBoolExp>>;
  _not?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
  _or?: InputMaybe<Array<ReportTaskGnpieMembersBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  report_task?: InputMaybe<ReportTasksBoolExp>;
  report_task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "report_task_gnpie_members" */
export enum ReportTaskGnpieMembersConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTaskGnpieMembersPkey = 'report_task_gnpie_members_pkey',
  /** unique or primary key constraint on columns "gnpie_member_id", "report_task_id" */
  ReportTaskGnpieMembersReportTaskIdGnpieMemberIdKey = 'report_task_gnpie_members_report_task_id_gnpie_member_id_key'
}

/** input type for inserting data into table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task?: InputMaybe<ReportTasksObjRelInsertInput>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface ReportTaskGnpieMembersMaxFields {
  __typename?: 'report_task_gnpie_members_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportTaskGnpieMembersMinFields {
  __typename?: 'report_task_gnpie_members_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersMutationResponse {
  __typename?: 'report_task_gnpie_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportTaskGnpieMembers>;
}

/** on_conflict condition type for table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersOnConflict {
  constraint: ReportTaskGnpieMembersConstraint;
  update_columns?: Array<ReportTaskGnpieMembersUpdateColumn>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}

/** Ordering options when selecting data from "report_task_gnpie_members". */
export interface ReportTaskGnpieMembersOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_task?: InputMaybe<ReportTasksOrderBy>;
  report_task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_task_gnpie_members */
export interface ReportTaskGnpieMembersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "report_task_gnpie_members" */
export enum ReportTaskGnpieMembersSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "report_task_gnpie_members" */
export interface ReportTaskGnpieMembersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportTaskGnpieMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportTaskGnpieMembersStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "report_task_gnpie_members" */
export enum ReportTaskGnpieMembersUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportTaskId = 'report_task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface ReportTaskGnpieMembersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportTaskGnpieMembersSetInput>;
  /** filter the rows which have to be updated */
  where: ReportTaskGnpieMembersBoolExp;
}

/** statuses of report tasks */
export interface ReportTaskStatuses {
  __typename?: 'report_task_statuses';
  /** An array relationship */
  report_tasks: Array<ReportTasks>;
  /** An aggregate relationship */
  report_tasks_aggregate: ReportTasksAggregate;
  status: Scalars['String']['output'];
}


/** statuses of report tasks */
export interface ReportTaskStatusesReportTasksArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


/** statuses of report tasks */
export interface ReportTaskStatusesReportTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}

/** aggregated selection of "report_task_statuses" */
export interface ReportTaskStatusesAggregate {
  __typename?: 'report_task_statuses_aggregate';
  aggregate?: Maybe<ReportTaskStatusesAggregateFields>;
  nodes: Array<ReportTaskStatuses>;
}

/** aggregate fields of "report_task_statuses" */
export interface ReportTaskStatusesAggregateFields {
  __typename?: 'report_task_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportTaskStatusesMaxFields>;
  min?: Maybe<ReportTaskStatusesMinFields>;
}


/** aggregate fields of "report_task_statuses" */
export interface ReportTaskStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportTaskStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "report_task_statuses". All fields are combined with a logical 'AND'. */
export interface ReportTaskStatusesBoolExp {
  _and?: InputMaybe<Array<ReportTaskStatusesBoolExp>>;
  _not?: InputMaybe<ReportTaskStatusesBoolExp>;
  _or?: InputMaybe<Array<ReportTaskStatusesBoolExp>>;
  report_tasks?: InputMaybe<ReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<ReportTasksAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "report_task_statuses" */
export enum ReportTaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ReportTaskStatusesPkey = 'report_task_statuses_pkey'
}

export enum ReportTaskStatusesEnum {
  Done = 'DONE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

/** Boolean expression to compare columns of type "report_task_statuses_enum". All fields are combined with logical 'AND'. */
export interface ReportTaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<ReportTaskStatusesEnum>;
  _in?: InputMaybe<Array<ReportTaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ReportTaskStatusesEnum>;
  _nin?: InputMaybe<Array<ReportTaskStatusesEnum>>;
}

/** input type for inserting data into table "report_task_statuses" */
export interface ReportTaskStatusesInsertInput {
  report_tasks?: InputMaybe<ReportTasksArrRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface ReportTaskStatusesMaxFields {
  __typename?: 'report_task_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface ReportTaskStatusesMinFields {
  __typename?: 'report_task_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "report_task_statuses" */
export interface ReportTaskStatusesMutationResponse {
  __typename?: 'report_task_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportTaskStatuses>;
}

/** input type for inserting object relation for remote table "report_task_statuses" */
export interface ReportTaskStatusesObjRelInsertInput {
  data: ReportTaskStatusesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportTaskStatusesOnConflict>;
}

/** on_conflict condition type for table "report_task_statuses" */
export interface ReportTaskStatusesOnConflict {
  constraint: ReportTaskStatusesConstraint;
  update_columns?: Array<ReportTaskStatusesUpdateColumn>;
  where?: InputMaybe<ReportTaskStatusesBoolExp>;
}

/** Ordering options when selecting data from "report_task_statuses". */
export interface ReportTaskStatusesOrderBy {
  report_tasks_aggregate?: InputMaybe<ReportTasksAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_task_statuses */
export interface ReportTaskStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "report_task_statuses" */
export enum ReportTaskStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "report_task_statuses" */
export interface ReportTaskStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "report_task_statuses" */
export interface ReportTaskStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportTaskStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportTaskStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "report_task_statuses" */
export enum ReportTaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface ReportTaskStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportTaskStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: ReportTaskStatusesBoolExp;
}

/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasks {
  __typename?: 'report_tasks';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chapter_coordinator?: Maybe<Users>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chief_gnpie_secretariat?: Maybe<Users>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deadline: Scalars['date']['output'];
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  final_answer_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_member?: Maybe<Users>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_secretariat?: Maybe<Users>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  is_priority: Scalars['Boolean']['output'];
  /** An object relationship */
  negotiator?: Maybe<Users>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid']['output'];
  /** An object relationship */
  report: Reports;
  report_id: Scalars['uuid']['output'];
  /** An array relationship */
  report_task_activities: Array<ReportTaskActivity>;
  /** An aggregate relationship */
  report_task_activities_aggregate: ReportTaskActivityAggregate;
  /** An array relationship */
  report_task_answers: Array<ReportAnswers>;
  /** An aggregate relationship */
  report_task_answers_aggregate: ReportAnswersAggregate;
  /** An array relationship */
  report_task_comments: Array<ReportTaskComment>;
  /** An aggregate relationship */
  report_task_comments_aggregate: ReportTaskCommentAggregate;
  /** An object relationship */
  report_task_final_answer?: Maybe<ReportAnswers>;
  /** An array relationship */
  report_task_gnpie_members: Array<ReportTaskGnpieMembers>;
  /** An aggregate relationship */
  report_task_gnpie_members_aggregate: ReportTaskGnpieMembersAggregate;
  /** An object relationship */
  report_task_status: ReportTaskStatuses;
  status: ReportTaskStatusesEnum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  updated_by_user?: Maybe<Users>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskActivitiesArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskActivitiesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskCommentsArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskGnpieMembersOrderBy>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}


/** contains tasks for report, one for each question for that progres report and all the answers for that question */
export interface ReportTasksReportTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskGnpieMembersOrderBy>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}

/** aggregated selection of "report_tasks" */
export interface ReportTasksAggregate {
  __typename?: 'report_tasks_aggregate';
  aggregate?: Maybe<ReportTasksAggregateFields>;
  nodes: Array<ReportTasks>;
}

export interface ReportTasksAggregateBoolExp {
  bool_and?: InputMaybe<ReportTasksAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ReportTasksAggregateBoolExpBoolOr>;
  count?: InputMaybe<ReportTasksAggregateBoolExpCount>;
}

export interface ReportTasksAggregateBoolExpBoolAnd {
  arguments: ReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportTasksBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface ReportTasksAggregateBoolExpBoolOr {
  arguments: ReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportTasksBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface ReportTasksAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportTasksBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "report_tasks" */
export interface ReportTasksAggregateFields {
  __typename?: 'report_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ReportTasksMaxFields>;
  min?: Maybe<ReportTasksMinFields>;
}


/** aggregate fields of "report_tasks" */
export interface ReportTasksAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "report_tasks" */
export interface ReportTasksAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportTasksMaxOrderBy>;
  min?: InputMaybe<ReportTasksMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface ReportTasksAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "report_tasks" */
export interface ReportTasksArrRelInsertInput {
  data: Array<ReportTasksInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportTasksOnConflict>;
}

/** Boolean expression to filter rows from the table "report_tasks". All fields are combined with a logical 'AND'. */
export interface ReportTasksBoolExp {
  _and?: InputMaybe<Array<ReportTasksBoolExp>>;
  _not?: InputMaybe<ReportTasksBoolExp>;
  _or?: InputMaybe<Array<ReportTasksBoolExp>>;
  assignee_id?: InputMaybe<UuidComparisonExp>;
  chapter_coordinator?: InputMaybe<UsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<UuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  completition_date?: InputMaybe<DateComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  final_answer_id?: InputMaybe<UuidComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_priority?: InputMaybe<BooleanComparisonExp>;
  negotiator?: InputMaybe<UsersBoolExp>;
  negotiator_id?: InputMaybe<UuidComparisonExp>;
  question?: InputMaybe<QuestionsBoolExp>;
  question_id?: InputMaybe<UuidComparisonExp>;
  report?: InputMaybe<ReportsBoolExp>;
  report_id?: InputMaybe<UuidComparisonExp>;
  report_task_activities?: InputMaybe<ReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<ReportTaskActivityAggregateBoolExp>;
  report_task_answers?: InputMaybe<ReportAnswersBoolExp>;
  report_task_answers_aggregate?: InputMaybe<ReportAnswersAggregateBoolExp>;
  report_task_comments?: InputMaybe<ReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<ReportTaskCommentAggregateBoolExp>;
  report_task_final_answer?: InputMaybe<ReportAnswersBoolExp>;
  report_task_gnpie_members?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
  report_task_gnpie_members_aggregate?: InputMaybe<ReportTaskGnpieMembersAggregateBoolExp>;
  report_task_status?: InputMaybe<ReportTaskStatusesBoolExp>;
  status?: InputMaybe<ReportTaskStatusesEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  updated_by_user?: InputMaybe<UsersBoolExp>;
  updated_by_user_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "report_tasks" */
export enum ReportTasksConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportTasksPkey = 'report_tasks_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ReportTasksDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ReportTasksDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ReportTasksDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "report_tasks" */
export interface ReportTasksInsertInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<UsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  final_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_priority?: InputMaybe<Scalars['Boolean']['input']>;
  negotiator?: InputMaybe<UsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<QuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report?: InputMaybe<ReportsObjRelInsertInput>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  report_task_activities?: InputMaybe<ReportTaskActivityArrRelInsertInput>;
  report_task_answers?: InputMaybe<ReportAnswersArrRelInsertInput>;
  report_task_comments?: InputMaybe<ReportTaskCommentArrRelInsertInput>;
  report_task_final_answer?: InputMaybe<ReportAnswersObjRelInsertInput>;
  report_task_gnpie_members?: InputMaybe<ReportTaskGnpieMembersArrRelInsertInput>;
  report_task_status?: InputMaybe<ReportTaskStatusesObjRelInsertInput>;
  status?: InputMaybe<ReportTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user?: InputMaybe<UsersObjRelInsertInput>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface ReportTasksMaxFields {
  __typename?: 'report_tasks_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  final_answer_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "report_tasks" */
export interface ReportTasksMaxOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  final_answer_id?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  question_id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportTasksMinFields {
  __typename?: 'report_tasks_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  final_answer_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  report_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "report_tasks" */
export interface ReportTasksMinOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  final_answer_id?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  question_id?: InputMaybe<OrderBy>;
  report_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "report_tasks" */
export interface ReportTasksMutationResponse {
  __typename?: 'report_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportTasks>;
}

/** input type for inserting object relation for remote table "report_tasks" */
export interface ReportTasksObjRelInsertInput {
  data: ReportTasksInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportTasksOnConflict>;
}

/** on_conflict condition type for table "report_tasks" */
export interface ReportTasksOnConflict {
  constraint: ReportTasksConstraint;
  update_columns?: Array<ReportTasksUpdateColumn>;
  where?: InputMaybe<ReportTasksBoolExp>;
}

/** Ordering options when selecting data from "report_tasks". */
export interface ReportTasksOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  chapter_coordinator?: InputMaybe<UsersOrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  final_answer_id?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_priority?: InputMaybe<OrderBy>;
  negotiator?: InputMaybe<UsersOrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  question?: InputMaybe<QuestionsOrderBy>;
  question_id?: InputMaybe<OrderBy>;
  report?: InputMaybe<ReportsOrderBy>;
  report_id?: InputMaybe<OrderBy>;
  report_task_activities_aggregate?: InputMaybe<ReportTaskActivityAggregateOrderBy>;
  report_task_answers_aggregate?: InputMaybe<ReportAnswersAggregateOrderBy>;
  report_task_comments_aggregate?: InputMaybe<ReportTaskCommentAggregateOrderBy>;
  report_task_final_answer?: InputMaybe<ReportAnswersOrderBy>;
  report_task_gnpie_members_aggregate?: InputMaybe<ReportTaskGnpieMembersAggregateOrderBy>;
  report_task_status?: InputMaybe<ReportTaskStatusesOrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  updated_by_user?: InputMaybe<UsersOrderBy>;
  updated_by_user_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: report_tasks */
export interface ReportTasksPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ReportTasksPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "report_tasks" */
export enum ReportTasksSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

/** select "report_tasks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "report_tasks" */
export enum ReportTasksSelectColumnReportTasksAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

/** select "report_tasks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "report_tasks" */
export enum ReportTasksSelectColumnReportTasksAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPriority = 'is_priority'
}

/** input type for updating data in table "report_tasks" */
export interface ReportTasksSetInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  final_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_priority?: InputMaybe<Scalars['Boolean']['input']>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<ReportTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "report_tasks" */
export interface ReportTasksStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportTasksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportTasksStreamCursorValueInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  final_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_priority?: InputMaybe<Scalars['Boolean']['input']>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  report_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<ReportTaskStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "report_tasks" */
export enum ReportTasksUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  FinalAnswerId = 'final_answer_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPriority = 'is_priority',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReportId = 'report_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id'
}

export interface ReportTasksUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ReportTasksAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ReportTasksDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ReportTasksDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ReportTasksDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ReportTasksPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportTasksSetInput>;
  /** filter the rows which have to be updated */
  where: ReportTasksBoolExp;
}

/** contains the main entity for progress reports */
export interface Reports {
  __typename?: 'reports';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  deadline: Scalars['date']['output'];
  dms_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  recommendation?: Maybe<Recommendations>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: RecommendationsAggregate;
  /** An array relationship */
  report_answers: Array<ReportAnswers>;
  /** An aggregate relationship */
  report_answers_aggregate: ReportAnswersAggregate;
  /** An array relationship */
  report_chapters: Array<ReportChapters>;
  /** An aggregate relationship */
  report_chapters_aggregate: ReportChaptersAggregate;
  report_name: Scalars['String']['output'];
  /** An object relationship */
  report_status: ReportStatuses;
  /** An array relationship */
  report_tasks: Array<ReportTasks>;
  /** An aggregate relationship */
  report_tasks_aggregate: ReportTasksAggregate;
  status: ReportStatusesEnum;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
}


/** contains the main entity for progress reports */
export interface ReportsRecommendationsArgs {
  distinct_on?: InputMaybe<Array<RecommendationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RecommendationsOrderBy>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsRecommendationsAggregateArgs {
  distinct_on?: InputMaybe<Array<RecommendationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RecommendationsOrderBy>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsReportAnswersArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsReportAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsReportChaptersArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsReportChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsReportTasksArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


/** contains the main entity for progress reports */
export interface ReportsReportTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}

/** aggregated selection of "reports" */
export interface ReportsAggregate {
  __typename?: 'reports_aggregate';
  aggregate?: Maybe<ReportsAggregateFields>;
  nodes: Array<Reports>;
}

export interface ReportsAggregateBoolExp {
  count?: InputMaybe<ReportsAggregateBoolExpCount>;
}

export interface ReportsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<ReportsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReportsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "reports" */
export interface ReportsAggregateFields {
  __typename?: 'reports_aggregate_fields';
  avg?: Maybe<ReportsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ReportsMaxFields>;
  min?: Maybe<ReportsMinFields>;
  stddev?: Maybe<ReportsStddevFields>;
  stddev_pop?: Maybe<ReportsStddevPopFields>;
  stddev_samp?: Maybe<ReportsStddevSampFields>;
  sum?: Maybe<ReportsSumFields>;
  var_pop?: Maybe<ReportsVarPopFields>;
  var_samp?: Maybe<ReportsVarSampFields>;
  variance?: Maybe<ReportsVarianceFields>;
}


/** aggregate fields of "reports" */
export interface ReportsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<ReportsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "reports" */
export interface ReportsAggregateOrderBy {
  avg?: InputMaybe<ReportsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportsMaxOrderBy>;
  min?: InputMaybe<ReportsMinOrderBy>;
  stddev?: InputMaybe<ReportsStddevOrderBy>;
  stddev_pop?: InputMaybe<ReportsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReportsStddevSampOrderBy>;
  sum?: InputMaybe<ReportsSumOrderBy>;
  var_pop?: InputMaybe<ReportsVarPopOrderBy>;
  var_samp?: InputMaybe<ReportsVarSampOrderBy>;
  variance?: InputMaybe<ReportsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "reports" */
export interface ReportsArrRelInsertInput {
  data: Array<ReportsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportsOnConflict>;
}

/** aggregate avg on columns */
export interface ReportsAvgFields {
  __typename?: 'reports_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by avg() on columns of table "reports" */
export interface ReportsAvgOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export interface ReportsBoolExp {
  _and?: InputMaybe<Array<ReportsBoolExp>>;
  _not?: InputMaybe<ReportsBoolExp>;
  _or?: InputMaybe<Array<ReportsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by?: InputMaybe<UuidComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  recommendation?: InputMaybe<RecommendationsBoolExp>;
  recommendations?: InputMaybe<RecommendationsBoolExp>;
  recommendations_aggregate?: InputMaybe<RecommendationsAggregateBoolExp>;
  report_answers?: InputMaybe<ReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<ReportAnswersAggregateBoolExp>;
  report_chapters?: InputMaybe<ReportChaptersBoolExp>;
  report_chapters_aggregate?: InputMaybe<ReportChaptersAggregateBoolExp>;
  report_name?: InputMaybe<StringComparisonExp>;
  report_status?: InputMaybe<ReportStatusesBoolExp>;
  report_tasks?: InputMaybe<ReportTasksBoolExp>;
  report_tasks_aggregate?: InputMaybe<ReportTasksAggregateBoolExp>;
  status?: InputMaybe<ReportStatusesEnumComparisonExp>;
  total_priorities?: InputMaybe<BigintComparisonExp>;
  total_questions?: InputMaybe<IntComparisonExp>;
  total_tasks_done?: InputMaybe<BigintComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "reports" */
export enum ReportsConstraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

/** input type for incrementing numeric columns in table "reports" */
export interface ReportsIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "reports" */
export interface ReportsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  recommendation?: InputMaybe<RecommendationsObjRelInsertInput>;
  recommendations?: InputMaybe<RecommendationsArrRelInsertInput>;
  report_answers?: InputMaybe<ReportAnswersArrRelInsertInput>;
  report_chapters?: InputMaybe<ReportChaptersArrRelInsertInput>;
  report_name?: InputMaybe<Scalars['String']['input']>;
  report_status?: InputMaybe<ReportStatusesObjRelInsertInput>;
  report_tasks?: InputMaybe<ReportTasksArrRelInsertInput>;
  status?: InputMaybe<ReportStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface ReportsMaxFields {
  __typename?: 'reports_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_name?: Maybe<Scalars['String']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "reports" */
export interface ReportsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface ReportsMinFields {
  __typename?: 'reports_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_name?: Maybe<Scalars['String']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "reports" */
export interface ReportsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "reports" */
export interface ReportsMutationResponse {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
}

/** input type for inserting object relation for remote table "reports" */
export interface ReportsObjRelInsertInput {
  data: ReportsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ReportsOnConflict>;
}

/** on_conflict condition type for table "reports" */
export interface ReportsOnConflict {
  constraint: ReportsConstraint;
  update_columns?: Array<ReportsUpdateColumn>;
  where?: InputMaybe<ReportsBoolExp>;
}

/** Ordering options when selecting data from "reports". */
export interface ReportsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  created_by?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recommendation?: InputMaybe<RecommendationsOrderBy>;
  recommendations_aggregate?: InputMaybe<RecommendationsAggregateOrderBy>;
  report_answers_aggregate?: InputMaybe<ReportAnswersAggregateOrderBy>;
  report_chapters_aggregate?: InputMaybe<ReportChaptersAggregateOrderBy>;
  report_name?: InputMaybe<OrderBy>;
  report_status?: InputMaybe<ReportStatusesOrderBy>;
  report_tasks_aggregate?: InputMaybe<ReportTasksAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  total_priorities?: InputMaybe<OrderBy>;
  total_questions?: InputMaybe<OrderBy>;
  total_tasks_done?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: reports */
export interface ReportsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "reports" */
export enum ReportsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reports" */
export interface ReportsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReportStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface ReportsStddevFields {
  __typename?: 'reports_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by stddev() on columns of table "reports" */
export interface ReportsStddevOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ReportsStddevPopFields {
  __typename?: 'reports_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by stddev_pop() on columns of table "reports" */
export interface ReportsStddevPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ReportsStddevSampFields {
  __typename?: 'reports_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by stddev_samp() on columns of table "reports" */
export interface ReportsStddevSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "reports" */
export interface ReportsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ReportsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReportsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReportStatusesEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface ReportsSumFields {
  __typename?: 'reports_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by sum() on columns of table "reports" */
export interface ReportsSumOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** update columns of table "reports" */
export enum ReportsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface ReportsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportsSetInput>;
  /** filter the rows which have to be updated */
  where: ReportsBoolExp;
}

/** aggregate var_pop on columns */
export interface ReportsVarPopFields {
  __typename?: 'reports_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by var_pop() on columns of table "reports" */
export interface ReportsVarPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface ReportsVarSampFields {
  __typename?: 'reports_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by var_samp() on columns of table "reports" */
export interface ReportsVarSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface ReportsVarianceFields {
  __typename?: 'reports_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
  /** calculate the number of report task priority for a given priority */
  total_priorities?: Maybe<Scalars['bigint']['output']>;
  /** Calculate the total number of questions assigned to a report */
  total_questions?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "c_total_tasks_done" */
  total_tasks_done?: Maybe<Scalars['bigint']['output']>;
}

/** order by variance() on columns of table "reports" */
export interface ReportsVarianceOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** types of roadmap measures(tasks) */
export interface RoadmapMeasureType {
  __typename?: 'roadmap_measure_type';
  type: Scalars['String']['output'];
}

/** aggregated selection of "roadmap_measure_type" */
export interface RoadmapMeasureTypeAggregate {
  __typename?: 'roadmap_measure_type_aggregate';
  aggregate?: Maybe<RoadmapMeasureTypeAggregateFields>;
  nodes: Array<RoadmapMeasureType>;
}

/** aggregate fields of "roadmap_measure_type" */
export interface RoadmapMeasureTypeAggregateFields {
  __typename?: 'roadmap_measure_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RoadmapMeasureTypeMaxFields>;
  min?: Maybe<RoadmapMeasureTypeMinFields>;
}


/** aggregate fields of "roadmap_measure_type" */
export interface RoadmapMeasureTypeAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<RoadmapMeasureTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "roadmap_measure_type". All fields are combined with a logical 'AND'. */
export interface RoadmapMeasureTypeBoolExp {
  _and?: InputMaybe<Array<RoadmapMeasureTypeBoolExp>>;
  _not?: InputMaybe<RoadmapMeasureTypeBoolExp>;
  _or?: InputMaybe<Array<RoadmapMeasureTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "roadmap_measure_type" */
export enum RoadmapMeasureTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  RoadmapMeasureTypePkey = 'roadmap_measure_type_pkey'
}

export enum RoadmapMeasureTypeEnum {
  KapacitetiZbatues = 'KAPACITETI_ZBATUES',
  KuadriStrategjik = 'KUADRI_STRATEGJIK',
  Performance = 'PERFORMANCE'
}

/** Boolean expression to compare columns of type "roadmap_measure_type_enum". All fields are combined with logical 'AND'. */
export interface RoadmapMeasureTypeEnumComparisonExp {
  _eq?: InputMaybe<RoadmapMeasureTypeEnum>;
  _in?: InputMaybe<Array<RoadmapMeasureTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<RoadmapMeasureTypeEnum>;
  _nin?: InputMaybe<Array<RoadmapMeasureTypeEnum>>;
}

/** input type for inserting data into table "roadmap_measure_type" */
export interface RoadmapMeasureTypeInsertInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface RoadmapMeasureTypeMaxFields {
  __typename?: 'roadmap_measure_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface RoadmapMeasureTypeMinFields {
  __typename?: 'roadmap_measure_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "roadmap_measure_type" */
export interface RoadmapMeasureTypeMutationResponse {
  __typename?: 'roadmap_measure_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RoadmapMeasureType>;
}

/** on_conflict condition type for table "roadmap_measure_type" */
export interface RoadmapMeasureTypeOnConflict {
  constraint: RoadmapMeasureTypeConstraint;
  update_columns?: Array<RoadmapMeasureTypeUpdateColumn>;
  where?: InputMaybe<RoadmapMeasureTypeBoolExp>;
}

/** Ordering options when selecting data from "roadmap_measure_type". */
export interface RoadmapMeasureTypeOrderBy {
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: roadmap_measure_type */
export interface RoadmapMeasureTypePkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "roadmap_measure_type" */
export enum RoadmapMeasureTypeSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "roadmap_measure_type" */
export interface RoadmapMeasureTypeSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "roadmap_measure_type" */
export interface RoadmapMeasureTypeStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: RoadmapMeasureTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface RoadmapMeasureTypeStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "roadmap_measure_type" */
export enum RoadmapMeasureTypeUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface RoadmapMeasureTypeUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoadmapMeasureTypeSetInput>;
  /** filter the rows which have to be updated */
  where: RoadmapMeasureTypeBoolExp;
}

/** columns and relationships of "signed_documents" */
export interface SignedDocuments {
  __typename?: 'signed_documents';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  document?: Maybe<Documents>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  done_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  report_chapter?: Maybe<ReportChapters>;
  report_chapter_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  work_plan_task?: Maybe<PkieWorkPlanTask>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregated selection of "signed_documents" */
export interface SignedDocumentsAggregate {
  __typename?: 'signed_documents_aggregate';
  aggregate?: Maybe<SignedDocumentsAggregateFields>;
  nodes: Array<SignedDocuments>;
}

export interface SignedDocumentsAggregateBoolExp {
  count?: InputMaybe<SignedDocumentsAggregateBoolExpCount>;
}

export interface SignedDocumentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SignedDocumentsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "signed_documents" */
export interface SignedDocumentsAggregateFields {
  __typename?: 'signed_documents_aggregate_fields';
  avg?: Maybe<SignedDocumentsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SignedDocumentsMaxFields>;
  min?: Maybe<SignedDocumentsMinFields>;
  stddev?: Maybe<SignedDocumentsStddevFields>;
  stddev_pop?: Maybe<SignedDocumentsStddevPopFields>;
  stddev_samp?: Maybe<SignedDocumentsStddevSampFields>;
  sum?: Maybe<SignedDocumentsSumFields>;
  var_pop?: Maybe<SignedDocumentsVarPopFields>;
  var_samp?: Maybe<SignedDocumentsVarSampFields>;
  variance?: Maybe<SignedDocumentsVarianceFields>;
}


/** aggregate fields of "signed_documents" */
export interface SignedDocumentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "signed_documents" */
export interface SignedDocumentsAggregateOrderBy {
  avg?: InputMaybe<SignedDocumentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SignedDocumentsMaxOrderBy>;
  min?: InputMaybe<SignedDocumentsMinOrderBy>;
  stddev?: InputMaybe<SignedDocumentsStddevOrderBy>;
  stddev_pop?: InputMaybe<SignedDocumentsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SignedDocumentsStddevSampOrderBy>;
  sum?: InputMaybe<SignedDocumentsSumOrderBy>;
  var_pop?: InputMaybe<SignedDocumentsVarPopOrderBy>;
  var_samp?: InputMaybe<SignedDocumentsVarSampOrderBy>;
  variance?: InputMaybe<SignedDocumentsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "signed_documents" */
export interface SignedDocumentsArrRelInsertInput {
  data: Array<SignedDocumentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SignedDocumentsOnConflict>;
}

/** aggregate avg on columns */
export interface SignedDocumentsAvgFields {
  __typename?: 'signed_documents_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "signed_documents" */
export interface SignedDocumentsAvgOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "signed_documents". All fields are combined with a logical 'AND'. */
export interface SignedDocumentsBoolExp {
  _and?: InputMaybe<Array<SignedDocumentsBoolExp>>;
  _not?: InputMaybe<SignedDocumentsBoolExp>;
  _or?: InputMaybe<Array<SignedDocumentsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  dms_id?: InputMaybe<IntComparisonExp>;
  document?: InputMaybe<DocumentsBoolExp>;
  document_id?: InputMaybe<UuidComparisonExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  report_chapter?: InputMaybe<ReportChaptersBoolExp>;
  report_chapter_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskBoolExp>;
  work_plan_task_id?: InputMaybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "signed_documents" */
export enum SignedDocumentsConstraint {
  /** unique or primary key constraint on columns "id" */
  SignedDocumentsPkey = 'signed_documents_pkey'
}

/** input type for incrementing numeric columns in table "signed_documents" */
export interface SignedDocumentsIncInput {
  dms_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "signed_documents" */
export interface SignedDocumentsInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  document?: InputMaybe<DocumentsObjRelInsertInput>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_chapter?: InputMaybe<ReportChaptersObjRelInsertInput>;
  report_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskObjRelInsertInput>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface SignedDocumentsMaxFields {
  __typename?: 'signed_documents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_chapter_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "signed_documents" */
export interface SignedDocumentsMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  document_id?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_chapter_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface SignedDocumentsMinFields {
  __typename?: 'signed_documents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  dms_id?: Maybe<Scalars['Int']['output']>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_chapter_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_plan_task_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "signed_documents" */
export interface SignedDocumentsMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  document_id?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_chapter_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "signed_documents" */
export interface SignedDocumentsMutationResponse {
  __typename?: 'signed_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SignedDocuments>;
}

/** on_conflict condition type for table "signed_documents" */
export interface SignedDocumentsOnConflict {
  constraint: SignedDocumentsConstraint;
  update_columns?: Array<SignedDocumentsUpdateColumn>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}

/** Ordering options when selecting data from "signed_documents". */
export interface SignedDocumentsOrderBy {
  created_at?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  document?: InputMaybe<DocumentsOrderBy>;
  document_id?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  report_chapter?: InputMaybe<ReportChaptersOrderBy>;
  report_chapter_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  work_plan_task?: InputMaybe<PkieWorkPlanTaskOrderBy>;
  work_plan_task_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: signed_documents */
export interface SignedDocumentsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "signed_documents" */
export enum SignedDocumentsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportChapterId = 'report_chapter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

/** input type for updating data in table "signed_documents" */
export interface SignedDocumentsSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface SignedDocumentsStddevFields {
  __typename?: 'signed_documents_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "signed_documents" */
export interface SignedDocumentsStddevOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface SignedDocumentsStddevPopFields {
  __typename?: 'signed_documents_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "signed_documents" */
export interface SignedDocumentsStddevPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface SignedDocumentsStddevSampFields {
  __typename?: 'signed_documents_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "signed_documents" */
export interface SignedDocumentsStddevSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "signed_documents" */
export interface SignedDocumentsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: SignedDocumentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface SignedDocumentsStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dms_id?: InputMaybe<Scalars['Int']['input']>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_chapter_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_plan_task_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface SignedDocumentsSumFields {
  __typename?: 'signed_documents_sum_fields';
  dms_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "signed_documents" */
export interface SignedDocumentsSumOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** update columns of table "signed_documents" */
export enum SignedDocumentsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportChapterId = 'report_chapter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkPlanTaskId = 'work_plan_task_id'
}

export interface SignedDocumentsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SignedDocumentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignedDocumentsSetInput>;
  /** filter the rows which have to be updated */
  where: SignedDocumentsBoolExp;
}

/** aggregate var_pop on columns */
export interface SignedDocumentsVarPopFields {
  __typename?: 'signed_documents_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "signed_documents" */
export interface SignedDocumentsVarPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface SignedDocumentsVarSampFields {
  __typename?: 'signed_documents_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "signed_documents" */
export interface SignedDocumentsVarSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface SignedDocumentsVarianceFields {
  __typename?: 'signed_documents_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "signed_documents" */
export interface SignedDocumentsVarianceOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** columns and relationships of "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutions {
  __typename?: 'sub_chapter_lead_institutions';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  institution: Institutions;
  institution_id: Scalars['uuid']['output'];
  /** An object relationship */
  sub_chapter: SubChapters;
  sub_chapter_id: Scalars['Int']['output'];
}

/** aggregated selection of "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsAggregate {
  __typename?: 'sub_chapter_lead_institutions_aggregate';
  aggregate?: Maybe<SubChapterLeadInstitutionsAggregateFields>;
  nodes: Array<SubChapterLeadInstitutions>;
}

export interface SubChapterLeadInstitutionsAggregateBoolExp {
  count?: InputMaybe<SubChapterLeadInstitutionsAggregateBoolExpCount>;
}

export interface SubChapterLeadInstitutionsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsAggregateFields {
  __typename?: 'sub_chapter_lead_institutions_aggregate_fields';
  avg?: Maybe<SubChapterLeadInstitutionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SubChapterLeadInstitutionsMaxFields>;
  min?: Maybe<SubChapterLeadInstitutionsMinFields>;
  stddev?: Maybe<SubChapterLeadInstitutionsStddevFields>;
  stddev_pop?: Maybe<SubChapterLeadInstitutionsStddevPopFields>;
  stddev_samp?: Maybe<SubChapterLeadInstitutionsStddevSampFields>;
  sum?: Maybe<SubChapterLeadInstitutionsSumFields>;
  var_pop?: Maybe<SubChapterLeadInstitutionsVarPopFields>;
  var_samp?: Maybe<SubChapterLeadInstitutionsVarSampFields>;
  variance?: Maybe<SubChapterLeadInstitutionsVarianceFields>;
}


/** aggregate fields of "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsAggregateOrderBy {
  avg?: InputMaybe<SubChapterLeadInstitutionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SubChapterLeadInstitutionsMaxOrderBy>;
  min?: InputMaybe<SubChapterLeadInstitutionsMinOrderBy>;
  stddev?: InputMaybe<SubChapterLeadInstitutionsStddevOrderBy>;
  stddev_pop?: InputMaybe<SubChapterLeadInstitutionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SubChapterLeadInstitutionsStddevSampOrderBy>;
  sum?: InputMaybe<SubChapterLeadInstitutionsSumOrderBy>;
  var_pop?: InputMaybe<SubChapterLeadInstitutionsVarPopOrderBy>;
  var_samp?: InputMaybe<SubChapterLeadInstitutionsVarSampOrderBy>;
  variance?: InputMaybe<SubChapterLeadInstitutionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsArrRelInsertInput {
  data: Array<SubChapterLeadInstitutionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SubChapterLeadInstitutionsOnConflict>;
}

/** aggregate avg on columns */
export interface SubChapterLeadInstitutionsAvgFields {
  __typename?: 'sub_chapter_lead_institutions_avg_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsAvgOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "sub_chapter_lead_institutions". All fields are combined with a logical 'AND'. */
export interface SubChapterLeadInstitutionsBoolExp {
  _and?: InputMaybe<Array<SubChapterLeadInstitutionsBoolExp>>;
  _not?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
  _or?: InputMaybe<Array<SubChapterLeadInstitutionsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  institution?: InputMaybe<InstitutionsBoolExp>;
  institution_id?: InputMaybe<UuidComparisonExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  sub_chapter_id?: InputMaybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "sub_chapter_lead_institutions" */
export enum SubChapterLeadInstitutionsConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChapterLeadInstitutionsPkey = 'sub_chapter_lead_institutions_pkey'
}

/** input type for incrementing numeric columns in table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsIncInput {
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsInsertInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface SubChapterLeadInstitutionsMaxFields {
  __typename?: 'sub_chapter_lead_institutions_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsMaxOrderBy {
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface SubChapterLeadInstitutionsMinFields {
  __typename?: 'sub_chapter_lead_institutions_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsMinOrderBy {
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsMutationResponse {
  __typename?: 'sub_chapter_lead_institutions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SubChapterLeadInstitutions>;
}

/** on_conflict condition type for table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsOnConflict {
  constraint: SubChapterLeadInstitutionsConstraint;
  update_columns?: Array<SubChapterLeadInstitutionsUpdateColumn>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}

/** Ordering options when selecting data from "sub_chapter_lead_institutions". */
export interface SubChapterLeadInstitutionsOrderBy {
  id?: InputMaybe<OrderBy>;
  institution?: InputMaybe<InstitutionsOrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: sub_chapter_lead_institutions */
export interface SubChapterLeadInstitutionsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "sub_chapter_lead_institutions" */
export enum SubChapterLeadInstitutionsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

/** input type for updating data in table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsSetInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface SubChapterLeadInstitutionsStddevFields {
  __typename?: 'sub_chapter_lead_institutions_stddev_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsStddevOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface SubChapterLeadInstitutionsStddevPopFields {
  __typename?: 'sub_chapter_lead_institutions_stddev_pop_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsStddevPopOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface SubChapterLeadInstitutionsStddevSampFields {
  __typename?: 'sub_chapter_lead_institutions_stddev_samp_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsStddevSampOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: SubChapterLeadInstitutionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface SubChapterLeadInstitutionsStreamCursorValueInput {
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  sub_chapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface SubChapterLeadInstitutionsSumFields {
  __typename?: 'sub_chapter_lead_institutions_sum_fields';
  sub_chapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsSumOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** update columns of table "sub_chapter_lead_institutions" */
export enum SubChapterLeadInstitutionsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  SubChapterId = 'sub_chapter_id'
}

export interface SubChapterLeadInstitutionsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SubChapterLeadInstitutionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SubChapterLeadInstitutionsSetInput>;
  /** filter the rows which have to be updated */
  where: SubChapterLeadInstitutionsBoolExp;
}

/** aggregate var_pop on columns */
export interface SubChapterLeadInstitutionsVarPopFields {
  __typename?: 'sub_chapter_lead_institutions_var_pop_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsVarPopOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface SubChapterLeadInstitutionsVarSampFields {
  __typename?: 'sub_chapter_lead_institutions_var_samp_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsVarSampOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface SubChapterLeadInstitutionsVarianceFields {
  __typename?: 'sub_chapter_lead_institutions_variance_fields';
  sub_chapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "sub_chapter_lead_institutions" */
export interface SubChapterLeadInstitutionsVarianceOrderBy {
  sub_chapter_id?: InputMaybe<OrderBy>;
}

/** contains eu subchapters for each chapter */
export interface SubChapters {
  __typename?: 'sub_chapters';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  bookmarks: Array<Bookmarks>;
  /** An aggregate relationship */
  bookmarks_aggregate: BookmarksAggregate;
  /** An object relationship */
  chapter: Chapters;
  chapter_id: Scalars['Int']['output'];
  /** An array relationship */
  gnpies: Array<Gnpie>;
  /** An aggregate relationship */
  gnpies_aggregate: GnpieAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: QuestionsAggregate;
  /** An array relationship */
  sub_chapter_lead_institutions: Array<SubChapterLeadInstitutions>;
  /** An aggregate relationship */
  sub_chapter_lead_institutions_aggregate: SubChapterLeadInstitutionsAggregate;
  sub_chapter_number?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  topics: Array<Topics>;
  /** An aggregate relationship */
  topics_aggregate: TopicsAggregate;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersBookmarksArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersGnpiesArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersGnpiesAggregateArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersQuestionsArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersSubChapterLeadInstitutionsArgs {
  distinct_on?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersSubChapterLeadInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersTopicsArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


/** contains eu subchapters for each chapter */
export interface SubChaptersTopicsAggregateArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}

/** aggregated selection of "sub_chapters" */
export interface SubChaptersAggregate {
  __typename?: 'sub_chapters_aggregate';
  aggregate?: Maybe<SubChaptersAggregateFields>;
  nodes: Array<SubChapters>;
}

export interface SubChaptersAggregateBoolExp {
  bool_and?: InputMaybe<SubChaptersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SubChaptersAggregateBoolExpBoolOr>;
  count?: InputMaybe<SubChaptersAggregateBoolExpCount>;
}

export interface SubChaptersAggregateBoolExpBoolAnd {
  arguments: SubChaptersSelectColumnSubChaptersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SubChaptersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface SubChaptersAggregateBoolExpBoolOr {
  arguments: SubChaptersSelectColumnSubChaptersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SubChaptersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface SubChaptersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<SubChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SubChaptersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "sub_chapters" */
export interface SubChaptersAggregateFields {
  __typename?: 'sub_chapters_aggregate_fields';
  avg?: Maybe<SubChaptersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SubChaptersMaxFields>;
  min?: Maybe<SubChaptersMinFields>;
  stddev?: Maybe<SubChaptersStddevFields>;
  stddev_pop?: Maybe<SubChaptersStddevPopFields>;
  stddev_samp?: Maybe<SubChaptersStddevSampFields>;
  sum?: Maybe<SubChaptersSumFields>;
  var_pop?: Maybe<SubChaptersVarPopFields>;
  var_samp?: Maybe<SubChaptersVarSampFields>;
  variance?: Maybe<SubChaptersVarianceFields>;
}


/** aggregate fields of "sub_chapters" */
export interface SubChaptersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<SubChaptersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "sub_chapters" */
export interface SubChaptersAggregateOrderBy {
  avg?: InputMaybe<SubChaptersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SubChaptersMaxOrderBy>;
  min?: InputMaybe<SubChaptersMinOrderBy>;
  stddev?: InputMaybe<SubChaptersStddevOrderBy>;
  stddev_pop?: InputMaybe<SubChaptersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SubChaptersStddevSampOrderBy>;
  sum?: InputMaybe<SubChaptersSumOrderBy>;
  var_pop?: InputMaybe<SubChaptersVarPopOrderBy>;
  var_samp?: InputMaybe<SubChaptersVarSampOrderBy>;
  variance?: InputMaybe<SubChaptersVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "sub_chapters" */
export interface SubChaptersArrRelInsertInput {
  data: Array<SubChaptersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SubChaptersOnConflict>;
}

/** aggregate avg on columns */
export interface SubChaptersAvgFields {
  __typename?: 'sub_chapters_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "sub_chapters" */
export interface SubChaptersAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "sub_chapters". All fields are combined with a logical 'AND'. */
export interface SubChaptersBoolExp {
  _and?: InputMaybe<Array<SubChaptersBoolExp>>;
  _not?: InputMaybe<SubChaptersBoolExp>;
  _or?: InputMaybe<Array<SubChaptersBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  bookmarks?: InputMaybe<BookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateBoolExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  gnpies?: InputMaybe<GnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  questions?: InputMaybe<QuestionsBoolExp>;
  questions_aggregate?: InputMaybe<QuestionsAggregateBoolExp>;
  sub_chapter_lead_institutions?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
  sub_chapter_lead_institutions_aggregate?: InputMaybe<SubChapterLeadInstitutionsAggregateBoolExp>;
  sub_chapter_number?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  title_en?: InputMaybe<StringComparisonExp>;
  topics?: InputMaybe<TopicsBoolExp>;
  topics_aggregate?: InputMaybe<TopicsAggregateBoolExp>;
}

/** unique or primary key constraints on table "sub_chapters" */
export enum SubChaptersConstraint {
  /** unique or primary key constraint on columns "id" */
  SubChaptersPkey = 'sub_chapters_pkey',
  /** unique or primary key constraint on columns "sub_chapter_number" */
  SubChaptersSubChapterNumberKey = 'sub_chapters_sub_chapter_number_key'
}

/** input type for incrementing numeric columns in table "sub_chapters" */
export interface SubChaptersIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "sub_chapters" */
export interface SubChaptersInsertInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bookmarks?: InputMaybe<BookmarksArrRelInsertInput>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<GnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<QuestionsArrRelInsertInput>;
  sub_chapter_lead_institutions?: InputMaybe<SubChapterLeadInstitutionsArrRelInsertInput>;
  sub_chapter_number?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<TopicsArrRelInsertInput>;
}

/** aggregate max on columns */
export interface SubChaptersMaxFields {
  __typename?: 'sub_chapters_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_number?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "sub_chapters" */
export interface SubChaptersMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_number?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface SubChaptersMinFields {
  __typename?: 'sub_chapters_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sub_chapter_number?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "sub_chapters" */
export interface SubChaptersMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter_number?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "sub_chapters" */
export interface SubChaptersMutationResponse {
  __typename?: 'sub_chapters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SubChapters>;
}

/** input type for inserting object relation for remote table "sub_chapters" */
export interface SubChaptersObjRelInsertInput {
  data: SubChaptersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SubChaptersOnConflict>;
}

/** on_conflict condition type for table "sub_chapters" */
export interface SubChaptersOnConflict {
  constraint: SubChaptersConstraint;
  update_columns?: Array<SubChaptersUpdateColumn>;
  where?: InputMaybe<SubChaptersBoolExp>;
}

/** Ordering options when selecting data from "sub_chapters". */
export interface SubChaptersOrderBy {
  active?: InputMaybe<OrderBy>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateOrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  questions_aggregate?: InputMaybe<QuestionsAggregateOrderBy>;
  sub_chapter_lead_institutions_aggregate?: InputMaybe<SubChapterLeadInstitutionsAggregateOrderBy>;
  sub_chapter_number?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
  topics_aggregate?: InputMaybe<TopicsAggregateOrderBy>;
}

/** primary key columns input for table: sub_chapters */
export interface SubChaptersPkColumnsInput {
  id: Scalars['Int']['input'];
}

/** select columns of table "sub_chapters" */
export enum SubChaptersSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

/** select "sub_chapters_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sub_chapters" */
export enum SubChaptersSelectColumnSubChaptersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** select "sub_chapters_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sub_chapters" */
export enum SubChaptersSelectColumnSubChaptersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "sub_chapters" */
export interface SubChaptersSetInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_number?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface SubChaptersStddevFields {
  __typename?: 'sub_chapters_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "sub_chapters" */
export interface SubChaptersStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface SubChaptersStddevPopFields {
  __typename?: 'sub_chapters_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "sub_chapters" */
export interface SubChaptersStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface SubChaptersStddevSampFields {
  __typename?: 'sub_chapters_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "sub_chapters" */
export interface SubChaptersStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "sub_chapters" */
export interface SubChaptersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: SubChaptersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface SubChaptersStreamCursorValueInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter_number?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface SubChaptersSumFields {
  __typename?: 'sub_chapters_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "sub_chapters" */
export interface SubChaptersSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** update columns of table "sub_chapters" */
export enum SubChaptersUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubChapterNumber = 'sub_chapter_number',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export interface SubChaptersUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SubChaptersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SubChaptersSetInput>;
  /** filter the rows which have to be updated */
  where: SubChaptersBoolExp;
}

/** aggregate var_pop on columns */
export interface SubChaptersVarPopFields {
  __typename?: 'sub_chapters_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "sub_chapters" */
export interface SubChaptersVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface SubChaptersVarSampFields {
  __typename?: 'sub_chapters_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "sub_chapters" */
export interface SubChaptersVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface SubChaptersVarianceFields {
  __typename?: 'sub_chapters_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "sub_chapters" */
export interface SubChaptersVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
}

export interface SubscriptionRoot {
  __typename?: 'subscription_root';
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequestsAggregate;
  /** fetch data from the table in a streaming manner: "auth.provider_requests" */
  authProviderRequests_stream: Array<AuthProviderRequests>;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProvidersAggregate;
  /** fetch data from the table in a streaming manner: "auth.providers" */
  authProviders_stream: Array<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypesAggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
  authRefreshTokenTypes_stream: Array<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokensAggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRolesAggregate;
  /** fetch data from the table in a streaming manner: "auth.roles" */
  authRoles_stream: Array<AuthRoles>;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProvidersAggregate;
  /** fetch data from the table in a streaming manner: "auth.user_providers" */
  authUserProviders_stream: Array<AuthUserProviders>;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRolesAggregate;
  /** fetch data from the table in a streaming manner: "auth.user_roles" */
  authUserRoles_stream: Array<AuthUserRoles>;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeysAggregate;
  /** fetch data from the table in a streaming manner: "auth.user_security_keys" */
  authUserSecurityKeys_stream: Array<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.modules" */
  auth_modules: Array<AuthModules>;
  /** fetch aggregated fields from the table: "auth.modules" */
  auth_modules_aggregate: AuthModulesAggregate;
  /** fetch data from the table: "auth.modules" using primary key columns */
  auth_modules_by_pk?: Maybe<AuthModules>;
  /** fetch data from the table in a streaming manner: "auth.modules" */
  auth_modules_stream: Array<AuthModules>;
  /** fetch data from the table: "bookmark_gnpie_members" */
  bookmark_gnpie_members: Array<BookmarkGnpieMembers>;
  /** fetch aggregated fields from the table: "bookmark_gnpie_members" */
  bookmark_gnpie_members_aggregate: BookmarkGnpieMembersAggregate;
  /** fetch data from the table: "bookmark_gnpie_members" using primary key columns */
  bookmark_gnpie_members_by_pk?: Maybe<BookmarkGnpieMembers>;
  /** fetch data from the table in a streaming manner: "bookmark_gnpie_members" */
  bookmark_gnpie_members_stream: Array<BookmarkGnpieMembers>;
  /** fetch data from the table: "bookmark_status" */
  bookmark_status: Array<BookmarkStatus>;
  /** fetch aggregated fields from the table: "bookmark_status" */
  bookmark_status_aggregate: BookmarkStatusAggregate;
  /** fetch data from the table: "bookmark_status" using primary key columns */
  bookmark_status_by_pk?: Maybe<BookmarkStatus>;
  /** fetch data from the table in a streaming manner: "bookmark_status" */
  bookmark_status_stream: Array<BookmarkStatus>;
  /** fetch data from the table: "bookmark_task_activity" */
  bookmark_task_activity: Array<BookmarkTaskActivity>;
  /** fetch aggregated fields from the table: "bookmark_task_activity" */
  bookmark_task_activity_aggregate: BookmarkTaskActivityAggregate;
  /** fetch data from the table: "bookmark_task_activity" using primary key columns */
  bookmark_task_activity_by_pk?: Maybe<BookmarkTaskActivity>;
  /** fetch data from the table in a streaming manner: "bookmark_task_activity" */
  bookmark_task_activity_stream: Array<BookmarkTaskActivity>;
  /** An array relationship */
  bookmark_task_answers: Array<BookmarkTaskAnswers>;
  /** An aggregate relationship */
  bookmark_task_answers_aggregate: BookmarkTaskAnswersAggregate;
  /** fetch data from the table: "bookmark_task_answers" using primary key columns */
  bookmark_task_answers_by_pk?: Maybe<BookmarkTaskAnswers>;
  /** fetch data from the table in a streaming manner: "bookmark_task_answers" */
  bookmark_task_answers_stream: Array<BookmarkTaskAnswers>;
  /** fetch data from the table: "bookmark_task_comment" */
  bookmark_task_comment: Array<BookmarkTaskComment>;
  /** fetch aggregated fields from the table: "bookmark_task_comment" */
  bookmark_task_comment_aggregate: BookmarkTaskCommentAggregate;
  /** fetch data from the table: "bookmark_task_comment" using primary key columns */
  bookmark_task_comment_by_pk?: Maybe<BookmarkTaskComment>;
  /** fetch data from the table in a streaming manner: "bookmark_task_comment" */
  bookmark_task_comment_stream: Array<BookmarkTaskComment>;
  /** fetch data from the table: "bookmark_task_measures" */
  bookmark_task_measures: Array<BookmarkTaskMeasures>;
  /** fetch aggregated fields from the table: "bookmark_task_measures" */
  bookmark_task_measures_aggregate: BookmarkTaskMeasuresAggregate;
  /** fetch data from the table: "bookmark_task_measures" using primary key columns */
  bookmark_task_measures_by_pk?: Maybe<BookmarkTaskMeasures>;
  /** fetch data from the table in a streaming manner: "bookmark_task_measures" */
  bookmark_task_measures_stream: Array<BookmarkTaskMeasures>;
  /** fetch data from the table: "bookmark_task_statuses" */
  bookmark_task_statuses: Array<BookmarkTaskStatuses>;
  /** fetch aggregated fields from the table: "bookmark_task_statuses" */
  bookmark_task_statuses_aggregate: BookmarkTaskStatusesAggregate;
  /** fetch data from the table: "bookmark_task_statuses" using primary key columns */
  bookmark_task_statuses_by_pk?: Maybe<BookmarkTaskStatuses>;
  /** fetch data from the table in a streaming manner: "bookmark_task_statuses" */
  bookmark_task_statuses_stream: Array<BookmarkTaskStatuses>;
  /** An array relationship */
  bookmark_tasks: Array<BookmarkTasks>;
  /** An aggregate relationship */
  bookmark_tasks_aggregate: BookmarkTasksAggregate;
  /** fetch data from the table: "bookmark_tasks" using primary key columns */
  bookmark_tasks_by_pk?: Maybe<BookmarkTasks>;
  /** fetch data from the table in a streaming manner: "bookmark_tasks" */
  bookmark_tasks_stream: Array<BookmarkTasks>;
  /** An array relationship */
  bookmarks: Array<Bookmarks>;
  /** An aggregate relationship */
  bookmarks_aggregate: BookmarksAggregate;
  /** fetch data from the table: "bookmarks" using primary key columns */
  bookmarks_by_pk?: Maybe<Bookmarks>;
  /** fetch data from the table in a streaming manner: "bookmarks" */
  bookmarks_stream: Array<Bookmarks>;
  calendarTasksOfChiefSecretariat: Array<MemberCalendar>;
  calendarTasksOfCoordinator: Array<NegotiatorCalendar>;
  calendarTasksOfHorizontalCoordinator: Array<NegotiatorCalendar>;
  calendarTasksOfMember: Array<MemberCalendar>;
  calendarTasksOfNegotiator: Array<NegotiatorCalendar>;
  calendarTasksOfPMO: Array<PmoCalendar>;
  calendarTasksOfSecretariat: Array<MemberCalendar>;
  /** fetch data from the table: "capac.beneficiaries" */
  capac_beneficiaries: Array<CapacBeneficiaries>;
  /** fetch aggregated fields from the table: "capac.beneficiaries" */
  capac_beneficiaries_aggregate: CapacBeneficiariesAggregate;
  /** fetch data from the table: "capac.beneficiaries" using primary key columns */
  capac_beneficiaries_by_pk?: Maybe<CapacBeneficiaries>;
  /** fetch data from the table in a streaming manner: "capac.beneficiaries" */
  capac_beneficiaries_stream: Array<CapacBeneficiaries>;
  /** fetch data from the table: "capac.chapter_donator" */
  capac_chapter_donator: Array<CapacChapterDonator>;
  /** fetch aggregated fields from the table: "capac.chapter_donator" */
  capac_chapter_donator_aggregate: CapacChapterDonatorAggregate;
  /** fetch data from the table: "capac.chapter_donator" using primary key columns */
  capac_chapter_donator_by_pk?: Maybe<CapacChapterDonator>;
  /** fetch data from the table in a streaming manner: "capac.chapter_donator" */
  capac_chapter_donator_stream: Array<CapacChapterDonator>;
  /** fetch data from the table: "capac.donators" */
  capac_donators: Array<CapacDonators>;
  /** fetch aggregated fields from the table: "capac.donators" */
  capac_donators_aggregate: CapacDonatorsAggregate;
  /** fetch data from the table: "capac.donators" using primary key columns */
  capac_donators_by_pk?: Maybe<CapacDonators>;
  /** fetch data from the table in a streaming manner: "capac.donators" */
  capac_donators_stream: Array<CapacDonators>;
  /** fetch data from the table: "capac.experts" */
  capac_experts: Array<CapacExperts>;
  /** fetch aggregated fields from the table: "capac.experts" */
  capac_experts_aggregate: CapacExpertsAggregate;
  /** fetch data from the table: "capac.experts" using primary key columns */
  capac_experts_by_pk?: Maybe<CapacExperts>;
  /** fetch data from the table in a streaming manner: "capac.experts" */
  capac_experts_stream: Array<CapacExperts>;
  /** fetch data from the table: "capac.integration_steps" */
  capac_integration_steps: Array<CapacIntegrationSteps>;
  /** fetch aggregated fields from the table: "capac.integration_steps" */
  capac_integration_steps_aggregate: CapacIntegrationStepsAggregate;
  /** fetch data from the table: "capac.integration_steps" using primary key columns */
  capac_integration_steps_by_pk?: Maybe<CapacIntegrationSteps>;
  /** fetch data from the table in a streaming manner: "capac.integration_steps" */
  capac_integration_steps_stream: Array<CapacIntegrationSteps>;
  /** fetch data from the table: "capac.priority_lists" */
  capac_priority_lists: Array<CapacPriorityLists>;
  /** fetch aggregated fields from the table: "capac.priority_lists" */
  capac_priority_lists_aggregate: CapacPriorityListsAggregate;
  /** fetch data from the table: "capac.priority_lists" using primary key columns */
  capac_priority_lists_by_pk?: Maybe<CapacPriorityLists>;
  /** fetch data from the table in a streaming manner: "capac.priority_lists" */
  capac_priority_lists_stream: Array<CapacPriorityLists>;
  /** fetch data from the table: "capac.project_beneficiaries" */
  capac_project_beneficiaries: Array<CapacProjectBeneficiaries>;
  /** fetch aggregated fields from the table: "capac.project_beneficiaries" */
  capac_project_beneficiaries_aggregate: CapacProjectBeneficiariesAggregate;
  /** fetch data from the table: "capac.project_beneficiaries" using primary key columns */
  capac_project_beneficiaries_by_pk?: Maybe<CapacProjectBeneficiaries>;
  /** fetch data from the table in a streaming manner: "capac.project_beneficiaries" */
  capac_project_beneficiaries_stream: Array<CapacProjectBeneficiaries>;
  /** fetch data from the table: "capac.projects" */
  capac_projects: Array<CapacProjects>;
  /** fetch aggregated fields from the table: "capac.projects" */
  capac_projects_aggregate: CapacProjectsAggregate;
  /** fetch data from the table: "capac.projects" using primary key columns */
  capac_projects_by_pk?: Maybe<CapacProjects>;
  /** fetch data from the table in a streaming manner: "capac.projects" */
  capac_projects_stream: Array<CapacProjects>;
  /** fetch data from the table: "capac.taiex" */
  capac_taiex: Array<CapacTaiex>;
  /** fetch aggregated fields from the table: "capac.taiex" */
  capac_taiex_aggregate: CapacTaiexAggregate;
  /** fetch data from the table: "capac.taiex" using primary key columns */
  capac_taiex_by_pk?: Maybe<CapacTaiex>;
  /** fetch data from the table in a streaming manner: "capac.taiex" */
  capac_taiex_stream: Array<CapacTaiex>;
  /** fetch data from the table: "capac.training_needs" */
  capac_training_needs: Array<CapacTrainingNeeds>;
  /** fetch aggregated fields from the table: "capac.training_needs" */
  capac_training_needs_aggregate: CapacTrainingNeedsAggregate;
  /** fetch data from the table: "capac.training_needs" using primary key columns */
  capac_training_needs_by_pk?: Maybe<CapacTrainingNeeds>;
  /** fetch data from the table in a streaming manner: "capac.training_needs" */
  capac_training_needs_stream: Array<CapacTrainingNeeds>;
  /** fetch data from the table: "capac.training_participants" */
  capac_training_participants: Array<CapacTrainingParticipants>;
  /** fetch aggregated fields from the table: "capac.training_participants" */
  capac_training_participants_aggregate: CapacTrainingParticipantsAggregate;
  /** fetch data from the table: "capac.training_participants" using primary key columns */
  capac_training_participants_by_pk?: Maybe<CapacTrainingParticipants>;
  /** fetch data from the table in a streaming manner: "capac.training_participants" */
  capac_training_participants_stream: Array<CapacTrainingParticipants>;
  /** fetch data from the table: "capac.training_sessions" */
  capac_training_sessions: Array<CapacTrainingSessions>;
  /** fetch aggregated fields from the table: "capac.training_sessions" */
  capac_training_sessions_aggregate: CapacTrainingSessionsAggregate;
  /** fetch data from the table: "capac.training_sessions" using primary key columns */
  capac_training_sessions_by_pk?: Maybe<CapacTrainingSessions>;
  /** fetch data from the table in a streaming manner: "capac.training_sessions" */
  capac_training_sessions_stream: Array<CapacTrainingSessions>;
  /** fetch data from the table: "capac.trainings" */
  capac_trainings: Array<CapacTrainings>;
  /** fetch aggregated fields from the table: "capac.trainings" */
  capac_trainings_aggregate: CapacTrainingsAggregate;
  /** fetch data from the table: "capac.trainings" using primary key columns */
  capac_trainings_by_pk?: Maybe<CapacTrainings>;
  /** fetch data from the table: "capac.trainings_experts" */
  capac_trainings_experts: Array<CapacTrainingsExperts>;
  /** fetch aggregated fields from the table: "capac.trainings_experts" */
  capac_trainings_experts_aggregate: CapacTrainingsExpertsAggregate;
  /** fetch data from the table: "capac.trainings_experts" using primary key columns */
  capac_trainings_experts_by_pk?: Maybe<CapacTrainingsExperts>;
  /** fetch data from the table in a streaming manner: "capac.trainings_experts" */
  capac_trainings_experts_stream: Array<CapacTrainingsExperts>;
  /** fetch data from the table in a streaming manner: "capac.trainings" */
  capac_trainings_stream: Array<CapacTrainings>;
  /** fetch data from the table: "capac.twinning" */
  capac_twinning: Array<CapacTwinning>;
  /** fetch aggregated fields from the table: "capac.twinning" */
  capac_twinning_aggregate: CapacTwinningAggregate;
  /** fetch data from the table: "capac.twinning" using primary key columns */
  capac_twinning_by_pk?: Maybe<CapacTwinning>;
  /** fetch data from the table in a streaming manner: "capac.twinning" */
  capac_twinning_stream: Array<CapacTwinning>;
  /** An array relationship */
  chapter_lead_institutions: Array<ChapterLeadInstitutions>;
  /** An aggregate relationship */
  chapter_lead_institutions_aggregate: ChapterLeadInstitutionsAggregate;
  /** fetch data from the table: "chapter_lead_institutions" using primary key columns */
  chapter_lead_institutions_by_pk?: Maybe<ChapterLeadInstitutions>;
  /** fetch data from the table in a streaming manner: "chapter_lead_institutions" */
  chapter_lead_institutions_stream: Array<ChapterLeadInstitutions>;
  /** An array relationship */
  chapters: Array<Chapters>;
  /** An aggregate relationship */
  chapters_aggregate: ChaptersAggregate;
  /** fetch data from the table: "chapters" using primary key columns */
  chapters_by_pk?: Maybe<Chapters>;
  /** fetch data from the table in a streaming manner: "chapters" */
  chapters_stream: Array<Chapters>;
  /** fetch data from the table: "clusters" */
  clusters: Array<Clusters>;
  /** fetch aggregated fields from the table: "clusters" */
  clusters_aggregate: ClustersAggregate;
  /** fetch data from the table: "clusters" using primary key columns */
  clusters_by_pk?: Maybe<Clusters>;
  /** fetch data from the table in a streaming manner: "clusters" */
  clusters_stream: Array<Clusters>;
  dashboardStatistics: Array<DashboardStatisticsEntity>;
  dashboardStatisticsAggregate: Array<DashboardAggregateEntity>;
  /** fetch data from the table: "dms.folder" */
  dms_folder: Array<DmsFolder>;
  /** fetch aggregated fields from the table: "dms.folder" */
  dms_folder_aggregate: DmsFolderAggregate;
  /** fetch data from the table: "dms.folder" using primary key columns */
  dms_folder_by_pk?: Maybe<DmsFolder>;
  /** fetch data from the table in a streaming manner: "dms.folder" */
  dms_folder_stream: Array<DmsFolder>;
  /** fetch data from the table: "dms.folder_type" */
  dms_folder_type: Array<DmsFolderType>;
  /** fetch aggregated fields from the table: "dms.folder_type" */
  dms_folder_type_aggregate: DmsFolderTypeAggregate;
  /** fetch data from the table: "dms.folder_type" using primary key columns */
  dms_folder_type_by_pk?: Maybe<DmsFolderType>;
  /** fetch data from the table in a streaming manner: "dms.folder_type" */
  dms_folder_type_stream: Array<DmsFolderType>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: DocumentsAggregate;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table in a streaming manner: "documents" */
  documents_stream: Array<Documents>;
  /** fetch data from the table: "error_logs" */
  error_logs: Array<ErrorLogs>;
  /** fetch aggregated fields from the table: "error_logs" */
  error_logs_aggregate: ErrorLogsAggregate;
  /** fetch data from the table: "error_logs" using primary key columns */
  error_logs_by_pk?: Maybe<ErrorLogs>;
  /** fetch data from the table in a streaming manner: "error_logs" */
  error_logs_stream: Array<ErrorLogs>;
  /** An array relationship */
  event_notes: Array<EventNotes>;
  /** An aggregate relationship */
  event_notes_aggregate: EventNotesAggregate;
  /** fetch data from the table: "event_notes" using primary key columns */
  event_notes_by_pk?: Maybe<EventNotes>;
  /** fetch data from the table in a streaming manner: "event_notes" */
  event_notes_stream: Array<EventNotes>;
  /** fetch data from the table: "event_typology" */
  event_typology: Array<EventTypology>;
  /** fetch aggregated fields from the table: "event_typology" */
  event_typology_aggregate: EventTypologyAggregate;
  /** fetch data from the table: "event_typology" using primary key columns */
  event_typology_by_pk?: Maybe<EventTypology>;
  /** fetch data from the table in a streaming manner: "event_typology" */
  event_typology_stream: Array<EventTypology>;
  /** An array relationship */
  event_users: Array<EventUsers>;
  /** An aggregate relationship */
  event_users_aggregate: EventUsersAggregate;
  /** fetch data from the table: "event_users" using primary key columns */
  event_users_by_pk?: Maybe<EventUsers>;
  /** fetch data from the table in a streaming manner: "event_users" */
  event_users_stream: Array<EventUsers>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: EventsAggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<Events>;
  getFolderPath: Array<FolderPathModel>;
  /** fetch data from the table: "gnpie" */
  gnpie: Array<Gnpie>;
  gnpieUsersByChapter: Array<GnpieUser>;
  /** fetch aggregated fields from the table: "gnpie" */
  gnpie_aggregate: GnpieAggregate;
  /** fetch data from the table: "gnpie" using primary key columns */
  gnpie_by_pk?: Maybe<Gnpie>;
  /** fetch data from the table in a streaming manner: "gnpie" */
  gnpie_stream: Array<Gnpie>;
  /** An array relationship */
  institutions: Array<Institutions>;
  /** An aggregate relationship */
  institutions_aggregate: InstitutionsAggregate;
  /** fetch data from the table: "institutions" using primary key columns */
  institutions_by_pk?: Maybe<Institutions>;
  /** fetch data from the table in a streaming manner: "institutions" */
  institutions_stream: Array<Institutions>;
  integrationPhaseProgres: Array<IntegrationPhaseProgres>;
  /** fetch data from the table: "integration_phases" */
  integration_phases: Array<IntegrationPhases>;
  /** fetch aggregated fields from the table: "integration_phases" */
  integration_phases_aggregate: IntegrationPhasesAggregate;
  /** fetch data from the table: "integration_phases" using primary key columns */
  integration_phases_by_pk?: Maybe<IntegrationPhases>;
  /** fetch data from the table: "integration_phases_enum" */
  integration_phases_enum: Array<IntegrationPhasesEnum>;
  /** fetch aggregated fields from the table: "integration_phases_enum" */
  integration_phases_enum_aggregate: IntegrationPhasesEnumAggregate;
  /** fetch data from the table: "integration_phases_enum" using primary key columns */
  integration_phases_enum_by_pk?: Maybe<IntegrationPhasesEnum>;
  /** fetch data from the table in a streaming manner: "integration_phases_enum" */
  integration_phases_enum_stream: Array<IntegrationPhasesEnum>;
  /** fetch data from the table in a streaming manner: "integration_phases" */
  integration_phases_stream: Array<IntegrationPhases>;
  /** fetch data from the table: "meeting_attendance" */
  meeting_attendance: Array<MeetingAttendance>;
  /** fetch aggregated fields from the table: "meeting_attendance" */
  meeting_attendance_aggregate: MeetingAttendanceAggregate;
  /** fetch data from the table: "meeting_attendance" using primary key columns */
  meeting_attendance_by_pk?: Maybe<MeetingAttendance>;
  /** fetch data from the table in a streaming manner: "meeting_attendance" */
  meeting_attendance_stream: Array<MeetingAttendance>;
  /** An array relationship */
  meeting_notes: Array<MeetingNotes>;
  /** An aggregate relationship */
  meeting_notes_aggregate: MeetingNotesAggregate;
  /** fetch data from the table: "meeting_notes" using primary key columns */
  meeting_notes_by_pk?: Maybe<MeetingNotes>;
  /** fetch data from the table in a streaming manner: "meeting_notes" */
  meeting_notes_stream: Array<MeetingNotes>;
  /** An array relationship */
  meeting_users: Array<MeetingUsers>;
  /** An aggregate relationship */
  meeting_users_aggregate: MeetingUsersAggregate;
  /** fetch data from the table: "meeting_users" using primary key columns */
  meeting_users_by_pk?: Maybe<MeetingUsers>;
  /** fetch data from the table in a streaming manner: "meeting_users" */
  meeting_users_stream: Array<MeetingUsers>;
  /** An array relationship */
  meetings: Array<Meetings>;
  /** An aggregate relationship */
  meetings_aggregate: MeetingsAggregate;
  /** fetch data from the table: "meetings" using primary key columns */
  meetings_by_pk?: Maybe<Meetings>;
  /** fetch data from the table in a streaming manner: "meetings" */
  meetings_stream: Array<Meetings>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: NotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_origin" */
  notification_origin: Array<NotificationOrigin>;
  /** fetch aggregated fields from the table: "notification_origin" */
  notification_origin_aggregate: NotificationOriginAggregate;
  /** fetch data from the table: "notification_origin" using primary key columns */
  notification_origin_by_pk?: Maybe<NotificationOrigin>;
  /** fetch data from the table in a streaming manner: "notification_origin" */
  notification_origin_stream: Array<NotificationOrigin>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<NotificationType>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: NotificationTypeAggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<NotificationType>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<NotificationType>;
  nrOfInstitutionsPerChapter: Array<NrOfInstitutionsPerChapter>;
  nrOfUsersPerInstitution: Array<NrOfInstitutionsPerChapter>;
  /** fetch data from the table: "pkie.acquis" */
  pkie_acquis: Array<PkieAcquis>;
  /** fetch aggregated fields from the table: "pkie.acquis" */
  pkie_acquis_aggregate: PkieAcquisAggregate;
  /** fetch data from the table: "pkie.acquis" using primary key columns */
  pkie_acquis_by_pk?: Maybe<PkieAcquis>;
  /** fetch data from the table: "pkie.acquis_chapter" */
  pkie_acquis_chapter: Array<PkieAcquisChapter>;
  /** fetch aggregated fields from the table: "pkie.acquis_chapter" */
  pkie_acquis_chapter_aggregate: PkieAcquisChapterAggregate;
  /** fetch data from the table: "pkie.acquis_chapter" using primary key columns */
  pkie_acquis_chapter_by_pk?: Maybe<PkieAcquisChapter>;
  /** fetch data from the table in a streaming manner: "pkie.acquis_chapter" */
  pkie_acquis_chapter_stream: Array<PkieAcquisChapter>;
  /** fetch data from the table: "pkie.acquis_institutions" */
  pkie_acquis_institutions: Array<PkieAcquisInstitutions>;
  /** fetch aggregated fields from the table: "pkie.acquis_institutions" */
  pkie_acquis_institutions_aggregate: PkieAcquisInstitutionsAggregate;
  /** fetch data from the table: "pkie.acquis_institutions" using primary key columns */
  pkie_acquis_institutions_by_pk?: Maybe<PkieAcquisInstitutions>;
  /** fetch data from the table in a streaming manner: "pkie.acquis_institutions" */
  pkie_acquis_institutions_stream: Array<PkieAcquisInstitutions>;
  /** fetch data from the table: "pkie.acquis_national_acts" */
  pkie_acquis_national_acts: Array<PkieAcquisNationalActs>;
  /** fetch aggregated fields from the table: "pkie.acquis_national_acts" */
  pkie_acquis_national_acts_aggregate: PkieAcquisNationalActsAggregate;
  /** fetch data from the table: "pkie.acquis_national_acts" using primary key columns */
  pkie_acquis_national_acts_by_pk?: Maybe<PkieAcquisNationalActs>;
  /** fetch data from the table in a streaming manner: "pkie.acquis_national_acts" */
  pkie_acquis_national_acts_stream: Array<PkieAcquisNationalActs>;
  /** fetch data from the table in a streaming manner: "pkie.acquis" */
  pkie_acquis_stream: Array<PkieAcquis>;
  /** fetch data from the table: "pkie.acquis_type" */
  pkie_acquis_type: Array<PkieAcquisType>;
  /** fetch aggregated fields from the table: "pkie.acquis_type" */
  pkie_acquis_type_aggregate: PkieAcquisTypeAggregate;
  /** fetch data from the table: "pkie.acquis_type" using primary key columns */
  pkie_acquis_type_by_pk?: Maybe<PkieAcquisType>;
  /** fetch data from the table in a streaming manner: "pkie.acquis_type" */
  pkie_acquis_type_stream: Array<PkieAcquisType>;
  /** fetch data from the table: "pkie.acquis_work_plan_task" */
  pkie_acquis_work_plan_task: Array<PkieAcquisWorkPlanTask>;
  /** fetch aggregated fields from the table: "pkie.acquis_work_plan_task" */
  pkie_acquis_work_plan_task_aggregate: PkieAcquisWorkPlanTaskAggregate;
  /** fetch data from the table: "pkie.acquis_work_plan_task" using primary key columns */
  pkie_acquis_work_plan_task_by_pk?: Maybe<PkieAcquisWorkPlanTask>;
  /** fetch data from the table in a streaming manner: "pkie.acquis_work_plan_task" */
  pkie_acquis_work_plan_task_stream: Array<PkieAcquisWorkPlanTask>;
  /** fetch data from the table: "pkie.act_type" */
  pkie_act_type: Array<PkieActType>;
  /** fetch aggregated fields from the table: "pkie.act_type" */
  pkie_act_type_aggregate: PkieActTypeAggregate;
  /** fetch data from the table: "pkie.act_type" using primary key columns */
  pkie_act_type_by_pk?: Maybe<PkieActType>;
  /** fetch data from the table in a streaming manner: "pkie.act_type" */
  pkie_act_type_stream: Array<PkieActType>;
  /** fetch data from the table: "pkie.level_of_alignment" */
  pkie_level_of_alignment: Array<PkieLevelOfAlignment>;
  /** fetch aggregated fields from the table: "pkie.level_of_alignment" */
  pkie_level_of_alignment_aggregate: PkieLevelOfAlignmentAggregate;
  /** fetch data from the table: "pkie.level_of_alignment" using primary key columns */
  pkie_level_of_alignment_by_pk?: Maybe<PkieLevelOfAlignment>;
  /** fetch data from the table in a streaming manner: "pkie.level_of_alignment" */
  pkie_level_of_alignment_stream: Array<PkieLevelOfAlignment>;
  /** fetch data from the table: "pkie.measure" */
  pkie_measure: Array<PkieMeasure>;
  /** fetch data from the table: "pkie.measure_activity" */
  pkie_measure_activity: Array<PkieMeasureActivity>;
  /** fetch aggregated fields from the table: "pkie.measure_activity" */
  pkie_measure_activity_aggregate: PkieMeasureActivityAggregate;
  /** fetch data from the table: "pkie.measure_activity" using primary key columns */
  pkie_measure_activity_by_pk?: Maybe<PkieMeasureActivity>;
  /** fetch data from the table in a streaming manner: "pkie.measure_activity" */
  pkie_measure_activity_stream: Array<PkieMeasureActivity>;
  /** fetch aggregated fields from the table: "pkie.measure" */
  pkie_measure_aggregate: PkieMeasureAggregate;
  /** fetch data from the table: "pkie.measure_answers" */
  pkie_measure_answers: Array<PkieMeasureAnswers>;
  /** fetch aggregated fields from the table: "pkie.measure_answers" */
  pkie_measure_answers_aggregate: PkieMeasureAnswersAggregate;
  /** fetch data from the table: "pkie.measure_answers" using primary key columns */
  pkie_measure_answers_by_pk?: Maybe<PkieMeasureAnswers>;
  /** fetch data from the table in a streaming manner: "pkie.measure_answers" */
  pkie_measure_answers_stream: Array<PkieMeasureAnswers>;
  /** fetch data from the table: "pkie.measure_bookmark" */
  pkie_measure_bookmark: Array<PkieMeasureBookmark>;
  /** fetch aggregated fields from the table: "pkie.measure_bookmark" */
  pkie_measure_bookmark_aggregate: PkieMeasureBookmarkAggregate;
  /** fetch data from the table: "pkie.measure_bookmark" using primary key columns */
  pkie_measure_bookmark_by_pk?: Maybe<PkieMeasureBookmark>;
  /** fetch data from the table in a streaming manner: "pkie.measure_bookmark" */
  pkie_measure_bookmark_stream: Array<PkieMeasureBookmark>;
  /** fetch data from the table: "pkie.measure_budgets" */
  pkie_measure_budgets: Array<PkieMeasureBudgets>;
  /** fetch aggregated fields from the table: "pkie.measure_budgets" */
  pkie_measure_budgets_aggregate: PkieMeasureBudgetsAggregate;
  /** fetch data from the table: "pkie.measure_budgets" using primary key columns */
  pkie_measure_budgets_by_pk?: Maybe<PkieMeasureBudgets>;
  /** fetch data from the table in a streaming manner: "pkie.measure_budgets" */
  pkie_measure_budgets_stream: Array<PkieMeasureBudgets>;
  /** fetch data from the table: "pkie.measure" using primary key columns */
  pkie_measure_by_pk?: Maybe<PkieMeasure>;
  /** fetch data from the table: "pkie.measure_comment" */
  pkie_measure_comment: Array<PkieMeasureComment>;
  /** fetch aggregated fields from the table: "pkie.measure_comment" */
  pkie_measure_comment_aggregate: PkieMeasureCommentAggregate;
  /** fetch data from the table: "pkie.measure_comment" using primary key columns */
  pkie_measure_comment_by_pk?: Maybe<PkieMeasureComment>;
  /** fetch data from the table in a streaming manner: "pkie.measure_comment" */
  pkie_measure_comment_stream: Array<PkieMeasureComment>;
  /** fetch data from the table: "pkie.measure_gnpie_members" */
  pkie_measure_gnpie_members: Array<PkieMeasureGnpieMembers>;
  /** fetch aggregated fields from the table: "pkie.measure_gnpie_members" */
  pkie_measure_gnpie_members_aggregate: PkieMeasureGnpieMembersAggregate;
  /** fetch data from the table: "pkie.measure_gnpie_members" using primary key columns */
  pkie_measure_gnpie_members_by_pk?: Maybe<PkieMeasureGnpieMembers>;
  /** fetch data from the table in a streaming manner: "pkie.measure_gnpie_members" */
  pkie_measure_gnpie_members_stream: Array<PkieMeasureGnpieMembers>;
  /** fetch data from the table: "pkie.measure_roadmap" */
  pkie_measure_roadmap: Array<PkieMeasureRoadmap>;
  /** fetch aggregated fields from the table: "pkie.measure_roadmap" */
  pkie_measure_roadmap_aggregate: PkieMeasureRoadmapAggregate;
  /** fetch data from the table: "pkie.measure_roadmap" using primary key columns */
  pkie_measure_roadmap_by_pk?: Maybe<PkieMeasureRoadmap>;
  /** fetch data from the table in a streaming manner: "pkie.measure_roadmap" */
  pkie_measure_roadmap_stream: Array<PkieMeasureRoadmap>;
  /** fetch data from the table: "pkie.measure_status" */
  pkie_measure_status: Array<PkieMeasureStatus>;
  /** fetch aggregated fields from the table: "pkie.measure_status" */
  pkie_measure_status_aggregate: PkieMeasureStatusAggregate;
  /** fetch data from the table: "pkie.measure_status" using primary key columns */
  pkie_measure_status_by_pk?: Maybe<PkieMeasureStatus>;
  /** fetch data from the table in a streaming manner: "pkie.measure_status" */
  pkie_measure_status_stream: Array<PkieMeasureStatus>;
  /** fetch data from the table in a streaming manner: "pkie.measure" */
  pkie_measure_stream: Array<PkieMeasure>;
  /** fetch data from the table: "pkie.national_acts" */
  pkie_national_acts: Array<PkieNationalActs>;
  /** fetch aggregated fields from the table: "pkie.national_acts" */
  pkie_national_acts_aggregate: PkieNationalActsAggregate;
  /** fetch data from the table: "pkie.national_acts" using primary key columns */
  pkie_national_acts_by_pk?: Maybe<PkieNationalActs>;
  /** fetch data from the table in a streaming manner: "pkie.national_acts" */
  pkie_national_acts_stream: Array<PkieNationalActs>;
  /** fetch data from the table: "pkie.proces_pune_iaga" */
  pkie_proces_pune_iaga: Array<PkieProcesPuneIaga>;
  /** fetch aggregated fields from the table: "pkie.proces_pune_iaga" */
  pkie_proces_pune_iaga_aggregate: PkieProcesPuneIagaAggregate;
  /** fetch data from the table: "pkie.proces_pune_iaga" using primary key columns */
  pkie_proces_pune_iaga_by_pk?: Maybe<PkieProcesPuneIaga>;
  /** fetch data from the table in a streaming manner: "pkie.proces_pune_iaga" */
  pkie_proces_pune_iaga_stream: Array<PkieProcesPuneIaga>;
  /** fetch data from the table: "pkie.proces_pune_lga" */
  pkie_proces_pune_lga: Array<PkieProcesPuneLga>;
  /** fetch aggregated fields from the table: "pkie.proces_pune_lga" */
  pkie_proces_pune_lga_aggregate: PkieProcesPuneLgaAggregate;
  /** fetch data from the table: "pkie.proces_pune_lga" using primary key columns */
  pkie_proces_pune_lga_by_pk?: Maybe<PkieProcesPuneLga>;
  /** fetch data from the table in a streaming manner: "pkie.proces_pune_lga" */
  pkie_proces_pune_lga_stream: Array<PkieProcesPuneLga>;
  /** fetch data from the table: "pkie.source_of_funding" */
  pkie_source_of_funding: Array<PkieSourceOfFunding>;
  /** fetch aggregated fields from the table: "pkie.source_of_funding" */
  pkie_source_of_funding_aggregate: PkieSourceOfFundingAggregate;
  /** fetch data from the table: "pkie.source_of_funding" using primary key columns */
  pkie_source_of_funding_by_pk?: Maybe<PkieSourceOfFunding>;
  /** fetch data from the table in a streaming manner: "pkie.source_of_funding" */
  pkie_source_of_funding_stream: Array<PkieSourceOfFunding>;
  /** fetch data from the table: "pkie.type_alignment_act" */
  pkie_type_alignment_act: Array<PkieTypeAlignmentAct>;
  /** fetch aggregated fields from the table: "pkie.type_alignment_act" */
  pkie_type_alignment_act_aggregate: PkieTypeAlignmentActAggregate;
  /** fetch data from the table: "pkie.type_alignment_act" using primary key columns */
  pkie_type_alignment_act_by_pk?: Maybe<PkieTypeAlignmentAct>;
  /** fetch data from the table in a streaming manner: "pkie.type_alignment_act" */
  pkie_type_alignment_act_stream: Array<PkieTypeAlignmentAct>;
  /** fetch data from the table: "pkie.type_measure" */
  pkie_type_measure: Array<PkieTypeMeasure>;
  /** fetch aggregated fields from the table: "pkie.type_measure" */
  pkie_type_measure_aggregate: PkieTypeMeasureAggregate;
  /** fetch data from the table: "pkie.type_measure_budget" */
  pkie_type_measure_budget: Array<PkieTypeMeasureBudget>;
  /** fetch aggregated fields from the table: "pkie.type_measure_budget" */
  pkie_type_measure_budget_aggregate: PkieTypeMeasureBudgetAggregate;
  /** fetch data from the table: "pkie.type_measure_budget" using primary key columns */
  pkie_type_measure_budget_by_pk?: Maybe<PkieTypeMeasureBudget>;
  /** fetch data from the table in a streaming manner: "pkie.type_measure_budget" */
  pkie_type_measure_budget_stream: Array<PkieTypeMeasureBudget>;
  /** fetch data from the table: "pkie.type_measure" using primary key columns */
  pkie_type_measure_by_pk?: Maybe<PkieTypeMeasure>;
  /** fetch data from the table in a streaming manner: "pkie.type_measure" */
  pkie_type_measure_stream: Array<PkieTypeMeasure>;
  /** fetch data from the table: "pkie.work_plan_comment" */
  pkie_work_plan_comment: Array<PkieWorkPlanComment>;
  /** fetch aggregated fields from the table: "pkie.work_plan_comment" */
  pkie_work_plan_comment_aggregate: PkieWorkPlanCommentAggregate;
  /** fetch data from the table: "pkie.work_plan_comment" using primary key columns */
  pkie_work_plan_comment_by_pk?: Maybe<PkieWorkPlanComment>;
  /** fetch data from the table in a streaming manner: "pkie.work_plan_comment" */
  pkie_work_plan_comment_stream: Array<PkieWorkPlanComment>;
  /** fetch data from the table: "pkie.work_plan_statuses" */
  pkie_work_plan_statuses: Array<PkieWorkPlanStatuses>;
  /** fetch aggregated fields from the table: "pkie.work_plan_statuses" */
  pkie_work_plan_statuses_aggregate: PkieWorkPlanStatusesAggregate;
  /** fetch data from the table: "pkie.work_plan_statuses" using primary key columns */
  pkie_work_plan_statuses_by_pk?: Maybe<PkieWorkPlanStatuses>;
  /** fetch data from the table in a streaming manner: "pkie.work_plan_statuses" */
  pkie_work_plan_statuses_stream: Array<PkieWorkPlanStatuses>;
  /** fetch data from the table: "pkie.work_plan_task" */
  pkie_work_plan_task: Array<PkieWorkPlanTask>;
  /** fetch data from the table: "pkie.work_plan_task_activity" */
  pkie_work_plan_task_activity: Array<PkieWorkPlanTaskActivity>;
  /** fetch aggregated fields from the table: "pkie.work_plan_task_activity" */
  pkie_work_plan_task_activity_aggregate: PkieWorkPlanTaskActivityAggregate;
  /** fetch data from the table: "pkie.work_plan_task_activity" using primary key columns */
  pkie_work_plan_task_activity_by_pk?: Maybe<PkieWorkPlanTaskActivity>;
  /** fetch data from the table in a streaming manner: "pkie.work_plan_task_activity" */
  pkie_work_plan_task_activity_stream: Array<PkieWorkPlanTaskActivity>;
  /** fetch aggregated fields from the table: "pkie.work_plan_task" */
  pkie_work_plan_task_aggregate: PkieWorkPlanTaskAggregate;
  /** fetch data from the table: "pkie.work_plan_task" using primary key columns */
  pkie_work_plan_task_by_pk?: Maybe<PkieWorkPlanTask>;
  /** fetch data from the table: "pkie.work_plan_task_statuses" */
  pkie_work_plan_task_statuses: Array<PkieWorkPlanTaskStatuses>;
  /** fetch aggregated fields from the table: "pkie.work_plan_task_statuses" */
  pkie_work_plan_task_statuses_aggregate: PkieWorkPlanTaskStatusesAggregate;
  /** fetch data from the table: "pkie.work_plan_task_statuses" using primary key columns */
  pkie_work_plan_task_statuses_by_pk?: Maybe<PkieWorkPlanTaskStatuses>;
  /** fetch data from the table in a streaming manner: "pkie.work_plan_task_statuses" */
  pkie_work_plan_task_statuses_stream: Array<PkieWorkPlanTaskStatuses>;
  /** fetch data from the table in a streaming manner: "pkie.work_plan_task" */
  pkie_work_plan_task_stream: Array<PkieWorkPlanTask>;
  /** fetch data from the table: "pkie.work_plans" */
  pkie_work_plans: Array<PkieWorkPlans>;
  /** fetch aggregated fields from the table: "pkie.work_plans" */
  pkie_work_plans_aggregate: PkieWorkPlansAggregate;
  /** fetch data from the table: "pkie.work_plans" using primary key columns */
  pkie_work_plans_by_pk?: Maybe<PkieWorkPlans>;
  /** fetch data from the table in a streaming manner: "pkie.work_plans" */
  pkie_work_plans_stream: Array<PkieWorkPlans>;
  /** fetch data from the table: "question_types" */
  question_types: Array<QuestionTypes>;
  /** fetch aggregated fields from the table: "question_types" */
  question_types_aggregate: QuestionTypesAggregate;
  /** fetch data from the table: "question_types" using primary key columns */
  question_types_by_pk?: Maybe<QuestionTypes>;
  /** fetch data from the table in a streaming manner: "question_types" */
  question_types_stream: Array<QuestionTypes>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: QuestionsAggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** An array relationship */
  recommendations: Array<Recommendations>;
  /** An aggregate relationship */
  recommendations_aggregate: RecommendationsAggregate;
  /** fetch data from the table: "recommendations" using primary key columns */
  recommendations_by_pk?: Maybe<Recommendations>;
  /** fetch data from the table in a streaming manner: "recommendations" */
  recommendations_stream: Array<Recommendations>;
  /** An array relationship */
  report_answers: Array<ReportAnswers>;
  /** An aggregate relationship */
  report_answers_aggregate: ReportAnswersAggregate;
  /** fetch data from the table: "report_answers" using primary key columns */
  report_answers_by_pk?: Maybe<ReportAnswers>;
  /** fetch data from the table in a streaming manner: "report_answers" */
  report_answers_stream: Array<ReportAnswers>;
  /** fetch data from the table: "report_chapter_statuses" */
  report_chapter_statuses: Array<ReportChapterStatuses>;
  /** fetch aggregated fields from the table: "report_chapter_statuses" */
  report_chapter_statuses_aggregate: ReportChapterStatusesAggregate;
  /** fetch data from the table: "report_chapter_statuses" using primary key columns */
  report_chapter_statuses_by_pk?: Maybe<ReportChapterStatuses>;
  /** fetch data from the table in a streaming manner: "report_chapter_statuses" */
  report_chapter_statuses_stream: Array<ReportChapterStatuses>;
  /** An array relationship */
  report_chapters: Array<ReportChapters>;
  /** An aggregate relationship */
  report_chapters_aggregate: ReportChaptersAggregate;
  /** fetch data from the table: "report_chapters" using primary key columns */
  report_chapters_by_pk?: Maybe<ReportChapters>;
  /** fetch data from the table in a streaming manner: "report_chapters" */
  report_chapters_stream: Array<ReportChapters>;
  /** fetch data from the table: "report_statuses" */
  report_statuses: Array<ReportStatuses>;
  /** fetch aggregated fields from the table: "report_statuses" */
  report_statuses_aggregate: ReportStatusesAggregate;
  /** fetch data from the table: "report_statuses" using primary key columns */
  report_statuses_by_pk?: Maybe<ReportStatuses>;
  /** fetch data from the table in a streaming manner: "report_statuses" */
  report_statuses_stream: Array<ReportStatuses>;
  /** fetch data from the table: "report_task_activity" */
  report_task_activity: Array<ReportTaskActivity>;
  /** fetch aggregated fields from the table: "report_task_activity" */
  report_task_activity_aggregate: ReportTaskActivityAggregate;
  /** fetch data from the table: "report_task_activity" using primary key columns */
  report_task_activity_by_pk?: Maybe<ReportTaskActivity>;
  /** fetch data from the table in a streaming manner: "report_task_activity" */
  report_task_activity_stream: Array<ReportTaskActivity>;
  /** fetch data from the table: "report_task_comment" */
  report_task_comment: Array<ReportTaskComment>;
  /** fetch aggregated fields from the table: "report_task_comment" */
  report_task_comment_aggregate: ReportTaskCommentAggregate;
  /** fetch data from the table: "report_task_comment" using primary key columns */
  report_task_comment_by_pk?: Maybe<ReportTaskComment>;
  /** fetch data from the table in a streaming manner: "report_task_comment" */
  report_task_comment_stream: Array<ReportTaskComment>;
  /** An array relationship */
  report_task_gnpie_members: Array<ReportTaskGnpieMembers>;
  /** An aggregate relationship */
  report_task_gnpie_members_aggregate: ReportTaskGnpieMembersAggregate;
  /** fetch data from the table: "report_task_gnpie_members" using primary key columns */
  report_task_gnpie_members_by_pk?: Maybe<ReportTaskGnpieMembers>;
  /** fetch data from the table in a streaming manner: "report_task_gnpie_members" */
  report_task_gnpie_members_stream: Array<ReportTaskGnpieMembers>;
  /** fetch data from the table: "report_task_statuses" */
  report_task_statuses: Array<ReportTaskStatuses>;
  /** fetch aggregated fields from the table: "report_task_statuses" */
  report_task_statuses_aggregate: ReportTaskStatusesAggregate;
  /** fetch data from the table: "report_task_statuses" using primary key columns */
  report_task_statuses_by_pk?: Maybe<ReportTaskStatuses>;
  /** fetch data from the table in a streaming manner: "report_task_statuses" */
  report_task_statuses_stream: Array<ReportTaskStatuses>;
  /** An array relationship */
  report_tasks: Array<ReportTasks>;
  /** An aggregate relationship */
  report_tasks_aggregate: ReportTasksAggregate;
  /** fetch data from the table: "report_tasks" using primary key columns */
  report_tasks_by_pk?: Maybe<ReportTasks>;
  /** fetch data from the table in a streaming manner: "report_tasks" */
  report_tasks_stream: Array<ReportTasks>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: ReportsAggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** fetch data from the table: "roadmap_measure_type" */
  roadmap_measure_type: Array<RoadmapMeasureType>;
  /** fetch aggregated fields from the table: "roadmap_measure_type" */
  roadmap_measure_type_aggregate: RoadmapMeasureTypeAggregate;
  /** fetch data from the table: "roadmap_measure_type" using primary key columns */
  roadmap_measure_type_by_pk?: Maybe<RoadmapMeasureType>;
  /** fetch data from the table in a streaming manner: "roadmap_measure_type" */
  roadmap_measure_type_stream: Array<RoadmapMeasureType>;
  /** An array relationship */
  signed_documents: Array<SignedDocuments>;
  /** An aggregate relationship */
  signed_documents_aggregate: SignedDocumentsAggregate;
  /** fetch data from the table: "signed_documents" using primary key columns */
  signed_documents_by_pk?: Maybe<SignedDocuments>;
  /** fetch data from the table in a streaming manner: "signed_documents" */
  signed_documents_stream: Array<SignedDocuments>;
  /** An array relationship */
  sub_chapter_lead_institutions: Array<SubChapterLeadInstitutions>;
  /** An aggregate relationship */
  sub_chapter_lead_institutions_aggregate: SubChapterLeadInstitutionsAggregate;
  /** fetch data from the table: "sub_chapter_lead_institutions" using primary key columns */
  sub_chapter_lead_institutions_by_pk?: Maybe<SubChapterLeadInstitutions>;
  /** fetch data from the table in a streaming manner: "sub_chapter_lead_institutions" */
  sub_chapter_lead_institutions_stream: Array<SubChapterLeadInstitutions>;
  /** An array relationship */
  sub_chapters: Array<SubChapters>;
  /** An aggregate relationship */
  sub_chapters_aggregate: SubChaptersAggregate;
  /** fetch data from the table: "sub_chapters" using primary key columns */
  sub_chapters_by_pk?: Maybe<SubChapters>;
  /** fetch data from the table in a streaming manner: "sub_chapters" */
  sub_chapters_stream: Array<SubChapters>;
  /** fetch data from the table: "task" */
  task: Array<Task>;
  /** fetch data from the table: "task_activity" */
  task_activity: Array<TaskActivity>;
  /** fetch aggregated fields from the table: "task_activity" */
  task_activity_aggregate: TaskActivityAggregate;
  /** fetch data from the table: "task_activity" using primary key columns */
  task_activity_by_pk?: Maybe<TaskActivity>;
  /** fetch data from the table in a streaming manner: "task_activity" */
  task_activity_stream: Array<TaskActivity>;
  /** fetch aggregated fields from the table: "task" */
  task_aggregate: TaskAggregate;
  /** An array relationship */
  task_answers: Array<TaskAnswers>;
  /** An aggregate relationship */
  task_answers_aggregate: TaskAnswersAggregate;
  /** fetch data from the table: "task_answers" using primary key columns */
  task_answers_by_pk?: Maybe<TaskAnswers>;
  /** fetch data from the table in a streaming manner: "task_answers" */
  task_answers_stream: Array<TaskAnswers>;
  /** fetch data from the table: "task" using primary key columns */
  task_by_pk?: Maybe<Task>;
  /** An array relationship */
  task_comments: Array<TaskComments>;
  /** An aggregate relationship */
  task_comments_aggregate: TaskCommentsAggregate;
  /** fetch data from the table: "task_comments" using primary key columns */
  task_comments_by_pk?: Maybe<TaskComments>;
  /** fetch data from the table in a streaming manner: "task_comments" */
  task_comments_stream: Array<TaskComments>;
  /** An array relationship */
  task_gnpie_members: Array<TaskGnpieMembers>;
  /** An aggregate relationship */
  task_gnpie_members_aggregate: TaskGnpieMembersAggregate;
  /** fetch data from the table: "task_gnpie_members" using primary key columns */
  task_gnpie_members_by_pk?: Maybe<TaskGnpieMembers>;
  /** fetch data from the table in a streaming manner: "task_gnpie_members" */
  task_gnpie_members_stream: Array<TaskGnpieMembers>;
  /** fetch data from the table: "task_statuses" */
  task_statuses: Array<TaskStatuses>;
  /** fetch aggregated fields from the table: "task_statuses" */
  task_statuses_aggregate: TaskStatusesAggregate;
  /** fetch data from the table: "task_statuses" using primary key columns */
  task_statuses_by_pk?: Maybe<TaskStatuses>;
  /** fetch data from the table in a streaming manner: "task_statuses" */
  task_statuses_stream: Array<TaskStatuses>;
  /** fetch data from the table in a streaming manner: "task" */
  task_stream: Array<Task>;
  /** fetch data from the table: "task_types" */
  task_types: Array<TaskTypes>;
  /** fetch aggregated fields from the table: "task_types" */
  task_types_aggregate: TaskTypesAggregate;
  /** fetch data from the table: "task_types" using primary key columns */
  task_types_by_pk?: Maybe<TaskTypes>;
  /** fetch data from the table in a streaming manner: "task_types" */
  task_types_stream: Array<TaskTypes>;
  /** An array relationship */
  topics: Array<Topics>;
  /** An aggregate relationship */
  topics_aggregate: TopicsAggregate;
  /** fetch data from the table: "topics" using primary key columns */
  topics_by_pk?: Maybe<Topics>;
  /** fetch data from the table in a streaming manner: "topics" */
  topics_stream: Array<Topics>;
  /** fetch data from the table: "type_institutions" */
  type_institutions: Array<TypeInstitutions>;
  /** fetch aggregated fields from the table: "type_institutions" */
  type_institutions_aggregate: TypeInstitutionsAggregate;
  /** fetch data from the table: "type_institutions" using primary key columns */
  type_institutions_by_pk?: Maybe<TypeInstitutions>;
  /** fetch data from the table in a streaming manner: "type_institutions" */
  type_institutions_stream: Array<TypeInstitutions>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "yearly_quarters" */
  yearly_quarters: Array<YearlyQuarters>;
  /** fetch aggregated fields from the table: "yearly_quarters" */
  yearly_quarters_aggregate: YearlyQuartersAggregate;
  /** fetch data from the table: "yearly_quarters" using primary key columns */
  yearly_quarters_by_pk?: Maybe<YearlyQuarters>;
  /** fetch data from the table in a streaming manner: "yearly_quarters" */
  yearly_quarters_stream: Array<YearlyQuarters>;
}


export interface SubscriptionRootAuthProviderArgs {
  id: Scalars['String']['input'];
}


export interface SubscriptionRootAuthProviderRequestArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootAuthProviderRequestsArgs {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
}


export interface SubscriptionRootAuthProviderRequestsAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
}


export interface SubscriptionRootAuthProviderRequestsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviderRequestsStreamCursorInput>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
}


export interface SubscriptionRootAuthProvidersArgs {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
}


export interface SubscriptionRootAuthProvidersAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
}


export interface SubscriptionRootAuthProvidersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProvidersStreamCursorInput>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
}


export interface SubscriptionRootAuthRefreshTokenArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootAuthRefreshTokenTypeArgs {
  value: Scalars['String']['input'];
}


export interface SubscriptionRootAuthRefreshTokenTypesArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
}


export interface SubscriptionRootAuthRefreshTokenTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
}


export interface SubscriptionRootAuthRefreshTokenTypesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokenTypesStreamCursorInput>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
}


export interface SubscriptionRootAuthRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


export interface SubscriptionRootAuthRefreshTokensAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


export interface SubscriptionRootAuthRefreshTokensStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokensStreamCursorInput>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


export interface SubscriptionRootAuthRoleArgs {
  role: Scalars['String']['input'];
}


export interface SubscriptionRootAuthRolesArgs {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
}


export interface SubscriptionRootAuthRolesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
}


export interface SubscriptionRootAuthRolesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRolesStreamCursorInput>>;
  where?: InputMaybe<AuthRolesBoolExp>;
}


export interface SubscriptionRootAuthUserProviderArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootAuthUserProvidersArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


export interface SubscriptionRootAuthUserProvidersAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


export interface SubscriptionRootAuthUserProvidersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserProvidersStreamCursorInput>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


export interface SubscriptionRootAuthUserRoleArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootAuthUserRolesArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


export interface SubscriptionRootAuthUserRolesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


export interface SubscriptionRootAuthUserRolesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserRolesStreamCursorInput>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


export interface SubscriptionRootAuthUserSecurityKeyArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootAuthUserSecurityKeysArgs {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


export interface SubscriptionRootAuthUserSecurityKeysAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


export interface SubscriptionRootAuthUserSecurityKeysStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserSecurityKeysStreamCursorInput>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


export interface SubscriptionRootAuthModulesArgs {
  distinct_on?: InputMaybe<Array<AuthModulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthModulesOrderBy>>;
  where?: InputMaybe<AuthModulesBoolExp>;
}


export interface SubscriptionRootAuthModulesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthModulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthModulesOrderBy>>;
  where?: InputMaybe<AuthModulesBoolExp>;
}


export interface SubscriptionRootAuthModulesByPkArgs {
  module: Scalars['String']['input'];
}


export interface SubscriptionRootAuthModulesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthModulesStreamCursorInput>>;
  where?: InputMaybe<AuthModulesBoolExp>;
}


export interface SubscriptionRootBookmarkGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkGnpieMembersOrderBy>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


export interface SubscriptionRootBookmarkGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkGnpieMembersOrderBy>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


export interface SubscriptionRootBookmarkGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootBookmarkGnpieMembersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkGnpieMembersStreamCursorInput>>;
  where?: InputMaybe<BookmarkGnpieMembersBoolExp>;
}


export interface SubscriptionRootBookmarkStatusArgs {
  distinct_on?: InputMaybe<Array<BookmarkStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkStatusOrderBy>>;
  where?: InputMaybe<BookmarkStatusBoolExp>;
}


export interface SubscriptionRootBookmarkStatusAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkStatusOrderBy>>;
  where?: InputMaybe<BookmarkStatusBoolExp>;
}


export interface SubscriptionRootBookmarkStatusByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootBookmarkStatusStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkStatusStreamCursorInput>>;
  where?: InputMaybe<BookmarkStatusBoolExp>;
}


export interface SubscriptionRootBookmarkTaskActivityArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskActivityOrderBy>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


export interface SubscriptionRootBookmarkTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskActivityOrderBy>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


export interface SubscriptionRootBookmarkTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootBookmarkTaskActivityStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkTaskActivityStreamCursorInput>>;
  where?: InputMaybe<BookmarkTaskActivityBoolExp>;
}


export interface SubscriptionRootBookmarkTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskAnswersOrderBy>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


export interface SubscriptionRootBookmarkTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskAnswersOrderBy>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


export interface SubscriptionRootBookmarkTaskAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootBookmarkTaskAnswersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkTaskAnswersStreamCursorInput>>;
  where?: InputMaybe<BookmarkTaskAnswersBoolExp>;
}


export interface SubscriptionRootBookmarkTaskCommentArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskCommentOrderBy>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


export interface SubscriptionRootBookmarkTaskCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskCommentOrderBy>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


export interface SubscriptionRootBookmarkTaskCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootBookmarkTaskCommentStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkTaskCommentStreamCursorInput>>;
  where?: InputMaybe<BookmarkTaskCommentBoolExp>;
}


export interface SubscriptionRootBookmarkTaskMeasuresArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskMeasuresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskMeasuresOrderBy>>;
  where?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
}


export interface SubscriptionRootBookmarkTaskMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskMeasuresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskMeasuresOrderBy>>;
  where?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
}


export interface SubscriptionRootBookmarkTaskMeasuresByPkArgs {
  measure: Scalars['String']['input'];
}


export interface SubscriptionRootBookmarkTaskMeasuresStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkTaskMeasuresStreamCursorInput>>;
  where?: InputMaybe<BookmarkTaskMeasuresBoolExp>;
}


export interface SubscriptionRootBookmarkTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskStatusesOrderBy>>;
  where?: InputMaybe<BookmarkTaskStatusesBoolExp>;
}


export interface SubscriptionRootBookmarkTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTaskStatusesOrderBy>>;
  where?: InputMaybe<BookmarkTaskStatusesBoolExp>;
}


export interface SubscriptionRootBookmarkTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootBookmarkTaskStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkTaskStatusesStreamCursorInput>>;
  where?: InputMaybe<BookmarkTaskStatusesBoolExp>;
}


export interface SubscriptionRootBookmarkTasksArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


export interface SubscriptionRootBookmarkTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


export interface SubscriptionRootBookmarkTasksByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootBookmarkTasksStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarkTasksStreamCursorInput>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


export interface SubscriptionRootBookmarksArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


export interface SubscriptionRootBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


export interface SubscriptionRootBookmarksByPkArgs {
  id: Scalars['Int']['input'];
}


export interface SubscriptionRootBookmarksStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BookmarksStreamCursorInput>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfChiefSecretariatArgs {
  args: CalendarTasksOfChiefSecretariatArguments;
  distinct_on?: InputMaybe<Array<MemberCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCalendarOrderBy>>;
  where?: InputMaybe<MemberCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfCoordinatorArgs {
  args: CalendarTasksOfCoordinatorArguments;
  distinct_on?: InputMaybe<Array<NegotiatorCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NegotiatorCalendarOrderBy>>;
  where?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfHorizontalCoordinatorArgs {
  args: CalendarTasksOfHorizontalCoordinatorArguments;
  distinct_on?: InputMaybe<Array<NegotiatorCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NegotiatorCalendarOrderBy>>;
  where?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfMemberArgs {
  args: CalendarTasksOfMemberArguments;
  distinct_on?: InputMaybe<Array<MemberCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCalendarOrderBy>>;
  where?: InputMaybe<MemberCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfNegotiatorArgs {
  args: CalendarTasksOfNegotiatorArguments;
  distinct_on?: InputMaybe<Array<NegotiatorCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NegotiatorCalendarOrderBy>>;
  where?: InputMaybe<NegotiatorCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfPmoArgs {
  args: CalendarTasksOfPmoArguments;
  distinct_on?: InputMaybe<Array<PmoCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PmoCalendarOrderBy>>;
  where?: InputMaybe<PmoCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCalendarTasksOfSecretariatArgs {
  args: CalendarTasksOfSecretariatArguments;
  distinct_on?: InputMaybe<Array<MemberCalendarEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MemberCalendarOrderBy>>;
  where?: InputMaybe<MemberCalendarBoolExpBoolExp>;
}


export interface SubscriptionRootCapacBeneficiariesArgs {
  distinct_on?: InputMaybe<Array<CapacBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacBeneficiariesBoolExp>;
}


export interface SubscriptionRootCapacBeneficiariesAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacBeneficiariesBoolExp>;
}


export interface SubscriptionRootCapacBeneficiariesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacBeneficiariesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacBeneficiariesStreamCursorInput>>;
  where?: InputMaybe<CapacBeneficiariesBoolExp>;
}


export interface SubscriptionRootCapacChapterDonatorArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


export interface SubscriptionRootCapacChapterDonatorAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacChapterDonatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacChapterDonatorOrderBy>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


export interface SubscriptionRootCapacChapterDonatorByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacChapterDonatorStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacChapterDonatorStreamCursorInput>>;
  where?: InputMaybe<CapacChapterDonatorBoolExp>;
}


export interface SubscriptionRootCapacDonatorsArgs {
  distinct_on?: InputMaybe<Array<CapacDonatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacDonatorsOrderBy>>;
  where?: InputMaybe<CapacDonatorsBoolExp>;
}


export interface SubscriptionRootCapacDonatorsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacDonatorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacDonatorsOrderBy>>;
  where?: InputMaybe<CapacDonatorsBoolExp>;
}


export interface SubscriptionRootCapacDonatorsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacDonatorsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacDonatorsStreamCursorInput>>;
  where?: InputMaybe<CapacDonatorsBoolExp>;
}


export interface SubscriptionRootCapacExpertsArgs {
  distinct_on?: InputMaybe<Array<CapacExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacExpertsOrderBy>>;
  where?: InputMaybe<CapacExpertsBoolExp>;
}


export interface SubscriptionRootCapacExpertsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacExpertsOrderBy>>;
  where?: InputMaybe<CapacExpertsBoolExp>;
}


export interface SubscriptionRootCapacExpertsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacExpertsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacExpertsStreamCursorInput>>;
  where?: InputMaybe<CapacExpertsBoolExp>;
}


export interface SubscriptionRootCapacIntegrationStepsArgs {
  distinct_on?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacIntegrationStepsOrderBy>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}


export interface SubscriptionRootCapacIntegrationStepsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacIntegrationStepsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacIntegrationStepsOrderBy>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}


export interface SubscriptionRootCapacIntegrationStepsByPkArgs {
  id: Scalars['numeric']['input'];
}


export interface SubscriptionRootCapacIntegrationStepsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacIntegrationStepsStreamCursorInput>>;
  where?: InputMaybe<CapacIntegrationStepsBoolExp>;
}


export interface SubscriptionRootCapacPriorityListsArgs {
  distinct_on?: InputMaybe<Array<CapacPriorityListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacPriorityListsOrderBy>>;
  where?: InputMaybe<CapacPriorityListsBoolExp>;
}


export interface SubscriptionRootCapacPriorityListsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacPriorityListsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacPriorityListsOrderBy>>;
  where?: InputMaybe<CapacPriorityListsBoolExp>;
}


export interface SubscriptionRootCapacPriorityListsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacPriorityListsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacPriorityListsStreamCursorInput>>;
  where?: InputMaybe<CapacPriorityListsBoolExp>;
}


export interface SubscriptionRootCapacProjectBeneficiariesArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


export interface SubscriptionRootCapacProjectBeneficiariesAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectBeneficiariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectBeneficiariesOrderBy>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


export interface SubscriptionRootCapacProjectBeneficiariesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacProjectBeneficiariesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacProjectBeneficiariesStreamCursorInput>>;
  where?: InputMaybe<CapacProjectBeneficiariesBoolExp>;
}


export interface SubscriptionRootCapacProjectsArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


export interface SubscriptionRootCapacProjectsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacProjectsOrderBy>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


export interface SubscriptionRootCapacProjectsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacProjectsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacProjectsStreamCursorInput>>;
  where?: InputMaybe<CapacProjectsBoolExp>;
}


export interface SubscriptionRootCapacTaiexArgs {
  distinct_on?: InputMaybe<Array<CapacTaiexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTaiexOrderBy>>;
  where?: InputMaybe<CapacTaiexBoolExp>;
}


export interface SubscriptionRootCapacTaiexAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTaiexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTaiexOrderBy>>;
  where?: InputMaybe<CapacTaiexBoolExp>;
}


export interface SubscriptionRootCapacTaiexByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTaiexStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTaiexStreamCursorInput>>;
  where?: InputMaybe<CapacTaiexBoolExp>;
}


export interface SubscriptionRootCapacTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


export interface SubscriptionRootCapacTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


export interface SubscriptionRootCapacTrainingNeedsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTrainingNeedsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTrainingNeedsStreamCursorInput>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


export interface SubscriptionRootCapacTrainingParticipantsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


export interface SubscriptionRootCapacTrainingParticipantsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


export interface SubscriptionRootCapacTrainingParticipantsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTrainingParticipantsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTrainingParticipantsStreamCursorInput>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


export interface SubscriptionRootCapacTrainingSessionsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingSessionsOrderBy>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


export interface SubscriptionRootCapacTrainingSessionsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingSessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingSessionsOrderBy>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


export interface SubscriptionRootCapacTrainingSessionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTrainingSessionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTrainingSessionsStreamCursorInput>>;
  where?: InputMaybe<CapacTrainingSessionsBoolExp>;
}


export interface SubscriptionRootCapacTrainingsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsOrderBy>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


export interface SubscriptionRootCapacTrainingsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsOrderBy>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


export interface SubscriptionRootCapacTrainingsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTrainingsExpertsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


export interface SubscriptionRootCapacTrainingsExpertsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsExpertsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsExpertsOrderBy>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


export interface SubscriptionRootCapacTrainingsExpertsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTrainingsExpertsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTrainingsExpertsStreamCursorInput>>;
  where?: InputMaybe<CapacTrainingsExpertsBoolExp>;
}


export interface SubscriptionRootCapacTrainingsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTrainingsStreamCursorInput>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


export interface SubscriptionRootCapacTwinningArgs {
  distinct_on?: InputMaybe<Array<CapacTwinningSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTwinningOrderBy>>;
  where?: InputMaybe<CapacTwinningBoolExp>;
}


export interface SubscriptionRootCapacTwinningAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTwinningSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTwinningOrderBy>>;
  where?: InputMaybe<CapacTwinningBoolExp>;
}


export interface SubscriptionRootCapacTwinningByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootCapacTwinningStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CapacTwinningStreamCursorInput>>;
  where?: InputMaybe<CapacTwinningBoolExp>;
}


export interface SubscriptionRootChapterLeadInstitutionsArgs {
  distinct_on?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


export interface SubscriptionRootChapterLeadInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<ChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


export interface SubscriptionRootChapterLeadInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootChapterLeadInstitutionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChapterLeadInstitutionsStreamCursorInput>>;
  where?: InputMaybe<ChapterLeadInstitutionsBoolExp>;
}


export interface SubscriptionRootChaptersArgs {
  distinct_on?: InputMaybe<Array<ChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChaptersOrderBy>>;
  where?: InputMaybe<ChaptersBoolExp>;
}


export interface SubscriptionRootChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChaptersOrderBy>>;
  where?: InputMaybe<ChaptersBoolExp>;
}


export interface SubscriptionRootChaptersByPkArgs {
  id: Scalars['Int']['input'];
}


export interface SubscriptionRootChaptersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChaptersStreamCursorInput>>;
  where?: InputMaybe<ChaptersBoolExp>;
}


export interface SubscriptionRootClustersArgs {
  distinct_on?: InputMaybe<Array<ClustersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClustersOrderBy>>;
  where?: InputMaybe<ClustersBoolExp>;
}


export interface SubscriptionRootClustersAggregateArgs {
  distinct_on?: InputMaybe<Array<ClustersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClustersOrderBy>>;
  where?: InputMaybe<ClustersBoolExp>;
}


export interface SubscriptionRootClustersByPkArgs {
  id: Scalars['Int']['input'];
}


export interface SubscriptionRootClustersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ClustersStreamCursorInput>>;
  where?: InputMaybe<ClustersBoolExp>;
}


export interface SubscriptionRootDashboardStatisticsArgs {
  args: DashboardStatisticsArguments;
  distinct_on?: InputMaybe<Array<DashboardStatisticsEntityEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardStatisticsEntityOrderBy>>;
  where?: InputMaybe<DashboardStatisticsEntityBoolExpBoolExp>;
}


export interface SubscriptionRootDashboardStatisticsAggregateArgs {
  args: DashboardStatisticsAggregateArguments;
  distinct_on?: InputMaybe<Array<DashboardAggregateEntityEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardAggregateEntityOrderBy>>;
  where?: InputMaybe<DashboardAggregateEntityBoolExpBoolExp>;
}


export interface SubscriptionRootDmsFolderArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface SubscriptionRootDmsFolderAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderOrderBy>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface SubscriptionRootDmsFolderByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootDmsFolderStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DmsFolderStreamCursorInput>>;
  where?: InputMaybe<DmsFolderBoolExp>;
}


export interface SubscriptionRootDmsFolderTypeArgs {
  distinct_on?: InputMaybe<Array<DmsFolderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderTypeOrderBy>>;
  where?: InputMaybe<DmsFolderTypeBoolExp>;
}


export interface SubscriptionRootDmsFolderTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<DmsFolderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DmsFolderTypeOrderBy>>;
  where?: InputMaybe<DmsFolderTypeBoolExp>;
}


export interface SubscriptionRootDmsFolderTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootDmsFolderTypeStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DmsFolderTypeStreamCursorInput>>;
  where?: InputMaybe<DmsFolderTypeBoolExp>;
}


export interface SubscriptionRootDocumentsArgs {
  distinct_on?: InputMaybe<Array<DocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentsOrderBy>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


export interface SubscriptionRootDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<DocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentsOrderBy>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


export interface SubscriptionRootDocumentsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootDocumentsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentsStreamCursorInput>>;
  where?: InputMaybe<DocumentsBoolExp>;
}


export interface SubscriptionRootErrorLogsArgs {
  distinct_on?: InputMaybe<Array<ErrorLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ErrorLogsOrderBy>>;
  where?: InputMaybe<ErrorLogsBoolExp>;
}


export interface SubscriptionRootErrorLogsAggregateArgs {
  distinct_on?: InputMaybe<Array<ErrorLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ErrorLogsOrderBy>>;
  where?: InputMaybe<ErrorLogsBoolExp>;
}


export interface SubscriptionRootErrorLogsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootErrorLogsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ErrorLogsStreamCursorInput>>;
  where?: InputMaybe<ErrorLogsBoolExp>;
}


export interface SubscriptionRootEventNotesArgs {
  distinct_on?: InputMaybe<Array<EventNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventNotesOrderBy>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


export interface SubscriptionRootEventNotesAggregateArgs {
  distinct_on?: InputMaybe<Array<EventNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventNotesOrderBy>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


export interface SubscriptionRootEventNotesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootEventNotesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventNotesStreamCursorInput>>;
  where?: InputMaybe<EventNotesBoolExp>;
}


export interface SubscriptionRootEventTypologyArgs {
  distinct_on?: InputMaybe<Array<EventTypologySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTypologyOrderBy>>;
  where?: InputMaybe<EventTypologyBoolExp>;
}


export interface SubscriptionRootEventTypologyAggregateArgs {
  distinct_on?: InputMaybe<Array<EventTypologySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventTypologyOrderBy>>;
  where?: InputMaybe<EventTypologyBoolExp>;
}


export interface SubscriptionRootEventTypologyByPkArgs {
  typology: Scalars['String']['input'];
}


export interface SubscriptionRootEventTypologyStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventTypologyStreamCursorInput>>;
  where?: InputMaybe<EventTypologyBoolExp>;
}


export interface SubscriptionRootEventUsersArgs {
  distinct_on?: InputMaybe<Array<EventUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventUsersOrderBy>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


export interface SubscriptionRootEventUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<EventUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventUsersOrderBy>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


export interface SubscriptionRootEventUsersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootEventUsersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventUsersStreamCursorInput>>;
  where?: InputMaybe<EventUsersBoolExp>;
}


export interface SubscriptionRootEventsArgs {
  distinct_on?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
}


export interface SubscriptionRootEventsAggregateArgs {
  distinct_on?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
}


export interface SubscriptionRootEventsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootEventsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventsStreamCursorInput>>;
  where?: InputMaybe<EventsBoolExp>;
}


export interface SubscriptionRootGetFolderPathArgs {
  args: GetFolderPathArguments;
  distinct_on?: InputMaybe<Array<FolderPathModelEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FolderPathModelOrderBy>>;
  where?: InputMaybe<FolderPathModelBoolExpBoolExp>;
}


export interface SubscriptionRootGnpieArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


export interface SubscriptionRootGnpieUsersByChapterArgs {
  args: GnpieUsersByChapterArguments;
  distinct_on?: InputMaybe<Array<GnpieUserEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieUserOrderBy>>;
  where?: InputMaybe<GnpieUserBoolExpBoolExp>;
}


export interface SubscriptionRootGnpieAggregateArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


export interface SubscriptionRootGnpieByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootGnpieStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GnpieStreamCursorInput>>;
  where?: InputMaybe<GnpieBoolExp>;
}


export interface SubscriptionRootInstitutionsArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


export interface SubscriptionRootInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


export interface SubscriptionRootInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootInstitutionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<InstitutionsStreamCursorInput>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


export interface SubscriptionRootIntegrationPhaseProgresArgs {
  args: IntegrationPhaseProgresArguments;
  distinct_on?: InputMaybe<Array<IntegrationPhaseProgresEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhaseProgresOrderBy>>;
  where?: InputMaybe<IntegrationPhaseProgresBoolExpBoolExp>;
}


export interface SubscriptionRootIntegrationPhasesArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesOrderBy>>;
  where?: InputMaybe<IntegrationPhasesBoolExp>;
}


export interface SubscriptionRootIntegrationPhasesAggregateArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesOrderBy>>;
  where?: InputMaybe<IntegrationPhasesBoolExp>;
}


export interface SubscriptionRootIntegrationPhasesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootIntegrationPhasesEnumArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesEnumOrderBy>>;
  where?: InputMaybe<IntegrationPhasesEnumBoolExp>;
}


export interface SubscriptionRootIntegrationPhasesEnumAggregateArgs {
  distinct_on?: InputMaybe<Array<IntegrationPhasesEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IntegrationPhasesEnumOrderBy>>;
  where?: InputMaybe<IntegrationPhasesEnumBoolExp>;
}


export interface SubscriptionRootIntegrationPhasesEnumByPkArgs {
  phase: Scalars['String']['input'];
}


export interface SubscriptionRootIntegrationPhasesEnumStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IntegrationPhasesEnumStreamCursorInput>>;
  where?: InputMaybe<IntegrationPhasesEnumBoolExp>;
}


export interface SubscriptionRootIntegrationPhasesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IntegrationPhasesStreamCursorInput>>;
  where?: InputMaybe<IntegrationPhasesBoolExp>;
}


export interface SubscriptionRootMeetingAttendanceArgs {
  distinct_on?: InputMaybe<Array<MeetingAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingAttendanceOrderBy>>;
  where?: InputMaybe<MeetingAttendanceBoolExp>;
}


export interface SubscriptionRootMeetingAttendanceAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingAttendanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingAttendanceOrderBy>>;
  where?: InputMaybe<MeetingAttendanceBoolExp>;
}


export interface SubscriptionRootMeetingAttendanceByPkArgs {
  attendance: Scalars['String']['input'];
}


export interface SubscriptionRootMeetingAttendanceStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeetingAttendanceStreamCursorInput>>;
  where?: InputMaybe<MeetingAttendanceBoolExp>;
}


export interface SubscriptionRootMeetingNotesArgs {
  distinct_on?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingNotesOrderBy>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


export interface SubscriptionRootMeetingNotesAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingNotesOrderBy>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


export interface SubscriptionRootMeetingNotesByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootMeetingNotesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeetingNotesStreamCursorInput>>;
  where?: InputMaybe<MeetingNotesBoolExp>;
}


export interface SubscriptionRootMeetingUsersArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


export interface SubscriptionRootMeetingUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


export interface SubscriptionRootMeetingUsersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootMeetingUsersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeetingUsersStreamCursorInput>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


export interface SubscriptionRootMeetingsArgs {
  distinct_on?: InputMaybe<Array<MeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingsOrderBy>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


export interface SubscriptionRootMeetingsAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingsOrderBy>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


export interface SubscriptionRootMeetingsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootMeetingsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeetingsStreamCursorInput>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


export interface SubscriptionRootNotificationArgs {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
}


export interface SubscriptionRootNotificationAggregateArgs {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
}


export interface SubscriptionRootNotificationByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootNotificationOriginArgs {
  distinct_on?: InputMaybe<Array<NotificationOriginSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOriginOrderBy>>;
  where?: InputMaybe<NotificationOriginBoolExp>;
}


export interface SubscriptionRootNotificationOriginAggregateArgs {
  distinct_on?: InputMaybe<Array<NotificationOriginSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOriginOrderBy>>;
  where?: InputMaybe<NotificationOriginBoolExp>;
}


export interface SubscriptionRootNotificationOriginByPkArgs {
  origin: Scalars['String']['input'];
}


export interface SubscriptionRootNotificationOriginStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NotificationOriginStreamCursorInput>>;
  where?: InputMaybe<NotificationOriginBoolExp>;
}


export interface SubscriptionRootNotificationStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NotificationStreamCursorInput>>;
  where?: InputMaybe<NotificationBoolExp>;
}


export interface SubscriptionRootNotificationTypeArgs {
  distinct_on?: InputMaybe<Array<NotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationTypeOrderBy>>;
  where?: InputMaybe<NotificationTypeBoolExp>;
}


export interface SubscriptionRootNotificationTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<NotificationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationTypeOrderBy>>;
  where?: InputMaybe<NotificationTypeBoolExp>;
}


export interface SubscriptionRootNotificationTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootNotificationTypeStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NotificationTypeStreamCursorInput>>;
  where?: InputMaybe<NotificationTypeBoolExp>;
}


export interface SubscriptionRootNrOfInstitutionsPerChapterArgs {
  args: NrOfInstitutionsPerChapterArguments;
  distinct_on?: InputMaybe<Array<NrOfInstitutionsPerChapterEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NrOfInstitutionsPerChapterOrderBy>>;
  where?: InputMaybe<NrOfInstitutionsPerChapterBoolExpBoolExp>;
}


export interface SubscriptionRootNrOfUsersPerInstitutionArgs {
  args: NrOfUsersPerInstitutionArguments;
  distinct_on?: InputMaybe<Array<NrOfInstitutionsPerChapterEnumName>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NrOfInstitutionsPerChapterOrderBy>>;
  where?: InputMaybe<NrOfInstitutionsPerChapterBoolExpBoolExp>;
}


export interface SubscriptionRootPkieAcquisArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisOrderBy>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


export interface SubscriptionRootPkieAcquisAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisOrderBy>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


export interface SubscriptionRootPkieAcquisByPkArgs {
  celex: Scalars['String']['input'];
}


export interface SubscriptionRootPkieAcquisChapterArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


export interface SubscriptionRootPkieAcquisChapterAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisChapterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisChapterOrderBy>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


export interface SubscriptionRootPkieAcquisChapterByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieAcquisChapterStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieAcquisChapterStreamCursorInput>>;
  where?: InputMaybe<PkieAcquisChapterBoolExp>;
}


export interface SubscriptionRootPkieAcquisInstitutionsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


export interface SubscriptionRootPkieAcquisInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisInstitutionsOrderBy>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


export interface SubscriptionRootPkieAcquisInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieAcquisInstitutionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieAcquisInstitutionsStreamCursorInput>>;
  where?: InputMaybe<PkieAcquisInstitutionsBoolExp>;
}


export interface SubscriptionRootPkieAcquisNationalActsArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


export interface SubscriptionRootPkieAcquisNationalActsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisNationalActsOrderBy>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


export interface SubscriptionRootPkieAcquisNationalActsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieAcquisNationalActsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieAcquisNationalActsStreamCursorInput>>;
  where?: InputMaybe<PkieAcquisNationalActsBoolExp>;
}


export interface SubscriptionRootPkieAcquisStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieAcquisStreamCursorInput>>;
  where?: InputMaybe<PkieAcquisBoolExp>;
}


export interface SubscriptionRootPkieAcquisTypeArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisTypeOrderBy>>;
  where?: InputMaybe<PkieAcquisTypeBoolExp>;
}


export interface SubscriptionRootPkieAcquisTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisTypeOrderBy>>;
  where?: InputMaybe<PkieAcquisTypeBoolExp>;
}


export interface SubscriptionRootPkieAcquisTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootPkieAcquisTypeStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieAcquisTypeStreamCursorInput>>;
  where?: InputMaybe<PkieAcquisTypeBoolExp>;
}


export interface SubscriptionRootPkieAcquisWorkPlanTaskArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


export interface SubscriptionRootPkieAcquisWorkPlanTaskAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieAcquisWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieAcquisWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


export interface SubscriptionRootPkieAcquisWorkPlanTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieAcquisWorkPlanTaskStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieAcquisWorkPlanTaskStreamCursorInput>>;
  where?: InputMaybe<PkieAcquisWorkPlanTaskBoolExp>;
}


export interface SubscriptionRootPkieActTypeArgs {
  distinct_on?: InputMaybe<Array<PkieActTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieActTypeOrderBy>>;
  where?: InputMaybe<PkieActTypeBoolExp>;
}


export interface SubscriptionRootPkieActTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieActTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieActTypeOrderBy>>;
  where?: InputMaybe<PkieActTypeBoolExp>;
}


export interface SubscriptionRootPkieActTypeByPkArgs {
  code: Scalars['String']['input'];
}


export interface SubscriptionRootPkieActTypeStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieActTypeStreamCursorInput>>;
  where?: InputMaybe<PkieActTypeBoolExp>;
}


export interface SubscriptionRootPkieLevelOfAlignmentArgs {
  distinct_on?: InputMaybe<Array<PkieLevelOfAlignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieLevelOfAlignmentOrderBy>>;
  where?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
}


export interface SubscriptionRootPkieLevelOfAlignmentAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieLevelOfAlignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieLevelOfAlignmentOrderBy>>;
  where?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
}


export interface SubscriptionRootPkieLevelOfAlignmentByPkArgs {
  level: Scalars['String']['input'];
}


export interface SubscriptionRootPkieLevelOfAlignmentStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieLevelOfAlignmentStreamCursorInput>>;
  where?: InputMaybe<PkieLevelOfAlignmentBoolExp>;
}


export interface SubscriptionRootPkieMeasureArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


export interface SubscriptionRootPkieMeasureActivityArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureActivityOrderBy>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


export interface SubscriptionRootPkieMeasureActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureActivityOrderBy>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


export interface SubscriptionRootPkieMeasureActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureActivityStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureActivityStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureActivityBoolExp>;
}


export interface SubscriptionRootPkieMeasureAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


export interface SubscriptionRootPkieMeasureAnswersArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureAnswersOrderBy>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


export interface SubscriptionRootPkieMeasureAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureAnswersOrderBy>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


export interface SubscriptionRootPkieMeasureAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureAnswersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureAnswersStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureAnswersBoolExp>;
}


export interface SubscriptionRootPkieMeasureBookmarkArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


export interface SubscriptionRootPkieMeasureBookmarkAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBookmarkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBookmarkOrderBy>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


export interface SubscriptionRootPkieMeasureBookmarkByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureBookmarkStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureBookmarkStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureBookmarkBoolExp>;
}


export interface SubscriptionRootPkieMeasureBudgetsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


export interface SubscriptionRootPkieMeasureBudgetsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureBudgetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureBudgetsOrderBy>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


export interface SubscriptionRootPkieMeasureBudgetsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureBudgetsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureBudgetsStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureBudgetsBoolExp>;
}


export interface SubscriptionRootPkieMeasureByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureCommentArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureCommentOrderBy>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


export interface SubscriptionRootPkieMeasureCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureCommentOrderBy>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


export interface SubscriptionRootPkieMeasureCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureCommentStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureCommentStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureCommentBoolExp>;
}


export interface SubscriptionRootPkieMeasureGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureGnpieMembersOrderBy>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


export interface SubscriptionRootPkieMeasureGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureGnpieMembersOrderBy>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


export interface SubscriptionRootPkieMeasureGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureGnpieMembersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureGnpieMembersStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureGnpieMembersBoolExp>;
}


export interface SubscriptionRootPkieMeasureRoadmapArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


export interface SubscriptionRootPkieMeasureRoadmapAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


export interface SubscriptionRootPkieMeasureRoadmapByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieMeasureRoadmapStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureRoadmapStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


export interface SubscriptionRootPkieMeasureStatusArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureStatusOrderBy>>;
  where?: InputMaybe<PkieMeasureStatusBoolExp>;
}


export interface SubscriptionRootPkieMeasureStatusAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureStatusOrderBy>>;
  where?: InputMaybe<PkieMeasureStatusBoolExp>;
}


export interface SubscriptionRootPkieMeasureStatusByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootPkieMeasureStatusStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureStatusStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureStatusBoolExp>;
}


export interface SubscriptionRootPkieMeasureStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieMeasureStreamCursorInput>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


export interface SubscriptionRootPkieNationalActsArgs {
  distinct_on?: InputMaybe<Array<PkieNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieNationalActsOrderBy>>;
  where?: InputMaybe<PkieNationalActsBoolExp>;
}


export interface SubscriptionRootPkieNationalActsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieNationalActsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieNationalActsOrderBy>>;
  where?: InputMaybe<PkieNationalActsBoolExp>;
}


export interface SubscriptionRootPkieNationalActsByPkArgs {
  eli: Scalars['String']['input'];
}


export interface SubscriptionRootPkieNationalActsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieNationalActsStreamCursorInput>>;
  where?: InputMaybe<PkieNationalActsBoolExp>;
}


export interface SubscriptionRootPkieProcesPuneIagaArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneIagaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


export interface SubscriptionRootPkieProcesPuneIagaAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneIagaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneIagaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


export interface SubscriptionRootPkieProcesPuneIagaByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieProcesPuneIagaStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieProcesPuneIagaStreamCursorInput>>;
  where?: InputMaybe<PkieProcesPuneIagaBoolExp>;
}


export interface SubscriptionRootPkieProcesPuneLgaArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneLgaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}


export interface SubscriptionRootPkieProcesPuneLgaAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieProcesPuneLgaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieProcesPuneLgaOrderBy>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}


export interface SubscriptionRootPkieProcesPuneLgaByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieProcesPuneLgaStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieProcesPuneLgaStreamCursorInput>>;
  where?: InputMaybe<PkieProcesPuneLgaBoolExp>;
}


export interface SubscriptionRootPkieSourceOfFundingArgs {
  distinct_on?: InputMaybe<Array<PkieSourceOfFundingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieSourceOfFundingOrderBy>>;
  where?: InputMaybe<PkieSourceOfFundingBoolExp>;
}


export interface SubscriptionRootPkieSourceOfFundingAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieSourceOfFundingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieSourceOfFundingOrderBy>>;
  where?: InputMaybe<PkieSourceOfFundingBoolExp>;
}


export interface SubscriptionRootPkieSourceOfFundingByPkArgs {
  source: Scalars['String']['input'];
}


export interface SubscriptionRootPkieSourceOfFundingStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieSourceOfFundingStreamCursorInput>>;
  where?: InputMaybe<PkieSourceOfFundingBoolExp>;
}


export interface SubscriptionRootPkieTypeAlignmentActArgs {
  distinct_on?: InputMaybe<Array<PkieTypeAlignmentActSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeAlignmentActOrderBy>>;
  where?: InputMaybe<PkieTypeAlignmentActBoolExp>;
}


export interface SubscriptionRootPkieTypeAlignmentActAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieTypeAlignmentActSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeAlignmentActOrderBy>>;
  where?: InputMaybe<PkieTypeAlignmentActBoolExp>;
}


export interface SubscriptionRootPkieTypeAlignmentActByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootPkieTypeAlignmentActStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieTypeAlignmentActStreamCursorInput>>;
  where?: InputMaybe<PkieTypeAlignmentActBoolExp>;
}


export interface SubscriptionRootPkieTypeMeasureArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBoolExp>;
}


export interface SubscriptionRootPkieTypeMeasureAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBoolExp>;
}


export interface SubscriptionRootPkieTypeMeasureBudgetArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureBudgetOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
}


export interface SubscriptionRootPkieTypeMeasureBudgetAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieTypeMeasureBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieTypeMeasureBudgetOrderBy>>;
  where?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
}


export interface SubscriptionRootPkieTypeMeasureBudgetByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootPkieTypeMeasureBudgetStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieTypeMeasureBudgetStreamCursorInput>>;
  where?: InputMaybe<PkieTypeMeasureBudgetBoolExp>;
}


export interface SubscriptionRootPkieTypeMeasureByPkArgs {
  measure: Scalars['String']['input'];
}


export interface SubscriptionRootPkieTypeMeasureStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieTypeMeasureStreamCursorInput>>;
  where?: InputMaybe<PkieTypeMeasureBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanCommentArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanCommentOrderBy>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanCommentOrderBy>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieWorkPlanCommentStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieWorkPlanCommentStreamCursorInput>>;
  where?: InputMaybe<PkieWorkPlanCommentBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanStatusesArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootPkieWorkPlanStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieWorkPlanStatusesStreamCursorInput>>;
  where?: InputMaybe<PkieWorkPlanStatusesBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskActivityArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskActivityOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieWorkPlanTaskActivityStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieWorkPlanTaskActivityStreamCursorInput>>;
  where?: InputMaybe<PkieWorkPlanTaskActivityBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieWorkPlanTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlanTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlanTaskStatusesOrderBy>>;
  where?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootPkieWorkPlanTaskStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieWorkPlanTaskStatusesStreamCursorInput>>;
  where?: InputMaybe<PkieWorkPlanTaskStatusesBoolExp>;
}


export interface SubscriptionRootPkieWorkPlanTaskStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieWorkPlanTaskStreamCursorInput>>;
  where?: InputMaybe<PkieWorkPlanTaskBoolExp>;
}


export interface SubscriptionRootPkieWorkPlansArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlansOrderBy>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}


export interface SubscriptionRootPkieWorkPlansAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieWorkPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieWorkPlansOrderBy>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}


export interface SubscriptionRootPkieWorkPlansByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootPkieWorkPlansStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PkieWorkPlansStreamCursorInput>>;
  where?: InputMaybe<PkieWorkPlansBoolExp>;
}


export interface SubscriptionRootQuestionTypesArgs {
  distinct_on?: InputMaybe<Array<QuestionTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypesOrderBy>>;
  where?: InputMaybe<QuestionTypesBoolExp>;
}


export interface SubscriptionRootQuestionTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<QuestionTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionTypesOrderBy>>;
  where?: InputMaybe<QuestionTypesBoolExp>;
}


export interface SubscriptionRootQuestionTypesByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootQuestionTypesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionTypesStreamCursorInput>>;
  where?: InputMaybe<QuestionTypesBoolExp>;
}


export interface SubscriptionRootQuestionsArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


export interface SubscriptionRootQuestionsAggregateArgs {
  distinct_on?: InputMaybe<Array<QuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionsOrderBy>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


export interface SubscriptionRootQuestionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootQuestionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionsStreamCursorInput>>;
  where?: InputMaybe<QuestionsBoolExp>;
}


export interface SubscriptionRootRecommendationsArgs {
  distinct_on?: InputMaybe<Array<RecommendationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RecommendationsOrderBy>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


export interface SubscriptionRootRecommendationsAggregateArgs {
  distinct_on?: InputMaybe<Array<RecommendationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RecommendationsOrderBy>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


export interface SubscriptionRootRecommendationsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootRecommendationsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RecommendationsStreamCursorInput>>;
  where?: InputMaybe<RecommendationsBoolExp>;
}


export interface SubscriptionRootReportAnswersArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


export interface SubscriptionRootReportAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


export interface SubscriptionRootReportAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportAnswersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportAnswersStreamCursorInput>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


export interface SubscriptionRootReportChapterStatusesArgs {
  distinct_on?: InputMaybe<Array<ReportChapterStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChapterStatusesOrderBy>>;
  where?: InputMaybe<ReportChapterStatusesBoolExp>;
}


export interface SubscriptionRootReportChapterStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChapterStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChapterStatusesOrderBy>>;
  where?: InputMaybe<ReportChapterStatusesBoolExp>;
}


export interface SubscriptionRootReportChapterStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootReportChapterStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportChapterStatusesStreamCursorInput>>;
  where?: InputMaybe<ReportChapterStatusesBoolExp>;
}


export interface SubscriptionRootReportChaptersArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


export interface SubscriptionRootReportChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportChaptersOrderBy>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


export interface SubscriptionRootReportChaptersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportChaptersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportChaptersStreamCursorInput>>;
  where?: InputMaybe<ReportChaptersBoolExp>;
}


export interface SubscriptionRootReportStatusesArgs {
  distinct_on?: InputMaybe<Array<ReportStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportStatusesOrderBy>>;
  where?: InputMaybe<ReportStatusesBoolExp>;
}


export interface SubscriptionRootReportStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportStatusesOrderBy>>;
  where?: InputMaybe<ReportStatusesBoolExp>;
}


export interface SubscriptionRootReportStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootReportStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportStatusesStreamCursorInput>>;
  where?: InputMaybe<ReportStatusesBoolExp>;
}


export interface SubscriptionRootReportTaskActivityArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


export interface SubscriptionRootReportTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


export interface SubscriptionRootReportTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportTaskActivityStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportTaskActivityStreamCursorInput>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


export interface SubscriptionRootReportTaskCommentArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


export interface SubscriptionRootReportTaskCommentAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


export interface SubscriptionRootReportTaskCommentByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportTaskCommentStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportTaskCommentStreamCursorInput>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


export interface SubscriptionRootReportTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskGnpieMembersOrderBy>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}


export interface SubscriptionRootReportTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskGnpieMembersOrderBy>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}


export interface SubscriptionRootReportTaskGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportTaskGnpieMembersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportTaskGnpieMembersStreamCursorInput>>;
  where?: InputMaybe<ReportTaskGnpieMembersBoolExp>;
}


export interface SubscriptionRootReportTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<ReportTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskStatusesOrderBy>>;
  where?: InputMaybe<ReportTaskStatusesBoolExp>;
}


export interface SubscriptionRootReportTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskStatusesOrderBy>>;
  where?: InputMaybe<ReportTaskStatusesBoolExp>;
}


export interface SubscriptionRootReportTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootReportTaskStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportTaskStatusesStreamCursorInput>>;
  where?: InputMaybe<ReportTaskStatusesBoolExp>;
}


export interface SubscriptionRootReportTasksArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


export interface SubscriptionRootReportTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTasksOrderBy>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


export interface SubscriptionRootReportTasksByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportTasksStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportTasksStreamCursorInput>>;
  where?: InputMaybe<ReportTasksBoolExp>;
}


export interface SubscriptionRootReportsArgs {
  distinct_on?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
}


export interface SubscriptionRootReportsAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
}


export interface SubscriptionRootReportsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootReportsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReportsStreamCursorInput>>;
  where?: InputMaybe<ReportsBoolExp>;
}


export interface SubscriptionRootRoadmapMeasureTypeArgs {
  distinct_on?: InputMaybe<Array<RoadmapMeasureTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoadmapMeasureTypeOrderBy>>;
  where?: InputMaybe<RoadmapMeasureTypeBoolExp>;
}


export interface SubscriptionRootRoadmapMeasureTypeAggregateArgs {
  distinct_on?: InputMaybe<Array<RoadmapMeasureTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoadmapMeasureTypeOrderBy>>;
  where?: InputMaybe<RoadmapMeasureTypeBoolExp>;
}


export interface SubscriptionRootRoadmapMeasureTypeByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootRoadmapMeasureTypeStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RoadmapMeasureTypeStreamCursorInput>>;
  where?: InputMaybe<RoadmapMeasureTypeBoolExp>;
}


export interface SubscriptionRootSignedDocumentsArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


export interface SubscriptionRootSignedDocumentsAggregateArgs {
  distinct_on?: InputMaybe<Array<SignedDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SignedDocumentsOrderBy>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


export interface SubscriptionRootSignedDocumentsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootSignedDocumentsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SignedDocumentsStreamCursorInput>>;
  where?: InputMaybe<SignedDocumentsBoolExp>;
}


export interface SubscriptionRootSubChapterLeadInstitutionsArgs {
  distinct_on?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


export interface SubscriptionRootSubChapterLeadInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<SubChapterLeadInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChapterLeadInstitutionsOrderBy>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


export interface SubscriptionRootSubChapterLeadInstitutionsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootSubChapterLeadInstitutionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SubChapterLeadInstitutionsStreamCursorInput>>;
  where?: InputMaybe<SubChapterLeadInstitutionsBoolExp>;
}


export interface SubscriptionRootSubChaptersArgs {
  distinct_on?: InputMaybe<Array<SubChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChaptersOrderBy>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


export interface SubscriptionRootSubChaptersAggregateArgs {
  distinct_on?: InputMaybe<Array<SubChaptersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SubChaptersOrderBy>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


export interface SubscriptionRootSubChaptersByPkArgs {
  id: Scalars['Int']['input'];
}


export interface SubscriptionRootSubChaptersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SubChaptersStreamCursorInput>>;
  where?: InputMaybe<SubChaptersBoolExp>;
}


export interface SubscriptionRootTaskArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


export interface SubscriptionRootTaskActivityArgs {
  distinct_on?: InputMaybe<Array<TaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskActivityOrderBy>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


export interface SubscriptionRootTaskActivityAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskActivityOrderBy>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


export interface SubscriptionRootTaskActivityByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootTaskActivityStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskActivityStreamCursorInput>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


export interface SubscriptionRootTaskAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


export interface SubscriptionRootTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskAnswersOrderBy>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


export interface SubscriptionRootTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskAnswersOrderBy>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


export interface SubscriptionRootTaskAnswersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootTaskAnswersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskAnswersStreamCursorInput>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


export interface SubscriptionRootTaskByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootTaskCommentsArgs {
  distinct_on?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskCommentsOrderBy>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


export interface SubscriptionRootTaskCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskCommentsOrderBy>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


export interface SubscriptionRootTaskCommentsByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootTaskCommentsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskCommentsStreamCursorInput>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


export interface SubscriptionRootTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskGnpieMembersOrderBy>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}


export interface SubscriptionRootTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskGnpieMembersOrderBy>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}


export interface SubscriptionRootTaskGnpieMembersByPkArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootTaskGnpieMembersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskGnpieMembersStreamCursorInput>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}


export interface SubscriptionRootTaskStatusesArgs {
  distinct_on?: InputMaybe<Array<TaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskStatusesOrderBy>>;
  where?: InputMaybe<TaskStatusesBoolExp>;
}


export interface SubscriptionRootTaskStatusesAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskStatusesOrderBy>>;
  where?: InputMaybe<TaskStatusesBoolExp>;
}


export interface SubscriptionRootTaskStatusesByPkArgs {
  status: Scalars['String']['input'];
}


export interface SubscriptionRootTaskStatusesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskStatusesStreamCursorInput>>;
  where?: InputMaybe<TaskStatusesBoolExp>;
}


export interface SubscriptionRootTaskStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskStreamCursorInput>>;
  where?: InputMaybe<TaskBoolExp>;
}


export interface SubscriptionRootTaskTypesArgs {
  distinct_on?: InputMaybe<Array<TaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskTypesOrderBy>>;
  where?: InputMaybe<TaskTypesBoolExp>;
}


export interface SubscriptionRootTaskTypesAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskTypesOrderBy>>;
  where?: InputMaybe<TaskTypesBoolExp>;
}


export interface SubscriptionRootTaskTypesByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootTaskTypesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaskTypesStreamCursorInput>>;
  where?: InputMaybe<TaskTypesBoolExp>;
}


export interface SubscriptionRootTopicsArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


export interface SubscriptionRootTopicsAggregateArgs {
  distinct_on?: InputMaybe<Array<TopicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TopicsOrderBy>>;
  where?: InputMaybe<TopicsBoolExp>;
}


export interface SubscriptionRootTopicsByPkArgs {
  id: Scalars['Int']['input'];
}


export interface SubscriptionRootTopicsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TopicsStreamCursorInput>>;
  where?: InputMaybe<TopicsBoolExp>;
}


export interface SubscriptionRootTypeInstitutionsArgs {
  distinct_on?: InputMaybe<Array<TypeInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TypeInstitutionsOrderBy>>;
  where?: InputMaybe<TypeInstitutionsBoolExp>;
}


export interface SubscriptionRootTypeInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<TypeInstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TypeInstitutionsOrderBy>>;
  where?: InputMaybe<TypeInstitutionsBoolExp>;
}


export interface SubscriptionRootTypeInstitutionsByPkArgs {
  type: Scalars['String']['input'];
}


export interface SubscriptionRootTypeInstitutionsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TypeInstitutionsStreamCursorInput>>;
  where?: InputMaybe<TypeInstitutionsBoolExp>;
}


export interface SubscriptionRootUserArgs {
  id: Scalars['uuid']['input'];
}


export interface SubscriptionRootUsersArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}


export interface SubscriptionRootUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
}


export interface SubscriptionRootUsersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
}


export interface SubscriptionRootYearlyQuartersArgs {
  distinct_on?: InputMaybe<Array<YearlyQuartersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<YearlyQuartersOrderBy>>;
  where?: InputMaybe<YearlyQuartersBoolExp>;
}


export interface SubscriptionRootYearlyQuartersAggregateArgs {
  distinct_on?: InputMaybe<Array<YearlyQuartersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<YearlyQuartersOrderBy>>;
  where?: InputMaybe<YearlyQuartersBoolExp>;
}


export interface SubscriptionRootYearlyQuartersByPkArgs {
  quarter: Scalars['String']['input'];
}


export interface SubscriptionRootYearlyQuartersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<YearlyQuartersStreamCursorInput>>;
  where?: InputMaybe<YearlyQuartersBoolExp>;
}

/** generic task */
export interface Task {
  __typename?: 'task';
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chapter_coordinator?: Maybe<Users>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  chief_gnpie_secretariat?: Maybe<Users>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  children: Array<Task>;
  /** An aggregate relationship */
  children_aggregate: TaskAggregate;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  deadline: Scalars['date']['output'];
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  document?: Maybe<Documents>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  event?: Maybe<Events>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_member?: Maybe<Users>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  gnpie_secretariat?: Maybe<Users>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  indicators?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  measure_roadmaps: Array<PkieMeasureRoadmap>;
  /** An aggregate relationship */
  measure_roadmaps_aggregate: PkieMeasureRoadmapAggregate;
  measure_type?: Maybe<PkieTypeMeasureEnum>;
  /** An object relationship */
  meeting?: Maybe<Meetings>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  negotiator?: Maybe<Users>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  parent?: Maybe<Task>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  performance?: Maybe<Scalars['String']['output']>;
  phase?: Maybe<IntegrationPhasesEnumEnum>;
  /** An object relationship */
  question?: Maybe<Questions>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  reporter?: Maybe<Users>;
  reporter_id?: Maybe<Scalars['uuid']['output']>;
  reporting_deadline?: Maybe<Scalars['date']['output']>;
  reporting_methods?: Maybe<Scalars['String']['output']>;
  roadmap_measure_type?: Maybe<RoadmapMeasureTypeEnum>;
  status: TaskStatusesEnum;
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  task_activities: Array<TaskActivity>;
  /** An aggregate relationship */
  task_activities_aggregate: TaskActivityAggregate;
  /** An array relationship */
  task_answers: Array<TaskAnswers>;
  /** An aggregate relationship */
  task_answers_aggregate: TaskAnswersAggregate;
  /** An array relationship */
  task_comments: Array<TaskComments>;
  /** An aggregate relationship */
  task_comments_aggregate: TaskCommentsAggregate;
  /** An array relationship */
  task_gnpie_members: Array<TaskGnpieMembers>;
  /** An aggregate relationship */
  task_gnpie_members_aggregate: TaskGnpieMembersAggregate;
  task_type: TaskTypesEnum;
  text?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  topic?: Maybe<Topics>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
}


/** generic task */
export interface TaskChildrenArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** generic task */
export interface TaskChildrenAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** generic task */
export interface TaskDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** generic task */
export interface TaskMeasureRoadmapsArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


/** generic task */
export interface TaskMeasureRoadmapsAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureRoadmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureRoadmapOrderBy>>;
  where?: InputMaybe<PkieMeasureRoadmapBoolExp>;
}


/** generic task */
export interface TaskTaskActivitiesArgs {
  distinct_on?: InputMaybe<Array<TaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskActivityOrderBy>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


/** generic task */
export interface TaskTaskActivitiesAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskActivityOrderBy>>;
  where?: InputMaybe<TaskActivityBoolExp>;
}


/** generic task */
export interface TaskTaskAnswersArgs {
  distinct_on?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskAnswersOrderBy>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


/** generic task */
export interface TaskTaskAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskAnswersOrderBy>>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}


/** generic task */
export interface TaskTaskCommentsArgs {
  distinct_on?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskCommentsOrderBy>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


/** generic task */
export interface TaskTaskCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskCommentsOrderBy>>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}


/** generic task */
export interface TaskTaskGnpieMembersArgs {
  distinct_on?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskGnpieMembersOrderBy>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}


/** generic task */
export interface TaskTaskGnpieMembersAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskGnpieMembersOrderBy>>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}

/** maintains activity for a generic task */
export interface TaskActivity {
  __typename?: 'task_activity';
  activity: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  status?: Maybe<TaskStatusesEnum>;
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "task_activity" */
export interface TaskActivityAggregate {
  __typename?: 'task_activity_aggregate';
  aggregate?: Maybe<TaskActivityAggregateFields>;
  nodes: Array<TaskActivity>;
}

export interface TaskActivityAggregateBoolExp {
  count?: InputMaybe<TaskActivityAggregateBoolExpCount>;
}

export interface TaskActivityAggregateBoolExpCount {
  arguments?: InputMaybe<Array<TaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TaskActivityBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "task_activity" */
export interface TaskActivityAggregateFields {
  __typename?: 'task_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaskActivityMaxFields>;
  min?: Maybe<TaskActivityMinFields>;
}


/** aggregate fields of "task_activity" */
export interface TaskActivityAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "task_activity" */
export interface TaskActivityAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaskActivityMaxOrderBy>;
  min?: InputMaybe<TaskActivityMinOrderBy>;
}

/** input type for inserting array relation for remote table "task_activity" */
export interface TaskActivityArrRelInsertInput {
  data: Array<TaskActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskActivityOnConflict>;
}

/** Boolean expression to filter rows from the table "task_activity". All fields are combined with a logical 'AND'. */
export interface TaskActivityBoolExp {
  _and?: InputMaybe<Array<TaskActivityBoolExp>>;
  _not?: InputMaybe<TaskActivityBoolExp>;
  _or?: InputMaybe<Array<TaskActivityBoolExp>>;
  activity?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  done_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<TaskStatusesEnumComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "task_activity" */
export enum TaskActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskActivityPkey = 'task_activity_pkey'
}

/** input type for inserting data into table "task_activity" */
export interface TaskActivityInsertInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<TaskStatusesEnum>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface TaskActivityMaxFields {
  __typename?: 'task_activity_max_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "task_activity" */
export interface TaskActivityMaxOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface TaskActivityMinFields {
  __typename?: 'task_activity_min_fields';
  activity?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  done_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "task_activity" */
export interface TaskActivityMinOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "task_activity" */
export interface TaskActivityMutationResponse {
  __typename?: 'task_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskActivity>;
}

/** on_conflict condition type for table "task_activity" */
export interface TaskActivityOnConflict {
  constraint: TaskActivityConstraint;
  update_columns?: Array<TaskActivityUpdateColumn>;
  where?: InputMaybe<TaskActivityBoolExp>;
}

/** Ordering options when selecting data from "task_activity". */
export interface TaskActivityOrderBy {
  activity?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  done_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task_activity */
export interface TaskActivityPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "task_activity" */
export enum TaskActivitySelectColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task_activity" */
export interface TaskActivitySetInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<TaskStatusesEnum>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "task_activity" */
export interface TaskActivityStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskActivityStreamCursorValueInput {
  activity?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  done_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<TaskStatusesEnum>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "task_activity" */
export enum TaskActivityUpdateColumn {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoneByUserId = 'done_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface TaskActivityUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskActivitySetInput>;
  /** filter the rows which have to be updated */
  where: TaskActivityBoolExp;
}

/** aggregated selection of "task" */
export interface TaskAggregate {
  __typename?: 'task_aggregate';
  aggregate?: Maybe<TaskAggregateFields>;
  nodes: Array<Task>;
}

export interface TaskAggregateBoolExp {
  count?: InputMaybe<TaskAggregateBoolExpCount>;
}

export interface TaskAggregateBoolExpCount {
  arguments?: InputMaybe<Array<TaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TaskBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "task" */
export interface TaskAggregateFields {
  __typename?: 'task_aggregate_fields';
  avg?: Maybe<TaskAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TaskMaxFields>;
  min?: Maybe<TaskMinFields>;
  stddev?: Maybe<TaskStddevFields>;
  stddev_pop?: Maybe<TaskStddevPopFields>;
  stddev_samp?: Maybe<TaskStddevSampFields>;
  sum?: Maybe<TaskSumFields>;
  var_pop?: Maybe<TaskVarPopFields>;
  var_samp?: Maybe<TaskVarSampFields>;
  variance?: Maybe<TaskVarianceFields>;
}


/** aggregate fields of "task" */
export interface TaskAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "task" */
export interface TaskAggregateOrderBy {
  avg?: InputMaybe<TaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaskMaxOrderBy>;
  min?: InputMaybe<TaskMinOrderBy>;
  stddev?: InputMaybe<TaskStddevOrderBy>;
  stddev_pop?: InputMaybe<TaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TaskStddevSampOrderBy>;
  sum?: InputMaybe<TaskSumOrderBy>;
  var_pop?: InputMaybe<TaskVarPopOrderBy>;
  var_samp?: InputMaybe<TaskVarSampOrderBy>;
  variance?: InputMaybe<TaskVarianceOrderBy>;
}

/** answers given for a generic task */
export interface TaskAnswers {
  __typename?: 'task_answers';
  answer: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  doc_ids?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  integration_phase?: Maybe<IntegrationPhasesEnumEnum>;
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}


/** answers given for a generic task */
export interface TaskAnswersDocIdsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "task_answers" */
export interface TaskAnswersAggregate {
  __typename?: 'task_answers_aggregate';
  aggregate?: Maybe<TaskAnswersAggregateFields>;
  nodes: Array<TaskAnswers>;
}

export interface TaskAnswersAggregateBoolExp {
  count?: InputMaybe<TaskAnswersAggregateBoolExpCount>;
}

export interface TaskAnswersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TaskAnswersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "task_answers" */
export interface TaskAnswersAggregateFields {
  __typename?: 'task_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaskAnswersMaxFields>;
  min?: Maybe<TaskAnswersMinFields>;
}


/** aggregate fields of "task_answers" */
export interface TaskAnswersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskAnswersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "task_answers" */
export interface TaskAnswersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaskAnswersMaxOrderBy>;
  min?: InputMaybe<TaskAnswersMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface TaskAnswersAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "task_answers" */
export interface TaskAnswersArrRelInsertInput {
  data: Array<TaskAnswersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskAnswersOnConflict>;
}

/** Boolean expression to filter rows from the table "task_answers". All fields are combined with a logical 'AND'. */
export interface TaskAnswersBoolExp {
  _and?: InputMaybe<Array<TaskAnswersBoolExp>>;
  _not?: InputMaybe<TaskAnswersBoolExp>;
  _or?: InputMaybe<Array<TaskAnswersBoolExp>>;
  answer?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumEnumComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "task_answers" */
export enum TaskAnswersConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskAnswersPkey = 'task_answers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface TaskAnswersDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface TaskAnswersDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface TaskAnswersDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "task_answers" */
export interface TaskAnswersInsertInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface TaskAnswersMaxFields {
  __typename?: 'task_answers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "task_answers" */
export interface TaskAnswersMaxOrderBy {
  answer?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface TaskAnswersMinFields {
  __typename?: 'task_answers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "task_answers" */
export interface TaskAnswersMinOrderBy {
  answer?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "task_answers" */
export interface TaskAnswersMutationResponse {
  __typename?: 'task_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskAnswers>;
}

/** on_conflict condition type for table "task_answers" */
export interface TaskAnswersOnConflict {
  constraint: TaskAnswersConstraint;
  update_columns?: Array<TaskAnswersUpdateColumn>;
  where?: InputMaybe<TaskAnswersBoolExp>;
}

/** Ordering options when selecting data from "task_answers". */
export interface TaskAnswersOrderBy {
  answer?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integration_phase?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task_answers */
export interface TaskAnswersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface TaskAnswersPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "task_answers" */
export enum TaskAnswersSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationPhase = 'integration_phase',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task_answers" */
export interface TaskAnswersSetInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "task_answers" */
export interface TaskAnswersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskAnswersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskAnswersStreamCursorValueInput {
  answer?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "task_answers" */
export enum TaskAnswersUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationPhase = 'integration_phase',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface TaskAnswersUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TaskAnswersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TaskAnswersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TaskAnswersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TaskAnswersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TaskAnswersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskAnswersSetInput>;
  /** filter the rows which have to be updated */
  where: TaskAnswersBoolExp;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface TaskAppendInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "task" */
export interface TaskArrRelInsertInput {
  data: Array<TaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskOnConflict>;
}

/** aggregate avg on columns */
export interface TaskAvgFields {
  __typename?: 'task_avg_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "task" */
export interface TaskAvgOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
export interface TaskBoolExp {
  _and?: InputMaybe<Array<TaskBoolExp>>;
  _not?: InputMaybe<TaskBoolExp>;
  _or?: InputMaybe<Array<TaskBoolExp>>;
  assignee?: InputMaybe<UsersBoolExp>;
  assignee_id?: InputMaybe<UuidComparisonExp>;
  chapter_coordinator?: InputMaybe<UsersBoolExp>;
  chapter_coordinator_id?: InputMaybe<UuidComparisonExp>;
  chief_gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  chief_gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  children?: InputMaybe<TaskBoolExp>;
  children_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  completition_date?: InputMaybe<DateComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  deadline?: InputMaybe<DateComparisonExp>;
  doc_ids?: InputMaybe<JsonbComparisonExp>;
  document?: InputMaybe<DocumentsBoolExp>;
  document_id?: InputMaybe<UuidComparisonExp>;
  event?: InputMaybe<EventsBoolExp>;
  event_id?: InputMaybe<UuidComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  gnpie_secretariat?: InputMaybe<UsersBoolExp>;
  gnpie_secretariat_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  indicators?: InputMaybe<StringComparisonExp>;
  measure_roadmaps?: InputMaybe<PkieMeasureRoadmapBoolExp>;
  measure_roadmaps_aggregate?: InputMaybe<PkieMeasureRoadmapAggregateBoolExp>;
  measure_type?: InputMaybe<PkieTypeMeasureEnumComparisonExp>;
  meeting?: InputMaybe<MeetingsBoolExp>;
  meeting_id?: InputMaybe<UuidComparisonExp>;
  negotiator?: InputMaybe<UsersBoolExp>;
  negotiator_id?: InputMaybe<UuidComparisonExp>;
  parent?: InputMaybe<TaskBoolExp>;
  parent_id?: InputMaybe<UuidComparisonExp>;
  performance?: InputMaybe<StringComparisonExp>;
  phase?: InputMaybe<IntegrationPhasesEnumEnumComparisonExp>;
  question?: InputMaybe<QuestionsBoolExp>;
  question_id?: InputMaybe<UuidComparisonExp>;
  reporter?: InputMaybe<UsersBoolExp>;
  reporter_id?: InputMaybe<UuidComparisonExp>;
  reporting_deadline?: InputMaybe<DateComparisonExp>;
  reporting_methods?: InputMaybe<StringComparisonExp>;
  roadmap_measure_type?: InputMaybe<RoadmapMeasureTypeEnumComparisonExp>;
  status?: InputMaybe<TaskStatusesEnumComparisonExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  subchapter_id?: InputMaybe<IntComparisonExp>;
  task_activities?: InputMaybe<TaskActivityBoolExp>;
  task_activities_aggregate?: InputMaybe<TaskActivityAggregateBoolExp>;
  task_answers?: InputMaybe<TaskAnswersBoolExp>;
  task_answers_aggregate?: InputMaybe<TaskAnswersAggregateBoolExp>;
  task_comments?: InputMaybe<TaskCommentsBoolExp>;
  task_comments_aggregate?: InputMaybe<TaskCommentsAggregateBoolExp>;
  task_gnpie_members?: InputMaybe<TaskGnpieMembersBoolExp>;
  task_gnpie_members_aggregate?: InputMaybe<TaskGnpieMembersAggregateBoolExp>;
  task_type?: InputMaybe<TaskTypesEnumComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  topic?: InputMaybe<TopicsBoolExp>;
  topic_id?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** comments of the generic tasks */
export interface TaskComments {
  __typename?: 'task_comments';
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by_user: Users;
  created_by_user_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "task_comments" */
export interface TaskCommentsAggregate {
  __typename?: 'task_comments_aggregate';
  aggregate?: Maybe<TaskCommentsAggregateFields>;
  nodes: Array<TaskComments>;
}

export interface TaskCommentsAggregateBoolExp {
  count?: InputMaybe<TaskCommentsAggregateBoolExpCount>;
}

export interface TaskCommentsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TaskCommentsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "task_comments" */
export interface TaskCommentsAggregateFields {
  __typename?: 'task_comments_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaskCommentsMaxFields>;
  min?: Maybe<TaskCommentsMinFields>;
}


/** aggregate fields of "task_comments" */
export interface TaskCommentsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskCommentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "task_comments" */
export interface TaskCommentsAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaskCommentsMaxOrderBy>;
  min?: InputMaybe<TaskCommentsMinOrderBy>;
}

/** input type for inserting array relation for remote table "task_comments" */
export interface TaskCommentsArrRelInsertInput {
  data: Array<TaskCommentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskCommentsOnConflict>;
}

/** Boolean expression to filter rows from the table "task_comments". All fields are combined with a logical 'AND'. */
export interface TaskCommentsBoolExp {
  _and?: InputMaybe<Array<TaskCommentsBoolExp>>;
  _not?: InputMaybe<TaskCommentsBoolExp>;
  _or?: InputMaybe<Array<TaskCommentsBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  created_by_user?: InputMaybe<UsersBoolExp>;
  created_by_user_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "task_comments" */
export enum TaskCommentsConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskCommentsPkey = 'task_comments_pkey'
}

/** input type for inserting data into table "task_comments" */
export interface TaskCommentsInsertInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface TaskCommentsMaxFields {
  __typename?: 'task_comments_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "task_comments" */
export interface TaskCommentsMaxOrderBy {
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface TaskCommentsMinFields {
  __typename?: 'task_comments_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "task_comments" */
export interface TaskCommentsMinOrderBy {
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "task_comments" */
export interface TaskCommentsMutationResponse {
  __typename?: 'task_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskComments>;
}

/** on_conflict condition type for table "task_comments" */
export interface TaskCommentsOnConflict {
  constraint: TaskCommentsConstraint;
  update_columns?: Array<TaskCommentsUpdateColumn>;
  where?: InputMaybe<TaskCommentsBoolExp>;
}

/** Ordering options when selecting data from "task_comments". */
export interface TaskCommentsOrderBy {
  comment?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task_comments */
export interface TaskCommentsPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "task_comments" */
export enum TaskCommentsSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task_comments" */
export interface TaskCommentsSetInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "task_comments" */
export interface TaskCommentsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskCommentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskCommentsStreamCursorValueInput {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "task_comments" */
export enum TaskCommentsUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface TaskCommentsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskCommentsSetInput>;
  /** filter the rows which have to be updated */
  where: TaskCommentsBoolExp;
}

/** unique or primary key constraints on table "task" */
export enum TaskConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface TaskDeleteAtPathInput {
  doc_ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface TaskDeleteElemInput {
  doc_ids?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface TaskDeleteKeyInput {
  doc_ids?: InputMaybe<Scalars['String']['input']>;
}

/** Many to many relation for roadmap tasks and members */
export interface TaskGnpieMembers {
  __typename?: 'task_gnpie_members';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  gnpie_member: Users;
  gnpie_member_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Task;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
}

/** aggregated selection of "task_gnpie_members" */
export interface TaskGnpieMembersAggregate {
  __typename?: 'task_gnpie_members_aggregate';
  aggregate?: Maybe<TaskGnpieMembersAggregateFields>;
  nodes: Array<TaskGnpieMembers>;
}

export interface TaskGnpieMembersAggregateBoolExp {
  count?: InputMaybe<TaskGnpieMembersAggregateBoolExpCount>;
}

export interface TaskGnpieMembersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TaskGnpieMembersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "task_gnpie_members" */
export interface TaskGnpieMembersAggregateFields {
  __typename?: 'task_gnpie_members_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaskGnpieMembersMaxFields>;
  min?: Maybe<TaskGnpieMembersMinFields>;
}


/** aggregate fields of "task_gnpie_members" */
export interface TaskGnpieMembersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskGnpieMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "task_gnpie_members" */
export interface TaskGnpieMembersAggregateOrderBy {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaskGnpieMembersMaxOrderBy>;
  min?: InputMaybe<TaskGnpieMembersMinOrderBy>;
}

/** input type for inserting array relation for remote table "task_gnpie_members" */
export interface TaskGnpieMembersArrRelInsertInput {
  data: Array<TaskGnpieMembersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskGnpieMembersOnConflict>;
}

/** Boolean expression to filter rows from the table "task_gnpie_members". All fields are combined with a logical 'AND'. */
export interface TaskGnpieMembersBoolExp {
  _and?: InputMaybe<Array<TaskGnpieMembersBoolExp>>;
  _not?: InputMaybe<TaskGnpieMembersBoolExp>;
  _or?: InputMaybe<Array<TaskGnpieMembersBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  gnpie_member?: InputMaybe<UsersBoolExp>;
  gnpie_member_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  task_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "task_gnpie_members" */
export enum TaskGnpieMembersConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskGnpieMembersPkey = 'task_gnpie_members_pkey',
  /** unique or primary key constraint on columns "task_id", "gnpie_member_id" */
  TaskGnpieMembersTaskIdGnpieMemberIdKey = 'task_gnpie_members_task_id_gnpie_member_id_key'
}

/** input type for inserting data into table "task_gnpie_members" */
export interface TaskGnpieMembersInsertInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface TaskGnpieMembersMaxFields {
  __typename?: 'task_gnpie_members_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "task_gnpie_members" */
export interface TaskGnpieMembersMaxOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface TaskGnpieMembersMinFields {
  __typename?: 'task_gnpie_members_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "task_gnpie_members" */
export interface TaskGnpieMembersMinOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "task_gnpie_members" */
export interface TaskGnpieMembersMutationResponse {
  __typename?: 'task_gnpie_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskGnpieMembers>;
}

/** on_conflict condition type for table "task_gnpie_members" */
export interface TaskGnpieMembersOnConflict {
  constraint: TaskGnpieMembersConstraint;
  update_columns?: Array<TaskGnpieMembersUpdateColumn>;
  where?: InputMaybe<TaskGnpieMembersBoolExp>;
}

/** Ordering options when selecting data from "task_gnpie_members". */
export interface TaskGnpieMembersOrderBy {
  created_at?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  task_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task_gnpie_members */
export interface TaskGnpieMembersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** select columns of table "task_gnpie_members" */
export enum TaskGnpieMembersSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task_gnpie_members" */
export interface TaskGnpieMembersSetInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "task_gnpie_members" */
export interface TaskGnpieMembersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskGnpieMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskGnpieMembersStreamCursorValueInput {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "task_gnpie_members" */
export enum TaskGnpieMembersUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface TaskGnpieMembersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskGnpieMembersSetInput>;
  /** filter the rows which have to be updated */
  where: TaskGnpieMembersBoolExp;
}

/** input type for incrementing numeric columns in table "task" */
export interface TaskIncInput {
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "task" */
export interface TaskInsertInput {
  assignee?: InputMaybe<UsersObjRelInsertInput>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator?: InputMaybe<UsersObjRelInsertInput>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  children?: InputMaybe<TaskArrRelInsertInput>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user?: InputMaybe<UsersObjRelInsertInput>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document?: InputMaybe<DocumentsObjRelInsertInput>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  event?: InputMaybe<EventsObjRelInsertInput>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat?: InputMaybe<UsersObjRelInsertInput>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  indicators?: InputMaybe<Scalars['String']['input']>;
  measure_roadmaps?: InputMaybe<PkieMeasureRoadmapArrRelInsertInput>;
  measure_type?: InputMaybe<PkieTypeMeasureEnum>;
  meeting?: InputMaybe<MeetingsObjRelInsertInput>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator?: InputMaybe<UsersObjRelInsertInput>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  parent?: InputMaybe<TaskObjRelInsertInput>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  performance?: InputMaybe<Scalars['String']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  question?: InputMaybe<QuestionsObjRelInsertInput>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter?: InputMaybe<UsersObjRelInsertInput>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  reporting_deadline?: InputMaybe<Scalars['date']['input']>;
  reporting_methods?: InputMaybe<Scalars['String']['input']>;
  roadmap_measure_type?: InputMaybe<RoadmapMeasureTypeEnum>;
  status?: InputMaybe<TaskStatusesEnum>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  task_activities?: InputMaybe<TaskActivityArrRelInsertInput>;
  task_answers?: InputMaybe<TaskAnswersArrRelInsertInput>;
  task_comments?: InputMaybe<TaskCommentsArrRelInsertInput>;
  task_gnpie_members?: InputMaybe<TaskGnpieMembersArrRelInsertInput>;
  task_type?: InputMaybe<TaskTypesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<TopicsObjRelInsertInput>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface TaskMaxFields {
  __typename?: 'task_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  indicators?: Maybe<Scalars['String']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  performance?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  reporter_id?: Maybe<Scalars['uuid']['output']>;
  reporting_deadline?: Maybe<Scalars['date']['output']>;
  reporting_methods?: Maybe<Scalars['String']['output']>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "task" */
export interface TaskMaxOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  document_id?: InputMaybe<OrderBy>;
  event_id?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indicators?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  performance?: InputMaybe<OrderBy>;
  question_id?: InputMaybe<OrderBy>;
  reporter_id?: InputMaybe<OrderBy>;
  reporting_deadline?: InputMaybe<OrderBy>;
  reporting_methods?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface TaskMinFields {
  __typename?: 'task_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  chapter_coordinator_id?: Maybe<Scalars['uuid']['output']>;
  chief_gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  completition_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deadline?: Maybe<Scalars['date']['output']>;
  document_id?: Maybe<Scalars['uuid']['output']>;
  event_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_member_id?: Maybe<Scalars['uuid']['output']>;
  gnpie_secretariat_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  indicators?: Maybe<Scalars['String']['output']>;
  meeting_id?: Maybe<Scalars['uuid']['output']>;
  negotiator_id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  performance?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  reporter_id?: Maybe<Scalars['uuid']['output']>;
  reporting_deadline?: Maybe<Scalars['date']['output']>;
  reporting_methods?: Maybe<Scalars['String']['output']>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "task" */
export interface TaskMinOrderBy {
  assignee_id?: InputMaybe<OrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  document_id?: InputMaybe<OrderBy>;
  event_id?: InputMaybe<OrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indicators?: InputMaybe<OrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  performance?: InputMaybe<OrderBy>;
  question_id?: InputMaybe<OrderBy>;
  reporter_id?: InputMaybe<OrderBy>;
  reporting_deadline?: InputMaybe<OrderBy>;
  reporting_methods?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "task" */
export interface TaskMutationResponse {
  __typename?: 'task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Task>;
}

/** input type for inserting object relation for remote table "task" */
export interface TaskObjRelInsertInput {
  data: TaskInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TaskOnConflict>;
}

/** on_conflict condition type for table "task" */
export interface TaskOnConflict {
  constraint: TaskConstraint;
  update_columns?: Array<TaskUpdateColumn>;
  where?: InputMaybe<TaskBoolExp>;
}

/** Ordering options when selecting data from "task". */
export interface TaskOrderBy {
  assignee?: InputMaybe<UsersOrderBy>;
  assignee_id?: InputMaybe<OrderBy>;
  chapter_coordinator?: InputMaybe<UsersOrderBy>;
  chapter_coordinator_id?: InputMaybe<OrderBy>;
  chief_gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  chief_gnpie_secretariat_id?: InputMaybe<OrderBy>;
  children_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  completition_date?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  created_by_user?: InputMaybe<UsersOrderBy>;
  created_by_user_id?: InputMaybe<OrderBy>;
  deadline?: InputMaybe<OrderBy>;
  doc_ids?: InputMaybe<OrderBy>;
  document?: InputMaybe<DocumentsOrderBy>;
  document_id?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventsOrderBy>;
  event_id?: InputMaybe<OrderBy>;
  gnpie_member?: InputMaybe<UsersOrderBy>;
  gnpie_member_id?: InputMaybe<OrderBy>;
  gnpie_secretariat?: InputMaybe<UsersOrderBy>;
  gnpie_secretariat_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indicators?: InputMaybe<OrderBy>;
  measure_roadmaps_aggregate?: InputMaybe<PkieMeasureRoadmapAggregateOrderBy>;
  measure_type?: InputMaybe<OrderBy>;
  meeting?: InputMaybe<MeetingsOrderBy>;
  meeting_id?: InputMaybe<OrderBy>;
  negotiator?: InputMaybe<UsersOrderBy>;
  negotiator_id?: InputMaybe<OrderBy>;
  parent?: InputMaybe<TaskOrderBy>;
  parent_id?: InputMaybe<OrderBy>;
  performance?: InputMaybe<OrderBy>;
  phase?: InputMaybe<OrderBy>;
  question?: InputMaybe<QuestionsOrderBy>;
  question_id?: InputMaybe<OrderBy>;
  reporter?: InputMaybe<UsersOrderBy>;
  reporter_id?: InputMaybe<OrderBy>;
  reporting_deadline?: InputMaybe<OrderBy>;
  reporting_methods?: InputMaybe<OrderBy>;
  roadmap_measure_type?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
  task_activities_aggregate?: InputMaybe<TaskActivityAggregateOrderBy>;
  task_answers_aggregate?: InputMaybe<TaskAnswersAggregateOrderBy>;
  task_comments_aggregate?: InputMaybe<TaskCommentsAggregateOrderBy>;
  task_gnpie_members_aggregate?: InputMaybe<TaskGnpieMembersAggregateOrderBy>;
  task_type?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  topic?: InputMaybe<TopicsOrderBy>;
  topic_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task */
export interface TaskPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface TaskPrependInput {
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "task" */
export enum TaskSelectColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  Indicators = 'indicators',
  /** column name */
  MeasureType = 'measure_type',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Performance = 'performance',
  /** column name */
  Phase = 'phase',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  ReportingDeadline = 'reporting_deadline',
  /** column name */
  ReportingMethods = 'reporting_methods',
  /** column name */
  RoadmapMeasureType = 'roadmap_measure_type',
  /** column name */
  Status = 'status',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "task" */
export interface TaskSetInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  indicators?: InputMaybe<Scalars['String']['input']>;
  measure_type?: InputMaybe<PkieTypeMeasureEnum>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  performance?: InputMaybe<Scalars['String']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  reporting_deadline?: InputMaybe<Scalars['date']['input']>;
  reporting_methods?: InputMaybe<Scalars['String']['input']>;
  roadmap_measure_type?: InputMaybe<RoadmapMeasureTypeEnum>;
  status?: InputMaybe<TaskStatusesEnum>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  task_type?: InputMaybe<TaskTypesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** statuses of generic tasks */
export interface TaskStatuses {
  __typename?: 'task_statuses';
  status: Scalars['String']['output'];
}

/** aggregated selection of "task_statuses" */
export interface TaskStatusesAggregate {
  __typename?: 'task_statuses_aggregate';
  aggregate?: Maybe<TaskStatusesAggregateFields>;
  nodes: Array<TaskStatuses>;
}

/** aggregate fields of "task_statuses" */
export interface TaskStatusesAggregateFields {
  __typename?: 'task_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaskStatusesMaxFields>;
  min?: Maybe<TaskStatusesMinFields>;
}


/** aggregate fields of "task_statuses" */
export interface TaskStatusesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "task_statuses". All fields are combined with a logical 'AND'. */
export interface TaskStatusesBoolExp {
  _and?: InputMaybe<Array<TaskStatusesBoolExp>>;
  _not?: InputMaybe<TaskStatusesBoolExp>;
  _or?: InputMaybe<Array<TaskStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "task_statuses" */
export enum TaskStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  TaskStatusesPkey = 'task_statuses_pkey'
}

export enum TaskStatusesEnum {
  Canceled = 'CANCELED',
  Done = 'DONE',
  Draft = 'DRAFT',
  Ongoing = 'ONGOING',
  WaitingForAssignee = 'WAITING_FOR_ASSIGNEE',
  WaitingForChiefGnpieSecretariat = 'WAITING_FOR_CHIEF_GNPIE_SECRETARIAT',
  WaitingForCoordinator = 'WAITING_FOR_COORDINATOR',
  WaitingForGnpieMember = 'WAITING_FOR_GNPIE_MEMBER',
  WaitingForGnpieSecretariat = 'WAITING_FOR_GNPIE_SECRETARIAT',
  WaitingForNegotiator = 'WAITING_FOR_NEGOTIATOR',
  WaitingForPmo = 'WAITING_FOR_PMO',
  WaitingForReporter = 'WAITING_FOR_REPORTER',
  WaitingForSignature = 'WAITING_FOR_SIGNATURE'
}

/** Boolean expression to compare columns of type "task_statuses_enum". All fields are combined with logical 'AND'. */
export interface TaskStatusesEnumComparisonExp {
  _eq?: InputMaybe<TaskStatusesEnum>;
  _in?: InputMaybe<Array<TaskStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TaskStatusesEnum>;
  _nin?: InputMaybe<Array<TaskStatusesEnum>>;
}

/** input type for inserting data into table "task_statuses" */
export interface TaskStatusesInsertInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface TaskStatusesMaxFields {
  __typename?: 'task_statuses_max_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface TaskStatusesMinFields {
  __typename?: 'task_statuses_min_fields';
  status?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "task_statuses" */
export interface TaskStatusesMutationResponse {
  __typename?: 'task_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskStatuses>;
}

/** on_conflict condition type for table "task_statuses" */
export interface TaskStatusesOnConflict {
  constraint: TaskStatusesConstraint;
  update_columns?: Array<TaskStatusesUpdateColumn>;
  where?: InputMaybe<TaskStatusesBoolExp>;
}

/** Ordering options when selecting data from "task_statuses". */
export interface TaskStatusesOrderBy {
  status?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task_statuses */
export interface TaskStatusesPkColumnsInput {
  status: Scalars['String']['input'];
}

/** select columns of table "task_statuses" */
export enum TaskStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "task_statuses" */
export interface TaskStatusesSetInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "task_statuses" */
export interface TaskStatusesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskStatusesStreamCursorValueInput {
  status?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "task_statuses" */
export enum TaskStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export interface TaskStatusesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: TaskStatusesBoolExp;
}

/** aggregate stddev on columns */
export interface TaskStddevFields {
  __typename?: 'task_stddev_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "task" */
export interface TaskStddevOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface TaskStddevPopFields {
  __typename?: 'task_stddev_pop_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "task" */
export interface TaskStddevPopOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface TaskStddevSampFields {
  __typename?: 'task_stddev_samp_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "task" */
export interface TaskStddevSampOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "task" */
export interface TaskStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskStreamCursorValueInput {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  chapter_coordinator_id?: InputMaybe<Scalars['uuid']['input']>;
  chief_gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  completition_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deadline?: InputMaybe<Scalars['date']['input']>;
  doc_ids?: InputMaybe<Scalars['jsonb']['input']>;
  document_id?: InputMaybe<Scalars['uuid']['input']>;
  event_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_member_id?: InputMaybe<Scalars['uuid']['input']>;
  gnpie_secretariat_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  indicators?: InputMaybe<Scalars['String']['input']>;
  measure_type?: InputMaybe<PkieTypeMeasureEnum>;
  meeting_id?: InputMaybe<Scalars['uuid']['input']>;
  negotiator_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  performance?: InputMaybe<Scalars['String']['input']>;
  phase?: InputMaybe<IntegrationPhasesEnumEnum>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  reporter_id?: InputMaybe<Scalars['uuid']['input']>;
  reporting_deadline?: InputMaybe<Scalars['date']['input']>;
  reporting_methods?: InputMaybe<Scalars['String']['input']>;
  roadmap_measure_type?: InputMaybe<RoadmapMeasureTypeEnum>;
  status?: InputMaybe<TaskStatusesEnum>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  task_type?: InputMaybe<TaskTypesEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
  topic_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface TaskSumFields {
  __typename?: 'task_sum_fields';
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  topic_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "task" */
export interface TaskSumOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** types of generic tasks */
export interface TaskTypes {
  __typename?: 'task_types';
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasks_aggregate: TaskAggregate;
  type: Scalars['String']['output'];
}


/** types of generic tasks */
export interface TaskTypesTasksArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** types of generic tasks */
export interface TaskTypesTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}

/** aggregated selection of "task_types" */
export interface TaskTypesAggregate {
  __typename?: 'task_types_aggregate';
  aggregate?: Maybe<TaskTypesAggregateFields>;
  nodes: Array<TaskTypes>;
}

/** aggregate fields of "task_types" */
export interface TaskTypesAggregateFields {
  __typename?: 'task_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaskTypesMaxFields>;
  min?: Maybe<TaskTypesMinFields>;
}


/** aggregate fields of "task_types" */
export interface TaskTypesAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TaskTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "task_types". All fields are combined with a logical 'AND'. */
export interface TaskTypesBoolExp {
  _and?: InputMaybe<Array<TaskTypesBoolExp>>;
  _not?: InputMaybe<TaskTypesBoolExp>;
  _or?: InputMaybe<Array<TaskTypesBoolExp>>;
  tasks?: InputMaybe<TaskBoolExp>;
  tasks_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "task_types" */
export enum TaskTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  TaskTypesPkey = 'task_types_pkey'
}

export enum TaskTypesEnum {
  Event = 'EVENT',
  Integration = 'INTEGRATION',
  MeetingTask = 'MEETING_TASK',
  Roadmap = 'ROADMAP'
}

/** Boolean expression to compare columns of type "task_types_enum". All fields are combined with logical 'AND'. */
export interface TaskTypesEnumComparisonExp {
  _eq?: InputMaybe<TaskTypesEnum>;
  _in?: InputMaybe<Array<TaskTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TaskTypesEnum>;
  _nin?: InputMaybe<Array<TaskTypesEnum>>;
}

/** input type for inserting data into table "task_types" */
export interface TaskTypesInsertInput {
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface TaskTypesMaxFields {
  __typename?: 'task_types_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface TaskTypesMinFields {
  __typename?: 'task_types_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "task_types" */
export interface TaskTypesMutationResponse {
  __typename?: 'task_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskTypes>;
}

/** on_conflict condition type for table "task_types" */
export interface TaskTypesOnConflict {
  constraint: TaskTypesConstraint;
  update_columns?: Array<TaskTypesUpdateColumn>;
  where?: InputMaybe<TaskTypesBoolExp>;
}

/** Ordering options when selecting data from "task_types". */
export interface TaskTypesOrderBy {
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: task_types */
export interface TaskTypesPkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "task_types" */
export enum TaskTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "task_types" */
export interface TaskTypesSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "task_types" */
export interface TaskTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TaskTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TaskTypesStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "task_types" */
export enum TaskTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface TaskTypesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskTypesSetInput>;
  /** filter the rows which have to be updated */
  where: TaskTypesBoolExp;
}

/** update columns of table "task" */
export enum TaskUpdateColumn {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ChapterCoordinatorId = 'chapter_coordinator_id',
  /** column name */
  ChiefGnpieSecretariatId = 'chief_gnpie_secretariat_id',
  /** column name */
  CompletitionDate = 'completition_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DocIds = 'doc_ids',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GnpieMemberId = 'gnpie_member_id',
  /** column name */
  GnpieSecretariatId = 'gnpie_secretariat_id',
  /** column name */
  Id = 'id',
  /** column name */
  Indicators = 'indicators',
  /** column name */
  MeasureType = 'measure_type',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  NegotiatorId = 'negotiator_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Performance = 'performance',
  /** column name */
  Phase = 'phase',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  ReportingDeadline = 'reporting_deadline',
  /** column name */
  ReportingMethods = 'reporting_methods',
  /** column name */
  RoadmapMeasureType = 'roadmap_measure_type',
  /** column name */
  Status = 'status',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  Text = 'text',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface TaskUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TaskAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TaskDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TaskDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TaskDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TaskIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TaskPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskSetInput>;
  /** filter the rows which have to be updated */
  where: TaskBoolExp;
}

/** aggregate var_pop on columns */
export interface TaskVarPopFields {
  __typename?: 'task_var_pop_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "task" */
export interface TaskVarPopOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface TaskVarSampFields {
  __typename?: 'task_var_samp_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "task" */
export interface TaskVarSampOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface TaskVarianceFields {
  __typename?: 'task_variance_fields';
  subchapter_id?: Maybe<Scalars['Float']['output']>;
  topic_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "task" */
export interface TaskVarianceOrderBy {
  subchapter_id?: InputMaybe<OrderBy>;
  topic_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export interface TimeComparisonExp {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface TimestampComparisonExp {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface TimestamptzComparisonExp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

/** Topic is under sub-chapter completing backbone */
export interface Topics {
  __typename?: 'topics';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  bookmarks: Array<Bookmarks>;
  /** An aggregate relationship */
  bookmarks_aggregate: BookmarksAggregate;
  /** An object relationship */
  chapter?: Maybe<Chapters>;
  chapter_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  gnpies: Array<Gnpie>;
  /** An aggregate relationship */
  gnpies_aggregate: GnpieAggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  sub_chapter?: Maybe<SubChapters>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  title_en?: Maybe<Scalars['String']['output']>;
}


/** Topic is under sub-chapter completing backbone */
export interface TopicsBookmarksArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** Topic is under sub-chapter completing backbone */
export interface TopicsBookmarksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarksOrderBy>>;
  where?: InputMaybe<BookmarksBoolExp>;
}


/** Topic is under sub-chapter completing backbone */
export interface TopicsGnpiesArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** Topic is under sub-chapter completing backbone */
export interface TopicsGnpiesAggregateArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}

/** aggregated selection of "topics" */
export interface TopicsAggregate {
  __typename?: 'topics_aggregate';
  aggregate?: Maybe<TopicsAggregateFields>;
  nodes: Array<Topics>;
}

export interface TopicsAggregateBoolExp {
  bool_and?: InputMaybe<TopicsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<TopicsAggregateBoolExpBoolOr>;
  count?: InputMaybe<TopicsAggregateBoolExpCount>;
}

export interface TopicsAggregateBoolExpBoolAnd {
  arguments: TopicsSelectColumnTopicsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TopicsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface TopicsAggregateBoolExpBoolOr {
  arguments: TopicsSelectColumnTopicsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TopicsBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface TopicsAggregateBoolExpCount {
  arguments?: InputMaybe<Array<TopicsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TopicsBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "topics" */
export interface TopicsAggregateFields {
  __typename?: 'topics_aggregate_fields';
  avg?: Maybe<TopicsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TopicsMaxFields>;
  min?: Maybe<TopicsMinFields>;
  stddev?: Maybe<TopicsStddevFields>;
  stddev_pop?: Maybe<TopicsStddevPopFields>;
  stddev_samp?: Maybe<TopicsStddevSampFields>;
  sum?: Maybe<TopicsSumFields>;
  var_pop?: Maybe<TopicsVarPopFields>;
  var_samp?: Maybe<TopicsVarSampFields>;
  variance?: Maybe<TopicsVarianceFields>;
}


/** aggregate fields of "topics" */
export interface TopicsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TopicsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "topics" */
export interface TopicsAggregateOrderBy {
  avg?: InputMaybe<TopicsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TopicsMaxOrderBy>;
  min?: InputMaybe<TopicsMinOrderBy>;
  stddev?: InputMaybe<TopicsStddevOrderBy>;
  stddev_pop?: InputMaybe<TopicsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TopicsStddevSampOrderBy>;
  sum?: InputMaybe<TopicsSumOrderBy>;
  var_pop?: InputMaybe<TopicsVarPopOrderBy>;
  var_samp?: InputMaybe<TopicsVarSampOrderBy>;
  variance?: InputMaybe<TopicsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "topics" */
export interface TopicsArrRelInsertInput {
  data: Array<TopicsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TopicsOnConflict>;
}

/** aggregate avg on columns */
export interface TopicsAvgFields {
  __typename?: 'topics_avg_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "topics" */
export interface TopicsAvgOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "topics". All fields are combined with a logical 'AND'. */
export interface TopicsBoolExp {
  _and?: InputMaybe<Array<TopicsBoolExp>>;
  _not?: InputMaybe<TopicsBoolExp>;
  _or?: InputMaybe<Array<TopicsBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  bookmarks?: InputMaybe<BookmarksBoolExp>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateBoolExp>;
  chapter?: InputMaybe<ChaptersBoolExp>;
  chapter_id?: InputMaybe<IntComparisonExp>;
  gnpies?: InputMaybe<GnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  sub_chapter?: InputMaybe<SubChaptersBoolExp>;
  subchapter_id?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  title_en?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "topics" */
export enum TopicsConstraint {
  /** unique or primary key constraint on columns "id" */
  TopicsNewIdKey = 'topics_new_id_key',
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

/** input type for incrementing numeric columns in table "topics" */
export interface TopicsIncInput {
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "topics" */
export interface TopicsInsertInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bookmarks?: InputMaybe<BookmarksArrRelInsertInput>;
  chapter?: InputMaybe<ChaptersObjRelInsertInput>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  gnpies?: InputMaybe<GnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sub_chapter?: InputMaybe<SubChaptersObjRelInsertInput>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface TopicsMaxFields {
  __typename?: 'topics_max_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "topics" */
export interface TopicsMaxOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface TopicsMinFields {
  __typename?: 'topics_min_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_en?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "topics" */
export interface TopicsMinOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "topics" */
export interface TopicsMutationResponse {
  __typename?: 'topics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Topics>;
}

/** input type for inserting object relation for remote table "topics" */
export interface TopicsObjRelInsertInput {
  data: TopicsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TopicsOnConflict>;
}

/** on_conflict condition type for table "topics" */
export interface TopicsOnConflict {
  constraint: TopicsConstraint;
  update_columns?: Array<TopicsUpdateColumn>;
  where?: InputMaybe<TopicsBoolExp>;
}

/** Ordering options when selecting data from "topics". */
export interface TopicsOrderBy {
  active?: InputMaybe<OrderBy>;
  bookmarks_aggregate?: InputMaybe<BookmarksAggregateOrderBy>;
  chapter?: InputMaybe<ChaptersOrderBy>;
  chapter_id?: InputMaybe<OrderBy>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  sub_chapter?: InputMaybe<SubChaptersOrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  title_en?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: topics */
export interface TopicsPkColumnsInput {
  id: Scalars['Int']['input'];
}

/** select columns of table "topics" */
export enum TopicsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

/** select "topics_aggregate_bool_exp_bool_and_arguments_columns" columns of table "topics" */
export enum TopicsSelectColumnTopicsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** select "topics_aggregate_bool_exp_bool_or_arguments_columns" columns of table "topics" */
export enum TopicsSelectColumnTopicsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "topics" */
export interface TopicsSetInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface TopicsStddevFields {
  __typename?: 'topics_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "topics" */
export interface TopicsStddevOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface TopicsStddevPopFields {
  __typename?: 'topics_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "topics" */
export interface TopicsStddevPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface TopicsStddevSampFields {
  __typename?: 'topics_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "topics" */
export interface TopicsStddevSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "topics" */
export interface TopicsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TopicsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TopicsStreamCursorValueInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  chapter_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subchapter_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface TopicsSumFields {
  __typename?: 'topics_sum_fields';
  chapter_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  subchapter_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "topics" */
export interface TopicsSumOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** update columns of table "topics" */
export enum TopicsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  Id = 'id',
  /** column name */
  SubchapterId = 'subchapter_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleEn = 'title_en'
}

export interface TopicsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TopicsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TopicsSetInput>;
  /** filter the rows which have to be updated */
  where: TopicsBoolExp;
}

/** aggregate var_pop on columns */
export interface TopicsVarPopFields {
  __typename?: 'topics_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "topics" */
export interface TopicsVarPopOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface TopicsVarSampFields {
  __typename?: 'topics_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "topics" */
export interface TopicsVarSampOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface TopicsVarianceFields {
  __typename?: 'topics_variance_fields';
  chapter_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  subchapter_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "topics" */
export interface TopicsVarianceOrderBy {
  chapter_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subchapter_id?: InputMaybe<OrderBy>;
}

/** type of institution */
export interface TypeInstitutions {
  __typename?: 'type_institutions';
  /** An array relationship */
  institutions: Array<Institutions>;
  /** An aggregate relationship */
  institutions_aggregate: InstitutionsAggregate;
  type: Scalars['String']['output'];
}


/** type of institution */
export interface TypeInstitutionsInstitutionsArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}


/** type of institution */
export interface TypeInstitutionsInstitutionsAggregateArgs {
  distinct_on?: InputMaybe<Array<InstitutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstitutionsOrderBy>>;
  where?: InputMaybe<InstitutionsBoolExp>;
}

/** aggregated selection of "type_institutions" */
export interface TypeInstitutionsAggregate {
  __typename?: 'type_institutions_aggregate';
  aggregate?: Maybe<TypeInstitutionsAggregateFields>;
  nodes: Array<TypeInstitutions>;
}

/** aggregate fields of "type_institutions" */
export interface TypeInstitutionsAggregateFields {
  __typename?: 'type_institutions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TypeInstitutionsMaxFields>;
  min?: Maybe<TypeInstitutionsMinFields>;
}


/** aggregate fields of "type_institutions" */
export interface TypeInstitutionsAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<TypeInstitutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "type_institutions". All fields are combined with a logical 'AND'. */
export interface TypeInstitutionsBoolExp {
  _and?: InputMaybe<Array<TypeInstitutionsBoolExp>>;
  _not?: InputMaybe<TypeInstitutionsBoolExp>;
  _or?: InputMaybe<Array<TypeInstitutionsBoolExp>>;
  institutions?: InputMaybe<InstitutionsBoolExp>;
  institutions_aggregate?: InputMaybe<InstitutionsAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "type_institutions" */
export enum TypeInstitutionsConstraint {
  /** unique or primary key constraint on columns "type" */
  TypeInstitutionsPkey = 'type_institutions_pkey'
}

export enum TypeInstitutionsEnum {
  Pavarur = 'PAVARUR',
  Qendror = 'QENDROR',
  Varesie = 'VARESIE'
}

/** Boolean expression to compare columns of type "type_institutions_enum". All fields are combined with logical 'AND'. */
export interface TypeInstitutionsEnumComparisonExp {
  _eq?: InputMaybe<TypeInstitutionsEnum>;
  _in?: InputMaybe<Array<TypeInstitutionsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TypeInstitutionsEnum>;
  _nin?: InputMaybe<Array<TypeInstitutionsEnum>>;
}

/** input type for inserting data into table "type_institutions" */
export interface TypeInstitutionsInsertInput {
  institutions?: InputMaybe<InstitutionsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface TypeInstitutionsMaxFields {
  __typename?: 'type_institutions_max_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface TypeInstitutionsMinFields {
  __typename?: 'type_institutions_min_fields';
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "type_institutions" */
export interface TypeInstitutionsMutationResponse {
  __typename?: 'type_institutions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TypeInstitutions>;
}

/** input type for inserting object relation for remote table "type_institutions" */
export interface TypeInstitutionsObjRelInsertInput {
  data: TypeInstitutionsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TypeInstitutionsOnConflict>;
}

/** on_conflict condition type for table "type_institutions" */
export interface TypeInstitutionsOnConflict {
  constraint: TypeInstitutionsConstraint;
  update_columns?: Array<TypeInstitutionsUpdateColumn>;
  where?: InputMaybe<TypeInstitutionsBoolExp>;
}

/** Ordering options when selecting data from "type_institutions". */
export interface TypeInstitutionsOrderBy {
  institutions_aggregate?: InputMaybe<InstitutionsAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: type_institutions */
export interface TypeInstitutionsPkColumnsInput {
  type: Scalars['String']['input'];
}

/** select columns of table "type_institutions" */
export enum TypeInstitutionsSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "type_institutions" */
export interface TypeInstitutionsSetInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "type_institutions" */
export interface TypeInstitutionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TypeInstitutionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TypeInstitutionsStreamCursorValueInput {
  type?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "type_institutions" */
export enum TypeInstitutionsUpdateColumn {
  /** column name */
  Type = 'type'
}

export interface TypeInstitutionsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TypeInstitutionsSetInput>;
  /** filter the rows which have to be updated */
  where: TypeInstitutionsBoolExp;
}

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface Users {
  __typename?: 'users';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole: AuthRolesEnum;
  /** An object relationship */
  defaultRoleByRole: AuthRoles;
  departamenti?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  dms_id?: Maybe<Scalars['numeric']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  funksioni?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  gnpies: Array<Gnpie>;
  /** An aggregate relationship */
  gnpies_aggregate: GnpieAggregate;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  institution?: Maybe<Institutions>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  isAnonymous: Scalars['Boolean']['output'];
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale: Scalars['String']['output'];
  /** An array relationship */
  meeting_users: Array<MeetingUsers>;
  /** An aggregate relationship */
  meeting_users_aggregate: MeetingUsersAggregate;
  /** An array relationship */
  meetings: Array<Meetings>;
  /** An aggregate relationship */
  meetings_aggregate: MeetingsAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt: Scalars['timestamptz']['output'];
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokensAggregate;
  /** An array relationship */
  report_answers: Array<ReportAnswers>;
  /** An aggregate relationship */
  report_answers_aggregate: ReportAnswersAggregate;
  /** An array relationship */
  report_task_activities: Array<ReportTaskActivity>;
  /** An aggregate relationship */
  report_task_activities_aggregate: ReportTaskActivityAggregate;
  /** An array relationship */
  report_task_comments: Array<ReportTaskComment>;
  /** An aggregate relationship */
  report_task_comments_aggregate: ReportTaskCommentAggregate;
  /** An array relationship */
  roles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  roles_aggregate: AuthUserRolesAggregate;
  /** An array relationship */
  securityKeys: Array<AuthUserSecurityKeys>;
  /** An aggregate relationship */
  securityKeys_aggregate: AuthUserSecurityKeysAggregate;
  /** An array relationship */
  tasksAssigned: Array<Task>;
  /** An aggregate relationship */
  tasksAssigned_aggregate: TaskAggregate;
  /** An array relationship */
  tasksToReport: Array<Task>;
  /** An aggregate relationship */
  tasksToReport_aggregate: TaskAggregate;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt: Scalars['timestamptz']['output'];
  totpSecret?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  training_needs: Array<CapacTrainingNeeds>;
  /** An aggregate relationship */
  training_needs_aggregate: CapacTrainingNeedsAggregate;
  /** An array relationship */
  training_participations: Array<CapacTrainingParticipants>;
  /** An aggregate relationship */
  training_participations_aggregate: CapacTrainingParticipantsAggregate;
  /** An array relationship */
  trainings: Array<CapacTrainings>;
  /** An aggregate relationship */
  trainings_aggregate: CapacTrainingsAggregate;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProvidersAggregate;
  username?: Maybe<Scalars['String']['output']>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersGnpiesArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersGnpiesAggregateArgs {
  distinct_on?: InputMaybe<Array<GnpieSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GnpieOrderBy>>;
  where?: InputMaybe<GnpieBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersMeetingUsersArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersMeetingUsersAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingUsersOrderBy>>;
  where?: InputMaybe<MeetingUsersBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersMeetingsArgs {
  distinct_on?: InputMaybe<Array<MeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingsOrderBy>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersMeetingsAggregateArgs {
  distinct_on?: InputMaybe<Array<MeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeetingsOrderBy>>;
  where?: InputMaybe<MeetingsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersMetadataArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersRefreshTokensAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersReportAnswersArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersReportAnswersAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportAnswersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportAnswersOrderBy>>;
  where?: InputMaybe<ReportAnswersBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersReportTaskActivitiesArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersReportTaskActivitiesAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskActivityOrderBy>>;
  where?: InputMaybe<ReportTaskActivityBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersReportTaskCommentsArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersReportTaskCommentsAggregateArgs {
  distinct_on?: InputMaybe<Array<ReportTaskCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportTaskCommentOrderBy>>;
  where?: InputMaybe<ReportTaskCommentBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersRolesArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersRolesAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersSecurityKeysArgs {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersSecurityKeysAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTasksAssignedArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTasksAssignedAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTasksToReportArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTasksToReportAggregateArgs {
  distinct_on?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTrainingNeedsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTrainingNeedsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingNeedsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingNeedsOrderBy>>;
  where?: InputMaybe<CapacTrainingNeedsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTrainingParticipationsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTrainingParticipationsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingParticipantsOrderBy>>;
  where?: InputMaybe<CapacTrainingParticipantsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTrainingsArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsOrderBy>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersTrainingsAggregateArgs {
  distinct_on?: InputMaybe<Array<CapacTrainingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CapacTrainingsOrderBy>>;
  where?: InputMaybe<CapacTrainingsBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersUserProvidersArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface UsersUserProvidersAggregateArgs {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
}

/** aggregated selection of "auth.users" */
export interface UsersAggregate {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
}

export interface UsersAggregateBoolExp {
  bool_and?: InputMaybe<UsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<UsersAggregateBoolExpCount>;
}

export interface UsersAggregateBoolExpBoolAnd {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface UsersAggregateBoolExpBoolOr {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface UsersAggregateBoolExpCount {
  arguments?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "auth.users" */
export interface UsersAggregateFields {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<UsersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
  stddev?: Maybe<UsersStddevFields>;
  stddev_pop?: Maybe<UsersStddevPopFields>;
  stddev_samp?: Maybe<UsersStddevSampFields>;
  sum?: Maybe<UsersSumFields>;
  var_pop?: Maybe<UsersVarPopFields>;
  var_samp?: Maybe<UsersVarSampFields>;
  variance?: Maybe<UsersVarianceFields>;
}


/** aggregate fields of "auth.users" */
export interface UsersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "auth.users" */
export interface UsersAggregateOrderBy {
  avg?: InputMaybe<UsersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UsersMaxOrderBy>;
  min?: InputMaybe<UsersMinOrderBy>;
  stddev?: InputMaybe<UsersStddevOrderBy>;
  stddev_pop?: InputMaybe<UsersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UsersStddevSampOrderBy>;
  sum?: InputMaybe<UsersSumOrderBy>;
  var_pop?: InputMaybe<UsersVarPopOrderBy>;
  var_samp?: InputMaybe<UsersVarSampOrderBy>;
  variance?: InputMaybe<UsersVarianceOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface UsersAppendInput {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "auth.users" */
export interface UsersArrRelInsertInput {
  data: Array<UsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
}

/** aggregate avg on columns */
export interface UsersAvgFields {
  __typename?: 'users_avg_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "auth.users" */
export interface UsersAvgOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export interface UsersBoolExp {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  activeMfaType?: InputMaybe<StringComparisonExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currentChallenge?: InputMaybe<StringComparisonExp>;
  defaultRole?: InputMaybe<AuthRolesEnumComparisonExp>;
  defaultRoleByRole?: InputMaybe<AuthRolesBoolExp>;
  departamenti?: InputMaybe<StringComparisonExp>;
  disabled?: InputMaybe<BooleanComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  dms_id?: InputMaybe<NumericComparisonExp>;
  email?: InputMaybe<CitextComparisonExp>;
  emailVerified?: InputMaybe<BooleanComparisonExp>;
  funksioni?: InputMaybe<StringComparisonExp>;
  gnpies?: InputMaybe<GnpieBoolExp>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  institution?: InputMaybe<InstitutionsBoolExp>;
  institution_id?: InputMaybe<UuidComparisonExp>;
  isAnonymous?: InputMaybe<BooleanComparisonExp>;
  lastSeen?: InputMaybe<TimestamptzComparisonExp>;
  locale?: InputMaybe<StringComparisonExp>;
  meeting_users?: InputMaybe<MeetingUsersBoolExp>;
  meeting_users_aggregate?: InputMaybe<MeetingUsersAggregateBoolExp>;
  meetings?: InputMaybe<MeetingsBoolExp>;
  meetings_aggregate?: InputMaybe<MeetingsAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  newEmail?: InputMaybe<CitextComparisonExp>;
  otpHash?: InputMaybe<StringComparisonExp>;
  otpHashExpiresAt?: InputMaybe<TimestamptzComparisonExp>;
  otpMethodLastUsed?: InputMaybe<StringComparisonExp>;
  passwordHash?: InputMaybe<StringComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  phoneNumberVerified?: InputMaybe<BooleanComparisonExp>;
  refreshTokens?: InputMaybe<AuthRefreshTokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateBoolExp>;
  report_answers?: InputMaybe<ReportAnswersBoolExp>;
  report_answers_aggregate?: InputMaybe<ReportAnswersAggregateBoolExp>;
  report_task_activities?: InputMaybe<ReportTaskActivityBoolExp>;
  report_task_activities_aggregate?: InputMaybe<ReportTaskActivityAggregateBoolExp>;
  report_task_comments?: InputMaybe<ReportTaskCommentBoolExp>;
  report_task_comments_aggregate?: InputMaybe<ReportTaskCommentAggregateBoolExp>;
  roles?: InputMaybe<AuthUserRolesBoolExp>;
  roles_aggregate?: InputMaybe<AuthUserRolesAggregateBoolExp>;
  securityKeys?: InputMaybe<AuthUserSecurityKeysBoolExp>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeysAggregateBoolExp>;
  tasksAssigned?: InputMaybe<TaskBoolExp>;
  tasksAssigned_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  tasksToReport?: InputMaybe<TaskBoolExp>;
  tasksToReport_aggregate?: InputMaybe<TaskAggregateBoolExp>;
  ticket?: InputMaybe<StringComparisonExp>;
  ticketExpiresAt?: InputMaybe<TimestamptzComparisonExp>;
  totpSecret?: InputMaybe<StringComparisonExp>;
  training_needs?: InputMaybe<CapacTrainingNeedsBoolExp>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateBoolExp>;
  training_participations?: InputMaybe<CapacTrainingParticipantsBoolExp>;
  training_participations_aggregate?: InputMaybe<CapacTrainingParticipantsAggregateBoolExp>;
  trainings?: InputMaybe<CapacTrainingsBoolExp>;
  trainings_aggregate?: InputMaybe<CapacTrainingsAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userProviders?: InputMaybe<AuthUserProvidersBoolExp>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateBoolExp>;
  username?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "auth.users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface UsersDeleteAtPathInput {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface UsersDeleteElemInput {
  metadata?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface UsersDeleteKeyInput {
  metadata?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "auth.users" */
export interface UsersIncInput {
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "auth.users" */
export interface UsersInsertInput {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<AuthRolesEnum>;
  defaultRoleByRole?: InputMaybe<AuthRolesObjRelInsertInput>;
  departamenti?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  funksioni?: InputMaybe<Scalars['String']['input']>;
  gnpies?: InputMaybe<GnpieArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<InstitutionsObjRelInsertInput>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  meeting_users?: InputMaybe<MeetingUsersArrRelInsertInput>;
  meetings?: InputMaybe<MeetingsArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokensArrRelInsertInput>;
  report_answers?: InputMaybe<ReportAnswersArrRelInsertInput>;
  report_task_activities?: InputMaybe<ReportTaskActivityArrRelInsertInput>;
  report_task_comments?: InputMaybe<ReportTaskCommentArrRelInsertInput>;
  roles?: InputMaybe<AuthUserRolesArrRelInsertInput>;
  securityKeys?: InputMaybe<AuthUserSecurityKeysArrRelInsertInput>;
  tasksAssigned?: InputMaybe<TaskArrRelInsertInput>;
  tasksToReport?: InputMaybe<TaskArrRelInsertInput>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  training_needs?: InputMaybe<CapacTrainingNeedsArrRelInsertInput>;
  training_participations?: InputMaybe<CapacTrainingParticipantsArrRelInsertInput>;
  trainings?: InputMaybe<CapacTrainingsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<AuthUserProvidersArrRelInsertInput>;
  username?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface UsersMaxFields {
  __typename?: 'users_max_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  departamenti?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['numeric']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  funksioni?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "auth.users" */
export interface UsersMaxOrderBy {
  activeMfaType?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currentChallenge?: InputMaybe<OrderBy>;
  departamenti?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  funksioni?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  newEmail?: InputMaybe<OrderBy>;
  otpHash?: InputMaybe<OrderBy>;
  otpHashExpiresAt?: InputMaybe<OrderBy>;
  otpMethodLastUsed?: InputMaybe<OrderBy>;
  passwordHash?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  ticket?: InputMaybe<OrderBy>;
  ticketExpiresAt?: InputMaybe<OrderBy>;
  totpSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
}

/** aggregate min on columns */
export interface UsersMinFields {
  __typename?: 'users_min_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  departamenti?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  dms_id?: Maybe<Scalars['numeric']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  funksioni?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution_id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "auth.users" */
export interface UsersMinOrderBy {
  activeMfaType?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currentChallenge?: InputMaybe<OrderBy>;
  departamenti?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  funksioni?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  newEmail?: InputMaybe<OrderBy>;
  otpHash?: InputMaybe<OrderBy>;
  otpHashExpiresAt?: InputMaybe<OrderBy>;
  otpMethodLastUsed?: InputMaybe<OrderBy>;
  passwordHash?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  ticket?: InputMaybe<OrderBy>;
  ticketExpiresAt?: InputMaybe<OrderBy>;
  totpSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
}

/** response of any mutation on the table "auth.users" */
export interface UsersMutationResponse {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
}

/** input type for inserting object relation for remote table "auth.users" */
export interface UsersObjRelInsertInput {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
}

/** on_conflict condition type for table "auth.users" */
export interface UsersOnConflict {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
}

/** Ordering options when selecting data from "auth.users". */
export interface UsersOrderBy {
  activeMfaType?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currentChallenge?: InputMaybe<OrderBy>;
  defaultRole?: InputMaybe<OrderBy>;
  defaultRoleByRole?: InputMaybe<AuthRolesOrderBy>;
  departamenti?: InputMaybe<OrderBy>;
  disabled?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  dms_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emailVerified?: InputMaybe<OrderBy>;
  funksioni?: InputMaybe<OrderBy>;
  gnpies_aggregate?: InputMaybe<GnpieAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  institution?: InputMaybe<InstitutionsOrderBy>;
  institution_id?: InputMaybe<OrderBy>;
  isAnonymous?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  meeting_users_aggregate?: InputMaybe<MeetingUsersAggregateOrderBy>;
  meetings_aggregate?: InputMaybe<MeetingsAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  newEmail?: InputMaybe<OrderBy>;
  otpHash?: InputMaybe<OrderBy>;
  otpHashExpiresAt?: InputMaybe<OrderBy>;
  otpMethodLastUsed?: InputMaybe<OrderBy>;
  passwordHash?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  phoneNumberVerified?: InputMaybe<OrderBy>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateOrderBy>;
  report_answers_aggregate?: InputMaybe<ReportAnswersAggregateOrderBy>;
  report_task_activities_aggregate?: InputMaybe<ReportTaskActivityAggregateOrderBy>;
  report_task_comments_aggregate?: InputMaybe<ReportTaskCommentAggregateOrderBy>;
  roles_aggregate?: InputMaybe<AuthUserRolesAggregateOrderBy>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeysAggregateOrderBy>;
  tasksAssigned_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  tasksToReport_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  ticket?: InputMaybe<OrderBy>;
  ticketExpiresAt?: InputMaybe<OrderBy>;
  totpSecret?: InputMaybe<OrderBy>;
  training_needs_aggregate?: InputMaybe<CapacTrainingNeedsAggregateOrderBy>;
  training_participations_aggregate?: InputMaybe<CapacTrainingParticipantsAggregateOrderBy>;
  trainings_aggregate?: InputMaybe<CapacTrainingsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateOrderBy>;
  username?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: auth.users */
export interface UsersPkColumnsInput {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface UsersPrependInput {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "auth.users" */
export enum UsersSelectColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Departamenti = 'departamenti',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Funksioni = 'funksioni',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

/** input type for updating data in table "auth.users" */
export interface UsersSetInput {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<AuthRolesEnum>;
  departamenti?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  funksioni?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface UsersStddevFields {
  __typename?: 'users_stddev_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "auth.users" */
export interface UsersStddevOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface UsersStddevPopFields {
  __typename?: 'users_stddev_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "auth.users" */
export interface UsersStddevPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface UsersStddevSampFields {
  __typename?: 'users_stddev_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "auth.users" */
export interface UsersStddevSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Streaming cursor of the table "users" */
export interface UsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface UsersStreamCursorValueInput {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<AuthRolesEnum>;
  departamenti?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dms_id?: InputMaybe<Scalars['numeric']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  funksioni?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution_id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface UsersSumFields {
  __typename?: 'users_sum_fields';
  dms_id?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "auth.users" */
export interface UsersSumOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** update columns of table "auth.users" */
export enum UsersUpdateColumn {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Departamenti = 'departamenti',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  DmsId = 'dms_id',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Funksioni = 'funksioni',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionId = 'institution_id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export interface UsersUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UsersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UsersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UsersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UsersDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UsersIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UsersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
}

/** aggregate var_pop on columns */
export interface UsersVarPopFields {
  __typename?: 'users_var_pop_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "auth.users" */
export interface UsersVarPopOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface UsersVarSampFields {
  __typename?: 'users_var_samp_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "auth.users" */
export interface UsersVarSampOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** aggregate variance on columns */
export interface UsersVarianceFields {
  __typename?: 'users_variance_fields';
  dms_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "auth.users" */
export interface UsersVarianceOrderBy {
  dms_id?: InputMaybe<OrderBy>;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface UuidArrayComparisonExp {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _eq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _gte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _lte?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _neq?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['uuid']['input']>>>;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface UuidComparisonExp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

/** enum contains quarters */
export interface YearlyQuarters {
  __typename?: 'yearly_quarters';
  /** An array relationship */
  bookmark_tasks: Array<BookmarkTasks>;
  /** An aggregate relationship */
  bookmark_tasks_aggregate: BookmarkTasksAggregate;
  /** An array relationship */
  measures: Array<PkieMeasure>;
  /** An aggregate relationship */
  measures_aggregate: PkieMeasureAggregate;
  quarter: Scalars['String']['output'];
}


/** enum contains quarters */
export interface YearlyQuartersBookmarkTasksArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


/** enum contains quarters */
export interface YearlyQuartersBookmarkTasksAggregateArgs {
  distinct_on?: InputMaybe<Array<BookmarkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BookmarkTasksOrderBy>>;
  where?: InputMaybe<BookmarkTasksBoolExp>;
}


/** enum contains quarters */
export interface YearlyQuartersMeasuresArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}


/** enum contains quarters */
export interface YearlyQuartersMeasuresAggregateArgs {
  distinct_on?: InputMaybe<Array<PkieMeasureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PkieMeasureOrderBy>>;
  where?: InputMaybe<PkieMeasureBoolExp>;
}

/** aggregated selection of "yearly_quarters" */
export interface YearlyQuartersAggregate {
  __typename?: 'yearly_quarters_aggregate';
  aggregate?: Maybe<YearlyQuartersAggregateFields>;
  nodes: Array<YearlyQuarters>;
}

/** aggregate fields of "yearly_quarters" */
export interface YearlyQuartersAggregateFields {
  __typename?: 'yearly_quarters_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<YearlyQuartersMaxFields>;
  min?: Maybe<YearlyQuartersMinFields>;
}


/** aggregate fields of "yearly_quarters" */
export interface YearlyQuartersAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<YearlyQuartersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "yearly_quarters". All fields are combined with a logical 'AND'. */
export interface YearlyQuartersBoolExp {
  _and?: InputMaybe<Array<YearlyQuartersBoolExp>>;
  _not?: InputMaybe<YearlyQuartersBoolExp>;
  _or?: InputMaybe<Array<YearlyQuartersBoolExp>>;
  bookmark_tasks?: InputMaybe<BookmarkTasksBoolExp>;
  bookmark_tasks_aggregate?: InputMaybe<BookmarkTasksAggregateBoolExp>;
  measures?: InputMaybe<PkieMeasureBoolExp>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateBoolExp>;
  quarter?: InputMaybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "yearly_quarters" */
export enum YearlyQuartersConstraint {
  /** unique or primary key constraint on columns "quarter" */
  YearlyQuartersPkey = 'yearly_quarters_pkey'
}

/** input type for inserting data into table "yearly_quarters" */
export interface YearlyQuartersInsertInput {
  bookmark_tasks?: InputMaybe<BookmarkTasksArrRelInsertInput>;
  measures?: InputMaybe<PkieMeasureArrRelInsertInput>;
  quarter?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface YearlyQuartersMaxFields {
  __typename?: 'yearly_quarters_max_fields';
  quarter?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface YearlyQuartersMinFields {
  __typename?: 'yearly_quarters_min_fields';
  quarter?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "yearly_quarters" */
export interface YearlyQuartersMutationResponse {
  __typename?: 'yearly_quarters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<YearlyQuarters>;
}

/** input type for inserting object relation for remote table "yearly_quarters" */
export interface YearlyQuartersObjRelInsertInput {
  data: YearlyQuartersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<YearlyQuartersOnConflict>;
}

/** on_conflict condition type for table "yearly_quarters" */
export interface YearlyQuartersOnConflict {
  constraint: YearlyQuartersConstraint;
  update_columns?: Array<YearlyQuartersUpdateColumn>;
  where?: InputMaybe<YearlyQuartersBoolExp>;
}

/** Ordering options when selecting data from "yearly_quarters". */
export interface YearlyQuartersOrderBy {
  bookmark_tasks_aggregate?: InputMaybe<BookmarkTasksAggregateOrderBy>;
  measures_aggregate?: InputMaybe<PkieMeasureAggregateOrderBy>;
  quarter?: InputMaybe<OrderBy>;
}

/** primary key columns input for table: yearly_quarters */
export interface YearlyQuartersPkColumnsInput {
  quarter: Scalars['String']['input'];
}

/** select columns of table "yearly_quarters" */
export enum YearlyQuartersSelectColumn {
  /** column name */
  Quarter = 'quarter'
}

/** input type for updating data in table "yearly_quarters" */
export interface YearlyQuartersSetInput {
  quarter?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "yearly_quarters" */
export interface YearlyQuartersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: YearlyQuartersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface YearlyQuartersStreamCursorValueInput {
  quarter?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "yearly_quarters" */
export enum YearlyQuartersUpdateColumn {
  /** column name */
  Quarter = 'quarter'
}

export interface YearlyQuartersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<YearlyQuartersSetInput>;
  /** filter the rows which have to be updated */
  where: YearlyQuartersBoolExp;
}
