import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MisAuthService } from '@core/services/mis-auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoreActions } from '@store/core';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AuthActions } from './auth.actions';
import { captureException } from '@sentry/angular-ivy';
@Injectable()
export class AuthEffects {
  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signOut),
      switchMap(() =>
        from(this.auth.client.signOut({ all: false })).pipe(
          map(signOutResult => ({
            signOutResult,
            actions: [AuthActions.resetAuth(), CoreActions.resetCore()],
          })),
          catchError(error => of({
            signOutResult: null,
            actions: [AuthActions.resetAuth(), CoreActions.resetCore()],
            error
          })),
          tap(async ({ signOutResult }) => {
            try {
              this.cookie.deleteAll('/');
              indexedDB.deleteDatabase('cache');
              indexedDB.deleteDatabase('dms');
            } catch(err) { 
              captureException(err, {
                level: 'error',
                user: {
                  id: this.auth.user?.id,
                },
              });
            }
            if(signOutResult?.redirectTo) {
              // Assuming signOutResult?.redirectTo contains the URL you want to redirect to
              let redirectTo = signOutResult?.redirectTo || '';
              // Create a URL object to manipulate the URL
              const url = new URL(redirectTo, window.location.origin);
              // Append the query parameters to the URL
              url.searchParams.append('post_logout_redirect_uri', window.location.origin);
              // Use window.location.replace to redirect with the new URL
              window.location.replace(url.toString());
            } else {
              await this.router.navigate(['/auth/login']);
            }
          }),
          switchMap(({ actions }) => actions)
        )
      )
    )
  );

  initDmsToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initDmsToken),
      exhaustMap((payload) =>
        of(payload.dmsToken).pipe(
          map((dmsToken) => {
            if(dmsToken) {
              let token = dmsToken.replace(/\s/g, '');
              let parts = token.split(';');
              if(parts.length === 2) {
                let csrfToken = parts[0].split('=')[1];
                let sessionId = parts[1].split('=')[1];
                this.cookie.set('csrftoken', csrfToken, {path: '/'});
                this.cookie.set('sessionid', sessionId, {path: '/'});
                return AuthActions.setDmsToken({ csrfToken, sessionId });
              }
            }
            return AuthActions.setDmsNotAvailable();
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router, private auth: MisAuthService, private cookie: CookieService) {}
}
