import { Users } from "@core/api";

export interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export interface AuthSession {
  accessToken: string;
  refreshToken: string;
  user: Users;
}

export enum AuthProvider {
  OIDC = 'oidc',
  ENTRA_ID = 'entraid',
  NAIS_B2C = 'naisb2c',
  E_ALBANIA = 'ealbania',
}