import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { MaliciousCodeLogsService } from '@core/services/malicious-code-log.service';
import { LoggerService } from '@core/services/logger.service';
@Injectable()
export class MaliciousCodeInterceptor implements HttpInterceptor {

  constructor(
    private _maliciousCodeLogsService: MaliciousCodeLogsService,
    private _loggerService: LoggerService
  ) { };

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((event) => 
        {}, 
        error => {
        if (error instanceof HttpErrorResponse && this.hasMaliciousCode(error.error.text)) {
          let object = {
            // ip added on the service
            endpoint: request.url,
            payload: btoa(request.body),
            response: this.convertHtmlToString(error.error.text),
          };
          (async() => {
            try {
              const response = await this._maliciousCodeLogsService.insertErrorLog(object);
              response.subscribe();
            } catch(err) {
              this._loggerService.error(err);
            }
          })();
        }
      })
    );
  };

  private hasMaliciousCode(body: string): boolean {
    if (!body) return false;
    return body.includes('kod keq-dashës');
  };

  private convertHtmlToString(body: any) {
    const html = body;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const plainText = tempDiv.textContent || tempDiv.innerText || '';
    tempDiv.remove();
    return plainText;
  };

}