export * from './generated/mutations';
export * from './generated/queries';
export * from './generated/types';
export enum NetworkStatus {
    loading = 1,
    setVariables = 2,
    fetchMore = 3,
    refetch = 4,
    poll = 6,
    ready = 7,
    error = 8
}