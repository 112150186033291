/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ChangeLogResponse } from '../../models/change-log-response';

export interface GetParagraphTranslationHistory$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;

/**
 * Unique identifier of the EU Law under this project
 */
  lawId: string;

/**
 * Unique identifier of the article in the EU Law which obtained from the Table of Content
 */
  articleId: string;

/**
 * Unique identifier of the paragraph in the article
 */
  paragraphId: string;
}

export function getParagraphTranslationHistory(http: HttpClient, rootUrl: string, params: GetParagraphTranslationHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChangeLogResponse>>> {
  const rb = new RequestBuilder(rootUrl, getParagraphTranslationHistory.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('lawId', params.lawId, {});
    rb.path('articleId', params.articleId, {});
    rb.path('paragraphId', params.paragraphId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ChangeLogResponse>>;
    })
  );
}

getParagraphTranslationHistory.PATH = '/projects/{projectId}/euLaw/{lawId}/translations/{articleId}/paragraphs/{paragraphId}/history';
