/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface UpdateParagraphTranslation$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;

/**
 * Unique identifier of the EU Law under this project
 */
  lawId: string;

/**
 * Unique identifier of the article in the EU Law which obtained from the Table of Content
 */
  articleId: string;

/**
 * Unique identifier of the paragraph in the article
 */
  paragraphId: string;
      body: {

/**
 * Updated translation content of the paragraph
 */
'content': string;
}
}

export function updateParagraphTranslation(http: HttpClient, rootUrl: string, params: UpdateParagraphTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, updateParagraphTranslation.PATH, 'put');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('lawId', params.lawId, {});
    rb.path('articleId', params.articleId, {});
    rb.path('paragraphId', params.paragraphId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

updateParagraphTranslation.PATH = '/projects/{projectId}/euLaw/{lawId}/translations/{articleId}/paragraphs/{paragraphId}';
