import { ValueProvider } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { DYNAMIC_VALIDATORS, Validator, ValidatorFactory } from '@ng-dynamic-forms/core';

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const hasError = control.value ? (control.value as string).match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$") : false;
  return hasError ? { email: true } : null;
}

export const CUSTOM_VALIDATORS: ValueProvider[] = [
  {
    provide: NG_VALIDATORS,
    useValue: emailValidator,
    multi: true,
  },
  {
    provide: DYNAMIC_VALIDATORS,
    useValue: new Map<string, Validator | ValidatorFactory>([
        ['email', emailValidator] // <-- Don't forget to add the custom validator in the array here!
    ]),
  },
];
